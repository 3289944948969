import React, { useState } from "react";
import { Popconfirm, Tooltip, Checkbox } from "antd";
import { useIntl } from "react-intl";
import moment from "moment";
import { Panel, Icon, CustomTable, CustomTag } from "../../../../components/common";
import "./push-notification-table.scss";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "push-notification-table__panel-container",
};

function PushNotificationTable(props,) {
  const intl = useIntl();
  
// const isBlocked=props.data.isBlocked
// console.log(isBlocked,"hush")

  const columns = [
    {
      dataIndex: '',
      title: intl.formatMessage({id: 'app.field.sNo'}),
      render: (item,object,index) => {
        return (<span>{props.pagination.pageSize * (props.pagination.current - 1) + index + 1}</span>)
      }
    },
    {
      dataIndex: 'name',
      title: "Name"
    },
    {
      dataIndex: 'branchName',
      title: "State",
    },
    {
      dataIndex: 'tags',
      title: "Country",
    },
    {
      dataIndex: 'isBlocked',
      title: "Status",
      render: (i) => <CustomTag isBlocked={i} />
    },
    
    
  ]
  console.log("wereee",columns[4]  )
  return (
    <CustomTable
    dataSource={props.data.filter(i => (i.name?.toLowerCase().includes(props.search.toLowerCase()) || `${i.tags}`.toLowerCase().includes(props.search.toLowerCase()) || `${i.branchName}`.toLowerCase().includes(props.search.toLowerCase())))}
    isLoading={props.isLoading}
    columns={columns}
    pagination={false}
    rowSelection={{
      type: 'checkbox',
      ...props.rowSelection,
    }}
    rowKey={i => i.geofenceId}
    toggleRowSelectAccessor={'isBlocked'}
     />
  );

  return (
    <Panel
      containerClass={styles.panelContainer}
      isLoading={props.isLoading}
      isEmpty={props.isEmpty}
      emptyText="No data"
    >
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>Select</th>
            {/* <th>{intl.formatMessage({ id: "app.field.storeName" })}</th> */}
            <th>{intl.formatMessage({ id: "app.field.areaName" })}</th>
            <th>{intl.formatMessage({ id: "app.field.areaDescription" })}</th>
          </tr>
          {props.data.filter(i => (i.name?.toLowerCase().includes(props.search.toLowerCase()) || `${i.tags}`.toLowerCase().includes(props.search.toLowerCase()))).map((item, index) => (
            <tr key={item.geofenceId}>
              <td><input style={{padding: '1rem'}} type="checkbox" checked={item.selected} onChange={() => {props.toggleItem(item.geofenceId)}} /></td>
              {/* <td>{item.branchName}</td> */}
              <td>{item.name}</td>
              <td>{item.tags}</td>
              {/* <td>{moment(item.createdAt).format("HH:MM MMMM Do YYYY")}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
}

export default PushNotificationTable;
