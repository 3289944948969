import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../../components/common";
import axios from "../../../../shared/api/axiose";
import Validations from "../../../../shared/validations/Validations";
import { multiSort } from "../../../../shared/utils/object-manipulation";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-promo-modal.scss";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "active-promo__add-edit-promo-modal__image-container",
  imageContainerImage:
    "active-promo__add-edit-promo-modal__image-container__image",
  imageContainerForm:
    "active-promo__add-edit-promo-modal__image-container__form",
  spanImp: "active-promo__add-edit-promo-modal__span-img",
  datePicker: "datePicker",
};

const errorText = "Field cannot be empty";

const promoTypes = [
  {
    value: "1",
    text: "Fixed Proce",
  },
  {
    value: "2",
    text: "Quotations via prooof",
  },
  {
    value: "2",
    text: "Quotations via visit",
  },
];

function AddEditPromoContainer({
  getBannerData,
  // getServicesData,
  getCategoriesData,
  fetchBanners,
  getCityData,
  ...props
}) {
  // ===============================================================
  const [servicesData, setServicesData] = useState([]);
  console.log("letdata", servicesData);
  const [selectedServices, setSelectedServices] = useState([]);
  const [banners, SetBanners] = useState("");
  const [CategoryDataset, setCategoryDataset] = useState([]);
  const intl = useIntl();
  const imageContainerRef = useRef(null);
  const [isPristine, togglePristine] = useState(true);

  const [bannerDetails, updateBannerDetails] = useState({
    bannerName: Validations.validateTextField(undefined),
    bannerImg: Validations.validateTextField(undefined),
    startDate: Validations.validateTextField(undefined),
    endDate: Validations.validateTextField(undefined),
    type: Validations.validateTextField(undefined),
    cityData: Validations.validateMultiSelect(),
    categotySelect: Validations.validateMultiSelect(),
    serviceSelect: Validations.validateMultiSelect(),
    selectedServicesData: [],
  });

  // =====================================================================

  const resetForm = () => {
    updateBannerDetails({
      ...bannerDetails,
      bannerName: Validations.validateTextField(undefined),
      bannerImg: Validations.validateTextField(undefined),
      startDate: Validations.validateTextField(undefined),
      endDate: Validations.validateTextField(undefined),
      type: Validations.validateTextField(undefined),
      cityData: Validations.validateMultiSelect(),
      categotySelect: Validations.validateMultiSelect(),
      serviceSelect: Validations.validateMultiSelect(),
    });
  };
  useEffect(() => {
    axios
      .get("/category/")
      .then((response) => {
        setCategoryDataset(response.data.data?.categories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("/services")
      .then((response) => {
        setServicesData(response.data.data.services);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleServiceSelectData = (selectedValues) => {
    setSelectedServices(selectedValues);
  };
  const setForm = () => {
    togglePristine(true);
    console.log("banner setFOrm => ", props?.data);

    let city = [];

    if (props?.data?.city) {
      console.log("banner setFOrm => city ", city);
      JSON.parse(props?.data?.city).map((obj) => {
        city.push(obj?.city);
      });
    }
    let obj = {};
    obj.type = Validations.validateTextField(props?.data?.type);
    let subType = [];
    if (props?.data?.type === "Categories" && props?.data?.subType) {
      JSON.parse(props?.data?.subType).map((obj) => {
        subType.push(obj?.subType);
      });
      obj.categotySelect = Validations.validateMultiSelect(
        subType ? subType : []
      );
    }

    if (props?.data?.type === "Services" && props?.data?.subType) {
      JSON.parse(props?.data?.subType).map((obj) => {
        subType.push(obj?.subType);
      });
      obj.serviceSelect = Validations.validateMultiSelect(
        subType ? subType : []
      );
    }

    updateBannerDetails({
      ...bannerDetails,
      bannerName: Validations.validateTextField(props?.data?.title),
      bannerImg: Validations.validateTextField(props?.data?.imgURL),
      startDate: Validations.validateTextField(
        props?.data?.startDate ? new Date(props.data?.startDate) : ""
      ),
      endDate: Validations.validateTextField(
        props?.data?.endDate ? new Date(props.data?.endDate) : ""
      ),
      cityData: Validations.validateMultiSelect(city ? city : []),
      ...obj,
    });
  };

  useEffect(() => {
    if (props?.modalToShow === "EDIT" || props?.modalToShow === "VIEW") {
      setForm();
    } else {
      resetForm();
    }
  }, [props?.modalToShow, props?.data]);

  const handleEdit = () => {
    let subType = [];
    let city = [];
    let payload = {
      title: bannerDetails?.bannerName?.value,
      imgURL: bannerDetails?.bannerImg?.value,
      isActive: true,
      startDate: bannerDetails?.startDate?.value,
      endDate: bannerDetails?.endDate?.value,
      type: bannerDetails?.type?.value,
    };
    if (
      bannerDetails?.type?.value === "Categories" &&
      bannerDetails?.categotySelect?.value
    ) {
      (bannerDetails?.categotySelect?.value).map((id) => {
        let obj = {};
        obj.subType = id;
        subType.push(obj);
      });
    }
    if (
      bannerDetails?.type?.value === "Services" &&
      bannerDetails?.serviceSelect?.value
    ) {
      (bannerDetails?.serviceSelect?.value).map((id) => {
        let obj = {};
        obj.subType = id;
        subType.push(obj);
      });
    }
    if (bannerDetails?.cityData?.value) {
      (bannerDetails?.cityData?.value).map((id) => {
        let obj = {};
        obj.city = id;
        city.push(obj);
      });
    }

    // payload.subType = subType;
    // payload.city = city;
    axios
      .put(`/banners/${props?.data?.id}`, payload)
      .then((res) => {
        console.log("Add banner => ", res?.data);
        toast.success(res?.data?.message);
        SetBanners(props.banners);
        resetForm();
        closeModal();
        getBannerData();
        fetchBanners();
      })
      .catch((err, res) => {
        toast.error(res?.data?.message);
        console.log(err);
        closeModal();
      });
  };

  const handleAdd = () => {
    console.log("banner add => ", bannerDetails);

    let subType = [];
    let city = [];
    let payload = {
      title: bannerDetails?.bannerName?.value,
      imgURL: bannerDetails?.bannerImg?.value,
      isActive: true,
      startDate: bannerDetails?.startDate?.value,
      endDate: bannerDetails?.endDate?.value,
      type: bannerDetails?.type?.value,
    };
    if (bannerDetails?.type?.value === "Categories") {
      (bannerDetails?.categotySelect?.value).map((id) => {
        let obj = {};
        obj.subType = id;
        subType.push(obj);
      });
    }
    if (bannerDetails?.type?.value === "Services") {
      (bannerDetails?.serviceSelect?.value).map((id) => {
        let obj = {};
        obj.subType = id;
        subType.push(obj);
      });
    }
    if (bannerDetails?.cityData?.value) {
      (bannerDetails?.cityData?.value).map((id) => {
        let obj = {};
        obj.city = id;
        city.push(obj);
      });
    }

    payload.subType = subType;
    payload.city = city;
    console.log("subtype", subType);
    axios
      .post("/banners", payload)
      .then((res) => {
        console.log("Add banner => ", res?.data);
        toast.success(res?.data?.message);
        resetForm();
        closeModal();
        getBannerData();
      })
      .catch((err, res) => {
        toast.error(res?.data?.message);
        console.log(err);
        closeModal();
      });
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "bannerName":
        updateBannerDetails({
          ...bannerDetails,
          bannerName: Validations.validateTextField(value),
        });
        break;

      case "bannerDescription":
        updateBannerDetails({
          ...bannerDetails,
          bannerDescription: Validations.validateTextField(value),
        });
        break;
      case "bannerDetails":
        updateBannerDetails({
          ...bannerDetails,
          bannerDetails: Validations.validateTextField(value),
        });
        break;
      default:
        return;
    }
  };
  const handlbeStartDate = (date) => {
    updateBannerDetails({
      ...bannerDetails,
      startDate: Validations.validateTextField(date),
    });
  };
  const handlbeEndDate = (date) => {
    updateBannerDetails({
      ...bannerDetails,
      endDate: Validations.validateTextField(date),
    });
  };
  const handleCitySelect = (data) => {
    updateBannerDetails({
      ...bannerDetails,
      cityData: Validations.validateMultiSelect(data),
    });
  };

  const isFormValid = () => {
    let isValid = true;
    isValid =
      isValid &&
      bannerDetails?.bannerName?.isValid &&
      bannerDetails?.bannerImg?.isValid &&
      bannerDetails?.startDate?.isValid &&
      bannerDetails?.endDate?.isValid &&
      bannerDetails?.type?.isValid &&
      (bannerDetails?.categotySelect?.isValid ||
        bannerDetails?.serviceSelect?.isValid) &&
      bannerDetails?.cityData?.isValid;

    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const formAction = () => {
    togglePristine(false);

    if (props.modalToShow === "ADD") {
      handleAdd();
    } else if (props.modalToShow === "EDIT") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    updateBannerDetails({
      ...bannerDetails,
      bannerImg: Validations.validateTextField(image),
    });
  };
  const Types = [
    {
      id: 1,
      text: "Categories",
    },
    {
      id: 2,
      text: "Services",
    },
  ];
  const handleAdvertisementType = (val) => {
    updateBannerDetails({
      ...bannerDetails,
      type: Validations.validateTextField(val),
    });
  };

  const handleServiceSelect = (val) => {
    const selectedServicesData = servicesData.filter((service) =>
      val.includes(service.id)
    );
    updateBannerDetails({
      ...bannerDetails,
      serviceSelect: Validations.validateMultiSelect(val),
      selectedServicesData: selectedServicesData,
    });
  };
  const handleCategorySelect = (val) => {
    updateBannerDetails({
      ...bannerDetails,
      categotySelect: Validations.validateMultiSelect(val),
    });
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onClick={formAction}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD"
          ? "Add Banner"
          : props.modalToShow === "VIEW"
          ? "View Banner Details"
          : "Edit Banner"
      }
      action={
        <>
          {props.modalToShow !== "VIEW" && (
            <>
              <Button type="default" shape={undefined} onClick={closeModal}>
                {intl.formatMessage({ id: "app.button.cancel" })}
              </Button>
              <Button
                loading={props.isLoading}
                type="primary"
                shape={undefined}
                onClick={formAction}
              >
                {intl.formatMessage({ id: "app.button.submit" })}
              </Button>
            </>
          )}
        </>
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.imageContainer}>
          <div ref={imageContainerRef} className={styles.imageContainerImage}>
            <CustomImage src={bannerDetails?.bannerImg?.value} />
            {props.modalToShow !== "VIEW" ? (
              <>
                <UploadButton
                  parentRef={imageContainerRef}
                  resultType="url"
                  type="fileUpload"
                  accept="image/*"
                  id="913fjhiu4ng2vri"
                  text="Upload Image(300x300)"
                  onChange={handleImageChange}
                />
                {!isPristine && !bannerDetails?.bannerImg?.isValid && (
                  <ErrorMessage text={bannerDetails?.bannerImg?.errMsg} />
                )}
              </>
            ) : (
              " "
            )}
          </div>

          <div className={styles.rightForm} style={{ width: "50%" }}>
            {
              <div className={styles.formItem}>
                <Label value="Advertisement Type" />
                <Select
                  showSearch
                  placeholder="Type"
                  disabled={props.modalToShow === "VIEW"}
                  onChange={handleAdvertisementType}
                  value={bannerDetails?.type?.value}
                >
                  {Types.map((obj) => {
                    return (
                      <Option key={obj?.text} value={obj?.text}>
                        {obj?.text}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            }
            {bannerDetails?.type?.value === "Categories" && (
              <div className={styles.formItem}>
                <Label value="Categories" />
                <Select
                  showSearch
                  disabled={props.modalToShow === "VIEW"}
                  placeholder="Select Categories"
                  mode="multiple"
                  value={bannerDetails?.categotySelect?.value}
                  onChange={handleCategorySelect}
                  maxTagCount={2}
                  maxTagTextLength={8}
                >
                  {CategoryDataset.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item?.title}
                    </Option>
                  ))}
                </Select>
                {!isPristine && !bannerDetails?.categotySelect?.isValid && (
                  <ErrorMessage selectOption />
                )}
              </div>
            )}

            {bannerDetails?.type?.value === "Services" ? (
              <div
                style={{ marginBottom: "margin-bottom: 8.51rem;" }}
                className={styles.formItem}
              >
                <Label value="Services" />
                <Select
                  placeholder="Select Service"
                  mode="multiple"
                  value={bannerDetails.serviceSelect}
                  onChange={handleServiceSelect}
                >
                  {servicesData.map((item) => (
                    <Select.Option key={item.id} value={item.title}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>

                {!isPristine && !bannerDetails?.serviceSelect?.isValid && (
                  <ErrorMessage selectOption />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Banner Name" />
            <Input
              placeholder="Banner Name"
              name="bannerName"
              disabled={props.modalToShow === "VIEW"}
              onChange={handleFieldChange}
              value={bannerDetails?.bannerName?.value}
            />
            {!isPristine && !bannerDetails?.bannerName?.isValid && (
              <ErrorMessage text={bannerDetails?.bannerName?.errMsg} />
            )}
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Start Date" />
            <DatePicker
              disabled={props.modalToShow === "VIEW"}
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              placeholderText="Start Date"
              className={styles.datePicker}
              dateFormat="MM-dd-yyyy hh:mm"
              selected={bannerDetails?.startDate?.value}
              onChange={handlbeStartDate}
            />
            {!isPristine && !bannerDetails?.startDate?.isValid && (
              <ErrorMessage text={bannerDetails?.startDate?.errMsg} />
            )}
          </div>
          <div className={styles.formItem}>
            <Label value="End Date" />
            <DatePicker
              disabled={props.modalToShow === "VIEW"}
              showYearDropdown
              showMonthDropdown
              // showTimeInput
              // dropdownMode="select"
              placeholderText="End Date"
              className={styles.datePicker}
              dateFormat="MM-dd-yyyy hh:mm"
              selected={bannerDetails?.endDate?.value}
              onChange={handlbeEndDate}
            />
            {!isPristine && !bannerDetails?.endDate?.isValid && (
              <ErrorMessage text={bannerDetails?.endDate?.errMsg} />
            )}
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}
const mapState = (state) => ({
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
});
export default connect(mapState)(AddEditPromoContainer);
