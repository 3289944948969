import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Select,
  Dropdown,
  Menu,
  Modal,
  Button,
  Input,
  Space,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import { toastr } from "react-redux-toastr";

import { checkRtl } from "../../shared/utils/rtl";

import sidebarData from "../../shared/constants/sidebar-data.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../shared/api/axiosConfig";
import modalActions from "../../redux/modal/modal-actions";
import userActions from "../../redux/user-info/user-actions";
import { Icon } from "../common/";
import history from "../../shared/utils/history";
import resetPass from "../../assets/images/reset-pass.png";
import resetSuccess from "../../assets/images/reset-scccess.png";

import placeholderImg from "../../assets/images/profile-placeholder.png";
import "./navbar.scss";
import ViewProfile from "../../auth/login/view-Profile";
import Item from "antd/lib/list/Item";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import {
  nestedParseJSON,
  removeEmptyKeys,
} from "../../shared/utils/object-manipulation";
import { branch } from "recompose";
import Support from "../../auth/login/help-support";
import { getUserName } from "../../shared/utils/local-storage";
import { useDispatch } from "react-redux";
import { hameBuggerIcon } from "../../shared/utils/icon-storage";
import OTPVerificationModal from "../../auth/login/verificationOTP";
import View from "../../auth/login/view";
import ForgetPassword from "../../containers/external/forget-password/forget-password";
const styles = {
  wrapper: "custom-navbar__wrapper",
  open: "custom-navbar__wrapper--open",
  closed: "custom-navbar__wrapper--closed",
  contentContainer: "custom-navbar__content-container",
  sidebarToggleButton: "custom-navbar__sidebar-toggle-button",
  navbarLeft: "custom-navbar__left-items-container",
  navbarRight: "custom-navbar__right-items-container",
  profileWrapper: "custom-navbar__right-items-container__profile-wrapper",
  profileInfo: "custom-navbar__right-items-container__profile-info",
  avatar: "custom-navbar__right-items-container__avatar",
  name: "custom-navbar__right-items-container__name",
  adminType: "custom-navbar__right-items-container__admin-type",
  hameBuggerIcon__: "hameBuggerIcon__",
};
const Navbar = ({ toggleSidebar, isSidebarExpanded, ...props }) => {
  const userInformationString = localStorage.getItem("userInformation");
  const userInformation = userInformationString
    ? JSON.parse(userInformationString)
    : {};

  const { firstName, email } = userInformation;
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const [modalControls, updatedmodalControl] = useState({
    isVisible: false,
    isLoading: false,
    modalType: "",
    data: {},
    update: "",
    heading: "",
  });
  // const [OTPmodalControls, updatedOTPmodalControl] = useState({
  //   isVisible: false,
  // });
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(modalActions.showModal("LOGOUT"));
    // setTimeout(() => {
    //   toast.info("Logout Sucessfull");
    // }, 1500);
  };
  const handleModalClose = () => {
    updatedmodalControl({
      ...modalControls,
      isVisible: false,
      modalType: "",
    });
  };
  const handleViewProfile = () => {
    updatedmodalControl({
      ...modalControls,
      isVisible: true,
      modalType: "VIEW",
      heading: "View User Info",
    });
  };
  const handleEmailUpdate = () => {
    updatedmodalControl({
      ...modalControls,
      isVisible: true,
      modalType: "EDIT",
      update: "email",
      heading: "Update Email and User Name",
    });
  };
  const handlePhoneNoUpdate = () => {
    updatedmodalControl({
      ...modalControls,
      isVisible: true,
      modalType: "EDIT",
      update: "phone",
      heading: "Update Phone No",
    });
  };
  const handleChangePassword = () => {
    updatedmodalControl({
      ...modalControls,
      isVisible: true,
      modalType: "EDIT",
      update: "password",
      heading: "Change Password",
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getMenu = () => {
    return (
      <Menu>
        <Menu.Item onClick={handleViewProfile}>View Profile</Menu.Item>
        {/* <Menu.Item onClick={handleEmailUpdate}>Update Email</Menu.Item>
        <Menu.Item onClick={handlePhoneNoUpdate}>Update Phone no</Menu.Item> */}
        {/* <Menu.Item onClick={showModal}>Reset Password</Menu.Item> */}

        <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
      </Menu>
    );
  };

  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        [styles.open]: isSidebarExpanded,
        [styles.closed]: !isSidebarExpanded,
      })}
    >
      <div className={styles.contentContainer}>
        <div className={styles.hameBuggerIcon__} onClick={toggleSidebar}>
          {hameBuggerIcon}
        </div>

        <div className={styles.navbarRight}>
          <div className={styles.profileWrapper}>
            <div className={styles.profileInfo}>
              <div className={styles.name}>
                <>
                  <Dropdown overlay={getMenu()}>
                    <span> {firstName}</span>
                  </Dropdown>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>

      <View />
      {/* <ForgetPassword /> */}
      {/* <Modal
        title="Reset Your Password"
        open={isModalOpen}
        onOk={handleOk}
        // onCancel={handleCancel}
      >
        <img
          src={resetPass}
          alt=""
          style={{ maxWidth: "40%", marginLeft: "9rem", marginBottom: "2rem" }}
        />
        <br />
        <span> Enter Old Password</span>
        <Input placeholder="input password" style={{ marginBottom: "3rem" }} />
        Enter New Password
        <Input placeholder="input password" />
      </Modal> */}
      <ViewProfile
        isVisible={modalControls?.isVisible}
        onClose={handleModalClose}
        onCloseIconClick={handleModalClose}
        modalToShow={modalControls?.modalType}
        data={modalControls?.data}
        isLoading={modalControls?.isLoading}
        updateData={modalControls?.update}
        heading={modalControls?.heading}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.user.locale,
  userInfo: state.user.userInfo,
  panelType: state.user.enterprice.panelType,
  isRTL: checkRtl(state.user.locale),

  enabledVerticals: state.user.enterprice.enabledVerticals
    ? JSON.parse(state.user.enterprice.enabledVerticals)
    : [],
});

export default connect(mapStateToProps)(Navbar);
