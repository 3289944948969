import React, { PureComponent } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, Legend,ResponsiveContainer
} from 'recharts';
import {CHART_COLORS} from '../../../../shared/constants/orders';


export default class RecordsLineChart extends PureComponent {

  render() {
    return (
        <ResponsiveContainer aspect={2} width="99%">
      <AreaChart
        // width={500}
        // height={300}
        data={this.props.data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 0" />
        <XAxis angle={-30} dataKey="date" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="Total Pending Orders" stroke={CHART_COLORS.totalPendingOrders} fill={CHART_COLORS.totalPendingOrders} />
        <Area type="monotone" dataKey="Total Delivered Orders" stroke={CHART_COLORS.totalDeliveredOrders} fill={CHART_COLORS.totalDeliveredOrders} />
        <Area type="monotone" dataKey="Total Accepted Orders" stroke={CHART_COLORS.totalAcceptedOrders} fill={CHART_COLORS.totalAcceptedOrders} />
        <Area type="monotone" dataKey="Total Cancelled Orders" stroke={CHART_COLORS.totalCancelledOrders} fill={CHART_COLORS.totalCancelledOrders} />
      </AreaChart>
      </ResponsiveContainer>
    );
  }
}
