import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";
import axios from "../../../../shared/api/axiose";

import { Input, Select, Button, DatePicker, Radio } from "antd";
import { ErrorMessage } from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";

import "./form.scss";

const styles = {
  wrapper: "order-reports__form__wrapper",
  content: "order-reports__form__content",
  formRadioItem: "custom-form-item--radio",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  radioWrapper: "order-reports__form__radio-wrapper",
};
const { Option } = Select;
function Form(props) {
  const intl = useIntl();
  const [startDate, updateStartDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).startDate
  );
  const [endDate, updateEndDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).endDate
  );
  const [store, changeStore] = useState("");
  const [reportType, changeReportType] = useState(0);
  const [isPristine, toggleIsPristine] = useState(true);
  const handleStartDateChange = (date) => {
    const dates = Validations.validateStartEndDate(date, endDate.value);
    updateStartDate(dates.startDate);
    updateEndDate(dates.endDate);
  };

  const handleEndDateChange = (date) => {
    const dates = Validations.validateStartEndDate(startDate.value, date);
    updateStartDate(dates.startDate);
    updateEndDate(dates.endDate);
  };
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const isFormValid = () => {
    toggleIsPristine(false);
    return startDate.isValid && endDate.isValid;
  };

  useEffect(() => {
    if (props.panelType != 0) {
      changeStore(props.branchId);
    }
  }, [props.branchId]);

  const getFormData = () => ({
    startDate: moment(startDate.value).format("YYYY-MM-DD"),
    endDate: moment(endDate.value).format("YYYY-MM-DD"),
    reportType: reportType,
  });
  const handleSubmit = async () => {
    if (isFormValid()) {
      const formData = getFormData();

      // Map the reportType integer to its string representation
      const reportTypeMap = {
        0: "day",
        1: "week",
        2: "month",
      };
      formData.reportType = reportTypeMap[formData.reportType];

      const params = new URLSearchParams(formData);

      try {
        const response = await axios.get("/admins/reports/getBookingReports", {
          params: params,
        });

        console.log("API Response:", response.data);
      } catch (error) {
        // Handle API errors
        console.error("API Error:", error);
      }
    }
  };

  function disabledDate(current) {
    return current && current.valueOf() > Date.now();
  }

  const handleReportTypeChange = (e) => {
    changeReportType(e.target.value);
  };
  console.log("reportType", reportType);
  return (
    <div className={styles.wrapper}>
      <div className={styles.formGroup}>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({
              id: "app.field.startDate",
            })}
          </label>
          <DatePicker
            value={startDate.value}
            placeholder={intl.formatMessage({ id: "app.placeholder.date" })}
            onChange={handleStartDateChange}
            disabledDate={disabledDate}
          />
          {!isPristine && <ErrorMessage field={startDate} />}
        </div>
        <div className={styles.formItem}>
          <label>
            {intl.formatMessage({
              id: "app.field.endDate",
            })}
          </label>
          <DatePicker
            value={endDate.value}
            disabledDate={disabledDate}
            placeholder={intl.formatMessage({ id: "app.placeholder.date" })}
            onChange={handleEndDateChange}
          />
          {!isPristine && <ErrorMessage field={endDate} />}
        </div>
      </div>
      <div className={styles.formGroup}>
        {props.panelType == 0 && (
          <div className={styles.formItem}>
            <label>
              {intl.formatMessage({
                id: "app.field.filterBy",
              })}
            </label>
            <Select
              showSearch
              filterOption={filterOption}
              placeholder={intl.formatMessage({
                id: "app.placeholder.store",
              })}
              onChange={changeStore}
              value={store}
            >
              <Option value="">
                {intl.formatMessage({ id: "app.field.allStores" })}
              </Option>
              {props.stores.map((store) => (
                <Option key={store.branchId} value={store.branchId}>
                  {store.branchName}
                </Option>
              ))}
            </Select>
          </div>
        )}
        <div className={classNames([styles.formItem, styles.formRadioItem])}>
          <label htmlFor="">
            {intl.formatMessage({
              id: "app.field.reportType",
            })}
          </label>
          <div className={styles.radioWrapper}>
            <Radio.Group
              onChange={handleReportTypeChange}
              value={reportType}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Radio value={0}>
                {intl.formatMessage({
                  id: "app.field.dailyReport",
                })}
              </Radio>
              <Radio value={1}>
                {intl.formatMessage({
                  id: "app.field.weeklyReport",
                })}
              </Radio>
              <Radio value={2}>
                {intl.formatMessage({
                  id: "app.field.monthlyReport",
                })}
              </Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className={styles.formAction}>
        <Button
          loading={props.isLoading}
          onClick={handleSubmit}
          type="primary"
          shape={undefined}
        >
          {" "}
          {intl.formatMessage({
            id: "app.button.submit",
          })}
        </Button>
      </div>
    </div>
  );
}

export default Form;
