import React, { useState, useEffect } from "react";
import { Popconfirm, Tooltip, Switch, Menu, Dropdown, Input } from "antd";

import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";
import axios from "../../../../shared/api/axiose";

import {
  Panel,
  Icon,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../../../components/common";
import "./active-promo-table.scss";
import { connect } from "react-redux";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  stickyAction: "sticky-action",
  actionIcon: "custom-table__action-icon",
  panelContainer: "active-promo-table__panel-container",
  wrapper: "active-promo__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "active-promo__menu--left",
  menuRight: "active-promo__menu--right",
  pageSize: "active-promo__records-per-page",
};

function ActivePromoTable(props) {
  console.log("ActivePromoTable banner => ", props.data);
  const intl = useIntl();
  const [banners, setBanners] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const handleSearch = (value) => {
    console.log("Search", value);
    if (value.trim() !== "") {
      setSearchQuery(value);
    } else {
      setSearchQuery("");
    }
  };

  const fetchBanners = async () => {
    setIsLoading(true);
    try {
      const queryParams = searchQuery ? { search: searchQuery } : {};
      const response = await axios.get("/banners", { params: queryParams });
      const { data } = response.data;
      setBanners(data.banners);
      console.log("bannerdata", response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchBanners();
  }, [searchQuery]);
  const Switchbtn = (item) => {
    // Your logic for handling the switch button
  };

  const columns = [
    {
      fixed: "left",
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.sNo" }),
      render: (item, object, index) => {
        return (
          <span>
            {props.pagination.pageSize * (props.pagination.current - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      dataIndex: "title",
      title: "Banner Name",
      fixed: "left",
    },
    {
      dataIndex: "imgURL",
      title: "Banner Cover",
      render: (item) => <CustomImage src={item} isTable />,
    },
    {
      dataIndex: "startDate",
      title: intl.formatMessage({ id: "app.field.startDate" }),
      render: (item) => moment(item).format("MMMM Do YYYY"),
    },
    {
      dataIndex: "endDate",
      title: intl.formatMessage({ id: "app.field.expiryDate" }),
      render: (item) => moment(item).format("MMMM Do YYYY"),
    },
    {
      dataIndex: "isActive",
      title: "Status",
      render: (item) => {
        return <CustomTag isEnabled={item} />;
      },
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const getMenu = (item) => {
    const handleDeleteBanner = () => {
      // const requestBody = {
      //   isDeleted: true,
      // };

      axios
        .delete(`/banners/${item.id}`)
        .then((res) => {
          if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
            toast.success(res?.data?.message);
            fetchBanners();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            props.handleView(item);
          }}
        >
          View
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            props.editItem(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        <Menu.Item onClick={handleDeleteBanner}>
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };
  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  // const imgStyle = {
  //   width: isHovered ? "43px" : "40px",
  //   height: isHovered ? "43px" : "40px",
  //   transition: "all 0.3s ease-in-out",
  // };

  return (
    <>
      <div className={styles.menu}>
        <Input
          placeholder="Search banners"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200 }}
        />
        <span onClick={fetchBanners} className="reload-button">
          Reload
          <ReloadOutlined style={{ marginLeft: "5px" }} />
        </span>
      </div>
      <CustomTable
        dataSource={banners}
        pagination={props.pagination}
        onChange={props.onChange}
        columns={columns}
        isLoading={isLoading}
        fetchBanners={fetchBanners}
      />
      <div style={{ display: "none" }}>
        <a href="https://icons8.com/icon/7JJpHLz4UBIH/sync">Sync</a> icon by
        <a href="https://icons8.com">Icons8</a>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  panelType: state.user.enterprice.panelType,
});

export default connect(mapStateToProps)(ActivePromoTable);
