import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  Label,
} from "../../../../components/common";
import { Button, Input, Select, Radio, Table, Checkbox } from "antd";
import { connect } from "react-redux";

import Validations from "../../../../shared/validations/Validations";
import countryCodes from "../../../../shared/constants/countries.json";

import { toastr } from "react-redux-toastr";
import "./add-edit-roles.scss";
import axios from "../../../../shared/api/axiosConfig";

import { checkRtl } from "../../../../shared/utils/rtl";

import classNames from "classnames";
const { Option } = Select;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
  // formHeader: "add-update-user-modal__",
};
function AddupdateRoles({ rolesData, ...props }) {
  const [privilages, changePrivilages] = useState(
    Validations.validateMultiSelect([])
  );
  const [roleName, setRoleName] = useState(Validations.validateTextField(""));

  console.log("privilages ", privilages);
  const [data, setData] = useState([
    {
      name: "city",
      read: false,
      update: false,
      delete: false,
      create: false,
      title: "City",
    }, // need to update in roles API

    {
      name: "admin",
      read: false,
      update: false,
      delete: false,
      create: false,
      title: "Role Management",
    },
    {
      name: "customer",
      read: false,
      update: false,
      delete: false,
      create: false,
      title: "Customer",
    },

    {
      name: "customer_to_sp",
      title: "Customer to SP Reviews",
      read: false,
      update: false,
      delete: false,
      create: false,
    }, // need to update in roles API
    {
      name: "sp_to_customer",
      title: "SP to Customer Reviews ",
      read: false,
      update: false,
      delete: false,
      create: false,
    }, // need to update in roles API

    {
      name: "category",
      title: "Category",
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    {
      name: "service",
      title: "Service",
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    {
      name: "business_owner",
      title: "Business Owner",
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    {
      name: "service_provider",
      title: "Service Provider",
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    {
      name: "general_faq",
      title: "General FAQs",
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    {
      name: "order",
      title: "Order",
      read: false,
      update: false,
      delete: false,
      create: false,
    },

    {
      name: "promo_codes",
      title: "Promo Codes",
      read: false,
      update: false,
      delete: false,
      create: false,
    }, // need to update in roles API
    {
      name: "push_notification",
      title: "Push Notification",
      read: false,
      update: false,
      delete: false,
      create: false,
    }, // need to update in roles API

    {
      name: "banners",
      title: "Banner",
      read: false,
      update: false,
      delete: false,
      create: false,
    }, // need to update in roles API
    {
      name: "training_video",
      title: "Training Video",
      read: false,
      update: false,
      delete: false,
      create: false,
    }, // need to update in roles API

    {
      name: "report",
      title: "Report",
      read: false,
      update: false,
      delete: false,
      create: false,
    }, // need to update in roles API
    {
      name: "settings",
      title: "settings",
      read: false,
      update: false,
      delete: false,
      create: false,
    },
  ]);

  // {
  //   "data" : [
  //     {
  //       "id": 1,
  //       "module_uid": "customer.read",
  //       "module": "customer",
  //       "sub_module": "read",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 2,
  //       "module_uid": "customer.create",
  //       "module": "customer",
  //       "sub_module": "create",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 3,
  //       "module_uid": "customer.update",
  //       "module": "customer",
  //       "sub_module": "update",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 4,
  //       "module_uid": "customer.delete",
  //       "module": "customer",
  //       "sub_module": "delete",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 5,
  //       "module_uid": "admin.read",
  //       "module": "admin",
  //       "sub_module": "read",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 6,
  //       "module_uid": "admin.create",
  //       "module": "admin",
  //       "sub_module": "create",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 7,
  //       "module_uid": "admin.update",
  //       "module": "admin",
  //       "sub_module": "update",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 8,
  //       "module_uid": "admin.delete",
  //       "module": "admin",
  //       "sub_module": "delete",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 9,
  //       "module_uid": "service_provider.read",
  //       "module": "service_provider",
  //       "sub_module": "read",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 10,
  //       "module_uid": "service_provider.create",
  //       "module": "service_provider",
  //       "sub_module": "create",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 11,
  //       "module_uid": "service_provider.update",
  //       "module": "service_provider",
  //       "sub_module": "update",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 12,
  //       "module_uid": "service_provider.delete",
  //       "module": "service_provider",
  //       "sub_module": "delete",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 13,
  //       "module_uid": "business_owner.read",
  //       "module": "business_owner",
  //       "sub_module": "read",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 14,
  //       "module_uid": "business_owner.create",
  //       "module": "business_owner",
  //       "sub_module": "create",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 15,
  //       "module_uid": "business_owner.update",
  //       "module": "business_owner",
  //       "sub_module": "update",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 16,
  //       "module_uid": "business_owner.delete",
  //       "module": "business_owner",
  //       "sub_module": "delete",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 17,
  //       "module_uid": "category.read",
  //       "module": "category",
  //       "sub_module": "read",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 18,
  //       "module_uid": "category.create",
  //       "module": "category",
  //       "sub_module": "create",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 19,
  //       "module_uid": "category.update",
  //       "module": "category",
  //       "sub_module": "update",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 20,
  //       "module_uid": "category.delete",
  //       "module": "category",
  //       "sub_module": "delete",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 21,
  //       "module_uid": "service.read",
  //       "module": "service",
  //       "sub_module": "read",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 22,
  //       "module_uid": "service.create",
  //       "module": "service",
  //       "sub_module": "create",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 23,
  //       "module_uid": "service.update",
  //       "module": "service",
  //       "sub_module": "update",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 24,
  //       "module_uid": "service.delete",
  //       "module": "service",
  //       "sub_module": "delete",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 25,
  //       "module_uid": "order.read",
  //       "module": "order",
  //       "sub_module": "read",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 26,
  //       "module_uid": "order.create",
  //       "module": "order",
  //       "sub_module": "create",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 27,
  //       "module_uid": "order.update",
  //       "module": "order",
  //       "sub_module": "update",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 28,
  //       "module_uid": "order.delete",
  //       "module": "order",
  //       "sub_module": "delete",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 29,
  //       "module_uid": "payment.read",
  //       "module": "payment",
  //       "sub_module": "read",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 30,
  //       "module_uid": "payment.create",
  //       "module": "payment",
  //       "sub_module": "create",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 31,
  //       "module_uid": "payment.update",
  //       "module": "payment",
  //       "sub_module": "update",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 32,
  //       "module_uid": "payment.delete",
  //       "module": "payment",
  //       "sub_module": "delete",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 33,
  //       "module_uid": "settings.read",
  //       "module": "settings",
  //       "sub_module": "read",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 34,
  //       "module_uid": "settings.create",
  //       "module": "settings",
  //       "sub_module": "create",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 35,
  //       "module_uid": "settings.update",
  //       "module": "settings",
  //       "sub_module": "update",
  //       "createdAt": null,
  //       "updatedAt": null
  //     },
  //     {
  //       "id": 36,
  //       "module_uid": "settings.delete",
  //       "module": "settings",
  //       "sub_module": "delete",
  //       "createdAt": null,
  //       "updatedAt": null
  //     }
  //   ]
  // }

  const [privileges, setPrivileges] = useState({
    city: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    role_management: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    customer: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    customer_to_sp_rereads: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    sp_to_customer_rereads: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    category: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    service: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    business_owners: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    service_providers: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    service_booking: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    promo_codes: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    push_notification: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    banner: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    questionnaire: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    report: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
    settings: {
      read: false,
      update: false,
      delete: false,
      create: false,
    },
  });
  const columns = [
    { title: "Module", dataIndex: "title", key: "name" },
    { title: "Create", dataIndex: "create", key: "create" },
    { title: "Read", dataIndex: "read", key: "read" },
    { title: "Update", dataIndex: "update", key: "update" },
    { title: "Delete", dataIndex: "delete", key: "delete" },
  ];

  const [isPristine, changeIsPristine] = useState(true);

  const setForm = () => {
    console.log("getFilterRoles setform => ", props?.data);
    // console.log("getFilterRoles setform => rolesData ", rolesData);
    let updateData = [
      {
        name: "city",
        read: false,
        update: false,
        delete: false,
        create: false,
        title: "City",
      }, // need to update in roles API

      {
        name: "admin",
        read: false,
        update: false,
        delete: false,
        create: false,
        title: "Role Management",
      },
      {
        name: "customer",
        read: false,
        update: false,
        delete: false,
        create: false,
        title: "Customer",
      },

      {
        name: "customer_to_sp",
        title: "Customer to SP Reviews",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API
      {
        name: "sp_to_customer",
        title: "SP to Customer Reviews ",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API

      {
        name: "category",
        title: "Category",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "service",
        title: "Service",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "business_owner",
        title: "Business Owner",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "service_provider",
        title: "Service Provider",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "general_faq",
        title: "General FAQs",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "order",
        title: "Order",
        read: false,
        update: false,
        delete: false,
        create: false,
      },

      {
        name: "promo_codes",
        title: "Promo Codes",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API
      {
        name: "push_notification",
        title: "Push Notification",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API

      {
        name: "banners",
        title: "Banner",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API
      {
        name: "training_video",
        title: "Training Video",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API

      {
        name: "report",
        title: "Report",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API
      {
        name: "settings",
        title: "settings",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
    ];

    // let getFilterRoles = rolesData.filter((obj) => {
    //   return props?.data?.roles ?  (props?.data?.roles).includes((obj?.module_uid)) : ""
    // })
    // console.log("getFilterRoles => ", getFilterRoles)

    if (props?.data?.roles) {
      (props?.data?.roles).map((obj) => {
        handleUpdateStates(obj, updateData);
      });
      setData(updateData);
    }
    setRoleName(Validations.validateTextField(props?.data?.title));
  };

  const handleUpdateStates = (obj, updateData) => {
    updateData.forEach((data) => {
      if (data.name === obj?.module) {
        data[obj?.sub_module] = true;
      }
    });
  };

  const resetForm = () => {
    setData([
      {
        name: "city",
        read: false,
        update: false,
        delete: false,
        create: false,
        title: "City",
      }, // need to update in roles API

      {
        name: "admin",
        read: false,
        update: false,
        delete: false,
        create: false,
        title: "Role Management",
      },
      {
        name: "customer",
        read: false,
        update: false,
        delete: false,
        create: false,
        title: "Customer",
      },

      {
        name: "customer_to_sp",
        title: "Customer to SP Reviews",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API
      {
        name: "sp_to_customer",
        title: "SP to Customer Reviews ",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API

      {
        name: "category",
        title: "Category",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "service",
        title: "Service",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "business_owner",
        title: "Business Owner",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "service_provider",
        title: "Service Provider",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "general_faq",
        title: "General FAQs",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
      {
        name: "order",
        title: "Order",
        read: false,
        update: false,
        delete: false,
        create: false,
      },

      {
        name: "promo_codes",
        title: "Promo Codes",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API
      {
        name: "push_notification",
        title: "Push Notification",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API

      {
        name: "banners",
        title: "Banner",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API
      {
        name: "training_video",
        title: "Training Video",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API

      {
        name: "report",
        title: "Report",
        read: false,
        update: false,
        delete: false,
        create: false,
      }, // need to update in roles API
      {
        name: "settings",
        title: "settings",
        read: false,
        update: false,
        delete: false,
        create: false,
      },
    ]);
    setRoleName(Validations.validateTextField(""));
  };

  useEffect(() => {
    if (props.isVisible) {
      if (props.modalToShow === "ADD") {
        console.log(props.data);
        resetForm();
      } else {
        // resetForm();
        setForm();
      }
    }
  }, [props.isVisible]);

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const handleRolesMap = (obj, rolesArr) => {
    let moduleLevel_privale = rolesData.filter((obj1) => {
      return obj1.module === obj.name;
    });

    console.log("moduleLevel_privale obj  => ", obj);

    if (obj?.read) {
      let module_data = moduleLevel_privale.filter((data) => {
        return data?.sub_module === "read";
      });
      if (module_data[0]) {
        rolesArr.push(module_data[0]?.id);
      }
    }
    if (obj?.update) {
      let module_data = moduleLevel_privale.filter((data) => {
        return data?.sub_module === "update";
      });
      if (module_data[0]) {
        rolesArr.push(module_data[0]?.id);
      }
    }
    if (obj?.delete) {
      let module_data = moduleLevel_privale.filter((data) => {
        return data?.sub_module === "delete";
      });
      if (module_data[0]) {
        rolesArr.push(module_data[0]?.id);
      }
    }
    if (obj?.create) {
      let module_data = moduleLevel_privale.filter((data) => {
        return data?.sub_module === "create";
      });
      if (module_data[0]) {
        rolesArr.push(module_data[0]?.id);
      }
    }
  };
  const handleAdd = (roles) => {
    let payload = {
      title: roleName.value,
      roles: roles,
    };
    axios
      .post("/role-groups", payload)
      .then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 200) {
          toastr.success(res?.data?.message);

          props.getAdmin_Types();

          console.log("role-group => ", res?.data);
        } else {
          toastr.error(res?.data?.message);
        }
        props.onCloseIconClick();
      })
      .catch((err) => {
        console.log(err);
        props.onCloseIconClick();
      });
  };
  const handleEdit = (roles) => {
    let payload = {
      title: roleName.value,
      roles: roles,
    };
    axios
      .put(`/role-groups/${props?.data?.id}`, payload)
      .then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 200) {
          toastr.success(res?.data?.message);

          props.getAdmin_Types();

          console.log("role-group => ", res?.data);
        } else {
          toastr.error(res?.data?.message);
        }
        props.onCloseIconClick();
      })
      .catch((err) => {
        console.log(err);
        props.onCloseIconClick();
      });
  };

  const formAction = () => {
    console.log("Data => ", data);
    let roles = [];
    data.map((obj) => {
      handleRolesMap(obj, roles);
    });

    if (props?.modalToShow === "ADD") {
      handleAdd(roles);
    } else {
      handleEdit(roles);
    }
  };

  const isFormValid = () => {
    let isValid = true;

    return isValid;
  };

  const handleRoleNameChange = (e) => {
    setRoleName(Validations.validateTextField(e.target.value));
  };

  const handleCheckboxChange = (checked, rowIndex, privilege, name) => {
    const newData = [...data];
    newData[rowIndex][privilege] = checked;

    setData(newData);
  };

  const dataSource = data.map((module, index) => ({
    ...module,
    create:
      module.name !== "push_notification" ? (
        <Checkbox
          disabled={props.modalToShow === "VIEW"}
          checked={module.create}
          onChange={(e) =>
            handleCheckboxChange(e.target.checked, index, "create", module.name)
          }
        />
      ) : (
        "-"
      ),
    read: (
      <Checkbox
        checked={module.read}
        disabled={props.modalToShow === "VIEW"}
        onChange={(e) =>
          handleCheckboxChange(e.target.checked, index, "read", module.name)
        }
      />
    ),
    update: (
      <Checkbox
        checked={module.update}
        disabled={props.modalToShow === "VIEW"}
        onChange={(e) =>
          handleCheckboxChange(e.target.checked, index, "update", module.name)
        }
      />
    ),

    delete:
      module.name !== "push_notification" ? (
        <Checkbox
          checked={module.delete}
          disabled={props.modalToShow === "VIEW"}
          onChange={(e) =>
            handleCheckboxChange(e.target.checked, index, "delete", module.name)
          }
        />
      ) : (
        "-"
      ),
  }));

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}

      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD"
          ? "Create New Role"
          : props.modalToShow === "EDIT"
          ? "Edit Roles"
          : "View Roles Details"
      }
      action={
        props.modalToShow !== "VIEW" ? (
          <>
            <Button type="default" shape={undefined} onClick={closeModal}>
              <FormattedMessage id="app.field.cancel" />
            </Button>
            <Button
              type="primary"
              loading={props.isLoading}
              shape={undefined}
              onClick={formAction}
            >
              <FormattedMessage id="app.field.submit" />
            </Button>
          </>
        ) : (
          ""
        )
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Role Name" />
            <Input
              name="roleName"
              disabled={props.modalToShow === "VIEW"}
              value={roleName?.value}
              onChange={handleRoleNameChange}

              // onChange={handleEmailChange}
              // value={email.value}
              // placeholder={intl.formatMessage({
              //   id: "app.placeholder.email",
              // })}
            />
            {/* {<ErrorMessage field={email} />} */}
          </div>
          <div className={styles.formItem}> </div>
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey={(record) => record.name}
            // onRow={(record) => (

            // )}
            // rowClassName={(record) =>
            //   selectedPrivileges.every((privilege) => record[privilege])
            //     ? "selected-row"
            //     : ""
            // }
          />
        </div>
      </Panel>
    </CustomModal>
  );
}

const mapStateToProps = (state) => ({
  enabledPanels: state.user.enterprice.enabledPanels,
  isAddDisabled: state.user.isAddDisabled,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(injectIntl(AddupdateRoles));
