import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Select,
  Dropdown,
  Button,
  Menu,
  Tag,
  Tooltip,
  DatePicker,
  Spin,
} from "antd";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import { CustomerData } from "../../FakeData";
import { greenTick } from "../../shared/utils/icon-storage";
import { DateRangePicker } from "react-date-range";
import AddEditQuestion from "./components/add-edit-user/add-edit-question";
import AddEditUser from "./components/add-edit-user/add-edit-user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import icons from "../../components/common/icon/icons";

import {
  Header,
  PaginationInfo,
  DelayedInput,
  ConfirmModal,
  CustomTable,
  CustomTag,
  Label,
} from "../../components/common";

import { toastr } from "react-redux-toastr";
import {
  nestedParseJSON,
  removeEmptyKeys,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiose";
import axiosConfig from "../../shared/api/axiosConfig";
import dayjs from "dayjs";
import "./customers.scss";
import Validations from "../../shared/validations/Validations";
import { dateFormat } from "../../shared/utils/local-storage";
import AddEditQuestionCustomer from "../customers/components/add-edit-user/add-edit-question-customer";
import AddEditService from "../service/components/add-edit-modal/add-edit-service";
import ViewUser from "../role-management/view-user/view-user";
import ViewCustomer from "./components/add-edit-user/view-customer";

const styles = {
  wrapper: "customers__wrapper",
  menu: "custom-menu",
  section: "custom-section",
  pageSize: "customers__menu__records-per-page",
  filterCont: "filterCont",
  VerifiedEmail_cont: "VerifiedEmail_cont",
};

const { Option } = Select;

function Customers(props) {
  const intl = useIntl();

  // Instaservice States and function  ======================================================
  const { RangePicker } = DatePicker;
  const [isBlockedFilter, setIsBlockedFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [isQuestionAdded, setIsQuestionAdded] = useState(null);
  const [isAllAnswerSubmitted, SetAllAnswerSubmitted] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [search, updateSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [rangePickerFilter, setRangePickerFilter] = useState([]);
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    modalType: "",
    type: "",
  });
  const [viewModal, updateViewModal] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    modalType: "",
    type: "",
  });
  // const [FAQ_question, updateFAQ_question] = useState([{}]);
  // const [forms, setForms] = useState([{}]);
  // const questionType = [
  //   {
  //     value: "drop-down",
  //     text: "Drop Down",
  //   },
  //   {
  //     value: "radio-button",
  //     text: "Radio Button",
  //   },
  // ];
  const [modalControl, updateModalControl] = useState({
    isVisible: false,
    type: "",
    data: [],
    isVisible: false,
    isLoading: false,
  });
  const [modalQuestion, updateModalQuestion] = useState({
    isVisible: false,
    type: "",
    data: [],
    isVisible: false,
    isLoading: false,
  });
  const [modalview, updateModalView] = useState({
    isVisible: false,
    type: "",
    data: [],
    isVisible: false,
    isLoading: false,
  });
  const [searchDelayed, updateSearchDelayed] = useState("");

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });
  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const handleModalClose = () => {};

  const closeModal = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };
  const closeModale = () => {
    updateModalControl({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
      isLoading: "true",
    });
  };
  let debounceTimer;
  function debounce(func, delay) {
    // Clear any previously set debounce timer

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer
    debounceTimer = setTimeout(() => {
      debounceTimer = null;
      func();
    }, delay);
  }
  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const openAddPromoModal = () => {
    updateModalControl({
      ...modalControl,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "",
      setIsLoading: "true",
    });
  };

  const closeQuestion = () => {
    updateModalQuestion({
      ...modalQuestion,
      isDeleteModalVisible: false,
      isVisible: false,
      type: "",
    });
  };
  const handleSearch = (val) => {
    getCustomersData();
  };
  const getCustomersData = (message) => {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: true,
    });

    let apiUrl = "/admins/getAllCustomers";
    if (isBlockedFilter === "true") {
      apiUrl += "?isBlocked=true";
    } else if (isBlockedFilter === "false") {
      apiUrl += "?isBlocked=false";
    }

    axios
      .get(apiUrl)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          const { data } = res.data;
          console.log("datalog", data);
          updateTableControls({
            ...tableControls,
            isEmpty: data.length === 0,
            isLoading: false,
            data: data?.data,
            count: data?.length,
          });
          if (message) {
            toastr.success(message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        updateTableControls({
          ...tableControls,
          isEmpty: false,
          isLoading: false,
        });
      });

    updatePagination({
      ...pagination,
    });
  };

  useEffect(() => {
    getCustomersData();
  }, [isBlockedFilter]);

  const handleFilterChange = (value) => {
    setIsBlockedFilter(value === "ALL" ? null : value);
  };
  const openViewModal = (data) => {
    console.log("openViewModal", data);
    updateModalControls({
      ...modalControls,
      isViewModalVisible: true,
      data,
    });
  };
  const handleViewModalClose = () => {
    updateViewModal({
      ...viewModal,
      isViewModalVisible: false,
    });
  };
  const handleCustomerEdit = (data) => {
    updateModalControls({
      ...modalControls,
      modalType: "EDIT",
      isVisible: true,
      data: data,
    });
  };
  const handleViewCustomer = (data) => {
    updateModalControls({
      ...modalControls,
      modalType: "VIEW",
      isVisible: true,
      data: data,
    });
  };
  const customerData = () => {};

  const getMenu = (item) => {
    const handleDeleteCustomer = () => {
      const requestBody = {
        isDeleted: true,
      };

      axios
        .put(`/admins/deleteCustomer/${item.customerId}`, requestBody)
        .then((res) => {
          if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
            toast.success(res?.data?.message);
            getCustomersData();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const handleEnableDisableCustomer = (customerId, isBlocked) => {
      const requestBody = {
        isBlocked: isBlocked,
      };

      axios
        .put(`/admins/enableDisableCustomer/${customerId}`, requestBody)
        .then((res) => {
          if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
            toast.success(res?.data?.message);
            getCustomersData();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const handleViewModalClose = () => {
      updateModalControls({
        ...modalControls,
        isViewModalVisible: false,
      });
      axios
        .get(`/admins/getCustomerById/${item.customerId}`)
        .then((res) => {
          if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
            // toast.success(res?.data?.message);
            getCustomersData();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const openAddQuestion = (customerId) => {
      setIsLoading(true);
      updateModalQuestion({
        ...modalQuestion,
        isDeleteModalVisible: false,
        isVisible: true,
        type: "",
      });
      axios
        .get(`/admins/getCustomerById/${customerId}`)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
            const {
              message,
              customerId,
              isQuestionAdded,
              isAllAnswerSubmitted,
            } = res.data.data;
            toast.success(message);
            setCustomerId(customerId);
            // setIsLoading(false);
            // console.log("select", customerId);

            // Send isQuestionAdded to the component as a prop
            setIsQuestionAdded(isQuestionAdded);
            SetAllAnswerSubmitted(isAllAnswerSubmitted);
          } else {
            toast.error(res?.data?.data?.message);
          }
        })
        .catch((err) => {
          // setIsLoading(false);
          console.log(err);
        });
    };

    return (
      <Menu>
        <Menu.Item onClick={() => handleViewCustomer(item)}>View</Menu.Item>

        <Menu.Item onClick={() => handleCustomerEdit(item)}>Edit</Menu.Item>

        {/* <Menu.Item onClick={() => {}}>Send OTP</Menu.Item> */}

        {item.isBlocked ? (
          <Menu.Item
            onClick={() => handleEnableDisableCustomer(item.customerId, false)}
          >
            Unblock
          </Menu.Item>
        ) : (
          ""
        )}

        {!item.isBlocked ? (
          <Menu.Item
            onClick={() => handleEnableDisableCustomer(item.customerId, true)}
          >
            {intl.formatMessage({ id: "app.field.block" })}
          </Menu.Item>
        ) : (
          ""
        )}

        <Menu.Item onClick={handleDeleteCustomer}>
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
        <Menu.Item onClick={() => openAddQuestion(item?.customerId)}>
          Add Questionnaire
        </Menu.Item>
      </Menu>
    );
  };

  const handleTableChange = (pag, filt, sorter) => {
    updatePagination({
      ...pag,
    });
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: "left",
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      title: "Profile Pic",
      dataIndex: "profilePicURL",
      render: (text, record) => (
        <img
          src={text}
          alt={`Profile of ${record.username}`}
          style={{ maxWidth: "50px" }}
        />
      ),
    },
    {
      title: "Full Name",
      render: (record) => {
        const { firstName, lastName } = record;
        return (
          <span
            style={{ color: " #1890ff", fontWeight: "600" }}
          >{`${firstName} ${lastName}`}</span>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "username",
      render: (username) => {
        return (
          <Tag color="#5FAAFF" style={{ width: "9rem" }}>
            {username}
          </Tag>
        );
      },
    },
    {
      title: "Questionnaire Status",
      dataIndex: "isQuestionAdded",
      render: (isQuestionAdded) => {
        return (
          <Tag
            style={{ width: "150px", textAlign: "center" }}
            color={isQuestionAdded ? "#74D95B" : "#F95050"}
          >
            {isQuestionAdded ? "Question Added" : "Question Not Added"}
          </Tag>
        );
      },
    },
    {
      title: "All Answer Submitted",
      dataIndex: "isAllAnswerSubmitted",
      render: (isAllAnswerSubmitted) => {
        return (
          <Tag
            style={{ width: "150px", textAlign: "center" }}
            color={isAllAnswerSubmitted ? "#74D95B" : "#F95050"}
          >
            {isAllAnswerSubmitted ? "Submitted" : " Not Submitted"}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "Registration Date" }),
      dataIndex: "createdAt",
      render: (item) => moment(item).format("MM/DD/YYYY hh:mm A"),
    },

    {
      dataIndex: "email",
      title: intl.formatMessage({ id: "app.field.email" }),
      render: (email) => {
        return (
          <>
            <div className={styles.VerifiedEmail_cont}>
              <span style={{ wordWrap: "break-word", marginRight: "10px" }}>
                {email}{" "}
              </span>
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="lg"
                style={{ color: "#9bd1f3" }}
              />
            </div>
          </>
        );
      },
    },
    {
      dataIndex: "phoneNo",
      title: intl.formatMessage({ id: "app.field.phoneNumber" }),
      render: (i, p) => `${p.countryCode || ""} ${p.phoneNo || "---"}`,
    },
    {
      dataIndex: "lastOnlineDate",
      title: "last Active",
      render: (item) => moment(item).format("MM/DD/YYYY hh:mm A"),
    },

    {
      dataIndex: "",
      sNo: true,
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        return <CustomTag isBlocked={item?.isBlocked} />;
      },
    },

    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        // debugger;
        return (
          <center>
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          </center>
        );
      },
    },
  ];
  const statusFilter = [
    {
      id: "ALL",
      text: "All",
    },
    {
      id: "true",
      text: "Blocked",
    },
    {
      id: "false",
      text: "Active",
    },
  ];
  const openAddCustomerModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      modalType: "ADD",
    });
  };
  const openAddQuestionModal = () => {
    updateModalControl({
      ...modalControl,
      isVisible: true,
      modalType: "",
    });
  };
  const rangeFilter = (data) => {
    let query = {};
    if (data) {
      let startDate = moment(new Date(data[0])).format("YYYY-MM-DD");
      let endDate = moment(new Date(data[1])).format("YYYY-MM-DD");
      console.log("rangeFilter => start ", startDate);
      console.log("rangeFilter => end ", endDate);
      query.startDate = startDate;
      query.endDate = endDate;
    }

    let params = {};
    params.params = query;
  };
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  return (
    <div className={styles.wrapper}>
      <h1>{}</h1>
      {isLoading && (
        <div className="loader-container">
          {" "}
          {/* You can style this container */}
          <Spin size="large" />
        </div>
      )}
      <ConfirmModal
        header={`Delete Customer Id: ${modalControls?.data?.id} ?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={closeModal}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      {customerId && (
        <AddEditQuestionCustomer
          customerId={customerId}
          isQuestionAdded={isQuestionAdded}
          isAllAnswerSubmitted={isAllAnswerSubmitted}
          isVisible={modalQuestion?.isVisible}
          onClose={closeQuestion}
          onCloseIconClick={closeQuestion}
          modalToShow={modalQuestion?.modalType}
          data={modalQuestion?.data}
          modalControls={closeQuestion}
          getCustomersData={getCustomersData}
          isLoading={isLoading}
        />
      )}

      <AddEditQuestion
        isVisible={modalControl?.isVisible}
        onClose={closeModale}
        onCloseIconClick={closeModale}
        modalToShow={modalControl?.modalType}
        data={modalControl?.data}
        modalControls={closeModal}
        isLoading={isLoading}
        getCustomersData={getCustomersData}
      />
      <ViewCustomer isVisible={modalview.isVisible} />

      <AddEditUser
        // isVisible={true}
        isVisible={modalControls?.isVisible}
        onClose={closeModal}
        onCloseIconClick={closeModal}
        modalToShow={modalControls?.modalType}
        data={modalControls?.data}
        modalControls={closeModal}
        getCustomersData={getCustomersData}
        customerId={customerId}
        // addAdmin={true}

        isLoading={modalControls?.isLoading}
      />

      <Header title="app.pageTitle.customers" />
      {/* <div className={styles?.filterCont}> */}
      <div className={styles?.filterCont}>
        <header>
          <div>
            <Label value="Filter By Status" vertical></Label>
            <Select
              defaultValue="ALL"
              onChange={handleFilterChange}
              style={{ width: "240px" }}
            >
              {statusFilter.map((status) => (
                <Option key={status.id} value={status.id}>
                  {status.text}
                </Option>
              ))}
            </Select>
          </div>

          {/* <Input
            placeholder="Search by Name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: "200px", marginBottom: "16px" }}
          /> */}
        </header>

        <div></div>

        <Button
          type="primary"
          style={{
            backgroundColor: "rgba(21, 96, 189, 1)",
            color: "white",
            border: hovered ? "0px" : "none",
            marginLeft: "auto", // Add this line to align the button to the right
          }}
          onClick={openAddPromoModal}
        >
          Customer Questionnaire
        </Button>
      </div>

      {/* </div> */}
      <div className="custom-section">
        <div
          className={styles.menu}
          style={{ justifyContent: "space-between", alignItems: "flex-end" }}
        >
          {/* <div className={styles.rangePicker}>
            <Label value="Filter By Registration Date" vertical></Label>
            <RangePicker onChange={rangeFilter} />
          </div> */}
          <div>
            {
              <Button
                type="primary"
                style={{
                  backgroundColor: "rgba(21, 96, 189, 1)",
                  color: "white",
                  border: hovered ? "0px" : "none",
                  marginLeft: "auto",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={openAddCustomerModal}
              >
                Add Customer
              </Button>
            }
          </div>
        </div>
        <CustomTable
          isLoading={tableControls.isLoading}
          columns={columns}
          dataSource={tableControls.data}
          isEmpty={tableControls.isEmpty}
          pagination={pagination}
          viewItem={openViewModal}
          onChange={handleTableChange}
          current={pagination.current}
          pageSize={pagination.pageSize}
          columnWidth={230}
          width
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Customers);
