import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { CustomTag, DelayedInput } from "../../../components/common";
import CustomTable from "../../../components/common/custom-table/custom-table";
import { dateFormat } from "../../../shared/utils/local-storage";
import { useIntl } from "react-intl";
import "./rolesTabs.scss";
import axiosClient from "../../../shared/api/axiosConfig";
import AddEditRoles from "./add-edit-roles/add-edit-roles";
import { toastr } from "react-redux-toastr";

const { confirm } = Modal;

const adminsData = [
  {
    id: 1,
    name: "Super Admin",
    status: true,
  },
  {
    id: 2,
    name: "Sub Admin",
    status: true,
  },
  {
    id: 3,
    name: "CSR",
    status: true,
  },
];

const styles = {
  wrapper: "wrapper",
  section: "custom-section",
  tableHeader: "tableHeader",
};

const RolesTabs = ({ getAdmin_Types, admin_types_roles, isLoading }) => {
  const intl = useIntl();
  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    isEmpty: false,
    data: [],
    count: 0,
  });
  const updateRolesData = (data) => {
    updateTableControls({
      ...tableControls,
      data: data,
    });
  };
  const [roles, setAllRoles] = useState([]);
  const [modalControl, updateModalComtrol] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: [],
    isLoading: false,
    type: "",
  });

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });
  const handleTableChange = (pag, filt, sorter) => {
    updatePagination({
      ...pag,
    });
  };
  const handleEdit = (data) => {
    updateModalComtrol({
      ...modalControl,
      type: "EDIT",
      isVisible: true,
      data: data,
    });
  };
  const handleView = (data) => {
    updateModalComtrol({
      ...modalControl,
      type: "VIEW",
      isVisible: true,
      data: data,
    });
  };
  const handleModalClose = () => {
    updateModalComtrol({
      ...modalControl,
      isVisible: false,
      type: "",
    });
  };
  const handleEnaleDisable = (item) => {
    console.log("handleEnaleDisable => ");
    //   // admin/enableDisable/:adminId
    axiosClient
      .put(`/enableDisableRole/${item.id}`, {
        isActive: !item.isActive,
      })
      .then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
          toastr.success(res?.data?.message);
          getAdmin_Types();
        }
      })
      .catch((err) => console.log(err));
  };
  const handleDelete = (item) => {
    axiosClient
      .delete(`/role-groups/${item.id}`)
      .then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
          toastr.success(res?.data?.message);
          getAdmin_Types();
        }
      })
      .catch((err) => console.log(err));
  };

  const showDeleteConfirm = (item) => {
    confirm({
      title: "Delete Role ?",
      width: 520,
      closable: true,
      // icon: <ExclamationCircleOutlined />,
      content: "Added Role will be deleted",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      okButtonProps: {
        type: "primary",
        // danger:"true",
      },
      onOk: () => {
        handleDelete(item);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleView(item)}>View</Menu.Item>

        <Menu.Item onClick={() => handleEdit(item)}>Edit</Menu.Item>

        {!item.isActive ? (
          <Menu.Item onClick={() => handleEnaleDisable(item)}>
            {" "}
            Unblock
          </Menu.Item>
        ) : (
          ""
        )}
        {item.isActive ? (
          <Menu.Item onClick={() => handleEnaleDisable(item)}>Block</Menu.Item>
        ) : (
          ""
        )}
        {<Menu.Item onClick={() => showDeleteConfirm(item)}>Delete</Menu.Item>}
      </Menu>
    );
  };
  const columns = [
    // S.No, Name, Registration Date, Email(Pending or Verified - Green / Orange tick), Phone Number, last Active, Address, Status, Action
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),

      sNo: true,
      fixed: "left",
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "title",
      title: "Role",
    },

    {
      dataIndex: "isActive",
      sNo: true,
      title: "Status",
      render: (item) => {
        return <CustomTag isBlocked={!item} />;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        return (
          <center>
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          </center>
        );
      },
    },
  ];
  const handleAddRoles = () => {
    updateModalComtrol({
      ...modalControl,
      isVisible: true,
      type: "ADD",
    });
  };

  //   const getAdmin_Types = () => {
  //     axiosClient
  //       .get("/role-groups")
  //       .then((res) => {
  //         if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 200) {
  //           console.log("role-groups get => ", res?.data?.data);
  //           updateTableControls({
  //             ...tableControls,
  //             data: res?.data?.data,
  //           });
  //         } else {
  //           toastr.error(res?.data?.message);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  const getRoles = () => {
    axiosClient
      .get("/available-roles")
      .then((res) => {
        console.log("Roles : ", res?.data);
        console.log("Roles Data: ", res?.data?.data);
        if (res?.data?.statusCode === 200) {
          setAllRoles(res?.data?.data);
        }
      })
      .catch((Err) => {
        console.log("Err", Err);
      });
  };

  useEffect(() => {
    getRoles();
    // getAdmin_Types();
  }, []);

  useEffect(() => {
    if (admin_types_roles) {
      updateRolesData(admin_types_roles);
    }
  }, [admin_types_roles]);

  return (
    <div className={styles.wrapper}>
      <section className="tableHeader">
        <Button size="medium" type="primary" shape="" onClick={handleAddRoles}>
          Create New Role
        </Button>
      </section>
      <AddEditRoles
        isVisible={modalControl?.isVisible}
        // isVisible={true}
        rolesData={roles}
        onClose={handleModalClose}
        getAdmin_Types={getAdmin_Types}
        onCloseIconClick={handleModalClose}
        modalToShow={modalControl?.type}
        data={modalControl?.data}
        isLoading={modalControl?.isLoading}
      />
      <CustomTable
        isLoading={isLoading}
        columns={columns}
        dataSource={tableControls.data}
        isEmpty={tableControls.isEmpty}
        // dataSource={tableControls.data}

        pagination={pagination}
        onChange={handleTableChange}
        current={pagination.current}
        pageSize={pagination.pageSize}
        columnWidth={180}
        width
      />
    </div>
  );
};

export default RolesTabs;
