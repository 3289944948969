import React from "react";
import {Spin} from 'antd';
import "./placeholder.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import Icon from '../icon/icon';
import className from 'classnames';
const styles = {
  wrapper: "custom-placeholder__wrapper",
  content: "custom-placeholder__content",
};
function Placeholder(props) {

    const getPlaceholder = () => {
        if(props.type === 'loading')
        {
            return <Spin size="large" /> 
        }

        if(props.type === 'empty') {
            return <Icon name="EMPTY" size="massive" />
        }
        return null;
     }
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {getPlaceholder()}
        <span>{props.text && props.text}</span>
      </div>
    </div>
  );
}

export default Placeholder;
