import React from "react";
import Pagination from "react-js-pagination";
import countries from "../../shared/constants/countries.json";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { DatePicker, Modal } from "antd";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import {
  Header,
  Panel,
  PaginationInfo,
  Icon,
  DelayedInput,
  CustomTable,
  CustomTag,
} from "../../components/common";
import { roleManagementData } from "../../FakeData";
import { withRouter } from "react-router-dom";

import axios from "../../shared/api/axios_config"; // base Url : 3003
import { Button, Select, Menu, Dropdown, Tag } from "antd";
import AddEditUser from "./add-edit-user/add-edit-user";
import ViewUser from "./view-user/view-user";
import "./businessowners.scss";
import { toastr } from "react-redux-toastr";
import { checkRtl } from "../../shared/utils/rtl";
import axiosConfig from "../../shared/api/axiose"; // base url: 3002
import axiosClient from "../../shared/api/axiosConfig"; //base Url: 3001
import Label from "../../components/common/label/label";
import { zipcode } from "../../shared/constants/countryFilterData";
import { statusFilter } from "../../shared/utils/local-storage";
import moment from "moment";
import Navbar from "../../components/navbar/navbar_";
import ViewProfile from "../../auth/login/view-Profile";

const styles = {
  wrapper: "role-management__users__wrapper",
  menuContainer: "role-management__users__menu-container",
  leftMenuItem: "role-management__users__left-menu-item",
  rightMenuItem: "role-management__users__right-menu-item",
  tableActions: "role-management__users__table-actions",
  actionIcon: "role-management__users__action-icon",
  actionIconHidden: "role-management__users__action-icon--hidden",
  table: "custom-table",
  menu: "custom-menu",
  section: "custom-section",
  filterCont: "filterCont",
};

const { Option } = Select;
const { confirm } = Modal;

class Businessowners extends React.Component {
  state = {
    totalRecords: null,
    data: [],
    isLoading: false,
    noRecordsFound: false,
    current: 1,
    pageSize: 5,
    skipRecords: 0,
    isModalVisible: false,
    isModalLoading: false,
    isViewModalVisible: false,
    modalData: {},
    // For No column
    currentDelayed: 1,
    pageSizeDelayed: 5,
    modalToShow: "ADD",
    userRoles: [],
    search: "",
    getCategoriesData: [],
    getServiceData: [],
    BusinessownersData: [],
    cityData: [],
    debounceTimer: "",
    zipcodes: [],
    isSidebarExpanded: true,
    modalControls: {
      isVisible: false,
      isLoading: false,
      modalType: "",
      data: {},
      update: "",
      heading: "",
    },
  };

  //   const [modalControls, updatedmodalControl] = useState({
  //     isVisible: false,
  //     isLoading: false,
  //     modalType: "",
  //     data: {},
  //     update: "",
  //     heading: ""
  // })
  constructor(props) {
    super(props);

    // Define a variable inside the class constructor
    this.debouncedSearch = this.debouncedSearch.bind(this);
  }

  serviceChoice = [
    {
      id: 1,
      text: "On-Demand Services",
    },
    {
      id: 2,
      text: "Scheduled Services",
    },
    {
      id: 3,
      text: "Quotation Services",
    },
  ];

  filteredData = () => {
    console.log("roleManagementData => ", roleManagementData());
    return roleManagementData().filter((i) => {
      if (this.state.search === "") {
        return true;
      } else {
        return (
          i.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
          i.email.toLowerCase().includes(this.state.search.toLowerCase()) ||
          i.phoneNo.toLowerCase().includes(this.state.search.toLowerCase())
        );
      }
    });
  };

  debouncedSearch = (func, delay) => {
    clearTimeout(this.state.debounceTimer);
    this.state.debounceTimer = setTimeout(() => {
      func();
    }, 1000);
  };

  handleSearchChange = (val) => {
    clearTimeout(this.state.debounceTimer); // Clear the timeout on each keystroke
    this.debouncedSearch(() => this.getBusinessOwnerData(val), 1000);

    this.setState({
      search: val,
    });
  };
  handleSearch = (val) => {};
  USER_TYPES = [
    {
      key: "Admin",
      value: "Admin",
      text: "Admin",
    },
    {
      key: "Sub-Admin",
      value: "Sub-Admin",
      text: "Sub-Admin",
    },
  ];

  handlePaginationChange = (pagination) => {
    this.setState({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.getPageData(this.state.pageSize, pagination.current);
  };

  handlepageSizeChange = (e, data) => {
    this.setState({
      pageSize: data.value,
    });

    this.getPageData(data.value, this.state.current);
  };

  getPlaceholder = (id) => {
    return this.props.intl.formatMessage({ id });
  };

  toggleAddModal = () => {
    this.setState((prev) => ({
      isModalVisible: !prev.isModalVisible,
      modalToShow: "ADD",
      adminId: "",
    }));
  };

  toggleEditModal = (data) => {
    this.setState((prev) => ({
      isModalVisible: !prev.isModalVisible,
      modalToShow: "EDIT",
      modalData: data,
      adminId: data.adminId,
    }));
  };

  handleModalClose = () => {
    this.setState({
      isModalVisible: false,
      isModalLoading: false,
      isViewModalVisible: false,
    });
  };

  addUser = (data) => {
    alert("Add User");
  };
  showDeleteConfirm = (item) => {
    confirm({
      title: "Delete Business Owner ?",
      width: 520,
      closable: true,
      // icon: <ExclamationCircleOutlined />,
      content: "Added Business Owner will be deleted",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      okButtonProps: {
        type: "primary",
        // danger:"true",
      },
      onOk: () => {
        // this.removeItem(item);
        console.log(item);
        this.deleteBO_Owner(item?.id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  editUser = (data) => {
    alert("Edit user");
  };

  getPageData = (pageSize, current) => {};

  getCityData = () => {
    axiosConfig
      .get("/city")
      .then((res) => {
        if (res?.data?.statusCode >= "200" && res?.data?.statusCode <= "299") {
          this.setState({
            cityData: res?.data?.data,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log("get cities err", err);
      });
  };
  handleEmailUpdate = (item) => {
    console.log("handleEmailUpdate item", item);
    this.setState({
      modalControls: {
        ...this.state.modalControls,
        isVisible: true,
        modalType: "EDIT",
        update: "email",
        heading: "Update Email and User Name",
        data: item,
      },
    });
  };

  handlePhoneNoUpdate = (item) => {
    console.log("handlePhoneNoUpdate item", item);
    this.setState({
      modalControls: {
        ...this.state.modalControls,
        isVisible: true,
        modalType: "EDIT",
        update: "phone",
        heading: "Update Phone No",
        data: item,
      },
    });
    // updatedmodalControl({
    //   ...modalControls,
    //   isVisible: true,
    //   modalType: "EDIT",
    //   update: "phone",
    //   heading: "Update Phone No"
    // });
  };

  getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            this.toggleViewModal(item);
          }}
        >
          {this.props.intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.handleEmailUpdate(item);
          }}
        >
          Update Email
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.handlePhoneNoUpdate(item);
          }}
        >
          Update Phone no
        </Menu.Item>
        {/* <Menu.Item
          onClick={() => {
            this.toggleEditModal(item);
          }}
        >
          {this.props.intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item> */}
        {item.isActive ? (
          <Menu.Item
            onClick={() => {
              this.toggleBlockAdmin(item);
            }}
          >
            Block
          </Menu.Item>
        ) : (
          ""
        )}
        {!item.isActive ? (
          <Menu.Item
            onClick={() => {
              this.toggleBlockAdmin(item);
            }}
          >
            Unblock
          </Menu.Item>
        ) : (
          ""
        )}

        <Menu.Item
          onClick={() => {
            this.showDeleteConfirm(item);
          }}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  };
  checkCountryCode() {
    const newcountries = countries.reduce((res, currentVal) => {
      if (res[currentVal.dial_code] === undefined) {
        res[currentVal.dial_code] = [currentVal];
      } else {
        res[currentVal.dial_code].push(currentVal);
      }
      return res;
    }, {});
    console.log(newcountries);
    // debugger;
  }

  componentDidMount() {
    this.getPageData(this.state.pageSize, 1);
    this.checkCountryCode();
    this.CategoryData();
    this.ServiceData();
    this.getBusinessOwnerData();
    this.getCityData();
    this.ZipcodeData();
  }
  //   {
  //     "_id": "6412fd78d11db6fc6163ba37",
  //     "id": 1,
  //     "businessName": "Business Name",
  //     "firstName": "John",
  //     "lastName": "Doe",
  //     "businessOwnerType": "business-owner",
  //     "description": "Describe your business",
  //     "zipcode": null,
  //     "language": null,
  //     "email": "john.doe@example.com",
  //     "phone": "8908908901",
  //     "username": null,
  //     "password": "$2b$10$J8U0/5ivldx9ZdKTMVrsx.FEeXX0bocJO.92aV3C195eU7oM.eSBO",
  //     "isActive": false,
  //     "isDeleted": false,
  //     "createdAt": "2023-03-16T11:28:56.316Z",
  //     "updatedAt": "2023-03-16T11:28:56.318Z",
  //     "__v": 0
  // }
  columns = [
    {
      dataIndex: "",
      fixed: "left",
      title: this.props.intl.formatMessage({ id: "app.field.sNo" }),
      render: (item, object, index) => {
        return (
          <span>
            {this.state.pageSize * (this.state.current - 1) + index + 1}
          </span>
        );
      },
    },

    {
      dataIndex: "firstName",
      title: "First Name",
      fixed: "left",
    },
    {
      dataIndex: "lastName",
      title: "Last Name",
    },
    {
      dataIndex: "email",
      title: "E-mail",
    },
    {
      dataIndex: "businessName",
      title: "Name of Business",
    },
    {
      dataIndex: "zipcode",
      title: "Zip Code",
    },
    {
      dataIndex: "phone",
      title: "Phone Number",
    },
    {
      dataIndex: "",
      title: "Priority Ranking",
    },
    {
      dataIndex: "",
      title: "Background verification Status",
      render: (item) => {
        console.log("Background verification Status =>", item);
        return (
          <CustomTag isVerified={item?.profile?.backgroundVerificationStatus} />
        );
      },
    },
    {
      dataIndex: "",
      title: "License verification Status",
      render: (item) => {
        return (
          <CustomTag isVerified={item?.profile?.licenseVerificationStatus} />
        );
      },
    },
    {
      dataIndex: "isActive",
      title: "Status",
      render: (item) => {
        return <CustomTag isBlocked={!item} />;
      },
    },
    {
      dataIndex: "isDeleted",
      title: "Is Deleted",
      render: (item) => {
        return <CustomTag isDeleted={item} />;
      },
    },
    {
      dataIndex: "",
      title: " Average Rating", // (All their SPs average rating)
    },
    // ======================================

    {
      title: this.props.intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      render: (item) => {
        // debugger;
        return (
          <Dropdown overlay={this.getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];
  handleFilterByStatus = (status) => {
    let query = {};
    if (status !== null) {
      query.isActive = status;
    }
    let params = {};
    params.params = query;
    this.debouncedSearch(
      () => this.getBusinessOwnerData_searchFun(params),
      1000
    );
  };
  handleZipCodeFilter = (val) => {
    let query = {};
    if (val !== null) {
      query.zipcode = val;
    }
    let params = {};
    params.params = query;
    this.debouncedSearch(
      () => this.getBusinessOwnerData_searchFun(params),
      1000
    );
  };

  rangeFilter = (data) => {
    let query = {};
    if (data) {
      let startDate = moment(new Date(data[0])).format("YYYY-MM-DD");
      let endDate = moment(new Date(data[1])).format("YYYY-MM-DD");

      query.startDate = startDate;
      query.endDate = endDate;
    }

    let params = {};
    params.params = query;

    this.debouncedSearch(
      () => this.getBusinessOwnerData_searchFun(params),
      1000
    );
  };

  handleModalClose2 = () => {
    this.setState({
      modalControls: {
        ...this.state.modalControls,
        isVisible: false,
        modalType: "",
      },
    });

    // updatedmodalControl({
    //     ...modalControls,
    //     isVisible: false,
    //     modalType: "",
    // })
  };
  render() {
    return (
      <div className={styles.wrapper}>
        <AddEditUser
          isVisible={this.state.isModalVisible}
          serviceChoice={this.serviceChoice}
          cityData={this.state.cityData}
          onClose={this.handleModalClose}
          onCloseIconClick={this.handleModalClose}
          modalToShow={this.state.modalToShow}
          roles={this.props.userInfo.userRoles}
          data={this.state.modalData}
          pickerFlow={this.props.pickerFlow}
          adminId={this.state.adminId}
          enabledVerticals={this.props.enabledVerticals}
          isLoading={this.state.isModalLoading}
          getCategoriesData={this.state.getCategoriesData}
          getServiceData={this.state.getServiceData}
          getBusinessOwnerData={this.getBusinessOwnerData}
        />

        <Header title="Business Owners" path="/home/Business Owners" />
        <div className={styles?.filterCont}>
          <div>
            <Label value="Filter By Zip Code" vertical></Label>
            <Select
              showSearch
              placeholder="Zip Code"
              allowClear
              name=""
              style={{ width: "180px" }}
              onChange={this.handleZipCodeFilter}
              maxTagCount={1}
              maxTagTextLength={5}
            >
              {this.state.zipcodes.map((item) => (
                <Option key={item?.zipCode} value={item?.zipCode}>
                  {item?.zipCode}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Label value="Filter By Status" vertical></Label>
            <Select
              showSearch
              placeholder="Status"
              name=""
              onChange={this.handleFilterByStatus}
              style={{ width: "180px" }}
            >
              {statusFilter.map((item) => (
                <Option key={item.id} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <DelayedInput
              onChange={this.handleSearchChange}
              value={this.state.search}
              // callback={this.handleSearch}
              delay={100}
              // minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={this.props.intl.formatMessage({
                id: "app.placeholder.search",
              })}
            />
          </div>
        </div>

        <div className={styles.section}>
          <h1></h1>
          <div className={styles.menu} style={{ alignItems: "flex-end" }}>
            <div className={styles.rangePicker}>
              <Label value="Filter By Registration Date" vertical></Label>

              <DatePicker.RangePicker onChange={this.rangeFilter} />
            </div>
            <section
              style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}
            >
              <Button
                size="medium"
                disabled={this.props.isAddDisabled}
                onClick={this.toggleAddModal}
                type="primary"
                shape=""
              >
                Add Business Owner
              </Button>
            </section>
          </div>
          <CustomTable
            isLoading={this.state.isLoading}
            columns={this.columns}
            dataSource={this.state.BusinessownersData}
            // dataSource={roleManagementData()}
            pagination={{
              pageSize: !this.state.search ? "10" : "10",
              current: this.state.current,
              count: roleManagementData().length,
            }}
            columnWidth={180}
            width
            onChange={this.handlePaginationChange}
            // offset={43}
          />
          <ViewProfile
            isVisible={this.state.modalControls?.isVisible}
            onClose={() => {
              this.handleModalClose2();
            }}
            onCloseIconClick={() => {
              this.handleModalClose2();
            }}
            modalToShow={this.state.modalControls?.modalType}
            data={this.state.modalControls?.data}
            isLoading={this.state.modalControls?.isLoading}
            updateData={this.state.modalControls?.update}
            heading={this.state.modalControls?.heading}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
  pickerFlow: state.user.enterprice.pickerFlow,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  // history: state.roles.history,
  enabledVerticals: state.user.enterprice.enabledVerticals
    ? JSON.parse(state.user.enterprice.enabledVerticals)
    : [],
});
export default connect(mapStateToProps)(
  injectIntl(React.memo(withRouter(Businessowners)))
);
