import { getRoles_from_admin } from "../../shared/utils/local-storage";
import RolesActionTypes from "./roles-action-types";
// import { useDispatch } from "react-redux"

// const  dispatch = useDispatch();
const rolesActions = {
    setModule: (data) => ({ type: RolesActionTypes.SET_MODULES, data }),
    setSubModule: (data) => ({ type: RolesActionTypes.SET_SUB_MODULES, data }),
    setRolesPrevaligesData: (data) => ({ type: RolesActionTypes.ROLES_PREVALIGESDATA , data }),

    getRolesPrivaleges: () => () => {
       
        let roles = getRoles_from_admin();
        if (roles === undefined) return [];
        let module_access = [];
        let sub_module_access = [];
        (roles).map((obj) => {
            if (
                obj?.module_uid === "city.read" ||
                obj?.module_uid === "city.create" ||
                obj?.module_uid === "city.delete" ||
                obj?.module_uid === "city.update"
            ) {
                module_access.push({
                    module: "cities",
                    menuId: 2,
                });
                sub_module_access.push(obj?.module_uid);
            }

            // ****************Role-manegment*************
            if (
                obj?.module_uid === "admin.read" ||
                obj?.module_uid === "admin.create" ||
                obj?.module_uid === "admin.delete" ||
                obj?.module_uid === "admin.update"
            ) {
                module_access.push({
                    module: "role-manegment",
                    menuId: 3,
                });
                sub_module_access.push(obj?.module_uid);
            }
            // *******************Customer***********************
            if (
                obj?.module_uid === "customer.read" ||
                obj?.module_uid === "customer.create" ||
                obj?.module_uid === "customer.delete" ||
                obj?.module_uid === "customer.update"
            ) {
                module_access.push({
                    module: "customer",
                    menuId: 4,
                });
                sub_module_access.push(obj?.module_uid);
            }

            // *********************** customer_to_sp.read**********
            if (
                obj?.module_uid === "customer_to_sp.read" ||
                obj?.module_uid === "customer_to_sp.create" ||
                obj?.module_uid === "customer_to_sp.delete" ||
                obj?.module_uid === "customer_to_sp.update"
            ) {
                module_access.push({
                    module: "Customer to SP",
                    menuId: 5,
                });
                sub_module_access.push(obj?.module_uid);
            }

            // *********************** sp_to_customer.read**********
            if (
                obj?.module_uid === "sp_to_customer.read" ||
                obj?.module_uid === "sp_to_customer.create" ||
                obj?.module_uid === "sp_to_customer.delete" ||
                obj?.module_uid === "sp_to_customer.update"
            ) {
                module_access.push({
                    module: "SP To Customer",
                    menuId: 6,
                });
                sub_module_access.push(obj?.module_uid);
            }

            // *********************** sp_to_customer.read**********
            if (
                obj?.module_uid === "sp_to_customer.read" ||
                obj?.module_uid === "sp_to_customer.create" ||
                obj?.module_uid === "sp_to_customer.delete" ||
                obj?.module_uid === "sp_to_customer.update"
            ) {
                module_access.push({
                    module: "SP To Customer",
                    menuId: 6,
                });
                sub_module_access.push(obj?.module_uid);
            }

            //========================categories============
            if (
                obj?.module_uid === "category.read" ||
                obj?.module_uid === "category.create" ||
                obj?.module_uid === "category.delete" ||
                obj?.module_uid === "category.update"
            ) {
                module_access.push({
                    module: "categories",
                    menuId: 7,
                });
                sub_module_access.push(obj?.module_uid);
            }

            //========================service============
            if (
                obj?.module_uid === "service.read" ||
                obj?.module_uid === "service.create" ||
                obj?.module_uid === "service.delete" ||
                obj?.module_uid === "service.update"
            ) {
                module_access.push({
                    module: "service",
                    menuId: 8,
                });
                sub_module_access.push(obj?.module_uid);
            }

            //========================business_owner============
            if (
                obj?.module_uid === "business_owner.read" ||
                obj?.module_uid === "business_owner.create" ||
                obj?.module_uid === "business_owner.delete" ||
                obj?.module_uid === "business_owner.update"
            ) {
                module_access.push({
                    module: "business_owner",
                    menuId: 9,
                });
                sub_module_access.push(obj?.module_uid);
            }


            //========================service_provider============
            if (
                obj?.module_uid === "service_provider.read" ||
                obj?.module_uid === "service_provider.create" ||
                obj?.module_uid === "service_provider.delete" ||
                obj?.module_uid === "service_provider.update"
            ) {
                module_access.push({
                    module: "service_provider",
                    menuId: 10,
                });
                sub_module_access.push(obj?.module_uid);
            }
            //========================general_faq============
            if (
                obj?.module_uid === "general_faq.read" ||
                obj?.module_uid === "general_faq.create" ||
                obj?.module_uid === "general_faq.delete" ||
                obj?.module_uid === "general_faq.update"
            ) {
                module_access.push({
                    module: "general_faq",
                    menuId: 11,
                });
                sub_module_access.push(obj?.module_uid);
            }

            //========================service booking============
            if (
                obj?.module_uid === "order.read" ||
                obj?.module_uid === "order.create" ||
                obj?.module_uid === "order.delete" ||
                obj?.module_uid === "order.update"
            ) {
                module_access.push({
                    module: "service booking",
                    menuId: 12,
                });
                sub_module_access.push(obj?.module_uid);
            }

            //========================promo_codes============
            if (
                obj?.module_uid === "promo_codes.read" ||
                obj?.module_uid === "promo_codes.create" ||
                obj?.module_uid === "promo_codes.delete" ||
                obj?.module_uid === "promo_codes.update"
            ) {
                module_access.push({
                    module: "service booking",
                    menuId: 13,
                });
                sub_module_access.push(obj?.module_uid);
            }

            //========================push_notification============
            if (
                obj?.module_uid === "push_notification.read" ||
                obj?.module_uid === "push_notification.create" ||
                obj?.module_uid === "push_notification.delete" ||
                obj?.module_uid === "push_notification.update"
            ) {
                module_access.push({
                    module: "push_notification",
                    menuId: 14,
                });
                sub_module_access.push(obj?.module_uid);
            }
            //========================banners============
            if (
                obj?.module_uid === "banners.read" ||
                obj?.module_uid === "banners.create" ||
                obj?.module_uid === "banners.delete" ||
                obj?.module_uid === "banners.update"
            ) {
                module_access.push({
                    module: "banners",
                    menuId: 15,
                });
                sub_module_access.push(obj?.module_uid);
            }
            //========================training_video============
            if (
                obj?.module_uid === "training_video.read" ||
                obj?.module_uid === "training_video.create" ||
                obj?.module_uid === "training_video.delete" ||
                obj?.module_uid === "training_video.update"
            ) {
                module_access.push({
                    module: "training_video",
                    menuId: 16,
                });
                sub_module_access.push(obj?.module_uid);
            }

            //========================report============
            if (
                obj?.module_uid === "report.read" ||
                obj?.module_uid === "report.create" ||
                obj?.module_uid === "report.delete" ||
                obj?.module_uid === "report.update"
            ) {
                module_access.push({
                    module: "report",
                    menuId: 17,
                });
                sub_module_access.push(obj?.module_uid);
            }

            //========================settings============
            if (
                obj?.module_uid === "settings.read" ||
                obj?.module_uid === "settings.create" ||
                obj?.module_uid === "settings.delete" ||
                obj?.module_uid === "settings.update"
            ) {
                module_access.push({
                    module: "settings",
                    menuId: 18,
                });
                sub_module_access.push(obj?.module_uid);
            }



        });
        let modules = [];
        module_access.forEach((obj) => {
            if (!modules.find((item) => JSON.stringify(item) === JSON.stringify(obj))) {
                modules.push(obj);
            }
        });
      

        return [{
            modules: modules
        },
        {
            sub_module_access: sub_module_access

        }
    ]

    },
};

export default rolesActions;
