import React from "react";
import "./Terms.css";
import Term from "../assets/images/terms.jpg";
const TermsAndConditions = () => {
  return (
    <>
      <div
        className="background"
        style={{ backgroundImage: `url(${Term})`, marginBottom: "30px" }}
      >
        <div className="terms-and-conditions">
          <h1>Terms and Conditions</h1>
          <p>
            Welcome to our website. These terms and conditions outline the rules
            and regulations for the use of our website.
          </p>

          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing and using this website, you accept and agree to be
            bound by the terms and conditions outlined herein. If you do not
            agree with any of these terms, please do not use this website.
          </p>

          <h2>2. Use of Website</h2>
          <p>
            You agree to use this website in accordance with all applicable laws
            and regulations. You must not use this website for any unlawful or
            prohibited purpose.
          </p>

          <h2>3. Intellectual Property</h2>
          <p>
            All content on this website, including text, graphics, logos,
            images, and software, is the property of our company and is
            protected by copyright and other intellectual property laws. You may
            not reproduce, distribute, or transmit any content from this website
            without our prior written consent.
          </p>

          <h2>4. Privacy</h2>
          <p>
            We collect and use your personal information in accordance with our
            Privacy Policy, which is incorporated by reference into these terms
            and conditions. By using this website, you consent to our collection
            and use of your personal information as described in the Privacy
            Policy.
          </p>

          <h2>5. Disclaimer</h2>
          <p>
            This website is provided "as is" and without warranties of any kind,
            either express or implied. We do not warrant that the website will
            be uninterrupted or error-free.
          </p>

          <h2>6. Changes to Terms</h2>
          <p>
            We reserve the right to change these terms and conditions at any
            time without notice. You are responsible for reviewing these terms
            periodically to stay informed of any changes.
          </p>

          <h2>7. Contact Information</h2>
          <p>
            If you have any questions about these terms and conditions, please
            contact us at [email address].
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
