import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, Spin, Checkbox, List } from "antd";
import { Panel, CustomModal } from "../../../../components/common";
import AddQuestions from "./add-questions";
import axios from "../../../../shared/api/axiose";
import "./add-edit-user.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { ReloadOutlined } from "@ant-design/icons";

import { checkRtl } from "../../../../shared/utils/rtl";
import { Select, Menu, Dropdown } from "antd";
import AddQuestionsByid from "./add-questions-byid";
const { Option } = Select;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
  datePicker: "datePicker",
  OTP_modal: "OTP_modal",
  OTP_input: "OTP_input",
};

function AddEditQuestionCustomer({
  getCustomersData,
  getCustomersDetail,
  onCloseIconClick,
  updateCustomerFlag,
  customerFlag,
  closeQuestion,
  customerId,
  isQuestionAdded,
  isAllAnswerSubmitted,
  ...props
}) {
  const [isAddQuestionsVisible, setIsAddQuestionsVisible] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isAnyQuestionSelected, setIsAnyQuestionSelected] = useState(false);

  const closeModale = () => {
    onCloseIconClick();
  };
  useEffect(() => {
    if (props.isVisible) {
      if (props?.modalToShow === "EDIT") {
        setForm();
      } else {
        // resetForm();
      }
    }
  }, [props.isVisible, props?.data]);

  useEffect(() => {
    fetchQuestions();
  }, [customerId]);

  useEffect(() => {
    // Check if the questions are fetched from the customerId API
    setIsFetchedFromCustomerId(
      questions.some((question) => !!question.customerId)
    );
  }, [questions]);

  const setForm = () => {
    // Implement your logic for setting the form
  };

  const closeModal = () => {
    onCloseIconClick();
    setIsAddQuestionsVisible(false);
  };

  const handleAdd = () => {
    setIsAddQuestionsVisible(true);
    if (isAllAnswerSubmitted) {
      // Show a toast error when all answers are already submitted
      setIsAddQuestionsVisible(false);
      toast.error(
        "Cannot add questions because all answers are already submitted."
      );
    } else {
      setIsAddQuestionsVisible(true);
    }
  };
  const handleAddQuestion = (newQuestion) => {
    setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
  };

  const handleDeleteQuestion = (questionId) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((question) => question.questionId !== questionId)
    );
  };

  const handleDeleteQuestions = async () => {
    try {
      setIsLoading(true);
      await axios.delete("/questionnaire/deleteAllDefaultQuestion");
      setQuestions([]);
      toast.success("All questions deleted");
    } catch (error) {
      toast.error("Failed to delete questions");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchQuestions = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `/questionnaire/getQuestionByCustomerId/${customerId}`
      );
      const { data } = response.data;
      let mergedQuestions = [];

      if (data.length === 0) {
        const defaultResponse = await axios.get(
          "/questionnaire/getDefaultCustomerQuestions"
        );
        const { data: defaultData } = defaultResponse.data;
        mergedQuestions = [...defaultData];
      } else {
        mergedQuestions = [...data];
      }

      setQuestions(mergedQuestions);
    } catch (error) {
      console.error("Failed to fetch questions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuClick = async (questionId, action) => {
    if (action === "edit") {
      // Handle edit question
    } else if (action === "delete") {
      try {
        setIsLoading(true);
        await axios.delete(`/questionnaire/deleteQuestionById/${questionId}`);
        handleDeleteQuestion(questionId);
        toast.success("Question deleted");
      } catch (error) {
        toast.error("Failed to delete question");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderQuestionMenu = (questionId) => {
    const menu = (
      <Menu onClick={({ key }) => handleMenuClick(questionId, key)}>
        {/* <Menu.Item key="edit">Edit</Menu.Item> */}
        <Menu.Item key="delete">Delete</Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="link" style={{ padding: 0 }}>
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            style={{ color: "#6693e1" }}
            size="xl"
          />
        </Button>
      </Dropdown>
    );
  };

  const handleQuestionSelect = (questionId) => {
    const questionIndex = selectedQuestions.indexOf(questionId);

    if (questionIndex > -1) {
      setSelectedQuestions((prevSelectedQuestions) => [
        ...prevSelectedQuestions.slice(0, questionIndex),
        ...prevSelectedQuestions.slice(questionIndex + 1),
      ]);
    } else {
      setSelectedQuestions((prevSelectedQuestions) => [
        ...prevSelectedQuestions,
        questionId,
      ]);
    }

    setIsAnyQuestionSelected(selectedQuestions.length > 0);
  };

  const handleSaveQuestions = async () => {
    if (selectedQuestions.length === 0) {
      toast.error("Please select at least one question.");
      return;
    }
    try {
      setIsLoading(true);

      const formattedQuestions = selectedQuestions.map((questionId) => {
        const question = questions.find((q) => q.questionId === questionId);
        let formattedQuestion = {
          questionText: question.questionText,
          answerType: question.answerType,
          customerId: customerId, // Add customerId to each question
        };

        if (
          question.answerType === "single-selection" ||
          question.answerType === "multiple-selection"
        ) {
          formattedQuestion.answerOption = question.answerOption;
        }

        return formattedQuestion;
      });

      const payload = {
        questions: formattedQuestions,
      };

      await axios.post("/questionnaire/addOrUpdateBulkQuestion", payload);
      toast.success("Questions added successfully");

      getCustomersData();
      closeModale();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleRefreshClick = () => {
    fetchQuestions();
  };
  const [isFetchedFromCustomerId, setIsFetchedFromCustomerId] = useState(false);

  return (
    <>
      {isAddQuestionsVisible && (
        <AddQuestions
          customerId={customerId}
          isVisible={props.isVisible}
          onClose={closeModal}
          getCustomersData={getCustomersData}
          onCloseIconClick={closeModal}
          modalToShow={props.modalToShow}
          data={props.data}
          modalControls={props.modalControls}
          isLoading={props.isLoading}
          onAddClick={handleAddQuestion}
        />
      )}
      <CustomModal
        size="tiny"
        isLoading={isLoading}
        onCloseIconClick={closeModal}
        header={
          props.modalToShow === "ADD"
            ? "Customer Questionnaire"
            : "Customer Questionnaire"
        }
        action={
          props.modalToShow !== "VIEW" ? (
            <>
              <Button
                style={{}}
                type="primary"
                shape={undefined}
                onClick={handleRefreshClick}
                icon={<ReloadOutlined />}
              >
                Refresh
              </Button>
            </>
          ) : (
            ""
          )
        }
        isVisible={props.isVisible}
      >
        <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
          {isLoading ? (
            <div className="large-spin">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <div>
                {isQuestionAdded ? (
                  <p
                    style={{
                      fontWeight: "600",
                      marginBottom: "1.5rem",
                      color: "green",
                    }}
                  >
                    Questions already added
                  </p>
                ) : (
                  <p style={{ fontWeight: "600", marginBottom: "1.5rem" }}>
                    You can add default questions by Selecting the questions and
                    submitting, or you can add custom questions.
                  </p>
                )}
                {!isQuestionAdded && !isAllAnswerSubmitted && (
                  <Button
                    type="primary"
                    shape={undefined}
                    onClick={handleSaveQuestions}
                    loading={props.isLoading}
                    style={{ marginRight: "1rem" }}
                    disabled={selectedQuestions.length === 0}
                  >
                    <FormattedMessage id="Add Default selected Questions" />
                  </Button>
                )}
                <Button
                  style={{ marginRight: "1rem" }}
                  type="primary"
                  loading={props.isLoading}
                  shape={undefined}
                  onClick={handleAdd}
                  // disabled={isAllAnswerSubmitted}
                >
                  <FormattedMessage id="Add Custom Questions" />
                </Button>
              </div>
              <div>
                {isLoading ? (
                  <div className="large-spin">
                    <Spin size="large" />
                  </div>
                ) : (
                  questions.map((question, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: "1.5rem",
                        padding: "1.5rem",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            style={{
                              marginRight: "0.5rem",
                            }}
                            checked={
                              isFetchedFromCustomerId
                                ? !!question.customerId
                                : selectedQuestions.includes(
                                    question.questionId
                                  )
                            }
                            onChange={() =>
                              isFetchedFromCustomerId
                                ? null
                                : handleQuestionSelect(question.questionId)
                            }
                            disabled={isFetchedFromCustomerId}
                          />
                          <p style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                            {question.questionText}
                          </p>
                        </div>
                        {renderQuestionMenu(question.questionId)}
                      </div>

                      {question.answerType === "single-selection" && (
                        <List
                          style={{ width: "570px", marginLeft: "1rem" }}
                          dataSource={question.answerOption}
                          renderItem={(option, optionIndex) => (
                            <List.Item key={optionIndex}>
                              <Checkbox
                                style={{
                                  marginLeft: "1.5rem",
                                  marginBottom: "1rem",
                                }}
                              >
                                {option}
                              </Checkbox>
                            </List.Item>
                          )}
                        />
                      )}
                      {question.answerType === "multiple-selection" && (
                        <List
                          style={{ width: "570px", marginLeft: "1rem" }}
                          dataSource={question.answerOption}
                          renderItem={(option, optionIndex) => (
                            <List.Item key={optionIndex}>
                              <Checkbox
                                style={{
                                  marginLeft: "1.5rem",
                                  marginBottom: "1rem",
                                }}
                              >
                                {option}
                              </Checkbox>
                            </List.Item>
                          )}
                        />
                      )}
                    </div>
                  ))
                )}
              </div>
            </>
          )}
          <div></div>
        </Panel>
      </CustomModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  enabledPanels: state.user.enterprice.enabledPanels,
  isAddDisabled: state.user.isAddDisabled,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(injectIntl(AddEditQuestionCustomer));
