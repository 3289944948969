import React, { useEffect, useState } from "react";
// import ReduxToastr, { toastr } from "react-redux-toastr";
import { LoadScript } from "@react-google-maps/api";
import { connect } from "react-redux";
import { useClearCache } from "react-clear-cache";
import { IntlProvider, useIntl } from "react-intl";
import {
  Router,
  Route,
  Switch,
  Redirect,
  StaticRouter,
} from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import GridLoader from "react-spinners/GridLoader";
import Helmet from "react-helmet";
import { Card, Button } from "antd";

import userActions from "./redux/user-info/user-actions";
import Auth from "./auth/auth-container";
import Home from "./containers/home-container";
import { ToastContainer, toast } from "react-toastify"; //// toast
// import "react-toastify/dist/ReactToastify.css";
import history from "./shared/utils/history";
import { ConfigProvider } from "antd";
import DevModal from "./components/dev/dev";
import Stores from "./containers/stores/stores";
import { nestedParseJSON } from "./shared/utils/object-manipulation";
import {
  getPanelType,
  setPanelType,
  DevStorage,
  clearUserData,
} from "./shared/utils/local-storage";
import { Placeholder } from "./components/common";
import GlobalModal from "./components/modal/global/GlobalModal";
import Businessowners from "./containers/Business_Owners/businessowners";
import "./App.less";
import "./App.scss";

import {
  ForgotPassword,
  ForgotPasswordSuccess,
  Payment,
} from "./containers/external";
import settingsViaDomain from "./shared/api/fake-db/settingsViaDomain.json";
import English from "./languages/en-US.json";
import Arabic from "./languages/ar.json";
import French from "./languages/fr.json";
import German from "./languages/de.json";
import Turkish from "./languages/tr.json";
import { useDispatch } from "react-redux";

// import forgetPassword from "./containers/external/forget-password/forget-password";
import rolesActions from "./redux/roles/roles-action";
import ForgotPasswordPhone from "./containers/external/forget-password/forgotPasswordPhone";
import CheckoutSuccess from "./auth/card/CheckoutSuccess";
import CheckoutFailed from "./auth/card/CheckoutFailed";

import TermsAndConditions from "./containers/TermsAndConditions";
import PrivacyPolicy from "./containers/PrivacyPolicy";

const messages = {
  en: English,
  ar: Arabic,
  fr: French,
  de: German,
  tr: Turkish,
};

const rtl = {
  ar: true,
  en: false,
  fr: false,
  tr: false,
  de: false,
};
function NoMatch() {
  return <div>Not found</div>;
}
const libraries = ["drawing", "places"];

const defaultTitle = "Dashboard";
const defaultFavico = "";
function App(props) {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [controls, updateControls] = useState({
    isLoading: true,
    isError: false,
  });
  const dispatch = useDispatch();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  const [isRTL, toggleIsRTL] = useState(false);
  const [metadata, updateMetadata] = useState({
    title: defaultTitle,
    favico: defaultFavico,
  });

  const [isDevModalVisible, toggleDevModal] = useState(false);

  // AIzaSyBpcV - fXpB6ZREEawA7BjDeU1ac5q - QsMU
  useEffect(() => {
    toggleIsRTL(rtl[props.locale]);
    // RTL
    document.documentElement.dir = rtl[props.locale] ? "rtl" : "ltr";
    document.documentElement.style.direction = rtl[props.locale]
      ? "rtl"
      : "ltr";
  }, [props.locale]);
  // const handleClick = () => {
  //   setIsDarkMode((previousValue) => !previousValue);
  //   const root = document.documentElement;
  //   if (isDarkMode) {
  //     root.style.setProperty("--primary-color", "#1890ff"); // Default Ant Design primary color
  //     root.style.setProperty("--background-color", "#f8f8f8"); // Default background color
  //   } else {
  //     root.style.setProperty("--primary-color", "#ed432a"); // Your custom dark mode primary color
  //     root.style.setProperty("--background-color", "#333"); // Your custom dark mode background color
  //   }
  // };
  useEffect(() => {
    const script = document.createElement("script");
    let apiKey = "";

    // apiKey = "AIzaSyCSHHKKFCGHd8jd0_jlhytWadlpFLX6h9k";

    script.setAttribute(
      "src",
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,drawing`
    );
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  const toggleDevSettings = () => {
    toggleDevModal(!isDevModalVisible);
  };
  const setAccent = (accent) => {
    if (!accent) {
      accent = "#ed432a";
    }
    if (window.less?.modifyVars) {
      window.less.modifyVars({ "@primary-color": accent }).then(() => {
        document.documentElement.style.setProperty("--accent", accent);
        document.documentElement.style.setProperty("--accentBG", `${accent}b3`);
        updateControls({
          isLoading: false,
          isError: false,
        });
      });
    } else {
    }
  };
  useEffect(() => {
    let color = "#ed432a"; // primary color for admin
    updateControls({
      isLoading: false,
      isError: false,
    });
    let domainName = window.location.origin;
    let apiKey = null;
    if (process?.env?.REACT_APP_DEV_MODE == "1") {
      const data = DevStorage.getData();
      domainName = data.domainName;
      apiKey = data.apiKey;
    }

    setTimeout(() => {
      setAccent(color);
    }, 0);
  }, []);

  return (
    <>
      {/* <Card style={{ width: "max-content" }}>
        <Button onClick={handleClick}>
          Change Theme to {isDarkMode ? "Light" : "Dark"}
        </Button>
      </Card> */}
      <ToastContainer
        position="top-center"
        autoClose={2700}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <IntlProvider locale={props.locale} messages={messages[props.locale]}>
        <GlobalModal />

        <Router history={history}>
          <ConfigProvider direction={isRTL ? "rtl" : "ltr"}>
            <ScrollToTop>
              <Switch>
                <Route path="/auth" component={Auth} />
                <Route
                  path="/terms-and-conditions"
                  component={TermsAndConditions}
                />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/checkout-success/" component={CheckoutSuccess} />
                <Route path="/checkout-failed/" component={CheckoutFailed} />

                {/* <Route path="/home/large-inv" component={Home} /> */}
                <Route path="/home" component={Home} />

                <Route
                  exact={true}
                  path="/customerPayment"
                  component={Payment}
                />
                <Route
                  exact={true}
                  path="/passwordResetSuccessfully"
                  component={ForgotPasswordSuccess}
                />
                <Route
                  exact={true}
                  path="/passwordResetToken/:token"
                  component={ForgotPassword}
                />

                <Route
                  exact={true}
                  path="/passwordResetPhone"
                  component={ForgotPasswordPhone}
                />
                <Route
                  path="/"
                  exact
                  component={() => <Redirect to="/auth/login" />}
                />
                <Route component={NoMatch} />
              </Switch>
            </ScrollToTop>
          </ConfigProvider>
        </Router>

        {/* </LoadScript> */}
      </IntlProvider>
    </>
  );
}

const mapStateToProps = (state) => ({
  locale: state.user.locale,
  panelLoading: state.user.panelLoading,
});

export default connect(mapStateToProps)(App);
