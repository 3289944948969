import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select, Divider, Space } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import classNames from "classnames";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../../components/common";
import axiosConfig from "../../../../shared/api/axiose"; // base Url : http://52.26.241.33:3002
import Validations from "../../../../shared/validations/Validations";
import { multiSort } from "../../../../shared/utils/object-manipulation";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-promo-modal.scss";
import { PlusOutlined } from "@ant-design/icons";
import { getAdminInfo } from "../../../../shared/utils/local-storage";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formModal_wrapper: "Questionary_add_edit_modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "Questionary_add_edit_modal__image-container",
  imageContainerImage: "Questionary_add_edit_modal__image-container__image",
  imageContainerForm: "Questionary_add_edit_modal__image-container__form",
  spanImp: "Questionary_add_edit_modal__span-img",
  disabledColor: "disabledColor",
};

const errorText = "Field cannot be empty";

function AddEditPromoContainer({
  isVisible,
  modalType,
  onCloseIconClick,
  data,
  editCategory,
  isLoading,

  questionType,
  getAllFAQ,

  ...props
}) {
  console.log("AddEditPromoContainer", data);
  console.log("modalType =>", modalType);

  /** Instaservice States */
  const intl = useIntl();

  const [isPristine, togglePristine] = useState(true);
  const [tagSelect, updateTagSelect] = useState(
    Validations.validateMultiSelect([])
  );

  const [question, updateQuestion] = useState(
    Validations.validateTextField(undefined)
  );
  const [answer, updateAnswer] = useState(
    Validations.validateTextField(undefined)
  );

  // console.log("question_Type = > ", question_Type)

  /** Instaservice States */

  const resetForm = () => {
    togglePristine(true);
    updateAnswer(Validations.validateTextField(""));
    updateQuestion(Validations.validateTextField(""));
  };

  const setForm = () => {
    updateAnswer(Validations.validateTextField(data?.answer));
    updateQuestion(Validations.validateTextField(data?.question));
  };

  useEffect(() => {
    if (modalType === "ADD") {
      resetForm();
    } else {
      setForm();
    }
  }, [modalType, data]);

  const isFormValid = () => {
    let isValid = true;

    isValid = isValid && question.isValid && answer?.isValid;

    return isValid;
  };

  const closeModal = () => {
    onCloseIconClick();
    resetForm();
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return toastr.warning(
        intl.formatMessage({ id: "app.message.formCheck" })
      );
    }
    if (modalType === "ADD") {
    } else if (modalType === "EDIT") {
    }
  };

  const handleTagsSelect = (value) => {
    console.log("select cities=>", value);
    updateTagSelect(Validations.validateMultiSelect(value));
  };

  const handleQuestion = (val) => {
    console.log("handleQuestion => ", val);
    updateQuestion(Validations.validateTextField(val));
  };
  const handleAnswer = (val) => {
    updateAnswer(Validations.validateTextField(val));
  };
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        modalType === "ADD"
          ? "Add FAQ"
          : modalType === "EDIT"
          ? "Edit FAQ"
          : "FAQ Details"
      }
      action={
        modalType !== "VIEW" ? (
          <>
            {" "}
            <Button type="default" shape={undefined} onClick={closeModal}>
              {intl.formatMessage({ id: "app.button.cancel" })}
            </Button>
            <Button
              // loading={isLoading}
              type="primary"
              shape={undefined}
              onClick={formAction}
            >
              {intl.formatMessage({ id: "app.button.submit" })}
            </Button>
          </>
        ) : (
          ""
        )
      }
      isVisible={isVisible}
    >
      <Panel
        isModal
        className={classNames({
          [styles.formModal]: true,
          [styles.formModal_wrapper]: true,
        })}
      >
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Question" />
            <Input.TextArea
              name="question"
              value={question?.value}
              disabled={modalType === "VIEW"}
              placeholder="Question"
              // value={category.value}
              onChange={(e) => handleQuestion(e.target.value)}
            />
            {!isPristine && !question.isValid && (
              <ErrorMessage text={question?.errMsg} />
            )}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Answer" />
            <Input.TextArea
              name="answer"
              disabled={modalType === "VIEW"}
              value={answer?.value}
              placeholder="Answer"
              // value={category.value}
              onChange={(e) => handleAnswer(e.target.value)}
            />
            {!isPristine && !answer.isValid && (
              <ErrorMessage text={answer?.errMsg} />
            )}
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}
const mapState = (state) => ({
  // branchId: state.user.userInfo.branchId,
  // panelType: state.user.enterprice.panelType,
});
export default connect(mapState)(AddEditPromoContainer);
