import React from "react";
import ThumbsUp from "../../assets/images/thumbs-up-dragon.png";
import "./CheckoutSuccess.css";

const CheckoutSuccess = () => {
  return (
    <div className="container">
      <div className="white-box">
        <img src={ThumbsUp} alt="Thumbs Up" className="image" />
        <div className="text">
          <span className="green-tick">&#10004; Congratulations! </span>
          <br />
          Your card has been added successfully
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
