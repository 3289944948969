import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch, Menu, Dropdown } from "antd";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Panel, Icon, CustomTable, CustomTag, Label} from "../../../../components/common";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";
import "./customers-table.scss";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "custom-table-panel-container",
  stickyAction: "sticky-action",
};
function CustomersTable(props) {
  const intl = useIntl();

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      render: (item,object,index) => {
        return (<span>{props.pagination.pageSize * (props.pagination.current - 1) + index + 1}</span>)
      }
      // fixed: 'left',
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.name" }),
      render: (a, b) => `${b.firstName || ""} ${b.lastName || ""}`,
      // fixed: 'left',
    },
    {
      dataIndex: "email",
      title: intl.formatMessage({ id: "app.field.email" }),
    },
    {
      dataIndex: "phoneNo",
      title: intl.formatMessage({ id: "app.field.phoneNumber" }),
    },
    // {
    //   dataIndex: "dateOfBirth",
    //   title: intl.formatMessage({ id: "app.field.age" }),
    //   render: (dateOfBirth) =>
    //     dateOfBirth ? moment().diff(dateOfBirth, "years") : "N/A",
    // },
    // {
    //   dataIndex: "gender",
    //   title: intl.formatMessage({ id: "app.field.gender" }),
    // },
    {
      dataIndex: "",
      title: "Status",
      render: (item) => {
        // return item.isBlocked ? <Tag color="volcano">BLOCKED</Tag> : <Tag color="green">ACTIVE</Tag>
        return <CustomTag isBlocked={item.IsBlocked} />;
      },
    },
  ];

  return (
    <CustomTable
    isLoading={props.isLoading}
    columns={columns}
    rowSelection={{
      type: 'checkbox',
      ...props.rowSelection,
    }}
    toggleRowSelectAccessor={'IsBlocked'}
     
    dataSource={props.data}
    isEmpty={props.isEmpty}
    pagination={props.pagination}
    rowKey={i => i.customerId}
    onChange={props.handlePaginationChange}
  />
  );
}

export default CustomersTable;
