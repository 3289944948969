import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch } from "antd";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Panel, Icon } from "../../../../components/common";
import "./stores-table.scss";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  actionIcon: "custom-table__action-icon",
  panelContainer: "stores-table__panel-container",
};

function StoresTable(props) {
  const intl = useIntl();

  return (
    <Panel
      isLoading={props.isLoading}
      isEmpty={props.notFound}
      emptyText="No Data"
      containerClass={styles.panelContainer}
    >
      <table className={styles.table}>
        <tbody>
          <tr>
          <th>{intl.formatMessage({ id: "app.field.index" })}</th>
            <th>{intl.formatMessage({ id: "app.field.storeName" })}</th>
            <th>{intl.formatMessage({ id: "app.field.email" })}</th>
            <th>{intl.formatMessage({ id: "app.field.contact" })}</th>
            <th>{intl.formatMessage({ id: "app.field.address" })}</th>
            <th>{intl.formatMessage({ id: "app.field.minimumOrderValue" })}</th>
            <th>{intl.formatMessage({ id: "app.field.deliveryCharge" })}</th>
            <th>{intl.formatMessage({ id: "app.field.isFreeDeliveryEnabled" })}</th>
            <th>{intl.formatMessage({ id: "app.field.limitForFreeDelivery" })}</th>
            <th>{intl.formatMessage({ id: "app.field.action" })}</th>
          </tr>
          {props.data.map((item, index) => (
            <tr key={item.branchId}>
              <td>{index + 1}</td>
              <td>{item.branchName}</td>
              <td>{item.email}</td>
              <td>{item.phoneNo}</td>
              <td>{item.address}</td>
              <td>{item.minimumOrderValue}</td>
              <td>{item.deliveryCharge}</td>
              <td>{item.isFreeDeliveryEnabled === 1 ? 'Yes' : 'No'}</td>
              <td>{item.limitForFreeDelivery}</td>
              {/* <td>{item.openingStatus}</td> */}
              <td>
                <div className={styles.tableActions}>
                  <Tooltip title="Edit" placement="top">
                    <span
                      onClick={() => {
                        props.editItem(item);
                      }}
                      className={styles.actionIcon}
                    >
                      <Icon name="PEN" size="small" />
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={item.isEnabled ? "Disable" : "Enable"}
                    placement="top"
                  >
                    <span style={{ marginRight: "15px" }}>
                      <Switch
                        checked={item.isEnabled}
                        onChange={() => {
                          props.toggleItem(item);
                        }}
                      />
                    </span>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
}

export default StoresTable;
