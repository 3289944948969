import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  Label,
  CustomImage,
  UploadButton,
} from "../../../../components/common";
import { Button, Input, Select, Radio, Modal } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Validations from "../../../../shared/validations/Validations";
import countryCodes from "../../../../shared/constants/countries.json";
import sidebarData from "../../../../shared/constants/sidebar-data.json";
import axios from "../../../../shared/api/axiose";

import { toastr } from "react-redux-toastr";
import "./add-edit-user.scss";

import { checkRtl } from "../../../../shared/utils/rtl";
import { InteractionFilled } from "@ant-design/icons";
import classNames from "classnames";
import { dateFormat } from "../../../../shared/utils/local-storage";
import moment from "moment";
import OTPInput from "react-otp-input";
import { validate } from "numeral";
const { Option } = Select;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
  // formHeader: "add-edit-user-modal__",
  datePicker: "datePicker",
  OTP_modal: "OTP_modal",
  OTP_input: "OTP_input",
};
function ViewCustomer({
  getCustomersData,
  getCustomersDetail,
  onCloseIconClick,
  updateCustomerFlag,
  customerFlag,
  ...props
}) {
  return (
    <CustomModal
      header="View Customer"
      size="tiny"
      // onClose={props.onClose}
      onClick={props.modalToShow === "View Customer"}
      //   onCloseIconClick={closeModal}

      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div> Customer Details</div>
      </Panel>
    </CustomModal>
  );
}

const mapStateToProps = (state) => ({
  enabledPanels: state.user.enterprice.enabledPanels,
  isAddDisabled: state.user.isAddDisabled,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(injectIntl(ViewCustomer));
