import React from "react";
import { Tag } from "antd";

const TAG_COLORS = {
  ACTIVE: "green",
  BLOCKED: "volcano",
  LOCKED: "gold",
  UNLOCKED: "green",
  completed: "green",
  onGoing: "orange",
  scheduled: "yellow",
};

const CustomTag = ({
  isBlocked,
  isLocked,
  isEnabled,
  isVerified,
  isOpen,
  isCityEnable,
  BookingStatus,
  isApproved,
  isDeleted,
}) => {
  if (
    isBlocked === undefined &&
    isLocked === undefined &&
    isEnabled === undefined &&
    isVerified === undefined &&
    isOpen === undefined &&
    isApproved === undefined &&
    isDeleted === undefined &&
    BookingStatus === undefined
  ) {
    return "---";
  }

  if (isLocked !== undefined && isLocked !== null) {
    return isLocked ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.LOCKED}>
        LOCKED
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        OPEN
      </Tag>
    );
  }

  /* Green- Completed Booking
                Red- Cancelled Booking
                Blue- Ongoing Booking
                Orange- Scheduled Booking */

  if (BookingStatus !== undefined && BookingStatus !== null) {
    // ( On going, Completed, Scheduled, Cancelled)

    if (BookingStatus === 1) {
      return (
        <Tag style={{ margin: 0 }} color={TAG_COLORS.onGoing}>
          On Going
        </Tag>
      );
    } else if (BookingStatus === 2) {
      return (
        <Tag style={{ margin: 0 }} color={TAG_COLORS.completed}>
          Completed
        </Tag>
      );
    } else if (BookingStatus === 3) {
      return (
        <Tag style={{ margin: 0 }} color={TAG_COLORS.scheduled}>
          Scheduled
        </Tag>
      );
    } else if (BookingStatus === 4) {
      return (
        <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
          Cancelled
        </Tag>
      );
    }
  }
  if (isBlocked !== undefined && isBlocked !== null) {
    return isBlocked ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        BLOCKED
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        ACTIVE
      </Tag>
    );
  }
  if (isDeleted !== undefined && isDeleted !== null) {
    return isDeleted ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        Deleted
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        Not Delete
      </Tag>
    );
  }
  // if (isCityEnable !== undefined && isCityEnable !== null) {
  //   return !isCityEnable ? (
  //     <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
  //       DIS
  //     </Tag>
  //   ) : (
  //     <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
  //       ENABLED
  //     </Tag>
  //   );
  // }
  if (isEnabled !== undefined && isEnabled !== null) {
    return !isEnabled ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        DISABLED
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        ENABLED
      </Tag>
    );
  }
  if (isVerified !== undefined && isVerified !== null) {
    return !isVerified ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        Unverified
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        Verified
      </Tag>
    );
  }

  if (isApproved !== undefined && isApproved !== null) {
    return !isApproved ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.BLOCKED}>
        DISAPPROVED
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        APPROVED
      </Tag>
    );
  }
  if (isOpen !== undefined && isOpen !== null) {
    return !isOpen ? (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.UNLOCKED}>
        OPEN
      </Tag>
    ) : (
      <Tag style={{ margin: 0 }} color={TAG_COLORS.LOCKED}>
        CLOSED
      </Tag>
    );
  }
  return "---";
};

export default CustomTag;
