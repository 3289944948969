import React from "react";
import './ErrorMessage.scss';
const styles = {
  wrapper: "error-message__wrapper",
  text: "error-message__text",
};
function ErrorMessage({ field, text, float, profilePic, uploadFile, selectOption, workingDays }) {
  const Profile_pic_err = "Please select profile pic"
  const Select_input = "Please select one option"
  const working_day = "Please select at least one day"
  const Upload_file = "Please upload file."

  if(text) {
    return (
      <div style={float ? {position: 'absolute', bottom: '-12px'} : {}} className={styles.wrapper}>
        <span className={styles.text}>{text}</span>
      </div>
    );
  }
  if(profilePic){
    return (
      <div style={float ? { position: 'absolute', bottom: '-12px' } : {}} className={styles.wrapper}>
        <span className={styles.text}>{Profile_pic_err}</span>
      </div>
    );

  }
  if (uploadFile){
    return (
      <div style={float ? { position: 'absolute', bottom: '-12px' } : {}} className={styles.wrapper}>
        <span className={styles.text}>{Upload_file}</span>
      </div>
    );

  }
  if (selectOption) {
    return (
      <div style={float ? { position: 'absolute', bottom: '-12px' } : {}} className={styles.wrapper}>
        <span className={styles.text}>{Select_input}</span>
      </div>
    );

  }
  if (workingDays) {
    return (
      <div style={float ? { position: 'absolute', bottom: '-12px' } : {}} className={styles.wrapper}>
        <span className={styles.text}>{working_day}</span>
      </div>
    );
  }
  
    // if(!(field?.errMsg).length) {
    //     return null;
    // }
  
  return (
    

      
    <div style={float ? {position: 'absolute', bottom: '-12px'} : {}} className={styles.wrapper}>
      {/* <span className={styles.text}>{field.errMsg[0]}</span> */}
    </div> 
    

    
  );
}

export default ErrorMessage;
