import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button } from "antd";
import { useIntl } from "react-intl";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import categoriesAction from "../../redux/categories/categories-actions";

import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
} from "../../components/common";
import { ActivePromoTable, AddEditPromoModalSub } from "./components";

import { PromoCodeData } from "../../FakeData";
import {
  country,
  states,
  cities,
  zipcode,
} from "../../shared/constants/countryFilterData";

import "./active-promo.scss";
import { checkRtl } from "../../shared/utils/rtl";
import { findAllByDisplayValue } from "@testing-library/react";
import axiosConfig from "../../shared/api/axiose";
import axios from "../../shared/api/axios_config";
import ViewUser from "./view-user/view-user";
import { useDispatch } from "react-redux";
import Label from "../../components/common/label/label";

const { Option } = Select;

const styles = {
  wrapper: "active-promo__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "active-promo__menu--left",
  menuRight: "active-promo__menu--right",
  pageSize: "active-promo__records-per-page",
  filterCont: "filterCont",
};

function ActivePromoSub(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [search, updateSearch] = useState("");
  const [getCategoriesData, setCategoriesData] = useState([]);

  const handleSearchChange = (val) => {
    updateSearch(val);
  };
  let debounceTimer;
  function debounce(func, delay) {
    // Clear any previously set debounce timer

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer
    debounceTimer = setTimeout(() => {
      debounceTimer = null;
      func();
    }, delay);
  }
  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });
    let query = {
      search: val,
    };
    let params = {};
    params.params = query;
    clearTimeout(debounceTimer);
    debounce(() => CategoryDataSearcg(params), 1000);
  };

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isDeleteModalVisible: false,
    isLoading: false,
    isViewModalVisible: false,
  });

  const [promocodes, updatePromos] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    total: 0,
    current: 1,
    search: "",
  });

  const [filter, updateFilter] = useState({
    country: {},
    state: {},
    city: {},
    zipcode: {},
  });
  console.log("filter => ", filter);
  /** Instaservice API Intregation */
  const CategoryData = (flag) => {
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
    });

    axios
      .get("/category/")
      .then((res) => {
        console.log("CategoryData", res?.data?.data);
        console.log("CategoryData res", res?.data?.statusCode);
        if (res?.data?.statusCode === 200) {
          setCategoriesData(res?.data?.data);
          if (flag) {
            toastr.success(res?.data?.message);
          }
        }
        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
      });
  };
  const CategoryDataSearcg = (params) => {
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
    });

    axios
      .get("/category/", params)
      .then((res) => {
        console.log("CategoryData", res?.data?.data);
        console.log("CategoryData res", res?.data?.statusCode);
        if (res?.data?.statusCode === 200) {
          setCategoriesData(res?.data?.data);
        }
        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
      });
  };

  useEffect(() => {
    CategoryData();
  }, []);

  const handlePaginationChange = (page, total) => {
    updatePagination({
      ...pagination,
      total: page.total,
      current: page.current,
      skip: (page.current - 1) * page.pageSize,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      pageSize: val,
    });
  };

  const openAddPromoModal = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "ADD",
    });
  };

  const editCategory = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
    data.promoId = modalControls.data.promoId;
    console.log(data);
    // alert("Edit promo code");
  };

  const openEditPromoModal = (val) => {
    console.log("openEditPromoModal", val);
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "EDIT",
      data: val,
    });
  };
  const openViewPromoCodeModal = (val) => {
    console.log("openViewPromoCodeModal", val);
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "VIEW",
      data: val,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: false,
      // data: {},
    });
  };

  const getPromoData = () => {
    // PromoCodeData
    updateTableControls({
      isTableEmpty: false,
      isTableLoading: false,
    });
    updatePromos({
      ...promocodes,
      data: PromoCodeData(),
      isLoading: false,
      notFound: false,
      count: 6,
    });
    // axios
    //   .get(`/getPromoCode`, {
    //     params: removeEmptyKeys({
    //       isActive: 1,
    //       limit: pagination.pageSize,
    //       skip: (pagination.current - 1) * pagination.pageSize,
    //       search: searchDelayed,
    //     }),
    //   })
    //   .then((res) => {

    //     if (res.data.data.count) {
    //       updatePromos({
    //         ...promocodes,
    //         data: res.data.data.promoCodes,
    //         isLoading: false,
    //         notFound: false,
    //         count: res.data.data.count,
    //       });
    //       updatePagination({
    //         ...pagination,
    //         total:res.data.data.count
    //       })
    //       console.log(res.data.data.count,"working")

    //       updateTableControls({
    //         isTableEmpty: false,
    //         isTableLoading: false,
    //       });
    //     } else {
    //       toastr.error("Error", "No promocodes found");
    //       updateTableControls({
    //         isTableEmpty: true,
    //         isTableLoading: false,
    //       });
    //       updatePromos({
    //         ...promocodes,
    //         data: [],
    //         isLoading: false,
    //         notFound: true,
    //         count: 0,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     updateTableControls({
    //       ...tableControls,
    //       isTableLoading: false,
    //     })
    //     // console.log(err);
    //   });
  };

  const removeCategory = (data) => {
    console.log("removeCategory", data);

    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleCategoryDelete = (id) => {
    console.log("handleCategoryDelete", id);
    updateModalControls({
      ...modalControls,
      isLoading: true,
      isDeleteModalVisible: true,
    });
    axiosConfig
      .delete(`/category/${id}`)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          toastr.success("Category Deleted successfully");
          updateModalControls({
            ...modalControls,
            isLoading: false,
            isDeleteModalVisible: false,
          });
          CategoryData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // alert("Promo code delete")
  };
  const openViewModal = (data) => {
    console.log("openViewModal", data);
    updateModalControls({
      ...modalControls,
      isViewModalVisible: true,
      data,
    });
  };
  const handleViewModalClose = () => {
    updateModalControls({
      ...modalControls,
      isViewModalVisible: false,
    });
  };

  useEffect(() => {
    getPromoData();
  }, [pagination.pageSize, pagination.current, searchDelayed]);

  const handleFilterCountry = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      country: data,
    });
  };
  const handleFilterState = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      state: data,
    });
  };
  const handleFilterCity = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      city: data,
    });
  };

  const handleFilterZipcode = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      zipcode: data,
    });
  };

  return (
    <div className={styles.wrapper}>
      <h1>{"  "}</h1>
      <Header title="Categories" />

      <AddEditPromoModalSub
        isVisible={modalControls.isVisible}
        CategoryData={CategoryData}
        modalToShow={modalControls.type}
        onCloseIconClick={handleModalClose}
        data={modalControls.data}
        editCategory={editCategory}
        isLoading={modalControls.isLoading}
      />

      <ConfirmModal
        header={`Delete `}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={handleCategoryDelete}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        data={modalControls?.data}
        isLoading={modalControls.isLoading}
      />

      <ViewUser
        isVisible={modalControls?.isViewModalVisible}
        onCloseIconClick={handleViewModalClose}
        // modalToShow={modalToShow}

        data={modalControls?.data}
      />

      <div className={styles.content}>
        <div className={styles.menu}>
          <header></header>
          <section>
            <div>
              <DelayedInput
                onChange={handleSearchChange}
                value={search}
                callback={handleSearch}
                delay={500}
                minLength={3}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
            <div>
              {
                <Button
                  type="primary"
                  disabled={props.isAddDisabled}
                  shape={undefined}
                  size="medium"
                  onClick={openAddPromoModal}
                >
                  Add Categories
                </Button>
              }
            </div>
          </section>
        </div>

        <ActivePromoTable
          data={getCategoriesData}
          // data={promocodes.data}
          isLoading={tableControls.isTableLoading}
          tableControls={tableControls}
          updateTableControls={updateTableControls}
          isEmpty={tableControls.isTableEmpty}
          editItem={openEditPromoModal}
          viewDetails={openViewPromoCodeModal}
          onChange={handlePaginationChange}
          removeItem={removeCategory}
          pagination={pagination}
          columnWidth={100}
          viewItem={openViewModal}
          CategoryData={CategoryData}
          width
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ActivePromoSub);
