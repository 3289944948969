import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { CustomModal, Panel, ErrorMessage } from "../../../components/common";
import { Tag } from "antd";
import "./view-user.scss";



const styles = {
  wrapper: "view-user__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  label: "view-user__label",
  value: "view-user__value",
  row: "view-user__row",
};
function ViewUser({data, ...props}) {
  const intl = useIntl();
  console.log("ViewUser" , data)

  



 

  return (
    <CustomModal
      size="tiny"
      
      onCloseIconClick={props?.onCloseIconClick}
      header="app.pageTitle.viewSubAdmin"
      isVisible={props?.isVisible}
    >
      <Panel isLoading={props?.isLoading} className={styles.formModal}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.name" })}
            </div>
            <div className={styles.value}>
              {/* {props?.data.name} */}
              
              </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.email" })}
            </div>
            <div className={styles.value}>
              {/* {props?.data.email} */}
              </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.userType" })}
            </div>
            <div className={styles.value}>
              {/* {props?.data.userType} */}
              </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.countryCode" })}
            </div>
            <div className={styles.value}>
              {/* {props?.data.countryCode} */}
              
              </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.phoneNumber" })}
            </div>
            <div className={styles.value}>
              {/* {props?.data.phoneNo} */}
              </div>
          </div>
          
        </div>
      </Panel>
    </CustomModal>
  );
}

export default ViewUser;
