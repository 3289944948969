const rtl = {
    ar: true,
    en: false,
    fr: false,
    de: false,
    tr: false,
}

const checkRtl = () => {
    const lang = sessionStorage.getItem('trollyAdmin_language');
    if(lang) {
        return rtl[lang] === true;
    }
    return false;
}

const getLanguage = () => {
    const lang = sessionStorage.getItem('trollyAdmin_language');
    if(!lang) return 'en';
    return lang;
}
export {checkRtl, getLanguage};