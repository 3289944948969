import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { CustomModal, Panel, ErrorMessage } from "../../../components/common";
import { Tag } from "antd";
import "./view-user.scss";

import sidebarData from "../../../shared/constants/sidebar-data.json";
import axiosConfig from "../../../shared/api/axios_config";
import moment from "moment";
import { Rate } from "antd";
import { dateFormat } from "../../../shared/utils/local-storage";

const styles = {
  wrapper: "view-user__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  label: "view-user__label",
  value: "view-user__value",
  row: "view-user__row",
};
function ViewUser(props) {
  const intl = useIntl();
  console.log("Customer Review=>", props.data);

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={props.onCloseIconClick}
      header="Review Details"
      isVisible={props.isVisible}
    >
      {/* 1.Service Provider's name
2.Service Provider's Contact no
3.Business Name
4.Order No
5.Category
6.Service
7.Service date
8.Review Date
9.Customer Name
10.Customer Phone No
11.Country (Customer)
12.State (Customer)
13.City (Customer)
14.Zip Code (Customer)
15.Ratings
16.Reviews */}
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.label}>Service Provider's name</div>
            <div className={styles.value}></div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Service Provider's Contact no</div>
            <div className={styles.value}></div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Business Name</div>
            <div className={styles.value}></div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Order No</div>
            <div className={styles.value}></div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Category</div>
            <div className={styles.value}></div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Service</div>
            <div className={styles.value}></div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Service date</div>
            <div className={styles.value}>
              {props?.data?.createdAt
                ? moment(props?.data?.createdAt).format(dateFormat)
                : "Not Found"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Review Date</div>
            <div className={styles.value}>
              {!props?.data?.createdAt
                ? moment(props?.data?.createdAt).format(dateFormat)
                : "Not Found"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Customer Name</div>
            <div className={styles.value}></div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Customer Phone No</div>
            <div className={styles.value}></div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Country (Customer)</div>
            <div className={styles.value}></div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>State (Customer)</div>
            <div className={styles.value}></div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>City (Customer)</div>
            <div className={styles.value}></div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Zip Code (Customer)</div>
            <div className={styles.value}></div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Ratings</div>
            <div className={styles.value}>
              {
                <Rate
                  style={{ color: "#ED432A" }}
                  disabled
                  value={props?.data?.rating}
                />
              }
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Reviews</div>
            <div className={styles.value}>
              {props?.data?.title ? props?.data?.title : "Not Found"}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Updated At</div>

            <div className={styles.value}>
              {props?.data?.updatedAt
                ? moment(props?.data?.updatedAt).format("MM/DD/YY")
                : "Not Found"}
            </div>
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default ViewUser;
