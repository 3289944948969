import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button } from "antd";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";

import { AdvertisementsTable, AddEditAdvertisement } from "./components";
import AddEditPromoContainer from "./components/add-edit-promo-modal/add-edit-promo-modal";
import { Header, DelayedInput } from "../../components/common";

import "./advertisements.scss";
import userActions from "../../redux/user-info/user-actions";
import {
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import { CustomTable, CustomTag } from "../../components/common";
import { questionaryData } from "../../FakeData";
import axiosConfig from "../../shared/api/axiose";
import moment from "moment";
import { Modal } from "antd";
import ViewUser from "./components/view-user/view-user";
import { Dropdown, Tooltip, Menu, Switch } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const styles = {
  wrapper: "advertisements__wrapper",
  menu: "custom-menu",
  content: "custom-section",
};

const { Option } = Select;

function Advertisements(props) {
  const questionType = [
    {
      value: "1",
      text: "String",
    },
    {
      value: "2",
      text: "Number",
    },
    {
      value: "3",
      text: "Phone No",
    },
    {
      value: "4",
      text: "Email",
    },
    {
      value: "5",
      text: "Document",
    },
    {
      value: "6",
      text: "Date only future",
    },
    {
      value: "7",
      text: "Date",
    },
    {
      value: "8",
      text: "Checklist",
    },
  ];
  const intl = useIntl();
  const [pagination, updatePagination] = useState({
    pageType: undefined,
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
  });

  const handlePaginationChange = ({ current, pageSize, total }) => {
    updatePagination({
      ...pagination,
      current,
      total,
      pageSize,
      skip: (current - 1) * pagination.pageSize,
    });
  };

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [questionData, updateQuestionData] = useState({
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    viewModal_IsVisible: false,

    modalType: "",
    data: {},
    isLoading: false,
  });

  const openAddAdvertisementModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data: {},
      modalType: "ADD",
    });
  };

  const handleEdit = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      modalType: "EDIT",
    });
  };
  const handleViewModal = (data) => {
    updateModalControls({
      ...modalControls,
      viewModal_IsVisible: true,
      data,
      modalType: "VIEW",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      viewModal_IsVisible: false,

      // data: {},
    });
  };

  const showDeleteConfirm = (item) => {
    Modal.confirm({
      title: "Delete Question ?",
      width: 520,
      closable: true,
      // icon: <ExclamationCircleOutlined />,
      content: "Added question  will be deleted",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      okButtonProps: {
        type: "primary",
        // danger:"true",
      },
      onOk: () => {
        console.log(item);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleViewModal(item)}>
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>

        <Menu.Item onClick={() => handleEdit(item)}>Edit</Menu.Item>

        {item.isDisabled && <Menu.Item onClick={"/"}>Enable</Menu.Item>}
        {!item.isDisabled && <Menu.Item onClick={"/"}>Disable</Menu.Item>}
        <Menu.Item
          onClick={() => {
            showDeleteConfirm(item?.id);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "question",
      title: "Question",
    },
    {
      title: "Question Type",
      dataIndex: "questionType",
      render: (item) => questionType[item - 1].text,
    },
    {
      dataIndex: "tag",
      title: "Tags",
    },
    {
      dataIndex: "inputOptions",
      title: "Input Options",
      render: (item) => (item ? item : "N/A"),
    },

    {
      dataIndex: "createdAt",
      title: "Created At",
      render: (item) => moment(item).format("DD/MM/YY"),
    },

    {
      dataIndex: "isDisabled",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        console.log("city status", item);
        return <CustomTag isEnabled={!item} />;
      },
    },

    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        // debugger;
        return (
          <center>
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          </center>
        );
      },
    },
  ];

  // Mount

  const addAdvertisement = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
  };

  const toggleAdvertisement = (bannerData, type) => {};

  const editAdvertisement = (data) => {
    alert("Edit");
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
  };

  // Advertisements
  useEffect(() => {}, []);

  return (
    <div className={styles.wrapper}>
      <Header title="General FAQ's" path="/home/General FAQ's" />

      <ViewUser
        isVisible={modalControls?.viewModal_IsVisible}
        onCloseIconClick={handleModalClose}
        // enabledVerticals={this.props.enabledVerticals}
        modalToShow={modalControls.isVisible}
        questionType={questionType}
        data={modalControls.data}
      />
      <AddEditPromoContainer
        editStoreId={stores.selected}
        modalType={modalControls?.modalType}
        data={modalControls.data}
        isVisible={modalControls?.isVisible}
        onCloseIconClick={handleModalClose}
        addAdvertisement={addAdvertisement}
        editAdvertisement={editAdvertisement}
        isLoading={modalControls.isLoading}
        questionType={questionType}
        getQuestionasData={"/"}
      />

      <div className={styles.content}>
        <div
          className={styles.menu}
          style={{ justifyContent: "space-between" }}
        >
          <section style={{ gap: "1rem" }}>
            <div>
              <DelayedInput
                delay={500}
                minLength={2}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
            <div>
              {
                <Select
                  showSearch
                  style={{ width: 250 }}
                  placeholder="Select Tag"
                >
                  {questionType.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.text}
                    </Option>
                  ))}
                </Select>
              }
            </div>
          </section>

          <div>
            {
              <Button
                onClick={openAddAdvertisementModal}
                type="primary"
                shape={undefined}
                size="medium"
              >
                {"Add FAQ"}
              </Button>
            }
          </div>
        </div>

        <CustomTable
          isLoading={questionData.isLoading}
          columns={columns}
          dataSource={questionData.data}
          // dataSource={tableControls.data}
          toggleItemBlock={toggleAdvertisement}
          onChange={handlePaginationChange}
          pagination={pagination}
          current={pagination.current}
          pageSize={pagination.pageSize}
          questionary={true}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
  isAddDisabled: state.user.isAddDisabled,
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
});

export default connect(mapStateToProps)(Advertisements);
