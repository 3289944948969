import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Drawer, Select, Input, Button } from "antd";
import { DevStorage } from "../../shared/utils/local-storage";

import styles from "./dev.module.scss";

const formGroup = "custom-form-group";
const formItem = "custom-form-item";
const DevSettings = (props) => {
  const [cIndex, updateCIndex] = useState(0);
  const [pIndex, updatePIndex] = useState(0);
  const [api, updateApi] = useState(undefined);
  const [googleApiKey, updateGoogleApiKey] = useState(undefined);
  useEffect(() => {
    // if(props.visible) {
    const { clientIndex, panelIndex, endpoint, domainName, apiKey } =
      DevStorage.getData();
    updateCIndex(clientIndex);
    updatePIndex(panelIndex);
    updateApi(endpoint);
    updateGoogleApiKey(apiKey);
    //   debugger;
    // }
  }, [props.visible]);

  return (
    <Drawer
      title="Dev Settings"
      placement="right"
      width="40%"
      visible={props.visible}
      onClose={props.onClose}
      footer={
        <div className={styles.footer}>
          <Button onClick={"/"} size="large" type="primary">
            Apply
          </Button>
          <Button onClick={"/"} size="large">
            Restore Default
          </Button>
        </div>
      }
    >
      <div className={styles.wrapper}>
        <i style={{ color: "green", marginBottom: "1rem" }}>
          Different settings can be user in parallel tabs
        </i>
        <div className={styles.content}>
          <h3>Panel Settings</h3>
          <div className={formGroup}>
            <div className={formItem}>
              <label>Client</label>
            </div>
            <div className={formItem}>
              <label>Panel</label>
            </div>
          </div>
          <div className={formItem}>
            <label htmlFor="">Alternate Google API Key (Optional)</label>
            <Input
              placeholder="Google API Key"
              onChange={(e) => {
                updateGoogleApiKey(e.target.value);
              }}
              value={googleApiKey}
            ></Input>
          </div>
        </div>
        <div className={styles.content}>
          <h3>Backend Settings</h3>
          <div className={formItem}>
            <label htmlFor="">API URL</label>
            <Input
              // placeholder="http://13.245.63.240:3002"
              onChange={(e) => {
                updateApi(e.target.value);
              }}
              value={api}
            ></Input>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(DevSettings);
