import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import classNames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";
import { Input, Button, Select, Radio } from "antd";

import sidebarData from "../../shared/constants/sidebar-data.json";
// import { ErrorMessage } from "../../components/common";
import { ErrorMessage, Icon, Label } from "../../components/common";
import Validations from "../../shared/validations/Validations";
import "./login.scss";
import LogoImage from "../../assets/images/signinLogo.png";

import BusinessLogo_two from "../../assets/images/logoexc-removebg-preview.png";

import axios from "../../shared/api/axiose";
// import axios from "axios";

import { useDispatch } from "react-redux";
import ForgetPassword from "./../../containers/external/forget-password/forget-password";

import ForgotPassword from "../forgot-password";

import userActions from "../../redux/user-info/user-actions";
import OTPVerificationModal from "./verificationOTP";

import {
  setAccessToken,
  setAdminInfo,
  setRoles,
  setUserName,
} from "../../shared/utils/local-storage";

import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

const styles = {
  wrapper: "login__wrapper",
  contentContainer: "login__content-container",
  input: "login__input",
  logoImage: "login__logo-image",
  loginButton: "login__login-button",
  formItem: "login__form-item",
  formContainer: "login__form-container",
  logoContainer: "login__logo-container",
  welcomeContainer: "login__welcome-container",
  welcomeTitle: "login__welcome-container",
  welcomeSubTitle: "login__welcome-container__sub-title",
  password: "login__form-item__password",
  phoneInput: "custom-phoneInput",

  formGroup: "custom-form-group",
  form_Item: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
};

class Login extends React.Component {
  state = {
    email: Validations.validateEmail(""),
    password: Validations.validatePassword(""),
    phone: Validations.validateNumericValue(""),
    isLoading: false,
    isPristine: true,
    isPasswordVisible: false,
    foodUserType: 5,
    isForgotPasswordModalVisible: false,
    isVisible: false,
    loginType: 0,
    loggedInWith: "",
    loggedInId: "",
    countryCode: Validations.validateTextField(""),
    errorMessage: "",
  };

  handleLoginClick = () => {
    console.log("api called ");
    const apiUrl = "/admins/login";
    const payload = {
      email: this.state.email.value,
      password: this.state.password.value,
    };

    if (this.state.isPristine && this.isFormValid()) {
      this.isLoading(true);

      axios
        .post(apiUrl, payload)
        .then((response) => {
          console.log(response.data);
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              this.props.history.push("/home/dashboard");
            }, 200);
            toast.success("Welcome! Login Successful");

            localStorage.setItem(
              "userInformation",
              JSON.stringify(response.data.data)
            );
          }
          localStorage.setItem(
            "Admin-Refresh-Token",
            response.data.data.refreshToken
          );
          const access_token = response?.data?.data?.accessToken || null;
          console.log("Token==>", access_token);
          setAccessToken(access_token);
        })
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.statusCode === 400) {
            this.setState({
              errorMessage: "Incorrect Email or Password",
            });
            toast.error("Incorrect Email or Password");
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        })
        .finally(() => {
          this.isLoading(false);
        });
    } else {
      toast.error("Please enter a valid email and password.");
    }
  };

  isFormValid = () => {
    let isValid = true;
    isValid =
      isValid &&
      (this.state.email.isValid || this.state.phone.isValid) &&
      this.state.password.isValid;
    return isValid;
  };

  handleLogin_insta = () => {
    setTimeout(() => {
      this.props.history.push("/home/dashboard");
    }, 500);
  };
  isLoading = (loading) => {
    this.setState({ isLoading: loading });
  };
  togglePassword = () => {
    this.setState((prev) => ({
      ...prev,
      isPasswordVisible: !prev.isPasswordVisible,
    }));
  };
  toggleForgotPassword = () => {
    this.setState((prev) => ({
      ...prev,
      isForgotPasswordModalVisible: !prev.isForgotPasswordModalVisible,
    }));
  };

  handleEmailChange = (e) => {
    this.setState({
      email: Validations.validateEmail(e.target.value),
    });
  };
  handlePhoneNoChange = (e) => {
    console.log("handlePhoneNoChange => ", e);
    this.setState({
      phone: Validations.validateNumericValue(e.target.value),
    });
  };
  handleCountryCode = (e) => {
    this.setState({
      countryCode: Validations.validateTextField(e.target.value),
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: Validations.validatePassword(e.target.value),
    });
  };
  handleModalClose = () => {
    this.setState({
      isVisible: false,
    });
    sessionStorage.removeItem("Admin_roles");
    sessionStorage.removeItem("User_name");
    sessionStorage.removeItem("Admin_info");
    sessionStorage.removeItem("Admin_access_token");
  };

  OTPCheck_confirmation = () => {
    setTimeout(() => {
      this.props.history.push("/home/dashboard");
    }, 500);
  };

  render() {
    const { email, password } = this.state;
    return (
      <>
        <OTPVerificationModal
          // isVisible={true}
          isVisible={this.state.isVisible}
          onOk={this.OTPCheck_confirmation}
          onCancel={this.handleModalClose}
          loggedInWith={this.state.loggedInWith}
        />
        <form className={styles.wrapper}>
          <ForgotPassword
            isVisible={this.state.isForgotPasswordModalVisible}
            onCloseIconClick={this.toggleForgotPassword}
            loginType={this.state.loginType}
          />
          <div className={styles.logoContainer}>
            <img className={styles.logoImage} src={BusinessLogo_two} alt="" />
          </div>
          <div className={styles.welcomeContainer}>
            <h1 className={styles.welcomeTitle}>
              {this.props.enterprice.name}
            </h1>
            <h2 className={styles.welcomeTitle}>Welcome Back</h2>
          </div>

          <div className={styles.formContainer}>
            <Label value="Login " />
            <div
              className={styles.formGroup}
              style={{ marginBottom: "1rem", color: "3f3fd4" }}
            ></div>

            <div style={{ marginBottom: "1rem" }}>
              <Input
                onChange={this.handleEmailChange}
                value={email.value}
                icon="user"
                placeholder="Enter E-Mail"
              />
              {!email.isValid && !email.isPristine && (
                <ErrorMessage text={email.errMsg} />
              )}
            </div>

            <div>
              <Input.Password
                onChange={this.handlePasswordChange}
                value={password.value}
                type="password"
                placeholder={this.props.intl.formatMessage({
                  id: "app.placeholder.password",
                })}
              />

              {!password.isValid && !password.isPristine && (
                <ErrorMessage text={password.errMsg} />
              )}
            </div>
            {!this.state.password.isValid &&
              !this.state.password.isPristine && (
                <ErrorMessage text={this.state.errorMessage} />
              )}
            {/* <div>
              <span>Terms and Conditions</span>
            </div> */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{
                  color: "#3f3fd4",
                }}
                onClick={this.toggleForgotPassword}
                type="link"
              >
                Forgot Password ?
              </Button>
            </div>

            <Button
              loading={this.state.isLoading}
              className={styles.loginButton}
              type="primary"
              shape={undefined}
              size="large"
              // htmlType="submit"
              onClick={this.handleLoginClick}
            >
              <FormattedMessage id="app.field.signin" />
            </Button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  enterprice: state.user.enterprice,
  settingsVertical: state.user.enterprice.verticalType,
  panelType: state.user.enterprice.panelType,
  verticle: state.user.enterprice.verticalType,
});

export default connect(mapStateToProps)(injectIntl(Login));
