import React, { useState, useEffect, useRef } from "react";
import { Input, Button, DatePicker, Select } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import classNames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../../components/common";
import axios from "../../../../shared/api/axiose";
import Validations from "../../../../shared/validations/Validations";
import { multiSort } from "../../../../shared/utils/object-manipulation";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-promo-sub.scss";
import { dateFormat } from "../../../../shared/utils/local-storage";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formModal_wrapper: "active-promo__add-edit-promo-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "active-promo__add-edit-promo-modal__image-container",
  imageContainerImage:
    "active-promo__add-edit-promo-modal__image-container__image",
  imageContainerForm:
    "active-promo__add-edit-promo-modal__image-container__form",
  spanImp: "active-promo__add-edit-promo-modal__span-img",
  disabledColor: "disabledColor",
};

const errorText = "Field cannot be empty";

const categoryType = [
  {
    value: "1",
    text: "On demand",
  },
  {
    value: "2",
    text: "Scheduled",
  },
  {
    value: "3",
    text: "Request for Quote",
  },
];

function AddEditPromoContainerSub({
  isVisible,
  modalToShow,
  onCloseIconClick,
  data,
  editCategory,
  isLoading,
  CategoryData,
  ...props
}) {
  console.log("AddEditPromoContainer", data);
  console.log("modalToShow", modalToShow);

  /** Instaservice States */
  const intl = useIntl();
  const imageContainerRef = useRef(null);
  const [isPristine, togglePristine] = useState(true);
  const [getCategories, setCategoriesData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [category, updateCategory] = useState(
    Validations.validateTextField("")
  );
  console.log(" Category Input enabledDate category", category);
  const [enabledDate, updateEnabledDate] = useState(
    Validations.validateTextField(undefined)
  );
  console.log("Category Input enabledDate", enabledDate);

  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  console.log("Category Input imageURL", imageURL);

  const [selectedCities, updateSelectedCities] = useState([]);
  const [selectedState, updateSelectedState] = useState([]);
  const [selectedCountry, updateSelectedCountry] = useState("");
  const [status, updateStatus] = useState();

  /** Instaservice States */

  const resetForm = () => {
    console.log("setForm=>", data);
    togglePristine(true);
    updateEnabledDate(Validations.validateTextField(""));
    updateCategory(Validations.validateTextField(""));
    changeImageURL(Validations.validateTextField(""));
  };
  async function getCategoriesData() {
    try {
      const response = await axios.get("/category/");
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getCategoriesData().then((data) => setCategoriesData(data));
  }, []);

  const setForm = () => {
    // debugger
    console.log("setForm=>", data);
    let status = "Enabled";
    if (!data?.isEnabled) {
      status = "Disabled";
    }
    updateStatus(status);

    updateEnabledDate(
      Validations.validateTextField(moment(data?.createdAt).format(dateFormat))
    );
    updateCategory(Validations.validateTextField(data?.title));
    changeImageURL(Validations.validateTextField(data?.subCategoryIcon));
  };

  useEffect(() => {
    if (modalToShow === "EDIT" || modalToShow == "VIEW") {
      setForm();
    } else {
      resetForm();
    }
  }, [modalToShow, data]);

  // const categoriesDataCustom = [];
  const handleAdd = () => {
    const payload = {
      title: category.value,
      parentCategoryId: selectedCategoryId,
      subCategoryIcon: imageURL.value,
    };
    axios
      .post("/subcategory", payload)
      .then((res) => {
        console.log("handleAdd", res);
        if (res?.data?.statusCode === 200) {
          onCloseIconClick();
          resetForm();
          CategoryData();
          toast.success("Category added Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEdit = (id) => {
    const payload = {
      title: category.value,
      parentCategoryId: 5,
      subCategoryIcon: imageURL.value,
    };
    console.log("handleEdit payload", payload);
    // onCloseIconClick();
    axios
      .put(`/categories/${id}`, payload)
      .then((res) => {
        console.log("handleAdd", res);
        if (res?.data?.statusCode === 200) {
          onCloseIconClick();
          resetForm();
          CategoryData();
          toast.success("Category Edit Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.res.data.messageCode);
      });
  };

  const isFormValid = () => {
    let isValid = true;

    isValid = isValid && imageURL.isValid && category.isValid;

    return isValid;
  };

  const closeModal = () => {
    onCloseIconClick();
    resetForm();
  };
  const handleCategoryType = (val) => {
    updateEnabledDate(Validations.validateTextField(val));
  };

  const handlecategoryName = (e) => {
    updateCategory(Validations.validateTextField(e.target.value));
  };
  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return toastr.warning(
        intl.formatMessage({ id: "app.message.formCheck" })
      );
    }
    if (modalToShow === "ADD") {
      handleAdd();
    } else if (modalToShow === "EDIT") {
      // editCategory();
      handleEdit(data?.id);
    }
  };

  const handleImageChange = (value) => {
    changeImageURL(Validations.validateTextField(value));
  };

  const handelCitiesSelect = (value) => {
    console.log("select cities=>", value);
    updateSelectedCities(value);
  };
  const handleStateSelect = (value) => {
    updateSelectedState(value);
  };
  const handelCountry = (value) => {
    console.log("select county=>", value);
    updateSelectedCountry(value);
  };
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        modalToShow === "ADD"
          ? "Add Categories"
          : modalToShow === "EDIT"
          ? "Edit Categories"
          : "View Categories"
      }
      action={
        modalToShow !== "VIEW" ? (
          <>
            {" "}
            <Button type="default" shape={undefined} onClick={closeModal}>
              {intl.formatMessage({ id: "app.button.cancel" })}
            </Button>
            <Button
              loading={isLoading}
              type="primary"
              shape={undefined}
              onClick={handleAdd}
            >
              {intl.formatMessage({ id: "app.button.submit" })}
            </Button>
          </>
        ) : (
          ""
        )
      }
      isVisible={isVisible}
    >
      <Panel
        isModal
        isLoading={isLoading}
        className={classNames({
          [styles.formModal]: true,
          [styles.formModal_wrapper]: true,
        })}
      >
        <div className={styles.imageContainer}>
          <div ref={imageContainerRef} className={styles.imageContainerImage}>
            <CustomImage src={imageURL.value} />
            {modalToShow !== "VIEW" ? (
              <UploadButton
                // disabled
                parentRef={imageContainerRef}
                type="fileUpload"
                resultType="url"
                accept="image/*"
                id="913fjhiu4ng2vri"
                text="Upload Image(300x300)"
                onChange={handleImageChange}
              />
            ) : (
              ""
            )}
            {!isPristine && !imageURL.value && (
              <ErrorMessage field={imageURL} />
            )}
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Sub Category Name" />
            <Input
              disabled={modalToShow == "VIEW"}
              placeholder=" Sub Category Name"
              value={category.value}
              onChange={(e) => handlecategoryName(e)}
            />
            {!isPristine && <ErrorMessage field={category} />}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Select Category" />
            <Select
              showSearch
              placeholder="Category"
              onChange={(value) => setSelectedCategoryId(value)}
            >
              {/* {getCategories.map((item) => (
                <Option key={item.id} value={item?.id}>
                  {item?.title}
                </Option>
              ))} */}
            </Select>
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formItem}></div>
        </div>
        {modalToShow == "VIEW" ? (
          <>
            <div className={styles.formItem}>
              <Label value="Status" />
              <Input
                disabled={modalToShow == "VIEW"}
                placeholder="Status"
                value={status}
                // onChange={(e) => handlecategoryName(e)}
              />
              {/* {!isPristine && <ErrorMessage field={category} />} */}
            </div>
          </>
        ) : (
          ""
        )}
      </Panel>
    </CustomModal>
  );
}
const mapState = (state) => ({
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
});
export default connect(mapState)(AddEditPromoContainerSub);
