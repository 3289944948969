import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button } from "antd";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";

import { AdvertisementsTable, AddEditAdvertisement } from "./components";
import AddEditPromoContainer from "./components/add-edit-promo-modal/add-edit-promo-modal";
import { Header, DelayedInput } from "../../components/common";

import "./advertisements.scss";
import userActions from "../../redux/user-info/user-actions";
import {
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import { CustomTable, CustomTag } from "../../components/common";
import { questionaryData } from "../../FakeData";
import axiosConfig from "../../shared/api/axiose";
import moment from "moment";
import { Modal } from "antd";
import ViewUser from "./components/view-user/view-user";
import { Dropdown, Tooltip, Menu, Switch } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const styles = {
  wrapper: "advertisements__wrapper",
  menu: "custom-menu",
  content: "custom-section",
};

const { Option } = Select;

function TrainingVideo(props) {
  const questionType = [
    {
      value: "1",
      text: "Business Owner",
    },
    {
      value: "2",
      text: "Customer",
    },
  ];
  const intl = useIntl();
  const [pagination, updatePagination] = useState({
    pageType: undefined,
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
  });

  const handlePaginationChange = ({ current, pageSize, total }) => {
    updatePagination({
      ...pagination,
      current,
      total,
      pageSize,
      skip: (current - 1) * pagination.pageSize,
    });
  };

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });
  const [serviceData, updateServiceData] = useState([]);

  const [trainingVideos, updateTrainingVideos] = useState({
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    viewModal_IsVisible: false,

    modalType: "",
    data: {},
    isLoading: false,
  });

  const openAddAdvertisementModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data: {},
      modalType: "ADD",
    });
  };

  const handleEdit = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      modalType: "EDIT",
    });
  };
  const handleViewModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      modalType: "VIEW",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      viewModal_IsVisible: false,

      // data: {},
    });
  };
  const handleEnableDisable = (item) => {
    axiosConfig
      .put(`/training/enableDisable/${item.id}`, {
        status: !item.status,
      })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          toastr.success(res?.data?.message);
          getAllVideos();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    axiosConfig
      .delete(`/training/${id}`)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          toastr.success(res?.data?.message);
          getAllVideos();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showDeleteConfirm = (item) => {
    Modal.confirm({
      title: "Delete Question ?",
      width: 520,
      closable: true,
      // icon: <ExclamationCircleOutlined />,
      content: "Added question  will be deleted",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      okButtonProps: {
        type: "primary",
        // danger:"true",
      },
      onOk: () => {
        console.log(item);

        handleDelete(item);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleViewModal(item)}>
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>

        <Menu.Item onClick={() => handleEdit(item)}>Edit</Menu.Item>

        {item.status && (
          <Menu.Item onClick={() => handleEnableDisable(item, false)}>
            Disable
          </Menu.Item>
        )}
        {!item.status && (
          <Menu.Item onClick={() => handleEnableDisable(item, true)}>
            Enable
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            showDeleteConfirm(item?.id);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "title",
      title: "For Service",
    },
    {
      dataIndex: "moduleType",
      title: "For Module",
    },

    {
      dataIndex: "createdAt",
      title: "Created At",
      render: (item) => moment(item).format("DD/MM/YY"),
    },

    {
      dataIndex: "videoLink",
      title: "Video Url",
      render: (item) => (
        <a style={{ color: "blue" }} href={item} target="_blank">
          {" "}
          Click hear
        </a>
      ),
    },

    {
      dataIndex: "status",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        return <CustomTag isEnabled={item} />;
      },
    },

    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        // debugger;
        return (
          <center>
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          </center>
        );
      },
    },
  ];

  // Mount

  const getQuestionasData = () => {};

  const addAdvertisement = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
  };

  const toggleAdvertisement = (bannerData, type) => {};

  const editAdvertisement = (data) => {
    alert("Edit");
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
  };

  // Advertisements
  const ServiceData = () => {
    axiosConfig
      .get("/home-services")
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          updateServiceData(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllVideos = () => {
    updateTrainingVideos({
      ...trainingVideos,

      isLoading: true,
    });
    axiosConfig
      .get("/training")
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          updateTrainingVideos({
            ...trainingVideos,
            data: res?.data?.data,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        updateTrainingVideos({
          ...trainingVideos,

          isLoading: true,
        });
      });
  };
  useEffect(() => {
    ServiceData();
    getAllVideos();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Header title="Training Videos" path="/home/Training Videos" />

      <AddEditPromoContainer
        editStoreId={stores.selected}
        modalType={modalControls?.modalType}
        data={modalControls.data}
        isVisible={modalControls?.isVisible}
        onCloseIconClick={handleModalClose}
        addAdvertisement={addAdvertisement}
        editAdvertisement={editAdvertisement}
        isLoading={modalControls.isLoading}
        questionType={questionType}
        getAllVideos={getAllVideos}
        serviceData={serviceData}
      />

      <div className={styles.content}>
        <div
          className={styles.menu}
          style={{ justifyContent: "space-between" }}
        >
          <section style={{ gap: "1rem" }}>
            <div>
              <DelayedInput
                delay={500}
                minLength={2}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
          </section>

          <div>
            {
              <Button
                onClick={openAddAdvertisementModal}
                type="primary"
                shape={undefined}
                size="medium"
              >
                {"Add Training Video"}
              </Button>
            }
          </div>
        </div>

        <CustomTable
          isLoading={trainingVideos?.isLoading}
          columns={columns}
          dataSource={trainingVideos.data}
          // dataSource={tableControls.data}
          toggleItemBlock={toggleAdvertisement}
          onChange={handlePaginationChange}
          pagination={pagination}
          current={pagination.current}
          pageSize={pagination.pageSize}
          questionary={true}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(TrainingVideo);
