import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button, Tag, Input } from "antd";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

import {
  Header,
  PaginationInfo,
  DelayedInput,
  Label,
  CustomTag,
  CustomTable,
} from "../../components/common";
import {
  PushNotificationTable,
  PushNotificationModal,
  CustomersTable,
} from "./components";
import axios from "../../shared/api/axiose";

import "./push-notification.scss";
import {
  PushNotificationCustomer,
  PushNotificationDataCities,
} from "../../FakeData";

const { Option } = Select;

const styles = {
  wrapper: "push-notification__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "push-notification__menu--left",
  menuRight: "push-notification__menu--right",
  pageSize: "push-notification__records-per-page",
};
const SEND_CRITERIA = [
  {
    value: 0,
    text: "Geofence",
  },
  {
    value: 1,
    text: "Customers",
  },
];

function PushNotification(props) {
  const intl = useIntl();

  const [searchDelayed, updateSearchDelayed] = useState("");
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: "",
    type: "",
  });

  const [sendCriteria, changeSendCriteria] = useState(0);

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    count: 0,
    isEmpty: false,
  });

  const handleCriteraChange = (data) => {
    updateTableControls({
      ...tableControls,
      data: [],
    });
    changeSendCriteria(data);
    updateIds([]);
  };
  const [customerSearch, updateCustomerSearch] = useState("");
  const [geofenceSearch, updateGeofenceSearch] = useState("");

  const [ids, updateIds] = useState([]);
  const handleSearch = (text) => {
    if (sendCriteria === 1) {
      updatePagination({
        ...pagination,
        current: 1,
        skip: 0,
      });

      updateSearchDelayed(text);
    }
  };

  const handleSearchChange = (val) => {
    if (sendCriteria == 0) {
      updateGeofenceSearch(val);
    } else {
      updateCustomerSearch(val);
    }
  };
  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (sendCriteria == 0) {
        updateIds(selectedRows.map((i) => i.geofenceId));
      } else {
        updateIds(selectedRows.map((i) => i.customerId));
      }
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    selectedRowKeys: ids,
  };

  const handlePaginationChange = (page) => {
    updatePagination({
      ...pagination,
      current: page,
      skip: (page - 1) * pagination.pageSize,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      pageSize: val,
    });
  };

  const openSendToAllModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "SEND_TO_ALL",
    });
  };

  const openSendToSelectedModal = () => {
    if (!ids.length) {
      if (sendCriteria === 0) {
        toastr.warning(
          "No Geofence Selected"
          // intl.formatMessage({ id: "app.message.noGeofenceSelected" })
        );
      } else {
        toastr.warning(
          intl.formatMessage({ id: "app.message.noCustomersSelected" })
        );
      }
      return;
    }
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "SEND_TO_SELECTED",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  const getGeofencesData = () => {
    updateTableControls({
      ...tableControls,
      data: PushNotificationDataCities().map((item) => {
        item.selected = false;
        return item;
      }),
      count: 20,
      isEmpty: PushNotificationDataCities().length === 0,
      isLoading: false,
    });
  };

  const getCustomersData = () => {
    updateTableControls({
      isLoading: false,
      isEmpty: PushNotificationCustomer().length === 0,
      data: PushNotificationCustomer(),
      count: 10,
    });

    // axios
    //   .get("/getAllCustomers", {
    //     params: removeEmptyKeys({
    //       limit: pagination.pageSize,
    //       skip: (pagination.current - 1) * pagination.pageSize,
    //       search: searchDelayed,
    //     }),
    //   })
    //   .then((res) => {
    //     updateTableControls({
    //       isLoading: false,
    //       isEmpty: res.data.data.customersData.length === 0,
    //       data: res.data.data.customersData,
    //       count: res.data.data.countOfCustomers,
    //     });

    //     // updatePagination({
    //     //   ...pagination,
    //     //   // delayedcurrent: pagination.current,
    //     //   // delayedpageSize: pagination.pageSize,
    //     // });
    //   })
    //   .catch((err) => {
    //     // toastr.error('Error', intl.formatMessage({id: 'app.message.error'}))
    //     updateTableControls({
    //       ...tableControls,
    //       isEmpty: true,
    //       isLoading: false,
    //       data: [],
    //     });
    //   });
  };

  const toggleItem = (id) => {
    updateTableControls({
      ...tableControls,
      data: tableControls.data.map((item) => {
        if (item.geofenceId === id || item.customerId === id) {
          item.selected = !item.selected;
          return item;
        } else return item;
      }),
    });
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
      // fixed: 'left',
    },
    {
      dataIndex: "",
      title: "Customer Name",
      render: (a, b) => `${b.firstName || ""} ${b.lastName || ""}`,
      // fixed: 'left',
    },
    {
      dataIndex: "email",
      title: intl.formatMessage({ id: "app.field.email" }),
    },
    {
      dataIndex: "phoneNo",
      title: intl.formatMessage({ id: "app.field.phoneNumber" }),
    },
    {
      dataIndex: "phoneNo",
      title: "Registration Date",
    },

    {
      dataIndex: "phoneNo",
      title: "Loyalty Points",
    },

    {
      title: "Device Token",
      render: (item) => (
        <Tag color="blue">
          {item.deviceToken || "Device Token Not Available"}
        </Tag>
      ),
    },
  ];

  const getSelectedCustomerIds = () =>
    tableControls.data.reduce((acc, val) => {
      if (val.selected) {
        if (sendCriteria === 0) {
          acc.push(val.geofenceId);
        }
        if (sendCriteria === 1) {
          acc.push(val.customerId);
        }
      }
      return acc;
    }, []);

  const getAllCustomerIds = () =>
    tableControls.data.reduce((acc, val) => {
      acc.push(val.geofenceId);
      return acc;
    }, []);

  const sendNotification = (data) => {
    alert("Notification send");
  };

  useEffect(() => {
    if (sendCriteria === 0) {
      getGeofencesData();
    }

    if (sendCriteria === 1) {
      getCustomersData();
    }
  }, [sendCriteria, pagination.current, pagination.pageSize, searchDelayed]);

  return (
    <div className={styles.wrapper}>
      <Header
        title="app.pageTitle.pushNotification"
        path="/home/marketing/Push Notification"
      />
      <div className={styles.content}>
        <h1></h1>
        <PushNotificationModal
          isVisible={modalControls.isVisible}
          onCloseIconClick={handleModalClose}
          sendNotification={sendNotification}
          isLoading={modalControls.isLoading}
        />
        <div className={styles.menu}>
          <header>
            <div className={styles.pageSize}>
              <Label value="app.field.sendCriteria" horizontal />
              <Select onChange={handleCriteraChange} value={sendCriteria}>
                {SEND_CRITERIA.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </div>
          </header>
          <section>
            <div className={styles.menuRight}>
              <Input
                onChange={handleSearchChange}
                value={sendCriteria == 0 ? geofenceSearch : customerSearch}
                callback={handleSearch}
                size="medium"
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
            <Button
              type="primary"
              shape={undefined}
              onClick={openSendToSelectedModal}
            >
              {intl.formatMessage({
                id: "app.button.sendNotificationToSelected",
              })}
            </Button>
            <Button
              type="primary"
              shape={undefined}
              disabled={tableControls.data.isEmpty}
              onClick={openSendToAllModal}
            >
              {intl.formatMessage({ id: "app.button.sendToAll" })}
            </Button>
          </section>
        </div>
        {sendCriteria === 0 && (
          <PushNotificationTable
            data={tableControls.data}
            isLoading={tableControls.isLoading}
            isEmpty={tableControls.isEmpty}
            search={geofenceSearch}
            rowSelection={rowSelection}
            pagination={pagination}
            toggleItem={toggleItem}
          />
        )}
        {sendCriteria === 1 && (
          // <CustomersTable
          //   data={tableControls.data}
          //   isLoading={tableControls.isLoading}
          //   isEmpty={tableControls.isEmpty}
          //   pagination={pagination}
          //   rowSelection={rowSelection}
          //   toggleItem={toggleItem}
          // />

          <CustomTable
          // isLoading={tableControls.isLoading}
          // columns={columns}
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          // }}
          // toggleRowSelectAccessor={"IsBlocked"}
          // dataSource={tableControls.data}
          // isEmpty={tableControls.isEmpty}
          // pagination={pagination}
          // rowKey={(i) => i.customerId}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  panelType: state.user.enterprice.panelType,
  isRestaurant: state.user.userInfo.isRestaurant,
  isPickupEnabled: state.user.enterprice.isPickupEnabled ? true : false,
  branchId: state.user.userInfo.branchId,
  selectedVerticalType: state.user.userInfo.selectedVerticalType,
});
export default connect(mapStateToProps)(PushNotification);
