import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch, Menu, Dropdown } from "antd";
import {MoreOutlined} from '@ant-design/icons';
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";

import { Panel, Icon, CustomTable, CustomTag, CustomImage, Label } from "../../../../components/common";
import { connect } from "react-redux";


const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  stickyAction: "sticky-action",
  actionIcon: "custom-table__action-icon",
  panelContainer: "active-promo-table__panel-container",
};

function ExpiredPromoTable(props) {
  const intl = useIntl();


  const columns = [
    {
      fixed: "left",
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.sNo" }),
      render: (item,object,index) => {
        return (<span>{props.pagination.pageSize * (props.pagination.current - 1) + index + 1}</span>)
      }
    },
    {
      fixed: "left",
      dataIndex: "code",
      title: intl.formatMessage({ id: "app.field.promoCode" }),
    },
    {
      dataIndex: "imageUrl",
      title: intl.formatMessage({ id: "app.field.image" }),
      render: (item) => <CustomImage src={item} isTable />
    },
    {
      dataIndex:"",
      title:intl.formatMessage({id:"app.field.title"}),
      render:(item)=>item.title==null?"---":`${item.title}`

    },
    {
      
      title: intl.formatMessage({ id: "app.field.discount" }),
      render: (item) => item.promoType == 2 ? `${item.credits} (% off)` : item.credits
    },
    {
      dataIndex: "branchName",
      title: intl.formatMessage({ id: "app.field.applicableFor" }),
      render: (item) => item ? item : intl.formatMessage({id: "app.field.allStores"})
    },
  
    {
      dataIndex: "perUser",
      title: intl.formatMessage({ id: "app.field.frequencyPerUser" }),
    },
 
    {
      dataIndex: "startDate",
      title: intl.formatMessage({ id: "app.field.startDate" }),
      render: (item) => moment(item).format("MMMM Do YYYY"),
    },

    {
      dataIndex: "endDate",
      title: intl.formatMessage({ id: "app.field.expiryDate" }),
      render: (item) => moment(item).format("MMMM Do YYYY"),
      fixed:props.panelType===1?"right":"",
    },
    {
      hidden:props.panelType==1?true:false,
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ].filter(item=>!item.hidden)
  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            props.editItem(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            props.removeItem(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };

  return (
      <CustomTable
      dataSource={props.data}
      pagination={props.pagination}
      onChange={props.onChange}
      columns={columns}
      isLoading={props.isLoading}
       />
  );
}
const mapStateToProps = (state) => ({
 
  panelType: state.user.enterprice.panelType,
 
});

export default connect(mapStateToProps) (ExpiredPromoTable);
