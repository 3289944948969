import { useState, useEffect } from "react";
import useOptimized from "./useOptimized";
import "../shared/styles/main.scss";

import { Modal, style } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;

const useConfirm = (callBack, displayContent = "Warning", displayTitle = "Are You Sure You Want To Delete ?") => {
  const handleDeleteClick = useOptimized((...args) => {
    confirm({
      // style: { opacity: "90%" },
      // title: "Are you sure you want to delete ?",
      width: 500,
      title: displayTitle,
      closable: true,
      // bodyStyle:{
      //   padding:"24px",
      //   fontSize:"14px",
      //   lineHeight:1.5715,
      //   wordWrap:"break-word"
      // },
      // icon: <ExclamationCircleOutlined />,
      // content: "Warning",
      content: displayContent,
      okText: "Delete",
      okButtonProps: {
        type: "primary",
        danger: "true",
      },
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => {
        callBack(...args);
      },
    });
  });
  return handleDeleteClick;
};

export default useConfirm;
