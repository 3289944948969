import React, { useState } from "react";
import { Input } from "antd";
import {SearchOutlined} from '@ant-design/icons'
// function DelayedInput(props) {

//     let timeout = '';
//     console.log('rerun')
//     const sendValue = (value) => {
//         console.log('sendValue')
//         props.callback(value);
//     }
//     const handleInput = (e) => {
//         console.log('clear timeout', timeout)
//         clearTimeout(timeout);
//         const {value} = e.target;

//         props.onChange(value)
//         timeout = setTimeout(() => {
//             sendValue(value)
//         }, 1000)
//         console.log(timeout, 'after')
//     }
//     return <Input onChange={handleInput} value={props.value} />
// }

class DelayedInput extends React.Component {
  timeout = "";
  sendValue = (value) => {
    this.props.callback(value);
  };

  handleInput = (e) => {
    console.log('hadnelInput')
    clearTimeout(this.timeout);
    const { value } = e.target;

    this.props.onChange(value);
    if(value.length >= this.props.minLength) {
        this.timeout = setTimeout(() => {
            this.sendValue(value);
          }, this.props.delay);
    } else {
        this.props.callback('');
    }
  };
  componentWillMount() {
    clearTimeout(this.timeout);
  }
  render() {
    return (
      <Input
        onChange={this.handleInput}
        placeholder={this.props.placeholder}
        value={this.props.value}
        prefix={this.props.prefixIcon}
        suffix={this.props.suffixIcon}
        size={this.props.size}
      />
    );
  }

  static defaultProps = {
      minLength: 0,
      size: 'middle',
      callback: () => {},
  }
}

export default DelayedInput;
