import React from "react";

import rolesActions from "../../redux/roles/roles-action";

const PANEL_KEY = "trollyAdmin_panel_mode";
const USER_KEY = "trollyAdmin_userInfo";
const ROLES_KEY = "trollyAdmin_roles";
const JWT = "trollyAdmin_jwt";

const userKeys = [PANEL_KEY, USER_KEY, ROLES_KEY, JWT];
const DEV_RANDOM_STRING = "0kf92h4931firwcj901jef";

const DEV_DATA = `${DEV_RANDOM_STRING}_dev`;
// ******************************************************************
const dateFormat = "MM/DD/YYYY hh:mm";
const date_Format = "MM/DD/YYYY";
const timeFormat = "HH:mm";
const ADMIN_ROLES = "Admin_roles";
const ACCESS_TOKEN = "Admin_access_token";
const ADMIN_INFO = "userInformation";
const ADMIN_NAME = "User_name";
const referral_Source = [
  {
    id: 1,
    text: "Google",
  },
  {
    id: 2,
    text: "Facebook",
  },
  {
    id: 3,
    text: "Linkedin",
  },
  {
    id: 4,
    text: "Instagram",
  },
  {
    id: 5,
    text: "Other",
  },
];

const panelData = {
  clientIndex: 0,
  panelIndex: 0,
  endpoint: process.env.REACT_APP_API_ENDPOINT,
  apiKey: null,
  domainName: null,
};

export const clearUserData = () => {
  userKeys.forEach((i) => {
    sessionStorage.removeItem(i);
  });
};

export class DevStorage {
  static allKeys = [PANEL_KEY, USER_KEY, JWT, ROLES_KEY, DEV_DATA];

  static getData = () => {
    let data = sessionStorage.getItem(DEV_DATA);
    console.log("getData: ", data);
    if (!data) {
      data = {};
    } else {
      data = JSON.parse(data);
    }
    const clientIndex = data.clientIndex || 0;
    const panelIndex = data.panelIndex || 0;
    const endpoint = data.endpoint || process.env.REACT_APP_API_ENDPOINT;
    const domainName = data.domainName;
    const apiKey = data.apiKey || null;

    console.log("  getDataclientIndex: ", clientIndex);
    console.log("getData panelIndex: ", panelIndex);
    console.log("getData endpoint: ", endpoint);
    console.log("getData domainName: ", domainName);

    return {
      clientIndex,
      panelIndex,
      endpoint,
      domainName,
      apiKey,
    };
  };

  static setData = (data) => {
    clearUserData();

    const prevData = DevStorage.getData();
    const newData = {
      ...prevData,
      ...data,
    };
    // newData.domainName =
    //   devSettings.clients[newData.clientIndex].panel[newData.panelIndex].url;
    // sessionStorage.setItem(DEV_DATA, JSON.stringify(newData));
    //   debugger;
  };

  static reset = () => {
    DevStorage.allKeys.forEach((key) => {
      sessionStorage.removeItem(key);
    });
    DevStorage.setData({});
  };
}
export class Dev_Storage {
  static getData = (val) => {
    let data = {};

    if (!data) {
      data = {};
    } else {
      data = JSON.parse(data);
    }
    const clientIndex = data.clientIndex || 0;
    const panelIndex = data.panelIndex || 0;

    let endpoint = "";
    if (val === "two") {
      endpoint = process.env.REACT_APP_API_ENDPOINT_TWO;
    } else if (val === "three") {
      endpoint = process.env.REACT_APP_API_ENDPOINT_THREE;
    } else {
      endpoint = process.env.REACT_APP_API_ENDPOINT;
    }
    // process.env.REACT_APP_API_ENDPOINT;

    console.log("  getDataclientIndex: ", clientIndex);
    console.log("getData panelIndex: ", panelIndex);
    console.log("getData endpoint: ", endpoint);
    // console.log("getData domainName: ", domainName)

    return {
      clientIndex,
      panelIndex,
      endpoint,
    };
  };
}

export const getPanelType = () => {
  // return 0;
  const mode = sessionStorage.getItem(PANEL_KEY);
  if (!mode) {
    return 0;
  }
  return mode;
};

export const getUserInfo = () => {
  const info = sessionStorage.getItem(USER_KEY);
  try {
    if (!info) {
      return {};
    }
    return JSON.parse(info);
  } catch (e) {
    return {};
  }
};

export const getRolesFromStorage = () => {
  const roles = sessionStorage.getItem(ROLES_KEY);
  // debugger;
  if (!roles) {
    return [];
  } else {
    return JSON.parse(roles);
  }
};
export const setPanelType = (mode) => {
  sessionStorage.setItem(PANEL_KEY, mode);
};

/*******************local storage for Instaservice */

const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

const setRoles = (data) => {
  sessionStorage.setItem(ADMIN_ROLES, JSON.stringify(data));
};
const getRoles_from_admin = () => {
  return JSON.parse(sessionStorage.getItem(ADMIN_ROLES));
};

const setAdminInfo = (info) => {
  sessionStorage.setItem(ADMIN_INFO, JSON.stringify(info));
};
const getAdminInfo = (id) => {
  let adminInfo = JSON.parse(sessionStorage.getItem(ADMIN_INFO));
  if (id) {
    return adminInfo?.id;
  }
  return JSON.parse(sessionStorage.getItem(ADMIN_INFO));
};

const setUserName = (name) => {
  sessionStorage.setItem(ADMIN_NAME, name);
};

const getUserName = () => {
  return sessionStorage.getItem(ADMIN_NAME);
};

const Set_Modules = (data) => {
  // const dispatch = useDispatch();
  // dispatch(rolesActions.setModule(data))
  return <div></div>;
};

const statusFilter = [
  {
    id: 1,
    value: null,
    text: "All",
  },
  {
    id: 2,
    value: true,
    text: "Active",
  },
  {
    id: 3,
    value: false,
    text: "Blocked",
  },
];
const ratingFilter = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
];
const verifyStatus = [
  {
    id: 1,
    text: "Verified",
  },
  {
    id: 2,
    text: "Unverified",
  },
];
const serviceChoice = [
  {
    id: 1,
    text: "On-Demand Services",
  },
  {
    id: 2,
    text: "Scheduled Services",
  },
  {
    id: 3,
    text: "Quotation Services",
  },
];

export {
  setAccessToken,
  getAccessToken,
  setRoles,
  getRoles_from_admin,
  Set_Modules,
  dateFormat,
  setAdminInfo,
  getAdminInfo,
  setUserName,
  getUserName,
  statusFilter,
  referral_Source,
  date_Format,
  timeFormat,
  ratingFilter,
  verifyStatus,
  serviceChoice,
};
