import React, { useState, useEffect } from "react";
import { Select, Button, Table, Tag, Input } from "antd";
import { connect } from "react-redux";
import { Header, Label } from "../../components/common";
import { PushNotificationModal } from "./components";
import axios from "../../shared/api/axiose";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;

const styles = {
  wrapper: "push-notification__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "push-notification__menu--left",
  menuRight: "push-notification__menu--right",
  pageSize: "push-notification__records-per-page",
  tableWrapper: "table-wrapper",
};

function PushNotifications(props) {
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sendNotificationModalVisible, setSendNotificationModalVisible] =
    useState(false);
  const [sendingNotification, setSendingNotification] = useState(false);
  const [modalControls, updateModalControls] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const filteredCustomerData = customerData.filter(
    (record) =>
      record.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
      record.lastName.toLowerCase().includes(searchValue.toLowerCase())
  );
  const openSendToAllModal = () => {
    setSendNotificationModalVisible(true);
  };

  const handleModalClose = () => {
    setSendNotificationModalVisible(false);
    updateModalControls(false);
  };

  const openSendToSelectedModal = () => {
    updateModalControls(true);
  };
  const sendSelectedNotifications = async ({ title, message }) => {
    try {
      setSendingNotification(true);

      const customerIds = selectedRowKeys.map((key) => {
        const selectedCustomer = customerData.find(
          (record) => record.customerId === key
        );
        return selectedCustomer.customerId;
      });

      const payload = {
        title: title,
        message: message,
        customers: customerIds,
      };

      const response = await axios.post("/admins/notifications/send", payload);

      if (response.data.success) {
        toast.success("Notification Sent 👍");
        setSendNotificationModalVisible(false);
      } else {
        toast.error("Something Went Wrong 🥺");
      }
    } catch (error) {
      // Handle error, e.g., show an error message
    } finally {
      setSendingNotification(false);
    }
  };
  const sendNotification = async ({ title, message }) => {
    try {
      setSendingNotification(true);

      const payload = {
        title: title,
        message: message,
      };

      const response = await axios.post(
        "/admins/notifications/sendAll",
        payload
      );

      if (response.data.success) {
        toast.success("Notification Sent 👍");
        setSendNotificationModalVisible(false);
      } else {
        toast.error("Something Went Wrong 🥺");
      }
    } catch (error) {
      // Handle error, e.g., show an error message
    } finally {
      setSendingNotification(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("/admins/getAllCustomers");
      if (response.data.success) {
        setCustomerData(response.data.data.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchValue]);
  const columns = [
    {
      width: "6rem",
      title: "Section No",
      dataIndex: "sectionNumber",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Profile Pic",
      dataIndex: "profilePicURL",
      render: (text, record) => (
        <img
          src={text}
          alt={`Profile of ${record.username}`}
          style={{ maxWidth: "50px" }}
        />
      ),
    },
    {
      title: "Full Name",
      render: (record) => {
        const { firstName, lastName } = record;
        return (
          <span
            style={{ color: " #1890ff", fontWeight: "600" }}
          >{`${firstName} ${lastName}`}</span>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "username",
      render: (username) => {
        return (
          <Tag color="#5FAAFF" style={{ width: "9rem", textAlign: "center" }}>
            {username}
          </Tag>
        );
      },
    },
    {
      dataIndex: "email",
      title: "Email",
      render: (email) => {
        return (
          <>
            <div className={styles.VerifiedEmail_cont}>
              <span style={{ wordWrap: "break-word", marginRight: "10px" }}>
                {email}{" "}
              </span>
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="lg"
                style={{ color: "#9bd1f3" }}
              />
            </div>
          </>
        );
      },
    },

    {
      title: "Phone",
      dataIndex: "phoneNo",
    },
    {
      title: "Token Status",
      render: (item) => {
        if (item.deviceToken !== null) {
          return <Tag color="#74D95B">Available</Tag>;
        } else {
          return <Tag color="#F95050">Unavailable</Tag>;
        }
      },
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: !record.deviceToken, // Disable checkbox for rows with null deviceToken
    }),
  };

  const getRowClassName = (record) => {
    if (!record.deviceToken) {
      return "disabled-row"; // Apply a custom CSS class for disabled rows
    }
    return "";
  };
  return (
    <>
      <div className={styles.wrapper}>
        <Header
          title="app.pageTitle.pushNotification"
          path="/home/marketing/Push Notification"
        />
        <div className={styles.content}>
          <h1></h1>
          <PushNotificationModal
            isVisible={sendNotificationModalVisible}
            onCloseIconClick={handleModalClose}
            sendNotification={sendNotification}
            isLoading={sendingNotification}
          />
          <PushNotificationModal
            isVisible={modalControls}
            onCloseIconClick={handleModalClose}
            sendNotification={sendSelectedNotifications}
          />
          <div className={styles.menu}>
            <header>
              <div className={styles.pageSize}>
                <Input
                  placeholder="Search by name"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  style={{ width: "240px" }}
                />
              </div>
            </header>
            <section>
              <div className={styles.menuRight}></div>
              <Button type="primary" shape={undefined}></Button>
              <Button
                type="primary"
                shape={undefined}
                onClick={openSendToSelectedModal}
              >
                Send Notification To Selected
              </Button>
              <Button
                type="primary"
                shape={undefined}
                //   disabled={tableControls.data.isEmpty}
                onClick={openSendToAllModal}
              >
                {" "}
                Send Notification To All
              </Button>
            </section>
          </div>
          <Table
            columns={columns}
            dataSource={filteredCustomerData}
            loading={loading}
            rowKey="customerId"
            rowSelection={rowSelection}
            rowClassName={getRowClassName}
            scroll={{ x: "max-content", y: 300 }}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  panelType: state.user.enterprice.panelType,
  isRestaurant: state.user.userInfo.isRestaurant,
  isPickupEnabled: state.user.enterprice.isPickupEnabled ? true : false,
  branchId: state.user.userInfo.branchId,
  selectedVerticalType: state.user.userInfo.selectedVerticalType,
});
export default connect(mapStateToProps)(PushNotifications);
