import orderActionTypes from './orders-action-types';
import Push from 'push.js';
import sound from '../../assets/sounds/notification.mp3';
const audio = new Audio(sound);
// 
const orderActions = {
    triggerRefetch: () => ({type: orderActionTypes.TRIGGER_REFETCH}),
    sendNewOrderNotification: (data) => (dispatch) => {
        if(Push.Permission.has()) {
            audio.play();
            Push.create("New Order!", {
                body: "A new order has arrived!",
                timeout: 6000,
                onClick: function () {
                    window.focus();
                    this.close();
                }
            });
        }
        dispatch(orderActions.triggerRefetch());
    },

    
    sendCancelOrderNotification: (data, isDriver) => (dispatch) => {
        console.log("cancel notificaiton", data, isDriver ? 'DRIVER_CANCELLED': 'CUSTOMER_CANCELLED' );
        let head = "Order Cancelled by Customer";
        let body = `${data.bookingId ? `Order ${data.bookingId}` : "An order"} has been cancelled by the customer`;

        if(isDriver) {
            head = `Order Cancelled by Driver`;
            body = `Order ${data.bookingId} has been cancelled by ${data.driverName || 'a driver'}`;
        }

        if(Push.Permission.has()) {
            audio.play();
           Push.create(head, {
                body: body,
                timeout: 6000,
                onClick: function () {
                    window.focus();
                    this.close();
                }
            });
        }
        dispatch(orderActions.triggerRefetch());
    }
}

export default orderActions;