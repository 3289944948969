import React from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { checkRtl } from "../../../shared/utils/rtl";
import { connect } from "react-redux";

import "./label.scss";

const styles = {
  label: "custom-label__label",
  horizontal: "custom-label__label--horizontal",
  vertical: "custom-label__label--vertical",
};
function CustomLabel({ horizontal, vertical, htmlFor, value, optional, isRTL }) {
  const intl = useIntl(); 

  

  return (
    <label
      dir={isRTL ? "rtl" : "ltr"}
      className={classNames({
        [styles.label]: true,
        [styles.horizontal]: horizontal,
        [styles.vertical]: vertical,
      })}
      htmlFor={htmlFor}
    >
      {intl.formatMessage({ id: value })}
      {optional ? ` (Optional)` : ""}
    </label>
  );
}

const mapState = (state) => ({
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapState)(CustomLabel);
