import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select, Divider, Space } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import classNames from "classnames";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../../components/common";
import axiosConfig from "../../../../shared/api/axiose"; // base Url : http://52.26.241.33:3002
import Validations from "../../../../shared/validations/Validations";
import { multiSort } from "../../../../shared/utils/object-manipulation";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-promo-modal.scss";
import { PlusOutlined } from "@ant-design/icons";
import { getAdminInfo } from "../../../../shared/utils/local-storage";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formModal_wrapper: "Questionary_add_edit_modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "Questionary_add_edit_modal__image-container",
  imageContainerImage: "Questionary_add_edit_modal__image-container__image",
  imageContainerForm: "Questionary_add_edit_modal__image-container__form",
  spanImp: "Questionary_add_edit_modal__span-img",
  disabledColor: "disabledColor",
};

const errorText = "Field cannot be empty";

const Tags = [
  {
    value: "1",
    text: "Cleaning",
  },
  {
    value: "2",
    text: "Service provider",
  },
  {
    value: "3",
    text: "Customer",
  },
  {
    value: "4",
    text: "Enquire ",
  },
  {
    value: "5",
    text: "Document",
  },
  {
    value: "6",
    text: "PDF",
  },
  {
    value: "7",
    text: "Washing",
  },
  {
    value: "8",
    text: "Testing Tags",
  },
];

function AddEditPromoContainer({
  isVisible,
  modalType,
  onCloseIconClick,
  data,
  editCategory,
  isLoading,
  getQuestionasData,
  questionType,

  ...props
}) {
  console.log("AddEditPromoContainer", data);
  console.log("modalType =>", modalType);

  /** Instaservice States */
  const intl = useIntl();

  const [isPristine, togglePristine] = useState(true);
  const [tagSelect, updateTagSelect] = useState(
    Validations.validateMultiSelect([])
  );

  const [question, updateQuestion] = useState(
    Validations.validateTextField(undefined)
  );
  const [inputOption, updateInputOption] = useState(
    Validations.validateTextField(undefined)
  );
  const [question_Type, updateQuestionType] = useState(
    Validations.validateTextField(undefined)
  );

  // console.log("question_Type = > ", question_Type)
  const [allTags, setAllTages] = useState(Tags);
  const [addTageName, setaddTageName] = useState("");

  /** Instaservice States */

  const onNameChange = (event) => {
    setaddTageName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    let tagobj = {
      value: allTags.length + 1,
      text: addTageName,
    };

    setAllTages([...allTags, tagobj]);

    setaddTageName("");
  };

  const resetForm = () => {
    console.log("setForm=>", data);
    setAllTages(Tags);
    setaddTageName("");
    updateQuestionType(Validations.validateTextField(undefined));
    updateInputOption(Validations.validateTextField(undefined));
    updateQuestion(Validations.validateTextField(undefined));
    updateTagSelect(Validations.validateMultiSelect([]));
    togglePristine(true);
  };

  const setForm = () => {
    // debugger
    console.log("setForm=>", data);

    updateQuestionType(Validations.validateTextField(data?.questionType));
    updateInputOption(Validations.validateTextField(data?.inputOptions));
    updateQuestion(Validations.validateTextField(data?.question));
    let tagList = data?.tag.split(" , ");
    updateTagSelect(Validations.validateMultiSelect([...tagList]));
  };

  useEffect(() => {
    if (modalType === "EDIT") {
      setForm();
    }
  }, [modalType, data]);

  const handleAdd = () => {
    console.log("handleAdd tagSelect => ", tagSelect.value);
    console.log("handleAdd question => ", question?.value);
    console.log("handleAdd question_Type => ", question_Type?.value);

    let tegList = [];
    (tagSelect?.value).map((item) => {
      let obj = {};
      obj.tag = item;
      tegList.push(obj);
    });
    // console.log("String tag =>  ", tag)
    let adminId = getAdminInfo()?.id;
    const payload = {
      question: question?.value,
      tagList: tegList,
      questionType: question_Type?.value,
      adminId: adminId,
      inputOptions: inputOption?.value,
    };

    axiosConfig
      .post("/questionnaire", payload)
      .then((res) => {
        console.log("handleAdd", res);
        if (res?.data?.statusCode === 200) {
          onCloseIconClick();
          getQuestionasData();
          resetForm();

          toastr.success(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEdit = (data) => {
    // console.log("handleEdit payload", payload);
    let tegList = [];
    (tagSelect?.value).map((item) => {
      let obj = {};
      obj.tag = item;
      tegList.push(obj);
    });

    const payload = {
      question: question?.value,
      tagList: tegList,
      questionType: question_Type?.value,
      adminId: data?.adminId,
      inputOptions: inputOption?.value,
    };

    console.log("handleEdit payload => ", payload);
    axiosConfig
      .put(`/questionnaire/${data?.id}`, payload)
      .then((res) => {
        console.log("handleAdd", res);
        if (res?.data?.statusCode === 200) {
          onCloseIconClick();
          resetForm();
          getQuestionasData();

          toastr.success(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isFormValid = () => {
    let isValid = true;

    isValid =
      isValid &&
      question.isValid &&
      tagSelect.isValid &&
      question_Type.isValid &&
      inputOption?.isValid;

    return isValid;
  };

  const closeModal = () => {
    onCloseIconClick();
    resetForm();
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return toastr.warning(
        intl.formatMessage({ id: "app.message.formCheck" })
      );
    }
    if (modalType === "ADD") {
      handleAdd();
    } else if (modalType === "EDIT") {
      handleEdit(data);
    }
  };

  const handleTagsSelect = (value) => {
    console.log("select cities=>", value);
    updateTagSelect(Validations.validateMultiSelect(value));
  };
  const handlequestionType = (value) => {
    console.log("question_Type = > ", value);

    updateQuestionType(Validations.validateTextField(value));
  };

  const handleInputOption = (value) => {
    updateInputOption(Validations.validateTextField(value));
  };

  const handleQuestion = (val) => {
    console.log("handleQuestion => ", val);
    updateQuestion(Validations.validateTextField(val));
  };
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        modalType === "ADD"
          ? "Add FAQ"
          : modalType === "EDIT"
          ? "Edit FAQ"
          : "FAQ Details"
      }
      action={
        modalType !== "VIEW" ? (
          <>
            {" "}
            <Button type="default" shape={undefined} onClick={closeModal}>
              {intl.formatMessage({ id: "app.button.cancel" })}
            </Button>
            <Button
              // loading={isLoading}
              type="primary"
              shape={undefined}
              onClick={formAction}
            >
              {intl.formatMessage({ id: "app.button.submit" })}
            </Button>
          </>
        ) : (
          ""
        )
      }
      isVisible={isVisible}
    >
      <Panel
        isModal
        className={classNames({
          [styles.formModal]: true,
          [styles.formModal_wrapper]: true,
        })}
      >
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Question" />
            <Input
              name="question"
              value={question?.value}
              placeholder="Question"
              // value={category.value}
              onChange={(e) => handleQuestion(e.target.value)}
            />
            {!isPristine && <ErrorMessage text={question?.errMsg} />}
          </div>

          <div className={styles.formItem}>
            <Label value="Question Type" />
            <Select
              placeholder="Question Type"
              onChange={handlequestionType}
              value={questionType[question_Type?.value - 1]?.text}
              // value={question_Type.value}
            >
              {questionType.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
            {!isPristine && <ErrorMessage text={question_Type?.errMsg} />}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}></div>
        </div>

        <div className={styles.formGroup}>
          {/* Teg Select Input */}
          <div className={styles.formItem}>
            <Label value="Tags" />
            <Select
              showSearch
              mode="multiple"
              placeholder="Select Tags"
              optionFilterProp="children"
              value={tagSelect?.value}
              onChange={handleTagsSelect}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "0 8px 4px",
                    }}
                  >
                    <Input
                      placeholder="Please enter item"
                      value={addTageName}
                      onChange={onNameChange}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addItem}
                    >
                      Add Tag
                    </Button>
                  </Space>
                </>
              )}
            >
              {allTags.map((item) => (
                <Option key={item.value} value={item.text}>
                  {item.text}
                </Option>
              ))}
            </Select>
            {!isPristine && <ErrorMessage text={tagSelect?.errMsg} />}
          </div>
          <div className={styles.formItem}>
            <Label value="Input Options" />
            <Input
              name="teg"
              placeholder="Enter Input Options"
              value={inputOption.value}
              onChange={(e) => handleInputOption(e.target.value)}
            />
            {!isPristine && <ErrorMessage text={inputOption?.errMsg} />}
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}
const mapState = (state) => ({
  // branchId: state.user.userInfo.branchId,
  // panelType: state.user.enterprice.panelType,
});
export default connect(mapState)(AddEditPromoContainer);
