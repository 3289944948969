import React from "react";

import axios from "axios";
import userActions from "../../redux/user-info/user-actions";
import store from "../../redux/store";
import {
  getPanelType,
  DevStorage,
  setAdminInfo,
  getAccessToken,
  setAccessToken,
} from "../utils/local-storage";
import { toastr } from "react-redux-toastr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const axiosConfig = axios.create({
  // baseURL,
});

axiosConfig.interceptors.request.use(function (config) {
  const accessToken = getAccessToken();

  let baseURL = "https://api.theexcursionist.net";

  if (accessToken) {
    config.headers["access-token"] = accessToken;
  }
  config.baseURL = baseURL;
  config.headers["content-language"] = "en";
  return config;
});

axiosConfig.interceptors.response.use(
  async function (response) {
    console.log("RESPONSE: ", response.config.url, response.data);
    return response;
  },
  async function (error) {
    if (
      error?.response?.data?.statusCode === 401 &&
      error?.response?.data?.messageCode === "access_token_expired"
    ) {
      const refreshToken = localStorage.getItem("Admin-Refresh-Token");
      console.log(refreshToken);
      if (refreshToken) {
        try {
          const renewedResponse = await axios.post(
            "https://api.theexcursionist.net/admins/renewAccessToken",
            null,
            {
              headers: {
                "refresh-token": refreshToken,
              },
            }
          );

          console.log("refreshResponse", renewedResponse.data.data.accessToken);

          const access_token = renewedResponse.data.data.accessToken || null;
          setAccessToken(access_token);
          window.location.reload();
        } catch (renewalError) {
          store.dispatch(userActions.sessionExpired());
          console.error("Error renewing token:", renewalError);
        }
      } else {
        if (error?.response?.data?.message) {
          store.dispatch(userActions.sessionExpired());
          toastr.error(error.response.data.message);
        } else {
          store.dispatch(userActions.sessionExpired());
          toastr.error("Error", "Something Went Wrong");
        }
      }
    }
    return Promise.reject(error.response);
  }
);

export default axiosConfig;
