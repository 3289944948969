import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Button, Input } from "antd";

import {
  Panel,
  CustomModal,
  ErrorMessage,
  Label,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
const { TextArea } = Input;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
};
function PushNotificationModal(props) {
  const intl = useIntl();
  const closeModal = () => {
    props.onCloseIconClick();
  };

  const [message, changeMessage] = useState(Validations.validateTextField(""));
  const [title, changeTitle] = useState(Validations.validateTextField(""));
  const [isPristine, changeIsPristine] = useState(true);

  const handleMessageChange = (e) => {
    changeMessage(Validations.validateTextField(e.target.value));
  };

  const handleTitleChange = (e) => {
    changeTitle(Validations.validateTextField(e.target.value));
  };

  const handleSubmit = () => {
    changeIsPristine(false);
    if (message.isValid && title.isValid) {
      props.sendNotification({
        message: message.value,
        title: title.value,
      });
    }
  };

  const resetForm = () => {
    changeMessage(Validations.validateTextField(""));
    changeTitle(Validations.validateTextField(""));
  };

  const setForm = () => {
    changeMessage(Validations.validateTextField(props.data));
  };

  useEffect(() => {
    if (props.isVisible) {
      changeIsPristine(true);

      if (props.modalType === "EDIT") {
        setForm();
      } else {
        resetForm();
      }
    }
  }, [props.isVisible]);

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header="app.pageTitle.notificationContent"
      isVisible={props.isVisible}
      action={
        <>
          <Button onClick={props.onCloseIconClick} shape={undefined}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button
            loading={props.isLoading}
            onClick={handleSubmit}
            type="primary"
            shape={undefined}
          >
            {intl.formatMessage({ id: "app.button.sendNotification" })}
          </Button>
        </>
      }
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formItem}>
          <Label value="app.placeholder.title" />
          <Input
            value={title.value}
            onChange={handleTitleChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.title",
            })}
          />
          {!isPristine && <ErrorMessage field={title} />}
        </div>
        <div className={styles.formItem}>
          <Label value="app.placeholder.message" />
          <TextArea
            rows={5}
            value={message.value}
            onChange={handleMessageChange}
            placeholder={intl.formatMessage({
              id: "app.placeholder.message",
            })}
          />
          {!isPristine && <ErrorMessage field={message} />}
        </div>
      </Panel>
    </CustomModal>
  );
}

export default PushNotificationModal;
