import React from "react";
import { useIntl } from "react-intl";
import { Modal, Button } from "antd";
import "./logout.scss";
const styles = {
  buttonContainer: "custom-logout-modal__button-container",
};
function Logout(props) {
  const intl = useIntl();
  //   return null;
  return (
    <Modal
      title="Logout"
      style={{ top: 20 }}
      closable={false}
      visible={props.isVisible}
      footer={[
        <Button onClick={props.cancel}>
          {intl.formatMessage({ id: "app.field.cancel" })}
        </Button>,
        <Button type="primary" onClick={props.logout}>
          {intl.formatMessage({ id: "app.field.logout" })}
        </Button>,
      ]}
    >
      <span> {intl.formatMessage({ id: "app.message.logout" })}</span>
    </Modal>
  );
}
export default Logout;
