import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Dropdown,
  Button,
  Input,
  Drawer,
  Table,
  Space,
  Spin,
  Menu,
  Tag,
  Form,
  Switch,
} from "antd";
import { useIntl } from "react-intl";
// import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { MoreOutlined } from "@ant-design/icons";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import Validations from "../../shared/validations/Validations";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import countryCodes from "../../shared/constants/countries.json";
import moment from "moment";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
  UploadButton,
  CustomImage,
  Label,
} from "../../components/common";

import "./active-promo.scss";

import axios from "../../shared/api/axiose";

import { useDispatch } from "react-redux";
// import Label from "../../components/common/label/label";

const { Option } = Select;

const styles = {
  wrapper: "active-promo__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  formGroup: "custom-form-group",
  formItem: "custom-form-item",
  menuLeft: "active-promo__menu--left",
  menuRight: "active-promo__menu--right",
  pageSize: "active-promo__records-per-page",
  filterCont: "filterCont",
};

function Agents(props) {
  const [form] = Form.useForm();
  const imageContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { Search } = Input;
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [agentData, setAgentData] = useState([]);
  const [isBlocked, setIsBlocked] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedAgentDetails, setSelectedAgentDetails] = useState(null);

  const [editAgentDetails, setEditAgentDetails] = useState({
    name: "",
    profilePicURL: "",
    countryCode: "",
    phoneNo: "",
    isBlocked: false,
  });
  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);

  const showAddDrawer = () => {
    setOpen(true);
  };

  const onAddClose = () => {
    setOpen(false);
  };
  const showEditDrawer = () => {
    setIsEditDrawerVisible(true);
  };

  const onEditDrawerClose = () => {
    setIsEditDrawerVisible(false);
  };
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );
  const [phoneCountryCode, changePhoneCountryCode] = useState(
    Validations.validateTextField("+1")
  );

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const onClose = () => {
    setIsDrawerVisible(false);
    changeImageURL(Validations.validateTextField(""));
  };
  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };
  const handleCountryCodeChange = (value) => {
    changePhoneCountryCode(
      Validations.validateTextField(value, "Country Code")
    );
  };
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const getAgentData = (isBlocked, searchQuery) => {
    setLoading(true);

    const apiUrl =
      isBlocked === undefined
        ? "/support-agents"
        : `/support-agents?isBlocked=${isBlocked}`;

    const fullApiUrl = searchQuery ? `${apiUrl}?search=${searchQuery}` : apiUrl;

    axios
      .get(fullApiUrl)
      .then((response) => {
        const agents = response.data.data.supportAgents;
        setAgentData(agents);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAgentData(isBlocked, searchQuery);
  }, [isBlocked, searchQuery]);

  const handleViewAgent = (agentId) => {
    axios
      .get(`/support-agents/${agentId}`)
      .then((response) => {
        setSelectedAgentDetails(response.data.data);
        showDrawer();
      })
      .catch((error) => {
        console.error("Error fetching agent details:", error);
        toast.error("Failed to fetch agent details", "Error");
      });
  };
  const handleAddAgent = () => {
    if (
      !agentName ||
      !imageURL.value ||
      !phoneCountryCode.value ||
      !phoneNumber
    ) {
      toast.error("Please fill in all the required fields");
      return;
    }
    const payload = {
      name: agentName,
      profilePicURL: imageURL.value,
      countryCode: phoneCountryCode.value,
      phoneNo: phoneNumber,
    };

    axios
      .post("/support-agents", payload)
      .then((response) => {
        toast.success("Agent added successfully", "Success");

        form.resetFields();

        onClose();
        onAddClose();
        getAgentData();
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        toast.error("Failed to add agent", "Error");
      });
  };

  const handleEditAgent = (agentId) => {
    axios
      .get(`/support-agents/${agentId}`)
      .then((response) => {
        const agentDetails = response.data.data;
        agentDetails.isBlocked = agentDetails.isBlocked || false;
        setEditAgentDetails(agentDetails);
        showEditDrawer();
      })
      .catch((error) => {
        console.error("Error fetching agent details for edit:", error);
        toast.error("Failed to fetch agent details for edit", "Error");
      });
  };

  const handleUpdateAgent = () => {
    const { id, ...updatePayload } = editAgentDetails;

    axios
      .put(`/support-agents/${id}`, updatePayload)
      .then((response) => {
        toast.success("Agent updated successfully", "Success");
        onEditDrawerClose();
        getAgentData();
      })
      .catch((error) => {
        console.error("Error updating agent:", error);
        toast.error("Failed to update agent", "Error");
      });
  };

  const handleEditInputChange = (field, value) => {
    setEditAgentDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const handleEditImageChange = (newImage) => {
    setEditAgentDetails((prev) => ({
      ...prev,
      profilePicURL: newImage,
    }));
  };
  const columns = [
    {
      width: "6rem",
      title: "S No.",
      dataIndex: "sectionNumber",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Profile Photo",
      dataIndex: "profilePicURL",
      key: "profilePicURL",
      width: "10rem",
      render: (text) => (
        <img
          src={text}
          alt="Profile Photo"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },

    {
      title: "Agent name",
      dataIndex: "name",
      key: "name",
      width: "15rem",
      render: (name) => (
        <span style={{ color: " #1890ff", fontWeight: "600" }}>{name}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "isBlocked",
      key: "isBlocked",
      render: (isBlocked) => (
        <Tag color={isBlocked ? "red" : "green"}>
          {isBlocked ? "Blocked" : "Active"}
        </Tag>
      ),
    },
    {
      title: "Phone Number",
      key: "countryCodeAndPhoneNo",
      render: (record) => (
        <span>{`${record.countryCode} ${record.phoneNo}`}</span>
      ),
    },
    {
      title: "Register Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <span>{moment(createdAt).format("MM/DD/YYYY h:mm A")}</span>
      ),
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => (
        <Dropdown overlay={getMenu(item)}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];
  const handleDeleteAgent = (id) => {
    axios
      .delete(`/support-agents/${id}`)
      .then((response) => {
        toast.success("Agent deleted successfully", "Success");

        getAgentData();
      })
      .catch((error) => {
        console.error("Error deleting agent:", error);
        toast.error("Failed to delete agent", "Error");
      });
  };
  const handleBlockActivateAgent = async (agentId, isChecked) => {
    try {
      await axios.put(`/support-agents/${agentId}`, {
        isBlocked: isChecked,
      });

      getAgentData();
    } catch (error) {
      console.error("Error updating agent's blocked status:", error);
    }
  };
  const getMenu = (item) => (
    <Menu>
      <Menu.Item onClick={() => handleViewAgent(item.id)}>View</Menu.Item>
      <Menu.Item onClick={() => handleEditAgent(item.id)}>Edit</Menu.Item>
      <Menu.Item onClick={() => handleDeleteAgent(item.id)}>Delete</Menu.Item>
      <Menu.Item
        onClick={() => handleBlockActivateAgent(item.id, !item.isBlocked)}
      >
        <span>{item.isBlocked ? "Activate" : "Block"}</span>
      </Menu.Item>
    </Menu>
  );

  const AgentDetailsDrawer = (
    <Drawer
      title="Agent Details"
      placement="right"
      width={700}
      onClose={onClose}
      visible={isDrawerVisible}
    >
      {selectedAgentDetails && (
        <div>
          <div className={styles.formItem}>
            <div>
              <Tag
                style={{
                  width: "15rem",

                  height: "2rem",
                  paddingTop: "0.3rem",
                  textAlign: "center",
                  fontSize: "15px",
                  borderRadius: "5px",
                  fontWeight: "600",
                }}
                color={selectedAgentDetails.isBlocked ? "red" : "green"}
              >
                {selectedAgentDetails.isBlocked ? "Blocked" : "Active"}
              </Tag>
            </div>
          </div>
          <CustomImage
            src={selectedAgentDetails.profilePicURL || imageURL.value}
          />
          <div className="element-distance">
            <UploadButton
              type="fileUpload"
              parentRef={imageContainerRef}
              resultType="url"
              accept="image/*"
              id="editAgentImageUpload"
              text="Change Image(300x300)"
              onChange={(newImage) => handleEditImageChange(newImage)}
            />
          </div>

          <div className={styles.formGroup} style={{ marginTop: "20px" }}>
            <div className={styles.formItem}>
              <Label value="Agent Name" />
              <Input value={selectedAgentDetails.name} width={200} readOnly />
            </div>
            <div className={styles.formItem}>
              <Label value="Country Code" />
              <Input
                value={selectedAgentDetails.countryCode}
                width={200}
                readOnly
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <Label value="Phone Number" />
            <Input value={selectedAgentDetails.phoneNo} readOnly />
          </div>
        </div>
      )}
    </Drawer>
  );

  const EditAgentDrawer = (
    <Drawer
      title="Edit Agent"
      placement="right"
      width={700}
      onClose={onEditDrawerClose}
      visible={isEditDrawerVisible}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button type="primary" onClick={handleUpdateAgent}>
            Update
          </Button>
        </div>
      }
    >
      <CustomImage src={editAgentDetails.profilePicURL} />
      <div className="element-distance">
        <UploadButton
          type="fileUpload"
          parentRef={imageContainerRef}
          resultType="url"
          accept="image/*"
          id="913fjhiu4ng2xzi"
          text="Change Image(300x300)"
          onChange={(newImage) => handleEditImageChange(newImage)}
        />
      </div>

      <div className={styles.formGroup} style={{ marginTop: "20px" }}>
        <div className={styles.formItem}>
          <Label value="Agent Name" />
          <Input
            value={editAgentDetails.name}
            onChange={(e) => handleEditInputChange("name", e.target.value)}
            width={200}
          />
        </div>
        <div className={styles.formItem}>
          <Label value="Country Code" />
          <Select
            onChange={(value) => handleEditInputChange("countryCode", value)}
            value={editAgentDetails.countryCode}
            optionFilterProp="children"
            filterOption={filterOption}
          >
            {countryCodes.map((item, index) => (
              <Option key={index} value={item.dial_code}>
                {`${item.dial_code}(${item.name})`}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className={styles.formGroup}>
        <Label value="Phone Number" />
        <Input
          value={editAgentDetails.phoneNo}
          onChange={(e) => handleEditInputChange("phoneNo", e.target.value)}
        />

        <div className={styles.formItem} style={{ marginTop: "10px" }}>
          <Label value="Select Agent Status" />
          <Select
            onChange={(value) =>
              handleEditInputChange("isBlocked", value === "Blocked")
            }
            value={editAgentDetails.isBlocked ? "Blocked" : "Active"}
          >
            <Option value="Active">Active</Option>
            <Option value="Blocked">Blocked</Option>
          </Select>
        </div>
      </div>
    </Drawer>
  );
  const handleSelectChange = (value) => {
    setIsBlocked(
      value === "Blocked" ? true : value === "Active" ? false : undefined
    );
  };
  const handleInputChange = (e) => {
    const inputSearchQuery = e.target.value.trim();

    if (inputSearchQuery.length >= 3 || inputSearchQuery.length === 0) {
      setSearchQuery(inputSearchQuery);
    }
  };
  return (
    <div className={styles.wrapper}>
      <h1>{"  "}</h1>
      <Header title="Agent" />
      <div className={styles.content}>
        <div className={styles.menu}>
          <header></header>
          <section>
            <div>
              <Input
                placeholder="Search by name"
                onChange={handleInputChange}
              />
            </div>
            <div style={{ marginLeft: "2rem " }}>
              <Select
                defaultValue="All"
                onChange={handleSelectChange}
                style={{ width: "200px" }}
              >
                <Option value="All">All</Option>
                <Option value="Active">Active</Option>
                <Option value="Blocked">Blocked</Option>
              </Select>
            </div>
            <div>
              <Button
                type="primary"
                disabled={props.isAddDisabled}
                shape={undefined}
                size="medium"
                style={{ alignItems: "flex-end" }}
                onClick={showAddDrawer}
              >
                Add Agent
              </Button>
            </div>
          </section>
        </div>
        <Spin spinning={loading} tip="Loading...">
          <Table columns={columns} dataSource={agentData} />
        </Spin>
      </div>

      {/*---------------------- Add Agent Drawer-------------------------*/}
      <Drawer
        title="Add Agent"
        placement="right"
        width={700}
        onClose={onAddClose}
        open={open}
        visible={isDrawerVisible}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button type="primary" onClick={handleAddAgent}>
              Submit
            </Button>
          </div>
        }
      >
        {" "}
        <CustomImage src={imageURL.value} />
        <div className="element-distance">
          <UploadButton
            type="fileUpload"
            parentRef={imageContainerRef}
            resultType="url"
            accept="image/*"
            id="913fjhiu4ng2vzi"
            text="Upload Image(300x300)"
            onChange={handleImageChange}
          />
        </div>
        <div className={styles.formGroup} style={{ marginTop: "20px" }}>
          <div className={styles.formItem}>
            <Label value="Agent Name" />
            <Input
              placeholder="Exter Name"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              width={200}
            />
          </div>
          <div className={styles.formItem}>
            <Label value="Country Code" />
            <Select
              onChange={handleCountryCodeChange}
              value={phoneCountryCode.value}
              optionFilterProp="children"
              filterOption={filterOption}
            >
              {countryCodes.map((item, index) => (
                <Option
                  key={index}
                  value={item.dial_code}
                >{`${item.dial_code}(${item.name})`}</Option>
              ))}
            </Select>
          </div>
        </div>
        <div className={styles.formGroup}>
          <Label value="Phone Number" />
          <Input
            placeholder="Enter Phone no."
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
      </Drawer>
      {AgentDetailsDrawer}
      {EditAgentDrawer}
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Agents);
