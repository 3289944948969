import axios from "axios";
import userActions from "../../redux/user-info/user-actions";
import store from "../../redux/store";
import { getPanelType, DevStorage } from "../utils/local-storage";
import { toastr } from "react-redux-toastr";

// const baseURL = "http://13.245.63.240:3000/api/admin";
// const baseURL = "http://13.245.63.240:8122/api/admin";
// const baseURL = "https://api.fromthestoretoyourdoor.ca/api/admin";
// const baseURL = "https://api.my-trolley.com/api/admin";
// const baseURL = 'https://api.goodgrocery.co/api/admin';
const axiosClient = axios.create({
  // baseURL,
});

axiosClient.interceptors.request.use(function (config) {
  const jwt = sessionStorage.getItem("trollyAdmin_jwt");
  const language = sessionStorage.getItem("trollyAdmin_language");
  // const language = 'en';
  let baseURL = "";
  if (process.env.REACT_APP_DEV_MODE == "1") {
    baseURL = DevStorage.getData().endpoint;
  } else {
    baseURL = process.env.REACT_APP_API_ENDPOINT;
  }

  // if(getPanelType() == 0) {
  //   // baseURL += '/api/admin';
  // } else if(getPanelType() == 1 || getPanelType() == 2) {
  //   // baseURL += '/api/branch'
  // }
  baseURL += "/api";
  // debugger;
  if (jwt) {
    config.headers["access-token"] = jwt;
  }
  config.baseURL = baseURL;
  config.headers["content-language"] = language ? language : "en";
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    console.log("RESPONSE: ", response.config.url, response.data);
    return response;
  },
  function (error) {
    if (error?.response?.data?.statusCode === 401) {
      store.dispatch(userActions.sessionExpired());
    } else {
      if (error?.response?.data?.message) {
        toastr.error(error.response.data.message);
      }
      // else if(error.pri){}
      else {
        toastr.error("Error", "Something Went Wrong");
      }
    }

    return Promise.reject(error.response);
  }
);

export default axiosClient;
