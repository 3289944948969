import axios from "axios";
import userActions from "../../redux/user-info/user-actions";
import store from "../../redux/store";
import { getPanelType, DevStorage } from "../utils/local-storage";
import { toastr } from "react-redux-toastr";

const axiosClient = axios.create({
  // baseURL,
});

axiosClient.interceptors.request.use(function (config) {
  const accessToken = sessionStorage.getItem("Admin_access_token");

  let baseURL = "https://api.theexcursionist.net";

  if (accessToken) {
    config.headers["access-token"] = accessToken;
  }
  config.baseURL = baseURL;
  config.headers["content-language"] = "en";
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    console.log("RESPONSE: ", response.config.url, response.data);
    return response;
  },
  function (error) {
    if (error?.response?.data?.statusCode === 401) {
      store.dispatch(userActions.sessionExpired());
    } else {
      if (error?.response?.data?.message) {
        toastr.error(error.response.data.message);
      }
      // else if(error.pri){}
      else {
        toastr.error("Error", "Something Went Wrong");
      }
    }

    return Promise.reject(error.response);
  }
);

export default axiosClient;
