import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Menu, Tooltip, Tag, Rate, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import { CustomerDetailsTable, CustomerDetailsFormModal } from "./components";
import AddCustomerNotes from "./components/Customer_Notes/AddCustomerNotes";
import { useParams } from "react-router-dom";
import { nestedParseJSON } from "../../shared/utils/object-manipulation";
import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  DelayedInput,
  BackButton,
  CustomTag,
} from "../../components/common";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  numberWithCommas,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiose";
// import { CustomerData } from "../../FakeData";
import placeholderImg from "../../assets/images/profile-placeholder.png";
import "./service-booking-details.scss";
import { Button } from "antd";
import { CustomTable } from "../../components/common";
import { dateFormat } from "../../shared/utils/local-storage";
// import AddEditUser from "../customers/components/add-edit-user/add-edit-user";
import { greenTick } from "../../shared/utils/icon-storage";

const styles = {
  wrapper: "customer-details__wrapper",
  menu: "customer-details__menu",
  pageSize: "customer-details__menu__records-per-page",
  image: "customer-details__image",
  form: "customer-details__form",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formWrapper: "customer-details__form-wrapper",
  editIcon: "customer-details__edit-icon",
  backButton: "customer-details__back-button",
};

const { Option } = Select;

function ServiceBookingDetails(selectedBookingId) {
  const { id } = useParams();
  const intl = useIntl();
  const [customerFlag, updateCustomerFlag] = useState(true);
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [bookingData, setBookingData] = useState(null);
  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });
  const [tableData, setTableData] = useState([]); // State to store the modified data for the table
  const [tableControlss, setTableControls] = useState({
    isLoading: true,
    isEmpty: false,
  });
  useEffect(() => {
    // Function to fetch the data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(`/bookings/${id}`);

        setBookingData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const column = [
    {
      title: "Service ID",
      dataIndex: "serviceId",
      key: "serviceId",
    },
    {
      title: "Service Image",
      dataIndex: "serviceImg",
      key: "serviceImg",
      render: (imgUrl) => (
        <img src={imgUrl} alt="Service" style={{ width: "50px" }} />
      ),
    },
    {
      title: "Service",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Booking Slot Date & Time",
      dataIndex: "bookingSlotDate",
      key: "bookingSlotDate",
      render: (date, record) => {
        if (!record.isPickupService) {
          return new Date(date).toLocaleString(undefined, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          });
        }

        return "N/A";
      },
    },
    {
      title: "Airline",
      dataIndex: "pickupDetail.airline",
      key: "airline",
      render: (airline, record) => {
        if (record.isPickupService && record.pickupDetail) {
          return record.pickupDetail.airline;
        }
        return "N/A";
      },
    },
    {
      title: "Flight Number",
      dataIndex: "pickupDetail.flightNumber",
      key: "flightNumber",
      render: (flightNumber, record) => {
        if (record.isPickupService && record.pickupDetail) {
          return record.pickupDetail.flightNumber;
        }
        return "N/A";
      },
    },
    {
      title: "Time of Arrival",
      dataIndex: "pickupDetail.timeOfArrival",
      key: "timeOfArrival",
      render: (timeOfArrival, record) => {
        if (record.isPickupService && record.pickupDetail) {
          return new Date(record.pickupDetail.timeOfArrival).toLocaleString();
        }
        return "N/A";
      },
    },

    {
      title: "Actual Price",
      dataIndex: "actualPrice",
      key: "actualPrice",
      render: (price) => <span>${price}</span>,
    },
    {
      title: "Discounted Price",
      dataIndex: "discountedPrice",
      key: "discountedPrice",
      render: (price) => <span>${price}</span>,
    },
    {
      title: "Booking Status",
      dataIndex: "bookedStatus",
      key: "bookedStatus",
      render: (bookedStatus) => (
        <Tag color={bookedStatus ? "green" : "red"}>
          {bookedStatus ? bookedStatus : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Service Status",
      dataIndex: "serviceStatus",
      key: "serviceStatus",
      render: (serviceStatus) => (
        <Tag color={serviceStatus ? "green" : "red"}>
          {serviceStatus ? serviceStatus : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Instructions",
      dataIndex: "instructions",
      key: "instructions",
      render: (instructions) => (
        <Tag color={instructions ? "green" : "red"}>
          {instructions ? instructions : "N/A"}
        </Tag>
      ),
    },
  ];

  const paymentDetailsColumns = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (transactionId) => (
        <Tag color={transactionId ? "blue" : "red"}>
          {transactionId ? transactionId : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Stripe Customer ID",
      dataIndex: "stripeCustomerId",
      key: "stripeCustomerId",
      render: (stripeCustomerId) => (
        <Tag color={stripeCustomerId ? "purple" : "red"}>
          {stripeCustomerId ? stripeCustomerId : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Booking ID",
      dataIndex: "booking_id",
      key: "booking_id",
      render: (booking_id) => (
        <Tag color={booking_id ? "blue" : "red"}>
          {booking_id ? booking_id : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    // {
    //   title: "Refund Status ",
    //   dataIndex: "refundStatus",
    //   key: "refundStatus",
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <Tag color={amount ? "green" : "red"}>{amount ? amount : "N/A"}</Tag>
      ),
    },
    {
      title: " Refunded Amount",
      dataIndex: "refundAmount",
      key: "refundAmount",
      render: (refundAmount) => (
        <Tag color={refundAmount ? "orange" : "red"}>
          {refundAmount ? refundAmount : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Cancelation Charges",
      dataIndex: "cancelationCharges",
      key: "cancelationCharges",
      render: (cancelationCharges) => (
        <Tag color={cancelationCharges ? "orange" : "red"}>
          {cancelationCharges ? cancelationCharges : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Stripe Payment Method ID",
      dataIndex: "stripePaymentMethodId",
      key: "stripePaymentMethodId",
      render: (stripePaymentMethodId) => (
        <Tag color={stripePaymentMethodId ? "purple" : "red"}>
          {stripePaymentMethodId ? stripePaymentMethodId : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Stripe Payment Intent ID",
      dataIndex: "stripePaymentIntentId",
      key: "stripePaymentIntentId",
      render: (stripePaymentIntentId) => (
        <Tag color={stripePaymentIntentId ? "purple" : "red"}>
          {stripePaymentIntentId ? stripePaymentIntentId : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Refund Status  ",
      dataIndex: "refundStatus",
      key: "refundStatus",
      render: (refundStatus) => (
        <Tag color={refundStatus ? "green" : "red"}>
          {refundStatus ? refundStatus : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Refund Failure Reason  ",
      dataIndex: "refundFailureReason",
      key: "refundFailureReason",
      render: (refundFailureReason) => (
        <Tag color={refundFailureReason ? "green" : "red"}>
          {refundFailureReason ? refundFailureReason : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAtLocalDateTime",
      key: "createdAtLocalDateTime",
      width: 300,
      render: (createdAtLocalDateTime) => (
        <Tag>{moment(createdAtLocalDateTime).format("YYYY-MM-DD")}</Tag>
      ),
    },
  ];

  const [customerData, updateCustomerData] = useState({
    isLoading: false,
    data: "",
  });

  const [modalControls, updateModalControls] = useState({
    formModalVisible: false,
    orderDetailsModalVisible: false,
    AddCustomerNotesVisible: false,
    modalType: "EDIT",
    data: {},
    isLoading: false,
    customerId: "",
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const openFormModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: true,
      data: customerData,
    });
  };

  const closeModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: false,

      // orderDetailsModalVisible: false,
      // AddCustomerNotesVisible: false,
    });
  };

  // Mount

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  // useEffect(() => {
  //   let customerId = window?.location?.pathname.split("details/")[1];

  //   getCustomersData(customerId);
  // }, [customerFlag]);

  return (
    <div className={styles.wrapper}>
      <Header title="Service Booking Details" showBackButton={true} />
      <Panel
        className="custom-section single"
        isLoading={customerData.isLoading}
      >
        <div className={styles.formWrapper}>
          <div style={{ display: "flex", width: "100%", flexWrap: "nowrap" }}>
            <div className={styles.image}>
              <img
                src={
                  bookingData?.customer?.profilePicURL
                    ? bookingData?.customer?.profilePicURL
                    : placeholderImg
                }
                onError={handleImageError}
                alt="Customer Profile Pic"
              />
            </div>
            <div className={styles.form}>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>First Name</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.customer?.firstName}
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Last Name</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.customer?.lastName}
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Phone</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.customer?.phoneNo}
                  </div>
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>Email</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.customer?.email}
                  </div>
                </div>

                <div className={styles.formItem}>
                  <label>Username</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.customer?.username}
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Payment Status</label>
                  <div className={styles.detailsField}>
                    {" "}
                    <Tag color="purple">{bookingData?.paymentStatus}</Tag>
                  </div>
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>Total Actual Price</label>
                  <div className={styles.detailsField}>
                    {" "}
                    <Tag color="blue"> {bookingData?.totalActualPrice}</Tag>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Discount</label>
                  <div className={styles.detailsField}>
                    {" "}
                    <Tag color="blue"> {bookingData?.discount}</Tag>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Mode Of Payment</label>
                  <div className={styles.detailsField}>
                    {" "}
                    <Tag color="purple">{bookingData?.modeOfPayment}</Tag>
                  </div>
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>Coupon Discount</label>
                  <div className={styles.detailsField}>
                    <Tag color="green"> {bookingData?.couponDiscount}</Tag>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Overall Discount</label>
                  <div className={styles.detailsField}>
                    <Tag color="green"> {bookingData?.overallDiscount}</Tag>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Total Service Payable</label>
                  <div className={styles.detailsField}>
                    {" "}
                    <Tag color="green"> {bookingData?.totalServicePayable}</Tag>
                  </div>
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>Discount Type</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.discountType}
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Coupon Code</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.couponCode}
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Booking Status</label>
                  <div
                    className={styles.detailsField}
                    style={{ color: "#50C878", fontWeight: "600" }}
                  >
                    {" "}
                    {bookingData?.bookingStatus}
                  </div>
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>Reason For Cancel</label>
                  <div className={styles.detailsField}>
                    <Tag color="green"> {bookingData?.reasonForCancel}</Tag>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Booking Id</label>
                  <div className={styles.detailsField}>
                    <Tag color="green"> {bookingData?.booking_id}</Tag>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Coupon Code</label>
                  <div className={styles.detailsField}>
                    <Tag color="PURPLE"> {bookingData?.couponCode}</Tag>
                  </div>
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>Created At</label>
                  <div className={styles.detailsField}>
                    <Tag>
                      {moment(bookingData?.createdAt).format("YYYY-MM-DD")}
                    </Tag>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Cancelation Charge</label>
                  <div className={styles.detailsField}>
                    <Tag color="Red"> {bookingData?.cancelationCharge}</Tag>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Reason For Cancel</label>
                  <div className={styles.detailsField}>
                    <Tag color="Red"> {bookingData?.reasonForCancel}</Tag>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer></footer>
        </div>

        <div>
          <h1>Booked Services </h1>
          <Table
            columns={column}
            dataSource={bookingData?.bookedServices}
            scroll={{ x: 400 }}
          />
        </div>
        <div>
          <h1>Payment Details</h1>
          <Table
            columns={paymentDetailsColumns}
            dataSource={[bookingData?.paymentDetails]}
            scroll={{ x: 400 }}
          />
        </div>
      </Panel>
    </div>
  );
}
export default ServiceBookingDetails;
