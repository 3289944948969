import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import Login from "./login/login";
import Register from "./register/register";
import "./auth-container.scss";
import { toastr } from "react-redux-toastr";
import TermsAndConditions from "../containers/TermsAndConditions";

const styles = {
  wrapper: "auth-container__wrapper",
  contentContainer: "auth-container__content-container",
};

class Auth extends React.Component {
  // componentDidMount() {
  //   if (sessionStorage.getItem("trollyAdmin_jwt")) {
  //     toastr.success("Redirecting", "Already Logged In", {
  //       timeOut: 1500,
  //     });
  //     const info = JSON.parse(sessionStorage.getItem("trollyAdmin_userInfo"));
  //     // setTimeout(() => {
  //     //   this.props.history.replace(
  //     //     `/home/
  //     //     ${
  //     //       verticleType[verticles[info.selectedVerticalType].type]
  //     //     }/
  //     //     ${verticles[
  //     //       info.selectedVerticalType
  //     //     ].text.toLowerCase()}/dashboard`
  //     //   );
  //     // }, 1500);
  //   }
  // }
  render() {
    return (
      <div
        style={{
          backgroundImage: this.props.bgImage
            ? `url(${this.props.bgImage})`
            : undefined,
        }}
        className={styles.wrapper}
      >
        <div className={styles.contentContainer}>
          <Switch>
            <Route path="/auth/login" component={Login} />

            <Route path="/auth/register" component={Register} />

            <Route
              path="/auth"
              exact
              component={() => <Redirect to="/auth/login" />}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enterprice: state.user.enterprice,
  settingsVertical: state.user.enterprice.verticalType,
  panelType: state.user.enterprice.panelType,
  bgImage: state.user.enterprice.bgImage,
  // verticle: state.user.enterprice.verticalType,
});

export default connect(mapStateToProps)(Auth);
