
const roleManagementData = () => {
    return (
        [
            {
                "adminId": 141,
                "name": "Divya",
                "email": "lakshya@gmail.com",
                "password": "$2b$10$nIvOxFAmDtrNnMuLEM.KQeCvmciuLl7Pu846AWjRYEwHMvyIXdne.",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-06-22T14:19:19.000Z",
                "updatedAt": "2021-06-22T14:19:19.000Z",
                "countryCode": "+91",
                "phoneNo": "0123456789",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 9254,
                "menuId": 1,
                "status": 1,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 21,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 144,
                "name": "Divya Sree",
                "email": "divyasree@gmail.com",
                "password": "$2b$10$55R.Yd7hYIErI8fgah/Mve5VJEsXPWzAOKbI/3WPNirDtIaUQpQ6e",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-06-22T14:37:55.000Z",
                "updatedAt": "2021-06-22T14:37:55.000Z",
                "countryCode": "+91",
                "phoneNo": "6300993460",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 6858,
                "menuId": 1,
                "status": 2,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 146,
                "name": "Divya",
                "email": "divya1@gmail.com",
                "password": "$2b$10$87DDwFSB79QSCvg2/DtDQezAGJOG5z3Qa0p/ETUNVEIrt8F9LYvtq",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-06-23T05:22:08.000Z",
                "updatedAt": "2021-06-23T05:22:08.000Z",
                "countryCode": "+91",
                "phoneNo": "123456789678",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 6758,
                "menuId": 1,
                "status": 3,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 175,
                "name": "Pardha",
                "email": "tamanviadmin@yopmail.com",
                "password": "$2b$10$mRsDzQ4yr7EZ7RmOvYQPYugsEmmcyNNhwsz87UCppuLgNvkFdc91a",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-07-06T11:35:21.000Z",
                "updatedAt": "2021-07-06T11:35:21.000Z",
                "countryCode": "+44",
                "phoneNo": "07507557905",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7913,
                "menuId": 1,
                "status": 4,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "1",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 180,
                "name": "div",
                "email": "divv@gmail.com",
                "password": "$2b$10$pdHSBffEvqBzOzpq0kjia.Eudgw4S9S2ynikUf1tijBb4.cZl5Ws2",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-07-12T05:33:47.000Z",
                "updatedAt": "2021-07-12T05:33:47.000Z",
                "countryCode": "+91",
                "phoneNo": "2345676666",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8236,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 181,
                "name": "div1",
                "email": "dd@gmail.comm",
                "password": "$2b$10$Gnvi9Gz5M0Rz.Vcb1MdF9.4tc92D65lv9KOQeAnIYIHwaQ32WXzV2",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-07-12T05:41:48.000Z",
                "updatedAt": "2021-07-12T05:41:48.000Z",
                "countryCode": "+91",
                "phoneNo": "234566",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 6618,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 188,
                "name": "Lakshya",
                "email": "lakshya0116@gmail.co",
                "password": "$2b$10$YVW07soqdFMmWEkDP6IzF.YdQhsHIMMs40xVqt3xzTVYLBar32qna",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-07-12T16:02:12.000Z",
                "updatedAt": "2021-07-12T16:02:12.000Z",
                "countryCode": "+590",
                "phoneNo": "8054111871",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7856,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 189,
                "name": "Arthi",
                "email": "arthi.parama@hotmail.com",
                "password": "$2b$10$n1uR63mSB740M3mZon0Fj.P5vfpxuJ4kTLXMNGb2h0g9qm2TkUhBK",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-07-16T21:06:24.000Z",
                "updatedAt": "2021-07-16T21:06:24.000Z",
                "countryCode": "+44",
                "phoneNo": "7939100054",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "0",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": "QPJ7II5M4KFD3450RG7EZ3FN",
                "roleId": 9044,
                "menuId": 1,
                "status": 1,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 21,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 199,
                "name": "divya",
                "email": "div7@gmail.com",
                "password": "$2b$10$M6Gu0Sd158AlYqQuQNfDx.YGu6Zph3QNnw2fq45EkOegwb23rPS5S",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-07-26T08:52:01.000Z",
                "updatedAt": "2021-07-26T08:52:01.000Z",
                "countryCode": "+91",
                "phoneNo": "1234560986",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7318,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 201,
                "name": "Sindhu ganta",
                "email": "divyastore@gmail.com",
                "password": "$2b$10$A.ec4vS1gn.2exP5g66cl.PyP2/fWq23Z3Y9tp9j8DNpicaHlfmA.",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-07-28T18:54:39.000Z",
                "updatedAt": "2021-07-28T18:54:39.000Z",
                "countryCode": "+44",
                "phoneNo": "0777777777",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": "8RBBFD318E0TJIEEF2NP5BP0",
                "roleId": 8198,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 202,
                "name": "Hemant-MERCHANT",
                "email": "merchant@yopmail.com",
                "password": "$2b$10$6tpISNoMOL2tpb0HoIXBkurCUFWyY.lmS19Kym.RD2jkouip6o9hS",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-01T09:14:20.000Z",
                "updatedAt": "2021-08-01T09:14:20.000Z",
                "countryCode": "+91",
                "phoneNo": "9028302753",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7457,
                "menuId": 1,
                "status": 1,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 203,
                "name": "Merchant-Hemant",
                "email": "merchant123@yopmail.com",
                "password": "$2b$10$5O2u9nyZAb4ONeb6hvQmn.ffoBOnte1v9SxhPqW27Pl6oIGH1i34C",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-01T09:15:45.000Z",
                "updatedAt": "2021-08-01T09:15:45.000Z",
                "countryCode": "+91",
                "phoneNo": "9028302710",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7476,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 205,
                "name": "ssss",
                "email": "imcool.lakshya16@gmaill.com",
                "password": "$2b$10$C7JGGlbxtXy1p10VjMGq1uP3ecKo/ATJZuado7AMhBDQmNiCc2cWa",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-01T13:08:13.000Z",
                "updatedAt": "2021-08-01T13:08:13.000Z",
                "countryCode": "+213",
                "phoneNo": "12345678111",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7571,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 206,
                "name": "Lakshya",
                "email": "avimusic1601@gmailLl..com",
                "password": "$2b$10$MKYoD4a1M8vGkeVrxlunAOcu6hWmvB9QFEDW7IImImWV4oJXWgIRG",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-01T13:14:01.000Z",
                "updatedAt": "2021-08-01T13:14:01.000Z",
                "countryCode": "+355",
                "phoneNo": "4455",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7590,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 207,
                "name": "efefefefe",
                "email": "imcool.lakshya1@gmail.com",
                "password": "$2b$10$uKctq.TOzhb5m99/6.hdC.pwU3pQWn32n5nZ5sLtPmbojSH7hIJei",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-01T13:15:46.000Z",
                "updatedAt": "2021-08-01T13:15:46.000Z",
                "countryCode": "+213",
                "phoneNo": "12345",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7609,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 208,
                "name": "divya 123",
                "email": "diva123@gmailll.com",
                "password": "$2b$10$bBvHXr3n7PraOI48xGhgWeantPogyYMghbbX7h1n5ukmqSPQjB3fK",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-02T12:23:31.000Z",
                "updatedAt": "2021-08-02T12:23:31.000Z",
                "countryCode": "+91",
                "phoneNo": "1235",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7666,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 209,
                "name": "divya 1234",
                "email": "divya1234@gmaillll.comm",
                "password": "$2b$10$9rZsgKicu5924FWX4Y3w3usI0r/o.cZ56zwdtfzcjo65T.T0SSRX6",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-02T12:24:50.000Z",
                "updatedAt": "2021-08-02T12:24:50.000Z",
                "countryCode": "+91",
                "phoneNo": "1236888888",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8293,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 211,
                "name": "divya 1",
                "email": "divya@gmail.com",
                "password": "$2b$10$nmH8WBphiuR3CNvaD96kiOC3Diaabyyi9Nd/BmKjgsM9ZrrLFRcf2",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-23T10:08:09.000Z",
                "updatedAt": "2021-08-23T10:08:09.000Z",
                "countryCode": "+91",
                "phoneNo": "7342989201",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7799,
                "menuId": 1,
                "status": 1,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 212,
                "name": "div",
                "email": "tamanviadmin@yopmail.com",
                "password": "$2b$10$xzuq14/VfIOLMoF.XNgtfO5Zd3rHkXRqRmUReHrTiqUdwiCzD6CZK",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-27T05:11:46.000Z",
                "updatedAt": "2021-08-27T05:11:46.000Z",
                "countryCode": "+91",
                "phoneNo": "1234567898",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7818,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 213,
                "name": "Lakshya",
                "email": "lakshya0116@gmail.com",
                "password": "$2b$10$Ro1i/lDSxT4ljCkAHeh5veZ39uyyFIvjWjIeFH/n1NLunr2tHX5gC",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-03T04:25:23.000Z",
                "updatedAt": "2021-09-03T04:25:23.000Z",
                "countryCode": "+91",
                "phoneNo": "1234567789",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 7875,
                "menuId": 1,
                "status": 1,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 214,
                "name": "Pushpendra",
                "email": "pushpendrasingh2306@gmail.com",
                "password": "$2b$10$LleXguFtHl3JFq87uaGpWeQSTlQB87QxpFxFdQuv4Kyi1f0m.Kk0y",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-03T08:13:38.000Z",
                "updatedAt": "2021-09-03T08:13:38.000Z",
                "countryCode": "+91",
                "phoneNo": "6396756230",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": "QAMSE3L40BEHFSFKTAB15QC8",
                "roleId": 7970,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 215,
                "name": "divya",
                "email": "divya222@gmail.com",
                "password": "$2b$10$k07SNQu4BMN6SpmU5gc6E.3.qaNcRJSRvUfTmum719gyIUDC42vnO",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-06T10:27:46.000Z",
                "updatedAt": "2021-09-06T10:27:46.000Z",
                "countryCode": "+91",
                "phoneNo": "9988334455",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8008,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 216,
                "name": "divya33",
                "email": "tamanviadmin999@yopmail.com",
                "password": "$2b$10$MJrkI25wMTqA1eTS1VGsAeXTUKgftdK4uHiSFznkYu2MoSVlke8KW",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-08T13:34:48.000Z",
                "updatedAt": "2021-09-08T13:34:48.000Z",
                "countryCode": "+91",
                "phoneNo": "6666988888",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8179,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 217,
                "name": "Samar Kundal",
                "email": "samar@yopmail.com",
                "password": "$2b$10$u4wd5y8ibDgYhPBxYOkSxeVz0UkIAUOa/6Ynavu0SrYqLFkf1rb26",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-22T06:00:32.000Z",
                "updatedAt": "2021-09-22T06:00:32.000Z",
                "countryCode": "+91",
                "phoneNo": "7897897891",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8217,
                "menuId": 1,
                "status": 1,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "1",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 218,
                "name": "divya",
                "email": "yagantidivyasree321@gmail.com",
                "password": "$2b$10$VumWhZAFV/VJFhk4Oo3tPeVkgxDtX4Ig3aWWd.CsJTuVZ5akrPtGS",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-24T04:58:40.000Z",
                "updatedAt": "2021-09-24T04:58:40.000Z",
                "countryCode": "+91",
                "phoneNo": "8978298559",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8274,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 219,
                "name": "divya",
                "email": "divya09@gmail.com",
                "password": "$2b$10$uPQvMATFtc0SQKVpZEBm3uCOhw5hUv9MsmmsCglvy4K3tIIPttzgu",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-24T08:46:43.000Z",
                "updatedAt": "2021-09-24T08:46:43.000Z",
                "countryCode": "+91",
                "phoneNo": "1234999999",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8312,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 221,
                "name": "lilies",
                "email": "liliesw@gkial.co",
                "password": "$2b$10$VL4mneuY/nxVTLv/1/fGE.Ul4CICCD7lOsCQZlj8X8RdfF1CJem7e",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-27T15:47:26.000Z",
                "updatedAt": "2021-09-27T15:47:26.000Z",
                "countryCode": "+91",
                "phoneNo": "7876545875",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8350,
                "menuId": 1,
                "status": 1,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 226,
                "name": "sindhu",
                "email": "tamanviadmin@yopmail.com",
                "password": "$2b$10$3QO8k/yV406Kh/0.xIuvTeWQb55WzroBBDKC6buh7zH5XwGPBLPfC",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-10-04T09:06:00.000Z",
                "updatedAt": "2021-10-04T09:06:00.000Z",
                "countryCode": "+44",
                "phoneNo": "7515159949",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8545,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 227,
                "name": "Samar",
                "email": "samar.kundal@oyelabs.com",
                "password": "$2b$10$FaATDYfVeNPYDx.a8cgI3OGMqf0KVRDyctNajD5MYdeltYCIRwi2W",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-10-05T06:56:05.000Z",
                "updatedAt": "2021-10-05T06:56:05.000Z",
                "countryCode": "+91",
                "phoneNo": "7878787891",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8565,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 228,
                "name": "Navya",
                "email": "navya.yaganti12@oyelabs.ccom",
                "password": "$2b$10$6B94gR0cxtBZBF1JE3P3bujzddfrEfGjAzT6kuhrIzpgTvgNk.p/e",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-10-05T07:33:38.000Z",
                "updatedAt": "2021-10-05T07:33:38.000Z",
                "countryCode": "+91",
                "phoneNo": "1234567896",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8645,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 229,
                "name": "Samar",
                "email": "samar.k@yopmail.com",
                "password": "$2b$10$aFaJkOQ7XUjb7oelO/qrYuUB9Qy0MOKvlBNKDZezYEJkBaDSxcY2m",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-10-05T10:39:51.000Z",
                "updatedAt": "2021-10-05T10:39:51.000Z",
                "countryCode": "+91",
                "phoneNo": "7897897567",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8665,
                "menuId": 1,
                "status": 1,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 231,
                "name": "navya sree",
                "email": "navya@gmail.com",
                "password": "$2b$10$VmENXA2PTxEgxljUDA7QMup1Dk60Yuqf7QR925Cw5qK51gfxLr9A.",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-10-13T06:42:07.000Z",
                "updatedAt": "2021-10-13T06:42:07.000Z",
                "countryCode": "+91",
                "phoneNo": "1234567899",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": "-330",
                "language": "en",
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8960,
                "menuId": 1,
                "status": 0,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "0",
                        "verticalType": 0
                    },
                    {
                        "menuId": 21,
                        "status": "0",
                        "verticalType": 0
                    }
                ]
            },
            {
                "adminId": 233,
                "name": "Samar Kundal",
                "email": "samatest11abc@yopmail.com",
                "password": "$2b$10$GU/osCVJqK6ETEjbKfVCfeE4r1J8sOXyYCpKJPONTl3pFHY./S1dS",
                "isSupperAdmin": 0,
                "isBlocked": 0,
                "createdAt": "2021-12-29T13:51:59.000Z",
                "updatedAt": "2021-12-29T13:51:59.000Z",
                "countryCode": "+91",
                "phoneNo": "7897897211",
                "masterAdminId": 1,
                "userType": "Subadmin",
                "isDeleted": 0,
                "timezone": null,
                "language": null,
                "selectedVerticalType": 0,
                "passwordResetToken": null,
                "roleId": 8981,
                "menuId": 1,
                "status": 1,
                "verticalType": 0,
                "roles": [
                    {
                        "menuId": 1,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 2,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 3,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 4,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 5,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 6,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 7,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 8,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 9,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 10,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 11,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 12,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 13,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 14,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 15,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 16,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 17,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 18,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 19,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 20,
                        "status": "1",
                        "verticalType": 0
                    },
                    {
                        "menuId": 21,
                        "status": "1",
                        "verticalType": 0
                    }
                ]
            }
        ]
    )
}
const CustomerData = () => {
    return (
        [
            {
                "customerId": 1390,
                "adminId": 1,
                "firstName": "TestSupra",
                "phoneNo": "9851650491",
                "lastName": null,
                "countryCode": "+91",
                "email": "testsupra@gmail.com",
                "password": "$2b$10$p/ReEry0RmFRdQUPbDzO0uq4FK9leEA0u7rwNmsHx1GTNnAmJJsXa",
                "accessToken": null,
                "socialId": null,
                "appVersion": 1,
                "deviceToken": "dg_H7we4cv_LgiQRH8hUI0:APA91bGIZocMjh4IskdkIsNpAE-6bp9DTInOyHYcFnP2R2ck543YWg5aeaPn9ZMTcl2w19AJoUHzWxF9Uyjv8tiqTJCHZI7tII87WGKVquCUR5A4d-IgROw0o6EQguindmTBwBDuN791",
                "deviceType": "WEB",
                "socialMode": null,
                "lastOnlineDate": "2022-12-19T19:14:10.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 2543,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": 0,
                "availableCredits": null,
                "online": null,
                "referralCode": "9NA2P988",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "23.1492279",
                "longitude": "87.6215207",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 87.6215207,
                    "y": 23.1492279
                },
                "age": null,
                "gender": null,
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-12-19T19:14:10.000Z",
                "address": "{\"city\": \"Greater London\", \"address\": \"17-21 Leicester Square, London WC2H 7LE, UK\", \"phoneNo\": null, \"pinCode\": \"WC2H 7LE\", \"landMark\": null, \"otherText\": \"\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
            },
            {
                "customerId": 1387,
                "adminId": 2,
                "firstName": "Papu",
                "phoneNo": "7029661710",
                "lastName": "Sarkar",
                "countryCode": "+91",
                "email": "papus944@gmail.com",
                "password": "$2b$10$j68G8.3u.uZtbH9AnPV7l.9HkaVFAXMNFKqEWWmFA2b1Qms74Nxua",
                "accessToken": null,
                "socialId": null,
                "appVersion": 1,
                "deviceToken": "MAKETHISOPTIONAL",
                "deviceType": "WEB",
                "socialMode": null,
                "lastOnlineDate": "2022-12-02T05:08:16.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": "IKEQPZDRABKCGQQ7J61MH8T5",
                "invitationCode": null,
                "otpCode": 3952,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "LNH5JETP",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 120,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-12-02T05:08:16.000Z",
                "address": "{}"
            },
            {
                "customerId": 1386,
                "adminId": 3,
                "firstName": "Supravat",
                "phoneNo": "9851650495",
                "lastName": "Sarkar",
                "countryCode": "+91",
                "email": "supravat.sarkar@oyelabs.com",
                "password": null,
                "accessToken": null,
                "socialId": "111163841682549091065",
                "appVersion": 1,
                "deviceToken": null,
                "deviceType": "ANDROID",
                "socialMode": "11116384168254909106",
                "lastOnlineDate": "2022-12-01T08:07:08.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": "B3HZBM3EA0I1PYBNEE97OPE5",
                "invitationCode": null,
                "otpCode": 2814,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": 50,
                "availableCredits": null,
                "online": null,
                "referralCode": "5POBZ7PN",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 0,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-12-01T08:07:08.000Z",
                "address": "{\"city\": \"England\", \"address\": \"3 Wardour St, London W1D 6PB, UK\", \"phoneNo\": null, \"pinCode\": \"W1D 6PB\", \"landMark\": null, \"otherText\": \"Other\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
            },
            {
                "customerId": 1370,
                "adminId": 4,
                "firstName": "Atul",
                "phoneNo": "8800695942",
                "lastName": "kumar",
                "countryCode": "+91",
                "email": "atulkumar99707.ak13@gmail.com",
                "password": "$2b$10$pBWOWWtAz2c5I86x09Xi4.28LKCwBOg32QUL8gNtMYHKgGWDa7.6i",
                "accessToken": null,
                "socialId": "101314087130518109969",
                "appVersion": 1,
                "deviceToken": null,
                "deviceType": "WEB",
                "socialMode": "10131408713051810996",
                "lastOnlineDate": "2022-11-19T13:50:32.000Z",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/76yp1669008151111-4mR71669008151043KakashiReadytofight.jfif",
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 6738,
                "phoneVerified": 0,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "JRQAOGQR",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-19T13:50:32.000Z",
                "address": "{\"city\": \"England\", \"address\": \"52 Eaton Square, London SW1W 9AP, UK\", \"phoneNo\": null, \"pinCode\": \"SW1W 9AP\", \"landMark\": null, \"otherText\": \"Work\", \"streetName\": null, \"addressType\": 3, \"houseNumber\": null}"
            },
            {
                "customerId": 1369,
                "adminId": 1,
                "firstName": "Atul",
                "phoneNo": "9953190769",
                "lastName": "kumar",
                "countryCode": "+91",
                "email": "atul.kumar@oyelabs.com",
                "password": "$2b$10$wsTILSUZnHXH/9wWtawAM.BsusFiOGBDFoGRTwYUQgrnRX7F4GQca",
                "accessToken": null,
                "socialId": "105324477467638238892",
                "appVersion": 1,
                "deviceToken": "cA5qQSIGffl28iPnBRpfwX:APA91bF70fyzKa8lSALrEM0tIjo8kMRDfpNoMTa61SNKBG-trWE64At5__eza719eGBFJAk4L261Qzr959fus0G_NaxQ-3yjcnbczwO29vLhqXqKVu8t4cuBnndjIZropyposdyyTEKp",
                "deviceType": "WEB",
                "socialMode": "10532447746763823889",
                "lastOnlineDate": "2022-11-17T13:03:20.000Z",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/irNA1669874492692-ANOb1669874492600screencapturelocalhost300020221124202030.png",
                "isNotificationOn": 1,
                "passwordResetToken": "5PCZ8LRBFQ4KM64ZR24PLD69",
                "invitationCode": null,
                "otpCode": 3897,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": 0,
                "availableCredits": null,
                "online": null,
                "referralCode": "AZN31DZ7",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 1,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "51.4947186",
                "longitude": "-0.1436198",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-17T13:03:20.000Z",
                "address": "{\"city\": \"England\", \"address\": \"320b Vauxhall Bridge Rd, London SW1V 1AA, UK\", \"phoneNo\": null, \"pinCode\": \"SW1V 1AA\", \"landMark\": null, \"otherText\": \"Other\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
            },
            {
                "customerId": 1365,
                "adminId": 1,
                "firstName": null,
                "phoneNo": "9503666645",
                "lastName": null,
                "countryCode": "+91",
                "email": "mindraneela33125@gmail.com",
                "password": "$2b$10$0Lj.JKWUeRHh722H43x.quaCe9qDrYF2qtb19aZ15ypYot.NMyWEy",
                "accessToken": null,
                "socialId": null,
                "appVersion": 1,
                "deviceToken": "MAKETHISOPTIONAL",
                "deviceType": "WEB",
                "socialMode": null,
                "lastOnlineDate": "2022-11-17T12:21:52.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 7834,
                "phoneVerified": 0,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "BNEAKJS6",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 120,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-17T12:21:52.000Z",
                "address": "{}"
            },
            {
                "customerId": 1344,
                "adminId": 1,
                "firstName": "Indraneela",
                "phoneNo": "9381507443",
                "lastName": "Mannam",
                "countryCode": "+91",
                "email": "mindraneela331@gmail.com",
                "password": "$2b$10$AzT02hhOxP7hoRF5pYw4/OUEr8GCRdo5oB8KGwF4WLGGy8.1Acpq.",
                "accessToken": null,
                "socialId": "104274537376022577432",
                "appVersion": 1,
                "deviceToken": null,
                "deviceType": "WEB",
                "socialMode": "10427453737602257743",
                "lastOnlineDate": "2022-11-11T09:10:10.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": "CGMZKGNDZQZAMYEQ2KZL0L32",
                "invitationCode": null,
                "otpCode": 2431,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 9999,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "4SMLYCR1",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-11T09:10:10.000Z",
                "address": "{\"city\": \"England\", \"address\": \"155 Russell Rd, Birmingham B13 8RR, UK\", \"phoneNo\": null, \"pinCode\": \"B13 8RR\", \"landMark\": null, \"otherText\": \"Other\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
            },
            {
                "customerId": 1336,
                "adminId": 1,
                "firstName": "Mannam",
                "phoneNo": "9912686351",
                "lastName": "Indraneela",
                "countryCode": "+91",
                "email": "mindraneela331@oyelabs.com",
                "password": null,
                "accessToken": null,
                "socialId": "102857298253027059501",
                "appVersion": 1,
                "deviceToken": null,
                "deviceType": "WEB",
                "socialMode": "10285729825302705950",
                "lastOnlineDate": "2022-11-10T12:12:43.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 7823,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "DAP43DA6",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-10T12:12:43.000Z",
                "address": "{}"
            },
            {
                "customerId": 1332,
                "adminId": 1,
                "firstName": "Mandeep Singh",
                "phoneNo": "6239287119",
                "lastName": "S",
                "countryCode": "91",
                "email": "mandeep.singh@oyelabs.com",
                "password": "$2b$10$gFD7xr1YOSd3kY1f/yPLGOfFiuhfErFaX/Y6FOClde.9NaNOaaCKm",
                "accessToken": null,
                "socialId": "113520825629372851450",
                "appVersion": 1,
                "deviceToken": "d0m58WHTT2Sjkx9BMXpTiQ:APA91bFKDBA4AP-fXqtjM9p7x5O2Rd5BoPJU7hV1ItYhotHk5ajAreKBa2egAUnRzgSDu5yvlCCh9da1n31NFsrntxuFOQC076zVpXaOfqnRO_01lqxOaIXwWx7eZNj8_x_UlMN_VJal",
                "deviceType": "ANDROID",
                "socialMode": "11352082562937285145",
                "lastOnlineDate": "2022-11-04T08:23:10.000Z",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/5na51669023109992-7WFW1669023109943androidwall.webp",
                "isNotificationOn": 1,
                "passwordResetToken": "S7OE1SDQR8FP8YYIMJ60CEM0",
                "invitationCode": null,
                "otpCode": 1354,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 312.72,
                "loyalityPoint": 0,
                "availableCredits": null,
                "online": null,
                "referralCode": "90TBN3FD",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 1,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "52.04950562160768",
                "longitude": "-0.26801979975377366",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-04T08:23:10.000Z",
                "address": "{\"city\": \"England\", \"address\": \"76 Station Rd, Langford, Biggleswade SG18 9PG, UK\", \"phoneNo\": null, \"pinCode\": \"SG18 9PG\", \"landMark\": null, \"otherText\": \"navya long\", \"streetName\": null, \"addressType\": 3, \"houseNumber\": null}"
            },
            {
                "customerId": 1326,
                "adminId": 1,
                "firstName": "sss",
                "phoneNo": "1234567894",
                "lastName": null,
                "countryCode": "+44",
                "email": "sss@gmail.com",
                "password": "$2b$10$WXfJ0gPZcX/VM1/3xtqej.jAzssslz//AqVODZLAoLyIioAYPMXJC",
                "accessToken": null,
                "socialId": null,
                "appVersion": 1,
                "deviceToken": "MAKETHISOPTIONAL",
                "deviceType": "WEB",
                "socialMode": null,
                "lastOnlineDate": "2022-11-01T10:43:05.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 5628,
                "phoneVerified": 0,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "Q58ZNTGP",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 120,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-01T10:43:05.000Z",
                "address": "{}"
            }
        ]
    )
}
const PromoCodeData = () => {
   return(
       [
           {
               "categoryId": 502,
               "categoryName": "Ear Nose Throat",
               "description": "Test",
               "categoryImage": "https://deiry8u6tztoz.cloudfront.net/images/jCKd1655719659130-xdDm1655719659082Banner.jpg",
               "categoryIcon": "https://deiry8u6tztoz.cloudfront.net/images/mFfG1655727735098-jUiA1655727735055newearnosethroatentconceptlinevectoriconeditablestrokeflatlinearillustrationisolatedwhitebackground98780188.jpg",
               "isDeleted": 0,
               "parentCategoryId": -1,
               "adminId": 1,
               "isEnabled": 1,
               "isHomeService": 0,
               "costPrice": 900,
               "commissionFee": 9,
               "flowType": 1,
               "inspectionRate": null,
               "cityId": 91,
               "type": 1,
               "totalRatedCount": 0,
               "totalRating": 0,
               "includeVisitCharge": 0,
               "isAddOn": 0,
               "servicesIncluded": "[\"Chronic ear infections\", \"Hearing loss\", \"Dizziness or vertigo\", \"Ringing in the ears\", \"Chronic stuffy nose\", \"Chronic sore throat or tonsillitis\", \"Lumps in the neck/enlarged lymph nodes\", \"Sleep apnea\"]",
               "isAvailable": 1,
               "isPopular": 1,
               "paymentMethodIds": "[\"1\", \"4\", \"7\", \"8\"]",
               "howItWorkData": "[{\"id\": \"170\", \"title\": \"Consult with an ENT Doctor ENT doctors are experts in surgical and medical management of your ears, nose, throat, head and neck conditions \", \"categoryId\": \"502\", \"description\": \"\"}, {\"id\": \"171\", \"title\": \"Your consultations are private, safe and secure\", \"categoryId\": \"502\", \"description\": \"\"}, {\"id\": \"172\", \"title\": \"5000+ online consultations completed in last 24 hours  \", \"categoryId\": \"502\", \"description\": \"\"}, {\"id\": \"173\", \"title\": \"lick on 'Consult with an ENT Doctor' Start by clicking on the ‘Consult with an ENT Doctor’ button above\", \"categoryId\": \"502\", \"description\": \"\"}, {\"id\": \"174\", \"title\": \"Complete payment Pay and get connected with top doctors in your preferred specialty\", \"categoryId\": \"502\", \"description\": \"\"}, {\"id\": \"175\", \"title\": \"Start your doctor consultation Connect with a top doctor in 60 seconds for consultation, audio or video call.\", \"categoryId\": \"502\", \"description\": \"\"}]",
               "providerQuestions": "[{\"cityId\": \"91.00\", \"quesRank\": 0, \"question\": \"Where does it pain?\", \"categoryId\": \"502\", \"entityType\": \"0\", \"isRequired\": \"1\", \"questionId\": \"186\", \"inputOptions\": \"Ears, Nose, Throat\", \"questionType\": \"9\"}]",
               "customerQuestions": "[{\"cityId\": \"91.00\", \"quesRank\": 0, \"question\": \"Where does it pain?\", \"categoryId\": \"502\", \"entityType\": \"1\", \"isRequired\": \"1\", \"questionId\": \"186\", \"inputOptions\": \"Ears, Nose, Throat\", \"questionType\": \"9\"}]",
               "isCustomizable": 1
           },
           {
               "categoryId": 505,
               "categoryName": "Eyes Specialist",
               "description": "Find a doctor tool assists you in choosing from our diverse pool of health specialists. Discover better health & wellness by using our doctor ratings & reviews to make your choice.",
               "categoryImage": "https://deiry8u6tztoz.cloudfront.net/images/6CMX1655959268415-a6nP1655959268367images7.jpg",
               "categoryIcon": "https://deiry8u6tztoz.cloudfront.net/images/Ipo41655988880344-ObVs1655988880255—Pngtree—eyecolorgeometric4000833.png",
               "isDeleted": 0,
               "parentCategoryId": -1,
               "adminId": 1,
               "isEnabled": 1,
               "isHomeService": 0,
               "costPrice": 900,
               "commissionFee": 10,
               "flowType": 1,
               "inspectionRate": null,
               "cityId": 91,
               "type": 1,
               "totalRatedCount": 0,
               "totalRating": 0,
               "includeVisitCharge": 0,
               "isAddOn": 0,
               "servicesIncluded": "[]",
               "isAvailable": 1,
               "isPopular": 1,
               "paymentMethodIds": "[\"1\", \"4\", \"7\", \"8\"]",
               "howItWorkData": "[{\"id\": \"204\", \"title\": \"Eye Pain\", \"categoryId\": \"505\", \"description\": \"\"}, {\"id\": \"205\", \"title\": \"Eye Fatigue\", \"categoryId\": \"505\", \"description\": \"\"}, {\"id\": \"206\", \"title\": \"Eye Infection\", \"categoryId\": \"505\", \"description\": \"\"}, {\"id\": \"207\", \"title\": \"Blurry Vision or Problems Focusing\", \"categoryId\": \"505\", \"description\": \"\"}, {\"id\": \"208\", \"title\": \"Dry or Itchy Eyes\", \"categoryId\": \"505\", \"description\": \"\"}, {\"id\": \"209\", \"title\": \"Flashes, Floaters, & Spots\", \"categoryId\": \"505\", \"description\": \"\"}, {\"id\": \"210\", \"title\": \"Double Vision\", \"categoryId\": \"505\", \"description\": \"\"}]",
               "providerQuestions": "[]",
               "customerQuestions": "[]",
               "isCustomizable": 0
           },
           {
               "categoryId": 506,
               "categoryName": "Hair Specialist",
               "description": "test",
               "categoryImage": "https://deiry8u6tztoz.cloudfront.net/images/lHuA1655989754455-tdQl1655989754393HairSpecial2.jpg",
               "categoryIcon": "https://deiry8u6tztoz.cloudfront.net/images/8dzF1656231814423-HNII1656231814340pngtransparentbeautyparlourcosmeticsfoodsamosaothersmiscellaneouscreamfacethumbnailremovebgpreview.png",
               "isDeleted": 0,
               "parentCategoryId": -1,
               "adminId": 1,
               "isEnabled": 1,
               "isHomeService": 0,
               "costPrice": 900,
               "commissionFee": 8,
               "flowType": 1,
               "inspectionRate": null,
               "cityId": 91,
               "type": 1,
               "totalRatedCount": 0,
               "totalRating": 0,
               "includeVisitCharge": 0,
               "isAddOn": 0,
               "servicesIncluded": "[]",
               "isAvailable": 1,
               "isPopular": 1,
               "paymentMethodIds": "[\"1\", \"4\", \"7\", \"8\"]",
               "howItWorkData": "[]",
               "providerQuestions": "[]",
               "customerQuestions": "[]",
               "isCustomizable": 0
           },
           {
               "categoryId": 507,
               "categoryName": "Sexologist",
               "description": "Test",
               "categoryImage": "https://deiry8u6tztoz.cloudfront.net/images/2e7T1655989890232-6OVu1655989890157Sexologiest2.jpg",
               "categoryIcon": "https://deiry8u6tztoz.cloudfront.net/images/qNpg1655989810206-uE231655989810151sexology.png",
               "isDeleted": 0,
               "parentCategoryId": -1,
               "adminId": 1,
               "isEnabled": 1,
               "isHomeService": 0,
               "costPrice": 900,
               "commissionFee": 9,
               "flowType": 1,
               "inspectionRate": null,
               "cityId": 91,
               "type": 1,
               "totalRatedCount": 0,
               "totalRating": 0,
               "includeVisitCharge": 0,
               "isAddOn": 0,
               "servicesIncluded": "[]",
               "isAvailable": 1,
               "isPopular": 1,
               "paymentMethodIds": "[\"1\", \"4\", \"7\", \"8\"]",
               "howItWorkData": "[]",
               "providerQuestions": "[]",
               "customerQuestions": "[]",
               "isCustomizable": 0
           },
           {
               "categoryId": 515,
               "categoryName": "Child Care",
               "description": "Take care of your chil",
               "categoryImage": "https://deiry8u6tztoz.cloudfront.net/images/JweZ1656325522177-fKkc1656325522124mediniebuy.jpg",
               "categoryIcon": "https://deiry8u6tztoz.cloudfront.net/images/SMdB1656325508916-xUKm1656325508824Gynaremovebgpreview.png",
               "isDeleted": 0,
               "parentCategoryId": -1,
               "adminId": 1,
               "isEnabled": 1,
               "isHomeService": 0,
               "costPrice": 900,
               "commissionFee": 9,
               "flowType": 1,
               "inspectionRate": null,
               "cityId": 91,
               "type": 1,
               "totalRatedCount": 0,
               "totalRating": 0,
               "includeVisitCharge": 0,
               "isAddOn": 0,
               "servicesIncluded": "[]",
               "isAvailable": 1,
               "isPopular": 1,
               "paymentMethodIds": "[\"1\", \"4\", \"7\", \"8\"]",
               "howItWorkData": "[]",
               "providerQuestions": "[]",
               "customerQuestions": "[]",
               "isCustomizable": 0
           }
       ]
    

   )
}

const productData = () => {
    return (
        [
            {
                "productId": 188405,
                "priority": 1,
                "image": "https://d2563s26joau7f.cloudfront.net/images/eRjS1659965417223-loQb1659965417116mangoripewhitebackground35283521.jpg",
                "skuId": "1432",
                "name": "Alphanso Mangoes",
                "description": "Tasty and juicy mangoes",
                "manufacturer": null,
                "brand": null,
                "featureFlag": 0,
                "loyalityPoints": null,
                "isRedeemable": 0,
                "length": null,
                "breadth": null,
                "height": null,
                "weight": 500,
                "quantity": 0,
                "costPrice": 50,
                "barcode": "1542",
                "redemptionPoints": 0,
                "vat": 132547896,
                "margin": -66273948,
                "adminId": 1,
                "branchId": 388,
                "isDeleted": 0,
                "isEnabled": 1,
                "actualPrice": 50,
                "discountedPrice": 0,
                "createdAt": "2022-08-08T13:32:52.000Z",
                "updatedAt": "2022-08-08T13:32:52.000Z",
                "brandId": 55017,
                "linkedProductIds": "[\"188353,188406\"]",
                "thumbnail": "",
                "tags": "rice,      testing tag,     testing tag, cookies, testing, fruits,   vegetables,   chocolate ,    cookies, mangoes,    fruits",
                "purchasedUnit": null,
                "sellingUnit": null,
                "baseValue": null,
                "purchasedStock": null,
                "gst": null,
                "extraStock": 0,
                "overAllCostPrice": 0,
                "unitValue": "50",
                "createdBy": null,
                "masterStoreId": null,
                "isAddOn": 0,
                "foodType": null,
                "eposProductId": 10049455,
                "currentStock": 0,
                "categoryName": "Dal Varieties",
                "subCategoryName": "Dal Varieties",
                "isFavourite": 0,
                "brandName": "Fruits and vegetables",
                "categoryIds": "459313",
                "subCategoryIds": "459317"
            },
            {
                "productId": 188317,
                "priority": 1,
                "image": "https://d2563s26joau7f.cloudfront.net/images/ToS91659685478290-gpCA16596854782437914product",
                "skuId": "12313",
                "name": "Masoor Dal",
                "description": "Masoor Dal",
                "manufacturer": "",
                "brand": null,
                "featureFlag": 1,
                "loyalityPoints": 0,
                "isRedeemable": 1,
                "length": 0,
                "breadth": 0,
                "height": 0,
                "weight": 0,
                "quantity": 50,
                "costPrice": 150,
                "barcode": "234234",
                "redemptionPoints": 100,
                "vat": 0,
                "margin": -70,
                "adminId": 1,
                "branchId": 388,
                "isDeleted": 0,
                "isEnabled": 1,
                "actualPrice": 0,
                "discountedPrice": 0,
                "createdAt": "2022-07-26T10:47:25.000Z",
                "updatedAt": "2022-07-26T10:47:25.000Z",
                "brandId": 160829,
                "linkedProductIds": "[\"\"]",
                "thumbnail": "",
                "tags": "  masoor,  dal",
                "purchasedUnit": null,
                "sellingUnit": null,
                "baseValue": null,
                "purchasedStock": null,
                "gst": null,
                "extraStock": 0,
                "overAllCostPrice": 0,
                "unitValue": "100",
                "createdBy": null,
                "masterStoreId": null,
                "isAddOn": 0,
                "foodType": null,
                "eposProductId": 9872682,
                "currentStock": 0,
                "categoryName": "Dal Varieties",
                "subCategoryName": "Dal Varieties",
                "isFavourite": 0,
                "brandName": "TRS",
                "categoryIds": "459313",
                "subCategoryIds": "459317"
            },
            {
                "productId": 188317,
                "priority": 1,
                "image": "https://d2563s26joau7f.cloudfront.net/images/ToS91659685478290-gpCA16596854782437914product",
                "skuId": "12313",
                "name": "Masoor Dal",
                "description": "Masoor Dal",
                "manufacturer": "",
                "brand": null,
                "featureFlag": 1,
                "loyalityPoints": 0,
                "isRedeemable": 1,
                "length": 0,
                "breadth": 0,
                "height": 0,
                "weight": 0,
                "quantity": 50,
                "costPrice": 150,
                "barcode": "234234",
                "redemptionPoints": 100,
                "vat": 0,
                "margin": -70,
                "adminId": 1,
                "branchId": 388,
                "isDeleted": 0,
                "isEnabled": 1,
                "actualPrice": 0,
                "discountedPrice": 0,
                "createdAt": "2022-07-26T10:47:25.000Z",
                "updatedAt": "2022-07-26T10:47:25.000Z",
                "brandId": 160829,
                "linkedProductIds": "[\"\"]",
                "thumbnail": "",
                "tags": "  masoor,  dal",
                "purchasedUnit": null,
                "sellingUnit": null,
                "baseValue": null,
                "purchasedStock": null,
                "gst": null,
                "extraStock": 0,
                "overAllCostPrice": 0,
                "unitValue": "100",
                "createdBy": null,
                "masterStoreId": null,
                "isAddOn": 0,
                "foodType": null,
                "eposProductId": 9872682,
                "currentStock": 0,
                "categoryName": "Dal Varieties",
                "subCategoryName": "Dal Varieties",
                "isFavourite": 0,
                "brandName": "TRS",
                "categoryIds": "459313",
                "subCategoryIds": "459317"
            },
            {
                "productId": 188406,
                "priority": 2,
                "image": "https://d2563s26joau7f.cloudfront.net/images/pdpf1659965596162-Qlp01659965596108apples.jpg",
                "skuId": "1532",
                "name": "Kashmir Apples",
                "description": "Tasty Apples",
                "manufacturer": null,
                "brand": null,
                "featureFlag": 0,
                "loyalityPoints": null,
                "isRedeemable": 0,
                "length": null,
                "breadth": null,
                "height": null,
                "weight": 500,
                "quantity": 0,
                "costPrice": 50,
                "barcode": "8973",
                "redemptionPoints": 0,
                "vat": 165451326510,
                "margin": -82725663255,
                "adminId": 1,
                "branchId": 388,
                "isDeleted": 0,
                "isEnabled": 1,
                "actualPrice": 50,
                "discountedPrice": 0,
                "createdAt": "2022-08-08T13:34:34.000Z",
                "updatedAt": "2022-08-08T13:34:34.000Z",
                "brandId": 55017,
                "linkedProductIds": "[\"\"]",
                "thumbnail": "",
                "tags": "fruits, apple",
                "purchasedUnit": null,
                "sellingUnit": null,
                "baseValue": null,
                "purchasedStock": null,
                "gst": null,
                "extraStock": 0,
                "overAllCostPrice": 0,
                "unitValue": "50",
                "createdBy": null,
                "masterStoreId": null,
                "isAddOn": 0,
                "foodType": null,
                "eposProductId": 10049472,
                "currentStock": 0,
                "categoryName": "Dal Varieties",
                "subCategoryName": "Dal Varieties",
                "isFavourite": 0,
                "brandName": "Fruits and vegetables",
                "categoryIds": "459313",
                "subCategoryIds": "459317"
            },
            {
                "productId": 188406,
                "priority": 2,
                "image": "https://d2563s26joau7f.cloudfront.net/images/pdpf1659965596162-Qlp01659965596108apples.jpg",
                "skuId": "1532",
                "name": "Kashmir Apples",
                "description": "Tasty Apples",
                "manufacturer": null,
                "brand": null,
                "featureFlag": 0,
                "loyalityPoints": null,
                "isRedeemable": 0,
                "length": null,
                "breadth": null,
                "height": null,
                "weight": 500,
                "quantity": 0,
                "costPrice": 50,
                "barcode": "8973",
                "redemptionPoints": 0,
                "vat": 165451326510,
                "margin": -82725663255,
                "adminId": 1,
                "branchId": 388,
                "isDeleted": 0,
                "isEnabled": 1,
                "actualPrice": 50,
                "discountedPrice": 0,
                "createdAt": "2022-08-08T13:34:34.000Z",
                "updatedAt": "2022-08-08T13:34:34.000Z",
                "brandId": 55017,
                "linkedProductIds": "[\"\"]",
                "thumbnail": "",
                "tags": "fruits, apple",
                "purchasedUnit": null,
                "sellingUnit": null,
                "baseValue": null,
                "purchasedStock": null,
                "gst": null,
                "extraStock": 0,
                "overAllCostPrice": 0,
                "unitValue": "50",
                "createdBy": null,
                "masterStoreId": null,
                "isAddOn": 0,
                "foodType": null,
                "eposProductId": 10049472,
                "currentStock": 0,
                "categoryName": "Dal Varieties",
                "subCategoryName": "Dal Varieties",
                "isFavourite": 0,
                "brandName": "Fruits and vegetables",
                "categoryIds": "459313",
                "subCategoryIds": "459317"
            },
            {
                "productId": 188406,
                "priority": 2,
                "image": "https://d2563s26joau7f.cloudfront.net/images/pdpf1659965596162-Qlp01659965596108apples.jpg",
                "skuId": "1532",
                "name": "Kashmir Apples",
                "description": "Tasty Apples",
                "manufacturer": null,
                "brand": null,
                "featureFlag": 0,
                "loyalityPoints": null,
                "isRedeemable": 0,
                "length": null,
                "breadth": null,
                "height": null,
                "weight": 500,
                "quantity": 0,
                "costPrice": 50,
                "barcode": "8973",
                "redemptionPoints": 0,
                "vat": 165451326510,
                "margin": -82725663255,
                "adminId": 1,
                "branchId": 388,
                "isDeleted": 0,
                "isEnabled": 1,
                "actualPrice": 50,
                "discountedPrice": 0,
                "createdAt": "2022-08-08T13:34:34.000Z",
                "updatedAt": "2022-08-08T13:34:34.000Z",
                "brandId": 55017,
                "linkedProductIds": "[\"\"]",
                "thumbnail": "",
                "tags": "fruits, apple",
                "purchasedUnit": null,
                "sellingUnit": null,
                "baseValue": null,
                "purchasedStock": null,
                "gst": null,
                "extraStock": 0,
                "overAllCostPrice": 0,
                "unitValue": "50",
                "createdBy": null,
                "masterStoreId": null,
                "isAddOn": 0,
                "foodType": null,
                "eposProductId": 10049472,
                "currentStock": 0,
                "categoryName": "Dal Varieties",
                "subCategoryName": "Dal Varieties",
                "isFavourite": 0,
                "brandName": "Fruits and vegetables",
                "categoryIds": "459313",
                "subCategoryIds": "459317"
            },
            {
                "productId": 188406,
                "priority": 2,
                "image": "https://d2563s26joau7f.cloudfront.net/images/pdpf1659965596162-Qlp01659965596108apples.jpg",
                "skuId": "1532",
                "name": "Kashmir Apples",
                "description": "Tasty Apples",
                "manufacturer": null,
                "brand": null,
                "featureFlag": 0,
                "loyalityPoints": null,
                "isRedeemable": 0,
                "length": null,
                "breadth": null,
                "height": null,
                "weight": 500,
                "quantity": 0,
                "costPrice": 50,
                "barcode": "8973",
                "redemptionPoints": 0,
                "vat": 165451326510,
                "margin": -82725663255,
                "adminId": 1,
                "branchId": 388,
                "isDeleted": 0,
                "isEnabled": 1,
                "actualPrice": 50,
                "discountedPrice": 0,
                "createdAt": "2022-08-08T13:34:34.000Z",
                "updatedAt": "2022-08-08T13:34:34.000Z",
                "brandId": 55017,
                "linkedProductIds": "[\"\"]",
                "thumbnail": "",
                "tags": "fruits, apple",
                "purchasedUnit": null,
                "sellingUnit": null,
                "baseValue": null,
                "purchasedStock": null,
                "gst": null,
                "extraStock": 0,
                "overAllCostPrice": 0,
                "unitValue": "50",
                "createdBy": null,
                "masterStoreId": null,
                "isAddOn": 0,
                "foodType": null,
                "eposProductId": 10049472,
                "currentStock": 0,
                "categoryName": "Dal Varieties",
                "subCategoryName": "Dal Varieties",
                "isFavourite": 0,
                "brandName": "Fruits and vegetables",
                "categoryIds": "459313",
                "subCategoryIds": "459317"
            },
        ]
    )
}

const cityData = () =>{
    return(
        [
            {
                "cityId": 96,
                "name": "Milpitas",
                "state": "CA",
                "country": "US",
                "currency": "Dollar",
                "currencyPosition": 0,
                "adminId": 1,
                "isDeleted": 0,
                "isEnabled": 1,
                "geoLongLat": [
                    [
                        {
                            "x": 31.405223877581317,
                            "y": -3.33984375
                        },
                        {
                            "x": 30.50075098029069,
                            "y": 31.46484375
                        },
                        {
                            "x": 19.699485994162497,
                            "y": 32.51953125
                        },
                        {
                            "x": 15.681220930466825,
                            "y": -11.42578125
                        },
                        {
                            "x": 31.405223877581317,
                            "y": -3.33984375
                        }
                    ]
                ],
                "Zip_Code": [{ "zipcode": "110094" }, { "zipcode": "110095" }],
                "paymentMethodIds": "[7]",
                "planIds": "[2]"
            },
            {
                "cityId": 95,
                "name": "Madhuri Kalahasti",
                "state": "California",
                "country": "United States",
                "currency": "dollar",
                "currencyPosition": 0,
                "adminId": 1,
                "isDeleted": 0,
                "isEnabled": 1,
                "Zip_Code": [{ "zipcode": "110094" }, { "zipcode": "110095" }],
                "geoLongLat": [
                    [
                        {
                            "x": 64.01202736734741,
                            "y": 37.75941879163965
                        },
                        {
                            "x": 63.50686201666326,
                            "y": 48.921528166639646
                        },
                        {
                            "x": 61.478069172836655,
                            "y": 49.097309416639646
                        },
                        {
                            "x": 61.18290888734497,
                            "y": 37.31996566663965
                        },
                        {
                            "x": 64.01202736734741,
                            "y": 37.75941879163965
                        }
                    ]
                ],
                "paymentMethodIds": "[7]",
                "planIds": "[13]"
            },
            {
                "Zip_Code": [{ "zipcode": "110094" }, { "zipcode": "110095" }],
                "cityId": 94,
                "name": "Dubai",
                "state": "Dubai",
                "country": "UAE",
                "currency": "Dinar",
                "currencyPosition": 0,
                "adminId": 1,
                "isDeleted": 0,
                "isEnabled": 1,
                "geoLongLat": [
                    [
                        {
                            "x": 25.146877668866733,
                            "y": 55.39228287062514
                        },
                        {
                            "x": 25.126985929219916,
                            "y": 56.11188736281264
                        },
                        {
                            "x": 24.533733179728,
                            "y": 56.07892837843764
                        },
                        {
                            "x": 24.543727230841355,
                            "y": 54.98029556593764
                        },
                        {
                            "x": 25.146877668866733,
                            "y": 55.39228287062514
                        }
                    ]
                ],
                "paymentMethodIds": "[1,4,7]",
                "planIds": "[2]"
            },
            {
                "Zip_Code": [{ "zipcode": "110094" }, { "zipcode": "110095" }],
                "cityId": 93,
                "name": "Noida",
                "state": "UP",
                "country": "Inda",
                "currency": "Rupee",
                "currencyPosition": 0,
                "adminId": 1,
                "isDeleted": 0,
                "isEnabled": 1,
                "geoLongLat": [
                    [
                        {
                            "x": 28.573469736689088,
                            "y": 77.33452710140443
                        },
                        {
                            "x": 28.583117537418847,
                            "y": 77.55562695492006
                        },
                        {
                            "x": 28.342866182240314,
                            "y": 77.52953442562318
                        },
                        {
                            "x": 28.3803281622437,
                            "y": 77.28234204281068
                        },
                        {
                            "x": 28.573469736689088,
                            "y": 77.33452710140443
                        }
                    ]
                ],
                "paymentMethodIds": "[1]",
                "planIds": "[11]"
            },
            {
                "Zip_Code": [{ "zipcode": "110094" }, { "zipcode": "110095" }],
                "cityId": 92,
                "name": "Indonesia",
                "state": "Indonesia",
                "country": "Indonesia",
                "currency": "$",
                "currencyPosition": 0,
                "adminId": 1,
                "isDeleted": 0,
                "isEnabled": 1,
                "geoLongLat": [
                    [
                        {
                            "x": -5.429244577560201,
                            "y": 116.3655402809908
                        },
                        {
                            "x": -5.735402506482072,
                            "y": 124.4514777809908
                        },
                        {
                            "x": 1.9450509684416122,
                            "y": 127.0442512184908
                        },
                        {
                            "x": 7.208013356429333,
                            "y": 117.9475715309908
                        },
                        {
                            "x": 6.073119685569742,
                            "y": 111.4436652809908
                        },
                        {
                            "x": 1.5140350552933792,
                            "y": 107.1370246559908
                        },
                        {
                            "x": -2.2644958099519252,
                            "y": 108.5432746559908
                        },
                        {
                            "x": -4.545466292651445,
                            "y": 111.6194465309908
                        },
                        {
                            "x": -5.429244577560201,
                            "y": 116.3655402809908
                        }
                    ]
                ],
                "paymentMethodIds": "[1,4,7,8]",
                "planIds": "[15]"
            },
            {
                "Zip_Code": [{ "zipcode": "110094" }, { "zipcode": "110095" }],
                "cityId": 91,
                "name": "Gurgaon",
                "state": "Delhi",
                "country": "India ",
                "currency": "₹",
                "currencyPosition": 0,
                "adminId": 1,
                "isDeleted": 0,
                "isEnabled": 0,
                "geoLongLat": [
                    [
                        {
                            "x": 28.382858407518867,
                            "y": 77.05181360807917
                        },
                        {
                            "x": 28.445062898871626,
                            "y": 77.0312142428448
                        },
                        {
                            "x": 28.501800137705093,
                            "y": 77.09301233854792
                        },
                        {
                            "x": 28.436006265737603,
                            "y": 77.17334986296198
                        },
                        {
                            "x": 28.382858407518867,
                            "y": 77.05181360807917
                        }
                    ]
                ],
                "paymentMethodIds": "[1,4,7,8]",
                "planIds": "[2,11,12,13,14]"
            },
            {
                "Zip_Code": [{ "zipcode": "110094" }, { "zipcode": "110095" }],
                "cityId": 90,
                "name": "India ",
                "state": "Uttar Pradesh",
                "country": "Norway",
                "currency": "INR",
                "currencyPosition": 0,
                "adminId": 1,
                "isDeleted": 0,
                "isEnabled": 0,
                "geoLongLat": [
                    [
                        {
                            "x": 26.92367701919138,
                            "y": 80.83127616999096
                        },
                        {
                            "x": 26.927350281409485,
                            "y": 81.08121513483471
                        },
                        {
                            "x": 26.742314484925796,
                            "y": 81.05924247858471
                        },
                        {
                            "x": 26.7079700213057,
                            "y": 80.78870414850658
                        },
                        {
                            "x": 26.92367701919138,
                            "y": 80.83127616999096
                        }
                    ]
                ],
                "paymentMethodIds": "[1,7]",
                "planIds": "[11]"
            },
            {
                "Zip_Code": [{ "zipcode": "110094" }, { "zipcode": "110095" }],
                "cityId": 89,
                "name": "Fiji Island",
                "state": "Australia",
                "country": "Australia",
                "currency": "Dollar",
                "currencyPosition": 0,
                "adminId": 1,
                "isDeleted": 0,
                "isEnabled": 1,
                "geoLongLat": [
                    [
                        {
                            "x": -17.023837524643923,
                            "y": 177.23095703125
                        },
                        {
                            "x": -17.097357642007214,
                            "y": 178.9228515625
                        },
                        {
                            "x": -18.363528132238187,
                            "y": 179.03271484375
                        },
                        {
                            "x": -18.75929156449337,
                            "y": 177.6484375
                        },
                        {
                            "x": -17.904140572045495,
                            "y": 176.604736328125
                        },
                        {
                            "x": -17.023837524643923,
                            "y": 177.23095703125
                        }
                    ]
                ],
                "paymentMethodIds": "[1,4,7]",
                "planIds": "[2,11]"
            },
            {
                "Zip_Code": [{ "zipcode": "110094" }, { "zipcode": "110095" }],
                "cityId": 88,
                "name": "India",
                "state": "India",
                "country": "India",
                "currency": "$",
                "currencyPosition": 0,
                "adminId": 1,
                "isDeleted": 0,
                "isEnabled": 1,
                "geoLongLat": [
                    [
                        {
                            "x": 45.13943008146791,
                            "y": 57.66448974609375
                        },
                        {
                            "x": 24.59208533779722,
                            "y": 57.66448974609375
                        },
                        {
                            "x": 2.882694178844882,
                            "y": 77.35198974609375
                        },
                        {
                            "x": 25.86416657624641,
                            "y": 95.63323974609375
                        },
                        {
                            "x": 40.50126945841641,
                            "y": 80.86761474609375
                        },
                        {
                            "x": 45.13943008146791,
                            "y": 57.66448974609375
                        }
                    ]
                ],
                "paymentMethodIds": "[1,4,7,8]",
                "planIds": "[2,11,12,13]"
            }
        ]
    )
}

const ReviewsData = () => {
    return (
        [
            {
                "reviewId": 1,
                "branchId": 388,
                "customerId": 830,
                "rating": 5,
                "totalRating": 3.82203,
                "review": "jhakkas katai",
                "adminId": 1,
                "reviewDate": "2021-11-19T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4708,
                "isReviewApproved": 0,
                "firstName": "Navya",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/nrmd1637036989907-myprofilepic",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 4,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 2,
                "branchId": 388,
                "customerId": 654,
                "rating": 5,
                "totalRating": 3.82203,
                "review": "good",
                "adminId": 1,
                "reviewDate": "2021-12-06T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4584,
                "isReviewApproved": 0,
                "firstName": "rob",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/LwHq1636608165300-myprofilepic",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 3,
                "branchId": 329,
                "customerId": 654,
                "rating": 4,
                "totalRating": 3.25,
                "review": "wahh!",
                "adminId": 1,
                "reviewDate": "2021-11-22T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4286,
                "isReviewApproved": 0,
                "firstName": "rob",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/LwHq1636608165300-myprofilepic",
                "branchName": "Tester Store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 4,
                "branchId": 383,
                "customerId": 654,
                "rating": 4,
                "totalRating": 4,
                "review": "good",
                "adminId": 1,
                "reviewDate": "2021-11-22T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4649,
                "isReviewApproved": 0,
                "firstName": "rob",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/LwHq1636608165300-myprofilepic",
                "branchName": "Kart Mart",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 5,
                "branchId": 388,
                "customerId": 908,
                "rating": 5,
                "totalRating": 3.82203,
                "review": "877",
                "adminId": 1,
                "reviewDate": "2021-12-01T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4840,
                "isReviewApproved": 0,
                "firstName": "Navya ",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/A3FP1638267195797-myprofilepic",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 6,
                "branchId": 388,
                "customerId": 871,
                "rating": 4,
                "totalRating": 3.82203,
                "review": "ucutc",
                "adminId": 1,
                "reviewDate": "2021-11-26T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4826,
                "isReviewApproved": 0,
                "firstName": "Shubham",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/Rxs51636451566851-myprofilepic",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 4,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 7,
                "branchId": 337,
                "customerId": 871,
                "rating": 2,
                "totalRating": 2,
                "review": "fff",
                "adminId": 1,
                "reviewDate": "2021-11-26T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4565,
                "isReviewApproved": 0,
                "firstName": "Shubham",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/Rxs51636451566851-myprofilepic",
                "branchName": "Sindhu Store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 8,
                "branchId": 388,
                "customerId": 957,
                "rating": 5,
                "totalRating": 3.82203,
                "review": "th",
                "adminId": 1,
                "reviewDate": "2022-01-04T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4930,
                "isReviewApproved": 0,
                "firstName": "Navya Reddy ",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/r9Qx1638424730914-myprofilepic",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 9,
                "branchId": 388,
                "customerId": 762,
                "rating": 5,
                "totalRating": 3.82203,
                "review": "Good",
                "adminId": 1,
                "reviewDate": "2021-12-23T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4940,
                "isReviewApproved": 0,
                "firstName": "sai ",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 10,
                "branchId": 388,
                "customerId": 960,
                "rating": 0,
                "totalRating": 3.82203,
                "review": "Test",
                "adminId": 1,
                "reviewDate": "2021-12-04T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 4963,
                "isReviewApproved": 0,
                "firstName": "Riya",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/gduP1638799895408-D6Bx1638799895329download5.jpg",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 11,
                "branchId": 388,
                "customerId": 977,
                "rating": 4,
                "totalRating": 3.82203,
                "review": "Test data ",
                "adminId": 1,
                "reviewDate": "2021-12-09T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5049,
                "isReviewApproved": 0,
                "firstName": "Gayatri",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/MQLd1639116206547-bbEU1639116206504download4.jpg",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 12,
                "branchId": 395,
                "customerId": 977,
                "rating": 5,
                "totalRating": 3.25,
                "review": "Thfjc",
                "adminId": 1,
                "reviewDate": "2021-12-09T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5050,
                "isReviewApproved": 0,
                "firstName": "Gayatri",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/MQLd1639116206547-bbEU1639116206504download4.jpg",
                "branchName": "Big Basket shop",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 13,
                "branchId": 395,
                "customerId": 984,
                "rating": 0,
                "totalRating": 3.25,
                "review": null,
                "adminId": 1,
                "reviewDate": "2021-12-10T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5060,
                "isReviewApproved": 0,
                "firstName": "Riya",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/lVvK1639405510044-tKL61639405509966download10.jpg",
                "branchName": "Big Basket shop",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 14,
                "branchId": 388,
                "customerId": 984,
                "rating": 5,
                "totalRating": 3.82203,
                "review": null,
                "adminId": 1,
                "reviewDate": "2021-12-12T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5063,
                "isReviewApproved": 0,
                "firstName": "Riya",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/lVvK1639405510044-tKL61639405509966download10.jpg",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 15,
                "branchId": 388,
                "customerId": 986,
                "rating": 5,
                "totalRating": 3.82203,
                "review": "1",
                "adminId": 1,
                "reviewDate": "2021-12-13T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5083,
                "isReviewApproved": 1,
                "firstName": "Navya Yaganti",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 16,
                "branchId": 388,
                "customerId": 987,
                "rating": 4,
                "totalRating": 3.82203,
                "review": null,
                "adminId": 1,
                "reviewDate": "2021-12-13T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5085,
                "isReviewApproved": 0,
                "firstName": "Gayatri",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/C2YY1639390549571-image.jpeg",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 17,
                "branchId": 401,
                "customerId": 837,
                "rating": 2.5,
                "totalRating": 3.5,
                "review": "3333",
                "adminId": 1,
                "reviewDate": "2021-12-13T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5097,
                "isReviewApproved": 0,
                "firstName": "undefined",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/fhgf1638722155470-ms1Q1638722155420Screenshot20211122at8.49.04AM.png",
                "branchName": "very large data",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 18,
                "branchId": 388,
                "customerId": 995,
                "rating": 4,
                "totalRating": 3.82203,
                "review": "test",
                "adminId": 1,
                "reviewDate": "2021-12-15T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5105,
                "isReviewApproved": 0,
                "firstName": "Gayatri ",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 19,
                "branchId": 388,
                "customerId": 837,
                "rating": 2.5,
                "totalRating": 3.82203,
                "review": "uiyftdrs",
                "adminId": 1,
                "reviewDate": "2021-12-15T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5147,
                "isReviewApproved": 0,
                "firstName": "undefined",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/fhgf1638722155470-ms1Q1638722155420Screenshot20211122at8.49.04AM.png",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 20,
                "branchId": 400,
                "customerId": 957,
                "rating": 5,
                "totalRating": 4.5,
                "review": "13",
                "adminId": 1,
                "reviewDate": "2021-12-17T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5213,
                "isReviewApproved": 0,
                "firstName": "Navya Reddy ",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/r9Qx1638424730914-myprofilepic",
                "branchName": "Zet Basket",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 21,
                "branchId": 388,
                "customerId": 1004,
                "rating": 5,
                "totalRating": 3.82203,
                "review": null,
                "adminId": 1,
                "reviewDate": "2021-12-22T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5285,
                "isReviewApproved": 0,
                "firstName": "Nisha",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 4,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 22,
                "branchId": 388,
                "customerId": 1024,
                "rating": 5,
                "totalRating": 3.82203,
                "review": "Test",
                "adminId": 1,
                "reviewDate": "2021-12-27T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5439,
                "isReviewApproved": 0,
                "firstName": "nidhi ",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/PbfX1640266317274-image.jpeg",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 23,
                "branchId": 388,
                "customerId": 1034,
                "rating": 5,
                "totalRating": 3.82203,
                "review": null,
                "adminId": 1,
                "reviewDate": "2022-01-10T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5588,
                "isReviewApproved": 0,
                "firstName": "Gayatri ",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 24,
                "branchId": 388,
                "customerId": 1114,
                "rating": 3.5,
                "totalRating": 3.82203,
                "review": "dytfuygyufv",
                "adminId": 1,
                "reviewDate": "2022-04-26T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5801,
                "isReviewApproved": 0,
                "firstName": "Shubham Mukherjee",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 25,
                "branchId": 388,
                "customerId": 1066,
                "rating": 3,
                "totalRating": 3.82203,
                "review": "best",
                "adminId": 1,
                "reviewDate": "2022-04-26T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5802,
                "isReviewApproved": 0,
                "firstName": "Chaitanya",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 26,
                "branchId": 388,
                "customerId": 1173,
                "rating": 3.5,
                "totalRating": 3.82203,
                "review": null,
                "adminId": 1,
                "reviewDate": "2022-06-16T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5925,
                "isReviewApproved": 0,
                "firstName": "rohan",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 27,
                "branchId": 388,
                "customerId": 1183,
                "rating": 5,
                "totalRating": 3.82203,
                "review": "good",
                "adminId": 1,
                "reviewDate": "2022-07-05T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5953,
                "isReviewApproved": 0,
                "firstName": "Shaurya Verma ",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 28,
                "branchId": 388,
                "customerId": 1178,
                "rating": 4,
                "totalRating": 3.82203,
                "review": "excellent",
                "adminId": 1,
                "reviewDate": "2022-07-19T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5973,
                "isReviewApproved": 0,
                "firstName": "shubham",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/FxCr1657106235277-myprofilepic",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 29,
                "branchId": 400,
                "customerId": 1178,
                "rating": 4,
                "totalRating": 4.5,
                "review": "excellent",
                "adminId": 1,
                "reviewDate": "2022-07-19T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5975,
                "isReviewApproved": 0,
                "firstName": "shubham",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/FxCr1657106235277-myprofilepic",
                "branchName": "Zet Basket",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 30,
                "branchId": 388,
                "customerId": 1190,
                "rating": 0,
                "totalRating": 3.82203,
                "review": null,
                "adminId": 1,
                "reviewDate": "2022-07-22T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5984,
                "isReviewApproved": 0,
                "firstName": "sai",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 31,
                "branchId": 388,
                "customerId": 969,
                "rating": 5,
                "totalRating": 3.84167,
                "review": "All products are good ????",
                "adminId": 1,
                "reviewDate": "2022-08-02T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 5998,
                "isReviewApproved": 1,
                "firstName": "Mallika",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/7AbV1658487208391-myprofilepic",
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 7,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 32,
                "branchId": 388,
                "customerId": 1106,
                "rating": 5,
                "totalRating": 3.82203,
                "review": null,
                "adminId": 1,
                "reviewDate": "2022-07-26T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 6012,
                "isReviewApproved": 1,
                "firstName": "Arthi",
                "profilePicURL": null,
                "branchName": "Navya Organic store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            },
            {
                "reviewId": 33,
                "branchId": 425,
                "customerId": 1225,
                "rating": 5,
                "totalRating": 5,
                "review": "nine",
                "adminId": 1,
                "reviewDate": "2022-10-11T00:00:00.000Z",
                "createdBy": 0,
                "bookingId": 6172,
                "isReviewApproved": 1,
                "firstName": "Supravat",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/Mb1H1669701103666-kiB51669701103567papubg.png",
                "branchName": "Test Supra Store",
                "modeOfPaymentStatus": 1,
                "isPickupService": 0,
                "isDeliveryService": 1
            }
        ]
    )
}
const DashboardData = () => {
    return (
        {
            "androidCustomers": 65,
            "iosCustomers": 8,
            "webCustomers": 67,
            "totalCustomer": 140,
            "onlineCustomers": 117,
            "totalPicker": 36,
            "totalStores": 45,
            "activeStores": 41,
            "totalDrivers": 131,
            "todayOrders": 0,
            "overAllTotalOrders": 2435,
            "overAllFailedOrders": 560,
            "totalOnlineCustomers": 0,
            "yearlySales": [
                {
                    "year": 2014,
                    "sales": 0
                },
                {
                    "year": 2015,
                    "sales": 0
                },
                {
                    "year": 2016,
                    "sales": 0
                },
                {
                    "year": 2017,
                    "sales": 0
                },
                {
                    "year": 2018,
                    "sales": 0
                },
                {
                    "year": 2019,
                    "sales": 0
                },
                {
                    "year": 2020,
                    "sales": 0
                },
                {
                    "year": 2021,
                    "sales": 30091394567.79
                }
            ],
            "hourlySales": [
                {
                    "hour": 0,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 1,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 2,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 3,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 4,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 5,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 6,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 7,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 8,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 9,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 10,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 11,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 12,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 13,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 14,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 15,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 16,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 17,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 18,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 19,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 20,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 21,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 22,
                    "todaySales": 0,
                    "yesterDaySales": 0
                },
                {
                    "hour": 23,
                    "todaySales": 0,
                    "yesterDaySales": 0
                }
            ],
            "todayCompletedOrders": 0
        }
    )
}
const ServiceProviderData = () => {
    return (
        [
            {
                "superCatId": 146,
                "categoryName": "Navya",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/x9KG1633942932702-CDKF1633942932583backgroundbitterbreakfastbright161559.jpeg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/x9KG1633942932702-CDKF1633942932583backgroundbitterbreakfastbright161559.jpeg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 162,
                "categoryName": "Test Lakshmi",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/Z9Ez1663166710834-Em5d1663166710681401791031heineken00nonalcoholicbeerzerodotzero.webp",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/Z9Ez1663166710834-Em5d1663166710681401791031heineken00nonalcoholicbeerzerodotzero.webp",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 159,
                "categoryName": "Lentils",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/TBbu1662377734467-tbW91662377734386lentils.PNG",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/TBbu1662377734467-tbW91662377734386lentils.PNG",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 158,
                "categoryName": "Fruits & Veges",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/ucPD1660648224296-fvaR1660648224166offsessinalfruitsandvegetables.jpg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/ucPD1660648224296-fvaR1660648224166offsessinalfruitsandvegetables.jpg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 157,
                "categoryName": "Rasalu",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/Ifeu1660031601453-NbxM1660031601355mangoripewhitebackground35283521.jpg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/Ifeu1660031601453-NbxM1660031601355mangoripewhitebackground35283521.jpg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 156,
                "categoryName": "big basket",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/1GzD1659965138919-gLld1659965138858thumbsup40075731280.webp",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/1GzD1659965138919-gLld1659965138858thumbsup40075731280.webp",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 155,
                "categoryName": "test-name",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/WKA91659782007111-6wY71659782007022add3.jpg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/WKA91659782007111-6wY71659782007022add3.jpg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 153,
                "categoryName": "more supermarket",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/AfRe1643950181082-upDg1643950181038more.jpg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/AfRe1643950181082-upDg1643950181038more.jpg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 151,
                "categoryName": "Supermarkets",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/7DBD1637421791914-h0ru16374217918415675676531shoppingcartfullofpostcerealboxesshoppingremovebgpreview1.png",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/7DBD1637421791914-h0ru16374217918415675676531shoppingcartfullofpostcerealboxesshoppingremovebgpreview1.png",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 145,
                "categoryName": "Organic store",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/F6Yd1636445423394-lqm21636445423332JysX1629201568783XV6n1629201568719Supermarketremovebgpreview.png",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/F6Yd1636445423394-lqm21636445423332JysX1629201568783XV6n1629201568719Supermarketremovebgpreview.png",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 144,
                "categoryName": "demo",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/ykMf1632475335144-uifo1632475335107o13vffkurigjlahnbtwp.jpg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/ykMf1632475335144-uifo1632475335107o13vffkurigjlahnbtwp.jpg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 142,
                "categoryName": "test2",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/kzAt1631097632515-jHdW1631097632482th31.jpg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/kzAt1631097632515-jHdW1631097632482th31.jpg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 141,
                "categoryName": "SuperMart",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/0E7i1655986195067-CMoa1655986195005Screenshot20220623173920removebgpreview.png",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/0E7i1655986195067-CMoa1655986195005Screenshot20220623173920removebgpreview.png",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 137,
                "categoryName": "super1",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/RBVy1629717260151-5Xhe1629717260087dragon.jpg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/RBVy1629717260151-5Xhe1629717260087dragon.jpg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 134,
                "categoryName": "rice and dal",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/EdZo1626514910736-SW6w1626514910673Capture.PNG",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/EdZo1626514910736-SW6w1626514910673Capture.PNG",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 132,
                "categoryName": "Continental",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/HgDB1626470633222-6l2B1626470633101download.png",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/HgDB1626470633222-6l2B1626470633101download.png",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 131,
                "categoryName": "divya store4",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/DoxC1625592288523-tNAs1625592288483groceries.jpg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/DoxC1625592288523-tNAs1625592288483groceries.jpg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 129,
                "categoryName": "Butcher Shops",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/SO7G1639672887868-dZXE1639672887790imageremovebgpreview.png",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/SO7G1639672887868-dZXE1639672887790imageremovebgpreview.png",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 1,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 127,
                "categoryName": "divya2",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/EvfV1625592272757-i3DF1625592272709groceries.jpg",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/EvfV1625592272757-i3DF1625592272709groceries.jpg",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 2,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            },
            {
                "superCatId": 133,
                "categoryName": "Grocery",
                "categoryImage": "https://d2563s26joau7f.cloudfront.net/images/WLqa1629201616810-343v1629201616760groceryremovebgpreview.png",
                "categoryIcon": "https://d2563s26joau7f.cloudfront.net/images/WLqa1629201616810-343v1629201616760groceryremovebgpreview.png",
                "isDeleted": 0,
                "isEnabled": 1,
                "priority": 2,
                "adminId": 1,
                "createdByBranch": null,
                "type": 0,
                "createdBy": null
            }
        ]
    )
}

const questionaryData = () =>{
    return(
        [
            {
                "questionId": 186,
                "question": "Where does it pain?",
                "questionType": 0,
                "tag": "serviceprovider,eyes nose throat,eyes nose throat,eyes nose throat,eyes nose throat,ear specialty ,ear nose throat,ear nose throat,online consulation ,ear specialty ,home visit,home visit,online consulation ,ear nose throat,ear nose throat,serviceprovider,eyes nose throat,ear specialty,ear nose throat,online consulation,home visit",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": "Ears, Nose, Throat",
                "createdAt": "2022-06-20 11:39:51",
                "containsTag": 1
            },
            {
                "questionId": 185,
                "question": "Where are we picking from?",
                "questionType": 0,
                "tag": "store delivery",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": null,
                "createdAt": "2022-06-15 00:37:07",
                "containsTag": 1
            },
            {
                "questionId": 184,
                "question": "Upload Image",
                "questionType": 8,
                "tag": "cleaning,cleaning service,cleaning,cleaning service,carpet cleaning,packers & movers,packers & movers,packers & movers,furniture ,furniture ,carpet cleaning,carpet cleaning,carpet cleaning,carpet cleaning,carpet cleaning,carpet cleaning,carpet cleaning,carpet cleaning,carpet cleaning,carpet cleaning,carpet cleaning,packers & movers,packers & movers,furniture ,furniture ,furniture ,carpet cleaning,cleaning,cleaning service,carpet cleaning,packers & movers,furniture,carpet cleaning,furniture repair,move a few items",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": null,
                "createdAt": "2022-04-01 09:01:40",
                "containsTag": 1
            },
            {
                "questionId": 183,
                "question": "Test Questionnaire",
                "questionType": 2,
                "tag": "serviceprovider,customer,serviceprovider",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": null,
                "createdAt": "2021-11-30 09:57:20",
                "containsTag": 1
            },
            {
                "questionId": 182,
                "question": "PV-Anlage",
                "questionType": 9,
                "tag": "customer,solaranlagen-installation,solaranlagen-installation,solaranlagen-installation,solaranlagen installation",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": "Mit Speicher,Ohne Speicher,Mit Autoladegerät",
                "createdAt": "2021-09-28 16:55:00",
                "containsTag": 1
            },
            {
                "questionId": 181,
                "question": "Wie viele Personen leben in Ihrem Haushalt?",
                "questionType": 1,
                "tag": "customer,solaranlagen-installation,solaranlagen-installation,solaranlagen-installation,solaranlagen installation",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": null,
                "createdAt": "2021-09-28 16:49:27",
                "containsTag": 1
            },
            {
                "questionId": 180,
                "question": "Bitte schätzen Sie Ihrem jährlichen Stromverbrauch",
                "questionType": 10,
                "tag": "customer,solaranlagen-installation,solaranlagen-installation,solaranlagen-installation,solaranlagen installation",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": "Unter 3000 kWh,3000-4000 kWh,4000-5000 kWh,Über 5000 kWh",
                "createdAt": "2021-09-28 16:48:47",
                "containsTag": 1
            },
            {
                "questionId": 179,
                "question": "Wählen Sie die vorhandene Dachform aus",
                "questionType": 10,
                "tag": "customer,customer,solaranlagen-installation,solaranlagen-installation,solaranlagen-installation,solaranlagen installation",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": "Sattledach,Flachdach,Pultdach,Dach mit Gaube,Andere",
                "createdAt": "2021-09-28 16:46:35",
                "containsTag": 1
            },
            {
                "questionId": 178,
                "question": "Bitte erläutern Sie die Art der Tätigkeit, die durchgeführt werden soll",
                "questionType": 0,
                "tag": "customer,allgemeine handwerkstätigkeiten,gardening,renovate",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": null,
                "createdAt": "2021-09-28 16:44:15",
                "containsTag": 1
            },
            {
                "questionId": 177,
                "question": "Welche Art von Klempnerarbeiten sollen durchgeführt werden?",
                "questionType": 0,
                "tag": "customer,klempnerarbeiten",
                "isDeleted": 0,
                "isDisabled": 0,
                "adminId": 1,
                "inputOptions": null,
                "createdAt": "2021-09-28 16:36:53",
                "containsTag": 1
            }
        ]
    )
}

const PushNotificationDataCities = () => {
    return (
        [
            {
                "geofenceId": 270,
                "geoLongLat": [
                    [
                        {
                            "x": -3.5737618803977966,
                            "y": 58.956786212778205
                        },
                        {
                            "x": 2.2270193696022034,
                            "y": 52.32994309181647
                        },
                        {
                            "x": 2.3149099946022034,
                            "y": 51.5169214702634
                        },
                        {
                            "x": -1.6401681303977966,
                            "y": 49.84645789504688
                        },
                        {
                            "x": -8.847199380397797,
                            "y": 49.21900538335119
                        },
                        {
                            "x": -4.628449380397797,
                            "y": 52.54426627679251
                        },
                        {
                            "x": -8.056183755397797,
                            "y": 57.7580880496739
                        },
                        {
                            "x": -3.5737618803977966,
                            "y": 58.956786212778205
                        }
                    ]
                ],
                "name": "uk",
                "tags": "uk area",
                "branchId": 400,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-10-14T14:25:14.000Z",
                "updatedAt": "2022-10-14T14:25:14.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Zet Basket"
            },
            {
                "geofenceId": 269,
                "geoLongLat": [
                    [
                        {
                            "x": -2.999365173920725,
                            "y": 53.01261228769365
                        },
                        {
                            "x": -2.746679627045725,
                            "y": 53.08608374382585
                        },
                        {
                            "x": -2.378637634858225,
                            "y": 52.77067947222129
                        },
                        {
                            "x": -1.609594666108225,
                            "y": 52.84539178856789
                        },
                        {
                            "x": -0.618078552826975,
                            "y": 53.091857085459104
                        },
                        {
                            "x": -0.332434021576975,
                            "y": 54.05565762900354
                        },
                        {
                            "x": -1.6267608038035375,
                            "y": 54.46913769059625
                        },
                        {
                            "x": -2.8881286016551,
                            "y": 54.23503384343411
                        },
                        {
                            "x": -3.197119080170725,
                            "y": 53.338558759729516
                        },
                        {
                            "x": -3.142187439545725,
                            "y": 53.141311106218744
                        },
                        {
                            "x": -2.999365173920725,
                            "y": 53.01261228769365
                        }
                    ]
                ],
                "name": "Manchester, UK",
                "tags": "Manchester",
                "branchId": 432,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-30T07:22:05.000Z",
                "updatedAt": "2022-09-30T07:22:05.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Indu's store"
            },
            {
                "geofenceId": 268,
                "geoLongLat": [
                    [
                        {
                            "x": -2.060558104512451,
                            "y": 52.36775271826361
                        },
                        {
                            "x": 2.070301270487569,
                            "y": 52.424735225381376
                        },
                        {
                            "x": 1.834095215800069,
                            "y": 51.47679997488761
                        },
                        {
                            "x": -1.313487792012451,
                            "y": 51.332878295377824
                        },
                        {
                            "x": -2.060558104512451,
                            "y": 52.36775271826361
                        }
                    ]
                ],
                "name": "UK ",
                "tags": "Test",
                "branchId": 427,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-20T11:47:35.000Z",
                "updatedAt": "2022-09-20T11:47:35.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Bazaar Food"
            },
            {
                "geofenceId": 267,
                "geoLongLat": [
                    [
                        {
                            "x": 6.660631778330357,
                            "y": 57.19249946786045
                        },
                        {
                            "x": 7.715319278330357,
                            "y": 10.935196299204733
                        },
                        {
                            "x": 66.9992828800724,
                            "y": 54.758451143214714
                        },
                        {
                            "x": 6.660631778330357,
                            "y": 57.19249946786045
                        }
                    ]
                ],
                "name": "birmingham",
                "tags": "birmingham",
                "branchId": 430,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-20T11:18:31.000Z",
                "updatedAt": "2022-09-20T11:18:31.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Sadda Store"
            },
            {
                "geofenceId": 266,
                "geoLongLat": [
                    [
                        {
                            "x": -2.1410291630893807,
                            "y": 52.540969589552994
                        },
                        {
                            "x": -1.7990797001987557,
                            "y": 52.520919556701735
                        },
                        {
                            "x": -1.8265455205112557,
                            "y": 52.43811638209942
                        },
                        {
                            "x": -2.1506422001987557,
                            "y": 52.537628553095026
                        },
                        {
                            "x": -2.1410291630893807,
                            "y": 52.540969589552994
                        }
                    ]
                ],
                "name": "Birmingham",
                "tags": "Birmingham",
                "branchId": 428,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-20T10:54:25.000Z",
                "updatedAt": "2022-09-20T10:54:25.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Lakshmi Stores"
            },
            {
                "geofenceId": 265,
                "geoLongLat": [
                    [
                        {
                            "x": -0.7760448050841706,
                            "y": 52.04429382626388
                        },
                        {
                            "x": -0.739480931793155,
                            "y": 52.042604503287905
                        },
                        {
                            "x": -0.7393092704162019,
                            "y": 52.02127130862333
                        },
                        {
                            "x": -0.780679662261905,
                            "y": 52.02317269945801
                        },
                        {
                            "x": -0.7760448050841706,
                            "y": 52.04429382626388
                        }
                    ]
                ],
                "name": "Milton Keyes",
                "tags": "Milton Keyes",
                "branchId": 427,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-20T10:52:56.000Z",
                "updatedAt": "2022-09-20T10:52:56.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Bazaar Food"
            },
            {
                "geofenceId": 264,
                "geoLongLat": [
                    [
                        {
                            "x": -0.8180236399176266,
                            "y": 52.21895685731052
                        },
                        {
                            "x": 0.5772400319573734,
                            "y": 52.239144443846286
                        },
                        {
                            "x": 0.4893494069573734,
                            "y": 51.78439966540987
                        },
                        {
                            "x": -0.8180236399176266,
                            "y": 52.21895685731052
                        }
                    ]
                ],
                "name": "UK",
                "tags": "Uk",
                "branchId": 430,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-12T11:54:44.000Z",
                "updatedAt": "2022-09-12T11:54:44.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Sadda Store"
            },
            {
                "geofenceId": 263,
                "geoLongLat": [
                    [
                        {
                            "x": -1.9280425666479672,
                            "y": 52.47888162888222
                        },
                        {
                            "x": -1.9160262702612485,
                            "y": 52.4889173815409
                        },
                        {
                            "x": -1.9156829475073422,
                            "y": 52.492680198537606
                        },
                        {
                            "x": -1.9067565559057797,
                            "y": 52.494143429314995
                        },
                        {
                            "x": -1.8889037727026547,
                            "y": 52.49288923448668
                        },
                        {
                            "x": -1.8813506721167172,
                            "y": 52.484317945774336
                        },
                        {
                            "x": -1.872767603269061,
                            "y": 52.471353311833155
                        },
                        {
                            "x": -1.8871871589331235,
                            "y": 52.463196181549684
                        },
                        {
                            "x": -1.9040099738745297,
                            "y": 52.467379514119756
                        },
                        {
                            "x": -1.9280425666479672,
                            "y": 52.47888162888222
                        }
                    ]
                ],
                "name": "B46HY",
                "tags": "UK",
                "branchId": 432,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-12T09:12:23.000Z",
                "updatedAt": "2022-09-12T09:12:23.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Indu's store"
            },
            {
                "geofenceId": 262,
                "geoLongLat": [
                    [
                        {
                            "x": 78.63877269730568,
                            "y": 17.66813489872171
                        },
                        {
                            "x": 78.67173168168068,
                            "y": 17.626257597792815
                        },
                        {
                            "x": 78.70743724808693,
                            "y": 17.57913400829748
                        },
                        {
                            "x": 78.74039623246193,
                            "y": 17.52676006058442
                        },
                        {
                            "x": 78.80356761918068,
                            "y": 17.435069284041372
                        },
                        {
                            "x": 78.78159496293068,
                            "y": 17.403621822071496
                        },
                        {
                            "x": 78.75412914261818,
                            "y": 17.393138131841322
                        },
                        {
                            "x": 78.75138256058693,
                            "y": 17.304002534473657
                        },
                        {
                            "x": 78.71293041214943,
                            "y": 17.28826823088611
                        },
                        {
                            "x": 78.67997142777443,
                            "y": 17.311869181576572
                        },
                        {
                            "x": 78.67997142777443,
                            "y": 17.39838005209193
                        },
                        {
                            "x": 78.64975902543068,
                            "y": 17.31449132247545
                        },
                        {
                            "x": 78.64426586136818,
                            "y": 17.267287067477852
                        },
                        {
                            "x": 78.52890941605568,
                            "y": 17.214823714921277
                        },
                        {
                            "x": 78.39432689652443,
                            "y": 17.20432925654763
                        },
                        {
                            "x": 78.37510082230568,
                            "y": 17.285645716166936
                        },
                        {
                            "x": 78.38059398636818,
                            "y": 17.377411469858732
                        },
                        {
                            "x": 78.34763500199318,
                            "y": 17.38789606135917
                        },
                        {
                            "x": 77.96036693558693,
                            "y": 17.448170794114354
                        },
                        {
                            "x": 77.88895580277443,
                            "y": 17.634110333256867
                        },
                        {
                            "x": 78.08258983597756,
                            "y": 17.809398710056335
                        },
                        {
                            "x": 78.32772228226662,
                            "y": 17.78193947850829
                        },
                        {
                            "x": 78.48771068558693,
                            "y": 17.66813489872171
                        },
                        {
                            "x": 78.63877269730568,
                            "y": 17.66813489872171
                        }
                    ]
                ],
                "name": "Hyderabad",
                "tags": "Hyderabad",
                "branchId": 432,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 1,
                "createdAt": "2022-09-12T09:09:54.000Z",
                "updatedAt": "2022-09-12T09:09:54.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Indu's store"
            },
            {
                "geofenceId": 261,
                "geoLongLat": [
                    [
                        {
                            "x": -13.62030029296875,
                            "y": 57.87105329209306
                        },
                        {
                            "x": -2.28240966796875,
                            "y": 59.825493056630116
                        },
                        {
                            "x": 2.90313720703125,
                            "y": 50.8232885012118
                        },
                        {
                            "x": -8.52264404296875,
                            "y": 48.140432438188114
                        },
                        {
                            "x": -13.62030029296875,
                            "y": 57.87105329209306
                        }
                    ]
                ],
                "name": "uk",
                "tags": "uk",
                "branchId": 431,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-06T13:16:55.000Z",
                "updatedAt": "2022-09-06T13:16:55.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "foodie store"
            },
            {
                "geofenceId": 260,
                "geoLongLat": [
                    [
                        {
                            "x": -12.48007555936363,
                            "y": 57.99291978803038
                        },
                        {
                            "x": -2.7242161843636303,
                            "y": 59.58659875750563
                        },
                        {
                            "x": 3.6917994406363697,
                            "y": 51.13381631656534
                        },
                        {
                            "x": -8.26132555936363,
                            "y": 48.46849913659315
                        },
                        {
                            "x": -12.48007555936363,
                            "y": 57.99291978803038
                        }
                    ]
                ],
                "name": "uk",
                "tags": "uk",
                "branchId": 429,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-05T13:08:37.000Z",
                "updatedAt": "2022-09-05T13:08:37.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Veena Stores"
            },
            {
                "geofenceId": 259,
                "geoLongLat": [
                    [
                        {
                            "x": -12.101493598525831,
                            "y": 57.83245947835005
                        },
                        {
                            "x": 0.5547564014741679,
                            "y": 59.478000829259116
                        },
                        {
                            "x": 4.861397026474168,
                            "y": 50.04936662021988
                        },
                        {
                            "x": -10.519462348525831,
                            "y": 48.73371771735023
                        },
                        {
                            "x": -12.101493598525831,
                            "y": 57.83245947835005
                        }
                    ]
                ],
                "name": "uk",
                "tags": "uk",
                "branchId": 407,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-05T12:44:39.000Z",
                "updatedAt": "2022-09-05T12:44:39.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Dmart"
            },
            {
                "geofenceId": 258,
                "geoLongLat": [
                    [
                        {
                            "x": -18.408101872166473,
                            "y": 57.156795869837715
                        },
                        {
                            "x": 4.4434606278335265,
                            "y": 58.004891020058615
                        },
                        {
                            "x": 4.7950231278335265,
                            "y": 51.2030912640437
                        },
                        {
                            "x": -12.079976872166473,
                            "y": 47.0659992660847
                        },
                        {
                            "x": -18.408101872166473,
                            "y": 57.156795869837715
                        }
                    ]
                ],
                "name": "uk",
                "tags": "uk",
                "branchId": 407,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-09-05T10:11:35.000Z",
                "updatedAt": "2022-09-05T10:11:35.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Dmart"
            },
            {
                "geofenceId": 257,
                "geoLongLat": [
                    [
                        {
                            "x": -3.408573166292892,
                            "y": 52.529914479767115
                        },
                        {
                            "x": -4.279239670199142,
                            "y": 52.93902879446201
                        },
                        {
                            "x": -0.579593674105392,
                            "y": 53.42294538916529
                        },
                        {
                            "x": 0.013668044644608024,
                            "y": 53.11083439057847
                        },
                        {
                            "x": -0.002811447542891976,
                            "y": 52.14055855064526
                        },
                        {
                            "x": -0.17309953348039198,
                            "y": 52.046062083986435
                        },
                        {
                            "x": -2.782352463167892,
                            "y": 51.91749657562725
                        },
                        {
                            "x": -4.397342697542892,
                            "y": 52.1203261558821
                        },
                        {
                            "x": -3.408573166292892,
                            "y": 52.529914479767115
                        }
                    ]
                ],
                "name": "birmingham",
                "tags": "test",
                "branchId": 426,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-08-22T06:37:51.000Z",
                "updatedAt": "2022-08-22T06:37:51.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "weight testing store"
            },
            {
                "geofenceId": 256,
                "geoLongLat": [
                    [
                        {
                            "x": -2.944107949733734,
                            "y": 50.018035001911244
                        },
                        {
                            "x": 0.8791342377662659,
                            "y": 50.7465941819511
                        },
                        {
                            "x": 2.241438925266266,
                            "y": 51.463989814932326
                        },
                        {
                            "x": 3.032454550266266,
                            "y": 53.654134467640475
                        },
                        {
                            "x": 1.7140951752662659,
                            "y": 55.01201395623187
                        },
                        {
                            "x": 0.30784517526626587,
                            "y": 56.42276354937139
                        },
                        {
                            "x": -1.5815752744674683,
                            "y": 59.08715029952038
                        },
                        {
                            "x": -3.7788408994674683,
                            "y": 59.53571123030096
                        },
                        {
                            "x": -8.612825274467468,
                            "y": 58.49512983497186
                        },
                        {
                            "x": -6.679231524467468,
                            "y": 55.5798979842037
                        },
                        {
                            "x": -4.481965899467468,
                            "y": 53.7503356888015
                        },
                        {
                            "x": -5.185090899467468,
                            "y": 52.64473078579492
                        },
                        {
                            "x": -6.239778399467468,
                            "y": 49.61249049802648
                        },
                        {
                            "x": -3.8667315244674683,
                            "y": 49.72625558925418
                        },
                        {
                            "x": -2.944107949733734,
                            "y": 50.018035001911244
                        }
                    ]
                ],
                "name": "US",
                "tags": "US Area",
                "branchId": 425,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-08-16T11:51:08.000Z",
                "updatedAt": "2022-08-16T11:51:08.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Test Supra Store"
            },
            {
                "geofenceId": 255,
                "geoLongLat": [
                    [
                        {
                            "x": 127.29933199438861,
                            "y": 33.58030410266607
                        },
                        {
                            "x": 131.1665194943886,
                            "y": 29.614059115612488
                        },
                        {
                            "x": 144.0864413693886,
                            "y": 34.381980447789445
                        },
                        {
                            "x": 149.6235507443886,
                            "y": 41.9697023133199
                        },
                        {
                            "x": 152.2602694943886,
                            "y": 45.33863350002958
                        },
                        {
                            "x": 139.7798007443886,
                            "y": 46.25774680771719
                        },
                        {
                            "x": 127.29933199438861,
                            "y": 33.58030410266607
                        }
                    ]
                ],
                "name": "japan",
                "tags": "japan area",
                "branchId": 425,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-08-16T11:47:01.000Z",
                "updatedAt": "2022-08-16T11:47:01.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Test Supra Store"
            },
            {
                "geofenceId": 253,
                "geoLongLat": [
                    [
                        {
                            "x": -8.588916245803734,
                            "y": 58.1899010858241
                        },
                        {
                            "x": -1.645556870803735,
                            "y": 59.59728372014287
                        },
                        {
                            "x": 3.979443129196265,
                            "y": 51.31217238030203
                        },
                        {
                            "x": -5.864306870803735,
                            "y": 48.42420218453667
                        },
                        {
                            "x": -8.588916245803734,
                            "y": 58.1899010858241
                        }
                    ]
                ],
                "name": "UK",
                "tags": "UK",
                "branchId": 423,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-07-29T05:13:38.000Z",
                "updatedAt": "2022-07-29T05:13:38.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Mytesting store"
            },
            {
                "geofenceId": 251,
                "geoLongLat": [
                    [
                        {
                            "x": -7.802124768839334,
                            "y": 57.88773117434684
                        },
                        {
                            "x": -1.3861091438393336,
                            "y": 58.5358967156374
                        },
                        {
                            "x": 2.9205314811606664,
                            "y": 50.89856441356392
                        },
                        {
                            "x": -5.077515393839334,
                            "y": 49.263181037783006
                        },
                        {
                            "x": -7.802124768839334,
                            "y": 57.88773117434684
                        }
                    ]
                ],
                "name": "uk",
                "tags": "uk",
                "branchId": 421,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-07-22T06:54:18.000Z",
                "updatedAt": "2022-07-22T06:54:18.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "text cutter new"
            },
            {
                "geofenceId": 248,
                "geoLongLat": [
                    [
                        {
                            "x": -5.8970509450355735,
                            "y": 58.018910907298476
                        },
                        {
                            "x": -6.6001759450355735,
                            "y": 49.48138803003101
                        },
                        {
                            "x": 3.3314646799644265,
                            "y": 51.875528037992055
                        },
                        {
                            "x": -1.3267384450355735,
                            "y": 58.801553983187965
                        },
                        {
                            "x": -5.8970509450355735,
                            "y": 58.018910907298476
                        }
                    ]
                ],
                "name": "Uk Epos",
                "tags": "For Purpose",
                "branchId": 418,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-06-23T12:03:57.000Z",
                "updatedAt": "2022-06-23T12:03:57.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Test epos"
            },
            {
                "geofenceId": 247,
                "geoLongLat": [
                    [
                        {
                            "x": -10.806427001953125,
                            "y": 57.30427332824548
                        },
                        {
                            "x": -2.456817626953125,
                            "y": 59.37938701592854
                        },
                        {
                            "x": 3.871307373046875,
                            "y": 51.37349493730543
                        },
                        {
                            "x": -11.509552001953125,
                            "y": 47.84450101574877
                        },
                        {
                            "x": -10.806427001953125,
                            "y": 57.30427332824548
                        }
                    ]
                ],
                "name": "india",
                "tags": "india",
                "branchId": 416,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-04-27T06:40:02.000Z",
                "updatedAt": "2022-04-27T06:40:02.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Big Basket Shop"
            },
            {
                "geofenceId": 245,
                "geoLongLat": [
                    [
                        {
                            "x": -18.27850341796875,
                            "y": 54.44768586644477
                        },
                        {
                            "x": -4.21600341796875,
                            "y": 62.494564761390556
                        },
                        {
                            "x": 13.01055908203125,
                            "y": 56.44124039140363
                        },
                        {
                            "x": -2.45819091796875,
                            "y": 46.65320687122665
                        },
                        {
                            "x": -18.27850341796875,
                            "y": 54.44768586644477
                        }
                    ]
                ],
                "name": "uok",
                "tags": "uok",
                "branchId": 410,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-04-08T09:24:02.000Z",
                "updatedAt": "2022-04-08T09:24:02.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "textCutter"
            },
            {
                "geofenceId": 244,
                "geoLongLat": [
                    [
                        {
                            "x": -65.88062167167664,
                            "y": 1.0941728420004708
                        },
                        {
                            "x": -46.193121671676636,
                            "y": -2.7718662772594267
                        },
                        {
                            "x": -50.060309171676636,
                            "y": -17.27092323558612
                        },
                        {
                            "x": -64.82593417167664,
                            "y": -16.261085077029687
                        },
                        {
                            "x": -65.88062167167664,
                            "y": 1.0941728420004708
                        }
                    ]
                ],
                "name": "ID",
                "tags": "ID",
                "branchId": 388,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-02-27T18:25:58.000Z",
                "updatedAt": "2022-02-27T18:25:58.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Navya Organic store"
            },
            {
                "geofenceId": 243,
                "geoLongLat": [
                    [
                        {
                            "x": 20.111567974090576,
                            "y": 21.326222514717557
                        },
                        {
                            "x": 35.228755474090576,
                            "y": 14.643117669763356
                        },
                        {
                            "x": 22.924067974090576,
                            "y": -3.122963159653739
                        },
                        {
                            "x": -3.443119525909424,
                            "y": 15.999352501589037
                        },
                        {
                            "x": 20.111567974090576,
                            "y": 21.326222514717557
                        }
                    ]
                ],
                "name": "US",
                "tags": "US",
                "branchId": 388,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-02-27T06:26:37.000Z",
                "updatedAt": "2022-02-27T06:26:37.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Navya Organic store"
            },
            {
                "geofenceId": 241,
                "geoLongLat": [
                    [
                        {
                            "x": -11.465592817163168,
                            "y": 54.67375793783635
                        },
                        {
                            "x": -8.037858442163168,
                            "y": 59.26581608414226
                        },
                        {
                            "x": 2.5090165578368318,
                            "y": 61.05822425332856
                        },
                        {
                            "x": 9.364485307836832,
                            "y": 49.38229056742874
                        },
                        {
                            "x": -3.6433271921631682,
                            "y": 48.34156222510593
                        },
                        {
                            "x": -11.114030317163168,
                            "y": 50.624992942298505
                        },
                        {
                            "x": -11.992936567163168,
                            "y": 52.74951727990642
                        },
                        {
                            "x": -11.465592817163168,
                            "y": 54.67375793783635
                        }
                    ]
                ],
                "name": "London ",
                "tags": "london",
                "branchId": 388,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-02-04T07:39:39.000Z",
                "updatedAt": "2022-02-04T07:39:39.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Navya Organic store"
            },
            {
                "geofenceId": 240,
                "geoLongLat": [
                    [
                        {
                            "x": -0.3007161024382343,
                            "y": 51.49584227678942
                        },
                        {
                            "x": -0.21557205946948432,
                            "y": 51.58339265856425
                        },
                        {
                            "x": 0.008961021585203177,
                            "y": 51.59448457603287
                        },
                        {
                            "x": 0.05565291611645318,
                            "y": 51.486009041271004
                        },
                        {
                            "x": -0.07549637587573432,
                            "y": 51.440663236240724
                        },
                        {
                            "x": -0.3384816053679218,
                            "y": 51.440663236240724
                        },
                        {
                            "x": -0.3007161024382343,
                            "y": 51.49584227678942
                        }
                    ]
                ],
                "name": "london",
                "tags": "london",
                "branchId": 404,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2022-02-03T05:58:44.000Z",
                "updatedAt": "2022-02-03T05:58:44.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Uk New Store"
            },
            {
                "geofenceId": 239,
                "geoLongLat": [
                    [
                        {
                            "x": 75.51782011985779,
                            "y": 37.97191641744236
                        },
                        {
                            "x": 93.44750761985779,
                            "y": 24.499145288530627
                        },
                        {
                            "x": 79.03344511985779,
                            "y": 7.680506472007543
                        },
                        {
                            "x": 64.61938261985779,
                            "y": 23.21307718559779
                        },
                        {
                            "x": 75.51782011985779,
                            "y": 37.97191641744236
                        }
                    ]
                ],
                "name": "ind",
                "tags": "ind",
                "branchId": 398,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-12-06T20:32:50.000Z",
                "updatedAt": "2021-12-06T20:32:50.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Testing"
            },
            {
                "geofenceId": 238,
                "geoLongLat": [
                    [
                        {
                            "x": 71.01837158203125,
                            "y": 46.26724020382508
                        },
                        {
                            "x": 60.11993408203125,
                            "y": 27.308333052145453
                        },
                        {
                            "x": 70.31524658203125,
                            "y": -2.8826941788448694
                        },
                        {
                            "x": 92.46368408203125,
                            "y": 6.244315907853541
                        },
                        {
                            "x": 99.84649658203125,
                            "y": 29.47307903155816
                        },
                        {
                            "x": 71.01837158203125,
                            "y": 46.26724020382508
                        }
                    ]
                ],
                "name": "asd",
                "tags": "asd",
                "branchId": 401,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-12-06T19:23:13.000Z",
                "updatedAt": "2021-12-06T19:23:13.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "very large data"
            },
            {
                "geofenceId": 237,
                "geoLongLat": [
                    [
                        {
                            "x": 56.18188261985779,
                            "y": 43.99597518850542
                        },
                        {
                            "x": 66.02563261985779,
                            "y": -11.303399501555067
                        },
                        {
                            "x": 121.22094511985779,
                            "y": 18.858468030528613
                        },
                        {
                            "x": 56.18188261985779,
                            "y": 43.99597518850542
                        }
                    ]
                ],
                "name": "Delhi",
                "tags": "asdfa",
                "branchId": 337,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-12-02T06:10:31.000Z",
                "updatedAt": "2021-12-02T06:10:31.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Sindhu Store"
            },
            {
                "geofenceId": 236,
                "geoLongLat": [
                    [
                        {
                            "x": -8.603944329492208,
                            "y": 59.30675725523722
                        },
                        {
                            "x": -6.934022454492208,
                            "y": 48.627715261453176
                        },
                        {
                            "x": 2.030821295507792,
                            "y": 50.56429177364909
                        },
                        {
                            "x": 3.261290045507792,
                            "y": 54.26063461218232
                        },
                        {
                            "x": -3.242616204492208,
                            "y": 59.88499458907345
                        },
                        {
                            "x": -8.603944329492208,
                            "y": 59.30675725523722
                        }
                    ]
                ],
                "name": "uk",
                "tags": "uk",
                "branchId": 388,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-12-02T04:56:17.000Z",
                "updatedAt": "2021-12-02T04:56:17.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Navya Organic store"
            },
            {
                "geofenceId": 235,
                "geoLongLat": [
                    [
                        {
                            "x": -8.036396082365872,
                            "y": 58.702547026993116
                        },
                        {
                            "x": -2.059833582365873,
                            "y": 59.33591016436983
                        },
                        {
                            "x": 2.774150792634127,
                            "y": 51.59403672778223
                        },
                        {
                            "x": -0.6535835823658731,
                            "y": 49.6426317075141
                        },
                        {
                            "x": -6.630146082365873,
                            "y": 49.29995036449555
                        },
                        {
                            "x": -5.136005457365873,
                            "y": 53.77784122981253
                        },
                        {
                            "x": -9.354755457365872,
                            "y": 57.87096892380228
                        },
                        {
                            "x": -8.036396082365872,
                            "y": 58.702547026993116
                        }
                    ]
                ],
                "name": "Uk",
                "tags": "UK",
                "branchId": 388,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-11-19T03:11:53.000Z",
                "updatedAt": "2021-11-19T03:11:53.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Navya Organic store"
            },
            {
                "geofenceId": 234,
                "geoLongLat": [
                    [
                        {
                            "x": -2.5281180463512287,
                            "y": 52.557924823298976
                        },
                        {
                            "x": 1.7455635942737713,
                            "y": 52.65133452787848
                        },
                        {
                            "x": 1.5807686723987713,
                            "y": 52.1553379397946
                        },
                        {
                            "x": 1.1413155473987713,
                            "y": 51.755878083129424
                        },
                        {
                            "x": 0.7787667192737713,
                            "y": 51.74227466656749
                        },
                        {
                            "x": 0.9325753130237713,
                            "y": 51.62648016015016
                        },
                        {
                            "x": 0.3612862505237713,
                            "y": 51.44196212790235
                        },
                        {
                            "x": 1.5038643755237713,
                            "y": 51.373432220408745
                        },
                        {
                            "x": 1.3500557817737713,
                            "y": 51.13966175325008
                        },
                        {
                            "x": 0.16353234427377128,
                            "y": 50.731176414371106
                        },
                        {
                            "x": -0.8801688276012287,
                            "y": 50.73812980127897
                        },
                        {
                            "x": -1.2207449994762287,
                            "y": 50.57794064269566
                        },
                        {
                            "x": -2.1216239057262287,
                            "y": 50.60583887885045
                        },
                        {
                            "x": -2.4622000776012287,
                            "y": 50.50113522839182
                        },
                        {
                            "x": -2.7917899213512287,
                            "y": 50.696393994415395
                        },
                        {
                            "x": -3.3081473432262287,
                            "y": 50.60583887885045
                        },
                        {
                            "x": -3.1433524213512287,
                            "y": 50.4731749720434
                        },
                        {
                            "x": -3.5388602338512287,
                            "y": 50.262945425814486
                        },
                        {
                            "x": -4.285930546351229,
                            "y": 50.24889699849564
                        },
                        {
                            "x": -2.5281180463512287,
                            "y": 52.557924823298976
                        }
                    ]
                ],
                "name": "London",
                "tags": "London",
                "branchId": 388,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-11-18T05:04:35.000Z",
                "updatedAt": "2021-11-18T05:04:35.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Navya Organic store"
            },
            {
                "geofenceId": 229,
                "geoLongLat": [
                    [
                        {
                            "x": 74.11157011985779,
                            "y": 38.23818222652529
                        },
                        {
                            "x": 94.50219511985779,
                            "y": 27.019986397423406
                        },
                        {
                            "x": 82.19750761985779,
                            "y": 5.922047287777888
                        },
                        {
                            "x": 60.04907011985779,
                            "y": 23.523702518128303
                        },
                        {
                            "x": 74.11157011985779,
                            "y": 38.23818222652529
                        }
                    ]
                ],
                "name": "India North",
                "tags": "North",
                "branchId": 383,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-11-11T10:55:14.000Z",
                "updatedAt": "2021-11-11T10:55:14.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Kart Mart"
            },
            {
                "geofenceId": 227,
                "geoLongLat": [
                    [
                        {
                            "x": 77.65624639459324,
                            "y": 8.490774305041022
                        },
                        {
                            "x": 94.53124639459323,
                            "y": 26.19185529826151
                        },
                        {
                            "x": 75.72265264459324,
                            "y": 36.383202034570765
                        },
                        {
                            "x": 67.98827764459324,
                            "y": 23.641439677995173
                        },
                        {
                            "x": 77.65624639459324,
                            "y": 8.490774305041022
                        }
                    ]
                ],
                "name": "mumbai",
                "tags": "good area",
                "branchId": 390,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-10-24T12:53:33.000Z",
                "updatedAt": "2021-10-24T12:53:33.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "D'Mart Store"
            },
            {
                "geofenceId": 223,
                "geoLongLat": [
                    [
                        {
                            "x": 77.74551031060886,
                            "y": 8.133393404766432
                        },
                        {
                            "x": 93.74160406060885,
                            "y": 25.232954872728616
                        },
                        {
                            "x": 75.63613531060886,
                            "y": 37.07988497182865
                        },
                        {
                            "x": 67.19863531060886,
                            "y": 23.79357321804686
                        },
                        {
                            "x": 77.74551031060886,
                            "y": 8.133393404766432
                        }
                    ]
                ],
                "name": "hyderbad India",
                "tags": "hyderbad india",
                "branchId": 388,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-10-04T13:48:01.000Z",
                "updatedAt": "2021-10-04T13:48:01.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Navya Organic store"
            },
            {
                "geofenceId": 222,
                "geoLongLat": [
                    [
                        {
                            "x": -14.682692176527476,
                            "y": 56.99608979923927
                        },
                        {
                            "x": -3.3448015515274765,
                            "y": 60.023893868793195
                        },
                        {
                            "x": 3.6864484484725235,
                            "y": 51.12790775654085
                        },
                        {
                            "x": -11.782301551527476,
                            "y": 48.57868512519531
                        },
                        {
                            "x": -14.682692176527476,
                            "y": 56.99608979923927
                        }
                    ]
                ],
                "name": "india",
                "tags": "india geofence",
                "branchId": 386,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-10-01T08:34:08.000Z",
                "updatedAt": "2021-10-01T08:34:08.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Navya Herbal Store"
            },
            {
                "geofenceId": 216,
                "geoLongLat": [
                    [
                        {
                            "x": 73.95038649467439,
                            "y": 18.54626798360014
                        },
                        {
                            "x": 73.95785376457185,
                            "y": 18.547569950686224
                        },
                        {
                            "x": 73.9606003466031,
                            "y": 18.552004701578166
                        },
                        {
                            "x": 73.96278902915925,
                            "y": 18.558148065234192
                        },
                        {
                            "x": 73.96313235191316,
                            "y": 18.561158639535112
                        },
                        {
                            "x": 73.95532175926179,
                            "y": 18.561890933360928
                        },
                        {
                            "x": 73.94742533592195,
                            "y": 18.558758321073487
                        },
                        {
                            "x": 73.9430050554654,
                            "y": 18.556724126456466
                        },
                        {
                            "x": 73.94751116661043,
                            "y": 18.550417969079618
                        },
                        {
                            "x": 73.95038649467439,
                            "y": 18.54626798360014
                        }
                    ]
                ],
                "name": "Pune Suburbs",
                "tags": "Pune Eon Free Zone",
                "branchId": 379,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-22T06:21:02.000Z",
                "updatedAt": "2021-09-22T06:21:02.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Samar Kundal"
            },
            {
                "geofenceId": 215,
                "geoLongLat": [
                    [
                        {
                            "x": 53.06322750516605,
                            "y": 23.591564586805312
                        },
                        {
                            "x": 82.41869625516605,
                            "y": 40.869817803926836
                        },
                        {
                            "x": 99.99682125516604,
                            "y": 14.633962355213034
                        },
                        {
                            "x": 77.32104000516605,
                            "y": -4.360227230403277
                        },
                        {
                            "x": 53.06322750516605,
                            "y": 23.591564586805312
                        }
                    ]
                ],
                "name": "india",
                "tags": "india",
                "branchId": 370,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-09-16T07:41:06.000Z",
                "updatedAt": "2021-09-16T07:41:06.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Divya Reddy Store"
            },
            {
                "geofenceId": 208,
                "geoLongLat": [
                    [
                        {
                            "x": 54.160136997316066,
                            "y": 25.21621047752905
                        },
                        {
                            "x": 76.66013699731606,
                            "y": -5.034763160318095
                        },
                        {
                            "x": 111.28904324731606,
                            "y": 33.037530027144214
                        },
                        {
                            "x": 72.26560574731606,
                            "y": 41.02246372966396
                        },
                        {
                            "x": 54.160136997316066,
                            "y": 25.21621047752905
                        }
                    ]
                ],
                "name": "Indi",
                "tags": "All over now",
                "branchId": 334,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-08-18T11:01:13.000Z",
                "updatedAt": "2021-08-18T11:01:13.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Freshie Meat"
            },
            {
                "geofenceId": 199,
                "geoLongLat": [
                    [
                        {
                            "x": 78.8781220667521,
                            "y": 17.499386660510034
                        },
                        {
                            "x": 78.9550263636271,
                            "y": 17.064038067301766
                        },
                        {
                            "x": 77.9937226526896,
                            "y": 17.132292996683777
                        },
                        {
                            "x": 77.9442841761271,
                            "y": 17.761144117723063
                        },
                        {
                            "x": 78.6034638636271,
                            "y": 17.865740277257917
                        },
                        {
                            "x": 78.7243134730021,
                            "y": 17.431267675008236
                        },
                        {
                            "x": 78.8781220667521,
                            "y": 17.499386660510034
                        }
                    ]
                ],
                "name": "Holborn",
                "tags": "City near farringdon",
                "branchId": 335,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-07-26T17:11:42.000Z",
                "updatedAt": "2021-07-26T17:11:42.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "more1"
            },
            {
                "geofenceId": 198,
                "geoLongLat": [
                    [
                        {
                            "x": 67.43468839407875,
                            "y": 23.6331770065052
                        },
                        {
                            "x": 78.33312589407875,
                            "y": 2.535613525173165
                        },
                        {
                            "x": 99.77843839407875,
                            "y": 25.551090392503983
                        },
                        {
                            "x": 78.68468839407875,
                            "y": 41.82759864088514
                        },
                        {
                            "x": 62.86437589407875,
                            "y": 44.14179302380434
                        },
                        {
                            "x": 67.43468839407875,
                            "y": 23.6331770065052
                        }
                    ]
                ],
                "name": "agra",
                "tags": "agra ghihkujn",
                "branchId": 337,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-07-20T07:53:18.000Z",
                "updatedAt": "2021-07-20T07:53:18.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "Sindhu Store"
            },
            {
                "geofenceId": 190,
                "geoLongLat": [
                    [
                        {
                            "x": -147.26922869682312,
                            "y": 33.465604179273925
                        },
                        {
                            "x": -105.43329119682312,
                            "y": 61.79248177477003
                        },
                        {
                            "x": -51.99579119682312,
                            "y": 43.098791369015
                        },
                        {
                            "x": -99.45672869682312,
                            "y": 8.09976713805671
                        },
                        {
                            "x": -147.26922869682312,
                            "y": 33.465604179273925
                        }
                    ]
                ],
                "name": "usa",
                "tags": "usa",
                "branchId": 333,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-06-28T10:58:49.000Z",
                "updatedAt": "2021-06-28T10:58:49.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "divya"
            },
            {
                "geofenceId": 187,
                "geoLongLat": [
                    [
                        {
                            "x": 60.47149658203125,
                            "y": 38.841846903808985
                        },
                        {
                            "x": 59.76837158203125,
                            "y": 24.549621500516615
                        },
                        {
                            "x": 75.58868408203125,
                            "y": 5.640585888363403
                        },
                        {
                            "x": 88.94805908203125,
                            "y": 5.640585888363403
                        },
                        {
                            "x": 103.01055908203125,
                            "y": 34.32756015705253
                        },
                        {
                            "x": 94.22149658203125,
                            "y": 45.10648485664684
                        },
                        {
                            "x": 68.90899658203125,
                            "y": 44.60806814444478
                        },
                        {
                            "x": 60.47149658203125,
                            "y": 38.841846903808985
                        }
                    ]
                ],
                "name": "Store name checking",
                "tags": "Store name checking descripton",
                "branchId": 324,
                "deliveryCharge": null,
                "minimumOrderValue": null,
                "adminId": 1,
                "isDeleted": 0,
                "isBlocked": 0,
                "createdAt": "2021-06-22T09:06:01.000Z",
                "updatedAt": "2021-06-22T09:06:01.000Z",
                "masterStoreId": null,
                "verticalType": null,
                "branchName": "G Mart"
            }
        ]
    )

}
const PushNotificationCustomer = () => {
    return(
        
        [
            {
                "customerId": 1390,
                "adminId": 1,
                "firstName": "TestSupra",
                "phoneNo": "9851650491",
                "lastName": null,
                "countryCode": "+91",
                "email": "testsupra@gmail.com",
                "password": "$2b$10$p/ReEry0RmFRdQUPbDzO0uq4FK9leEA0u7rwNmsHx1GTNnAmJJsXa",
                "accessToken": null,
                "socialId": null,
                "appVersion": 1,
                "deviceToken": "dg_H7we4cv_LgiQRH8hUI0:APA91bGIZocMjh4IskdkIsNpAE-6bp9DTInOyHYcFnP2R2ck543YWg5aeaPn9ZMTcl2w19AJoUHzWxF9Uyjv8tiqTJCHZI7tII87WGKVquCUR5A4d-IgROw0o6EQguindmTBwBDuN791",
                "deviceType": "WEB",
                "socialMode": null,
                "lastOnlineDate": "2022-12-19T19:14:10.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 2543,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": 0,
                "availableCredits": null,
                "online": null,
                "referralCode": "9NA2P988",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "23.1492279",
                "longitude": "87.6215207",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 87.6215207,
                    "y": 23.1492279
                },
                "age": null,
                "gender": null,
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-12-19T19:14:10.000Z",
                "address": "{\"city\": \"Greater London\", \"address\": \"17-21 Leicester Square, London WC2H 7LE, UK\", \"phoneNo\": null, \"pinCode\": \"WC2H 7LE\", \"landMark\": null, \"otherText\": \"\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
            },
            {
                "customerId": 1387,
                "adminId": 1,
                "firstName": "Papu",
                "phoneNo": "7029661710",
                "lastName": "Sarkar",
                "countryCode": "+91",
                "email": "papus944@gmail.com",
                "password": "$2b$10$j68G8.3u.uZtbH9AnPV7l.9HkaVFAXMNFKqEWWmFA2b1Qms74Nxua",
                "accessToken": null,
                "socialId": null,
                "appVersion": 1,
                "deviceToken": "MAKETHISOPTIONAL",
                "deviceType": "WEB",
                "socialMode": null,
                "lastOnlineDate": "2022-12-02T05:08:16.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": "IKEQPZDRABKCGQQ7J61MH8T5",
                "invitationCode": null,
                "otpCode": 3952,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "LNH5JETP",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 120,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-12-02T05:08:16.000Z",
                "address": "{}"
            },
            {
                "customerId": 1386,
                "adminId": 1,
                "firstName": "Supravat",
                "phoneNo": "9851650495",
                "lastName": "Sarkar",
                "countryCode": "+91",
                "email": "supravat.sarkar@oyelabs.com",
                "password": null,
                "accessToken": null,
                "socialId": "111163841682549091065",
                "appVersion": 1,
                "deviceToken": null,
                "deviceType": "ANDROID",
                "socialMode": "11116384168254909106",
                "lastOnlineDate": "2022-12-01T08:07:08.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": "B3HZBM3EA0I1PYBNEE97OPE5",
                "invitationCode": null,
                "otpCode": 2814,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": 50,
                "availableCredits": null,
                "online": null,
                "referralCode": "5POBZ7PN",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 0,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-12-01T08:07:08.000Z",
                "address": "{\"city\": \"England\", \"address\": \"3 Wardour St, London W1D 6PB, UK\", \"phoneNo\": null, \"pinCode\": \"W1D 6PB\", \"landMark\": null, \"otherText\": \"Other\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
            },
            {
                "customerId": 1370,
                "adminId": 1,
                "firstName": "Atul",
                "phoneNo": "8800695942",
                "lastName": "kumar",
                "countryCode": "+91",
                "email": "atulkumar99707.ak13@gmail.com",
                "password": "$2b$10$pBWOWWtAz2c5I86x09Xi4.28LKCwBOg32QUL8gNtMYHKgGWDa7.6i",
                "accessToken": null,
                "socialId": "101314087130518109969",
                "appVersion": 1,
                "deviceToken": null,
                "deviceType": "WEB",
                "socialMode": "10131408713051810996",
                "lastOnlineDate": "2022-11-19T13:50:32.000Z",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/76yp1669008151111-4mR71669008151043KakashiReadytofight.jfif",
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 6738,
                "phoneVerified": 0,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "JRQAOGQR",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-19T13:50:32.000Z",
                "address": "{\"city\": \"England\", \"address\": \"52 Eaton Square, London SW1W 9AP, UK\", \"phoneNo\": null, \"pinCode\": \"SW1W 9AP\", \"landMark\": null, \"otherText\": \"Work\", \"streetName\": null, \"addressType\": 3, \"houseNumber\": null}"
            },
            {
                "customerId": 1369,
                "adminId": 1,
                "firstName": "Atul",
                "phoneNo": "9953190769",
                "lastName": "kumar",
                "countryCode": "+91",
                "email": "atul.kumar@oyelabs.com",
                "password": "$2b$10$wsTILSUZnHXH/9wWtawAM.BsusFiOGBDFoGRTwYUQgrnRX7F4GQca",
                "accessToken": null,
                "socialId": "105324477467638238892",
                "appVersion": 1,
                "deviceToken": "cA5qQSIGffl28iPnBRpfwX:APA91bF70fyzKa8lSALrEM0tIjo8kMRDfpNoMTa61SNKBG-trWE64At5__eza719eGBFJAk4L261Qzr959fus0G_NaxQ-3yjcnbczwO29vLhqXqKVu8t4cuBnndjIZropyposdyyTEKp",
                "deviceType": "WEB",
                "socialMode": "10532447746763823889",
                "lastOnlineDate": "2022-11-17T13:03:20.000Z",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/irNA1669874492692-ANOb1669874492600screencapturelocalhost300020221124202030.png",
                "isNotificationOn": 1,
                "passwordResetToken": "5PCZ8LRBFQ4KM64ZR24PLD69",
                "invitationCode": null,
                "otpCode": 3897,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": 0,
                "availableCredits": null,
                "online": null,
                "referralCode": "AZN31DZ7",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 1,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "51.4947186",
                "longitude": "-0.1436198",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-17T13:03:20.000Z",
                "address": "{\"city\": \"England\", \"address\": \"320b Vauxhall Bridge Rd, London SW1V 1AA, UK\", \"phoneNo\": null, \"pinCode\": \"SW1V 1AA\", \"landMark\": null, \"otherText\": \"Other\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
            },
            {
                "customerId": 1365,
                "adminId": 1,
                "firstName": null,
                "phoneNo": "9503666645",
                "lastName": null,
                "countryCode": "+91",
                "email": "mindraneela33125@gmail.com",
                "password": "$2b$10$0Lj.JKWUeRHh722H43x.quaCe9qDrYF2qtb19aZ15ypYot.NMyWEy",
                "accessToken": null,
                "socialId": null,
                "appVersion": 1,
                "deviceToken": "MAKETHISOPTIONAL",
                "deviceType": "WEB",
                "socialMode": null,
                "lastOnlineDate": "2022-11-17T12:21:52.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 7834,
                "phoneVerified": 0,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "BNEAKJS6",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 120,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-17T12:21:52.000Z",
                "address": "{}"
            },
            {
                "customerId": 1344,
                "adminId": 1,
                "firstName": "Indraneela",
                "phoneNo": "9381507443",
                "lastName": "Mannam",
                "countryCode": "+91",
                "email": "mindraneela331@gmail.com",
                "password": "$2b$10$AzT02hhOxP7hoRF5pYw4/OUEr8GCRdo5oB8KGwF4WLGGy8.1Acpq.",
                "accessToken": null,
                "socialId": "104274537376022577432",
                "appVersion": 1,
                "deviceToken": null,
                "deviceType": "WEB",
                "socialMode": "10427453737602257743",
                "lastOnlineDate": "2022-11-11T09:10:10.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": "CGMZKGNDZQZAMYEQ2KZL0L32",
                "invitationCode": null,
                "otpCode": 2431,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 9999,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "4SMLYCR1",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-11T09:10:10.000Z",
                "address": "{\"city\": \"England\", \"address\": \"155 Russell Rd, Birmingham B13 8RR, UK\", \"phoneNo\": null, \"pinCode\": \"B13 8RR\", \"landMark\": null, \"otherText\": \"Other\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
            },
            {
                "customerId": 1336,
                "adminId": 1,
                "firstName": "Mannam",
                "phoneNo": "9912686351",
                "lastName": "Indraneela",
                "countryCode": "+91",
                "email": "mindraneela331@oyelabs.com",
                "password": null,
                "accessToken": null,
                "socialId": "102857298253027059501",
                "appVersion": 1,
                "deviceToken": null,
                "deviceType": "WEB",
                "socialMode": "10285729825302705950",
                "lastOnlineDate": "2022-11-10T12:12:43.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 7823,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "DAP43DA6",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-10T12:12:43.000Z",
                "address": "{}"
            },
            {
                "customerId": 1332,
                "adminId": 1,
                "firstName": "Mandeep Singh",
                "phoneNo": "6239287119",
                "lastName": "S",
                "countryCode": "91",
                "email": "mandeep.singh@oyelabs.com",
                "password": "$2b$10$gFD7xr1YOSd3kY1f/yPLGOfFiuhfErFaX/Y6FOClde.9NaNOaaCKm",
                "accessToken": null,
                "socialId": "113520825629372851450",
                "appVersion": 1,
                "deviceToken": "cgEEJKQJ-SZwjLPVor0s6j:APA91bHHivWhfsnGE5kv-2FhQkzVDAIFYQzMCtOsRE7QTehT6eRC3MtVfn3rmm-smwsXmTi5jQtMXxn5IrH78rI4nmd2T-6cn_-sXSwmXiRyBaV7Lb9P1hu4DowVhsPL_UmQgs0mnlFp",
                "deviceType": "WEB",
                "socialMode": "11352082562937285145",
                "lastOnlineDate": "2022-11-04T08:23:10.000Z",
                "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/5na51669023109992-7WFW1669023109943androidwall.webp",
                "isNotificationOn": 1,
                "passwordResetToken": "S7OE1SDQR8FP8YYIMJ60CEM0",
                "invitationCode": null,
                "otpCode": 2793,
                "phoneVerified": 1,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 312.72,
                "loyalityPoint": 0,
                "availableCredits": null,
                "online": null,
                "referralCode": "90TBN3FD",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 1,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "52.04950562160768",
                "longitude": "-0.26801979975377366",
                "isLoggedIn": 1,
                "timezone": 330,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-04T08:23:10.000Z",
                "address": "{\"city\": \"England\", \"address\": \"76 Station Rd, Langford, Biggleswade SG18 9PG, UK\", \"phoneNo\": null, \"pinCode\": \"SG18 9PG\", \"landMark\": null, \"otherText\": \"navya long\", \"streetName\": null, \"addressType\": 3, \"houseNumber\": null}"
            },
            {
                "customerId": 1326,
                "adminId": 1,
                "firstName": "sss",
                "phoneNo": "1234567894",
                "lastName": null,
                "countryCode": "+44",
                "email": "sss@gmail.com",
                "password": "$2b$10$WXfJ0gPZcX/VM1/3xtqej.jAzssslz//AqVODZLAoLyIioAYPMXJC",
                "accessToken": null,
                "socialId": null,
                "appVersion": 1,
                "deviceToken": "MAKETHISOPTIONAL",
                "deviceType": "WEB",
                "socialMode": null,
                "lastOnlineDate": "2022-11-01T10:43:05.000Z",
                "profilePicURL": null,
                "isNotificationOn": 1,
                "passwordResetToken": null,
                "invitationCode": null,
                "otpCode": 5628,
                "phoneVerified": 0,
                "totalBookings": null,
                "totalCompleteBookings": null,
                "walletAmount": 0,
                "loyalityPoint": null,
                "availableCredits": null,
                "online": null,
                "referralCode": "Q58ZNTGP",
                "completePhoneNo": null,
                "currentLocation": null,
                "customerAddresses": null,
                "IsBlocked": 0,
                "IsVerified": 0,
                "IsDeleted": 0,
                "promoCodes": null,
                "language": "en",
                "latitude": "43.34",
                "longitude": "55.54",
                "isLoggedIn": 1,
                "timezone": 120,
                "coords": {
                    "x": 55.54,
                    "y": 43.34
                },
                "age": null,
                "gender": "Male",
                "dateOfBirth": null,
                "referredBy": 0,
                "referAmount": 0,
                "verifiedViaFirebase": null,
                "firebaseUID": null,
                "createdAt": "2022-11-01T10:43:05.000Z",
                "address": "{}"
            },

        
                {
                    "customerId": 1390,
                    "adminId": 1,
                    "firstName": "TestSupra",
                    "phoneNo": "9851650491",
                    "lastName": null,
                    "countryCode": "+91",
                    "email": "testsupra@gmail.com",
                    "password": "$2b$10$p/ReEry0RmFRdQUPbDzO0uq4FK9leEA0u7rwNmsHx1GTNnAmJJsXa",
                    "accessToken": null,
                    "socialId": null,
                    "appVersion": 1,
                    "deviceToken": "dg_H7we4cv_LgiQRH8hUI0:APA91bGIZocMjh4IskdkIsNpAE-6bp9DTInOyHYcFnP2R2ck543YWg5aeaPn9ZMTcl2w19AJoUHzWxF9Uyjv8tiqTJCHZI7tII87WGKVquCUR5A4d-IgROw0o6EQguindmTBwBDuN791",
                    "deviceType": "WEB",
                    "socialMode": null,
                    "lastOnlineDate": "2022-12-19T19:14:10.000Z",
                    "profilePicURL": null,
                    "isNotificationOn": 1,
                    "passwordResetToken": null,
                    "invitationCode": null,
                    "otpCode": 2543,
                    "phoneVerified": 1,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 0,
                    "loyalityPoint": 0,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "9NA2P988",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 0,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "23.1492279",
                    "longitude": "87.6215207",
                    "isLoggedIn": 1,
                    "timezone": 330,
                    "coords": {
                        "x": 87.6215207,
                        "y": 23.1492279
                    },
                    "age": null,
                    "gender": null,
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-12-19T19:14:10.000Z",
                    "address": "{\"city\": \"Greater London\", \"address\": \"17-21 Leicester Square, London WC2H 7LE, UK\", \"phoneNo\": null, \"pinCode\": \"WC2H 7LE\", \"landMark\": null, \"otherText\": \"\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
                },
                {
                    "customerId": 1387,
                    "adminId": 1,
                    "firstName": "Papu",
                    "phoneNo": "7029661710",
                    "lastName": "Sarkar",
                    "countryCode": "+91",
                    "email": "papus944@gmail.com",
                    "password": "$2b$10$j68G8.3u.uZtbH9AnPV7l.9HkaVFAXMNFKqEWWmFA2b1Qms74Nxua",
                    "accessToken": null,
                    "socialId": null,
                    "appVersion": 1,
                    "deviceToken": "MAKETHISOPTIONAL",
                    "deviceType": "WEB",
                    "socialMode": null,
                    "lastOnlineDate": "2022-12-02T05:08:16.000Z",
                    "profilePicURL": null,
                    "isNotificationOn": 1,
                    "passwordResetToken": "IKEQPZDRABKCGQQ7J61MH8T5",
                    "invitationCode": null,
                    "otpCode": 3952,
                    "phoneVerified": 1,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 0,
                    "loyalityPoint": null,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "LNH5JETP",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 0,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "43.34",
                    "longitude": "55.54",
                    "isLoggedIn": 1,
                    "timezone": 120,
                    "coords": {
                        "x": 55.54,
                        "y": 43.34
                    },
                    "age": null,
                    "gender": "Male",
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-12-02T05:08:16.000Z",
                    "address": "{}"
                },
                {
                    "customerId": 1386,
                    "adminId": 1,
                    "firstName": "Supravat",
                    "phoneNo": "9851650495",
                    "lastName": "Sarkar",
                    "countryCode": "+91",
                    "email": "supravat.sarkar@oyelabs.com",
                    "password": null,
                    "accessToken": null,
                    "socialId": "111163841682549091065",
                    "appVersion": 1,
                    "deviceToken": null,
                    "deviceType": "ANDROID",
                    "socialMode": "11116384168254909106",
                    "lastOnlineDate": "2022-12-01T08:07:08.000Z",
                    "profilePicURL": null,
                    "isNotificationOn": 1,
                    "passwordResetToken": "B3HZBM3EA0I1PYBNEE97OPE5",
                    "invitationCode": null,
                    "otpCode": 2814,
                    "phoneVerified": 1,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 0,
                    "loyalityPoint": 50,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "5POBZ7PN",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 0,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "43.34",
                    "longitude": "55.54",
                    "isLoggedIn": 0,
                    "timezone": 330,
                    "coords": {
                        "x": 55.54,
                        "y": 43.34
                    },
                    "age": null,
                    "gender": "Male",
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-12-01T08:07:08.000Z",
                    "address": "{\"city\": \"England\", \"address\": \"3 Wardour St, London W1D 6PB, UK\", \"phoneNo\": null, \"pinCode\": \"W1D 6PB\", \"landMark\": null, \"otherText\": \"Other\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
                },
                {
                    "customerId": 1370,
                    "adminId": 1,
                    "firstName": "Atul",
                    "phoneNo": "8800695942",
                    "lastName": "kumar",
                    "countryCode": "+91",
                    "email": "atulkumar99707.ak13@gmail.com",
                    "password": "$2b$10$pBWOWWtAz2c5I86x09Xi4.28LKCwBOg32QUL8gNtMYHKgGWDa7.6i",
                    "accessToken": null,
                    "socialId": "101314087130518109969",
                    "appVersion": 1,
                    "deviceToken": null,
                    "deviceType": "WEB",
                    "socialMode": "10131408713051810996",
                    "lastOnlineDate": "2022-11-19T13:50:32.000Z",
                    "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/76yp1669008151111-4mR71669008151043KakashiReadytofight.jfif",
                    "isNotificationOn": 1,
                    "passwordResetToken": null,
                    "invitationCode": null,
                    "otpCode": 6738,
                    "phoneVerified": 0,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 0,
                    "loyalityPoint": null,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "JRQAOGQR",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 0,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "43.34",
                    "longitude": "55.54",
                    "isLoggedIn": 1,
                    "timezone": 330,
                    "coords": {
                        "x": 55.54,
                        "y": 43.34
                    },
                    "age": null,
                    "gender": "Male",
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-11-19T13:50:32.000Z",
                    "address": "{\"city\": \"England\", \"address\": \"52 Eaton Square, London SW1W 9AP, UK\", \"phoneNo\": null, \"pinCode\": \"SW1W 9AP\", \"landMark\": null, \"otherText\": \"Work\", \"streetName\": null, \"addressType\": 3, \"houseNumber\": null}"
                },
                {
                    "customerId": 1369,
                    "adminId": 1,
                    "firstName": "Atul",
                    "phoneNo": "9953190769",
                    "lastName": "kumar",
                    "countryCode": "+91",
                    "email": "atul.kumar@oyelabs.com",
                    "password": "$2b$10$wsTILSUZnHXH/9wWtawAM.BsusFiOGBDFoGRTwYUQgrnRX7F4GQca",
                    "accessToken": null,
                    "socialId": "105324477467638238892",
                    "appVersion": 1,
                    "deviceToken": "cA5qQSIGffl28iPnBRpfwX:APA91bF70fyzKa8lSALrEM0tIjo8kMRDfpNoMTa61SNKBG-trWE64At5__eza719eGBFJAk4L261Qzr959fus0G_NaxQ-3yjcnbczwO29vLhqXqKVu8t4cuBnndjIZropyposdyyTEKp",
                    "deviceType": "WEB",
                    "socialMode": "10532447746763823889",
                    "lastOnlineDate": "2022-11-17T13:03:20.000Z",
                    "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/irNA1669874492692-ANOb1669874492600screencapturelocalhost300020221124202030.png",
                    "isNotificationOn": 1,
                    "passwordResetToken": "5PCZ8LRBFQ4KM64ZR24PLD69",
                    "invitationCode": null,
                    "otpCode": 3897,
                    "phoneVerified": 1,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 0,
                    "loyalityPoint": 0,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "AZN31DZ7",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 1,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "51.4947186",
                    "longitude": "-0.1436198",
                    "isLoggedIn": 1,
                    "timezone": 330,
                    "coords": {
                        "x": 55.54,
                        "y": 43.34
                    },
                    "age": null,
                    "gender": "Male",
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-11-17T13:03:20.000Z",
                    "address": "{\"city\": \"England\", \"address\": \"320b Vauxhall Bridge Rd, London SW1V 1AA, UK\", \"phoneNo\": null, \"pinCode\": \"SW1V 1AA\", \"landMark\": null, \"otherText\": \"Other\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
                },
                {
                    "customerId": 1365,
                    "adminId": 1,
                    "firstName": null,
                    "phoneNo": "9503666645",
                    "lastName": null,
                    "countryCode": "+91",
                    "email": "mindraneela33125@gmail.com",
                    "password": "$2b$10$0Lj.JKWUeRHh722H43x.quaCe9qDrYF2qtb19aZ15ypYot.NMyWEy",
                    "accessToken": null,
                    "socialId": null,
                    "appVersion": 1,
                    "deviceToken": "MAKETHISOPTIONAL",
                    "deviceType": "WEB",
                    "socialMode": null,
                    "lastOnlineDate": "2022-11-17T12:21:52.000Z",
                    "profilePicURL": null,
                    "isNotificationOn": 1,
                    "passwordResetToken": null,
                    "invitationCode": null,
                    "otpCode": 7834,
                    "phoneVerified": 0,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 0,
                    "loyalityPoint": null,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "BNEAKJS6",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 0,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "43.34",
                    "longitude": "55.54",
                    "isLoggedIn": 1,
                    "timezone": 120,
                    "coords": {
                        "x": 55.54,
                        "y": 43.34
                    },
                    "age": null,
                    "gender": "Male",
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-11-17T12:21:52.000Z",
                    "address": "{}"
                },
                {
                    "customerId": 1344,
                    "adminId": 1,
                    "firstName": "Indraneela",
                    "phoneNo": "9381507443",
                    "lastName": "Mannam",
                    "countryCode": "+91",
                    "email": "mindraneela331@gmail.com",
                    "password": "$2b$10$AzT02hhOxP7hoRF5pYw4/OUEr8GCRdo5oB8KGwF4WLGGy8.1Acpq.",
                    "accessToken": null,
                    "socialId": "104274537376022577432",
                    "appVersion": 1,
                    "deviceToken": null,
                    "deviceType": "WEB",
                    "socialMode": "10427453737602257743",
                    "lastOnlineDate": "2022-11-11T09:10:10.000Z",
                    "profilePicURL": null,
                    "isNotificationOn": 1,
                    "passwordResetToken": "CGMZKGNDZQZAMYEQ2KZL0L32",
                    "invitationCode": null,
                    "otpCode": 2431,
                    "phoneVerified": 1,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 9999,
                    "loyalityPoint": null,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "4SMLYCR1",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 0,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "43.34",
                    "longitude": "55.54",
                    "isLoggedIn": 1,
                    "timezone": 330,
                    "coords": {
                        "x": 55.54,
                        "y": 43.34
                    },
                    "age": null,
                    "gender": "Male",
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-11-11T09:10:10.000Z",
                    "address": "{\"city\": \"England\", \"address\": \"155 Russell Rd, Birmingham B13 8RR, UK\", \"phoneNo\": null, \"pinCode\": \"B13 8RR\", \"landMark\": null, \"otherText\": \"Other\", \"streetName\": null, \"addressType\": 1, \"houseNumber\": null}"
                },
                {
                    "customerId": 1336,
                    "adminId": 1,
                    "firstName": "Mannam",
                    "phoneNo": "9912686351",
                    "lastName": "Indraneela",
                    "countryCode": "+91",
                    "email": "mindraneela331@oyelabs.com",
                    "password": null,
                    "accessToken": null,
                    "socialId": "102857298253027059501",
                    "appVersion": 1,
                    "deviceToken": null,
                    "deviceType": "WEB",
                    "socialMode": "10285729825302705950",
                    "lastOnlineDate": "2022-11-10T12:12:43.000Z",
                    "profilePicURL": null,
                    "isNotificationOn": 1,
                    "passwordResetToken": null,
                    "invitationCode": null,
                    "otpCode": 7823,
                    "phoneVerified": 1,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 0,
                    "loyalityPoint": null,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "DAP43DA6",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 0,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "43.34",
                    "longitude": "55.54",
                    "isLoggedIn": 1,
                    "timezone": 330,
                    "coords": {
                        "x": 55.54,
                        "y": 43.34
                    },
                    "age": null,
                    "gender": "Male",
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-11-10T12:12:43.000Z",
                    "address": "{}"
                },
                {
                    "customerId": 1332,
                    "adminId": 1,
                    "firstName": "Mandeep Singh",
                    "phoneNo": "6239287119",
                    "lastName": "S",
                    "countryCode": "91",
                    "email": "mandeep.singh@oyelabs.com",
                    "password": "$2b$10$gFD7xr1YOSd3kY1f/yPLGOfFiuhfErFaX/Y6FOClde.9NaNOaaCKm",
                    "accessToken": null,
                    "socialId": "113520825629372851450",
                    "appVersion": 1,
                    "deviceToken": "cgEEJKQJ-SZwjLPVor0s6j:APA91bHHivWhfsnGE5kv-2FhQkzVDAIFYQzMCtOsRE7QTehT6eRC3MtVfn3rmm-smwsXmTi5jQtMXxn5IrH78rI4nmd2T-6cn_-sXSwmXiRyBaV7Lb9P1hu4DowVhsPL_UmQgs0mnlFp",
                    "deviceType": "WEB",
                    "socialMode": "11352082562937285145",
                    "lastOnlineDate": "2022-11-04T08:23:10.000Z",
                    "profilePicURL": "https://d2563s26joau7f.cloudfront.net/images/5na51669023109992-7WFW1669023109943androidwall.webp",
                    "isNotificationOn": 1,
                    "passwordResetToken": "S7OE1SDQR8FP8YYIMJ60CEM0",
                    "invitationCode": null,
                    "otpCode": 2793,
                    "phoneVerified": 1,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 312.72,
                    "loyalityPoint": 0,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "90TBN3FD",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 1,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "52.04950562160768",
                    "longitude": "-0.26801979975377366",
                    "isLoggedIn": 1,
                    "timezone": 330,
                    "coords": {
                        "x": 55.54,
                        "y": 43.34
                    },
                    "age": null,
                    "gender": "Male",
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-11-04T08:23:10.000Z",
                    "address": "{\"city\": \"England\", \"address\": \"76 Station Rd, Langford, Biggleswade SG18 9PG, UK\", \"phoneNo\": null, \"pinCode\": \"SG18 9PG\", \"landMark\": null, \"otherText\": \"navya long\", \"streetName\": null, \"addressType\": 3, \"houseNumber\": null}"
                },
                {
                    "customerId": 1326,
                    "adminId": 1,
                    "firstName": "sss",
                    "phoneNo": "1234567894",
                    "lastName": null,
                    "countryCode": "+44",
                    "email": "sss@gmail.com",
                    "password": "$2b$10$WXfJ0gPZcX/VM1/3xtqej.jAzssslz//AqVODZLAoLyIioAYPMXJC",
                    "accessToken": null,
                    "socialId": null,
                    "appVersion": 1,
                    "deviceToken": "MAKETHISOPTIONAL",
                    "deviceType": "WEB",
                    "socialMode": null,
                    "lastOnlineDate": "2022-11-01T10:43:05.000Z",
                    "profilePicURL": null,
                    "isNotificationOn": 1,
                    "passwordResetToken": null,
                    "invitationCode": null,
                    "otpCode": 5628,
                    "phoneVerified": 0,
                    "totalBookings": null,
                    "totalCompleteBookings": null,
                    "walletAmount": 0,
                    "loyalityPoint": null,
                    "availableCredits": null,
                    "online": null,
                    "referralCode": "Q58ZNTGP",
                    "completePhoneNo": null,
                    "currentLocation": null,
                    "customerAddresses": null,
                    "IsBlocked": 0,
                    "IsVerified": 0,
                    "IsDeleted": 0,
                    "promoCodes": null,
                    "language": "en",
                    "latitude": "43.34",
                    "longitude": "55.54",
                    "isLoggedIn": 1,
                    "timezone": 120,
                    "coords": {
                        "x": 55.54,
                        "y": 43.34
                    },
                    "age": null,
                    "gender": "Male",
                    "dateOfBirth": null,
                    "referredBy": 0,
                    "referAmount": 0,
                    "verifiedViaFirebase": null,
                    "firebaseUID": null,
                    "createdAt": "2022-11-01T10:43:05.000Z",
                    "address": "{}"
                }
            
        ]
    )
}
const AdminSetting = () => {
    return (
        {
            "id": 32,
            "adminId": 1,
            "enterpriceReferenceId": "d31a69c9acd83cb725638e23e22fb00f",
            "androidGoogleMapKey": null,
            "iosGoogleMapKey": null,
            "logoImage": "https://d2563s26joau7f.cloudfront.net/images/mteP1626063676403-RmxQ1626063676308zaarol.png",
            "favIcon": "https://d2563s26joau7f.cloudfront.net/images/INfU1626063746064-6jzR1626063746019zaarolfavicon.png",
            "dashboardMapKey": "AIzaSyBpcV-fXpB6ZREEawA7BjDeU1ac5q-QsMU",
            "customerWebMapKey": null,
            "domainName": "https://devadmin.zaarol.com",
            "branchMapkey": null,
            "dailySchedulerThreshold": 7,
            "weeklySchedulerThreshold": 4,
            "monthlySchedulerThreshold": 3,
            "refferedByPoints": 101,
            "refferedToPoints": 51,
            "paymentMethods": "1,7,4",
            "sidebarLogo": null,
            "color": null,
            "name": null,
            "smsGateway": null,
            "flowStep": 0,
            "singleStoreId": null,
            "isChatEnabled": 0,
            "currency": "pound",
            "currencySymbol": "£",
            "showTomorrowSlotsOnly": 0,
            "forgotPasswordLink": null,
            "enabledLanguages": null,
            "pickerEnabled": 1,
            "autoAcceptOrders": 1,
            "enabledVerticals": "[0]",
            "panelType": 0,
            "goodFleetEnabled": 1,
            "goodFleetApiKey": "3ceaccd00703e29c6bef3aad7ec2c2fd",
            "sendItemsToGoodFleet": 1,
            "stripeCurrency": "gbp",
            "adminNumberAsStore": 0,
            "isPickupEnabled": 0,
            "showAllProducts": 1,
            "brandingEnabled": 1,
            "basicSearch": 0,
            "goodFleetGroceryEnabled": 1,
            "goodFleetFoodEnabled": null,
            "dynamicProductFields": "{\"gst\": {\"toBeShown\": 0, \"isRequired\": 0}, \"vat\": {\"toBeShown\": 1, \"isRequired\": 1}, \"name\": {\"toBeShown\": 1, \"isRequired\": 1}, \"tags\": {\"toBeShown\": 1, \"isRequired\": 1}, \"image\": {\"toBeShown\": 1, \"isRequired\": 1}, \"skuId\": {\"toBeShown\": 1, \"isRequired\": 1}, \"height\": {\"toBeShown\": 1, \"isRequired\": 0}, \"length\": {\"toBeShown\": 1, \"isRequired\": 0}, \"margin\": {\"toBeShown\": 1, \"isRequired\": 0}, \"weight\": {\"toBeShown\": 1, \"isRequired\": 0}, \"barcode\": {\"toBeShown\": 1, \"isRequired\": 1}, \"brandId\": {\"toBeShown\": 1, \"isRequired\": 1}, \"breadth\": {\"toBeShown\": 1, \"isRequired\": 0}, \"priority\": {\"toBeShown\": 1, \"isRequired\": 1}, \"quantity\": {\"toBeShown\": 1, \"isRequired\": 1}, \"baseValue\": {\"toBeShown\": 0, \"isRequired\": 0}, \"costPrice\": {\"toBeShown\": 1, \"isRequired\": 1}, \"thumbnail\": {\"toBeShown\": 1, \"isRequired\": 1}, \"unitValue\": {\"toBeShown\": 1, \"isRequired\": 0}, \"actualPrice\": {\"toBeShown\": 1, \"isRequired\": 1}, \"description\": {\"toBeShown\": 1, \"isRequired\": 1}, \"featureFlag\": {\"toBeShown\": 1, \"isRequired\": 1}, \"sellingUnit\": {\"toBeShown\": 0, \"isRequired\": 0}, \"isRedeemable\": {\"toBeShown\": 1, \"isRequired\": 1}, \"manufacturer\": {\"toBeShown\": 1, \"isRequired\": 0}, \"purchasedUnit\": {\"toBeShown\": 0, \"isRequired\": 0}, \"loyalityPoints\": {\"toBeShown\": 1, \"isRequired\": 0}, \"purchasedStock\": {\"toBeShown\": 0, \"isRequired\": 0}, \"subCategoryIds\": {\"toBeShown\": 1, \"isRequired\": 1}, \"discountedPrice\": {\"toBeShown\": 1, \"isRequired\": 1}, \"linkedProductIds\": {\"toBeShown\": 1, \"isRequired\": 0}, \"redemptionPoints\": {\"toBeShown\": 1, \"isRequired\": 0}}",
            "authViaFirebase": 0,
            "enabledVerticalsObjs": null,
            "bgImage": null,
            "alternateSidebarView": 0,
            "refferalMessage": "{\"ar\": \"لكل صديق ينضم ويقدم أول طلب له ، ستتلقى أنت وصديقك {{currency}}{{refferedByPoints}}، {{currency}} {{refferedToPoints}} على التوالي\", \"en\": \"Refer your friends to Zaarol. For each friend that joins using your code, you and your friend will receive {{currency}}{{refferedByPoints}} and {{currency}}{{refferedToPoints}} respectively\"}",
            "defaultTransport": null,
            "zoneWiseDHLDeliveryCharges": [
                {
                    "zoneId": "1",
                    "zoneName": "A",
                    "weightAllowForDhl": "30.00",
                    "workingDay": {
                        "id": 19,
                        "perKgCharge": 0.9,
                        "baseDeliverCharge": 42
                    },
                    "weekend": {
                        "id": 20,
                        "perKgCharge": 0.6,
                        "baseDeliverCharge": 18
                    }
                },
                {
                    "zoneId": "2",
                    "zoneName": "B",
                    "weightAllowForDhl": "30.00",
                    "workingDay": {
                        "id": 21,
                        "perKgCharge": 0.3,
                        "baseDeliverCharge": 22
                    },
                    "weekend": {
                        "id": 22,
                        "perKgCharge": 0.7,
                        "baseDeliverCharge": 28
                    }
                },
                {
                    "zoneId": "3",
                    "zoneName": "C",
                    "weightAllowForDhl": "30.00",
                    "workingDay": {
                        "id": 23,
                        "perKgCharge": 0.7,
                        "baseDeliverCharge": 33
                    },
                    "weekend": {
                        "id": 24,
                        "perKgCharge": 1.2,
                        "baseDeliverCharge": 38
                    }
                }
            ]
        }
    )
}
export {
    roleManagementData,
    CustomerData,
    PromoCodeData,
    productData,
    ReviewsData,
    cityData,
    DashboardData,
    ServiceProviderData,
    questionaryData,
    PushNotificationDataCities,
    PushNotificationCustomer,
    AdminSetting

}