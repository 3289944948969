import React from "react";
import {Spin} from 'antd';
import "./panel.scss";
import classNames from "classnames";
import {Icon} from '../';
import ScaleLoader from 'react-spinners/ScaleLoader';
const styles = {
  wrapper: "custom-panel__wrapper",
  blurred: "custom-panel__blurred-container",
  loading: "custom-panel__wrapper--loading",
  dimmer: "custom-panel__dimmer",
  emptyStateContainer: "custom-panel__empty-state-container",
  form: "custom-panel__container-class",
  modalLoader: "custom-panel__modal-loader",
};


function Panel({ loaderStyle, panelStyle, style, ...props}) {
  // if(props.isLoading) {
  //   return 
  // }
  if(props.isLoading) {
    if(props.isModal) {
      return <div className={`${props.className} ${styles.modalLoader}`}>{props.children}</div>
    }
    return (
      <div style={{...loaderStyle, display: 'flex', justifyContent: 'center', alignItems: 'center'}} className={props.className || 'custom-section single'}><Spin size="large" /></div>
    )
  }
  return (
    <div style={panelStyle} className={props.className || 'custom-section single'}>{props.children}</div>
  )
  return (
      <div
        className={classNames({
          [styles.wrapper]: true,
          [props.className]: props.className ? true : false,
          [styles.loading]: props.isLoading ? true : false,
        })}
      >
                {props.isLoading && (
          <div className={styles.dimmer}>
            <div className={styles.emptyStateContainer}>
            <Spin size="large" />
           {!!props.loadingText && <span>{props.loadingText}</span>}
            </div>
          </div>
        )}
        {
          props.isEmpty && (
            <div className={styles.dimmer}>
              <div className={styles.emptyStateContainer}>
            <Icon name="EMPTY" size="huge"/>
          <span>{props.emptyText}</span>
              </div>
           </div>
          )
        }
        <div className={classNames({[styles.containerClass]: true, [props.containerClass]: props.containerClass, [styles.blurred]: props.isLoading})}>

        {props.children}
        </div>
      </div>
  );
}

export default Panel;
