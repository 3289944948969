import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select, Tag } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import classNames from "classnames";

import axios from "../../shared/api/openAxios";
import Validations from "../../shared/validations/Validations";

import {
  CustomModal,
  ErrorMessage,
  Label,
  Panel,
} from "../../components/common";
import { connect } from "react-redux";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "custom-modal-image-container",
};
/*
   For checking
	
	"apiKey" : "NDdDMkg3MDU0VE5XVFcwUUxLQ01FNjRWWEw0MlpVOUI6RFZDUjZBWjdPMjExVENQNEc3N0VCMVNTOURSU0UyS0E=",

 */
function EPOS(props) {
  const intl = useIntl();

  const [isPristine, togglePristine] = useState(false);
  const [isVisible, toggleModal] = useState(false);
  const [isLoading, toggleLoading] = useState(false);
  const [message, setMessage] = useState(false);

  const [apiKey, changeAPIKey] = useState(Validations.validateTextField(""));

  const resetForm = () => {
    togglePristine(true);

    changeAPIKey(Validations.validateTextField(""));
  };

  const setForm = (data) => {
    changeAPIKey(Validations.validateTextField(data.apiKey));
  };

  //mount

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "apiKey":
        changeAPIKey(Validations.validateAddress(value));
        break;

      default:
        break;
    }
  };

  const getData = () => {
    if (props.panelType == 0) {
      return {
        token: apiKey.value,
        branchId: props.branchId,
      };
    }
    return {
      token: apiKey.value,
    };
  };

  const isFormValid = () => {
    const isValid = apiKey.isValid;

    return isValid;
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      toastr.warning(
        "Error",
        intl.formatMessage({ id: "app.message.formCheck" })
      );
      return;
    } else {
    }
  };

  return (
    <>
      <CustomModal
        size="large"
        // onClose={props.onClose}
        onCloseIconClick={closeModal}
        header={"app.field.eposSettings"}
        action={
          <>
            {" "}
            <Button type="default" shape={undefined} onClick={closeModal}>
              {intl.formatMessage({ id: "app.button.cancel" })}
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              shape={undefined}
              onClick={formAction}
            >
              Update
            </Button>
          </>
        }
        isVisible={isVisible}
      >
        <Panel isModal isLoading={isLoading} className={styles.formModal}>
          <div className={styles.formItem}>
            <Label value="app.field.apiKey" />
            <Input
              name="apiKey"
              onChange={handleFieldChange}
              value={apiKey.value}
              placeholder={"Enter API Key"}
            />
            {!isPristine && <ErrorMessage field={apiKey} />}
          </div>
          {message === true && isLoading == false ? (
            <div className={styles.formItem}>
              <Tag
                style={{ fontSize: "15px", margin: "0", padding: "20px" }}
                color="green"
              >
                {" "}
                EPOS is enabled for this store
              </Tag>
            </div>
          ) : message === false && isLoading == false ? (
            <div className={styles.formItem}>
              <Tag
                style={{ fontSize: "15px", margin: "0", padding: "20px" }}
                color="red"
              >
                {" "}
                EPOS is not enabled for this store.Please enter key to enable.
              </Tag>
            </div>
          ) : null}
        </Panel>
      </CustomModal>
      {props.panelType == 1 ? (
        <Button
          size="large"
          type="primary"
          onClick={() => {
            toggleModal(true);
          }}
        >
          View EPOS Settings
        </Button>
      ) : (
        <div
          role="button"
          onClick={() => {
            toggleModal(true);
          }}
        >
          {" "}
          View EPOS Settings
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  panelType: state.user.enterprice.panelType,
});

export default connect(mapStateToProps)(EPOS);
