import React from "react";

const greenTick = (color) =>  {
  return  (

 
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="check-circle"
    width="15px"
    height="15px"
    fill= {color}
    aria-hidden="true"
  >
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
  </svg>
  )
};

const hameBuggerIcon = (
  <svg viewBox="0 0 16 16" height="24px" width="24px">
    <defs>
      <clipPath id="a">
        <rect
          class="a"
          width="16"
          height="16"
          transform="translate(24 94)"
        ></rect>
      </clipPath>
    </defs>
    <g class="b" transform="translate(-24 -94)">
      <g transform="translate(24 94)">
        <path class="c" d="M14,2.5H4a1,1,0,0,0,0,2H14a1,1,0,1,0,0-2Z"></path>
        <path
          class="c"
          d="M2,3.5a1,1,0,0,0-2,0,.851.851,0,0,0,0,.195.9.9,0,0,0,.055.19,1.16,1.16,0,0,0,.095.17.915.915,0,0,0,.125.155.885.885,0,0,0,.15.12.814.814,0,0,0,.17.095A.825.825,0,0,0,.81,4.5a.877.877,0,0,0,.195,0,1,1,0,0,0,.7-.29.915.915,0,0,0,.125-.155,1.16,1.16,0,0,0,.095-.17A.928.928,0,0,0,2,3.694.851.851,0,0,0,2,3.5Z"
        ></path>
        <path
          class="c"
          d="M2,8A.855.855,0,0,0,2,7.8a.81.81,0,0,0-.075-.185,1,1,0,0,0-.095-.175.72.72,0,0,0-.125-.15,1,1,0,0,0-1.7.7,1,1,0,0,0,.165.555A.96.96,0,0,0,.3,8.7a.73.73,0,0,0,.15.125.811.811,0,0,0,.17.095.85.85,0,0,0,.19.055A.83.83,0,0,0,1.005,9,.851.851,0,0,0,1.2,9a.811.811,0,0,0,.185-.055.835.835,0,0,0,.175-.095.73.73,0,0,0,.15-.125.73.73,0,0,0,.125-.15A.8.8,0,0,0,1.93,8.4.846.846,0,0,0,2,8.192.827.827,0,0,0,2,8Z"
        ></path>
        <path
          class="c"
          d="M2,12.5A.858.858,0,0,0,2,12.3a.851.851,0,0,0-.075-.19,1.16,1.16,0,0,0-.095-.17.73.73,0,0,0-.125-.15,1,1,0,0,0-1.41,0,.73.73,0,0,0-.125.15,1.16,1.16,0,0,0-.095.17.819.819,0,0,0-.055.19.859.859,0,0,0-.02.195,1,1,0,0,0,.295.7.73.73,0,0,0,.15.125.811.811,0,0,0,.17.095.85.85,0,0,0,.19.055.83.83,0,0,0,.195.02.851.851,0,0,0,.195,0,.811.811,0,0,0,.185-.055.835.835,0,0,0,.175-.095.73.73,0,0,0,.15-.125.73.73,0,0,0,.125-.15.8.8,0,0,0,.095-.17A.849.849,0,0,0,2,12.691.827.827,0,0,0,2,12.5Z"
        ></path>
        <path class="c" d="M15,7H4A1,1,0,0,0,4,9H15a1,1,0,0,0,0-2Z"></path>
        <path
          class="c"
          d="M10.5,11.5H4a1,1,0,1,0,0,2h6.5a1,1,0,1,0,0-2Z"
        ></path>
      </g>
    </g>
  </svg>
);

export { greenTick, hameBuggerIcon };
