const userActionTypes = {
    SET_USER_INFO: 'USER_INFO/SET_USER_INFO',
    LOGOUT: 'USER_INFO/LOGOUT',
    USERNAME: "USERNAME",
    CHANGE_LANGUAGE: 'USER_INFO/CHANGE_LANGUAGE',
    CHANGE_STORE: 'USER_INFO/SET_STORE',
    SET_ENTERPRICE_INFO: 'USER_INFO/SET_ENTERPRICE_INFO',
    SET_GOOGLE_MAP_ERROR: 'USER_INFO/SET_GOOGLE_MAP_ERROR',
    SET_PANEL_TYPE: 'USER_INFO/SET_PANEL_TYPE',
    START_LOADING: 'USER_INFO/START_LOADING',
    STOP_LOADING: 'USER_INFO/STOP_LOADING',
    
};

export default userActionTypes;