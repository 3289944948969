import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import axiosConfig from "../../../shared/api/axiose"; // baseUrl : http://52.26.241.33:3002
import "./upload-button.scss";
import { toastr } from "react-redux-toastr";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { message, Spin, Upload } from "antd";
import classNames from "classnames";

const styles = {
  input: "custom-upload-button__input",
  label: "custom-upload-button__label",
  loader: "custom-upload-button__loader",
  disabled: "custom-upload-button__disabled",
};

function UploadButton(props) {
  const intl = useIntl();
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  console.log("urlimg", imgUrl);
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleimgUpload = (info) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setIsLoading(false);
        setImgUrl(url);
      });
    }
  };

  const uploadFile = (file) => {
    if (file) {
      setIsLoading(true);
      const data = new FormData();
      data.append("file", file);

      axiosConfig
        .post("/fileUpload", data)
        .then((res) => {
          if (res.data.statusCode === 200 && res.data.success) {
            console.log("propstype", props.type);
            if (props.type === "fileUpload") {
              const fileName = res.data.data.originalname[0].originalname;
              const fileUrl = `${res.data.data.publicBaseUrl}/${res.data.data.originalname[0].key}`;
              console.log("img", fileUrl);
              const obj = {
                // fileName: fileName,
                fileUrl: fileUrl,
              };
              setImgUrl(fileUrl);
              props.onChange(fileUrl);
            } else {
              props.onChange(res.data.data.location[0]);
              const img = `${res.data.data.publicBaseUrl}/${res.data.data.originalname[0].key}`;
              setImgUrl(img);
            }

            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const handleChange = (ev) => {
    handleFile(ev.target.files[0]);
  };

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      props.onChange(file);
    };
    if (props.resultType === "binary") {
      reader.readAsArrayBuffer(file);
    } else if (props.resultType === "base64") {
      reader.readAsDataURL(file);
    } else if (props.resultType === "url") {
      uploadFile(file);
    }
  };

  const clearInput = (e) => {
    e.target.value = null;
  };

  return (
    <>
      <input
        key={props.key}
        disabled={isLoading || props.disabled}
        onClick={clearInput}
        ref={inputRef}
        id={`${props.id}uploadFile`}
        className={styles.input}
        name={`${props.id}uploadFile`}
        type="file"
        accept={props.accept}
        onChange={handleChange}
      />
      {!isLoading ? (
        <label
          className={classNames({
            [styles.label]: true,
            [styles.loader]: isLoading,
          })}
          htmlFor={`${props.id}uploadFile`}
        >
          {props.fileName ? props.fileName : props.text}
        </label>
      ) : imgUrl ? (
        <div>
          <label className={styles.label}>{props.fileName}</label>
          <img
            src={imgUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        </div>
      ) : (
        <div>
          Uploading...
          <Spin />
        </div>
      )}
    </>
  );
}

UploadButton.propTypes = {
  resultType: PropTypes.oneOf(["binary", "base64", "url"]).isRequired,
  accept: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  parentRef: PropTypes.object,
};

UploadButton.defaultProps = {
  resultType: "base64",
  accept: "*",
  text: "Upload Image",
  parentRef: {
    current: null,
  },
  onChange: () => {},
};

export default UploadButton;
