import userActionTypes from "./user-action-types";
import modalActions from "../modal/modal-actions";
import { toastr } from "react-redux-toastr";
import history from "../../shared/utils/history";
import {
  getPanelType,
  setPanelType,
  getUserName,
  setUserName,
} from "../../shared/utils/local-storage";
const userActions = {
  setUserInfo: (payload) => ({ type: userActionTypes.SET_USER_INFO, payload }),
  logout: () => () => {
    localStorage.removeItem("Admin-Refresh-Token");
    localStorage.removeItem("userInformation");
    sessionStorage.removeItem("Admin_roles");
    sessionStorage.removeItem("User_name");
    sessionStorage.removeItem("Admin_info");
    sessionStorage.removeItem("Admin_access_token");
    toastr.info("Info", "Logged out");
    history.replace("/auth/login");
  },
  changeLanguage: (payload) => {
    sessionStorage.setItem("trollyAdmin_language", payload);
    return { type: userActionTypes.CHANGE_LANGUAGE, payload };
  },
  sessionExpired: () => (dispatch) => {
    dispatch(modalActions.showModal("SESSION_EXPIRED"));
    setTimeout(() => {
      dispatch(userActions.logout());
      dispatch(modalActions.hideModal());
    }, 3000);
  },

  startLoading: () => ({ type: userActionTypes.START_LOADING }),
  stopLoading: () => ({ type: userActionTypes.STOP_LOADING }),

  triggerLoading: () => (dispatch) => {
    dispatch(userActions.startLoading());
    setTimeout(() => {
      dispatch(userActions.stopLoading());
    }, 1000);
  },

  setPanelType: (payload) => {
    setPanelType(payload);
    return { type: userActionTypes.SET_PANEL_TYPE, payload };
  },

  setEnterpriceInfo: (data) => ({
    type: userActionTypes.SET_ENTERPRICE_INFO,
    payload: data,
  }),

  setStore: (data) => ({
    type: userActionTypes.CHANGE_STORE,
    payload: data,
  }),

  setGoogleMapError: () => ({
    type: userActionTypes.SET_GOOGLE_MAP_ERROR,
  }),

  setUserName: (name) => ({
    type: userActionTypes.USERNAME,
    payload: name,
  }),
};

export default userActions;
