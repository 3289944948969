import React from 'react';
import { CustomModal, Panel } from "../";
import {useIntl} from 'react-intl';
import { Button, Modal} from "antd";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  message: "confirm-modal__message",
};

function ConfirmModal(props) {
  console.log("ConfirmModal" , props?.data?.id)
  const intl = useIntl();
  return (
    <Modal
      size="tiny"
      // onClose={props.onClose}
      onCancel={props.onCloseIconClick}
      onClose={props.onCloseIconClick}
      closable
      title={props.header}
      visible={props.isVisible}
      footer={<>
           <Button type="default" shape={undefined} onClick={props.onCloseIconClick}>
            {intl.formatMessage({id: 'app.field.cancel'})}
          </Button>
        <Button type="primary" shape={undefined} onClick={() => props.onAction(props?.data?.id)}>
            {props.actionText}
          </Button>
      </>}
    >
      <Panel isModal isLoading={props.isLoading} className="asoijg">
        <h3 style={{margin: 0, padding: 0}} className={styles.message}>{props.message}</h3>
      </Panel>
    </Modal>
  );
}
export default ConfirmModal;