import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {CHART_COLORS} from '../../../../shared/constants/orders';


export default class ReportsBarChart extends PureComponent {

  render() {
      console.log(this.props.data)
    return (
        <ResponsiveContainer aspect={2} width="99%">
      <BarChart
        data={this.props.data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 0" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Bar stackId="a" dataKey="Total Pending Orders" fill={CHART_COLORS.totalPendingOrders} />
        <Bar stackId="a" dataKey="Total Delivered Orders" fill={CHART_COLORS.totalDeliveredOrders} />
        <Bar stackId="a" dataKey="Total Accepted Orders" fill={CHART_COLORS.totalAcceptedOrders} />
        <Bar stackId="a" dataKey="Total Cancelled Orders" fill={CHART_COLORS.totalCancelledOrders} />
      </BarChart>
      </ResponsiveContainer>
    );
  }
}

