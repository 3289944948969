import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function CustomUpload({ onChange, resultType }) {
  const [fileList, setFileList] = useState([]);

  const customRequest = ({ file, onSuccess }) => {
    // Simulate an async upload process
    setTimeout(() => {
      const url = "https://example.com/uploads/" + file.name;
      setFileList([{ uid: file.uid, name: file.name, status: "done", url }]);
      onSuccess();
      onChange({ fileUrl: url, fileName: file.name });
    }, 1000);
  };

  const onRemove = (file) => {
    setFileList([]);
    onChange({ fileUrl: "", fileName: "" });
  };

  const uploadProps = {
    customRequest,
    onRemove,
    fileList,
  };

  return (
    <Upload {...uploadProps}>
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
}

export default CustomUpload;
