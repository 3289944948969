import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Carousel } from "react-responsive-carousel";

import {
  Input,
  Button,
  Radio,
  Select,
  Divider,
  DatePicker,
  Rate,
  TimePicker,
  Modal,
  Tag,
  List,
  TextArea,
  Typography,
  Spin,
} from "antd";
import "./servicedetails.scss";
import { toastr } from "react-redux-toastr";
import classNames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";
// import moment from "moment-timezone";
import {
  allValidUnits,
  validUnitCombinations,
  unitNames,
} from "../../../../shared/constants/products";
import { useParams } from "react-router-dom";
import Validations from "../../../../shared/validations/Validations";
import CustomTable from "../../../../components/common/custom-table/custom-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "../../../../shared/api/axiose";
import { AiOutlineCloudDownload } from "react-icons/ai";
import {
  Header,
  FullPageDrawer,
  Panel,
  CustomModal,
  CustomImage,
  ErrorMessage,
  Label,
  UploadButton,
} from "../../../../components/common";

import "./add-edit-service.scss";
import { dateFormat } from "../../../../shared/utils/local-storage";
import AddEditQuestion from "../add-edit-question/add-edit-question";
const styles = {
  wrapper: "customer-details__wrapper",
  menu: "customer-details__menu",
  pageSize: "customer-details__menu__records-per-page",
  image: "customer-details__image",
  form: "customer-details__form",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formWrapper: "customer-details__form-wrapper",
  editIcon: "customer-details__edit-icon",
  backButton: "customer-details__back-button",
};
const { Option } = Select;
const ServiceEdit = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const imageContainerRef = useRef(null);
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  const [serviceData, setServiceData] = useState({});
  const [serviceName, setServiceName] = useState("");
  const [actualPrice, setActualPrice] = useState("");
  const [estimatedTimeInMinute, setTimeInMinute] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [newTimeSlot, setNewTimeSlot] = useState(null);
  const [serviceIncluded, setServiceIncluded] = useState([]);
  const [serviceExclude, setServiceExclude] = useState([]);
  const [newIncluded, setNewIncluded] = useState("");
  const [newExclude, setNewExclude] = useState("");
  const [description, setDescription] = useState("");
  const [serviceTypeTag, setServiceTypeTag] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { TextArea } = Input;
  const inputStyleleft = {
    width: 350,
    marginRight: "3rem",
    marginLeft: "2rem",
  };

  const fetchServiceData = () => {
    setLoading(true);
    axios
      .get(`/services/${id}`)
      .then((response) => {
        const responseData = response.data.data;
        setServiceData(responseData);

        // Set serviceIncluded and serviceExclude arrays from response data
        setServiceIncluded(responseData.serviceIncluded || []);
        setServiceExclude(responseData.excludes || []);
        setServiceTypeTag(responseData.serviceTypeTag);
        // Set other state variables based on response
        setServiceName(responseData.title);
        setActualPrice(responseData.actualPrice);
        setDiscountedPrice(responseData.discountedPrice);
        setSelectedCategory(responseData.Categories[0]?.id);
        setSelectedSubcategory(
          responseData.subcategories?.map((subcategory) => subcategory.id) || []
        );
        setTimeInMinute(responseData.estimatedTimeInMinute);
        setSelectedTimeSlots(responseData?.availableBookingSlotTime || []);
        setDescription(responseData.description);
      })
      .catch((error) => {
        console.error("Error fetching service data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchServiceData();
  }, []);

  //_________________________  // Fetch categories from API_________________________________//
  useEffect(() => {
    axios
      .get("/category")
      .then((response) => {
        const fetchedCategories = response.data.data.categories;
        setCategories(fetchedCategories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      axios
        .get(`/category/${selectedCategory}`)
        .then((response) => {
          const fetchedSubcategories = response.data.data.subcategories;
          setSubcategories(fetchedSubcategories);
        })
        .catch((error) => {
          console.error("Error fetching subcategories:", error);
        });
    } else {
      setSubcategories([]);
    }
  }, [selectedCategory]);
  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);

    setSelectedSubcategory(null);
  };

  const handleSubcategorySelect = (subcategoryId) => {
    setSelectedSubcategory(subcategoryId);
  };
  const ImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  const handleServiceNameChange = (e) => {
    setServiceName(e.target.value);
  };
  const handleActualPriceChange = (e) => {
    setActualPrice(e.target.value);
  };

  const handleDiscountedPriceChange = (e) => {
    setDiscountedPrice(e.target.value);
  };
  const handleEstimatedTime = (e) => {
    const inputVal = e.target.value;
    const numericValue = inputVal.replace(/\D/g, "");
    setTimeInMinute(numericValue);
  };
  const handleTimeSlotChange = (time, timeString) => {
    setNewTimeSlot(timeString);
  };
  const handleServiceTypeTagChange = (value) => {
    setServiceTypeTag(value);
  };
  const handleAddTimeSlot = () => {
    if (newTimeSlot) {
      const utcTimeSlot = moment(newTimeSlot, "h:mm a").utc().format("HH:mm");
      setSelectedTimeSlots([...selectedTimeSlots, utcTimeSlot]);
      setNewTimeSlot("");
    }
  };

  const handleRemoveTimeSlot = (timeSlot) => {
    const updatedTimeSlots = selectedTimeSlots.filter(
      (slot) => slot !== timeSlot
    );
    setSelectedTimeSlots(updatedTimeSlots);
  };
  const handleAddIncluded = () => {
    if (newIncluded.trim() !== "") {
      setServiceIncluded([...serviceIncluded, newIncluded]);
      setNewIncluded("");
    }
  };

  const handleAddExclude = () => {
    if (newExclude.trim() !== "") {
      setServiceExclude([...serviceExclude, newExclude]);
      setNewExclude("");
    }
  };

  const handleRemoveIncluded = (itemToRemove) => {
    const updatedServiceIncluded = serviceIncluded.filter(
      (item) => item !== itemToRemove
    );
    setServiceIncluded(updatedServiceIncluded);
  };

  const handleRemoveExclude = (itemToRemove) => {
    const updatedServiceExclude = serviceExclude.filter(
      (item) => item !== itemToRemove
    );
    setServiceExclude(updatedServiceExclude);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleSubmit = () => {
    const payload = {
      actualPrice: actualPrice,
      category: [{ categoryId: selectedCategory }],
      description,
      discountedPrice,
      estimatedTimeInMinute,
      serviceImg: imageURL.value || serviceData.serviceImg,
      status: true,
      title: serviceName,
    };

    if (serviceTypeTag !== null && serviceTypeTag !== "") {
      payload.serviceTypeTag = serviceTypeTag;
    }

    payload.subcategory =
      selectedSubcategory !== null
        ? selectedSubcategory.map((subcategoryId) => ({ subcategoryId }))
        : [];

    if (selectedTimeSlots && selectedTimeSlots.length > 0) {
      payload.availableBookingSlotTime = selectedTimeSlots;
    }

    if (serviceIncluded && serviceIncluded.length > 0) {
      payload.serviceIncluded = serviceIncluded;
    }

    if (serviceExclude && serviceExclude.length > 0) {
      payload.excludes = serviceExclude;
    }

    axios
      .put(`/services/${id}`, payload)
      .then((response) => {
        toast.success("Service Edited", "Service details have been updated.");
        fetchServiceData();
      })
      .catch((error) => {
        console.error("Error editing service:", error);
        toast.error("Error", "An error occurred while editing the service.");
      });
  };

  return (
    <div className={styles.wrapper}>
      <Header title="Service Edit" showBackButton={true} />
      <Panel className="custom-section single">
        {loading ? (
          <Spin size="large" style={{ marginTop: "16rem" }} />
        ) : (
          <div className={styles.formWrapper}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "340px",
              }}
            >
              <CustomImage src={imageURL.value || serviceData.serviceImg} />
              <UploadButton
                parentRef={imageContainerRef}
                resultType="url"
                accept="image/*"
                type="fileUpload"
                id="913fjhiu4ng2vri"
                text="Upload Image(300x300)"
                onChange={ImageChange}
              />
            </div>
            <div className={styles.form}>
              <div className={styles.formGroup}>
                <Divider orientation="left">Service Type</Divider>
                <Tag
                  style={{
                    width: "15rem",
                    marginLeft: "1.5rem",
                    height: "2rem",
                    paddingTop: "0.3rem",
                    textAlign: "center",
                    fontSize: "15px",
                    borderRadius: "5px",
                    fontWeight: "600",
                  }}
                  color={serviceData.isPickupService ? "#2db7f5" : "#2980B9"}
                >
                  {serviceData.isPickupService
                    ? "Pickup Service"
                    : "Regular Service"}
                </Tag>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Divider orientation="left">Category</Divider>
                  <Select
                    placeholder="Select a category"
                    style={inputStyleleft}
                    onChange={handleCategorySelect}
                    value={selectedCategory || serviceData?.title}
                  >
                    {categories.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.title}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.formItem}>
                  <Divider orientation="left">Service Name</Divider>
                  <Input
                    placeholder="Enter service name"
                    value={serviceName}
                    style={{ width: 350 }}
                    onChange={handleServiceNameChange}
                  />
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Divider orientation="left">Actual Price</Divider>
                  <Input
                    placeholder="Enter Actual Price"
                    style={inputStyleleft}
                    value={actualPrice}
                    onChange={handleActualPriceChange}
                  />
                </div>
                <div className={styles.formItem}>
                  <Divider orientation="left">Discounted Price</Divider>
                  <Input
                    placeholder="Enter Discounted Price"
                    style={{ width: 350 }}
                    value={discountedPrice}
                    onChange={handleDiscountedPriceChange}
                  />
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Divider orientation="left">Select service type</Divider>
                  <Select
                    placeholder="Select service type"
                    onChange={handleServiceTypeTagChange}
                    style={inputStyleleft}
                    value={serviceTypeTag}
                  >
                    <Option value="POPULAR">Popular</Option>
                  </Select>
                </div>
                <div className={styles.formItem}>
                  <Divider orientation="left">Estimated Time</Divider>
                  <Input
                    placeholder="Estimated time in mins"
                    style={{ width: 350 }}
                    value={estimatedTimeInMinute}
                    onChange={handleEstimatedTime}
                    type="number"
                  />
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Divider orientation="left">Sub Category</Divider>

                  <Select
                    mode="multiple"
                    // style={inputStyleleft}
                    placeholder="Select a subcategory"
                    onChange={handleSubcategorySelect}
                    value={selectedSubcategory || serviceData?.title}
                    disabled={!selectedCategory}
                  >
                    {subcategories.map((subcategory) => (
                      <Option key={subcategory.id} value={subcategory.id}>
                        {subcategory.title}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              {!serviceData.isPickupService && (
                <div className={styles.formItem}>
                  <Divider orientation="left">
                    Available Booking Slot Time
                  </Divider>
                  {selectedTimeSlots.length > 0 ? (
                    <List
                      dataSource={selectedTimeSlots}
                      renderItem={(timeSlot) => {
                        const utcTime = `${timeSlot} `;
                        const utcDate = moment.utc(timeSlot, "h:mm");
                        const istDate = utcDate.clone().utcOffset("+05:30");
                        const istTime = istDate.format("h:mm");
                        return (
                          <List.Item
                            key={timeSlot}
                            actions={[
                              <Button
                                type="text"
                                onClick={() => handleRemoveTimeSlot(timeSlot)}
                                style={{ color: "red" }}
                              >
                                <CloseOutlined />
                              </Button>,
                            ]}
                          >
                            <div className={styles.timeSlot}>
                              <span style={{ marginRight: "2rem" }}>
                                {utcTime} {"UTC"}
                              </span>
                            </div>
                          </List.Item>
                        );
                      }}
                    />
                  ) : (
                    <div>N/A</div>
                  )}
                  <TimePicker
                    format="h:mm a"
                    style={{ width: 350 }}
                    onChange={handleTimeSlotChange}
                    value={newTimeSlot ? moment(newTimeSlot, "h:mm a") : null}
                  />
                  <Button
                    style={{ width: "70px", marginTop: "10px" }}
                    onClick={handleAddTimeSlot}
                  >
                    Add
                  </Button>
                </div>
              )}

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Divider orientation="left">Service Included</Divider>
                  <List
                    dataSource={serviceIncluded}
                    renderItem={(item) => (
                      <List.Item
                        key={item}
                        actions={[
                          <Button
                            type="text"
                            onClick={() => handleRemoveIncluded(item)}
                            style={{ color: "red" }}
                          >
                            <CloseOutlined />
                          </Button>,
                        ]}
                      >
                        <h4>{item}</h4>
                      </List.Item>
                    )}
                  />
                  <Input
                    placeholder="Add service included"
                    value={newIncluded}
                    onChange={(e) => setNewIncluded(e.target.value)}
                    addonAfter={
                      <PlusOutlined
                        onClick={handleAddIncluded}
                        style={{ cursor: "pointer", color: "blue" }}
                      />
                    }
                  />
                </div>

                <div className={styles.formItem}>
                  <Divider orientation="left">Service Excluded</Divider>
                  <List
                    dataSource={serviceExclude}
                    renderItem={(item) => (
                      <List.Item
                        key={item}
                        actions={[
                          <Button
                            type="text"
                            onClick={() => handleRemoveExclude(item)}
                            style={{ color: "red" }}
                          >
                            <CloseOutlined />
                          </Button>,
                        ]}
                      >
                        <h4>{item}</h4>
                      </List.Item>
                    )}
                  />
                  <Input
                    placeholder="Add service exclude"
                    value={newExclude}
                    onChange={(e) => setNewExclude(e.target.value)}
                    addonAfter={
                      <PlusOutlined
                        onClick={handleAddExclude}
                        style={{ cursor: "pointer", color: "blue" }}
                      />
                    }
                  />
                </div>
              </div>

              <div className={styles.formItem}>
                <Divider orientation="left">Description</Divider>
                <TextArea
                  placeholder="Enter description"
                  rows={4}
                  style={{ width: "50rem" }}
                  value={description}
                  onChange={handleDescription}
                />
              </div>
            </div>
            <Button
              style={{ marginLeft: "auto", marginTop: "3rem" }}
              type="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        )}
      </Panel>
    </div>
  );
};

export default ServiceEdit;
