import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import io from "socket.io-client";
import Push from "push.js";
import { connect } from "react-redux";
import classNames from "classnames";

import Sidebar from "../components/sidebar/sidebar";
// import Navbar from "../components/navbar/navbar";
import Navbar from "../components/navbar/navbar_";
import "./home-container.scss";
import history from "../shared/utils/history";
import axiosClient from "../shared/api/axiosConfig";
import userActions from "../redux/user-info/user-actions";
import orderActions from "../redux/orders/orders-action";
import logo from "../assets/images/logo.png";
import { checkRtl } from "../shared/utils/rtl";

import Dashboard from "./dashboard/dashboard-container";
import Users from "./role-management/users";

import Products from "./products/products";

import Advertisements from "./advertisements/advertisements";

import PushNotification from "./push-notification/push-notification";
import ActivePromo from "./promo/active-promo";
import SubCategories from "./promo/sub-cat";
import Stores from "./stores/stores";
import Customers from "./customers/customers";
import CustomerDetails from "./customer-details/customer-details";
import PromoCodes from "./promosCodes/PromoCodes";

import Pickers from "./pickers/pickers";
import AdminSettings from "./settings/admin-settings";

import OrderReports from "./order-reports/order-reports";
import StoreCategories from "./store-categories/store-categories";

import Reviews from "./review/review";
import Reviews_SP from "./serviceProvider_review/review";
import Businessowners from "./Business_Owners/businessowners";
import Banners from "./Banners/Banners";
import BookingPayments from "./ServiceBooking/payments";
import BookingAccounts from "./ServiceBooking/accounts";
import servicebooking from "./ServiceBooking/servicebooking";
import ServiceBookingDetails from "./service-booking-details/service-booking-details";
// import serviceProvider from "./ServiceProvider/serviceProvider";
import ServiceProvider from "./service__provider/serviceprovider";
import serviceModule from "./service/service-module";
import { toastr } from "react-redux-toastr";
import BusinessOwnerDetails from "./businessOwner-details/businessOwner_details";
import ServiceProviderDetails from "./serviceProvider_details/serviceProvider_details";
import GeneralFAQ from "./generalFaq/general_faq";
import TrainingVideo from "./trainingVideo/trainingVideo";
import activePromoSub from "./promo/active-promo-sub";
import PaymentBookingDetails from "./service-booking-details/payment-details";
import CheckoutSuccess from "../auth/card/CheckoutSuccess";
import pushNotifications from "./push-notification/push-notifications";
import pushNotification from "./push-notification/push-notification";
import adminAbout from "./settings/admin-about";
import ServiceDetails from "./service/components/add-edit-modal/service-details";
import ServiceEdit from "./service/components/add-edit-modal/service-edit";

import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import agents from "./promo/agents";
const styles = {
  wrapper: "home__wrapper",
  contentContainer: "home__content-container",
  active: "home__content-container--active",
};
class Home extends React.Component {
  state = {
    isSidebarExpanded: true,
  };

  toggleSidebar = () => {
    // toastr.success("enter")
    this.setState((prev) => ({
      isSidebarExpanded: !prev.isSidebarExpanded,
    }));
  };
  socket = null;

  // componentDidMount() {
  //   if (!sessionStorage.getItem("Admin_access_token")) {
  //     history.replace("/auth/login");
  //   } else {
  //     if (process.env.NODE_ENV === "development") {
  //       return;
  //     }
  //     // this.setUpSocket();
  //     // this.setUpPN();
  //   }
  //   // TODO: get user details here
  //   // axiosClient.get('googleSheets/getListOfDataSets').then((r) => {console.log('then')}).catch((err) =>{ console.log('catcg', err)});
  // }

  componentWillUnmount() {
    if (this.socket !== null) {
      this.socket.disconnect();
    }
  }

  getLoader = () => <h1>Loading...</h1>;

  getRoutes = (location) => (
    <TransitionGroup appear={true} exit={true}>
      <CSSTransition
        key={location.key}
        timeout={400}
        unmountOnExit
        classNames="custom-oyelabs-anim"
      >
        <Switch location={location}>
          {/* <Route exact="/checkout-success/" component={CheckoutSuccess} /> */}
          <Route exact path="/home/settings" component={AdminSettings} />

          <Route exact path="/home/about" component={adminAbout} />

          <Route path="/home/dashboard" component={Dashboard} />
          <Route path="/home/business-owners" component={Businessowners} />

          <Route
            exact
            path="/home/businessowners/details/:Id"
            component={BusinessOwnerDetails}
          />
          <Route
            exact
            path="/home/serviceprovider/details/:Id"
            component={ServiceProviderDetails}
          />

          <Route path="/home/role-management" component={Users} />
          <Route path="/home/services" component={serviceModule} />
          <Route path="/home/general-faq" component={GeneralFAQ} />
          <Route path="/home/training-video" component={TrainingVideo} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/home/marketing/banners" component={Banners} />

          <Route exact path="/home/questionnaire" component={Advertisements} />

          <Route exact path="/home/marketing/promos" component={PromoCodes} />
          <Route
            exact
            path="/home/marketing/push-notification"
            component={pushNotifications}
          />

          <Route exact path="/home/Categories" component={ActivePromo} />
          <Route exact path="/home/Agent" component={agents} />

          <Route exact path="/home/SubCategories" component={SubCategories} />

          <Route exact path="/home/cities" component={Stores} />
          <Route exact path="/home/customers" component={Customers} />
          <Route
            exact
            path="/home/customers/details/:customerId"
            component={CustomerDetails}
          />

          <Route
            exact
            path="/home/service-booking"
            component={servicebooking}
          />

          <Route exact path="/home/accounts" component={BookingAccounts} />
          <Route exact path="/home/payments" component={BookingPayments} />
          <Route
            exact
            path="/home/service/details/:id"
            component={ServiceDetails}
          />
          <Route exact path="/home/service/edit/:id" component={ServiceEdit} />
          <Route
            exact
            path="/home/service-booking/details/:id"
            component={ServiceBookingDetails}
          />
          <Route
            exact
            path="/home/payment-booking/details/:id"
            component={PaymentBookingDetails}
          />
          <Route exact path="/home/reviews/customer" component={Reviews} />
          <Route exact path="/home/reviews/sp" component={Reviews_SP} />
          <Route exact path="/home/reports" component={OrderReports} />
          <Route
            exact
            path="/home/service-provider"
            component={ServiceProvider}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );

  render() {
    return (
      <Route
        render={({ location }) => (
          <div className={styles.wrapper}>
            <Sidebar
              location={location}
              toggleSidebar={this.toggleSidebar}
              isSidebarExpanded={this.state.isSidebarExpanded}
            />
            <div
              className="oyelabs__home-wrapper"
              dir={checkRtl(this.props.locale) ? "rtl" : "ltr"}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflow: "hidden",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Navbar
                toggleSidebar={this.toggleSidebar}
                isSidebarExpanded={this.state.isSidebarExpanded}
              />
              <div
                dir={checkRtl(this.props.locale) ? "rtl" : "ltr"}
                className={classNames({
                  [styles.contentContainer]: true,
                  [styles.active]: this.state.isSidebarExpanded,
                })}
              >
                {this.getRoutes(location)}
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.user.isLoading,
  locale: state.user.locale,
});

export default connect(mapStateToProps)(Home);
