import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button, Menu, Tooltip } from "antd";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import {
  Header,
  PaginationInfo,
  DelayedInput,
  CustomTable,
  CustomTag,
  Label,
  CustomImage,
} from "../../components/common";
import { StoresTable, StoresModal } from "./components";
import { dateFormat } from "../../shared/utils/local-storage";

import axiosConfig from "../../shared/api/axiose"; //base url http://52.26.241.33:3002

import "./stores.scss";
import {
  removeEmptyKeys,
  nestedParseJSON,
} from "../../shared/utils/object-manipulation";
import { useConfirm } from "../../hooks";
import EPOS from "../settings/epos";
import { cityData } from "../../FakeData";
import moment from "moment";
import {
  country,
  states,
  cities,
  zipcode,
} from "../../shared/constants/countryFilterData";
import { AccessControl } from "../AccessControl/accessControl";
import { connect } from "react-redux";
const { Option } = Select;

const styles = {
  wrapper: "stores__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "stores__menu--left",
  menuRight: "stores__menu--right",
  pageSize: "stores__records-per-page",
  filterCont: "filterCont",
};

function Stores(props) {
  const intl = useIntl();
  const [searchDelayed, updateSearchDelayed] = useState("");

  const [getCategoriesData, setCategoriesData] = useState([]);
  const [search, updateSearch] = useState("");
  const [accessControl, updateAccessControll] = useState({
    view: false,
    create: false,
    update: false,
    delete: false,
  });

  const [categories, updateCategories] = useState({
    data: [],
    isLoading: false,
  });
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isLoading: false,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    count: 0,
    isEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    search: "",
    current: 1,
    total: 0,
  });
  const [filter, updateFilter] = useState({
    country: "",
    state: "",
    city: "",
    zipcode: "",
  });
  console.log("filter city => ", filter);
  const [getFilterData, updateFilterData] = useState({
    country: [],
    state: [],
    city: [],
    zipcode: [],
  });

  const handlePaginationChange = ({ current, pageSize, total }) => {
    updatePagination({
      ...pagination,
      current,
      total,
      pageSize,
      skip: (current - 1) * pagination.pageSize,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      pageSize: val,
    });
  };

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };

  const openAddStoreModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD",
    });
  };

  const addStore = (data) => {
    // enableD;
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
    if (!data.isFreeDeliveryEnabled) {
      delete data.limitForFreeDelivery;
    }
  };

  const editCity = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
  };

  const openEditStoreModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "EDIT",
      data,
    });
  };
  const openViewCityModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "VIEW",
      data,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
    });
  };

  const getStoreData = () => {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: false,
      count: 9,
      data: cityData(),
    });
    updatePagination({
      ...pagination,
      total: 9,
    });
  };

  const handleDisable = useConfirm(
    // enableDisableCity,

    "Added city will be deleted !",
    "Delete City ?"
  );
  const getAccessControl = () => {
    let accessControlData = accessControl;
    let View = AccessControl(
      ["city.read", "city.create", "city.update", "city.delete"],
      props?.subModules
    );
    let Update = AccessControl(["city.update"], props?.subModules);

    let Delete = AccessControl(["city.delete"], props?.subModules);
    let Add = AccessControl(["city.create"], props?.subModules);

    accessControlData.view = View;
    accessControlData.update = Update;
    accessControlData.create = Add;
    accessControlData.delete = Delete;
    updateAccessControll({
      ...accessControl,
      ...accessControlData,
    });
  };

  useEffect(() => {
    getAccessControl();
    // getFilterZipcodeData();
  }, []);

  useEffect(() => {
    if (filter.country) {
    }
  }, [filter.country]);
  useEffect(() => {
    if (filter.state) {
    }
  }, [filter.state]);

  useEffect(() => {
    if (filter.city) {
    }
  }, [filter.city]);

  const getZipCodes = (item) => {
    let zipcode = JSON.parse(item);

    let data = "";
    if (zipcode && zipcode.length >= 2) {
      data = data + zipcode[0].zipCode + ", " + zipcode[1].zipCode + "... ";
    } else if (zipcode) {
      zipcode.map((obj, i) => {
        data = data + (i !== 0 ? " , " : " ") + obj?.zipCode;
      });
    }

    return data;
  };

  const getCategoryName = (data) => {
    let id_array = [];
    let categoryData = JSON.parse(data);
    if (categoryData) {
      categoryData.map((obj) => {
        id_array.push(obj.categoryId);
      });
    }

    let FilterCategory = [];
    if (getCategoriesData) {
      FilterCategory = getCategoriesData.filter((obj) => {
        return id_array.includes(obj.id);
      });
    }
    console.log("categoryName length => ", FilterCategory.length);
    let categoryName = "";

    if (FilterCategory && FilterCategory.length >= 2) {
      categoryName = categoryName + FilterCategory[0]?.title + "...";
    } else if (FilterCategory) {
      categoryName = categoryName + FilterCategory[0]?.title;
    }

    // return categoryName.length > 15 ? categoryName.substring(0 , 15) + "..." : categoryName ;
    return categoryName;
  };

  const columns = [
    {
      fixed: "left",
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.sNo" }),
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },

    {
      fixed: "left",
      dataIndex: "country",
      title: "Country",
    },
    {
      dataIndex: "state",
      title: "State",
    },
    {
      dataIndex: "name",
      title: "City",
      render: (item) => {
        return <span>{item}</span>;
      },
    },
    {
      dataIndex: "categoryId",
      title: "Category",
      render: (item) => getCategoryName(item),
    },
    {
      dataIndex: "zipCode",
      title: "Zip Code",
      render: (item) => getZipCodes(item),
    },

    {
      dataIndex: "createdAt",
      title: intl.formatMessage({ id: "app.field.createdAt" }),
      render: (item) => moment(item).format(dateFormat),
    },
    {
      dataIndex: "status",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        return <CustomTag isEnabled={item} />;
      },
    },

    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openViewCityModal(item);
          }}
        >
          View
        </Menu.Item>
        {accessControl?.update ? (
          <Menu.Item
            onClick={() => {
              openEditStoreModal(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.edit" })}
          </Menu.Item>
        ) : (
          ""
        )}

        {item.status == false && (
          <Menu.Item onClick={() => {}}>
            {intl.formatMessage({ id: "app.field.enable" })}
          </Menu.Item>
        )}
        {item.status == true && (
          <Menu.Item onClick={() => {}}>
            {intl.formatMessage({ id: "app.field.disable" })}
          </Menu.Item>
        )}

        <Menu.Item
          onClick={() => {
            handleDisable(item, true);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
        {item.isBlocked == 0 && (
          <Menu.Item onClick={() => {}}>
            {intl.formatMessage({ id: "app.field.block" })}
          </Menu.Item>
        )}
        {item.isBlocked == 1 && (
          <Menu.Item onClick={() => {}}>
            {intl.formatMessage({ id: "app.field.unblock" })}
          </Menu.Item>
        )}
      </Menu>
    );
  };
  const handleFilterCountry = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      country: data,
    });
  };
  const handleFilterState = (val) => {
    updateFilter({
      ...filter,
      state: val,
    });
  };
  const handleFilterCity = (val) => {
    // let data = JSON.parse(val)
    updateFilter({
      ...filter,
      city: val,
    });
  };

  const handleFilterZipcode = (val) => {
    // let data = JSON.parse(val)
    updateFilter({
      ...filter,
      zipcode: val,
    });
  };

  return (
    <div className={styles.wrapper}>
      <h1></h1>
      <Header title="app.pageTitle.cities" />
      <div className={styles?.filterCont}>
        <header style={{ display: "flex", gap: "2.3rem", flexWrap: "wrap" }}>
          <div>
            <Label value="Select Country" vertical></Label>
            <Select
              showSearch
              placeholder="Country"
              onChange={handleFilterCountry}
              style={{ width: "180px" }}
              // value={"US"}
            >
              {getFilterData.country.length &&
                getFilterData.country.map((item) => (
                  <Option key={item?.countryId}>{item?.countryName}</Option>
                ))}
            </Select>
          </div>
          <div>
            <Label value="Select State" vertical></Label>
            <Select
              showSearch
              placeholder="State"
              onChange={handleFilterState}
              // mode="multiple"
              style={{ width: "180px" }}
              // value={stores.selected}
            >
              {getFilterData.state.length &&
                getFilterData.state.map((item) => (
                  <Option key={item?.stateId} value={item?.stateId}>
                    {item?.stateName}
                  </Option>
                ))}
            </Select>
          </div>
          <div>
            <Label value="Select City" vertical></Label>
            <Select
              showSearch
              placeholder="City"
              // mode="multiple"
              onChange={handleFilterCity}
              style={{ width: "180px" }}
              // value={stores.selected}
            >
              {getFilterData.city.length &&
                getFilterData.city.map((item) => (
                  <Option key={item?.cityId} value={item?.cityId}>
                    {item?.cityName}
                  </Option>
                ))}
            </Select>
          </div>
          <div>
            <Label value="Select Zip Code" vertical></Label>
            <Select
              showSearch
              placeholder="Zip Code"
              name="zipCode"
              // mode="multiple"
              onChange={handleFilterZipcode}
              style={{ width: "180px" }}
              // value={stores.selected}
            >
              {getFilterData.zipcode.length &&
                getFilterData.zipcode.map((item) => (
                  <Option key={item?.zipCode} value={item?.zipCode}>
                    {item?.zipCode}
                  </Option>
                ))}
            </Select>
          </div>
        </header>
      </div>

      <StoresModal
        isVisible={modalControls.isVisible}
        modalType={modalControls.type}
        onCloseIconClick={handleModalClose}
        addStore={addStore}
        storeCategories={categories.data}
        data={modalControls.data}
        editCity={editCity}
        isLoading={modalControls.isLoading}
        cityData={tableControls}
        getCategoriesData={getCategoriesData}
      />

      <div className={styles.content}>
        <div className={styles.menu}>
          <header></header>
          <section>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={2}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
            <Button
              type="primary"
              shape={undefined}
              size="medium"
              loading={categories.isLoading}
              onClick={openAddStoreModal}
            >
              {intl.formatMessage({ id: "app.button.addCities" })}
            </Button>
          </section>
        </div>
        <CustomTable
          columns={columns}
          dataSource={tableControls.data}
          isLoading={tableControls.isLoading || categories.isLoading}
          isEmpty={tableControls.isEmpty}
          pagination={pagination}
          editItem={openEditStoreModal}
          onChange={handlePaginationChange}
          offset={43}
          columnWidth={130}
          width
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  subModules: state.roles.subModules,
});

export default connect(mapStateToProps)(Stores);
