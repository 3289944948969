import React from "react"
const country  = [
    {
        id: 0,
        text: "US"
    },
   

]
const states = [
    {
        id: 0,
        text: "Delhi"
    },{
        id: 1,
        text: "California"
    },{
        id: 2,
        text: "Texas"
    },{
        id: 3,
        text: "New York"
    },{
        id: 4,
        text: "Florida"
    },{
        id: 5,
        text: "Illinois"
    },{
        id: 6,
        text: "Quebec"
    },{
        id: 7,
        text: "British Columbia"
    },{
        id: 8,
        text: "Alberta"
    },{
        id: 9,
        text: "Manitoba"
    },
]

const cities = [
    {
        id: 0,
        text: "New York City, New York"
    }, {
        id: 1,
        text: "Los Angeles, California"
    }, {
        id: 2,
        text: "Chicago, Illinois"
    }, {
        id: 3,
        text: "Houston, Texas"
    }, {
        id: 4,
        text: "Phoenix, Arizona"
    }, {
        id: 5,
        text: "Philadelphia, Pennsylvania"
    }, {
        id: 6,
        text: "Toronto, Ontario"
    }, {
        id: 7,
        text: "British Columbia"
    }, {
        id: 8,
        text: "Vancouver, British Columbia"
    }, {
        id: 9,
        text: "Montreal, Quebec"
    },
]

const zipcode = [
    {
        id: 0,
        text: "1100548"
    }, {
        id: 1,
        text: "2255475"
    }, {
        id: 2,
        text: "64679974"
    }, {
        id: 3,
        text: "7858569"
    }, {
        id: 4,
        text: "25487979"
    }, {
        id: 5,
        text: "244779"
    }, {
        id: 6,
        text: "4464664"
    }, {
        id: 7,
        text: "12458787"
    }, {
        id: 8,
        text: "78965665"
    }, {
        id: 9,
        text: "454797997"
    },
]

export {
    country,
    states,
    cities,
    zipcode


}



























