import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch, Menu, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";
import axios from "../../../../shared/api/axiose";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Panel,
  Icon,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../../../components/common";
import "./active-promo-table.scss";
import { connect } from "react-redux";
import ViewUser from "../../view-user/view-user";
import { dateFormat } from "../../../../shared/utils/local-storage";
import { toastr } from "react-redux-toastr";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  stickyAction: "sticky-action",
  actionIcon: "custom-table__action-icon",
  panelContainer: "active-promo-table__panel-container",
};

function ActivePromoTable({
  viewItem,
  updateTableControls,
  tableControls,
  CategoryData,
  ...props
}) {
  const intl = useIntl();
  const Switchbtn = (item) => {};

  const handleEnableDisable = (data) => {
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
    });

    axios
      .put(`/category/enableDisable/${data?.id}`, {
        isEnabled: !data?.isEnabled,
      })
      .then((res) => {
        if (res?.data?.statusCode >= "200" && res?.data?.statusCode <= "299") {
          CategoryData(true);
          // toast.success(res?.data?.message);
        } else {
          updateTableControls({
            ...tableControls,
            isTableLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log("city enable disable err=>", err);
        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
      });
  };

  const columns = [
    // Category Image / Icon, Category Name, Enabled Date,
    // City, Status(Enabled / Disabled), Actions
    // {
    //   "id": 3,
    //   "title": "test",
    //   "slug": "test",
    //   "categoryCover": null,
    //   "categoryIcon": "https://instaservice-dev.s3.us-west-2.amazonaws.com/1677835379061circadian_rythm.jpg",
    //   "parentCategory": null,
    //   "categoryType": "On demand",
    //   "howItWorks": null,
    //   "servicesIncluded": null,
    //   "createdBy": null,
    //   "createdAt": "2023-03-03T09:23:12.996Z",
    //   "updatedAt": "2023-03-03T09:23:12.996Z"
    // }
    {
      fixed: "left",
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.sNo" }),
      render: (item, object, index) => {
        return (
          <span>
            {props.pagination.pageSize * (props.pagination.current - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      fixed: "left",
      dataIndex: "categoryIcon",
      title: "Category Icon",
      render: (item) => <CustomImage src={item} isTable />,
    },
    {
      dataIndex: "title",
      title: "Category Name",
      render: (title) => (
        <span style={{ color: " #1890ff", fontWeight: "600" }}>{title}</span>
      ),
    },
    {
      dataIndex: "createdAt",
      title: "Enabled Date",
      render: (item) => moment(item).format(dateFormat),
    },
    // {
    //   dataIndex: "",
    //   title: "City",
    // },

    {
      dataIndex: "isEnabled",
      sNo: true,
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        // return item.isBlocked ? <Tag color="volcano">BLOCKED</Tag> : <Tag color="green">ACTIVE</Tag>
        return <CustomTag isEnabled={item} />;
      },
    },

    // {

    //   title: "Is Popular",
    //   dataIndex: "isPopular",
    //   // render: (item) => item.promoType == 2 ? `${item.credits} (% off)` : item.credits
    //   render: (item) => <Switch onChange = {()=> Switchbtn(item)}  />
    // },

    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        console.log("getMenu", item);
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const getMenu = (item) => {
    console.log("getMenu =>> ", item);
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            // viewItem(item);
            props.viewDetails(item);
          }}
        >
          {"view"}
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            props.editItem(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {item?.isEnabled === true ? (
          <Menu.Item onClick={() => handleEnableDisable(item)}>
            Disable
          </Menu.Item>
        ) : (
          <Menu.Item onClick={() => handleEnableDisable(item)}>
            Enable
          </Menu.Item>
        )}

        <Menu.Item
          onClick={() => {
            props.removeItem(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <CustomTable
        dataSource={props.data}
        pagination={props.pagination}
        onChange={props.onChange}
        columns={columns}
        columnWidh={"50px"}
        isLoading={props.isLoading}
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  panelType: state.user.enterprice.panelType,
});

export default connect(mapStateToProps)(ActivePromoTable);
