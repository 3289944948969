import CategoriesActionTypes from "./categories-action-types";
import axiosConfig from "../../shared/api/axiose"; // baseUrl : http://52.26.241.33:3002

const initialState = {
  modalType: "",
  isVisible: false,
  categories: [],
};

const CategoryData = () => {
  axiosConfig
    .get("/categories")
    .then((res) => {
      console.log("CategoryData", res?.data?.data);
      console.log("CategoryData res", res?.data?.statusCode);
      if (res?.data?.statusCode === 200) {
        return res?.data?.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
const categoriesReducesr = (state = initialState, action) => {
  switch (action.type) {
    case CategoriesActionTypes.GET_CATEGORIES:
      // let catagories_data = CategoryData();
      return {
        // categories : catagories_data,
        // catagories_data,
      };

    case CategoriesActionTypes.SET_CATEGORIES:
      return {
        categories: action?.payload,
      };

    default:
      return state;
  }
};

export default categoriesReducesr;
