import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button, Input } from "antd";
import { toastr } from "react-redux-toastr";

import axios from "../../../../shared/api/axiosConfig";
import Validations from "../../../../shared/validations/Validations";
import {
  Panel,
  CustomModal,
  ErrorMessage,
  Label,
} from "../../../../components/common";
import { numberWithCommas } from "../../../../shared/utils/object-manipulation";
import {
  allValidUnits,
  validUnitCombinations,
  unitNames,
} from "../../../../shared/constants/products";
import "./update-stock-modal.scss";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
  spanImp: "update-inventory-modal__spanImp",
};

const { Option } = Select;

const errText = "Field cannot be empty";

const BUYING_CURRENCY = [
  { type: "INR", name: "₹ INR" },
  { type: "USD", name: "$ USD" },
];

function UpdateStockModal(props) {
  const intl = useIntl();

  const [batchId, changeBatchId] = useState(
    Validations.validateTextField(undefined)
  );
  const [purchasedUnit, changePurchasedUnit] = useState(
    Validations.validateTextField(allValidUnits.PIECES)
  );
  const [overAllCostPrice, changeOverAllCostPrice] = useState(
    Validations.validateNumericValue(null)
  );
  const [actualPrice, changeActualPrice] = useState(
    Validations.validateNumericValue(null)
  );
  const [margin, changeMargin] = useState(
    Validations.validateNumericValue(null)
  );
  const [vat, changeVat] = useState(Validations.validateNumericValue(null));
  const [gst, changeGst] = useState(Validations.validateNumericValue(null));
  const [sellingUnit, changeSellingUnit] = useState(
    Validations.validateTextField(allValidUnits.PIECES)
  );
  const [purchasedStock, changePurchasedStock] = useState(
    Validations.validateNumericValue("")
  );
  const [currency, changeCurrency] = useState(
    Validations.validateTextField(undefined)
  );
  const [isPristine, togglePristine] = useState(true);

  const resetForm = () => {
    togglePristine(true);
    changePurchasedStock(Validations.validateNumericValue(undefined));
    changePurchasedUnit(Validations.validateTextField(allValidUnits.PIECES));
    changeOverAllCostPrice(Validations.validateNumericValue(null));
    changeSellingUnit(Validations.validateTextField(allValidUnits.PIECES));
    changeActualPrice(Validations.validateNumericValue(null));
    changeGst(Validations.validateNumericValue(null));
    changeMargin(Validations.validateNumericValue(null));
    changeVat(Validations.validateNumericValue(null));
    changeCurrency(Validations.validateTextField(undefined));
    changeBatchId(Validations.validateTextField(undefined));
  };

  const setForm = () => {
    togglePristine(true);
    changePurchasedStock(
      Validations.validateNumericValue(props.data.purchasedStock)
    );
    changePurchasedUnit(
      Validations.validateTextField(props.data.purchasedUnit)
    );
    changeOverAllCostPrice(
      Validations.validateNumericValue(props.data.overAllCostPrice)
    );
    changeSellingUnit(Validations.validateTextField(props.data.sellingUnit));
    changeActualPrice(Validations.validateNumericValue(props.data.actualPrice));
    changeGst(Validations.validateNumericValue(props.data.gst));
    changeMargin(Validations.validateNumericValue(props.data.margin));
    changeVat(Validations.validateNumericValue(props.data.vat));
    changeCurrency(Validations.validateTextField(props.data.currency));
    changeBatchId(Validations.validateTextField(props.data.batchId));
  };

  useEffect(() => {
    if (props.isVisible) {
      setForm();
    }
  }, [props.isVisible]);

  const handlePurchasedUnitChange = (data) => {
    changePurchasedUnit(Validations.validateTextField(data));
    changeSellingUnit(
      Validations.validateTextField(validUnitCombinations[data][0])
    );
  };

  const handleSellingUnitChange = (data) => {
    changeSellingUnit(Validations.validateTextField(data));
  };

  const handleCurrencyChange = (value) => {
    changeCurrency(Validations.validateTextField(value));
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const isFormValid = () => {
    let isValid = true;
    isValid =
      isValid &&
      batchId.isValid &&
      purchasedStock.isValid &&
      overAllCostPrice.isValid &&
      currency.isValid;
    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const getFormData = () => {
    const data = {
      batchId: batchId.value,
      purchasedStock: parseInt(purchasedStock.value),
      purchasedUnit: purchasedUnit.value,
      overAllCostPrice: parseInt(overAllCostPrice.value),
      buyingCurrency: currency.value,
      margin: parseInt(margin.value),
      gst: parseInt(gst.value),
      actualPrice: parseInt(actualPrice.value),
      sellingUnit: sellingUnit.value,
    };
    if (data.purchasedUnit === null || data.purchasedUnit === undefined) {
      delete data.purchasedUnit;
    }
    if (data.gst === null || data.gst === undefined) {
      delete data.gst;
    }
    if (data.margin === null || data.margin === undefined) {
      delete data.margin;
    }
    if (data.actualPrice === null || data.actualPrice === undefined) {
      delete data.actualPrice;
    }
    if (data.sellingUnit === null || data.sellingUnit === undefined) {
      delete data.sellingUnit;
    }

    return data;
  };

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "batchId":
        changeBatchId(Validations.validateTextField(value));
        break;
      case "purchasedUnit":
        changePurchasedUnit(Validations.validateTextField(value));
        break;
      case "purchasedStock":
        changePurchasedStock(Validations.validateNumericValue(value));
        break;
      case "overAllCostPrice":
        changeOverAllCostPrice(Validations.validateNumericValue(value));
        break;
      case "actualPrice":
        changeActualPrice(Validations.validateNumericValue(value));
        break;
      case "sellingUnit":
        changeSellingUnit(Validations.validateTextField(value));
        break;
      case "gst":
        changeGst(Validations.validateNumericValue(value));
        break;
      case "margin":
        changeMargin(Validations.validateNumericValue(value));
        break;
      case "vat":
        changeVat(Validations.validateNumericValue(value));
        break;
      default:
        break;
    }
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return;
    } else {
      props.updateStock(getFormData());
    }
  };

  return (
    <CustomModal
      size="tiny"
      onCloseIconClick={props.onCloseIconClick}
      header={`${intl.formatMessage({ id: "app.field.updateStock" })} #${
        props.data.productId
      }`}
      action={
        <>
          <Button type="default" shape={undefined} onClick={closeModal}>
            {intl.formatMessage({ id: "app.field.cancel" })}
          </Button>
          <Button type="primary" shape={undefined} onClick={formAction}>
            {intl.formatMessage({ id: "app.field.submit" })}
          </Button>
        </>
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="app.field.batchId" />
              <Input
                name="batchId"
                onChange={handleFieldChange}
                value={batchId.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.batchId",
                })}
              />
              {!isPristine && batchId.value === undefined && (
                <ErrorMessage field={batchId} />
              )}
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="app.field.purchasedStock" />
              <Input
                name="purchasedStock"
                onChange={handleFieldChange}
                value={purchasedStock.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.purchasedStock",
                })}
              />
              {!isPristine &&
                props.productMapping.purchasedStock.isRequired === 1 && (
                  <ErrorMessage field={purchasedStock} />
                )}
            </div>
            {props.productMapping.purchasedUnit.toBeShown ===1 &&  (<div className={styles.formItem}>
              <Label value="app.field.purchasedUnit" />
              <Select
                placeholder={intl.formatMessage({
                  id: "app.placeholder.purchasedUnit",
                })}
                onChange={handlePurchasedUnitChange}
                value={purchasedUnit.value}
              >
                {(Object.keys(unitNames) || []).map((unit) => (
                  <Option key={unit} value={unit}>
                    {unitNames[unit]}
                  </Option>
                ))}
              </Select>
            </div>)}
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="app.field.costPrice" />
              <Input
                name="overAllCostPrice"
                onChange={handleFieldChange}
                value={overAllCostPrice.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.costPrice",
                })}
              />
              {!isPristine &&
                props.productMapping.costPrice.isRequired === 1 && (
                  <ErrorMessage field={overAllCostPrice} />
                )}
            </div>
            <div className={styles.formItem}>
              <Label value="app.field.currency" />
              <Select
                showSearch
                style={{ marginRight: "2rem", width: "200px" }}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.currency",
                })}
                onChange={handleCurrencyChange}
                value={currency.value}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {BUYING_CURRENCY.map((item, index) => (
                  <Option key={index} value={item.type}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              {!isPristine && currency.value === undefined && (
                <ErrorMessage field={currency} />
              )}
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="app.field.gst" optional />
              <Input
                name="gst"
                onChange={handleFieldChange}
                value={gst.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.gst",
                })}
              />
            </div>
            <div className={styles.formItem}>
              <Label value="app.field.margin" optional />
              <Input
                name="margin"
                onChange={handleFieldChange}
                value={margin.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.margin",
                })}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="app.field.unitSellingPrice" />
              <Input
                name="actualPrice"
                onChange={handleFieldChange}
                value={actualPrice.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.sellingPrice",
                })}
              />
            </div>
            <div className={styles.formItem}>
              <Label value="app.field.sellingUnit" optional />
              <Select
                placeholder={intl.formatMessage({
                  id: "app.placeholder.sellingUnit",
                })}
                onChange={handleSellingUnitChange}
                value={sellingUnit.value}
              >
                {(validUnitCombinations[purchasedUnit.value] || []).map(
                  (unit) => (
                    <Option key={unit} value={unit}>
                      {unitNames[unit]}
                    </Option>
                  )
                )}
              </Select>
            </div>
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default UpdateStockModal;
