import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import {
  Select,
  Dropdown,
  Button,
  Menu,
  DatePicker,
  Tag,
  Checkbox,
  Input,
} from "antd";
import { connect } from "react-redux";
import userActions from "../../redux/user-info/user-actions";
import classNames from "classnames";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../components/common";
import placeholderImg from "../../assets/images/picker-placeholder.png";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiose";
import AddEditUser from "./add-edit-picker/add-edit-picker";
// import "./pickers.scss";
import { checkRtl } from "../../shared/utils/rtl";

import { CustomerData } from "../../FakeData";

const styles = {
  wrapper: "serviceBooking__wrapper",
  stickyRight: "serviceBooking__sticky-right",
  stickyAction: "sticky-action",
  upperMenu: "serviceBooking__upper-menu",
  upperMenuLeft: "serviceBooking__upper-menu__left",
  upperMenuRight: "serviceBooking__upper-menu__right",
  upperMenuDropdown: "serviceBooking__upper-menu__dropdown",
  pageSize: "products__upper-menu__records-per-page",
  image: "serviceBooking__image",
  tableActions: "serviceBooking__table-actions",
  actionIcon: "serviceBooking__action-icon",
  actionIconHidden: "serviceBooking__action-icon--hidden",
  table: "custom-table",
  content: "custom-section",
  menu: "custom-menu",
  tableContainer: "serviceBooking__table-container",
  filterCont: "filterCont",
};

const { Option } = Select;
const { RangePicker } = DatePicker;

function ServiceBooking(props) {
  const intl = useIntl();
  const history = useHistory();
  const [inputSearch, setInputSearch] = useState("");
  const [reasonForCancel, setReasonForCancel] = useState("");
  const [showAllData, setShowAllData] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilter1, setSelectedFilter1] = useState(null);
  const [timeFrameFilterValue, setTimeFrameFilterValue] = useState();
  const [cancellationCharge, setCancellationCharge] = useState(0);
  const [cancelationChargePercentage, setCancelationChargePercentage] =
    useState(0);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [cancelationChargeOption, setCancelationChargeOption] =
    useState("calculate");
  const [search, updateSearch] = useState("");
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    type: "",
  });

  const [searchDelayed, updateSearchDelayed] = useState("");

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const closeModal = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length >= 3) {
      setInputSearch(inputValue);
    } else {
      setInputSearch("");
    }
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };
  useEffect(() => {
    console.log("Updated selectedBookingId:", selectedBookingId);
  }, [selectedBookingId]);

  useEffect(() => {
    axios
      .get("/admins/settings/")
      .then((response) => {
        const cancelationChargePercentage = parseFloat(
          response.data.data.cancelationChargePercentage
        );
        setCancelationChargePercentage(cancelationChargePercentage);
      })
      .catch((error) => {
        console.error("Error fetching cancelationChargePercentage:", error);
      });
  }, []);
  const getCustomersData = () => {
    updateTableControls((prevControls) => ({
      ...prevControls,
      isLoading: true,
    }));

    let params = {
      pageSize: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      search: searchDelayed,
      search: inputSearch !== "" ? inputSearch : undefined,
    };

    if (selectedFilter && selectedFilter !== "ALL") {
      params.bookingStatus = selectedFilter;
    }

    if (selectedFilter1 && selectedFilter1 !== "ALL") {
      params.paymentStatus = selectedFilter1;
    }
    axios
      .get("/bookings", { params })
      .then((response) => {
        const { data } = response.data;
        const { bookings, bookingCount } = data;
        const bookingId = bookings.length > 0 ? bookings[0].id : null;

        // Save the ID in state
        setSelectedBookingId(bookingId);
        console.log("id101", selectedBookingId);

        const processedBookings = bookings.map((booking) => ({
          ...booking,
          bookingType: "Type Value",
        }));

        updateTableControls({
          isLoading: false,
          isEmpty: processedBookings.length === 0,
          data: processedBookings,
          count: bookingCount,
        });

        updatePagination((prevPagination) => ({
          ...prevPagination,
          total: bookingCount,
        }));
      })
      .catch((error) => {
        updateTableControls({
          isLoading: false,
          isEmpty: true,
          data: [],
          count: 0,
        });
      });
  };

  const handleCustomerToggle = (data) => {
    alert("User Block");
  };

  const removeCustomer = (data) => {
    axios.get("/admins/settings/").then();

    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleDeleteCustomer = () => {
    const bookingId = modalControls.data.id;

    let calculatedCancellationCharge = null;

    if (cancelationChargeOption === "calculate") {
      const percentage = parseFloat(cancelationChargePercentage);
      calculatedCancellationCharge =
        (percentage / 100) * modalControls.data.totalServicePayable;
    } else if (cancelationChargeOption === "manual") {
      calculatedCancellationCharge =
        cancellationCharge !== null ? parseFloat(cancellationCharge) : 0;
    }

    const payload = {
      cancelationCharge: calculatedCancellationCharge,
      reasonForCancel: reasonForCancel,
    };

    axios
      .post(`/bookings/cancel/${bookingId}`, payload)
      .then((response) => {
        toast.success(response?.data?.message);
        closeModal();
        getCustomersData();
      })
      .catch((response) => {
        toast.error(response?.data?.message);
      });
  };

  const handleCustomerView = (data) => {
    history.push(`/home/service-booking/details/${data.id}`);
  };

  const handleAddSP = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      isDeleteModalVisible: false,
    });
  };
  const getMenu = (item) => {
    const { bookingStatus } = item;
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            handleCustomerView(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>

        {bookingStatus !== "CANCELLED" && (
          <Menu.Item
            onClick={() => {
              removeCustomer(item);
            }}
          >
            Cancel Booking
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    getCustomersData();
  }, [
    pagination.current,
    pagination.pageSize,
    searchDelayed,
    selectedFilter,
    selectedFilter1,
    inputSearch,
    timeFrameFilterValue?.valueKey,
  ]);

  const handleTableChange = (pag, filt, sorter) => {
    updatePagination({
      ...pag,
    });
  };
  const columns = [
    {
      dataIndex: "id",
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: "left",
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "booking_id",
      title: "Booking Id",
      render: (bookingId) => <Tag color="#5FAAFF">{bookingId}</Tag>,
    },

    {
      title: "Customer Name",
      render: (item) => {
        const { firstName, lastName } = item.customer || {};
        return (
          <span style={{ color: "#1890ff", fontWeight: "600" }}>
            {firstName || lastName ? `${firstName} ${lastName}` : "N/A"}
          </span>
        );
      },
    },

    {
      dataIndex: "bookingStatus",
      title: "Booking Status",
      render: (bookingStatus) => {
        let color = "orange"; // Default color

        if (bookingStatus === "CONFIRMED") {
          color = "#74D95B";
        } else if (bookingStatus === "CANCELLED") {
          color = "#F95050";
        } else if (bookingStatus === "PENDING") {
          color = "Yellow";
        }

        return <Tag color={color}>{bookingStatus ? bookingStatus : "N/A"}</Tag>;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Booking Time",
      render: (createdAt) => {
        const createdAtUTC = new Date(createdAt);
        const localTimeOptions = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        const formattedLocalTime = createdAtUTC.toLocaleString(
          undefined,
          localTimeOptions
        );

        return <Tag color="#2980B9">{formattedLocalTime}</Tag>;
      },
    },

    {
      dataIndex: "modeOfPayment",
      title: "Payment Type",
    },
    {
      dataIndex: "paymentStatus",
      title: "Payment Status",
    },
    {
      dataIndex: "cancelationCharge",
      title: "Cancelation Charge",
      render: (cancelationCharge) => {
        return (
          <Tag color="red">{cancelationCharge ? cancelationCharge : "N/A"}</Tag>
        );
      },
    },
    {
      dataIndex: "reasonForCancel",
      title: "Reason For Cancel ",
      render: (reasonForCancel) => {
        return (
          <Tag color="red">{reasonForCancel ? reasonForCancel : "N/A"}</Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        // debugger;
        return (
          <center>
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          </center>
        );
      },
    },
  ];

  const FIlterList = [
    {
      id: "ALL",
      text: "All",
    },
    {
      id: "CONFIRMED",
      text: "CONFIRMED",
    },

    {
      id: "PENDING",
      text: "PENDING",
    },
    {
      id: "CANCELLED",
      text: "CANCELLED",
    },
  ];

  const filterOption = [
    {
      id: "ALL",
      text: "All",
    },
    {
      id: "PAID",
      text: "PAID",
    },
    {
      id: "FAILED",
      text: "FAILED",
    },
    {
      id: "REFUNDED",
      text: "REFUNDED",
    },
  ];
  const exactAmount =
    (modalControls?.data?.totalServicePayable ?? 0) -
    (modalControls?.data?.paymentDetails?.refundAmount ?? 0);

  const exactCancellationCharge =
    (cancelationChargePercentage * exactAmount) / 100;

  console.log("data101010", exactAmount);
  const handleFilter = (val) => {
    console.log("handleFilter func", val);
    setTimeFrameFilterValue({
      ...timeFrameFilterValue,
      valueKey: val,
    });
  };
  return (
    <div className={styles.wrapper}>
      <h1>{"  "}</h1>

      <AddEditUser
        isVisible={modalControls.isVisible}
        onCloseIconClick={closeModal}
        getCustomersDetail={getCustomersData}
        isLoading={modalControls?.isLoading}
        bookingId={selectedBookingId} // Pass the booking ID as a prop
        modalToShow={modalControls?.modalType}
      />

      <ConfirmModal
        header="Select the Cancellation Charges"
        message={
          <>
            <div>
              <p style={{ marginBottom: "2rem" }}>
                <Checkbox
                  checked={
                    !cancellationCharge &&
                    cancelationChargeOption === "calculate"
                  }
                  onChange={() => {
                    setCancellationCharge(0);
                    setCancelationChargeOption("calculate");
                  }}
                >
                  Calculated Cancellation Charge ({cancelationChargePercentage}%
                  of Total Service Payable)
                </Checkbox>
                {cancelationChargeOption === "calculate" && (
                  <>
                    <div style={{ marginTop: "1rem" }}>
                      <Tag color="blue"> Exact Amount </Tag>
                      <Tag color="Orange">{exactAmount}</Tag>
                    </div>
                    <div>
                      <Tag color="blue"> Cancellation Charge: </Tag>
                      <Tag color="green">
                        {"$"}
                        {exactCancellationCharge}
                      </Tag>
                    </div>
                    <div>
                      <Tag color="blue"> Amount to be Refund: </Tag>
                      <Tag color="purple">
                        {exactAmount - exactCancellationCharge}
                      </Tag>
                    </div>
                  </>
                )}
              </p>

              <div>
                <Checkbox
                  checked={
                    !!cancellationCharge && cancelationChargeOption === "manual"
                  }
                  onChange={() => {
                    setCancellationCharge(null);
                    setCancelationChargeOption("manual");
                  }}
                >
                  Enter Cancellation Charge Manually
                </Checkbox>
                <div
                  style={{
                    marginTop: "1rem",
                  }}
                ></div>
                {cancelationChargeOption === "manual" && (
                  <>
                    <>
                      <div>
                        <div style={{ marginTop: "1rem" }}>
                          <Tag color="blue"> Exact Amount </Tag>
                          <Tag color="Orange">{exactAmount}</Tag>
                        </div>
                        <Tag color="blue"> Cancellation Charge: </Tag>

                        <Tag color="green">
                          {"$"}
                          {cancellationCharge}
                        </Tag>
                      </div>
                      <div style={{ marginBottom: "1rem" }}>
                        <Tag color="blue"> Amount to be Refund: </Tag>
                        <Tag color="purple">
                          {exactAmount - cancellationCharge}
                        </Tag>
                      </div>
                    </>
                    <Input
                      type="number"
                      value={
                        cancellationCharge !== null ? cancellationCharge : ""
                      }
                      style={{ marginBottom: "2rem" }}
                      onChange={(e) => setCancellationCharge(e.target.value)}
                      placeholder="Enter cancellation charge"
                    />
                  </>
                )}
              </div>
            </div>

            <div>
              <Label value="Reason for cancellation" vertical></Label>
              <Input
                value={reasonForCancel}
                onChange={(e) => setReasonForCancel(e.target.value)}
                placeholder="Reason for cancellation"
              />
            </div>
          </>
        }
        onCloseIconClick={closeModal}
        onAction={handleDeleteCustomer}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Cancel Booking"
        isLoading={modalControls.isLoading}
      />

      <Header title="Service Bookings" path="/home/Service Bookings" />

      <div className={styles?.filterCont}>
        {/* Filter by Last 1 day, week, 3 months and 6 months. */}

        <div>
          <Label value="Filter by Booking Status" vertical></Label>
          <Select
            showSearch
            placeholder="Filter by Booking Status"
            style={{ width: "240px" }}
            onChange={(value) => setSelectedFilter(value)}
          >
            {FIlterList.map((obj) => {
              return (
                <Option key={obj?.text} value={obj?.id}>
                  {obj?.text}
                </Option>
              );
            })}
          </Select>
        </div>

        <div>
          <Label value="Filter by Payment Status" vertical></Label>
          <Select
            showSearch
            placeholder="Filter by Booking Status"
            style={{ width: "240px" }}
            onChange={(value) => setSelectedFilter1(value)}
          >
            {filterOption.map((obj) => {
              return (
                <Option key={obj?.text} value={obj?.id}>
                  {obj?.text}
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Label value="Search" vertical></Label>
          <Input
            onChange={handleSearchChange}
            placeholder="Enter Booking Id "
            style={{ width: "240px" }}
          />
        </div>
      </div>

      <div className="custom-section">
        <CustomTable
          isLoading={tableControls.isLoading}
          columns={columns}
          dataSource={tableControls.data}
          isEmpty={tableControls.isEmpty}
          toggleItemBlock={handleCustomerToggle}
          viewItem={handleCustomerView}
          removeItem={removeCustomer}
          pagination={pagination}
          onChange={handleTableChange}
          current={pagination.current}
          pageSize={pagination.pageSize}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(ServiceBooking);
