import userActionTypes from "./user-action-types";
import { setUserName } from "../../shared/utils/local-storage";

const initialState = {
  locale: "en",
  isRtl: false,
  isAddDisabled: false,
  isLoading: true,
  panelLoading: false,
  userInfo: {
    selectedVerticalType: 0,
    verticleName: "",
  },
  admin_name: "",
  enterprice: {
    id: 1,
    adminId: 1,
    isPickupEnabled: false,
    storeId: null,
    enterpriceReferenceId: null,
    androidGoogleMapKey: null,
    iosGoogleMapKey: null,
    logoImage: null,
    alternateSidebarView: 0,
    sidebarLogo: null,
    favIcon: null,
    name: null,
    color: null,
    dashboardMapKey: null,
    customerWebMapKey: null,
    domainName: "",
    branchMapkey: null,
    dailySchedulerThreshold: 4,
    weeklySchedulerThreshold: 4,
    monthlySchedulerThreshold: 5,
    refferedByPoints: 10000,
    vertical: null,
    refferedToPoints: 150000,
    enabledVerticals: "[0,1,2,3,4,5,6]",
    // panel type refers to admin:0, store:1, restaurant(determined at fe): 2
    panelType: null,
    // panel mode refers to vertical, too lazy to change
    panelMode: null,
    dynamicProductFields: {
      priority: {
        toBeShown: 1,
        isRequired: 0,
      },
      image: {
        toBeShown: 1,
        isRequired: 0,
      },
      skuId: {
        toBeShown: 1,
        isRequired: 1,
      },
      name: {
        toBeShown: 1,
        isRequired: 1,
      },
      description: {
        toBeShown: 1,
        isRequired: 0,
      },
      manufacturer: {
        toBeShown: 1,
        isRequired: 0,
      },
      brandId: {
        toBeShown: 0,
        isRequired: 0,
      },
      featureFlag: {
        toBeShown: 1,
        isRequired: 0,
      },
      loyalityPoints: {
        toBeShown: 1,
        isRequired: 0,
      },
      isRedeemable: {
        toBeShown: 1,
        isRequired: 0,
      },
      length: {
        toBeShown: 1,
        isRequired: 0,
      },
      breadth: {
        toBeShown: 1,
        isRequired: 0,
      },
      height: {
        toBeShown: 1,
        isRequired: 0,
      },
      weight: {
        toBeShown: 1,
        isRequired: 0,
      },
      quantity: {
        toBeShown: 0,
        isRequired: 0,
      },
      costPrice: {
        toBeShown: 1,
        isRequired: 1,
      },
      barcode: {
        toBeShown: 1,
        isRequired: 1,
      },
      redemptionPoints: {
        toBeShown: 1,
        isRequired: 0,
      },
      vat: {
        toBeShown: 0,
        isRequired: 0,
      },
      gst: {
        toBeShown: 1,
        isRequired: 1,
      },
      margin: {
        toBeShown: 1,
        isRequired: 0,
      },
      actualPrice: {
        toBeShown: 1,
        isRequired: 0,
      },
      discountedPrice: {
        toBeShown: 1,
        isRequired: 0,
      },
      linkedProductIds: {
        toBeShown: 1,
        isRequired: 0,
      },
      thumbnail: {
        toBeShown: 1,
        isRequired: 0,
      },
      tags: {
        toBeShown: 1,
        isRequired: 0,
      },
      purchasedUnit: {
        toBeShown: 1,
        isRequired: 0,
      },
      sellingUnit: {
        toBeShown: 1,
        isRequired: 0,
      },
      baseValue: {
        toBeShown: 1,
        isRequired: 0,
      },
      purchasedStock: {
        toBeShown: 1,
        isRequired: 0,
      },
      subCategoryIds: {
        toBeShown: 1,
        isRequired: 1,
      },
    },
  },
  googleMapError: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.SET_USER_INFO:
      return {
        ...state,
        isLoading: false,
        userInfo: {
          ...action.payload,
        },
        enterprice: {
          ...state.enterprice,
          storeId: null,
        }
      };

    case userActionTypes.USERNAME:
      setUserName(action.payload);
      return{
        ...state,
        admin_name: action.payload,
      

      } 


    case userActionTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isAddDisabled: action.payload !== "en",
      };

    case userActionTypes.SET_ENTERPRICE_INFO:
      return {
        ...state,
        enterprice: {
          ...state.enterprice,
          ...action.payload,
          dynamicProductFields: {
            ...state.enterprice.dynamicProductFields,
            ...action.payload.dynamicProductFields,
          },
        },
      };
    case userActionTypes.CHANGE_STORE:
      return {
        ...state,
        enterprice: {
          ...state.enterprice,
          storeId: action.payload === "" ? null : action.payload,
        },
      };

    case userActionTypes.SET_GOOGLE_MAP_ERROR:
      return {
        ...state,
        googleMapError: true,
      };

    case userActionTypes.SET_PANEL_TYPE:
      return {
        ...state,
        panelType: parseInt(action.payload),
        enterprice: {
          ...state.enterprice,
          panelType: parseInt(action.payload)
        }
      };

    case userActionTypes.START_LOADING:
      return {
        ...state,
        panelLoading: true,
      };
    case userActionTypes.STOP_LOADING:
      return {
        ...state,
        panelLoading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
