import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  CustomImage,
} from "../../../components/common";
import { Tag } from "antd";
import "./view-user.scss";

import sidebarData from "../../../shared/constants/sidebar-data.json";
import axiosConfig from "../../../shared/api/axios_config";
import moment from "moment";
import { Rate } from "antd";

const styles = {
  wrapper: "view-user__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  label: "view-user__label",
  value: "view-user__value",
  row: "view-user__row",
};
function ViewUser(props) {
  const intl = useIntl();
  console.log("Customer Review=>", props.data);
  useEffect(() => {
    // if (props.isVisible) {
    //   addTitleToRoles(props.data.roles);
    // }
    if (props?.data?.id) {
      // getAdminDetails(props?.data?.id);
    }
  }, [props?.data?.id]);
  const [allRoles, updateAllRoles] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  });

  const [rolesArr, setRolesArr] = useState([]);

  console.log("rolesArr=>", rolesArr);

  const addStatusToSidebarRoles = (lsRoles) => {
    lsRoles = lsRoles.reduce((acc, val) => {
      if (val.status) {
        acc.push(val.menuId);
      }
      return acc;
    }, []);
    return sidebarData.map((i) => {
      if (lsRoles.indexOf(i.menuId) !== -1) {
        i.status = 1;
      } else {
        i.status = 0;
      }
      return i;
    });
  };

  const getFilteredRoles = (roles, sendAll, onlyActive) => {
    return roles.reduce(
      (acc, val) => {
        if (
          (val.menuId == 115 || val.menuId == 15) &&
          !props.goodFleetEnabled
        ) {
          return acc;
        }
        if (!props.pickerFlow && val.menuId == 8) {
          return acc;
        }
        if (onlyActive) {
          if (val.status == 1) {
            if (sendAll) {
              acc[val.verticalType].push(val);
            } else {
              acc[val.verticalType].push(val.menuId);
            }
          }
        } else {
          if (sendAll) {
            acc[val.verticalType].push(val);
          } else {
            acc[val.verticalType].push(val.menuId);
          }
        }
        return acc;
      },
      { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
    );
  };
  // const addTitleToRoles = (lsRoles) => {
  //   const alamanac = [...sidebarData, ].reduce((acc, val) => {
  //     acc[val.menuId] = val;
  //     return acc;
  //   }, {});

  //   const res = lsRoles.filter(i => alamanac[i.menuId] !== undefined).map((i) => ({
  //     ...i,
  //     name: alamanac[i.menuId].name,
  //   }));
  //   // updateAdminRoles(res);
  //   // debugger;
  //   updateAllRoles(getFilteredRoles(res, true, true));
  //   return res;
  // };
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={props.onCloseIconClick}
      header="Customer Review Details"
      isVisible={props.isVisible}
    >
      {/* {
        1.Customer name
      2.Customer Contact no
      3.Order No
      4.Service date
      5.Review Date
      6.Service Provider Name
      7.Business Name
      8.Category
      9.Service
      10.Country (SP)
      11.State (SP)
      12.City (SP)
      13.Zip Code (SP)
      14.Ratings
      15.Reviews
      16.Tags
      } */}
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.wrapper}>
          <CustomImage src={props?.data?.serviceImg} />
          <div className={styles.row}>
            <div className={styles.label}>Reviews Title</div>
            <div className={styles.value}>
              <Tag>{props?.data?.title ? props?.data?.title : "Not Found"}</Tag>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}> Review Content</div>
            <div className={styles.value}>
              <Tag>
                {props?.data?.content ? props?.data?.content : "Not Found"}
              </Tag>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Ratings</div>
            <div className={styles.value}>
              {
                <Rate
                  style={{ color: "#ED432A" }}
                  disabled
                  value={props?.data?.rating}
                />
              }
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Customer Name</div>
            <div className={styles.value}>
              <Tag>
                {props?.data?.customerName
                  ? props?.data?.customerName
                  : "Not Found"}
              </Tag>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Review Date</div>
            <div className={styles.value}>
              <Tag color="Orange">
                {" "}
                {props?.data?.createdAt
                  ? moment(props?.data?.createdAt).format("MM/DD/YY")
                  : "Not Found"}
              </Tag>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Booking Id </div>
            <div className={styles.value}>
              {" "}
              <Tag color="purple">{props?.data?.booking_id}</Tag>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Service Name</div>
            <div className={styles.value}>
              {" "}
              <Tag color="purple"> {props?.data?.serviceTitle}</Tag>{" "}
            </div>
          </div>
          {props?.data?.reject_reason && (
            <div className={styles.row}>
              <div className={styles.label}>Reject Reason</div>
              <div className={styles.value}>
                <Tag color="purple"> {props?.data?.reject_reason}</Tag>
              </div>
            </div>
          )}
          <div className={styles.row}>
            <div className={styles.label}>Booking Slot Date</div>
            <div className={styles.value}>
              <Tag color="blue">
                {props?.data?.bookingSlotDate
                  ? moment(props?.data?.bookingSlotDate).format("MM/DD/YY")
                  : "Not Found"}
              </Tag>
            </div>
          </div>
          {/* <div className={styles.row}>
            <div className={styles.label}>
              
              Service
            </div>
            <div className={styles.value}>
              {props?.data?.service ? props?.data?.service : "Not Found"}
            </div>
          </div> */}

          {/* <div className={styles.row}>
            <div className={styles.label}>
              
              Title
            </div>
            <div className={styles.value}>
              {props?.data?.title ? props?.data?.title : "Not Found"}
            </div>
          </div> */}
          {/* <div className={styles.row}>
            <div className={styles.label}>
              
              Rating
            </div>
            <div className={styles.value}>
             
              {
                <Rate
                  style={{ color: "#ED432A" }}
                  disabled
                  value={props?.data?.rating}
                />
              }
            </div>
          </div> */}
          {/* 
          <div className={styles.row}>
            <div className={styles.label}>Created At</div>

            <div className={styles.value}>
              {props?.data?.createdAt
                ? moment(props?.data?.createdAt).format("MM/DD/YY")
                : "Not Found"}
            </div>
          </div> */}
          {/* <div className={styles.row}>
            <div className={styles.label}>Updated At</div>

            <div className={styles.value}>
              {props?.data?.updatedAt
                ? moment(props?.data?.updatedAt).format("MM/DD/YY")
                : "Not Found"}
            </div>
          </div> */}
          {/* {
            props.enabledVerticals ? props.enabledVerticals.map((ev) => {
              return(
                <div>
                  {ev == 0 && (
                    <div className={styles.row}>
                      <div className={styles.label}>
                        {intl.formatMessage({ id: "app.field.groceryPanelPrivilages" })}
                      </div>
                    </div>
                  )}
                  {ev == 0 && (
                    <div>
                    {allRoles[0].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
        
                    {allRoles[0].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev == 1 && (
                    <div className={styles.row}>
                      <div className={styles.label}>
                        {intl.formatMessage({ id: "app.field.foodPanelPrivilages" })}
                      </div>
                    </div>
                  )}
                  {ev ==1 && (
                    <div>
                    {allRoles[1].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[1].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev ==2 && (
                    <div className={styles.row}>
                      <div className={styles.label}>
                        {intl.formatMessage({
                          id: "app.field.conveniencePanelPrivilages",
                        })}
                      </div>
                    </div>
                  )}
                  {ev ==2 && (
                    <div>
                    {allRoles[2].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[2].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev ==3 && (
                    <div className={styles.row}>
                      <div className={styles.label}>
                        {intl.formatMessage({ id: "app.field.medicinePanelPrivilages" })}
                      </div>
                    </div>
                  )}
                  {ev ==3 && (
                    <div>
                    {allRoles[3].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[3].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev ==4 && (
                    <div className={styles.row}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: "app.field.liquorPanelPrivilages" })}
                    </div>
                  </div>
                  )}
                  {ev ==4 && (
                    <div>
                    {allRoles[4].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[4].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev ==5 && (
                    <div className={styles.row}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: "app.field.smallPanelPrivilages" })}
                    </div>
                  </div>
                  )}
                  {ev ==5 && (
                    <div className={styles.row}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: "app.field.smallPanelPrivilages" })}
                    </div>
                  </div>
                  )}
                  {ev ==5 && (
                    <div>
                    {allRoles[5].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[5].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev == 6 && (
                    <div className={styles.row}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: "app.field.largePanelPrivilages" })}
                    </div>
                  </div>
                  )}
                  {ev == 6 && (
                    <div>
                    {allRoles[6].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[6].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                </div>
              )
            }):""
          } */}
        </div>
      </Panel>
    </CustomModal>
  );
}

export default ViewUser;
