import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select, Divider, Space } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";

import classNames from "classnames";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../../components/common";
import axiosConfig from "../../../../shared/api/axiose";
import Validations from "../../../../shared/validations/Validations";

import "./add-edit-promo-modal.scss";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formModal_wrapper: "Questionary_add_edit_modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "Questionary_add_edit_modal__image-container",
  imageContainerImage: "Questionary_add_edit_modal__image-container__image",
  imageContainerForm: "Questionary_add_edit_modal__image-container__form",
  spanImp: "Questionary_add_edit_modal__span-img",
  disabledColor: "disabledColor",
  options_Cont: "options_Cont",
  grid_item: "grid_item",
  optionInputField: "optionInputField",
};

const errorText = "Field cannot be empty";

const Tags = [
  {
    value: "1",
    text: "Cleaning",
  },
  {
    value: "2",
    text: "Service provider",
  },
  {
    value: "3",
    text: "Customer",
  },
  {
    value: "4",
    text: "Enquire ",
  },
  {
    value: "5",
    text: "Document",
  },
  {
    value: "6",
    text: "PDF",
  },
  {
    value: "7",
    text: "Washing",
  },
  {
    value: "8",
    text: "Testing Tags",
  },
];
const deleteIcon = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2504 0.880326L0.877515 17.2846C0.392877 17.7702 0.392669 18.5572 0.87705 19.0426C1.36143 19.5279 2.14698 19.5277 2.63162 19.0421L19.0045 2.6378C19.4891 2.15223 19.4893 1.36517 19.0049 0.879862C18.5205 0.39455 17.735 0.394758 17.2504 0.880326Z"
      fill="#C4C4C4"
    />
    <path
      d="M19.0052 17.2835L2.6324 0.879201C2.14776 0.393631 1.36221 0.393423 0.877832 0.878735C0.393451 1.36405 0.393659 2.1511 0.878297 2.63667L17.2511 19.041C17.7358 19.5265 18.5213 19.5267 19.0057 19.0414C19.4901 18.5561 19.4899 17.7691 19.0052 17.2835Z"
      fill="#C4C4C4"
    />
  </svg>
);
function AddEditQuestion({
  isVisible,
  modalType,
  onCloseIconClick,
  data,
  editCategory,
  isLoading,
  getQuestionasData,
  questionType,
  UpdateForms,
  SetFAQ_question,
  FAQquestion,
  formData,

  ...props
}) {
  /** Instaservice States */
  const intl = useIntl();
  const [forms, setForms] = useState([{}]);
  const [FAQ_question, updateFAQ_question] = useState([{}]);

  const handlSetFAQ = () => {
    updateFAQ_question(FAQquestion);
    setForms(formData);
  };
  useEffect(() => {
    handlSetFAQ();
  }, [isVisible, data]);
  const [count, setCount] = useState(1);

  const [isPristine, togglePristine] = useState(true);
  const [tagSelect, updateTagSelect] = useState(
    Validations.validateMultiSelect([])
  );

  const [question, updateQuestion] = useState(
    Validations.validateTextField(undefined)
  );
  const [inputOption, updateInputOption] = useState(
    Validations.validateTextField(undefined)
  );
  const [question_Type, updateQuestionType] = useState(
    Validations.validateTextField(undefined)
  );

  const [allTags, setAllTages] = useState(Tags);
  const [addTageName, setaddTageName] = useState("");

  /** Instaservice States */

  const onNameChange = (event) => {
    setaddTageName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    let tagobj = {
      value: allTags.length + 1,
      text: addTageName,
    };

    setAllTages([...allTags, tagobj]);

    setaddTageName("");
  };

  const resetForm = () => {
    setAllTages(Tags);
    setaddTageName("");
    updateQuestionType(Validations.validateTextField(undefined));
    updateInputOption(Validations.validateTextField(undefined));
    updateQuestion(Validations.validateTextField(undefined));
    updateTagSelect(Validations.validateMultiSelect([]));
    togglePristine(true);
  };

  const setForm = () => {
    updateQuestionType(Validations.validateTextField(data?.questionType));
    updateInputOption(Validations.validateTextField(data?.inputOptions));
    updateQuestion(Validations.validateTextField(data?.question));
    let tagList = data?.tag.split(" , ");
    updateTagSelect(Validations.validateMultiSelect([...tagList]));
  };

  const handleAdd = () => {
    if (modalType === "FAQ") {
      if (!isFAQFormValied()) {
        toastr.warning(
          "Please check for errors in the fields filled for the form"
        );
        return;
      }
      SetFAQ_question(FAQ_question);
    } else {
      if (!isQuestionFormValied()) {
        toastr.warning(
          "Please check for errors in the fields filled for the form"
        );
        return;
      }
      UpdateForms(forms);
    }

    onCloseIconClick();
  };

  const isFormValid = () => {
    let isValid = true;

    isValid =
      isValid &&
      question.isValid &&
      tagSelect.isValid &&
      question_Type.isValid &&
      inputOption?.isValid;

    return isValid;
  };

  const closeModal = () => {
    onCloseIconClick();
    resetForm();
  };

  const formAction = () => {
    togglePristine(false);

    handleAdd();
  };

  const handleTagsSelect = (value) => {
    updateTagSelect(Validations.validateMultiSelect(value));
  };

  const handlequestionType = (index, value) => {
    const values = [...forms];
    values[index]["questionType"] = value;
    if (
      values[index]["questionType"] === "drop-down" ||
      values[index]["questionType"] === "radio-button"
    ) {
      values[index]["inputValue"] = [{ title: "" }];
    } else {
      delete values[index]["inputValue"];
    }
    setForms(values);

    // updateQuestionType(Validations.validateTextField(value));
  };

  const handleAddInputField = (index) => {
    const values = [...forms];

    values[index]["inputValue"] = [
      ...values[index]["inputValue"],
      { title: "" },
    ];
    setForms(values);
  };

  const handleInputOption = (value) => {
    updateInputOption(Validations.validateTextField(value));
  };

  const handleQuestion = (val) => {
    updateQuestion(Validations.validateTextField(val));
  };
  const handleInputChange = (index, event) => {
    const values = [...forms];

    values[index][event.target.name] = event.target.value;

    setForms(values);
  };

  const handleInputOptionChange = (index, optionIdx, event) => {
    const values = [...forms];
    if (
      values[index]["questionType"] === "drop-down" ||
      values[index]["questionType"] === "radio-button"
    ) {
      values[index]["inputValue"][optionIdx]["title"] = event.target.value;
    }

    setForms(values);
  };
  const handlePriceMultiplier = (index, optionIdx, event) => {
    const values = [...forms];
    if (
      values[index]["questionType"] === "drop-down" ||
      values[index]["questionType"] === "radio-button"
    ) {
      values[index]["inputValue"][optionIdx]["price"] = event.target.value;
    }

    setForms(values);
  };

  const isOptionValidation = () => {};
  const handleInputChange_faq = (index, event) => {
    const values = [...FAQ_question];
    values[index][event.target.name] = event.target.value;
    values[index]["serialNo"] = index + 1;

    updateFAQ_question(values);
  };
  const handleAddForm = () => {
    if (count < 10) {
      setForms([...forms, {}]); // add an empty form to the forms array
      setCount(count + 1); // increment the count of forms
    }
  };
  const handleAddFAQ = () => {
    if (count < 10) {
      updateFAQ_question([...FAQ_question, {}]);
      setCount(count + 1);
    }
  };
  const isQuestionFormValied = () => {
    let valid = true; // Corrected variable declaration

    forms.forEach((obj, index) => {
      // Changed to forEach for iteration
      // debugger
      if (
        !obj?.question ||
        !obj?.questionType ||
        // isNaN(obj?.priceMultiplier) ||
        // obj?.priceMultiplier <= 0 ||
        (obj?.inputValue && !obj.inputValue.some((item) => item?.title !== ""))
      ) {
        valid = false; // Corrected variable spelling
      }
      // veliedOption(index);
    });
    return valid;
  };

  const veliedOption = (index) => {
    let valied = true;
    valied = forms[index].inputValue.some((obj) => obj?.title !== "");
    // setoptionValidation([...optionValidation , { index :  valied}]);

    return valied;
  };

  const isFAQFormValied = () => {
    let valied = true;
    FAQ_question.map((obj) => {
      if (!obj?.question || !obj?.answer) {
        valied = false;
      }
    });

    return valied;
  };
  const handleDeleteFAQ = (index) => {
    let updateFAQ = [...FAQ_question];
    updateFAQ.splice(index, 1);
    updateFAQ_question(updateFAQ);
  };
  const handleDeleteQue = (index) => {
    let formData = [...forms];
    formData.splice(index, 1);
    setForms(formData);
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        modalType === "FAQ"
          ? "Add FAQ"
          : modalType === "QUESTIONNAIRE"
          ? "ADD Question"
          : ""
      }
      action={
        modalType !== "VIEW" ? (
          <>
            {" "}
            <Button type="default" shape={undefined} onClick={closeModal}>
              {intl.formatMessage({ id: "app.button.cancel" })}
            </Button>
            <Button
              // loading={isLoading}
              type="primary"
              shape={undefined}
              onClick={formAction}
            >
              {intl.formatMessage({ id: "app.button.submit" })}
            </Button>
          </>
        ) : (
          ""
        )
      }
      isVisible={isVisible}
    >
      <Panel
        isModal
        className={classNames({
          [styles.formModal]: true,
          [styles.formModal_wrapper]: true,
        })}
      >
        {modalType === "QUESTIONNAIRE"
          ? forms.map((form, index) => {
              return (
                <div key={index}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {index > 0 && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteQue(index)}
                        >
                          {deleteIcon}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <Label value={`Question ${index + 1}`} />

                      <Input.TextArea
                        name="question"
                        placeholder="Question"
                        value={forms[index]?.question}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                      {!isPristine && !forms[index]?.question && (
                        <ErrorMessage text={errorText} />
                      )}
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <Label value="Question Type" />
                      <Select
                        placeholder="Question Type"
                        onChange={(val) => handlequestionType(index, val)}
                        value={forms[index]?.questionType}
                      >
                        {questionType.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.text}
                          </Option>
                        ))}
                      </Select>
                      {!isPristine && !forms[index]?.questionType && (
                        <ErrorMessage text={errorText} />
                      )}
                    </div>
                  </div>
                  {forms[index]?.questionType === "drop-down" ||
                  forms[index]?.questionType === "radio-button" ? (
                    <div className={styles.options_Cont}>
                      {forms[index]?.inputValue.map((obj, idx) => {
                        return (
                          <>
                            <div
                              key={obj}
                              className={classNames({
                                [styles.grid_item]: true,
                                [styles.formItem]: true,
                              })}
                            >
                              <Label value="Option Field" />
                              <Input
                                name={`option_${idx + 1}`}
                                placeholder="Enter Option"
                                value={forms[index]?.inputValue[idx].title}
                                onChange={(event) =>
                                  handleInputOptionChange(index, idx, event)
                                }
                              />
                            </div>
                            <div className={styles.formItem}>
                              <Label value="Price Multipler" />
                              <Input
                                name="priceMultiplier"
                                placeholder="Enter Price Multiplier"
                                type="number"
                                value={forms[index]?.inputValue[idx].price}
                                onChange={(event) =>
                                  handlePriceMultiplier(index, idx, event)
                                }
                              />
                            </div>
                          </>
                        );
                      })}
                      {forms[index]?.inputValue.length <= 5 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            padding: "1rem 0",
                          }}
                        >
                          <div
                            onClick={() => handleAddInputField(index)}
                            className={styles.optionInputField}
                          >
                            +
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {!isPristine &&
                        (forms[index]?.questionType === "drop-down" ||
                          forms[index]?.questionType === "radio-button") &&
                        forms[index]?.inputValue.length > 0 &&
                        !veliedOption(index) && (
                          <ErrorMessage
                            text={"Please Fill atleast one option"}
                          />
                        )}
                    </div>
                  ) : (
                    ""
                  )}

                  <hr />
                </div>
              );
            })
          : ""}

        {modalType === "FAQ"
          ? FAQ_question.map((obj, index) => {
              return (
                <div key={index}>
                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Label value={`Question ${index + 1}`} />
                        {index > 0 && (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteFAQ(index)}
                          >
                            {deleteIcon}
                          </span>
                        )}
                      </div>
                      <Input.TextArea
                        name="question"
                        placeholder="Question"
                        value={FAQ_question[index]?.question}
                        onChange={(event) =>
                          handleInputChange_faq(index, event)
                        }
                      />
                      {!isPristine && !FAQ_question[index]?.question && (
                        <ErrorMessage text={errorText} />
                      )}
                    </div>
                  </div>
                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <Label value="Answer" />
                      <Input.TextArea
                        name="answer"
                        placeholder="Answer"
                        value={FAQ_question[index]?.answer}
                        onChange={(event) =>
                          handleInputChange_faq(index, event)
                        }
                      />
                      {!isPristine && !FAQ_question[index]?.answer && (
                        <ErrorMessage text={errorText} />
                      )}
                    </div>
                  </div>

                  <hr />
                </div>
              );
            })
          : ""}

        {modalType === "QUESTIONNAIRE" && count < 10 && (
          <Button type="primary" onClick={handleAddForm}>
            Add Question
          </Button>
        )}
        {modalType === "FAQ" && count < 10 && (
          <Button type="primary" onClick={handleAddFAQ}>
            Add FAQ
          </Button>
        )}
      </Panel>
    </CustomModal>
  );
}
const mapState = (state) => ({
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
});
export default connect(mapState)(AddEditQuestion);
