import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { Select, Dropdown, Button, Menu, DatePicker, Tag, Input } from "antd";
import { connect } from "react-redux";
import userActions from "../../redux/user-info/user-actions";
import classNames from "classnames";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../components/common";
import placeholderImg from "../../assets/images/picker-placeholder.png";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiose";
import AddEditUser from "./add-edit-picker/add-edit-picker";
// import "./pickers.scss";
import { checkRtl } from "../../shared/utils/rtl";

// import { CustomerData } from "../../FakeData";

const styles = {
  wrapper: "serviceBooking__wrapper",
  stickyRight: "serviceBooking__sticky-right",
  stickyAction: "sticky-action",
  upperMenu: "serviceBooking__upper-menu",
  upperMenuLeft: "serviceBooking__upper-menu__left",
  upperMenuRight: "serviceBooking__upper-menu__right",
  upperMenuDropdown: "serviceBooking__upper-menu__dropdown",
  pageSize: "products__upper-menu__records-per-page",
  image: "serviceBooking__image",
  tableActions: "serviceBooking__table-actions",
  actionIcon: "serviceBooking__action-icon",
  actionIconHidden: "serviceBooking__action-icon--hidden",
  table: "custom-table",
  content: "custom-section",
  menu: "custom-menu",
  tableContainer: "serviceBooking__table-container",
  filterCont: "filterCont",
};

const { Option } = Select;
const { RangePicker } = DatePicker;

function BookingPayments(props) {
  const intl = useIntl();
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [timeFrameFilterValue, setTimeFrameFilterValue] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [selectedFliterId, setSelectedFilterId] = useState("booking_id");
  const [search, updateSearch] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    type: "",
  });

  const [searchDelayed, updateSearchDelayed] = useState("");

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const closeModal = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };
  // MountConfirmModal

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input value has at least 3 characters
    if (inputValue.length >= 3) {
      setInputSearch(inputValue);
    } else {
      setInputSearch("");
    }
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };
  const getTagColor = (value) => {
    if (value === "CARD" || value === "CONFIRMED") {
      return "green";
    } else if (value === "CASH" || value === "PENDING") {
      return "blue";
    } else if (value === "PAID") {
      return "yellow";
    } else if (value === "REFUNDED") {
      return "orange";
    } else if (value === "FAILED") {
      return "red";
    } else {
      return "default";
    }
  };

  const getCustomersData = async () => {
    try {
      updateTableControls({
        ...tableControls,
        isLoading: true,
      });
      let response;
      if (selectedFilter === "ALL") {
        response = await axios.get("/payments");
      } else {
        const queryParams = {
          paymentStatus: selectedFilter,
          searchBy: selectedFliterId !== "" ? selectedFliterId : undefined,
          searchString: inputSearch !== "" ? inputSearch : undefined,
        };
        response = await axios.get("/payments", { params: queryParams });
      }

      const customerData = response.data.data.payments;

      updateTableControls({
        ...tableControls,
        isLoading: false,
        data: customerData,
        count: customerData.length,
      });
    } catch (error) {
      console.error("Error fetching customer data:", error);
      updateTableControls({
        ...tableControls,
        isEmpty: true,
        isLoading: false,
        data: [],
        count: 0,
      });
    }
  };

  const handleCustomerToggle = (data) => {
    alert("User Block");
  };

  const removeCustomer = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleDeleteCustomer = () => {
    alert("Delete Customer");
  };

  const handleCustomerView = (data) => {
    console.log("handleCustomerView => ");
    history.push(`/home/payment-booking/details/${data?.id}`);
    // alert("View Details")
  };

  const handleAddSP = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      isDeleteModalVisible: false,
    });
  };
  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            handleCustomerView(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>
        {/* <Menu.Item
          onClick={() => {
            handleAddSP(item);
          }}
        >
          Add Service Provider
        </Menu.Item> */}
        {/* 
        <Menu.Item
          onClick={() => {
            removeCustomer(item);
          }}
        >
          Delete payment
        </Menu.Item> */}
      </Menu>
    );
  };

  useEffect(() => {
    getCustomersData();
  }, [
    pagination.current,
    pagination.pageSize,
    searchDelayed,
    selectedFilter,
    selectedFliterId,
    inputSearch,
  ]);

  const handleTableChange = (pag, filt, sorter) => {
    updatePagination({
      ...pag,
    });
  };
  const columns = [
    {
      dataIndex: "id",
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: "left",
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },

    {
      title: "Booking id",
      dataIndex: "booking_id",
      render: (booking_id) => (
        <Tag color="#5FAAFF" style={{ width: "6rem" }}>
          {booking_id || "N/A"}
        </Tag>
      ),
    },
    {
      dataIndex: "transactionId",
      title: "Transaction Id",

      render: (transactionId) => (
        <Tag color="purple">
          {transactionId
            ? `${transactionId.substring(0, 15)}...`
            : "Not Present"}
        </Tag>
      ),
    },
    {
      title: "Username",
      dataIndex: ["customer", "username"],

      render: (username) => (
        <Tag color="#2980B9" style={{ width: "9rem" }}>
          {username || "N/A"}
        </Tag>
      ),
    },
    {
      title: "Customer Name",
      render: (item) => {
        const { firstName, lastName } = item.customer || {};
        return (
          <span style={{ color: "#1890ff", fontWeight: "600" }}>
            {firstName || lastName ? `${firstName} ${lastName}` : "N/A"}
          </span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: ["customer", "email"],
      render: (email) => email || "N/A",
    },

    {
      title: "Phone Number",
      dataIndex: ["customer", "phoneNo"],
      render: (phoneNo) => phoneNo || "N/A",
    },

    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      render: (paymentStatus) => {
        let tagColor = "#FF0000";
        let tagText = "N/A";
        if (paymentStatus === "PAID") {
          tagColor = "#74D95B";
          tagText = "PAID";
        } else if (paymentStatus === "REFUNDED") {
          tagColor = "#499555";
          tagText = "REFUNDED";
        }

        return (
          <Tag style={{ width: "6rem" }} color={tagColor}>
            {tagText}
          </Tag>
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Refund Amount",
      dataIndex: "refundAmount",
      render: (refundAmount) => (
        <Tag color={refundAmount ? "#74D95B" : "#F95050"}>
          {refundAmount ? refundAmount : "--"}
        </Tag>
      ),
    },
    // ... (other columns)
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        return (
          <center>
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          </center>
        );
      },
    },
  ];

  const FIlterList = [
    {
      id: "ALL",
      text: "All",
    },
    {
      id: "PAID",
      text: "Paid",
    },
    {
      id: "FAILED",
      text: "Failed",
    },
    {
      id: "REFUNDED",
      text: "Refunded",
    },
  ];
  const filterOption = [
    {
      id: "booking_id",
      text: "Booking ID",
    },
    {
      id: "transactionId",
      text: "Transaction ID",
    },
  ];
  const handleFilter = (val) => {
    console.log("handleFilter func", val);
    setTimeFrameFilterValue({
      ...timeFrameFilterValue,
      valueKey: val,
    });
  };
  return (
    <div className={styles.wrapper}>
      <h1>{"  "}</h1>

      <AddEditUser
        isVisible={modalControls.isVisible}
        onCloseIconClick={closeModal}
        getCustomersDetail={getCustomersData}
        isLoading={modalControls?.isLoading}
        customerId={modalControls?.customerId}
        modalToShow={modalControls?.modalType}
      />

      <ConfirmModal
        header={`Delete ${modalControls?.data?.firstName}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={closeModal}
        onAction={handleDeleteCustomer}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      <Header title="Payments" path="/home/Payments" />

      <div className={styles?.filterCont}>
        <div className={styles.lowerMenuDropdown}>
          <div>
            <Label value="Seach by Payment Status" vertical></Label>
            <Select
              showSearch
              placeholder="Payment Status"
              style={{ width: "240px" }}
              onChange={(value) => setSelectedFilter(value)}
              value={selectedFilter}
            >
              {FIlterList.map((obj) => (
                <Option key={obj.id} value={obj.id}>
                  {obj.text}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="custom-section">
        <div style={{ padding: "1.5rem" }}>
          <Label value="Filter By" vertical />
          <Select
            style={{ width: "240px" }}
            onChange={(value) => setSelectedFilterId(value)}
            value={selectedFliterId}
          >
            {filterOption.map((item, index) => (
              <Option key={index} value={item.id}>
                {item?.text}
              </Option>
            ))}
          </Select>
          <Input
            style={{ width: "240px", marginLeft: "1rem" }}
            onChange={handleSearchChange}
            placeholder={
              selectedFliterId === "booking_id"
                ? "Enter Booking ID"
                : "Enter Transaction ID"
            }
          />
        </div>

        <CustomTable
          isLoading={tableControls.isLoading}
          columns={columns}
          dataSource={tableControls.data}
          isEmpty={tableControls.isEmpty}
          // dataSource={tableControls.data}
          toggleItemBlock={handleCustomerToggle}
          viewItem={handleCustomerView}
          removeItem={removeCustomer}
          pagination={pagination}
          onChange={handleTableChange}
          current={pagination.current}
          pageSize={pagination.pageSize}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(BookingPayments);
