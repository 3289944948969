import React, {useEffect, useRef} from 'react';
import { Placeholder } from '../common';
// import {Placeholder} from '../common'

import {connect} from 'react-redux';
//import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import './google-map.scss';

import { GoogleMap, LoadScript, DrawingManager } from '@react-google-maps/api';
const containerStyle = {
  width: '100%',
  height: '100%'
};
function CustomGoogleMap(props) {
  
  if(props?.error) {
    return <Placeholder type="empty" text="Google Maps Error Occured. Please Check your API Keys and Quota" />
  }
  if(window.google === undefined || window.google === null) {
    return <Placeholder type="loading" />
  }
  return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={props.zoom}
        center={props.center}
        onLoad={props.onLoad}
      >
        <DrawingManager 
        onLoad={props.drawingManagerOnLoad}
        onPolygonComplete={props.onPolygonComplete}
        onOverlayComplete={props.onOverlayComplete}
        options={{
          drawingControl: props.drawingControl,
          drawingControlOptions: {
            position: window.google?.maps?.ControlPosition.BOTTOM_CENTER,
            drawingModes: ['polygon'],
          },
          polygonOptions: {
            fillColor: props.polygonFillColor || props.color,
            fillOpacity: 0.5,
            strokeWeight: 5,
            clickable: true,
            editable: true,
            draggable: true,
            zIndex: 1
          }
        }}
        />
        {props.children}
      </GoogleMap>
  )

}
CustomGoogleMap.defaultProps = {
  center: {
    lat: 0,
    lng: 0,
  },
  drawingManagerOnLoad: () => {},
  zoom: 2,
}
const mapStateToProps = (state) => ({
  error: state.user.googleMapError,
  color: state.user.enterprice.color,
})
export default connect(mapStateToProps)(CustomGoogleMap)




