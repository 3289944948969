import React, { useState, useEffect } from "react";
import axios from "../../../../shared/api/axiose";
import { useIntl } from "react-intl";
import moment from "moment";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import numeral from "numeral";
import { Panel } from "../../../../components/common";
import {
  abbreviateNumber,
  numberWithCommas,
} from "../../../../shared/utils/object-manipulation";
import "./yearly-chart.scss";
import { Select, DatePicker, Button } from "antd";

const styles = {
  wrapper: "dashboard__yearly-chart__wrapper",
  header: "dashboard__yearly-chart__header",
  containerClass: "dashboard__yearly-chart__container-class",
  transFix: "custom-trans-fix",
  filterCont: "dashboard__yearly-chart__filterCont",
};

function YearlyChart({ header, ...props }) {
  const [timeFrameFilterValue, setTimeFrameFilterValue] = useState(1);
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [customRange, setCustomRange] = useState({
    startDate: "2023-07-24",
    endDate: "2023-07-30",
  });

  const intl = useIntl();
  const [chartData, updateChartData] = useState([]);
  const formatData = (yearChart) => {
    // Get the current month and year
    const currentMonth = moment().format("MM");
    const currentYear = moment().format("YYYY");

    const hasCurrentMonthData = yearChart.some(
      (item) =>
        moment(item.year).format("MM") === currentMonth &&
        moment(item.year).format("YYYY") === currentYear
    );

    if (!hasCurrentMonthData) {
      yearChart.push({
        year: moment(`${currentYear}-${currentMonth}-01`).format("YYYY-MM-DD"),
        Sales: 0,
      });
    }

    yearChart.sort((a, b) => moment(a.year).unix() - moment(b.year).unix());

    const formattedData = yearChart.map((item) => ({
      ...item,
      Sales: item.sales,
    }));
    updateChartData(formattedData);
  };

  const formatNums = (num) => {
    return numeral(num).format("0");
  };

  const [apiData, setApiData] = useState({});

  const fetchData = async (startDate, endDate) => {
    try {
      const response = await axios.get(
        "/admins/reports/dashboardAmountOfSales",
        {
          params: {
            startDate,
            endDate,
          },
        }
      );

      setApiData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const currentWeekStart = moment().startOf("week").format("YYYY-MM-DD");
    const currentWeekEnd = moment().endOf("week").format("YYYY-MM-DD");

    fetchData(currentWeekStart, currentWeekEnd);
  }, []);

  useEffect(() => {
    formatData(
      Object.entries(apiData).map(([year, sales]) => ({ year, sales }))
    );
  }, [apiData]);

  const handleFilter = async (val) => {
    setTimeFrameFilterValue(val);

    const today = moment().format("YYYY-MM-DD");
    const currentMonth = moment().subtract(1, "month").format("YYYY-MM-DD");
    const lastYear = moment().subtract(1, "year").format("YYYY-MM-DD");
    const lastSixMonths = moment().subtract(6, "months").format("YYYY-MM-DD");
    let startDate, endDate;

    switch (val) {
      case 1:
        startDate = moment().startOf("week").format("YYYY-MM-DD");
        endDate = moment().endOf("week").format("YYYY-MM-DD");
        break;
      case 2: // Current Month
        startDate = currentMonth;
        endDate = today;
        break;

      case 3:
        startDate = lastSixMonths;
        endDate = today;
        break;
      case 4:
        startDate = lastYear;
        endDate = today;
        break;
      case 6:
        break;
      default:
        startDate = moment().startOf("week").format("YYYY-MM-DD");
        endDate = moment().endOf("week").format("YYYY-MM-DD");
        break;
    }

    if (val !== 6) {
      try {
        const response = await axios.get(
          "/admins/reports/dashboardAmountOfSales",
          {
            params: {
              startDate,
              endDate,
            },
          }
        );

        setApiData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const filterOption = [
    {
      id: 1,
      text: "Current Week",
    },

    {
      id: 2,
      text: "Current Month",
    },
    {
      id: 3,
      text: "Last 6 Months",
    },
    {
      id: 4,
      text: "Last 1 Year",
    },
  ];

  return (
    <div className={styles.containerClass}>
      <div className={styles?.filterCont}>
        <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
          <div className={styles.lowerMenuDropdown}>
            <Label value="Filter By" horizontal />
            <Select
              value={timeFrameFilterValue}
              style={{ width: "150px" }}
              showSearch
              placeholder="Select Filter type"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleFilter}
            >
              {filterOption.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item?.text}
                </Option>
              ))}
            </Select>
          </div>
          {timeFrameFilterValue === 6 && (
            <RangePicker
              value={[
                moment(customRange.startDate, "YYYY-MM-DD"),
                moment(customRange.endDate, "YYYY-MM-DD"),
              ]}
              onChange={(dates) =>
                setCustomRange({
                  startDate: dates[0].format("YYYY-MM-DD"),
                  endDate: dates[1].format("YYYY-MM-DD"),
                })
              }
            />
          )}
        </div>
        {/* 
        <Button
          type="primary"
          style={{ backgroundColor: "rgba(21, 96, 189, 1)" }}
          className={styles.FilterBtn}
          onClick={() => {
            if (timeFrameFilterValue === 6) {
              fetchData(customRange.startDate, customRange.endDate);
            }
          }}
        >
          Filter
        </Button> */}
      </div>
      <div className={styles.header}>{header}</div>
      <ResponsiveContainer
        width="100%"
        height="100%"
        aspect={3}
        className={styles.transFix}
      >
        <AreaChart data={chartData}>
          <CartesianGrid vertical={false} strokeDasharray="3 0" />
          <XAxis height={20} dataKey="year"></XAxis>
          <YAxis type="number" tickFormatter={formatNums}>
            <Label
              angle={-90}
              fill="#2067bd"
              value={header}
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip formatter={numberWithCommas} />
          <Legend />
          <Area
            type="monotone"
            dataKey="Sales"
            // stroke={props.color}
            fill="#b3e6ff"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default YearlyChart;
