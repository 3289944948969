import React, { useState, useEffect, useRef } from "react";
import {
  CustomModal,
  Panel,
  Label,
  ErrorMessage,
  CustomImage,
} from "../../components/common";
import "./view-Profile.scss";
// import Panel from "./../../components/common/panel/panel";
// import CustomModal from "./../../components/common/custom-modal/custom-modal";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Button, Input, Select, Radio } from "antd";
import Validations from "../../shared/validations/Validations";
import { toastr } from "react-redux-toastr";
import { Marker, StandaloneSearchBox } from "@react-google-maps/api";
import GoogleMap from "../../components/google-map/google-map";
// import DHL from "../../containers/settings/dhl/dhl";
import EPOS from "../../containers/settings/epos";
// import { GoogleMap } from "react-google-maps";
import classNames from "classnames";
import { getAdminInfo } from "../../shared/utils/local-storage";
import axiosConfig from "../../shared/api/axiosConfig";
import axios from "../../shared/api/axiosAccount";
import userActions from "../../redux/user-info/user-actions";
import { useDispatch } from "react-redux";
import OTPVerificationModal from "./verificationOTP";
const { Option } = Select;

const styles = {
  wrapper: "view-user__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  label: "view-user__label",
  value: "view-user__value",
  row: "view-user__row",
};

const ViewProfile = (props) => {
  const userInformationString = localStorage.getItem("userInformation");
  const userInformation = userInformationString
    ? JSON.parse(userInformationString)
    : {};

  const { firstName, email } = userInformation;

  const intl = useIntl();

  const dispatch = useDispatch();
  const [OTPmodalControls, updatedOTPmodalControl] = useState({
    isVisible: false,
    modeOfOtp: "",
  });

  const [Name, changeName] = useState(Validations.validateTextField(""));
  const [countryCode, updateCountryCode] = useState(
    Validations.validateTextField("")
  );
  const [emails, changeEmail] = useState(Validations.validateEmail(""));
  const [password, changePassword] = useState(Validations.validatePassword(""));

  const [confirm_password, Changeconfirmpassword] = useState(
    Validations.validatePassword("")
  );
  const [userProfile, updateUserProfile] = useState();
  const [openingStatus, updateOpeningStatus] = useState(
    Validations.validateTextField(0)
  );
  const [isPristine, changeIsPristine] = useState(true);

  const [phoneNumber, changePhoneNumber] = useState(
    Validations.validatePhoneNumber("")
  );
  const [address, changeAddress] = useState(Validations.validateTextField(""));
  const [resetValue, updateResetValue] = useState(
    Validations.validateNumericValue(1)
  );
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const locationSearchRef = useRef(null);
  const [isAddressLoading, changeIsAddressLoading] = useState(false);
  console.log("resetValue => ", resetValue);

  const [latLng, updateLatLng] = useState({
    lat: null,
    lng: null,
  });

  const setForm = (data) => {
    console.log("User Profile -> ", data);
    updateUserProfile(data);

    changeName(Validations.validateTextField(data?.firstName));

    changeEmail(Validations.validateEmail(data?.email));
    updateCountryCode(Validations.validateTextField(data?.countryCode));

    changePhoneNumber(Validations.validatePhoneNumber(data?.phone));
  };
  const resetForm = () => {
    changeName(Validations.validateTextField(""));
    changeEmail(Validations.validateEmail(""));
    changePhoneNumber(Validations.validatePhoneNumber(""));
  };
  // const userInformationString = localStorage.getItem("userInformation");
  // if (userInformationString) {
  //   const userInformation = JSON.parse(userInformationString);
  //   // Use the user information as needed
  //   console.log("info101", userInformation);
  // }
  useEffect(() => {
    if (props.isVisible) {
      console.log("props?.data?.accountType =>", props?.data?.accountType);
      if (
        props?.data?.accountType === "business-owner" ||
        props?.data?.accountType === "service-provider"
      ) {
        console.log("props.data=>", props?.data);
        setForm(props?.data);
      } else {
        let userInfo = getAdminInfo();
        console.log("admin data=>", userInfo);
        setForm(userInfo);
      }
      if (true) {
        console.log(props.data);
        let userInfo = getAdminInfo();
        setForm(userInfo);
      }
    }
  }, [props.isVisible]);
  const handleFieldChange = (e) => {
    const { value } = e.target;
    // console.log(state)

    console.log("e.target.name => ", e.target.name);
    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "email":
        changeEmail(Validations.validateEmail(value));
        break;
      case "password":
        changePassword(Validations.validatePassword(value));
        break;
      case "phoneNumber":
        changePhoneNumber(Validations.validatePhoneNumber(value));
        break;
      case "address":
        changeAddress(Validations.validateTextField(value));
        break;
      case "confirm_password":
        Changeconfirmpassword(Validations.validateTextField(value));
        break;
      case "countryCode":
        updateCountryCode(Validations.validateTextField(value));
      case "resetPassword":
        updateResetValue(Validations.validateNumericValue(value));
        break;
      // case "openingStatus":
      //   updateOpeningStatus(Validations.validateTextField(value));

      default:
        return;
    }
  };
  const closeModal = () => {
    props.onCloseIconClick();
  };

  const handleModalClose = () => {
    updatedOTPmodalControl({
      isVisible: false,
    });
  };
  return (
    <CustomModal
      size="tiny"
      width={700}
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={props.heading}
      action={
        <>
          {props.modalToShow === "EDIT" ? (
            <>
              <Button type="default" shape={undefined} onClick={closeModal}>
                <FormattedMessage id="app.field.cancel" />
              </Button>
              <Button
                type="primary"
                loading={props.isLoading}
                shape={undefined}
                onClick={"/"}
              >
                <FormattedMessage id="Update" />
              </Button>
            </>
          ) : (
            ""
          )}
        </>
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div>
          <div
            style={{
              marginBottom: "1rem",
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="app.field.details" />
          </div>
          <div>
            {props.updateData !== "password" ? (
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label for="name" value="app.field.name" />
                  <Input
                    name="name"
                    onChange={handleFieldChange}
                    value={firstName}
                    disabled={
                      props.modalToShow === "VIEW" ||
                      props.updateData === "phone"
                    }
                    placeholder={intl.formatMessage({
                      id: "app.placeholder.name",
                    })}
                  />
                  {/* {!isPristine && !.isValid && (
                    <ErrorMessage text={?.errMsg} />
                  )} */}
                </div>
                {/* <div className={styles.formGroup}>
                  <div className={styles.formItem}>
                    <Label for="phoneNumber" value="app.field.phoneNumber" />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.6rem",
                      }}
                    >
                      <Input
                        disabled={
                          props.modalToShow === "VIEW" ||
                          props.updateData === "email"
                        }
                        style={{ width: "5rem" }}
                        name="countryCode"
                        onChange={handleFieldChange}
                        value={countryCode.value}
                      />

                      <Input
                        name="phoneNumber"
                        disabled={
                          props.modalToShow === "VIEW" ||
                          props.updateData === "email"
                        }
                        onChange={handleFieldChange}
                        value={phoneNumber.value}
                        placeholder={intl.formatMessage({
                          id: "app.placeholder.phoneNumber",
                        })}
                      />
                    </div>

                    {!isPristine &&
                      (!phoneNumber?.isValid || !countryCode.value) &&
                      props.updateData === "phone" && (
                        <ErrorMessage
                          text={"Country code and Phone no should be valid!"}
                        />
                      )}
                  </div>
                </div> */}
                <div className={styles.formItem}>
                  <Label for="email" value="app.field.email" />
                  <Input
                    name="email"
                    disabled={
                      props.modalToShow === "VIEW" ||
                      props.updateData === "phone"
                    }
                    onChange={handleFieldChange}
                    value={email}
                    placeholder={intl.formatMessage({
                      id: "app.placeholder.email",
                    })}
                  />

                  {!isPristine &&
                    !email.isValid &&
                    props.updateData === "email" && (
                      <ErrorMessage text={email?.errMsg} />
                    )}
                </div>
              </div>
            ) : (
              ""
            )}

            {props.updateData === "password" ? (
              <>
                <div className={styles.formItem}>
                  <Label for="password" value="New Password" />
                  <Input.Password
                    name="password"
                    onChange={handleFieldChange}
                    value={password.value}
                    type="password"
                    placeholder={intl.formatMessage({
                      id: "app.placeholder.password",
                    })}
                  />
                  {/* {!isPristine && <ErrorMessage field={password} />} */}
                  {!isPristine &&
                    !(
                      password.value === "" ||
                      password.value === undefined ||
                      password.value === null
                    ) && <ErrorMessage text={password?.errMsg} />}
                </div>

                <div className={styles.formItem}>
                  <Label for="password" value="Confirm Password" />
                  <Input.Password
                    name="confirm_password"
                    onChange={handleFieldChange}
                    value={confirm_password.value}
                    type="password"
                    placeholder={intl.formatMessage({
                      id: "app.placeholder.password",
                    })}
                  />
                  {!isPristine &&
                    !(password.value === confirm_password.value) && (
                      <ErrorMessage
                        text={"Passwords do not match. Please try again"}
                      />
                    )}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
};
export default ViewProfile;
