import orderActionTypes from "./orders-action-types";

const initialState = {
  timestamp: "",
};

function OrdersReducer(state = initialState, action) {
  switch (action.type) {
    case orderActionTypes.TRIGGER_REFETCH:
      return {
        ...state,
        timestamp: new Date().getTime(),
      };
      default:
          return state
  }
}

export default OrdersReducer;