import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Menu, Tooltip, Tag, Rate, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import { CustomerDetailsTable, CustomerDetailsFormModal } from "./components";
import AddCustomerNotes from "./components/Customer_Notes/AddCustomerNotes";
import { useParams } from "react-router-dom";
import { nestedParseJSON } from "../../shared/utils/object-manipulation";
import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  DelayedInput,
  BackButton,
  CustomTag,
} from "../../components/common";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  numberWithCommas,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiose";
// import { CustomerData } from "../../FakeData";
import placeholderImg from "../../assets/images/profile-placeholder.png";
import "./service-booking-details.scss";
import { Button } from "antd";
import { CustomTable } from "../../components/common";
import { dateFormat } from "../../shared/utils/local-storage";

import { greenTick } from "../../shared/utils/icon-storage";

const styles = {
  wrapper: "customer-details__wrapper",
  menu: "customer-details__menu",
  pageSize: "customer-details__menu__records-per-page",
  image: "customer-details__image",
  form: "customer-details__form",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formWrapper: "customer-details__form-wrapper",
  editIcon: "customer-details__edit-icon",
  backButton: "customer-details__back-button",
};

const { Option } = Select;

function PaymentBookingDetails(selectedBookingId) {
  const { id } = useParams();
  const intl = useIntl();
  const [customerFlag, updateCustomerFlag] = useState(true);
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [bookingData, setBookingData] = useState(null);
  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });
  const [tableData, setTableData] = useState([]); // State to store the modified data for the table
  const [tableControlss, setTableControls] = useState({
    isLoading: true,
    isEmpty: false,
  });
  useEffect(() => {
    // Function to fetch the data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(`/payments/${id}`);

        setBookingData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const column = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: ["customer", "firstName"],
      key: ["customer", "firstName"],
      render: (firstName) => (
        <span style={{ color: " #1890ff", fontWeight: "600" }}>
          {firstName}
        </span>
      ),
    },
    {
      title: " Customer ID",
      dataIndex: "customerId",
      key: "customerId",
    },
    {
      title: "Booking ID",
      dataIndex: "booking_id",
      key: "booking_id",
      render: (booking_id) => (
        <Tag color={booking_id ? "blue" : "red"}>
          {booking_id ? booking_id : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Stripe Payment ID ",
      dataIndex: "stripeCustomerId",
      key: "stripeCustomerId",
      render: (stripeCustomerId) => (
        <Tag color={stripeCustomerId ? "purple" : "red"}>
          {stripeCustomerId ? stripeCustomerId : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Stripe Payment Method ID ",
      dataIndex: "stripePaymentMethodId",
      key: "stripePaymentMethodId",
      render: (stripePaymentMethodId) => (
        <Tag color={stripePaymentMethodId ? "Orange" : "red"}>
          {stripePaymentMethodId ? stripePaymentMethodId : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Stripe Payment Intent ID  ",
      dataIndex: "stripePaymentIntentId",
      key: "stripePaymentIntentId",
      render: (stripePaymentIntentId) => (
        <Tag color={stripePaymentIntentId ? "Purple" : "red"}>
          {stripePaymentIntentId ? stripePaymentIntentId : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Transaction ID ",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (transactionId) => (
        <Tag color={transactionId ? "purple" : "red"}>
          {transactionId ? transactionId : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Email",
      dataIndex: ["customer", "email"],
      key: ["customer", "email"],
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Refund Amount",
      dataIndex: "refundAmount",
      key: "refundAmount",
    },

    {
      title: "Mode Of Payment",
      dataIndex: "modeOfPayment",
      key: "modeOfPayment",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleDateString(),
    },

    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => new Date(date).toLocaleDateString(),
    },

    {
      title: "payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (paymentStatus) => (
        <Tag color={paymentStatus ? "green" : "red"}>
          {paymentStatus ? paymentStatus : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Refund Status ",
      dataIndex: "refundStatus",
      key: "refundStatus",
      render: (refundStatus) => (
        <Tag color={refundStatus ? "green" : "red"}>
          {refundStatus ? refundStatus : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Refund Failure Reason  ",
      dataIndex: "refundFailureReason",
      key: "refundFailureReason",
      render: (refundFailureReason) => (
        <Tag color={refundFailureReason ? "green" : "red"}>
          {refundFailureReason ? refundFailureReason : "N/A"}
        </Tag>
      ),
    },
  ];

  const [customerData, updateCustomerData] = useState({
    isLoading: false,
    data: "",
  });

  const [modalControls, updateModalControls] = useState({
    formModalVisible: false,
    orderDetailsModalVisible: false,
    AddCustomerNotesVisible: false,
    modalType: "EDIT",
    data: {},
    isLoading: false,
    customerId: "",
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const openFormModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: true,
      data: customerData,
    });
  };

  const closeModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: false,

      // orderDetailsModalVisible: false,
      // AddCustomerNotesVisible: false,
    });
  };

  // Mount

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  // useEffect(() => {
  //   let customerId = window?.location?.pathname.split("details/")[1];

  //   getCustomersData(customerId);
  // }, [customerFlag]);

  return (
    <div className={styles.wrapper}>
      <Header title="Payments" showBackButton={true} />
      <Panel
        className="custom-section single"
        isLoading={customerData.isLoading}
      >
        <div className={styles.formWrapper}>
          <div style={{ display: "flex", width: "100%", flexWrap: "nowrap" }}>
            <div className={styles.image}>
              <img
                src={
                  bookingData?.customer?.profilePicURL
                    ? bookingData?.customer?.profilePicURL
                    : placeholderImg
                }
                onError={handleImageError}
                alt="Customer Profile Pic"
              />
            </div>
            <div className={styles.form}>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>First Name</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.customer?.firstName}
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Last Name</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.customer?.lastName}
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Phone</label>
                  <div className={styles.detailsField}>
                    {" "}
                    {bookingData?.customer?.phoneNo}
                  </div>
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>Email</label>
                  <div className={styles.detailsField}>
                    {bookingData?.customer?.email}
                  </div>
                  <div className={styles.formItem}>
                    <label>User Name</label>
                    <div className={styles.detailsField}>
                      <Tag color="purple">
                        {bookingData?.customer?.username}
                      </Tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer></footer>
        </div>

        <div>
          <h1>Payment Details </h1>
          <Table
            columns={column}
            dataSource={[bookingData]}
            scroll={{ x: 400 }}
          />
        </div>
      </Panel>
    </div>
  );
}
export default PaymentBookingDetails;
