import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Dropdown,
  Button,
  Drawer,
  Table,
  Spin,
  Menu,
  Input,
  Tag,
} from "antd";
import { useIntl } from "react-intl";
import { MoreOutlined } from "@ant-design/icons";
import { CaretDownOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import moment from "moment";
import { connect } from "react-redux";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import {
  Header,
  ConfirmModal,
  DelayedInput,
  UploadButton,
  CustomImage,
  Label,
} from "../../components/common";
import {
  ActivePromoTable,
  ActivePromoTableSub,
  AddEditPromoModalSub,
} from "./components";
import Validations from "../../shared/validations/Validations";
import axios from "../../shared/api/axiose";
import ViewUser from "./view-user/view-user";
import { useDispatch } from "react-redux";

const { Option } = Select;

const styles = {
  wrapper: "active-promo__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "active-promo__menu--left",
  menuRight: "active-promo__menu--right",
  pageSize: "active-promo__records-per-page",
  filterCont: "filterCont",
};

function SubCategories(props) {
  const imageContainerRef = useRef(null);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEditFormValid, setIsEditFormValid] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategories, setSubcategories] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAdddrawer, setOpenAddDrawer] = useState(false);

  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [editSubcategory, setEditSubcategory] = useState(null);
  const [editSubCategoryId, setEditSubCategoryId] = useState(null);
  const [viewDrawerVisible, setViewDrawerVisible] = useState(false);
  const [viewSubcategory, setViewSubcategory] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState(
    Validations.validateTextField("")
  );
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  const showAddDrawer = () => {
    setOpenAddDrawer(true);
  };

  const onClose = () => {
    setOpenAddDrawer(false);

    setSubCategoryName(Validations.validateTextField(""));
    changeImageURL(Validations.validateTextField(""));
  };

  const handleEditSubCategory = async (subCategoryId) => {
    try {
      const response = await axios.get(`/category/${subCategoryId}`);
      const subCategoryData = response.data;

      setSubCategoryName(Validations.validateTextField(subCategoryData.title));
      changeImageURL(
        Validations.validateTextField(subCategoryData.categoryIcon)
      );

      setEditDrawerVisible(true);
      setEditSubCategoryId(subCategoryId);
    } catch (error) {
      console.error("Error editing subcategory:", error);
    }
  };

  const handleEditDrawerClose = () => {
    setEditDrawerVisible(false);

    setSubCategoryName(Validations.validateTextField(""));
    changeImageURL(Validations.validateTextField(""));
    setEditSubCategoryId(null);
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  const handleSubcategoryName = (e) => {
    setSubCategoryName(Validations.validateTextField(e.target.value));
  };

  const handleViewSubCategory = (subCategoryId) => {
    const selectedSubcategory = subCategories.find(
      (subcategory) => subcategory.id === subCategoryId
    );

    setViewSubcategory(selectedSubcategory);
    setViewDrawerVisible(true);
  };

  const handleViewDrawerClose = () => {
    setViewDrawerVisible(false);
    setViewSubcategory(null);
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await axios.get("/category/");
        const data = response.data;
        const fetchedCategories = data.data.categories;

        setCategories(fetchedCategories);

        if (fetchedCategories.length > 0) {
          const defaultCategory = fetchedCategories[0].id;
          setSelectedCategory(defaultCategory);
          handleCategoryChange(defaultCategory);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = async (value) => {
    setSelectedCategory(value);
    const selectedCategoryObj = categories.find(
      (category) => category.id === value
    );
    setSubcategories(selectedCategoryObj?.subcategories || []);
  };

  const handleAddSubcategory = async () => {
    try {
      if (!subCategoryName.value || !imageURL.value || !selectedCategory) {
        toast.error("Please fill in all the required fields");
        return;
      }
      setShowSpinner(true);
      const payload = {
        title: subCategoryName.value,
        categoryIcon: imageURL.value,
        parentCategory: selectedCategory,
      };

      await axios.post("/category/", payload);

      const response = await axios.get("/category/");
      const data = response.data;

      const selectedCategoryObj = data.data.categories.find(
        (category) => category.id === selectedCategory
      );

      setSubcategories(selectedCategoryObj?.subcategories || []);

      onClose();

      toast.success("Subcategory added successfully");
    } catch (error) {
      console.error("Error adding subcategory:", error);
      toast.error("Failed to add subcategory");
    } finally {
      setTimeout(() => {
        setShowSpinner(false);
      }, 2000);
    }
  };

  const handleEditSubmit = async () => {
    setShowSpinner(true);
    try {
      if (
        !subCategoryName.value ||
        !imageURL.value ||
        !selectedCategory ||
        !editSubCategoryId
      ) {
        toast.error("Please fill in all the required fields");
        return;
      }
      await axios.put(`/category/${editSubCategoryId}`, {
        title: subCategoryName.value,
        categoryIcon: imageURL.value,
      });

      const response = await axios.get("/category/");
      const data = response.data;

      const selectedCategoryObj = data.data.categories.find(
        (category) => category.id === selectedCategory
      );

      setSubcategories(selectedCategoryObj?.subcategories || []);
      toast.success("Subcategory edited successfully");
      handleEditDrawerClose();
      props.reloadData();
    } catch (error) {
      console.error("Error editing subcategory:", error);
    } finally {
      setShowSpinner(false);
    }
  };
  const handleRemoveSubCategory = async (subCategoryId) => {
    setShowSpinner(true);
    try {
      await axios.delete(`/category/${subCategoryId}`);

      const response = await axios.get("/category/");
      const data = response.data;

      const selectedCategoryObj = data.data.categories.find(
        (category) => category.id === selectedCategory
      );

      setSubcategories(selectedCategoryObj?.subcategories || []);

      toast.success("Subcategory deleted successfully");
    } catch (error) {
      console.error("Error deleting subcategory:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          "An error occurred while deleting subcategory is in using by service"
        );
      }
    } finally {
      setShowSpinner(false);
    }
  };

  const columns = [
    {
      width: "6rem",
      title: "S No.",
      dataIndex: "sectionNumber",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Category Icon",
      dataIndex: "categoryIcon",
      key: "categoryIcon",
      width: "10rem",
      render: (text) => (
        <img
          src={text}
          alt="Category Icon"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      title: "Sub Category name",
      dataIndex: "title",
      key: "title",
      width: "15rem",
      render: (title) => (
        <span style={{ color: " #1890ff", fontWeight: "600" }}>{title}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled) => (
        <Tag color={isEnabled ? "green" : "red"}>
          {isEnabled ? "Enabled" : "Disabled"}
        </Tag>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <span>{moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>
      ),
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => (
        <Dropdown overlay={getMenu(item)}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const getMenu = (item) => (
    <Menu>
      <Menu.Item onClick={() => handleViewSubCategory(item.id)}>
        {"view"}
      </Menu.Item>
      <Menu.Item onClick={() => handleEditSubCategory(item.id)}>
        {intl.formatMessage({ id: "app.field.edit" })}
      </Menu.Item>
      <Menu.Item onClick={() => handleRemoveSubCategory(item.id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.wrapper}>
      <h1>{"  "}</h1>
      <Header title="Sub Categories" />

      <div className={styles.content}>
        <div className={styles.menu}>
          <header></header>
          <section>
            <Select
              style={{ width: "400" }}
              placeholder="Select a category"
              onChange={handleCategoryChange}
              value={selectedCategory}
              loading={loading}
            >
              {categories.map((category) => (
                <Option key={category.id} value={category.id}>
                  {category.title}
                </Option>
              ))}
            </Select>
            <div>
              <Button
                type="primary"
                disabled={props.isAddDisabled}
                shape={undefined}
                size="medium"
                onClick={showAddDrawer}
              >
                Add Subcategories
              </Button>
            </div>
          </section>
        </div>
        <Drawer
          title="Add Subcategory"
          placement="right"
          onClose={onClose}
          open={openAdddrawer}
          size="large"
          footer={
            <div style={{ textAlign: "right" }}>
              <Button onClick={handleAddSubcategory} type="primary">
                Submit
              </Button>
            </div>
          }
        >
          <CustomImage src={imageURL.value} />
          <div style={{ marginTop: "10px" }}>
            <UploadButton
              type="fileUpload"
              parentRef={imageContainerRef}
              resultType="url"
              accept="image/*"
              id="913fjhiu4ng2vzi"
              text="Upload Image(300x300)"
              onChange={(image) => {
                handleImageChange(image);
                setIsFormValid(
                  !!subCategoryName.value && !!image && !!selectedCategory
                );
              }}
            />
          </div>
          <div className={styles.formItem} style={{ marginTop: "20px" }}>
            <Label value="Sub Category Name" />
            <Input
              placeholder="Sub Category Name"
              value={subCategoryName.value}
              onChange={(e) => {
                handleSubcategoryName(e);
                setIsFormValid(
                  !!e.target.value && !!imageURL.value && !!selectedCategory
                );
              }}
            />
          </div>
        </Drawer>

        <Drawer
          title={`Edit Subcategory - ${
            editSubcategory ? editSubcategory.title : ""
          }`}
          placement="right"
          onClose={handleEditDrawerClose}
          open={editDrawerVisible}
          size="large"
          footer={
            <div style={{ textAlign: "right" }}>
              <Button type="primary" onClick={handleEditSubmit}>
                Submit
              </Button>
            </div>
          }
        >
          <CustomImage src={imageURL.value} />
          <div style={{ marginTop: "10px" }}>
            <UploadButton
              type="fileUpload"
              parentRef={imageContainerRef}
              resultType="url"
              accept="image/*"
              id="913fjhiu4ng2vzi"
              text="Upload Image(300x300)"
              onChange={(image) => {
                handleImageChange(image);
                setIsEditFormValid(
                  !!subCategoryName.value && !!image && !!selectedCategory
                );
              }}
            />
          </div>
          <div className={styles.formItem} style={{ marginTop: "20px" }}>
            <Label value="Sub Category Name" />
            <Input
              value={subCategoryName.value}
              onChange={(e) => {
                handleSubcategoryName(e);
                setIsEditFormValid(
                  !!e.target.value && !!imageURL.value && !!selectedCategory
                );
              }}
            />
          </div>
        </Drawer>

        <Drawer
          title={`View Subcategory - ${
            viewSubcategory ? viewSubcategory.title : ""
          }`}
          placement="right"
          onClose={handleViewDrawerClose}
          visible={viewDrawerVisible}
          size="large"
        >
          <CustomImage
            src={viewSubcategory ? viewSubcategory.categoryIcon : ""}
          />
          <div style={{ marginTop: "10px" }}>
            <Label value="Sub Category Name" />
            <Input
              value={viewSubcategory ? viewSubcategory.title : ""}
              disabled
            />
          </div>
        </Drawer>
        <Spin spinning={loading || showSpinner}>
          <Table dataSource={subCategories} columns={columns} rowKey="id" />
        </Spin>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SubCategories);
