import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Table, Tag, Input, Select } from "antd";
import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "../../../../shared/api/axiose";
import { connect } from "react-redux";
import {
  Panel,
  Icon,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../../../components/common";
import "./active-promo-table.scss";
const styles = {
  wrapper: "active-promo__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "active-promo__menu--left",
  menuRight: "active-promo__menu--right",
  pageSize: "active-promo__records-per-page",
};
const ActivePromoTable = (props) => {
  const { Option } = Select;
  const [promoData, setPromoData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    fetchData();
  }, [search, status]);

  const fetchData = async () => {
    setIsLoading(true);
    let apiUrl = "/promo";
    const params = {};

    if (search.length >= 3) {
      params.search = search;
    }

    if (status !== "all") {
      params.status = status;
    }

    try {
      const response = await axios.get(apiUrl, {
        params: params,
      });
      setIsLoading(false);
      setPromoData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const getMenu = (record) => {
    return (
      <Menu>
        <Menu.Item onClick={() => props.viewItem(record)}>View</Menu.Item>
        <Menu.Item onClick={() => props.editItem(record)}>Edit</Menu.Item>
        <Menu.Item onClick={() => props.removeItem(record)}>Delete</Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      fixed: "left",
      width: 100,
      render: (text, record, index) => index + 1,
    },

    {
      title: "Promo Name",
      dataIndex: "title",
      key: "title",
      width: 150,
      render: (title) => (
        <Tag style={{ width: "80px", textAlign: "center" }} color="#5FAAFF">
          {title}
        </Tag>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 150,
      render: (code) => (
        <Tag style={{ width: "80px", textAlign: "center" }} color="#C55FFF">
          {code}
        </Tag>
      ),
    },

    {
      title: "Max Allowed",
      dataIndex: "maxAllowedPerCustomer",
      key: "maxAllowedPerCustomer",
      width: 150,
      render: (maxAllowedPerCustomer) => (
        <Tag color="#74D95B">{maxAllowedPerCustomer}</Tag>
      ),
    },
    {
      title: "Total Allowed",
      dataIndex: "totalMaxAllowed",
      key: "totalMaxAllowed",
      width: 150,
      render: (totalMaxAllowed) => <Tag color="Orange">{totalMaxAllowed}</Tag>,
    },
    {
      title: "Discount Type",
      dataIndex: "discountType",
      key: "discountType",
      width: 150,
      render: (text) => {
        let backgroundColor = "";
        if (text === "amount") {
          backgroundColor = "#2980B9";
        } else if (text === "percentage") {
          backgroundColor = "#74D95B";
        }
        return (
          <Tag
            color="white"
            style={{ backgroundColor, width: "6rem", textAlign: "center" }}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 150,
    },
    {
      title: "Valid Till",
      dataIndex: "validTill",
      key: "validTill",
      width: 150,
      render: (validTill) => moment(validTill).format("YYYY-MM-DD"),
    },

    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: 150,
      render: (discount, record) => {
        const { discountType } = record;
        let backgroundColor = "";

        if (discountType === "amount") {
          backgroundColor = "#2980B9";
        } else if (discountType === "percentage") {
          backgroundColor = "#74D95B";
        }

        const formattedDiscount =
          discountType === "percentage"
            ? `${Math.floor(discount)}%`
            : Math.floor(discount);

        return (
          <Tag
            color="white"
            style={{ backgroundColor, width: "3rem", textAlign: "center" }}
          >
            {formattedDiscount}
          </Tag>
        );
      },
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 100,
      render: (record) => (
        <Dropdown overlay={getMenu(record)} trigger={["click"]}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];
  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  const imgStyle = {
    width: isHovered ? "46px" : "44px",
    height: isHovered ? "46px" : "44px",
    transition: "all 0.3s ease-in-out",
  };

  return (
    <>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginLeft: "1.5rem" }}>
            <Label value="Filter By Status" vertical></Label>

            <Select
              style={{ width: "300px", marginBottom: "1rem" }}
              defaultValue="all"
              onChange={(value) => setStatus(value)}
            >
              <Option value="all">All</Option>
              <Option value="active">Active</Option>
              <Option value="expired">Expired</Option>
            </Select>
          </div>
          <Input
            placeholder="Search Promo Code"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "300px",
              marginLeft: "1.5rem",
              marginBottom: "1rem",
              marginTop: "1.8rem",
            }}
          />
          <span onClick={fetchData} className="reload-button">
            Reload
            <ReloadOutlined style={{ marginLeft: "5px" }} />
          </span>
        </div>
      </div>

      <Table
        dataSource={promoData?.data}
        columns={columns}
        loading={isLoading}
        scroll={{ y: 300 }}
        setIsLoading={fetchData}
      />
      <div style={{ display: "none" }}>
        <a href="https://icons8.com/icon/7JJpHLz4UBIH/sync">Sync</a> icon by{" "}
        <a href="https://icons8.com">Icons8</a>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  panelType: state.user.enterprice.panelType,
});

export default connect(mapStateToProps)(ActivePromoTable);
