import { createStore, combineReducers, applyMiddleware } from 'redux'
import {reducer as toastrReducer} from 'react-redux-toastr'
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension';

import userReducer from './user-info/user-reducer';
import modalReducer from './modal/modal-reducer';
import ordersReducer from './orders/orders-reducer';
import rolesReducer from './roles/roles-reducer';
import categoriesReducesr from './categories/categories-reducer';

const middleWares = [
    logger,
    thunk
];

const store = createStore(
    combineReducers({
        user: userReducer,
        toastr: toastrReducer,
        modal: modalReducer,
        orders: ordersReducer,
        roles: rolesReducer,
        categories: categoriesReducesr,
    }),
    composeWithDevTools(
        applyMiddleware(...middleWares)
    ),
);

export default store;