import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button, Rate, Input } from "antd";
import { useIntl } from "react-intl";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import categoriesAction from "../../redux/categories/categories-actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
} from "../../components/common";
import { ActivePromoTable, AddEditPromoModal } from "./components";

import { PromoCodeData } from "../../FakeData";
import {
  country,
  states,
  cities,
  zipcode,
} from "../../shared/constants/countryFilterData";

import "./service-module.scss";
import { checkRtl } from "../../shared/utils/rtl";
import { findAllByDisplayValue } from "@testing-library/react";
import axiosConfig from "../../shared/api/axiose";
import axios from "../../shared/api/axios_config";
import ViewUser from "./view-user/view-user";
import { useDispatch } from "react-redux";
import Label from "../../components/common/label/label";
import AddEditService from "./components/add-edit-modal/add-edit-service";

const { Option } = Select;

const styles = {
  wrapper: "service-module__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "service-module__menu--left",
  menuRight: "service-module__menu--right",
  pageSize: "service-module__records-per-page",
  filterCont: "filterCont",
};

function ServiceModule(props) {
  const serviceTypeTags = [
    {
      id: 1,
      text: "Now",
    },
    {
      id: 2,
      text: "Schedule",
    },
    {
      id: 3,
      text: "Request for quote",
    },
  ];

  const dispatch = useDispatch();
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);
  const [getServicesData, setServicesData] = useState([]);
  const [selectedRating, setSelectedRating] = useState(null);
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [search, updateSearch] = useState("");

  console.log("getdataser", getServicesData);
  const [getCategoriesData, setCategoriesData] = useState([]);
  const [questionData, updateQuestionData] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // const handleSearchChange = (val) => {
  //   updateSearch(val);
  // };
  let debounceTimer;
  function debounce(func, delay) {
    // Clear any previously set debounce timer

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer
    debounceTimer = setTimeout(() => {
      debounceTimer = null;
      func();
    }, delay);
  }

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
    let query = {};
    query.search = val;
    let params = {};
    params.params = query;
    clearTimeout(debounceTimer);
    // debounce(() => ServiceData_Filter(params), 1000);
  };

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isDeleteModalVisible: false,
    isLoading: false,
    isViewModalVisible: false,
  });

  const [promocodes, updatePromos] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    total: 0,
    current: 1,
    search: "",
  });

  const [filter, updateFilter] = useState({
    country: {},
    state: {},
    city: {},
    zipcode: {},
  });
  console.log("filter => ", filter);
  const fetchGetServicesData = () => {
    updateTableControls({
      ...tableControls,
      isLoading: true,
    });

    axios
      .get("/services")
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          console.log("ServiceData => ", res?.data?.data);

          setServicesData(res.data.data.services);
        }

        updateTableControls({
          ...tableControls,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        updateTableControls({
          ...tableControls,
          isEmpty: false,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    fetchGetServicesData();
  }, []);

  useEffect(() => {
    if (searchQuery !== "" || selectedRating !== null) {
      updateTableControls({
        ...tableControls,
        isLoading: true,
      });

      let apiUrl = "/services";
      const queryParams = [];
      if (searchQuery !== "") {
        queryParams.push(`search=${searchQuery}`);
      }
      if (selectedRating !== null) {
        queryParams.push(`rating=${selectedRating}`);
      }

      if (queryParams.length > 0) {
        apiUrl += `?${queryParams.join("&")}`;
      }

      axios
        .get(apiUrl)
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            console.log("ServiceData => ", res?.data?.data);

            setFilteredServices(res.data.data.services);
          }

          updateTableControls({
            ...tableControls,
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          updateTableControls({
            ...tableControls,
            isEmpty: false,
            isLoading: false,
          });
        });
    } else {
      setFilteredServices([]);
    }
  }, [searchQuery, selectedRating]);
  const handleRatingChange = (value) => {
    setSelectedRating(value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const ServiceData = (flag) => {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: true,
    });

    axios
      .get("/services")
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          console.log("ServiceData => ", res?.data?.data);

          setServicesData(res?.data?.data?.services);

          if (flag) {
            toastr.success(res?.data?.message);
          }
        }

        updateTableControls({
          ...tableControls,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        updateTableControls({
          ...tableControls,
          isEmpty: false,
          isLoading: false,
        });
      });
  };

  const CategoryData = (flag) => {
    axios
      .get("/category/")
      .then((res) => {
        console.log("CategoryData", res?.data?.data);
        console.log("CategoryData res", res?.data?.statusCode);
        if (res?.data?.statusCode === 200) {
          setCategoriesData(res?.data?.data?.categories);
          if (flag) {
            toastr.success(res?.data?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // ServiceData();
    CategoryData();
    // getQuestionasData();
  }, []);

  /** Instaservice API Intregation */

  const handlePaginationChange = (page, total) => {
    updatePagination({
      ...pagination,
      total: page.total,
      current: page.current,
      skip: (page.current - 1) * page.pageSize,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      pageSize: val,
    });
  };

  const openAddPromoModal = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "ADD",
    });
  };

  const editCategory = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
    data.promoId = modalControls.data.promoId;
    console.log(data);
    // alert("Edit promo code");
  };

  const openEditPromoModal = (val) => {
    console.log("openEditPromoModal", val);
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "EDIT",
      data: val,
    });
  };
  const openViewPromoCodeModal = (val) => {
    console.log("openViewPromoCodeModal", val);
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "VIEW",
      data: val,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: false,
      type: "",
      // data: {},
    });
  };

  const getPromoData = () => {
    // PromoCodeData
    updateTableControls({
      isTableEmpty: false,
      isTableLoading: false,
    });
    updatePromos({
      ...promocodes,
      data: PromoCodeData(),
      isLoading: false,
      notFound: false,
      count: 6,
    });
  };

  const removeService = (data) => {
    console.log("removeService", data);

    updateModalControls({
      ...modalControls,
      isVisible: false,
      data,
      isDeleteModalVisible: true,
    });
  };

  const handleServiceDelete = (id) => {
    console.log("handleServiceId", id);
    updateModalControls({
      ...modalControls,
      isLoading: true,
      isDeleteModalVisible: true,
    });
    axiosConfig
      .delete(`/services/${id}`)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          toast.success("Service Deleted successfully");
          updateModalControls({
            ...modalControls,
            isLoading: false,
            isDeleteModalVisible: false,
          });
          ServiceData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // alert("Promo code delete");
  };
  const openViewModal = (data) => {
    console.log("openViewModal", data);
    updateModalControls({
      ...modalControls,
      isViewModalVisible: true,
      data,
    });
  };
  const handleViewModalClose = () => {
    updateModalControls({
      ...modalControls,
      isViewModalVisible: false,
    });
  };

  useEffect(() => {
    getPromoData();
  }, [pagination.pageSize, pagination.current, searchDelayed]);

  const handleFilterCountry = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      country: data,
    });
  };
  const handleFilterState = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      state: data,
    });
  };
  const handleFilterCity = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      city: data,
    });
  };

  const handleFilterZipcode = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      zipcode: data,
    });
  };
  const ratingFilter = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];

  const handleFilerByService = (title) => {
    let query = {};
    if (title !== null) {
      query.title = title;
    }
    let params = {};
    params.params = query;
  };

  return (
    <div className={styles.wrapper}>
      <h1>{"  "}</h1>
      <Header title="Services" />
      <div className={styles?.filterCont}>
        <header style={{ display: "flex", gap: "2.3rem", flexWrap: "wrap" }}>
          <div>
            <Label value="Filter By Category" vertical></Label>
            <Select
              showSearch
              placeholder="Category"
              onChange={(value) => {
                setSelectedCategoryId(value);
                if (value === null) {
                  axios
                    .get("/services")
                    .then((res) => {
                      setServicesData(res.data.data.services);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  axios
                    .get(`/services/getServicesByCategory/${value}`)
                    .then((res) => {
                      setServicesData(res.data.data.services);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }}
              style={{ width: "180px" }}
              allowClear
            >
              <Option value={null}>All</Option>
              {getCategoriesData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <Label value="Filter By Ratings" vertical></Label>

            <Select
              showSearch
              placeholder="Rating"
              style={{ width: "200px" }}
              allowClear
              onChange={handleRatingChange}
              value={selectedRating}
            >
              <Option value={null}>All</Option>
              {ratingFilter.map((item) => (
                <Option key={item.id} value={item.id}>
                  <Rate style={{ color: "#ED432A" }} disabled value={item.id} />
                </Option>
              ))}
            </Select>
          </div>
        </header>
      </div>
      {modalControls.isLoading ? (
        <p>Loading...</p>
      ) : (
        <AddEditService
          isVisible={modalControls.isVisible}
          ServiceData={ServiceData}
          modalToShow={modalControls.type}
          questionData={questionData}
          onCloseIconClick={handleModalClose}
          data={modalControls.data}
          editCategory={editCategory}
          getCategoriesData={getCategoriesData}
          serviceTypeTags={serviceTypeTags}
        />
      )}

      <ConfirmModal
        header={`Delete `}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={handleServiceDelete}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        data={modalControls?.data}
        isLoading={modalControls.isLoading}
      />

      <div className={styles.content}>
        <div className={styles.menu}>
          <header></header>
          <section>
            <div>
              <Input
                type="text"
                placeholder="Search by title or service name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <div>
              {
                <Button
                  type="primary"
                  disabled={props.isAddDisabled}
                  shape={undefined}
                  size="medium"
                  onClick={openAddPromoModal}
                >
                  Add Service
                </Button>
              }
            </div>
          </section>
        </div>

        <ActivePromoTable
          data={
            searchQuery || selectedRating !== null
              ? filteredServices
              : getServicesData
          }
          isLoading={tableControls.isLoading}
          getCategoriesData={getCategoriesData}
          tableControls={tableControls}
          updateTableControls={updateTableControls}
          isEmpty={tableControls.isTableEmpty}
          editItem={openEditPromoModal}
          viewDetails={openViewPromoCodeModal}
          onChange={handlePaginationChange}
          removeItem={removeService}
          pagination={pagination}
          columnWidth={100}
          viewItem={openViewModal}
          ServiceData={ServiceData}
          width
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ServiceModule);
