import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";

import { Icon } from "../";
import history from "../../../shared/utils/history";
import "./back-button.scss";

const styles = {
  wrapper: "custom-back-button__wrapper",
};
function BackButton() {
  return (
    <Button
      type="link"
      shape={undefined}
      className={styles.wrapper}
      onClick={() => {
        history.goBack();
      }}
    >
      <Icon name="LEFT_ARROW" />
      <FormattedMessage id="app.button.back" />
    </Button>
  );
}

export default BackButton;
