import React, { useState } from "react";
import { Modal, Input, Button } from "antd";
import OTPInput from "react-otp-input";
import "./verificationOTP.scss";
const styles = {
  OTP_modal: "OTP_modal",
  OTP_input: "OTP_input",
};
const OTPVerificationModal = ({
  isVisible,
  onCancel,
  onOk,
  loggedInWith,
  modeOfOtp,
}) => {
  console.log("modeOfOtp=>", modeOfOtp);
  const [otp, setOTP] = useState("");

  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const handleOk = () => {
    onOk(otp);
    setOTP("");
  };

  return (
    <Modal
      visible={isVisible}
      title="OTP Verification"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Submit
        </Button>,
      ]}
    >
      <p>
        Please enter the OTP sent to your{" "}
        {modeOfOtp === "email" ? "email id" : "mobile number"}
      </p>
      {/* <Input type="number" value={otp} onChange={handleOTPChange} /> */}
      <div className={styles.OTP_modal}>
        <OTPInput
          value={otp}
          onChange={setOTP}
          numInputs={6}
          renderSeparator={<span style={{ width: "2rem" }}></span>}
          renderInput={(props) => (
            <input {...props} className={styles.OTP_input} />
          )}
        />
      </div>
    </Modal>
  );
};

export default OTPVerificationModal;
