import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Carousel } from "react-responsive-carousel";
import { Input, Button, Radio, Select, Divider } from "antd";
import { toastr } from "react-redux-toastr";
import classNames from "classnames";
import { PlusOutlined } from "@ant-design/icons";
import {
  allValidUnits,
  validUnitCombinations,
  unitNames,
} from "../../../shared/constants/products";
import Validations from "../../../shared/validations/Validations";
import CustomTable from "../../../components/common/custom-table/custom-table";
import placeholderImg from "../../../assets/images/item-placeholder.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
  FullPageDrawer,
  Panel,
  CustomModal,
  CustomImage,
  ErrorMessage,
  Label,
  UploadButton,
} from "../../../components/common";
import axios from "../../../shared/api/axiosConfig";
import "./add-edit-product.scss";
import {
  multiSort,
  nestedParseJSON,
} from "../../../shared/utils/object-manipulation";

const { Option } = Select;

const measurandUnits = ["g", "kg", "lbs", "ltr", "ml"];

const styles = {
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formHeader: "custom-form-title",
  formContainer: "add-edit-product__form-container",
  formContent: "add-edit-product__form-content",
  imageContainer: "add-edit-product__image-container",
  formLeft: "add-edit-product__form-container__left",
  formRight: "add-edit-product__form-container__right",
  wrapper: "add-edit-product__wrapper",
  deleteButton: "add-edit-product__delete-button",
};

function AddEditProduct(props) {
  const intl = useIntl();
  const tagRef = useRef(null);
  const [brands, updateBrands] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [isLoading, toggleLoading] = useState(false);
  const imageContainerRef = useRef(null);

  const columns = () => {
    return [
      {
        dataIndex: "",
        title: intl.formatMessage({ id: "app.field.sNo" }),
        toBeShown: true,
        fixed: "left",
      },
      {
        dataIndex: "name",
        title: intl.formatMessage({ id: "app.field.name" }),

        fixed: "left",
      },

      {
        dataIndex: "description",
        title: intl.formatMessage({ id: "app.field.description" }),
      },
    ];
  };

  const [subcats, updateSubcats] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: false,
    count: null,
  });

  const [tags, updateTags] = useState([]);
  const [carousel, changeCarousel] = useState(0);
  const [selectedUnit, changeSelectedUnit] = useState("kg");
  const [purchasedUnit, changePurchasedUnit] = useState(
    Validations.validateTextField(allValidUnits.PIECES)
  );
  const [unitValue, changeUnitValue] = useState(
    Validations.validateNumericValue(null)
    // Validations.validateNumericValue(allValidUnits.PIECES)
  );

  const [sellingUnit, changeSellingUnit] = useState(
    Validations.validateTextField(allValidUnits.PIECES)
  );
  const [baseValue, changeBaseValue] = useState(
    Validations.validateNumericValue(undefined)
  );
  const [purchasedStock, changePurchasedStock] = useState(
    Validations.validateNumericValue(undefined)
  );
  const [GST, changeGST] = useState(
    Validations.validateNumericValue(undefined)
  );
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [brand, changeBrand] = useState(
    Validations.validateTextField(undefined)
  );
  const [description, changeDescription] = useState(
    Validations.validateTextField("")
  );

  const [selectedSubCats, changeSelectedSubCats] = useState(
    Validations.validateMultiSelect([])
  );
  const [skuId, changeSkuId] = useState(Validations.validateTextField(""));
  const [rank, changeRank] = useState(Validations.validateNumericValue(null));
  const [redemptionPoints, changeRedemptionPoints] = useState(
    Validations.validateNumericValue(undefined)
  );
  const [barcode, changeBarcode] = useState(
    Validations.validateTextField(null)
  );
  const [costPrice, changeCostPrice] = useState(
    Validations.validateNumericValue(null)
  );
  const [actualPrice, changeActualPrice] = useState(
    Validations.validateNumericValue(null)
  );
  const [margin, changeMargin] = useState(
    Validations.validateMarginValue(null)
  );
  const [vat, changeVat] = useState(Validations.validateNumericValue(null));
  const [quantity, changeQuantity] = useState(
    Validations.validateNumericValue(null)
  );
  const [length, changeLength] = useState(
    Validations.validateNumericValue(null)
  );
  const [breadth, changeBreadth] = useState(
    Validations.validateNumericValue(null)
  );
  const [loyalityPoints, changeLoyalityPoints] = useState(
    Validations.validateNumericValue(null)
  );
  const [height, changeHeight] = useState(
    Validations.validateNumericValue(null)
  );
  const [weight, changeWeight] = useState(
    Validations.validateNumericValue(null)
  );
  const [manufacturer, changeManufacturer] = useState(
    Validations.validateName(null)
  );

  const [imageURL, changeImageURL] = useState(
    Validations.validateMultiSelect([])
  );
  const [isPristine, changeIsPristine] = useState(true);
  const [isRedeemable, changeIsRedeemable] = useState(0);
  const [isFeatured, changeIsFeatured] = useState(null);

  const [linkedProducts, changeLinkedProducts] = useState(
    Validations.validateMultiSelect([])
  );

  const productDetailsData = {
    productId: 188406,
    priority: 2,
    image:
      "https://d2563s26joau7f.cloudfront.net/images/pdpf1659965596162-Qlp01659965596108apples.jpg",
    skuId: "1532",
    name: "Kashmir Apples",
    description: "Tasty Apples",
    manufacturer: null,
    brand: null,
    featureFlag: 0,
    loyalityPoints: null,
    isRedeemable: 0,
    length: null,
    breadth: null,
    height: null,
    weight: 500,
    quantity: 0,
    costPrice: 50,
    barcode: "8973",
    redemptionPoints: 0,
    vat: 165451326510,
    margin: -82725663255,
    adminId: 1,
    branchId: 382,
    isDeleted: 0,
    isEnabled: 1,
    actualPrice: 50,
    discountedPrice: 0,
    createdAt: "2022-08-08T13:34:34.000Z",
    updatedAt: "2022-08-08T13:34:34.000Z",
    brandId: null,
    linkedProductIds: '[""]',
    thumbnail: "",
    tags: "fruits, apple",
    purchasedUnit: null,
    sellingUnit: null,
    baseValue: null,
    purchasedStock: null,
    gst: null,
    extraStock: 0,
    overAllCostPrice: 0,
    unitValue: "50",
    createdBy: null,
    masterStoreId: null,
    isAddOn: 0,
    foodType: null,
    eposProductId: 10049472,
    currentStock: 0,
    categoryName: "Dal Varieties",
    subCategoryName: "Dal Varieties",
    isFavourite: 0,
    brandName: null,
    categoryIds: "459313",
    subCategoryIds: "459317",
  };

  const handlePurchasedUnitChange = (data) => {
    changePurchasedUnit(Validations.validateTextField(data));
    changeSellingUnit(
      Validations.validateTextField(validUnitCombinations[data][0])
    );
  };

  const handleSellingUnitChange = (data) => {
    changeSellingUnit(Validations.validateTextField(data));
  };

  const isFormValid = () => {
    let isValid = true;
    isValid =
      isValid && imageURL.isValid && selectedTags.isValid && weight.isValid;

    if (props.productMapping.name.isRequired) {
      isValid = isValid && name.isValid;
    }
    // if(props.productMapping.tags.isRequired){
    //   isValid=isValid && tags.isValid;
    // }

    if (props.productMapping.description.isRequired) {
      isValid = isValid && description.isValid;
    }

    if (props.productMapping.brandId?.isRequired) {
      isValid = isValid && brand.isValid;
    }

    if (props.productMapping.skuId.isRequired) {
      isValid = isValid && skuId.isValid;
    }

    if (props.productMapping.priority.isRequired) {
      isValid = isValid && rank.isValid;
    }

    if (margin.value) {
      isValid = isValid && margin.isValid;
    }

    if (props.productMapping.barcode.isRequired) {
      isValid = isValid && barcode.isValid;
    }

    if (props.productMapping.vat.isRequired) {
      isValid = isValid && vat.isValid;
    }
    if (
      props.productMapping.quantity.isRequired ||
      props.productMapping.gst.toBeShown == 1
    ) {
      isValid = isValid && quantity.isValid;
    }

    if (length.value) {
      isValid = isValid && length.isValid;
    }

    if (breadth.value) {
      isValid = isValid && breadth.isValid;
    }

    if (height.value) {
      isValid = isValid && height.isValid;
    }

    if (weight.value) {
      isValid = isValid && weight.isValid;
    }

    if (loyalityPoints.value) {
      isValid = isValid && loyalityPoints.isValid;
    }

    if (manufacturer.value) {
      isValid = isValid && manufacturer.isValid;
    }

    if (props.productMapping.subCategoryIds.isRequired) {
      isValid = isValid && selectedSubCats.isValid;
    }

    if (props.productMapping.linkedProductIds.isRequired) {
      isValid = isValid && linkedProducts.isValid;
    }

    if (redemptionPoints.value || isRedeemable == 1) {
      isValid = isValid && redemptionPoints.isValid;
    }

    if (
      props.productMapping.gst.isRequired &&
      props.productMapping.gst.toBeShown == 1
    ) {
      isValid = isValid && GST.isValid;
    }

    if (props.productMapping.costPrice.isRequired) {
      isValid = isValid && costPrice.isValid;
    }

    if (unitValue.value) {
      isValid = isValid && unitValue.isValid;
    }

    if (props.productMapping.actualPrice.isRequired) {
      isValid = isValid && actualPrice.isValid;
    }
    // debugger;
    return isValid;
  };

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "description":
        changeDescription(Validations.validateTextField(value));
        break;
      case "skuId":
        changeSkuId(Validations.validateTextField(value, true, "skuId"));
        break;
      case "rank":
        changeRank(Validations.validateNumericValue(value, true, true, "Rank"));
        break;
      case "barcode":
        changeBarcode(Validations.validateTextField(value));
        break;
      case "costPrice":
        changeCostPrice(
          Validations.validateNumericValue(
            value,
            true,
            false,
            true,
            "Cost Price"
          )
        );
        break;
      case "actualPrice":
        changeActualPrice(
          Validations.validateNumericValue(
            value,
            true,
            false,
            true,
            "Selling Price"
          )
        );
        break;
      case "margin":
        changeMargin(Validations.validateMarginValue(value, "margin"));
        break;
      case "vat":
        changeVat(Validations.validateNumericValue(value));
        break;
      case "quantity":
        changeQuantity(
          Validations.validateNumericValue(value, true, false, "Quantity")
        );
        break;
      case "length":
        changeLength(
          Validations.validateNumericValue(value, true, false, "length")
        );
        break;
      case "breadth":
        changeBreadth(
          Validations.validateNumericValue(value, true, false, "breadth")
        );
        break;
      case "height":
        changeHeight(
          Validations.validateNumericValue(value, true, false, "height")
        );
        break;
      case "weight":
        changeWeight(
          Validations.validateNumericValue(value, true, false, "weight")
        );
        break;
      case "loyalityPoints":
        changeLoyalityPoints(
          Validations.validateNumericValue(value, true, false, "LoyalityPoints")
        );
        break;
      case "manufacturer":
        changeManufacturer(Validations.validateName(value, true));
        break;
      case "isFeatured":
        changeIsFeatured(value);
        break;
      case "isRedeemable":
        changeIsRedeemable(value);
        break;
      case "redemptionPoints":
        changeRedemptionPoints(
          Validations.validateNumericValue(
            value,
            true,
            false,
            "RedemptionPoints"
          )
        );
        break;
      case "purchasedStock":
        changePurchasedStock(Validations.validateNumericValue(value));
        break;
      case "gst":
        changeGST(Validations.validateNumericValue(value));
        break;
      case "baseValue":
        changeBaseValue(Validations.validateNumericValue(value));
        break;
      case "unitValue":
        changeUnitValue(
          Validations.validateNumericValue(value, true, false, "unitValue")
        );

        // changeUnitValue(Validations.validateTextField(value, "Unit Value"));

        break;
      default:
        break;
    }
  };

  const handleBrandChange = (e) => {
    updateBrands({
      ...brands,
      selected: e,
    });
    changeBrand(Validations.validateTextField(e));
  };

  const handleLinkedProductsChange = (value) => {
    changeLinkedProducts(Validations.validateMultiSelect(value.slice(0, 6)));
  };

  useEffect(() => {
    console.log(tagRef.current);
  }, []);

  const handleSubmit = () => {
    alert("submit");
  };

  const handleImageDelete = (index) => {
    changeCarousel(index - 1);
    changeImageURL(
      Validations.validateMultiSelect(
        imageURL.value.filter((val, i) => i !== index)
      )
    );
  };

  const getFormData = () => {
    const data = {
      name: name.value,
      description: description.value,
      brandId: brand.value,
      skuId: skuId.value,
      priority: parseFloat(rank.value),
      costPrice: parseFloat(costPrice.value),
      actualPrice: parseFloat(actualPrice.value),
      margin: parseFloat(margin.value),
      barcode: barcode.value,
      vat: parseFloat(vat.value),
      quantity: parseFloat(quantity.value),
      length: parseFloat(length.value),
      breadth: parseFloat(breadth.value),
      height: parseFloat(height.value),
      gst: parseFloat(GST.value),
      purchasedUnit: purchasedUnit.value,
      sellingUnit: sellingUnit.value,
      baseValue: baseValue.value,
      purchasedStock: parseInt(purchasedStock.value),
      weight: parseFloat(weight.value),
      loyalityPoints: parseFloat(loyalityPoints.value),
      manufacturer: manufacturer.value,
      image: imageURL.value,

      unitValue: unitValue.value,
      isRedeemable: isRedeemable ? 1 : 0,
      featureFlag: isFeatured ? 1 : 0,
      linkedProductIds: linkedProducts.value.map((i) => `${i}`),
      redemptionPoints: parseFloat(redemptionPoints.value),
      subCategoryIds: selectedSubCats.value,
      tags: selectedTags.value.join(","),
    };
    const allowedFields = Object.keys(props.productMapping).reduce(
      (acc, val) => {
        acc[val] = props.productMapping[val].toBeShown;
        return acc;
      },
      {}
    );

    const alldata = Object.keys(data).reduce((acc, val) => {
      if (allowedFields[val]) {
        acc[val] = data[val];
      }
      return acc;
    }, {});

    // debugger;
    return alldata;
  };

  const handleSubCategoriesChange = (data) => {
    changeSelectedSubCats(Validations.validateMultiSelect(data));
  };

  const handleImageChange = (data) => {
    console.log(imageURL.value, data);
    changeImageURL(Validations.validateMultiSelect([...imageURL.value, data]));
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const [isTagLoading, toggleTagLoading] = useState(false);
  const [tagName, changeTagName] = useState("");
  const [selectedTags, updateSelectedTags] = useState(
    Validations.validateMultiSelect([])
  );
  const handleTagNameChange = (e) => {
    e.stopPropagation();
    console.log("tagagagag", e.keyCode, e.target.value);

    changeTagName(e.target.value);
  };

  const handleTagAdd = () => {
    const tag = tagName.toLowerCase();
    if (!tag) {
      toastr.error("Error", "Tag Cannot Be Empty");
      return;
    }
    if (tags.indexOf(tag) !== -1) {
      toastr.error("Error", "Tag Already Exists");
    } else {
      changeTagName("");
      updateTags([...tags, tag]);
    }
  };

  const changeSelectedTags = (e) => {
    console.log(e);
    updateSelectedTags(Validations.validateMultiSelect(e));
  };

  const stahp = (e) => {
    if (e.keyCode == 8 && e.target.value == "") {
      e.stopPropagation();
    }
  };

  return (
    <CustomModal
      isVisible={props.isVisible}
      action={
        <>
          <Button
            shape={undefined}
            onClick={props.onCloseIconClick}
            size="large"
          >
            {intl.formatMessage({ id: "app.field.cancel" })}
          </Button>
          <Button
            shape={undefined}
            type="primary"
            loading={props.isLoading || isLoading}
            onClick={handleSubmit}
            size="large"
          >
            {intl.formatMessage({ id: "app.field.submit" })}
          </Button>
        </>
      }
      onCloseIconClick={props.onCloseIconClick}
      header={
        props.modalType === "ADD_PRODUCT" ? "Add Service" : "Edit Service"
      }
    >
      <Panel
        isModal
        className={styles.formModal}
        // containerClass={styles.wrapper}
        isLoading={props.isLoading || isLoading}
      >
        <div className={styles.formHeader}>Service Detail</div>
        <div className={styles.wrapper}>
          <div className={styles.formContainer}>
            <div ref={imageContainerRef} className={styles.imageContainer}>
              <div style={{ position: "relative", paddingBottom: "1.5rem" }}>
                {imageURL.value.length === 0 && (
                  <CustomImage style={{ height: 300 }} src={imageURL.value} />
                )}

                {imageURL.value.length !== 0 && (
                  <Carousel
                    dynamicHeight={true}
                    selectedItem={carousel}
                    onChange={changeCarousel}
                  >
                    {imageURL.value.map((image, index) => (
                      <div>
                        <CustomImage style={{ height: 300 }} src={image} />
                        <div
                          onClick={() => {
                            handleImageDelete(index);
                          }}
                          className={classNames([
                            "legend",
                            styles.deleteButton,
                          ])}
                        >
                          Delete
                        </div>
                      </div>
                    ))}
                  </Carousel>
                )}

                <UploadButton
                  onChange={handleImageChange}
                  parentRef={imageContainerRef}
                  accept="image/*"
                  id="op903ij2f8hubr"
                  resultType="url"
                  text={
                    intl.formatMessage({ id: "app.field.uploadImage" }) +
                    "(200x200)"
                  }
                />
                {!isPristine && <ErrorMessage field={imageURL} />}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div
                className={classNames({
                  [styles.formItem]: true,
                  // required:
                  //   props.productMapping.subCategoryIds.isRequired === 1,
                })}
              >
                <Label
                  value="Category"
                  optional={
                    props.productMapping.subCategoryIds.isRequired === 0
                  }
                />
                <Select
                  showArrow={true}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.subCategory",
                  })}
                  onChange={handleSubCategoriesChange}
                  value={selectedSubCats.value}
                >
                  {subcats.data.map((item) => (
                    <Option key={item.categoryId} value={item.categoryId}>
                      {item.categoryName}
                    </Option>
                  ))}
                  {/* <Option>All Products</Option> */}
                </Select>
                {!isPristine &&
                  props.productMapping.subCategoryIds.isRequired === 1 && (
                    <ErrorMessage field={selectedSubCats} />
                  )}
              </div>

              <div
                className={classNames({
                  [styles.formItem]: true,
                  // required: props.productMapping.description.isRequired === 1,
                })}
              >
                <Label
                  value="Services"
                  optional={props.productMapping.description.isRequired === 0}
                />
                <Input
                  name="description"
                  onChange={handleFieldChange}
                  value={description.value}
                  placeholder="Services"
                />
                {!isPristine &&
                  props.productMapping.description.isRequired === 1 && (
                    <ErrorMessage field={description} />
                  )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div
                className={classNames({
                  [styles.formItem]: true,
                  // required: props.productMapping.description.isRequired === 1,
                })}
              >
                <Label
                  value="Description"
                  optional={props.productMapping.description.isRequired === 0}
                />
                <Input name="description" placeholder="Enter Discreption" />
                {!isPristine &&
                  props.productMapping.description.isRequired === 1 && (
                    <ErrorMessage field={description} />
                  )}
              </div>
              <div
                className={classNames({
                  [styles.formItem]: true,
                  // required:
                  //   props.productMapping.subCategoryIds.isRequired === 1,
                })}
              >
                <Label
                  value="Type"
                  optional={
                    props.productMapping.subCategoryIds.isRequired === 0
                  }
                />
                <Select
                  showArrow={true}
                  // mode="multiple"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.subCategory",
                  })}
                >
                  {/* {subcats.data.map((item) => (
                    <Option key={item.categoryId} value={item.categoryId}>
                      {item.categoryName}
                    </Option>
                  ))} */}
                  <Option>Fixed Price</Option>
                  <Option>Quotations via proof</Option>
                  <Option>Quotations via visit</Option>
                </Select>
                {!isPristine &&
                  props.productMapping.subCategoryIds.isRequired === 1 && (
                    <ErrorMessage field={selectedSubCats} />
                  )}
              </div>
            </div>
            <div className={styles.formGroup}>
              {
                <div
                  className={classNames({
                    [styles.formItem]: true,
                  })}
                >
                  <Label value="Other Service" />
                  <Select placeholder="Select Other Service">
                    {Object.keys(unitNames).map((unit) => (
                      <Option key={unit} value={unit}>
                        {unitNames[unit]}
                      </Option>
                    ))}
                  </Select>

                  {!isPristine &&
                    props.productMapping.purchasedUnit.isRequired === 1 && (
                      <ErrorMessage field={purchasedUnit} />
                    )}
                </div>
              }

              {
                <div
                  className={classNames({
                    [styles.formItem]: true,
                  })}
                >
                  <Label
                    value="Price"
                    optional={
                      props.productMapping.purchasedStock.isRequired === 0
                    }
                  />

                  <Input name="purchasedStock" placeholder="Price" />
                  {!isPristine &&
                    props.productMapping.purchasedStock.isRequired === 1 && (
                      <ErrorMessage field={purchasedStock} />
                    )}
                </div>
              }
            </div>

            <div className={styles.formGroup}>
              {
                <div
                  className={classNames({
                    [styles.formItem]: true,
                  })}
                >
                  <Label
                    value="app.field.rank"
                    optional={props.productMapping.priority.isRequired === 0}
                  />

                  <Input
                    name="rank"
                    type="number"
                    min={1}
                    onChange={handleFieldChange}
                    value={rank.value}
                    placeholder={intl.formatMessage({
                      id: "app.placeholder.rank",
                    })}
                  />
                  {!isPristine &&
                    props.productMapping.priority.isRequired === 1 && (
                      <ErrorMessage field={rank} />
                    )}
                </div>
              }

              {
                <div
                  className={classNames({
                    [styles.formItem]: true,
                  })}
                >
                  <Label
                    value="Plan"
                    optional={
                      props.productMapping.purchasedUnit.isRequired === 0
                    }
                  />
                  <Select placeholder="Select Plan">
                    {Object.keys(unitNames).map((unit) => (
                      <Option key={unit} value={unit}>
                        {unitNames[unit]}
                      </Option>
                    ))}
                  </Select>
                  {/* <Input
                name="brand"
                onChange={handleBrandChange}
                value={brand.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.brands",
                })}
              /> */}
                  {!isPristine &&
                    props.productMapping.purchasedUnit.isRequired === 1 && (
                      <ErrorMessage field={purchasedUnit} />
                    )}
                </div>
              }
            </div>

            <div className={styles.formGroup}></div>

            <div className={styles.formGroup}>
              <div className={styles.formGroup}>
                {props.productMapping.isRedeemable.toBeShown === 1 && (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className={classNames({
                      [styles.formItem]: true,
                    })}
                  >
                    <Label
                      value="app.field.isRedeemable"
                      optional={
                        props.productMapping.isRedeemable.isRequired === 0
                      }
                    />
                    <Radio.Group
                      name="isRedeemable"
                      onChange={handleFieldChange}
                      value={isRedeemable}
                      defaultValue={!isRedeemable}
                      style={{
                        display: "flex",
                        // justifyContent: "space-around",
                      }}
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.formGroup}>
              {props.productMapping.linkedProductIds.toBeShown === 1 && (
                <div
                  className={classNames({
                    [styles.formItem]: true,
                  })}
                >
                  <Label
                    value="app.field.linkProducts"
                    optional={
                      props.productMapping.linkedProductIds.isRequired === 0
                    }
                  />
                  <Select
                    showArrow={true}
                    mode="multiple"
                    placeholder={intl.formatMessage({
                      id: "app.field.linkProducts",
                    })}
                  >
                    <Option>bread</Option>
                    <Option>rice</Option>
                    {/* <Option>All Products</Option> */}
                  </Select>
                  {!isPristine &&
                    props.productMapping.linkedProductIds.isRequired === 1 && (
                      <ErrorMessage field={linkedProducts} />
                    )}
                </div>
              )}
              {props.productMapping.redemptionPoints.toBeShown === 1 && (
                <div
                  style={{ display: isRedeemable == 1 ? "flex" : "none" }}
                  className={classNames({
                    [styles.formItem]: true,
                  })}
                >
                  <Label
                    value="app.field.redemptionPoints"
                    optional={
                      props.productMapping.redemptionPoints.isRequired === 0
                    }
                  />
                  <Input
                    name="redemptionPoints"
                    onChange={handleFieldChange}
                    value={redemptionPoints.value}
                    placeholder={intl.formatMessage({
                      id: "app.field.redemptionPoints",
                    })}
                  />
                  {!isPristine &&
                    isRedeemable == 1 &&
                    redemptionPoints.value !== undefined &&
                    redemptionPoints.value !== null &&
                    props.productMapping.redemptionPoints.isRequired === 0 && (
                      <ErrorMessage field={redemptionPoints} />
                    )}
                </div>
              )}
            </div>
            <div className={styles.formGroup}>
              {props.productMapping.tags.toBeShown === 1 && (
                <div
                  className={classNames({
                    [styles.formItem]: true,
                  })}
                >
                  <Label
                    value="app.field.tag"
                    optional={props.productMapping.tags.isRequired === 0}
                  />
                  <Select
                    ref={tagRef}
                    disabled={isTagLoading}
                    onInputKeyDown={stahp}
                    mode="multiple"
                    value={selectedTags.value}
                    onChange={changeSelectedTags}
                    placeholder="Enter Tags"
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8,
                          }}
                        >
                          <input
                            onKeyDown={(e) => {
                              // if(e.keyCode == 8 && e.target.value == "") {
                              e.stopPropagation();
                              // }
                            }}
                            style={{ flex: "auto" }}
                            value={tagName}
                            onInputKeyDown={stahp}
                            onChange={handleTagNameChange}
                          />
                          <a
                            style={{
                              flex: "none",
                              padding: "8px",
                              display: "block",
                              cursor: "pointer",
                            }}
                            onClick={handleTagAdd}
                          >
                            <PlusOutlined /> Add Tag
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {tags.map((item) => (
                      <Option key={item}>{item}</Option>
                    ))}
                  </Select>
                  {!isPristine &&
                    props.productMapping.tags.isRequired === 1 && (
                      <ErrorMessage field={selectedTags} />
                    )}
                </div>
              )}
            </div>

            <div className={styles.formHeader}>Questionnaire</div>

            <div style={{ width: "640px" }}>
              <CustomTable
                // isLoading={tableControls.isTableLoading}
                // isEmpty={tableControls.isTableEmpty}
                // dataSource={products.data}
                // updateStock={openUpdateStockModal}

                columnWidth={150}
                width
                // pagination={pagination}
                columns={columns()}
              />
            </div>
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditProduct;
