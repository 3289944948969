import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, Spin, List } from "antd";
import { Panel, CustomModal } from "../../../../components/common";
import AddQuestions from "./add-questions";
import axios from "../../../../shared/api/axiose";
import "./add-edit-user.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { checkRtl } from "../../../../shared/utils/rtl";
import { Select, Checkbox, Menu, Dropdown } from "antd";
const { Option } = Select;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
  datePicker: "datePicker",
  OTP_modal: "OTP_modal",
  OTP_input: "OTP_input",
};

function AddEditQuestion({
  getCustomersData,
  getCustomersDetail,
  onCloseIconClick,
  updateCustomerFlag,
  customerFlag,
  ...props
}) {
  const [isAddQuestionsVisible, setIsAddQuestionsVisible] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.isVisible) {
      if (props?.modalToShow === "EDIT") {
        setForm();
      } else {
        // resetForm();
      }
    }
  }, [props.isVisible, props?.data]);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const setForm = () => {
    // Implement your logic for setting the form
  };

  const closeModal = () => {
    onCloseIconClick();
    setIsAddQuestionsVisible(false);
  };

  const handleAdd = () => {
    setIsAddQuestionsVisible(true);
  };

  const handleAddQuestion = (newQuestion) => {
    setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    fetchQuestions();
  };

  const handleDeleteQuestion = (questionId) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((question) => question.questionId !== questionId)
    );
  };

  const handleDeleteQuestions = async () => {
    try {
      setIsLoading(true);
      await axios.delete("/questionnaire/deleteAllDefaultQuestion");
      setQuestions([]);
      toast.success("All questions deleted");
    } catch (error) {
      toast.error("Failed to delete questions");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchQuestions = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        "/questionnaire/getDefaultCustomerQuestions"
      );
      const { data } = response.data;
      setQuestions(data);
    } catch (error) {
      // console.error("Failed to fetch questions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuClick = async (questionId, action) => {
    if (action === "edit") {
      // Handle edit question
    } else if (action === "delete") {
      try {
        setIsLoading(true);
        await axios.delete(`/questionnaire/deleteQuestionById/${questionId}`);
        handleDeleteQuestion(questionId);
        toast.success("Question deleted");
      } catch (error) {
        toast.error("Failed to delete question");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderQuestionMenu = (questionId) => {
    const menu = (
      <Menu onClick={({ key }) => handleMenuClick(questionId, key)}>
        {/* <Menu.Item key="edit">Edit</Menu.Item> */}
        <Menu.Item key="delete">Delete</Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="link" style={{ padding: 0 }}>
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            style={{ color: "#6693e1" }}
            size="xl"
          />
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      {isAddQuestionsVisible && (
        <AddQuestions
          isVisible={props.isVisible}
          onClose={closeModal}
          onCloseIconClick={closeModal}
          modalToShow={props.modalToShow}
          data={props.data}
          modalControls={props.modalControls}
          isLoading={props.isLoading}
          onAddClick={handleAddQuestion}
          onQuestionAdded={fetchQuestions}
        />
      )}
      <CustomModal
        size="tiny"
        onCloseIconClick={closeModal}
        header={
          props.modalToShow === "ADD"
            ? "Customer Questionnaire"
            : "Customer Questionnaire"
        }
        action={
          <>
            <Button type="default" shape={undefined} onClick={closeModal}>
              <FormattedMessage id="app.field.cancel" />
            </Button>
            <Button
              type="primary"
              loading={props.isLoading}
              shape={undefined}
              onClick={handleAdd}
            >
              <FormattedMessage id="app.field.submit" />
            </Button>
          </>
        }
        isVisible={props.isVisible}
      >
        <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
          <div style={{ paddingLeft: "20rem" }}>
            <Button
              style={{ marginRight: "1rem" }}
              type="primary"
              loading={props.isLoading}
              shape={undefined}
              onClick={handleAdd}
            >
              <FormattedMessage id="Add Questions" />
            </Button>
            <Button
              type="default"
              shape={undefined}
              onClick={handleDeleteQuestions}
              style={{ backgroundColor: "#ffdacc" }}
            >
              <FormattedMessage id="Delete questions" />
            </Button>
          </div>
          <div>
            {isLoading ? (
              <div className="large-spin">
                <Spin size="large" />
              </div>
            ) : (
              questions.map((question, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "1.5rem",
                    padding: "1.5rem",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                      {question.questionText}
                    </p>
                    {renderQuestionMenu(question.questionId)}
                  </div>

                  {question.answerType === "single-selection" && (
                    <List
                      style={{ width: "570px", marginLeft: "1rem" }}
                      dataSource={question.answerOption}
                      renderItem={(option, optionIndex) => (
                        <List.Item key={optionIndex}>
                          <Checkbox
                            style={{
                              marginLeft: "1.5rem",
                              marginBottom: "1rem",
                            }}
                          >
                            {option}
                          </Checkbox>
                        </List.Item>
                      )}
                    />
                  )}
                  {question.answerType === "multiple-selection" && (
                    <List
                      style={{ width: "570px", marginLeft: "1rem" }}
                      dataSource={question.answerOption}
                      renderItem={(option, optionIndex) => (
                        <List.Item key={optionIndex}>
                          <Checkbox
                            style={{
                              marginLeft: "1.5rem",
                              marginBottom: "1rem",
                            }}
                          >
                            {option}
                          </Checkbox>
                        </List.Item>
                      )}
                    />
                  )}
                </div>
              ))
            )}
          </div>
        </Panel>
      </CustomModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  enabledPanels: state.user.enterprice.enabledPanels,
  isAddDisabled: state.user.isAddDisabled,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(injectIntl(AddEditQuestion));
