import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Header, Icon, Panel, Label } from "../../components/common";
import { Radio, DatePicker, Spin, Button } from "antd";
import { Form } from "./components";
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import axios from "../../shared/api/axiose";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import "./order-reports.scss";

const COLORS = ["#ffc266", "#ff6666", "#99ff99"];
const styles = {
  wrapper: "order-reports__wrapper",
  content: "order-reports__content",
  formWrapper: "order-reports__form-wrapper custom-section",
  chartWrapper: "order-reports__chart-wrapper",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  radioWrapper: "order-reports__form__radio-wrapper",
};

function OrderReports(props) {
  const [isLoading, toggleIsLoading] = useState(false);
  const [stores, updateStores] = useState([]);
  const [reportsData, updateReportsData] = useState({
    overallGraphData: {
      totalPending: 0,
      totalCancelled: 0,
      totalConfirmed: 0,
    },
    graphsData: [],
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState("day");

  const intl = useIntl();

  const getReportsData = async (formData) => {
    try {
      const response = await axios.get("/admins/reports/getBookingReports", {
        params: formData,
      });

      if (response.data.success) {
        updateReportsData(response.data.data);
      } else {
        // Handle unsuccessful response if needed
      }
    } catch (error) {
      // Handle API errors
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    if (startDate && endDate && selectedReportType) {
      toggleIsLoading(true);
      const formData = {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        reportType: selectedReportType,
      };
      getReportsData(formData)
        .then(() => {
          toggleIsLoading(false);
        })
        .catch(() => {
          toggleIsLoading(false);
        });
    }
  }, [startDate, endDate, selectedReportType]);

  return (
    <div className={styles.wrapper}>
      <Header title="Reports" />
      <div className={styles.content}>
        <div className={styles.formWrapper}>
          <div className={styles.formGroup}>
            <DatePicker
              onChange={(date) => setStartDate(date)}
              value={startDate}
              style={{ width: "550px", marginRight: "2.5rem" }}
              placeholder=" Select Start Date"
            />
            <DatePicker
              onChange={(date) => setEndDate(date)}
              value={endDate}
              style={{ width: "550px" }}
              placeholder="Select End Date"
            />
          </div>
          <h2
            style={{
              marginTop: "2rem",
              color: "rgba(21, 96, 189, 1)",
            }}
          >
            Report Type
          </h2>
          <div className={styles.formGroup}>
            <Radio.Group
              onChange={(e) => setSelectedReportType(e.target.value)}
              value={selectedReportType}
            >
              <Radio
                value="day"
                style={{ marginTop: "1rem", marginRight: "20rem" }}
              >
                Day
              </Radio>
              <Radio value="week" style={{ marginRight: "20rem" }}>
                Week
              </Radio>
              <Radio value="month" style={{ marginBottom: "2rem" }}>
                Month
              </Radio>
            </Radio.Group>
          </div>
          <Button
            type="primary"
            style={{
              backgroundColor: "rgba(21, 96, 189, 1)",
              color: "white",
              border: "none",
              marginLeft: "auto",
              width: "7rem",
              height: "2rem",
              cursor: "pointer",
            }}
            onClick={() =>
              getReportsData({
                startDate: startDate?.format("YYYY-MM-DD"),
                endDate: endDate?.format("YYYY-MM-DD"),
                reportType: selectedReportType,
              })
            }
          >
            Submit
          </Button>
        </div>
      </div>
      {startDate && endDate && selectedReportType && (
        <div className="chartContainer">
          {isLoading ? (
            <div className="loadingContainer">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <div className="itemOne">
                <h2
                  style={{
                    marginLeft: "2rem",
                    marginTop: "2rem",
                    color: "rgba(21, 96, 189, 1)",
                  }}
                >
                  Total Orders in Given Period
                </h2>
                <PieChart width={400} height={400}>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={[
                      {
                        name: "Pending",
                        value: reportsData.overallGraphData.totalPending,
                        fill: COLORS[0],
                      },
                      {
                        name: "Cancelled",
                        value: reportsData.overallGraphData.totalCancelled,
                        fill: COLORS[1],
                      },
                      {
                        name: "Confirmed",
                        value: reportsData.overallGraphData.totalConfirmed,
                        fill: COLORS[2],
                      },
                    ]}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label
                  />
                  <Tooltip />
                </PieChart>
              </div>
              <div className="itemTwo">
                <h2
                  style={{
                    marginLeft: "2rem",
                    marginTop: "2rem",
                    color: "rgba(21, 96, 189, 1)",
                  }}
                >
                  Details
                </h2>
                <BarChart
                  width={780}
                  height={400}
                  data={reportsData.graphsData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="totalPending"
                    fill="#8884d8"
                    name="Total Pending"
                  />
                  <Bar
                    dataKey="totalCancelled"
                    fill="#82ca9d"
                    name="Total Cancelled"
                  />
                  <Bar
                    dataKey="totalConfirmed"
                    fill="#ffc658"
                    name="Total Confirmed"
                  />
                </BarChart>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    color: state.user.enterprice.color,
    masterStoreId: state.user.enterprice.masterStoreId,
    branchId: state.user.userInfo.branchId,
    isRestaurant: state.user.userInfo.isRestaurant,
    panelType: state.user.enterprice.panelType,
  };
};

export default connect(mapStateToProps)(OrderReports);
