import React, { useState, useEffect, useRef } from "react";
import { Input, Select, Dropdown, Button, Upload } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import CustomUpload from "./CustomUpload";
import {
  Header,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../components/common";
import Validations from "../../shared/validations/Validations";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../shared/api/axiose";
import userActions from "../../redux/user-info/user-actions";
import {
  PAYMENT_METHODS_SETTINGS,
  PAYMENT_METHODS_SETTINGS_MAPPING,
  FLOW_STEP_MAPPING,
} from "../../shared/constants/orders";
import "./admin-settings.scss";
import {
  removeEmptyKeys,
  nestedParseJSON,
} from "../../shared/utils/object-manipulation";

import { AdminSetting } from "../../FakeData";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { getAdminInfo } from "../../shared/utils/local-storage";

const styles = {
  wrapper: "oyelabs__large-inv__admin-settings__wrapper",
  content: "oyelabs__large-inv__admin-settings__content",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  formHeader: "oyelabs__large-inv__admin-settings__form-header",
  formWrapper: "oyelabs__large-inv__admin-settings__form-wrapper",
  imageItem: "oyelabs__large-inv__admin-settings__image-item",
};

// const imageContainerRef = useRef(null);
const { Option } = Select;
const errorText = "Field cannot be empty";

function AdminAbout() {
  const [isLoading, setIsLoading] = useState(true);
  const [adminData, setAdminData] = useState({});

  useEffect(() => {
    // Fetch admin settings data from the API
    axios
      .get("/admins/settings")
      .then((response) => {
        if (response.data.success) {
          setAdminData(response.data.data);
        } else {
          console.error(
            "Error fetching admin settings:",
            response.data.message
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching admin settings:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Header title="About " />
      <Panel
        loaderStyle={{
          height: "76vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        isLoading={isLoading}
        className="custom-section"
      >
        <div style={{ textAlign: "center" }}>
          {adminData.logoImage && (
            <img
              src={adminData.logoImage}
              alt="Logo"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          )}
        </div>
        <div style={{ margin: "30px" }}>
          <p>{adminData.aboutCompany}</p>
        </div>
      </Panel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.user.locale,
  enabledLanguages: state.user.enterprice.enabledLanguages
    ? state.user.enterprice.enabledLanguages.split(",")
    : ["en"],
  userInfo: state.user.userInfo,
});

export default connect(mapStateToProps)(AdminAbout);
