import React, { useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

const AccessControl = (accessCheck, accessGrant) => {
  console.log("accessGrant => ", accessGrant);

  let access = false;

  const checkIfAnyElementIsPresent = (A, B) => {
    return A.some((elem) => B.includes(elem));
  };

  if (accessGrant && accessCheck) {
    access = checkIfAnyElementIsPresent(accessCheck, accessGrant);
    return access;
  }

  return access;
};

export { AccessControl };
