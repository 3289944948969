import React, { useState, useEffect } from "react";
import succ from "../../../assets/images/succ.svg";
import fucc from "../../../assets/images/fucc.svg";


const containerStyles = {display: 'flex', justifyContent: 'center', alignItems: 'center'}
const contentContainerStyles = {display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
function Payment(props) {
  const [paymentStatus, setPaymentStatus] = useState("");
  useEffect(() => {
    const status = getParameterByName("paymentStatus");
    if (status) {
      setPaymentStatus(status.toLowerCase());
    }
  }, []);
  return (
    <div style={containerStyles} className="external-container">
      {paymentStatus === "success" && (
        <div style={contentContainerStyles}>
          <img width="150" height="150" src={succ} alt="" />
          <h1>Payment Success!</h1>
        </div>
      )}
            {paymentStatus === "failure" && (
        <div style={contentContainerStyles}>
          <img width="150" height="150" src={fucc} alt="" />
          <h1>Payment Failed!</h1>
        </div>
      )}
    </div>
  );
}

export default Payment;
