import React, { useState } from "react";
import { Popconfirm, Tooltip, Switch, Menu, Dropdown, Rate } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";
import axiosConfig from "../../../shared/api/axiose";

import {
  Panel,
  Icon,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../../components/common";
import "./active-promo-table.scss";
import { connect } from "react-redux";
import ViewUser from "../view-user/view-user";

import { dateFormat } from "../../../shared/utils/local-storage";
import { toastr } from "react-redux-toastr";
import { AccessControl } from "../../AccessControl/accessControl";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  stickyAction: "sticky-action",
  actionIcon: "custom-table__action-icon",
  panelContainer: "active-promo-table__panel-container",
};

function ActiveRolesTable(props) {
  const intl = useIntl();
  const Switchbtn = (item) => {};

  const getRoleName = (id) => {
    let roleName;
    if (props.admin_types) {
      roleName = props.admin_types.filter((obj) => {
        return obj.id === id;
      });
    }
    console.log("getRoleName => ", roleName);
    return roleName ? roleName[0]?.title : "N/A";
  };
  const columns = [
    {
      fixed: "left",
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.sNo" }),
      render: (item, object, index) => {
        return (
          <span>
            {props.pagination.pageSize * (props.pagination.current - 1) +
              index +
              1}
          </span>
        );
      },
    },
    {
      dataIndex: "firstName",
      title: "First Name",
    },
    {
      dataIndex: "lastName",
      title: "Last Name",
    },
    {
      dataIndex: "email",
      title: "Email",
    },
    {
      dataIndex: "phone",
      title: "Phone Number",
    },

    {
      dataIndex: "roleGroupId",
      title: "Roles",
      render: (id) => {
        return getRoleName(id);
      },
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        return <CustomTag isBlocked={!item.isActive} />;
      },
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      render: (item) => {
        // debugger;
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const getMenu = (item) => {
    return (
      <Menu>
        {AccessControl(
          ["admin.read", "admin.update", "admin.create"],
          props?.subModules
        ) || true ? (
          <Menu.Item
            onClick={() => {
              props.toggleViewModal(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.view" })}
          </Menu.Item>
        ) : (
          ""
        )}

        {AccessControl(["admin.update", "admin.create"], props?.subModules) ||
        true ? (
          <Menu.Item
            onClick={() => {
              props.toggleEditModal(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.edit" })}
          </Menu.Item>
        ) : (
          ""
        )}

        {AccessControl(["admin.delete"], props?.subModules) || true ? (
          <Menu.Item
            onClick={() => {
              props.showDeleteConfirm(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.delete" })}
          </Menu.Item>
        ) : (
          ""
        )}
      </Menu>
    );
  };

  return (
    <>
      <CustomTable
        dataSource={props.dataSource}
        pagination={props.pagination}
        onChange={props.onChange}
        columns={columns}
        columnWidh={"50px"}
        isLoading={props.isLoading}
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  panelType: state.user.enterprice.panelType,
});

export default connect(mapStateToProps)(ActiveRolesTable);
