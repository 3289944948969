import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Carousel } from "react-responsive-carousel";
import {
  Input,
  Button,
  Radio,
  Select,
  Divider,
  DatePicker,
  Rate,
  TimePicker,
  Modal,
  Tag,
  List,
  Typography,
  Spin,
} from "antd";
import "./servicedetails.scss";
import { toastr } from "react-redux-toastr";
import classNames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  allValidUnits,
  validUnitCombinations,
  unitNames,
} from "../../../../shared/constants/products";
import { useParams } from "react-router-dom";
import Validations from "../../../../shared/validations/Validations";
import CustomTable from "../../../../components/common/custom-table/custom-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "../../../../shared/api/axiose";
import { AiOutlineCloudDownload } from "react-icons/ai";
import {
  Header,
  FullPageDrawer,
  Panel,
  CustomModal,
  CustomImage,
  ErrorMessage,
  Label,
  UploadButton,
} from "../../../../components/common";

import "./add-edit-service.scss";
import { dateFormat } from "../../../../shared/utils/local-storage";
import AddEditQuestion from "../add-edit-question/add-edit-question";
const styles = {
  wrapper: "customer-details__wrapper",
  menu: "customer-details__menu",
  pageSize: "customer-details__menu__records-per-page",
  image: "customer-details__image",
  form: "customer-details__form",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formWrapper: "customer-details__form-wrapper",
  editIcon: "customer-details__edit-icon",
  backButton: "customer-details__back-button",
};
const ServiceDetails = () => {
  const { id } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/services/${id}`)
      .then((response) => {
        setServiceData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching service data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);
  const convertToIST12Hour = (utcTime) => {
    const [hours, minutes] = utcTime.split(":");
    const utcDate = new Date();
    utcDate.setUTCHours(Number(hours), Number(minutes));

    const istOffset = 5.5 * 60;
    const istTime = new Date(utcDate.getTime() + istOffset * 60 * 1000);

    let istHours = istTime.getUTCHours();
    const istMinutes = istTime.getUTCMinutes();
    let ampm = "AM";

    if (istHours >= 12) {
      ampm = "PM";
      if (istHours > 12) {
        istHours -= 12;
      }
    }

    return `${istHours.toString().padStart(2, "0")}:${istMinutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  return (
    <div className={styles.wrapper}>
      <Header title="Service Details" showBackButton={true} />
      <Panel className="custom-section single">
        <div className={styles.formWrapper}>
          {isLoading ? (
            <Spin
              size="large"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "35rem",
                marginTop: "20rem",
                marginBottom: "35rem",
              }}
            />
          ) : (
            serviceData && (
              <div
                style={{ display: "flex", width: "100%", flexWrap: "nowrap" }}
              >
                <div
                  style={{
                    //   width: "100%",
                    minWidth: "200px",
                    height: "200px",
                  }}
                >
                  <img
                    style={{
                      // width: "100%",
                      maxWidth: "300px",
                      height: "200px",
                    }}
                    src={serviceData.serviceImg}
                  />
                </div>
                <div className={styles.form}>
                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <Divider orientation="left">Service Type</Divider>
                      <Tag
                        style={{
                          width: "15rem",
                          marginLeft: "1.5rem",
                          height: "2rem",
                          paddingTop: "0.3rem",
                          textAlign: "center",
                          fontSize: "15px",
                          borderRadius: "5px",
                          fontWeight: "600",
                        }}
                        color={
                          serviceData.isPickupService ? "#2db7f5" : "#2980B9"
                        }
                      >
                        {serviceData.isPickupService
                          ? "Pickup Service"
                          : "Regular Service"}
                      </Tag>
                    </div>
                    {serviceData.serviceTypeTag && (
                      <div className={styles.formItem}>
                        <Divider orientation="left">Service Type Tag</Divider>
                        <Tag
                          style={{
                            width: "15rem",
                            marginLeft: "5.5rem",
                            height: "2rem",
                            paddingTop: "0.3rem",
                            textAlign: "center",
                            fontSize: "15px",
                            borderRadius: "5px",
                            fontWeight: "600",
                          }}
                          color={
                            serviceData.serviceTypeTag ? "#2db7f5" : "#2980B9"
                          }
                        >
                          {serviceData.serviceTypeTag}
                        </Tag>
                      </div>
                    )}
                  </div>

                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <Divider orientation="left">Service Name</Divider>
                      <Input
                        style={{ width: "240px", marginLeft: "1.5rem" }}
                        value={serviceData.title}
                        disabled
                      />
                    </div>
                    <div className={styles.formItem}>
                      <Divider orientation="left">Category Name</Divider>
                      {serviceData.Categories &&
                      serviceData.Categories.length > 0 ? (
                        <div className={styles.formItem}>
                          {serviceData.Categories.map((category) => (
                            <div key={category.id}>
                              <Select
                                style={{ width: "240px", marginLeft: "1.5rem" }}
                                value={category.title}
                                disabled
                              ></Select>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <Input
                          style={{ width: "240px", marginLeft: "1.5rem" }}
                          disabled
                          value={"N/A"}
                        />
                      )}
                    </div>
                    <div className={styles.formItem}>
                      <Divider orientation="left">Actual Price</Divider>
                      <Input
                        style={{ width: "240px", marginLeft: "1.5rem" }}
                        value={serviceData.actualPrice}
                        disabled
                      />
                    </div>
                  </div>
                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <Divider orientation="left">Discounted Price</Divider>

                      <Input
                        style={{ width: "240px", marginLeft: "1.5rem" }}
                        value={serviceData.discountedPrice}
                        disabled
                      />
                    </div>
                    <div className={styles.formItem}>
                      <Divider orientation="left">Estimated Time</Divider>

                      <Input
                        style={{ width: "240px", marginLeft: "1.5rem" }}
                        value={serviceData.estimatedTimeInMinute}
                        disabled
                      />
                    </div>
                    <div className={styles.formItem}>
                      <Divider orientation="left">
                        Available Booking Slot Times:
                      </Divider>
                      {serviceData.availableBookingSlotTime ? (
                        <List
                          style={{ width: "240px", marginLeft: "1.5rem" }}
                          bordered
                          dataSource={serviceData.availableBookingSlotTime}
                          renderItem={(time) => (
                            <List.Item>{convertToIST12Hour(time)}</List.Item>
                          )}
                        />
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                  </div>
                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <Divider orientation="left">Service Included</Divider>
                      {serviceData.serviceIncluded ? (
                        <List
                          size="small"
                          bordered
                          disabled
                          dataSource={serviceData.serviceIncluded}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                    <div className={styles.formItem}>
                      <Divider orientation="left">Service Excluded</Divider>
                      {serviceData.excludes ? (
                        <List
                          size="small"
                          bordered
                          disabled
                          dataSource={serviceData.excludes}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                  </div>
                  <div className={styles.formGroup}>
                    {serviceData.subcategories &&
                      serviceData.subcategories.length > 0 && (
                        <div className={styles.formItem}>
                          <Divider orientation="left">Subcategories</Divider>
                          {serviceData.subcategories.map((subCategory) => (
                            <div
                              key={subCategory.id}
                              style={{
                                width: "650px",
                                height: "100px",
                                backgroundColor: "#D9EEFA",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                padding: "10px",
                                borderRadius: "10px",
                                marginBottom: "20px",
                              }}
                            >
                              <CustomImage
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  marginRight: "10px",
                                  borderRadius: "10px",
                                  marginLeft: "20px",
                                }}
                                src={subCategory.categoryIcon}
                              />
                              <p
                                style={{
                                  fontWeight: "600",
                                  marginLeft: "160px",
                                  fontSize: "15px",
                                }}
                              >
                                {subCategory.title}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <Divider orientation="left">Description:</Divider>

                      <Input.TextArea
                        rows={4}
                        value={serviceData.description}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          )}

          <footer></footer>
        </div>
      </Panel>
    </div>
  );
};

export default ServiceDetails;
