const PAYMENT_METHODS = {
  COD: 1,
  SADAD: 3,
  WALLET: 4,
  AREEBA: 5,
  CARD_ON_DELIVERY: 6,
  STRIPE: 7,
  RAZOR_PAY: 8,
};

const FLOW_STEP_MAPPING = {
  0: {
    key: 0,
    text: "Start From Store-Categories",
  },
  1: {
    key: 1,
    text: "Start From Stores List",
  },
  2: {
    key: 2,
    text: "Single Store",
  },
};

const PAYMENT_METHODS_MAPPING = {
  1: "Cash On Delivery",
  2: "Card",
  3: "SADAD",
  4: "Wallet",
  5: "Areeba",
  6: "Card On Delivery",
  7: "Stripe",
  8: "Razor Pay",
};

const PAYMENT_METHODS_SETTINGS_MAPPING = {
  1: "CARD",
};

const PAYMENT_METHODS_SETTINGS = {
  COD: 1,
  SADAD: 3,
  WALLET: 4,
  AREEBA: 5,
  CARD_ON_DELIVERY: 6,
  STRIPE: 7,
  RAZOR_PAY: 8,
};

const LABEL_FORMAT = {
  totalPendingOrders: "app.field.totalPendingOrders",
  totalDeliveredOrders: "app.field.totalDeliveredOrders",
  totalAcceptedOrders: "app.field.totalAcceptedOrders",
  totalCancelledOrders: "app.field.totalCancelledOrders",
};

const CHART_COLORS = {
  totalPendingOrders: "rgb(163, 160, 251)",
  totalDeliveredOrders: "rgb(255, 218, 131)",
  totalAcceptedOrders: "rgb(85, 216, 252)",
  totalCancelledOrders: "rgb(245, 130, 115)",
};

const ORDER_STATUS = {
  PARTIAL_REQUESTED: 5,
  REQUESTED: 17,
  ACCEPTED: 1,
  REJECTED: 8,
  ASSIGNED: 0,
  ON_THE_WAY: 4,
  REACHED: 18,
  COMPLETED: 2,
  CANCELLED: 9,
  CANCELLED_DUE_TO_PAYMENT: 12,
  ADMIN_CANCELLED: 13,
  DRIVER_ACCEPTED: 14,
  DRIVER_REJECTED: 15,
  DRIVER_FAILED: 16,
  PICK_UP: 19,
};

const PICKER_STATUS = {
  REQUESTED: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  PICKED: 3,
  JOB_NOT_ASSIGNED: -1,
};

const ORDER_STATUS_MAPPING = {
  5: "Partially Requested",
  17: "Requested",
  1: "Accepted",
  8: "Rejected",
  0: "Assigned",
  4: "On the way",
  18: "Reached",
  2: "Completed",
  9: "Cancelled",
  12: "Cancelled due to payment",
  13: "Cancelled by Admin",
  14: "Accepted by driver",
  15: "Rejected by driver",
  16: "Failed by driver",
  19: "Pick Up",
};

export {
  PAYMENT_METHODS,
  PAYMENT_METHODS_MAPPING,
  PICKER_STATUS,
  ORDER_STATUS_MAPPING,
  ORDER_STATUS,
  LABEL_FORMAT,
  CHART_COLORS,
  PAYMENT_METHODS_SETTINGS,
  PAYMENT_METHODS_SETTINGS_MAPPING,
  FLOW_STEP_MAPPING,
};
