import React, { useState, useEffect, useRef } from "react";
import { Input, Button, DatePicker, Select } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import classNames from "classnames";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../../components/common";
import axios from "../../../../shared/api/axiose";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Validations from "../../../../shared/validations/Validations";
import { multiSort } from "../../../../shared/utils/object-manipulation";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-promo-modal.scss";
import { dateFormat } from "../../../../shared/utils/local-storage";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formModal_wrapper: "active-promo__add-edit-promo-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "active-promo__add-edit-promo-modal__image-container",
  imageContainerImage:
    "active-promo__add-edit-promo-modal__image-container__image",
  imageContainerForm:
    "active-promo__add-edit-promo-modal__image-container__form",
  spanImp: "active-promo__add-edit-promo-modal__span-img",
  disabledColor: "disabledColor",
};

const errorText = "Field cannot be empty";

function AddEditPromoContainer({
  isVisible,
  modalToShow,
  onCloseIconClick,
  data,
  editCategory,
  isLoading,
  CategoryData,
  ...props
}) {
  console.log("AddEditPromoContainer", data);
  console.log("modalToShow", modalToShow);

  const intl = useIntl();
  const imageContainerRef = useRef(null);
  const [isPristine, togglePristine] = useState(true);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategories, setSubCategories] = useState([]);

  const handleAddSubCategoryClick = () => {
    handleAddCategoryBeforeSubcategory();
    setSubCategories([
      ...subCategories,
      {
        image: Validations.validateTextField(""),
        category: Validations.validateTextField(""),
      },
    ]);
  };

  const [category, updateCategory] = useState(
    Validations.validateTextField("")
  );
  console.log(" Category Input enabledDate category", category);
  const [enabledDate, updateEnabledDate] = useState(
    Validations.validateTextField(undefined)
  );
  console.log("Category Input enabledDate", enabledDate);

  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  console.log("Category Input imageURL", imageURL);

  const [selectedCities, updateSelectedCities] = useState([]);
  const [selectedState, updateSelectedState] = useState([]);
  const [selectedCountry, updateSelectedCountry] = useState("");
  const [status, updateStatus] = useState();

  /** Instaservice States */

  const resetForm = () => {
    console.log("setForm=>", data);
    togglePristine(true);
    updateEnabledDate(Validations.validateTextField(""));
    updateCategory(Validations.validateTextField(""));
    changeImageURL(Validations.validateTextField(""));
    setSubCategories([]);
  };

  const setForm = () => {
    // debugger
    console.log("setForm=>", data);
    let status = "Enabled";
    if (!data?.isEnabled) {
      status = "Disabled";
    }
    updateStatus(status);

    updateEnabledDate(
      Validations.validateTextField(moment(data?.createdAt).format(dateFormat))
    );
    updateCategory(Validations.validateTextField(data?.title));
    changeImageURL(Validations.validateTextField(data?.categoryIcon));
    if (data?.subcategories && data.subcategories.length > 0) {
      setSubCategories(data.subcategories);
    }
  };

  useEffect(() => {
    if (modalToShow === "EDIT" || modalToShow == "VIEW") {
      setForm();
    } else {
      resetForm();
    }
  }, [modalToShow, data]);

  const handleAddCategoryBeforeSubcategory = () => {
    const payload = {
      title: category.value,
      categoryIcon: imageURL.value,
    };

    axios
      .post("/category/", payload)
      .then((res) => {
        console.log("handleAdd", res);
        if (res?.data?.statusCode === 200) {
          // Extract id from the response
          setCategoryId(res.data.data.id);

          CategoryData();
          toast.success("Category added Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("sub", categoryId);
  const handleAdd = () => {
    const payload = {
      title: category.value,

      categoryIcon: imageURL.value,
    };
    axios
      .post("/category/", payload)
      .then((res) => {
        console.log("handleAdd", res);
        if (res?.data?.statusCode === 200) {
          onCloseIconClick();
          resetForm();
          CategoryData();
          toast.success("Category added Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("😥 Oppos... something went wrong");
      });
  };
  const handleAddNow = () => {
    subCategories.map((subCategory) => {
      const payload = {
        title: subCategory.category.value,
        categoryIcon: subCategory.image.value,
        parentCategory: categoryId,
      };

      axios
        .post("/category/", payload)
        .then((res) => {
          console.log("handleAdd", res);
          if (res?.data?.statusCode === 200) {
            onCloseIconClick();
            resetForm();
            CategoryData();
            toast.success("Category added Successfully");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("😥 Oops... something went wrong");
        });
    });
  };

  const handleEdit = (id) => {
    const payload = {
      title: category.value,
      categoryType: enabledDate.value,
      categoryIcon: imageURL.value,
    };
    console.log("handleEdit payload", payload);
    // onCloseIconClick();
    axios
      .put(`/category/${id}`, payload)
      .then((res) => {
        console.log("handleAdd", res);
        if (res?.data?.statusCode === 200) {
          onCloseIconClick();
          resetForm();
          CategoryData();
          toast.success("Category Edit Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const isFormValid = () => {
    let isValid = true;

    isValid = isValid && imageURL.isValid && category.isValid;

    return isValid;
  };

  const closeModal = () => {
    onCloseIconClick();
    resetForm();
  };
  const handleCategoryType = (val) => {
    updateEnabledDate(Validations.validateTextField(val));
  };

  const handlecategoryName = (e) => {
    updateCategory(Validations.validateTextField(e.target.value));
  };
  const formAction = () => {
    if (modalToShow === "ADD") {
      // Check if categoryId is null
      if (categoryId === null) {
        handleAdd();
      } else {
        handleAddNow();
      }
    } else if (modalToShow === "EDIT") {
      // editCategory();
      handleEdit(data?.id);
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };
  const handleSubImgChange = (images, index) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories[index].image = Validations.validateTextField(images);
    setSubCategories(updatedSubCategories);
  };
  const handleCategoryChange = (value, index) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories[index].category = Validations.validateTextField(value);
    setSubCategories(updatedSubCategories);
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        modalToShow === "ADD"
          ? "Add Categories"
          : modalToShow === "EDIT"
          ? "Edit Categories"
          : "View Categories"
      }
      action={
        modalToShow !== "VIEW" ? (
          <>
            {" "}
            <Button type="default" shape={undefined} onClick={closeModal}>
              {intl.formatMessage({ id: "app.button.cancel" })}
            </Button>
            <Button
              loading={isLoading}
              type="primary"
              shape={undefined}
              onClick={formAction}
            >
              {intl.formatMessage({ id: "app.button.submit" })}
            </Button>
          </>
        ) : (
          ""
        )
      }
      isVisible={isVisible}
    >
      <Panel
        isModal
        isLoading={isLoading}
        className={classNames({
          [styles.formModal]: true,
          [styles.formModal_wrapper]: true,
        })}
      >
        <div className={styles.imageContainer}>
          <div ref={imageContainerRef} className={styles.imageContainerImage}>
            <CustomImage src={imageURL.value} />
            {modalToShow !== "VIEW" ? (
              <UploadButton
                // disabled
                type="fileUpload"
                parentRef={imageContainerRef}
                resultType="url"
                accept="image/*"
                id="913fjhiu4ng2vri"
                text="Upload Image(300x300)"
                onChange={handleImageChange}
              />
            ) : (
              ""
            )}
            {!isPristine && !imageURL.value && (
              <ErrorMessage field={imageURL} />
            )}
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Category Name" />
            <Input
              disabled={modalToShow == "VIEW"}
              placeholder="Category Name"
              value={category.value}
              onChange={(e) => handlecategoryName(e)}
            />
            {!isPristine && <ErrorMessage field={category} />}
          </div>

          {modalToShow == "VIEW" ? (
            <div className={styles.formItem}>
              <Label value="Enabled Date" />
              <Select
                className={styles.disabledColor}
                disabled={modalToShow == "VIEW"}
                // value={typeOfPromo.value}
                value={enabledDate.value}
              ></Select>
              {!isPristine && !enabledDate.value && (
                <ErrorMessage text={errorText} />
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formItem}></div>
        </div>
        {modalToShow == "VIEW" ? (
          <>
            <div className={styles.formItem}>
              <Label value="Status" />
              <Input
                disabled={modalToShow == "VIEW"}
                placeholder="Status"
                value={status}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </Panel>
    </CustomModal>
  );
}
const mapState = (state) => ({
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
});
export default connect(mapState)(AddEditPromoContainer);
