import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select, Radio, TimePicker } from "antd";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import classNames from "classnames";
import moment from "moment";
import GoogleMap from "../../../../components/google-map/google-map";
import { Marker, StandaloneSearchBox } from "@react-google-maps/api";
import StoresCategoryModal from "../store-categories-modal/store-categories-modal";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import { dateFormat } from "../../../../shared/utils/local-storage";

import {
  Panel,
  ErrorMessage,
  CustomModal,
  Label,
  DelayedInput,
} from "../../../../components/common";
import countryCodes from "../../../../shared/constants/countries.json";
import {
  country,
  states,
  cities,
  zipcode,
} from "../../../../shared/constants/countryFilterData";
import "./stores-modal.scss";
import { SearchOutlined } from "@ant-design/icons";
import axiosConfig from "../../../../shared/api/axiose";
//
const styles = {
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formHeader: "custom-form-title",
  multySelect_disabled: "multySelect_disabled",
  content: "custom-section",

  formContainer: "stores-modal__form-container",
  formContent: "stores-modal__form-content",
  imageContainer: "stores-modal__image-container",
  formLeft: "stores-modal__form-container__left",
  formRight: "stores-modal__form-container__right",
  wrapper: "stores-modal__wrapper",
  googleMap: "stores-modal__google-map",
  imageContainerItem: "stores-modal__image-container__item",
  categoryContainer: "stores-modal__category-container",
  disabled: "disabled",
  categoryTag: {
    wrapper: "stores-modal__category-tag__wrapper",
    text: "stores-modal__category-tag__text",
    rank: "stores-modal__category-tag__rank",
    name: "stores-modal__category-tag__name",
    iconContainer: "stores-modal__category-tag__icon-container",
  },
};

const { Option } = Select;

function StoresModal({ getCityData, getCategoriesData, cityData, ...props }) {
  const errorMessage = "Please fill the field";

  // Instaservice States =================================================================
  const intl = useIntl();
  const [city, setCity] = useState(Validations.validateMultiSelect(undefined));
  const [country__, setCountry] = useState(
    Validations.validateTextField(undefined)
  );
  const [cityPrice_Multiplier, setCityPrice_Multiplier] = useState(
    Validations.validateNumericValue(undefined)
  );

  const [zipCode, setZipCode] = useState(
    Validations.validateMultiSelect(undefined)
  );
  const [categorySelect, setCategorySelect] = useState(
    Validations.validateMultiSelect(undefined)
  );
  console.log("categorySelect city => ", categorySelect);
  const [state, setState] = useState(
    Validations.validateMultiSelect(undefined)
  );
  const [savedCity, setSavedCity] = useState(Validations.validateObject({}));
  const [isPristine, changeIsPristine] = useState(true);
  const [filter, updateFilter] = useState({
    country: {},
    state: {},
    city: {},
    zipcode: {},
  });

  const [address, changeAddress] = useState(Validations.validateTextField(""));
  const [pinCode, setPinCode] = useState(Validations.validateTextField(""));
  const [latLng, updateLatLng] = useState({
    lat: null,
    lng: null,
  });
  const mapRef = useRef(null);
  const locationSearchRef = useRef(null);
  const inputRef = useRef(null);

  const markerRef = useRef(null);
  const [isAddressLoading, changeIsAddressLoading] = useState(false);

  // Instaservice States =================================================================

  const handleCityPrice_Multiplier = (e) => {
    setCityPrice_Multiplier(Validations.validateNumericValue(e.target.value));
  };

  const isFormValid = () => {
    // debugger
    let isValid = true;

    isValid =
      isValid &&
      city?.isValid &&
      country__?.isValid &&
      zipCode?.isValid &&
      state?.isValid &&
      categorySelect?.isValid &&
      (!cityPrice_Multiplier?.value ||
        (cityPrice_Multiplier?.value && cityPrice_Multiplier?.isValid));

    return isValid;
  };

  const resetForm = () => {
    setCity(Validations.validateMultiSelect(undefined));
    setCountry(Validations.validateTextField(undefined));
    setZipCode(Validations.validateMarginValue(undefined));
    setCategorySelect(Validations.validateMarginValue(undefined));
    setState(Validations.validateMarginValue(undefined));
    changeIsPristine(true);
    setCityPrice_Multiplier(Validations.validateNumericValue(undefined));

    setSavedCity(Validations.validateObject(""));
  };
  const setForm = () => {
    const { name, country, state, zipCode, categoryId } = props?.data;
    console.log("Set form City => ", props?.data);
    //      {
    //     "id": 2,
    //     "categoryId": "[{\"categoryId\":2},{\"categoryId\":1}]",
    //     "name": "Houston, Texas",
    //     "country": "France",
    //     "state": "Illinois",
    //     "zipCode": "[{\"zipCode\":\"12458787\"},{\"zipCode\":\"1100548\"},{\"zipCode\":\"64679974\"}]",
    //     "status": true,
    //     "cityMultiplier": "5",
    //     "createdBy": null,
    //     "createdAt": "2023-04-11T09:40:06.295Z",
    //     "updatedAt": "2023-04-11T09:40:06.295Z"
    // }
    let id_array = [];
    let categoryData = JSON.parse(props.data.categoryId);

    categoryData.map((obj) => {
      id_array.push(obj.categoryId);
    });

    // {
    //   "value": [
    //     "2255475",
    //     "64679974",
    //     "7858569",
    //     "25487979",
    //     "244779"
    //   ],
    // [{ "zipCode": "1100548" }, { "zipCode": "2255475" }, { "zipCode": "64679974" }, { "zipCode": "7858569" }, { "zipCode": "25487979" }, { "zipCode": "244779" }, { "zipCode": "4464664" }, { "zipCode": "12458787" }, { "zipCode": "78965665" }, { "zipCode": "454797997" }]
    // }

    let val = getCategoriesData.filter((obj) => {
      return obj.id == categoryId;
    });
    console.log("props?.data zipCode =>", zipCode);

    let zipCodeArr = JSON.parse(zipCode);
    let arr = [];
    zipCodeArr.map((obj) => {
      let zip_code = obj?.zipCode;
      arr.push(zip_code);
    });
    if (props.data.cityMultiplier) {
      setCityPrice_Multiplier(
        Validations.validateNumericValue(props.data.cityMultiplier)
      );
    }

    if (id_array) {
      setCategorySelect(Validations.validateMultiSelect(id_array));
    }
    changeIsPristine(true);

    setCity(Validations.validateMultiSelect(name));
    setCountry(Validations.validateTextField(country));

    setZipCode(Validations.validateMultiSelect(arr));
    setState(Validations.validateMultiSelect(state));
  };

  useEffect(() => {
    if (props.isVisible) {
      if (props.modalType === "ADD") {
        resetForm();
      } else {
        setForm();
      }
    }
  }, [props.isVisible, props?.data]);

  const handleEdit = () => {
    const { id, status } = props?.data;

    let zip = [];
    let category = [];
    // console.log("handleEdit = > ", zipCode?.value)
    if (Array.isArray(zipCode.value)) {
      zipCode.value.map((val) => {
        let obj = {};
        obj.zipCode = val;
        zip.push(obj);
      });
    } else {
      let obj = {};
      obj.zipCode = zipCode.value;
      zip.push(obj);
    }
    categorySelect.value.map((val) => {
      let obj = {};
      obj.categoryId = val;
      category.push(obj);
    });

    console.log("handleEdit = > ", zip);
    let payload = {
      name: city.value,
      country: country__?.value,
      state: state.value,
      zip: zip,
      status: status,
      category: category,
    };
    if (cityPrice_Multiplier.value) {
      payload.cityMultiplier = cityPrice_Multiplier.value;
    }

    axiosConfig
      .put(`/city/${id}`, payload)
      .then((res) => {
        if (res?.data?.statusCode >= "200" && res?.data?.statusCode <= "299") {
          toastr.success("City Updated successfully!");
          getCityData();

          resetForm();
        } else {
        }
        props.onCloseIconClick();
      })
      .catch((err) => {
        console.log("city edit err", err);
      });
  };

  const handleAdd = () => {
    // return ;

    // name: city.value,
    //   country: country.value,
    //     state: state.value,
    //       zipCode: zipCode.value,
    //         status: true,
    let zip = [];
    zipCode.value.map((val) => {
      let obj = {};
      obj.zipCode = val;
      zip.push(obj);
    });
    let category = [];
    categorySelect.value.map((val) => {
      let obj = {};
      obj.categoryId = val;
      category.push(obj);
    });
    // cityPrice_Multiplier
    let payload = {
      name: city.value,
      country: country__.value,
      state: state.value,
      zip: zip,
      status: true,
      category: category,
    };
    if (cityPrice_Multiplier.value) {
      payload.cityMultiplier = cityPrice_Multiplier.value;
    }
    axiosConfig
      .post(`/city`, payload)
      .then((res) => {
        if (res?.data?.statusCode >= "200" && res?.data?.statusCode <= "299") {
          toastr.success("City Added successfully!");
          getCityData();

          resetForm();
        } else {
        }
        props.onCloseIconClick();
      })
      .catch((err) => {
        console.log("city edit err", err);
      });
  };

  const handleSubmit = () => {
    changeIsPristine(false);
    if (!isFormValid()) {
      toastr.warning(intl.formatMessage({ id: "app.message.formCheck" }));
      return;
    }

    if (props.modalType == "EDIT") {
      handleEdit();
    } else {
      handleAdd();
    }
  };

  const handleModalClose = () => {
    props.onCloseIconClick();
    resetForm();
  };

  const handleCitySelect = (item) => {
    let obj = JSON.parse(item);

    let id_array = [];
    let categoryData = JSON.parse(obj?.categoryId);

    categoryData.map((data) => {
      id_array.push(data.categoryId);
    });

    setSavedCity(Validations.validateObject(obj));

    let zipCode = JSON.parse(obj?.zipCode);

    let zipArr = [];

    if (zipCode) {
      zipCode.map((obj) => {
        zipArr.push(obj.zipCode);
      });
    }

    setZipCode(Validations.validateMultiSelect(zipArr));
    setCountry(Validations.validateTextField(obj?.country));
    setCity(Validations.validateMultiSelect(obj?.name));
    setCategorySelect(Validations.validateMultiSelect(id_array));
    setState(Validations.validateMultiSelect(obj?.state));
  };

  const handleCityChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "state":
        setState(Validations.validateMultiSelect(value));
        break;
      case "city":
        setCity(Validations.validateMultiSelect(value));
        break;
      case "country":
        setCountry(Validations.validateTextField(value));
        break;
      case "zipCode":
        setZipCode(Validations.validateMultiSelect(value));

        break;

      default:
        return;
    }
  };
  const handleFilterCountry = (val) => {
    let data = JSON.parse(val);
    setCountry(Validations.validateTextField(data?.text));
    // updateFilter({
    //   ...filter,
    //   country: data
    // })
  };
  const handleFilterState = (val) => {
    setState(Validations.validateMultiSelect(val));

    // updateFilter({
    //   ...filter,
    //   state: val
    // })
  };
  const handleFilterCity = (val) => {
    // let data = JSON.parse(val)
    setCity(Validations.validateMultiSelect(val));
    // updateFilter({
    //   ...filter,
    //   city: val
    // })
  };

  const handleFilterZipcode = (val) => {
    // let data = JSON.parse(val)
    setZipCode(Validations.validateMultiSelect(val));
    updateFilter({
      ...filter,
      zipcode: val,
    });
  };
  const handleCategorySelect = (val) => {
    setCategorySelect(Validations.validateMultiSelect(val));
  };

  const handleMapLoad = (ref) => {
    mapRef.current = ref;
  };
  const handleSearchOnLoad = (ref) => {
    locationSearchRef.current = ref;
  };
  const handleSearchPlaceChange = (data) => {
    const places = locationSearchRef.current.getPlaces();

    if (places.length) {
      const bounds = new window.google.maps.LatLngBounds();
      changeAddress(Validations.validateTextField(places[0].formatted_address));
      updateLatLng({
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng(),
      });
      if (
        places[0]?.address_components?.slice(-1)[0]?.types[0] === "postal_code"
      ) {
        setPinCode(
          Validations.validateTextField(
            places[0]?.address_components?.slice(-1)[0]?.long_name
          )
        );
        // getCounty();
      }
      places.forEach((place) => {
        if (!place.geometry) {
          return;
        }
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      mapRef.current.fitBounds(bounds);
    }
  };
  const handleInputFocus = (e) => {
    inputRef.current.value = "";
  };
  const handleMarkerDrag = (marker) => {
    const lat = marker.latLng.lat();
    const lng = marker.latLng.lng();
    mapRef.current.setCenter(marker.latLng);
    changeIsAddressLoading(true);
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCSHHKKFCGHd8jd0_jlhytWadlpFLX6h9k`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.results.length) {
          changeAddress(
            Validations.validateTextField(res.results[0].formatted_address)
          );
          if (
            res.results[0]?.address_components?.slice(-1)[0]?.types[0] ===
            "postal_code"
          ) {
            setPinCode(
              Validations.validateTextField(
                res.results[0]?.address_components?.slice(-1)[0]?.long_name
              )
            );
            // getCounty();
          }
        }
        changeIsAddressLoading(false);
      })
      .catch((err) => {
        changeIsAddressLoading(false);
        // toastr.error("Error", "Something Went Wrong");
      });
    updateLatLng({
      lat,
      lng,
    });
  };

  const handleMarkerLoad = (ref) => {
    markerRef.current = ref;
    mapRef.current.setCenter(ref.position);
    mapRef.current.setZoom(18);
  };

  return (
    <CustomModal
      width={750}
      isVisible={props.isVisible}
      onCloseIconClick={handleModalClose}
      action={
        props.modalType !== "VIEW" ? (
          <>
            <Button shape={undefined} onClick={handleModalClose} size="large">
              Cancel
            </Button>
            <Button
              shape={undefined}
              type="primary"
              loading={props.isLoading}
              onClick={handleSubmit}
              size="large"
            >
              Submit
            </Button>
          </>
        ) : (
          ""
        )
      }
      header={
        props.modalType === "ADD"
          ? "Add City"
          : props.modalType === "VIEW"
          ? "View City Details"
          : "Edit City"
      }
    >
      <Panel
        isModal
        className={styles.formModal}
        // containerClass={styles.wrapper}
        isLoading={props.isLoading}
      >
        <div className={styles.wrapper}>
          <div className={styles.formContainer}>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Copy Data from City" optional />

                <Select
                  showSearch
                  placeholder="City"
                  disabled={props.modalType === "VIEW"}
                  onChange={handleCitySelect}
                  maxTagCount={1}
                  value={savedCity ? savedCity?.value?.name : ""}
                >
                  {cityData?.data.map((item) => (
                    <Option key={item?.id} value={JSON.stringify(item)}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className={styles.formItem}>
                <Label value="Category" />
                <Select
                  showSearch
                  placeholder="Select Categories"
                  mode="multiple"
                  maxTagCount={3}
                  className={classNames({
                    [styles.multySelect_disabled]: props.modalType === "VIEW",
                  })}
                  maxTagTextLength={6}
                  onChange={handleCategorySelect}
                  value={categorySelect ? categorySelect?.value : ""}
                >
                  {getCategoriesData &&
                    getCategoriesData?.map((item) => (
                      <Option
                        key={item?.id}
                        disabled={props.modalType === "VIEW"}
                        value={item?.id}
                      >
                        {item?.title}
                      </Option>
                    ))}
                </Select>
                {!isPristine && !categorySelect?.isValid && (
                  <ErrorMessage text={categorySelect?.errMsg} />
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={classNames([styles.formItem])}>
                <Label value="Select Country"></Label>
                <Select
                  showSearch
                  placeholder="Country"
                  disabled={props.modalType === "VIEW"}
                  maxTagCount={2}
                  onChange={handleFilterCountry}
                  value={country__?.value}
                >
                  {country?.map((item) => (
                    <Option key={item.id} value={JSON.stringify(item)}>
                      {item.text}
                    </Option>
                  ))}
                </Select>
                {!isPristine && !country__?.isValid && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
              <div className={classNames([styles.formItem])}>
                <Label value="Select State" vertical></Label>
                <Select
                  showSearch
                  placeholder="State"
                  onChange={handleFilterState}
                  disabled={props.modalType === "VIEW"}
                  value={state?.value}

                  // value={stores.selected}
                >
                  {country__?.value &&
                    states.map((item) => (
                      <Option key={item.id} value={item?.text}>
                        {item.text}
                      </Option>
                    ))}
                </Select>
                {!isPristine && !state?.isValid && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={classNames([styles.formItem])}>
                <Label value="Select City" vertical></Label>
                <Select
                  showSearch
                  placeholder="City"
                  disabled={props.modalType === "VIEW"}
                  value={city?.value}
                  // mode="multiple"
                  onChange={handleFilterCity}
                >
                  {state?.value &&
                    cities.map((item) => (
                      <Option key={item.id} value={item?.text}>
                        {item.text}
                      </Option>
                    ))}
                </Select>
                {!isPristine && !city?.isValid && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>

              <div className={styles.formItem}>
                <Label value="Select Zip Code" vertical></Label>
                <Select
                  showSearch
                  placeholder="Zip Code"
                  value={zipCode?.value}
                  className={classNames({
                    [styles.multySelect_disabled]: props.modalType === "VIEW",
                  })}
                  name="zipCode"
                  mode="multiple"
                  maxTagCount={2}
                  maxTagTextLength={6}
                  onChange={handleFilterZipcode}
                >
                  {city?.value &&
                    zipcode.map((item) => (
                      <Option
                        key={item.id}
                        disabled={props.modalType === "VIEW"}
                        value={item?.text}
                      >
                        {item.text}
                      </Option>
                    ))}
                </Select>
                {!isPristine && !zipCode?.isValid && (
                  <ErrorMessage selectOption />
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={classNames([styles.formItem])}>
                <Label value="City Price Multiplier" vertical optional></Label>
                <Input
                  name="cityPrice_Multiplier"
                  placeholder="Type City Price Multiplier"
                  value={cityPrice_Multiplier?.value}
                  disabled={props.modalType === "VIEW"}
                  onChange={(e) => handleCityPrice_Multiplier(e)}
                />
                {!isPristine &&
                  cityPrice_Multiplier?.value &&
                  !cityPrice_Multiplier?.isValid && (
                    <ErrorMessage text={cityPrice_Multiplier?.errMsg} />
                  )}
              </div>

              <div className={styles.formItem}></div>
            </div>

            <div className={styles.mapForm}>
              <div className={styles.googleMap}>
                <GoogleMap drawingControl={false} onLoad={handleMapLoad}>
                  <StandaloneSearchBox
                    onLoad={handleSearchOnLoad}
                    onPlacesChanged={handleSearchPlaceChange}
                  >
                    <input
                      autoComplete={true}
                      ref={(ref) => (inputRef.current = ref)}
                      onFocus={handleInputFocus}
                      placeholder="Search location"
                      style={{
                        position: "absolute",

                        top: 0,
                        left: "37%",
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                      }}
                    />
                  </StandaloneSearchBox>

                  {latLng.lat !== null && (
                    <Marker
                      draggable={true}
                      onDragEnd={handleMarkerDrag}
                      position={latLng}
                      onLoad={handleMarkerLoad}
                    />
                  )}
                </GoogleMap>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default StoresModal;
