import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select } from "antd";
import { useIntl } from "react-intl";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  CustomImage,
  Label,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./store-categories-modal.scss";
import { toastr } from "react-redux-toastr";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  imageContainer: "store-categories__add-edit-modal__image-container",
  imageContainerImage:
    "store-categories__add-edit-modal__image-container__image",
  imageContainerForm: "store-categories__add-edit-modal__image-container__form",
  formGroup: "custom-form-group",
};


function AddEditStoreCategory(props) {
  const intl = useIntl();

  const [isPristine, togglePristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [rank, changeRank] = useState(Validations.validateNumericValue(null));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  const imageContainerRef = useRef(null);

  const resetForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(null));
    changeRank(Validations.validateNumericValue(null));
    changeImageURL(Validations.validateTextField(null));
  };

  const setForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(props.data.categoryName));
    // changeDescription(Validations.validateTextField(props.data.description));
    changeRank(Validations.validateNumericValue(props.data.priority));
    changeImageURL(Validations.validateTextField(props.data.categoryImage));
  };


  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
   
      case "rank":
        changeRank(Validations.validateNumericValue(value, true, true, "rank"));
        break;
      default:
        break;
    }
  };

  const getData = () => ({
    categoryName: name.value,
    // "description" :  description.value ,
    categoryImage: imageURL.value,
    categoryIcon: imageURL.value,
    priority: rank.value,
  });

  const handleAdd = () => {
    // TODO
    alert("Submit")
    props.addCategory(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editCategory(getData());
  };

  const isFormValid = () => {
    return name.isValid && rank.isValid && imageURL.isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const formAction = () => {

    togglePristine(false);
    if (props.modalToShow === "ADD_CATEGORY") {
      handleAdd();
    } else if (props.modalToShow === "EDIT_CATEGORY") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };
  const Option = {Select}
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD_CATEGORY"
          ? "Add Store Category" : "Edit Store Category"
        // ? "app.placeholder.addCategory"
        // : "app.placeholder.editCategory"
      }
      action={
        <>
          <Button type="default" shape={undefined} onClick={closeModal}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button
            type="primary"
            loading={props.isLoading}
            shape={undefined}
            onClick={formAction}
          >
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </>
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.imageContainer}>
          <div ref={imageContainerRef} className={styles.imageContainerImage}>
            <CustomImage src={imageURL.value} />
            <UploadButton
              parentRef={imageContainerRef}
       
              resultType="url"
              accept="image/*"
              text="Upload Image(1200x400)"
              onChange={handleImageChange}
            />
            {!isPristine && <ErrorMessage field={imageURL} />}
          </div>

          <div className={styles.imageContainerForm}>
            <div className={styles.formItem}>
              <Label value="Name" />
              <Input
            
                type="text"
                min={1}
     
         
                placeholder= "Name"
              />
              {!isPristine && <ErrorMessage field={rank} />}
            </div>
            <div className={styles.formItem}>
              <Label value="E-mail" />
              <Input
               placeholder="E-mail"
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>

            <div className={styles.formItem}>
              <Label value="Phone No" />
              <Input
                placeholder="Phone No"
              />
              {!isPristine && <ErrorMessage field={name} />}
            </div>
          </div>

        
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Type" />
            <Select
              placeholder="Type"
            > 
              <Option

                value={`Agency`}
              > Agency
              </Option>
              <Option

                value={`Individual`}
              > Individual
              </Option>
            
            </Select>
            {!isPristine && <ErrorMessage field={name} />}
          </div>
          <div className={styles.formItem}>
            <Label value="Phone No" />
            <Input
              placeholder="Phone No"
            />
            {!isPristine && <ErrorMessage field={name} />}
          </div>

        </div><div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Cities" />
            <Input
              placeholder="Cities"
            />
            {!isPristine && <ErrorMessage field={name} />}
          </div>
          <div className={styles.formItem}>
            <Label value="Categories" />
            <Select
              placeholder=""
            > 
              <Option

                value={`Agency`}
              > Agency
              </Option>
              <Option

                value={`Individual`}
              > Individual
              </Option>
            </Select>
            {!isPristine && <ErrorMessage field={name} />}
          </div>

        </div>
        
        
       
      </Panel>
    </CustomModal>
  );
}

export default AddEditStoreCategory;
