import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button, Input } from "antd";
import { useIntl } from "react-intl";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
} from "../../components/common";
import { ActivePromoTable, AddEditPromoModal } from "./components";

import { PromoCodeData } from "../../FakeData";

import "./PromoCodes.scss";
import { checkRtl } from "../../shared/utils/rtl";
import { findAllByDisplayValue } from "@testing-library/react";
import axiosConfig from "../../shared/api/axiose";
import axios from "../../shared/api/axiose";
import categoriesAction from "../../redux/categories/categories-actions";

const { Option } = Select;
// import { useDispatch } from "react-redux";

const styles = {
  wrapper: "active-promo__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "active-promo__menu--left",
  menuRight: "active-promo__menu--right",
  pageSize: "active-promo__records-per-page",
};

function PromoCodes({ fetchData, ...props }) {
  // const dispatch = useDispatch();
  const intl = useIntl();
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [search, updateSearch] = useState("");
  const [categories_data, setCategories_data] = useState();
  const [service_data, setServicesData_data] = useState();

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isDeleteModalVisible: false,
    isLoading: false,
  });

  const [promocodes, updatePromos] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });
  console.log("pro", promocodes);

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    total: 0,
    current: 1,
    search: "",
  });
  // const handlePaginationChange = ({ current, pageSize, total }) => {
  //   updatePagination({
  //     ...pagination,
  //     current,
  //     total,
  //     pageSize,
  //     skip: (current - 1) * pagination.pageSize,
  //   });
  // };

  const handlePaginationChange = (page, total) => {
    updatePagination({
      ...pagination,
      total: page.total,
      current: page.current,
      skip: (page.current - 1) * page.pageSize,
    });
  };

  const handleRecordsPerPageChange = (val) => {
    updatePagination({
      ...pagination,
      pageSize: val,
    });
  };

  const openAddPromoModal = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "ADD",
    });
  };

  const addPromo = (data) => {
    console.log(data);

    alert("Add promo");
  };

  const editPromo = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
    data.promoId = modalControls.data.promoId;
    console.log(data);
    alert("Edit promo code");
  };

  const openEditPromoModal = (data) => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "EDIT",
      data,
    });
  };

  const openViewPromoModal = (data) => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "VIEW",
      data,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: false,
    });
  };

  const getPromoData = () => {
    PromoCodeData();
    updateTableControls({
      isTableEmpty: false,
      isTableLoading: true,
    });
    axios
      .get("/promo")
      .then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
          // toast.success(res?.data?.message);
          console.log("coupons=>", res?.data);
          updatePromos({
            ...promocodes,
            data: res?.data?.data,
            isLoading: false,
            notFound: false,
            count: res?.data?.data.length,
          });
          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        } else {
          updateTableControls({
            isTableEmpty: false,
            isTableLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log("err=>", err);
        updateTableControls({
          isTableEmpty: false,
          isTableLoading: false,
        });
      });
  };

  const removePromo = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handlePromoCodeDelete = () => {
    axios
      .delete(`/promo/${modalControls?.data?.id}`)
      .then((res) => {
        console.log("Add banner => ", res?.data);
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
          toast.success(res?.data?.message);
          getPromoData();
          fetchData();
        } else {
          toast.error(res?.data?.message);
        }
        updateModalControls({
          ...modalControls,
          isVisible: false,
          isDeleteModalVisible: false,
          data: [],
          type: "",
        });
      })
      .catch((err) => {
        console.log(err);
        updateModalControls({
          ...modalControls,
          isVisible: false,
          isDeleteModalVisible: false,
          data: [],
          type: "",
        });
      });
  };
  const CategoryData = () => {
    axios
      .get("/categories")
      .then((res) => {
        console.log("CategoryData", res?.data?.data);
        console.log("CategoryData res", res?.data?.statusCode);
        if (res?.data?.statusCode === 200) {
          // dispatch(categoriesAction?.setCategories(res?.data?.CategoryData))
          setCategories_data(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ServiceData = () => {
    axios
      .get("/services")
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setServicesData_data(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPromoData();
  }, [fetchData]);

  return (
    <div className={styles.wrapper}>
      <h1>{"  "}</h1>
      <Header title="Promo Codes" path="/home/marketing/Promo Codes" />
      <AddEditPromoModal
        isVisible={modalControls.isVisible}
        categories_data={categories_data}
        modalToShow={modalControls.type}
        onCloseIconClick={handleModalClose}
        addPromo={addPromo}
        data={modalControls.data}
        editPromo={editPromo}
        service_data={service_data}
        getPromoData={fetchData}
        // isRTL={props.isRTL}
        promocodes={promocodes}
        isLoading={modalControls.isLoading}
      />
      <ConfirmModal
        header={`Delete ${modalControls?.data?.code}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={handlePromoCodeDelete}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      <div className={styles.content}>
        <div className={styles.menu}>
          <div>
            {
              <Button
                type="primary"
                // disabled={props.isAddDisabled}
                shape={undefined}
                size="medium"
                onClick={openAddPromoModal}
              >
                Add Promo Code
              </Button>
            }
          </div>
        </div>

        <ActivePromoTable
          data={promocodes}
          isLoading={tableControls.isTableLoading}
          isEmpty={tableControls.isTableEmpty}
          editItem={openEditPromoModal}
          viewItem={openViewPromoModal}
          onChange={handlePaginationChange}
          removeItem={removePromo}
          pagination={pagination}
          columnWidth={150}
          // promocodes={promocodes}
          width
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(PromoCodes);
