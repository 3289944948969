import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { connect, useDispatch } from "react-redux";
import { Radio, Menu, Tooltip, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import axios from "../../shared/api/axiose";
import { Icon } from "../common";
import "./sidebar.scss";
// import LeftLogo from "../../assets/images/sidebar.png";

import BusinessLogo_two from "../../assets/images/logoexc-removebg-preview.png";
import symbol_logo from "../../assets/images/Group 50.png";

import {
  getPanelType,
  getRolesFromStorage,
  getRoles_from_admin,
} from "../../shared/utils/local-storage";

import sidebarItems from "../../shared/constants/sidebar-data.json";
import { checkRtl } from "../../shared/utils/rtl";
import { toastr } from "react-redux-toastr";

import { Set_Modules } from "../../shared/utils/local-storage";
import rolesActions from "../../redux/roles/roles-action";

const styles = {
  wrapper: "custom-sidebar__wrapper",
  contentContainer: "custom-sidebar__content-container",
  sidebarActive: "custom-sidebar__wrapper--sidebar-active",
  listItem: "custom-sidebar__list-item",
  listItemClosed: "custom-sidebar__list-item--closed",
  listItemActive: "custom-sidebar__list-item--active",
  logoContainer: "custom-sidebar__logo-container",
  leftLogo: "custom-sidebar__logo-container__left-logo",
  rightLogo: "custom-sidebar__logo-container__right-logo",
  footer: "custom-sidebar__footer",
  alternateLogo: "custom-sidebar__alternate-logo",
  alternateH: "custom-sidebar__alternate-H",
  footerActive: "custom-sidebar__footer--active",
  icon: "custom-sidebar__list-item__icon",
  subIcon: "custom-sidebar__list-item__icon--submenu",
  text: "custom-sidebar__list-item__text",
  subMenuContainer: "custom-sidebar__sub-menu-container",
  subMenuContainerToggled: "custom-sidebar__sub-menu-container--toggled",
  subMenuItem: "custom-sidebar__sub-menu-item",
  subMenuItemActive: "custom-sidebar__sub-menu-item--active",
  dropIcon: "custom-sidebar__drop-icon",
  dropIconRotated: "custom-sidebar__drop-icon--rotated",
  toggledListItem: "custom-sidebar__list-item--toggled",
  shadowContainer: "custom-sidebar__shadow-container",
};

const nonPickerOptions = [];
// const dispatch = useDispatch();

class Sidebar extends React.Component {
  state = {
    activeSubMenu: "",
    toggledSubMenu: "",
    logoImage: "", // Initialize logoImage state
    favIcon: "", // Initialize favIcon state
  };

  componentDidMount() {
    axios
      .get("/admins/settings")
      .then((response) => {
        const { logoImage, favIcon } = response.data.data;

        // Set logo and favicon in component's state
        this.setState({
          logoImage,
          favIcon,
        });
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
        // Handle error if needed
      });
  }
  scrollTo = (offset, callback) => {
    const fixedOffset = offset.toFixed(),
      onScroll = function () {
        if (window.pageYOffset.toFixed() === fixedOffset) {
          window.removeEventListener("scroll", onScroll);
          callback();
        }
      };

    window.addEventListener("scroll", onScroll);
    onScroll();
    window.scrollTo({
      top: offset,
      behavior: "smooth",
    });
  };

  setMenuActive = (menu) => {
    this.setState({
      activeSubMenu: menu,
      toggledSubMenu: menu,
    });
  };

  resetSubMenu = () => {
    this.setState({
      activeSubMenu: "",
      toggledSubMenu: "",
    });
  };

  toggleSubMenu = (menu) => {
    if (menu === this.state.toggledSubMenu) {
      this.setState({
        toggledSubMenu: "",
      });
    } else {
      this.setState({
        toggledSubMenu: menu,
      });
      if (!this.props.isSidebarExpanded) {
      }
    }
  };

  toggleHover = () => {
    this.setState({ isSidebarHover: !this.state.isSidebarHover });
  };
  toggleHoverOn = (e) => {
    e.preventDefault();
    this.setState({ isSidebarHover: true });
  };

  toggleHoverOff = (e) => {
    e.preventDefault();
    this.setState({ isSidebarHover: false });
  };
  // Role Based Access Controll ************************

  getRoles = () => {
    let roles = getRoles_from_admin();
    if (roles === undefined) return [];
    let res = [];
    let sub_module = [];
    // this.props.dispatch(rolesActions.setSubModule({"sub_module": "sdsds"}))

    let routes = sidebarItems;
    return routes;
    console.log("getRolesUser => routes", routes);
    console.log("getRolesUser => modules", this.props.modules);
    //  let modules = this.props.
    const filteredRoutes = routes.filter((route) => {
      // Check if the route's menuId matches any module's menuId
      const matchingModule = this.props.modules.find(
        (module) => module.menuId === route.menuId
      );

      // If there's a matching module, return the route and its subMenu (if it exists)
      if (matchingModule) {
        const filteredRoute = { ...route }; // Create a shallow copy of the route object

        if (filteredRoute.subMenu) {
          filteredRoute.subMenu = filteredRoute.subMenu.filter(
            (subMenu) => subMenu.menuId === matchingModule.menuId
          );
        }

        return filteredRoute;
      }
    });

    console.log("this.props.modules.some => ", this.props.modules);
    return routes.filter((item) => {
      let result = item;

      return this.props.modules.some((obj) => {
        // console.log("getRolesUser => obj" , obj)
        return (
          obj?.menuId === result?.menuId ||
          item?.menuId === 1 ||
          (obj?.menuId === 5 || obj?.menuId === 6 ? item?.menuId === 19 : "") ||
          (obj?.menuId === 13 || obj?.menuId === 14 || obj?.menuId === 15
            ? item?.menuId === 20
            : "")
        );
      });
    });
  };

  // componentDidMount() {
  //   let getRolesPrivalegesObj = this.props.dispatch(
  //     rolesActions.getRolesPrivaleges()
  //   );
  //   if (getRolesPrivalegesObj) {
  //     this.props.dispatch(
  //       rolesActions.setRolesPrevaligesData(getRolesPrivalegesObj)
  //     );
  //   }
  // }

  getSubMenu = (item, index) => {
    let menu = [...item.subMenu];
    if (item.menuId == 9) {
      // ORDERS
      if (this.props.pickerFlow) {
      } else {
        // menu = [...menu, ...this.verticalPlacer(nonPickerOptions)];
      }

      menu = menu.sort((a, b) => a.order - b.order);
    }

    return menu.map((subItem, i) => (
      <NavLink
        dir={this.props.isRTL ? "rtl" : "ltr"}
        className={styles.subMenuItem}
        activeClassName={styles.subMenuItemActive}
        key={`item${index}subItem${i}`}
        to={subItem.url}
        onClick={() => {
          this.setMenuActive(item.name);
        }}
      >
        <div dir={this.props.isRTL ? "rtl" : "ltr"} className={styles.subIcon}>
          <Icon name={subItem.icon} size="sidebar" />
        </div>
        <div dir={this.props.isRTL ? "rtl" : "ltr"} className={styles.text}>
          <FormattedMessage id={subItem.name} />
        </div>
      </NavLink>
    ));
  };
  getOpenSidebarView = () => {
    // return sidebarItems().map((item, index) => {
    return this.getRoles().map((item, index) => {
      if (Array.isArray(item?.subMenu)) {
        if (
          true || this.props.isSidebarExpanded
            ? false
            : !this.state.isSidebarHover
        ) {
          const { location } = this.props;

          return (
            <Menu
              key={index}
              defaultSelectedKeys={["/"]}
              selectedKeys={[location.pathname]}
              onClick={this.handleClick}
              expandIcon={() => <></>}
              mode="vertical"
            >
              <Menu.SubMenu
                icon={<Icon name={item.icon} size="sidebar" />}
                expandIcon={() => <></>}
                key="sub4"
              >
                {this.getSubMenu(item, index)}
              </Menu.SubMenu>
            </Menu>
          );
        }
        return (
          <React.Fragment key={`item${index}`}>
            <NavLink
              className={classNames({
                [styles.listItem]: true,
                [styles.listItemClosed]: this.props.isSidebarExpanded
                  ? false
                  : !this.state.isSidebarHover,
              })}
              dir={this.props.isRTL ? "rtl" : "ltr"}
              activeClassName={classNames([
                styles.listItemActive,
                styles.toggledListItem,
              ])}
              to={item.url}
              style={
                item.name == this.state.toggledSubMenu
                  ? { background: "#f5f5f5" }
                  : {}
              }
              onClick={(e) => {
                e.preventDefault();
                this.toggleSubMenu(item.name);
              }}
            >
              <div
                dir={this.props.isRTL ? "rtl" : "ltr"}
                className={styles.icon}
              >
                <Icon name={item.icon} size="sidebar" />
              </div>
              <div
                dir={this.props.isRTL ? "rtl" : "ltr"}
                className={styles.text}
              >
                <FormattedMessage id={item.name} />
                <div
                  dir={this.props.isRTL ? "rtl" : "ltr"}
                  className={classNames({
                    [styles.dropIcon]: true,
                    [styles.dropIconRotated]:
                      this.state.toggledSubMenu === item.name,
                  })}
                >
                  <Icon name="CARET_DOWN" size="tiny" />
                </div>
              </div>
            </NavLink>
            <div
              dir={this.props.isRTL ? "rtl" : "ltr"}
              className={classNames({
                [styles.subMenuContainer]: true,
                [styles.subMenuContainerToggled]:
                  this.state.toggledSubMenu === item.name &&
                  (this.state.isSidebarHover || this.props.isSidebarExpanded),
              })}
            >
              {this.getSubMenu(item, index)}
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <NavLink
            dir={this.props.isRTL ? "rtl" : "ltr"}
            className={classNames({
              [styles.listItem]: true,
              [styles.listItemClosed]: false,
            })}
            key={`item${index}`}
            activeClassName={styles.listItemActive}
            to={item.url}
            onClick={this.resetSubMenu}
          >
            <div dir={this.props.isRTL ? "rtl" : "ltr"} className={styles.icon}>
              <Icon name={item.icon} size="sidebar" />
            </div>

            <div dir={this.props.isRTL ? "rtl" : "ltr"} className={styles.text}>
              <FormattedMessage id={item.name} />
            </div>
          </NavLink>
        );
      }
    });
  };
  render() {
    const { logoImage, favIcon } = this.state;

    return (
      <div
        dir={this.props.isRTL ? "rtl" : "ltr"}
        className={classNames({
          [styles.wrapper]: true,
          [styles.sidebarActive]:
            this.state.isSidebarHover || this.props.isSidebarExpanded,
        })}
      >
        {this.props.alternateSidebarView !== 1 && (
          <>
            <div className={styles.logoContainer}>
              {!logoImage || !favIcon ? (
                <div className={styles.loaderContainer}>
                  <Spin size="small" />
                </div>
              ) : (
                <>
                  {this.props.isSidebarExpanded || this.state.isSidebarHover ? (
                    <div style={{ width: "86%" }}>
                      <img
                        src={this.state.logoImage}
                        className={styles.leftLogo}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        src={this.state.favIcon}
                        alt=""
                        className={styles.leftLogo}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

        {this.props.alternateSidebarView === 1 && (
          <>
            <div
              dir={this.props.isRTL ? "rtl" : "ltr"}
              className={`${styles.logoContainer} ${styles.alternateLogo}`}
            >
              <img
                src={
                  this.props.enterprice.logoImage ||
                  this.props.enterprice.branchLogo
                }
                alt=""
              />
              <h1 className={styles.alternateH}>
                {" "}
                <Radio
                  onClick={this.props.toggleSidebar}
                  checked={this.props.isSidebarExpanded}
                />
              </h1>
            </div>
          </>
        )}

        <div
          dir={this.props.isRTL ? "rtl" : "ltr"}
          className={styles.contentContainer}
        >
          {/* <section >{sidebarItems()}</section> */}
          <section>{this.getOpenSidebarView()}</section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enterprice: state.user.enterprice,
  userInfo: state.user.userInfo,
  isRTL: checkRtl(state.user.locale),
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  pickerFlow: state.user.enterprice.pickerFlow,
  alternateSidebarView: state.user.enterprice.alternateSidebarView,
  // ======================================== RBAC ===================
  modules: state.roles.modules,
  subModules: state.roles.subModules,
});

export default connect(mapStateToProps)(Sidebar);
