import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Select, Dropdown, Button, Menu, Tooltip, Checkbox } from "antd";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import userActions from "../../redux/user-info/user-actions";
import {
  nestedParseJSON,
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import { ProductsTable, UpdateStock } from "./components";

import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  DelayedInput,
  FullPageDrawer,
  CustomImage,
  CustomTable,
  CustomTag,
  Label,
} from "../../components/common";
import { toastr } from "react-redux-toastr";
import axios from "../../shared/api/axiosConfig";
import "./products.scss";
import AddEditProduct from "./add-edit-product/add-edit-product";
import { useConfirm } from "../../hooks";
import { productData } from "../../FakeData";

const styles = {
  wrapper: "products__wrapper",
  menu: "custom-menu",
  section: "custom-section",
};

const { Option } = Select;

function Products(props) {
  const intl = useIntl();
  const [stores, updateStores] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: true,
  });

  const csvUploadRef = useRef(null);

  const sub__Categories = [
    {
      categoryId: 490031,
      categoryName: "Dev Test",
      description: "",
      categoryImage:
        "https://d2563s26joau7f.cloudfront.net/images/lAzE1633068930961-fP6U1633068930918naturalsoaporganicsoaplavender1.jpg",
      categoryIcon:
        "https://d2563s26joau7f.cloudfront.net/images/lAzE1633068930961-fP6U1633068930918naturalsoaporganicsoaplavender1.jpg",
      priority: 1,
      parentCategoryId: -1,
      adminId: 1,
      isEnabled: 1,
      isDeleted: 0,
      branchId: 386,
    },
    {
      categoryId: 178679,
      categoryName: "Organic Fruits",
      description: "",
      categoryImage:
        "https://d2lm4yxmegs2n.cloudfront.net/images/JXIc1629193883530-vjff1629193883388download20210817T152157.093.jpg",
      categoryIcon:
        "https://d2lm4yxmegs2n.cloudfront.net/images/JXIc1629193883530-vjff1629193883388download20210817T152157.093.jpg",
      priority: 1,
      parentCategoryId: -1,
      adminId: 1,
      isEnabled: 1,
      isDeleted: 0,
      branchId: 386,
    },
    {
      categoryId: 178737,
      categoryName: "Organic soaps",
      description: "",
      categoryImage:
        "https://d2563s26joau7f.cloudfront.net/images/lAzE1633068930961-fP6U1633068930918naturalsoaporganicsoaplavender1.jpg",
      categoryIcon:
        "https://d2563s26joau7f.cloudfront.net/images/lAzE1633068930961-fP6U1633068930918naturalsoaporganicsoaplavender1.jpg",
      priority: 1,
      parentCategoryId: -1,
      adminId: 1,
      isEnabled: 1,
      isDeleted: 0,
      branchId: 386,
    },
  ];

  const store__categories = [
    {
      branchId: 416,
      branchName: "Big Basket Shop",
      latitude: "52.09383099999999",
      longitude: "-0.2536441",
      teamId: 658,
    },
    {
      branchId: 390,
      branchName: "D'Mart Store",
      latitude: "19.3919275",
      longitude: "72.8397317",
      teamId: 465,
    },
    {
      branchId: 382,
      branchName: "demo store",
      latitude: "20.593684",
      longitude: "78.96288",
      teamId: null,
    },
    {
      branchId: 375,
      branchName: "divya",
      latitude: "20.593684",
      longitude: "78.96288",
      teamId: null,
    },
    {
      branchId: 333,
      branchName: "divya",
      latitude: "37.09024",
      longitude: "-95.712891",
      teamId: null,
    },
    {
      branchId: 370,
      branchName: "Divya Reddy Store",
      latitude: "20.593684",
      longitude: "78.96288",
      teamId: null,
    },
    {
      branchId: 407,
      branchName: "Dmart",
      latitude: "51.512283320512324",
      longitude: "-0.09425562117920006",
      teamId: 650,
    },
    {
      branchId: 431,
      branchName: "foodie store",
      latitude: "53.7300637",
      longitude: "-2.756463",
      teamId: 673,
    },
    {
      branchId: 381,
      branchName: "Fresh and more",
      latitude: "20.593684",
      longitude: "78.96288",
      teamId: null,
    },
    {
      branchId: 394,
      branchName: "Fresh Market",
      latitude: "20.593684",
      longitude: "78.96288",
      teamId: 497,
    },
    {
      branchId: 334,
      branchName: "Freshie Meat",
      latitude: "30.7187849",
      longitude: "76.81037409999999",
      teamId: null,
    },
    {
      branchId: 324,
      branchName: "G Mart",
      latitude: "30.7187849",
      longitude: "76.81037409999999",
      teamId: null,
    },
    {
      branchId: 380,
      branchName: "hemant store",
      latitude: "19.2307329",
      longitude: "72.856673",
      teamId: null,
    },
    {
      branchId: 432,
      branchName: "Indu's store",
      latitude: "53.47225990000001",
      longitude: "-2.2541560037048325",
      teamId: 674,
    },
    {
      branchId: 383,
      branchName: "Kart Mart",
      latitude: "52.086938",
      longitude: "-0.26422",
      teamId: null,
    },
    {
      branchId: 428,
      branchName: "Lakshmi Stores",
      latitude: "52.088869397397126",
      longitude: "-0.32580500845946325",
      teamId: 670,
    },
    {
      branchId: 433,
      branchName: "Mallika Store",
      latitude: "51.5781864",
      longitude: "0.070603",
      teamId: 675,
    },
    {
      branchId: 405,
      branchName: "More Supermarket",
      latitude: "17.415529",
      longitude: "78.41041469999999",
      teamId: 648,
    },
    {
      branchId: 335,
      branchName: "more1",
      latitude: "17.385044",
      longitude: "78.486671",
      teamId: null,
    },
    {
      branchId: 423,
      branchName: "Mytesting store",
      latitude: "55.36715041348145",
      longitude: "-3.506740402648918",
      teamId: 665,
    },
    {
      branchId: 386,
      branchName: "Navya Herbal Store",
      latitude: "51.51248363178958",
      longitude: "-0.10511320327148521",
      teamId: null,
    },
    {
      branchId: 388,
      branchName: "Navya Organic store",
      latitude: "52.09383099999999",
      longitude: "-0.2536441",
      teamId: 458,
    },
    {
      branchId: 406,
      branchName: "Ratnadeep Super Market",
      latitude: "17.417646",
      longitude: "78.411317",
      teamId: 649,
    },
    {
      branchId: 430,
      branchName: "Sadda Store",
      latitude: "52.0752584",
      longitude: "-0.2400639",
      teamId: 672,
    },
    {
      branchId: 379,
      branchName: "Samar Kundal",
      latitude: "18.5204303",
      longitude: "73.8567437",
      teamId: null,
    },
    {
      branchId: 373,
      branchName: "shops",
      latitude: "41.87194",
      longitude: "12.56738",
      teamId: null,
    },
    {
      branchId: 378,
      branchName: "Sid Store",
      latitude: "28.6554182",
      longitude: "77.16462",
      teamId: null,
    },
    {
      branchId: 337,
      branchName: "Sindhu Store",
      latitude: "52.09383099999999",
      longitude: "-0.2536441",
      teamId: null,
    },
    {
      branchId: 397,
      branchName: "Tamanvi",
      latitude: "51.5072178",
      longitude: "-0.1275862",
      teamId: 558,
    },
    {
      branchId: 418,
      branchName: "Test epos",
      latitude: "31.3900711",
      longitude: "75.4031395",
      teamId: 660,
    },
    {
      branchId: 425,
      branchName: "Test Supra Store",
      latitude: "52.0752584",
      longitude: "-0.2400639",
      teamId: 667,
    },
    {
      branchId: 398,
      branchName: "Testing",
      latitude: "51.5072178",
      longitude: "-0.1275862",
      teamId: 559,
    },
    {
      branchId: 371,
      branchName: "Testing Store",
      latitude: "19.422171040802308",
      longitude: "72.82517247271595",
      teamId: null,
    },
    {
      branchId: 421,
      branchName: "text cutter new",
      latitude: "53.46114111503327",
      longitude: "-2.193192023437509",
      teamId: 663,
    },
    {
      branchId: 410,
      branchName: "textCutter",
      latitude: "52.09255899999999",
      longitude: "-0.2590765",
      teamId: 653,
    },
    {
      branchId: 404,
      branchName: "Uk New Store",
      latitude: "51.50682046918619",
      longitude: "-0.1454658052932678",
      teamId: 647,
    },
    {
      branchId: 429,
      branchName: "Veena Stores",
      latitude: "52.086094852716904",
      longitude: "-0.26856541359176367",
      teamId: 671,
    },
    {
      branchId: 401,
      branchName: "very large data",
      latitude: "30.7333148",
      longitude: "76.7794179",
      teamId: 617,
    },
    {
      branchId: 426,
      branchName: "weight testing store",
      latitude: "52.48515413099954",
      longitude: "-1.8976364343933039",
      teamId: 668,
    },
    {
      branchId: 400,
      branchName: "Zet Basket",
      latitude: "53.48693602596205",
      longitude: "-2.029722999999992",
      teamId: 565,
    },
  ];

  const [search, updateSearch] = useState("");
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    isUpdateStockModalVisible: false,
    data: {},
    type: "",
  });

  const [isCSVUploading, changeIsCSVUploading] = useState(false);
  const [showAllProducts, toggleAllProducts] = useState(false);
  const productChainTimeoutRef = useRef(null);
  const [categories, updateCategories] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: false,
  });

  const [subCategories, updateSubCategories] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: false,
  });

  const [brands, updateBrands] = useState({
    selected: "",
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [products, updateProducts] = useState({
    selected: undefined,
    data: [],
    notFound: false,
    isLoading: false,
    count: null,
  });

  const [searchDelayed, updateSearchDelayed] = useState("");

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    total: 0,
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  console.log("products.data", products.data);

  const openAddProductModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_PRODUCT",
    });
  };

  const openEditProductModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_PRODUCT",
    });
  };

  const handleProductToggle = (product) => {
    updateProducts({
      ...products,
      data: products.data.map((i) => {
        if (i.productId === product.productId) {
          i.isEnabled = i.isEnabled ? 0 : 1;
        }
        return i;
      }),
    });
    alert("Product Update");
  };

  const closeModal = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };

  const getProducts = () => {
    let product = productData();

    updateTableControls({
      isTableEmpty: false,
      isTableLoading: false,
    });
    updateProducts({
      ...products,
      data: [...product, ...product, ...product],
      count: 15,
      isLoading: false,
      notFound: false,
    });
    updatePagination({
      ...pagination,
      total: 5,
    });
  };
  useEffect(() => {
    console.log("subcategory effect");

    getProducts();
  }, [
    pagination.pageSize,
    pagination.current,
    searchDelayed,
    subCategories.selected,
    brands.selected,
  ]);

  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const openUpdateStockModal = (product) => {
    updateModalControls({
      ...modalControls,
      data: product,
      isUpdateStockModalVisible: true,
    });
  };

  const handleProductAdd = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: true,
    });
  };

  const handleProductEdit = (data) => {
    alert("handleProductEdit");
    data.branchId = stores.selected;
    data.productId = modalControls.data.productId;

    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
  };

  const handleProductDelete = (item) => {
    alert("handle Delete");
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
  };
  const handleDelete = useConfirm(
    handleProductDelete,
    "Added Product will be deleted !",
    "Delete Product?"
  );

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditProductModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>

        {
          <Menu.Item>
            {intl.formatMessage({ id: "app.field.enable" })}
          </Menu.Item>
        }
        {
          <Menu.Item>
            {intl.formatMessage({ id: "app.field.disable" })}
          </Menu.Item>
        }

        <Menu.Item
          onClick={() => {
            handleDelete(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
        {/* )} */}
      </Menu>
    );
  };

  const columns = () => {
    const productMapping = props.productMapping;

    return [
      {
        dataIndex: "",
        title: intl.formatMessage({ id: "app.field.sNo" }),
        // toBeShowntrue,
        fixed: "left",
        render: (item, object, index) => {
          return (
            <span>
              {pagination.pageSize * (pagination.current - 1) + index + 1}
            </span>
          );
        },
      },
      {
        dataIndex: "",
        title: "Category Name",
        // toBeShownproductMapping.name?.toBeShown,
        fixed: "left",
      },
      {
        dataIndex: "",
        title: "Service Name",
        // toBeShownproductMapping.name?.toBeShown,
      },

      {
        dataIndex: "",
        title: "Service Image / Icon",
        // toBeShownproductMapping.name?.toBeShown,
      },

      {
        dataIndex: "",
        title: "Ratings",
        // toBeShownproductMapping.costPrice?.toBeShown,
      },
      {
        dataIndex: "",
        title: "Actual Price",
        // toBeShownproductMapping.actualPrice?.toBeShown,
      },
      {
        dataIndex: "",
        title: "Discounted Price",
        // toBeShowntrue,
      },

      {
        dataIndex: "",
        title: "Estimated time",
        // toBeShownproductMapping.purchasedUnit?.toBeShown,
      },

      {
        dataIndex: "",
        title: "Service type Tag",
        // toBeShownproductMapping.purchasedStock?.toBeShown,
      },
      {
        dataIndex: "",
        title: "Status",
        // toBeShownproductMapping.purchasedStock?.toBeShown,
      },
      {
        dataIndex: "",
        title: intl.formatMessage({ id: "app.field.action" }),
        // toBeShowntrue,
        fixed: "right",
        render: (item) => {
          return (
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          );
        },
      },
    ];
  };

  return (
    <div>
      <h1> </h1>
      <Header title="Services" />
      <AddEditProduct
        isVisible={modalControls.isVisible}
        data={modalControls.data}
        productMapping={props.productMapping}
        isSuperAdmin={props.userInfo.isSupperAdmin === 1}
        onCloseIconClick={closeModal}
        modalType={modalControls.type}
        enterpriceReferenceId={props.enterpriceReferenceId}
        addProduct={handleProductAdd}
        editProduct={handleProductEdit}
        storeId={stores.selected}
        isLoading={modalControls.isLoading}
      />

      <div style={{ marginBottom: "32px" }} className={styles.section}>
        <div style={{ flexWrap: "wrap" }} className={styles.menu}>
          <header>
            <div className={styles.lowerMenuDropdown}>
              <Label value="app.field.category" horizontal />
              <Select
                disabled={showAllProducts}
                style={{ width: "150px" }}
                showSearch
                placeholder={intl.formatMessage({
                  id: "app.placeholder.category",
                })}
                // onChange={handleCategoryChange}
                value={categories.selected}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {categories.data.map((category) => (
                  <Option key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </Option>
                ))}
              </Select>
            </div>
          </header>
        </div>
      </div>
      <Panel isLoading={isCSVUploading} className={styles.section}>
        <div className={styles.menu}>
          <header></header>
          <section>
            <div>
              <DelayedInput
                onChange={handleSearchChange}
                value={search}
                callback={handleSearch}
                delay={500}
                minLength={3}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
            <Button
              onClick={openAddProductModal}
              shape={undefined}
              size="medium"
              disabled={props.isAddDisabled}
              type="primary"
            >
              {intl.formatMessage({ id: "app.pageTitle.addService" })}
            </Button>
          </section>
        </div>

        <CustomTable
          isLoading={tableControls.isTableLoading}
          isEmpty={tableControls.isTableEmpty}
          dataSource={products.data}
          columnWidth={150}
          width
          pagination={pagination}
          columns={columns()}
        />
      </Panel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  productMapping: state.user.enterprice.dynamicProductFields,
  storeId: state.user.enterprice.storeId,
  isAddDisabled: state.user.isAddDisabled,
  branchId: state.user.userInfo.branchId,
  userInfo: state.user.userInfo,
  enterpriceReferenceId: state.user.enterprice.enterpriceReferenceId,
  panelType: state.user.enterprice.panelType,
  allProductsFlow: state.user.enterprice.showAllProducts == 1,
});
export default connect(mapStateToProps)(Products);
