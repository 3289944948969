import React from "react";
import icons from "./icons";

class Icon extends React.Component {
  _svg = null;
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  _applySize = () => {
    this._svg.setAttribute("display", "block");
    switch (this.props.size) {
      case "tiny":
        this._svg.setAttribute("height", "14px");
        this._svg.setAttribute("width", "14px");
        this.myRef.current.style.width = "14px";
        this.myRef.current.style.height = "14px";
        break;
      case "small":
        this._svg.setAttribute("height", "18px");
        this._svg.setAttribute("width", "18px");
        this.myRef.current.style.width = "18px";
        this.myRef.current.style.height = "18px";
        break;
      case "medium":
        this._svg.setAttribute("height", "24px");
        this._svg.setAttribute("width", "24px");
        this.myRef.current.style.width = "24px";
        this.myRef.current.style.height = "24px";
        break;
      case "sidebar":
        this._svg.setAttribute("height", "21px");
        this._svg.setAttribute("width", "21px");
        this.myRef.current.style.width = "21px";
        this.myRef.current.style.height = "21px";
        break;
      case "large":
        this._svg.setAttribute("height", "32px");
        this._svg.setAttribute("width", "32px");
        this.myRef.current.style.width = "32px";
        this.myRef.current.style.height = "32px";
        break;
      case "big":
        this._svg.setAttribute("height", "40px");
        this._svg.setAttribute("width", "40px");
        this.myRef.current.style.width = "40px";
        this.myRef.current.style.height = "40px";
        break;
      case "huge":
        this._svg.setAttribute("height", "64px");
        this._svg.setAttribute("width", "64px");
        this.myRef.current.style.width = "64px";
        this.myRef.current.style.height = "64px";
        break;
      case "massive":
        this._svg.setAttribute("height", "100px");
        this._svg.setAttribute("width", "100px");
        this.myRef.current.style.width = "100px";
        this.myRef.current.style.height = "100px";
        break;
      default:
        this._svg.setAttribute("height", "24px");
        this._svg.setAttribute("width", "24px");
        this.myRef.current.style.width = "24px";
        this.myRef.current.style.height = "24px";
    }
  };

  _applyColor = () => {
    if (this.props.fill) {
      this._svg.setAttribute("fill", this.props.fill);
    }

    if (this.props.stroke) {
      this._svg.setAttribute("stroke", this.props.stroke);
    }
  };
  componentDidMount() {
    if (this.props.name && icons[this.props.name]) {
      const ref = this.myRef.current;
      if (ref) {
        this._svg = ref.childNodes[0];
        this._applySize();
        this._applyColor();
      }
    }
  }
  render() {
    if (!this.props.name || !icons[this.props.name]) {
      return "?";
    }

    return (
      <i
        style={{ cursor: "pointer" }}
        ref={this.myRef}
        onClick={this.handleClick}
        dangerouslySetInnerHTML={{
          __html: icons[this.props.name] ? icons[this.props.name] : "?",
        }}
      ></i>
    );
  }
}

export default Icon;
