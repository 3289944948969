import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Carousel } from "react-responsive-carousel";
import {
  Input,
  Button,
  Radio,
  Select,
  Divider,
  DatePicker,
  Rate,
  TimePicker,
  Modal,
  List,
} from "antd";
import { toastr } from "react-redux-toastr";
import classNames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment-timezone";
import {
  PlusSquareOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import {
  allValidUnits,
  validUnitCombinations,
  unitNames,
} from "../../../../shared/constants/products";
import Validations from "../../../../shared/validations/Validations";
import CustomTable from "../../../../components/common/custom-table/custom-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "../../../../shared/api/axiose";
import { AiOutlineCloudDownload } from "react-icons/ai";
import {
  FullPageDrawer,
  Panel,
  CustomModal,
  CustomImage,
  ErrorMessage,
  Label,
  UploadButton,
} from "../../../../components/common";
import momentTimezone from "moment-timezone";
import "./add-edit-service.scss";
import { dateFormat } from "../../../../shared/utils/local-storage";
import AddEditQuestion from "../add-edit-question/add-edit-question";

const { Option } = Select;

const measurandUnits = ["g", "kg", "lbs", "ltr", "ml"];

const styles = {
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formHeader: "custom-form-title",
  formContainer: "add-edit-product__form-container",
  formContent: "add-edit-product__form-content",
  imageContainer: "imageContainer",
  formLeft: "add-edit-product__form-container__left",
  formRight: "add-edit-product__form-container__right",
  wrapper: "add-edit-product__wrapper",
  deleteButton: "add-edit-product__delete-button",
  imageContainerImage: "imageContainerImage",
  isDisabled: "isDisabled",
  questionaryTableHead: "questionaryTableHead",
  multySelect_disabled: "multySelect_disabled",
};

function AddEditService({
  getCategoriesData,
  serviceTypeTags,
  ServiceData,
  questionData,
  ...props
}) {
  const { TextArea } = Input;
  const imageContainerRef = useRef(null);

  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  console.log("subcat", selectedSubcategory);
  const [serviceName, setServiceName] = useState("");
  const [actualPrice, setActualPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [estimatedTimeInMinute, setTimeInMinute] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [serviceTypeTag, setServiceTypeTag] = useState("");
  console.log("type", serviceTypeTag);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [currentTime, setCurrentTime] = useState(null);
  const [serviceIncluded, setServiceIncluded] = useState([]);
  const [serviceExclude, setServiceExclude] = useState([]);
  const [newIncluded, setNewIncluded] = useState("");
  const [newExclude, setNewExclude] = useState("");
  const [description, setDescription] = useState("");
  const [formValidation, setFormValidation] = useState({
    category: false,
    serviceName: false,
    actualPrice: false,
    discountedPrice: false,
    estimatedTimeInMinute: false,
    serviceType: false,
  });
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [selectedTimesIST, setSelectedTimesIST] = useState([]);

  useEffect(() => {
    axios
      .get("/category")
      .then((response) => {
        const fetchedCategories = response.data.data.categories;
        setCategories(fetchedCategories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      axios
        .get(`/category/${selectedCategory}`)
        .then((response) => {
          const fetchedSubcategories = response.data.data.subcategories;
          setSubcategories(fetchedSubcategories);
        })
        .catch((error) => {
          console.error("Error fetching subcategories:", error);
        });
    } else {
      setSubcategories([]);
    }
  }, [selectedCategory]);

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);

    setSelectedSubcategory(null);
  };

  const handleSubcategorySelect = (subcategoryId) => {
    setSelectedSubcategory(subcategoryId);
  };
  const handleServiceNameChange = (e) => {
    setServiceName(e.target.value);
  };

  const ImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  const handleActualPriceChange = (e) => {
    setActualPrice(e.target.value);
  };

  const handleDiscountedPriceChange = (e) => {
    setDiscountedPrice(e.target.value);
  };
  const handleEstimatedTime = (e) => {
    const inputVal = e.target.value;
    const numericValue = inputVal.replace(/\D/g, "");
    setTimeInMinute(numericValue);
  };
  const handleServiceTypeTagChange = (value) => {
    setServiceTypeTag(value);
  };
  const handleServiceTypeChange = (value) => {
    setServiceType(value);
    setSelectedTimes([]);
  };

  const handleTimeSelect = (time) => {
    setCurrentTime(time);
  };

  const handleAddTime = () => {
    if (currentTime) {
      setSelectedTimes([...selectedTimes, currentTime]);
      setCurrentTime(null);
    }
  };

  const handleAddIncluded = () => {
    if (newIncluded.trim().length <= 30) {
      setServiceIncluded([...serviceIncluded, newIncluded]);
      setNewIncluded("");
    } else {
      toast.error("Service included items must contain (max 30 characters).", {
        onClose: () => {
          toast.info(
            " If you want to add more details You can add multiple items as a list."
          );
        },
      });
    }
  };

  const handleAddExclude = () => {
    if (newExclude.trim().length <= 30) {
      setServiceExclude([...serviceExclude, newExclude]);
      setNewExclude("");
    } else {
      toast.error("Service included items must contain (max 30 characters).", {
        onClose: () => {
          toast.info(
            "If you want to add more details You can add multiple items as a list"
          );
        },
      });
    }
  };

  const handleRemoveIncluded = (itemToRemove) => {
    const updatedServiceIncluded = serviceIncluded.filter(
      (item) => item !== itemToRemove
    );
    setServiceIncluded(updatedServiceIncluded);
  };

  const handleRemoveExclude = (itemToRemove) => {
    const updatedServiceExclude = serviceExclude.filter(
      (item) => item !== itemToRemove
    );
    setServiceExclude(updatedServiceExclude);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  const validateActualPrice = (price) => {
    return price.trim() !== "" && parseFloat(price) !== 0;
  };

  const validateDiscountedPrice = (discountedPrice, actualPrice) => {
    return (
      discountedPrice.trim() !== "" &&
      parseFloat(discountedPrice) !== 0 &&
      parseFloat(discountedPrice) < parseFloat(actualPrice)
    );
  };
  const validateEstimatedTime = (time) => {
    return time.trim() !== "" && !isNaN(time);
  };
  const validateServiceType = (type) => {
    return type !== "";
  };
  const validateDescription = (desc) => {
    return desc.trim() !== "";
  };
  const handleSubmit = () => {
    const utcTimes = selectedTimes.map((time) => time.utc().format("HH:mm"));
    setFormSubmitted(true);
    const newFormValidation = {
      category: selectedCategory !== null,
      serviceName: serviceName.trim() !== "",
      actualPrice: validateActualPrice(actualPrice),
      discountedPrice: validateDiscountedPrice(discountedPrice, actualPrice),
      estimatedTimeInMinute: validateEstimatedTime(estimatedTimeInMinute),
      serviceType: validateServiceType(serviceType),
      description: validateDescription(description),
    };

    setFormValidation(newFormValidation);

    const requiredFields = [
      "category",
      "serviceName",
      "actualPrice",
      "discountedPrice",
    ];

    const allRequiredFieldsValid = requiredFields.every(
      (field) => newFormValidation[field]
    );

    if (allRequiredFieldsValid) {
      const payload = {
        actualPrice,
        isPickupService: serviceType === "pickup",
        ...(serviceType === "regular" && {
          availableBookingSlotTime: utcTimes,
        }),
        category: [{ categoryId: selectedCategory }],
        title: serviceName,
        description,
        discountedPrice,
        estimatedTimeInMinute,
        serviceImg: imageURL.value,
        serviceTypeTag: serviceTypeTag,
        status: true,
      };

      if (selectedSubcategory !== null && selectedSubcategory.length > 0) {
        console.log("selectedSubcategory:", selectedSubcategory);
        payload.subcategory = selectedSubcategory.map((subcategoryId) => ({
          subcategoryId,
        }));
        console.log("Payload with subcategory:", payload);
      }

      if (serviceIncluded.length > 0) {
        payload.serviceIncluded = serviceIncluded;
      }

      if (serviceExclude.length > 0) {
        payload.excludes = serviceExclude;
      }

      axios
        .post("/services", payload)
        .then((response) => {
          toast.success("Service Edited", "Service details have been updated.");
          ServiceData();

          props.onCloseIconClick();
        })
        .catch((error) => {
          console.error("Error editing service:", error);
          toast.error("Error", "An error occurred while editing the service.");
        });
    }
  };

  return (
    <CustomModal
      // isVisible={true}
      isVisible={props?.isVisible}
      action={
        props.modalToShow !== "VIEW" ? (
          <>
            <Button
              shape={undefined}
              onClick={props?.onCloseIconClick}
              size="large"
            >
              Cancel
            </Button>
            <Button
              shape={undefined}
              type="primary"
              // loading={props?.isLoading || isLoading}
              onClick={handleSubmit}
              size="large"
            >
              Add
            </Button>
          </>
        ) : (
          ""
        )
      }
      onCloseIconClick={props?.onCloseIconClick}
      header={"Add Service"}
    >
      <Panel
        isModal
        className={styles.formModal}
        // containerClass={styles.wrapper}
        // isLoading={props?.isLoading || isLoading}
      >
        <div className={styles.formHeader}>Service Details</div>
        <div className={styles.wrapper}>
          <div className={styles.formContainer}>
            <div className={styles.imageContainer}>
              <div
                // ref={imageContainerRef}
                className={styles.imageContainerImage}
              >
                <CustomImage src={imageURL.value} />

                <UploadButton
                  parentRef={imageContainerRef}
                  resultType="url"
                  accept="image/*"
                  type="fileUpload"
                  id="913fjhiu4ng2voi"
                  text="Upload Image(300x300)"
                  onChange={ImageChange}
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Category" />
                <Select
                  placeholder="Select a category"
                  onChange={handleCategorySelect}
                  value={selectedCategory}
                >
                  {categories.map((category) => (
                    <Option key={category.id} value={category.id}>
                      {category.title}
                    </Option>
                  ))}
                </Select>
                {isFormSubmitted && formValidation.category === false && (
                  <p style={{ color: "red" }}>Category is required.</p>
                )}
              </div>

              <div className={styles.formItem}>
                <Label value="Service Name" />
                <Input
                  placeholder="Enter service name"
                  value={serviceName}
                  onChange={handleServiceNameChange}
                />
                {isFormSubmitted && formValidation.serviceName === false && (
                  <p style={{ color: "red" }}>Service name is required.</p>
                )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Actual Price" />
                <Input
                  placeholder="Enter Actual Price"
                  value={actualPrice}
                  onChange={handleActualPriceChange}
                />
                {isFormSubmitted &&
                  (!formValidation.actualPrice ||
                    (formValidation.actualPrice &&
                      parseFloat(actualPrice) === 0)) && (
                    <p style={{ color: "red" }}>
                      Actual price is required and cannot be 0.
                    </p>
                  )}
              </div>
              <div className={styles.formItem}>
                <Label value="Discounted Price" />
                <Input
                  placeholder="Enter Discounted Price"
                  value={discountedPrice}
                  onChange={handleDiscountedPriceChange}
                />
                {isFormSubmitted &&
                  (!formValidation.discountedPrice ||
                    (formValidation.discountedPrice &&
                      parseFloat(discountedPrice) === 0)) && (
                    <p style={{ color: "red" }}>
                      Discounted price is required and cannot be 0.
                    </p>
                  )}
                {isFormSubmitted &&
                  formValidation.discountedPrice &&
                  parseFloat(discountedPrice) >= parseFloat(actualPrice) && (
                    <p style={{ color: "red" }}>
                      Discounted price must be less than actual price.
                    </p>
                  )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Estimated Time" />
                <Input
                  placeholder="Estimated time in mins"
                  value={estimatedTimeInMinute}
                  onChange={handleEstimatedTime}
                  type="number"
                />
                {isFormSubmitted &&
                  (!formValidation.estimatedTimeInMinute ||
                    (formValidation.estimatedTimeInMinute &&
                      (estimatedTimeInMinute.trim() === "" ||
                        isNaN(estimatedTimeInMinute)))) && (
                    <p style={{ color: "red" }}>
                      Estimated time is required and must be a number.
                    </p>
                  )}
              </div>
              <div className={styles.formItem}>
                <Label value="Sub Category" />
                {subcategories.length > 0 && (
                  <Select
                    mode="multiple"
                    placeholder="Select a subcategory"
                    onChange={handleSubcategorySelect}
                    value={selectedSubcategory}
                    disabled={!selectedCategory}
                  >
                    {subcategories.map((subcategory) => (
                      <Option key={subcategory.id} value={subcategory.id}>
                        {subcategory.title}
                      </Option>
                    ))}
                  </Select>
                )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Service Type Tag" />
                <Select
                  placeholder="Select service type"
                  onChange={handleServiceTypeTagChange}
                  value={serviceTypeTag}
                >
                  <Option value="POPULAR">Popular</Option>
                </Select>
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Service Type " />
                <Select
                  placeholder="Select service type"
                  onChange={handleServiceTypeChange}
                  value={serviceType}
                >
                  <Option value="regular">Regular service</Option>
                  <Option value="pickup">Pickup service</Option>
                </Select>
                {isFormSubmitted && !formValidation.serviceType && (
                  <p style={{ color: "red" }}>
                    At least one service type must be selected.
                  </p>
                )}
              </div>
            </div>
            {serviceType === "regular" && (
              <div className={styles.formGroup}>
                <Label value="Available Booking Slot Time (Optional)" />
              </div>
            )}
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                {serviceType === "regular" && (
                  <div style={{ display: "inline-block" }}>
                    <TimePicker
                      format="h:mm a"
                      style={{ width: "400px", height: "1.9rem" }}
                      onChange={handleTimeSelect}
                      value={currentTime}
                    />
                    <div style={{ display: "inline-block", marginTop: "15px" }}>
                      <PlusSquareOutlined
                        onClick={handleAddTime}
                        style={{
                          cursor: "pointer",
                          color: "grey",
                          fontSize: "35px",
                          verticalAlign: "middle",
                          transition: "color 0.3s ease-in-out",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.color = "blue")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.color = "grey")
                        }
                      />
                    </div>
                  </div>
                )}
                {selectedTimes.length > 0 && (
                  <div>
                    <h3>Selected Times</h3>
                    <List
                      dataSource={selectedTimes}
                      renderItem={(time) => (
                        <List.Item>{time.format("h:mm a")}</List.Item>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.formHeader}>Additional Service Details</div>

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Description" />
                <TextArea
                  placeholder="Enter description"
                  rows={4}
                  value={description}
                  onChange={handleDescription}
                />
                {isFormSubmitted && !formValidation.description && (
                  <p style={{ color: "red" }}>Description is required.</p>
                )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Service Included (optional)" />
                <List
                  dataSource={serviceIncluded}
                  renderItem={(item) => (
                    <List.Item
                      key={item}
                      actions={[
                        <Button
                          type="text"
                          onClick={() => handleRemoveIncluded(item)}
                          style={{ color: "red" }}
                        >
                          <CloseOutlined />
                        </Button>,
                      ]}
                    >
                      <h4>{item}</h4>
                    </List.Item>
                  )}
                />
                <Input
                  placeholder="Add service included"
                  value={newIncluded}
                  onChange={(e) => setNewIncluded(e.target.value)}
                  addonAfter={
                    <PlusOutlined
                      onClick={handleAddIncluded}
                      style={{ cursor: "pointer", color: "blue" }}
                    />
                  }
                />
              </div>

              <div className={styles.formItem}>
                <Label value="Service Excluded (optional)" />
                <List
                  dataSource={serviceExclude}
                  renderItem={(item) => (
                    <List.Item
                      key={item}
                      actions={[
                        <Button
                          type="text"
                          onClick={() => handleRemoveExclude(item)}
                          style={{ color: "red" }}
                        >
                          <CloseOutlined />
                        </Button>,
                      ]}
                    >
                      <h4>{item}</h4>
                    </List.Item>
                  )}
                />
                <Input
                  placeholder="Add service exclude"
                  value={newExclude}
                  onChange={(e) => setNewExclude(e.target.value)}
                  addonAfter={
                    <PlusOutlined
                      onClick={handleAddExclude}
                      style={{ cursor: "pointer", color: "blue" }}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditService;
