import React, { useState, useEffect } from "react";
import { Breadcrumb, Button } from "antd";
import { connect } from "react-redux";
import { HomeOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";


import "./Header.scss";
import { checkRtl } from "../../../shared/utils/rtl";

import Icon from "./../icon/icon";

const styles = {
  button: "custom-back-button__wrapper",
  wrapper: "header__wrapper",
  title: "header__title",
  subTitle: "header__sub-title",
  divider: "header__divider",
  action: "custom-form-action",
  cta: "header__cta",
  crumbs: "header__crumbs",
};
function Header({
  showBackButton,
  title,
  key,
  offset,
  subTitle,
  hidehr,
  actions,
  crumbs,
  path,
  vertical,
  customEnding,
  isRTL,
}) {
  const [baseURL, changeBaseURL] = useState("");

  // useEffect(() => {
  //   const url = getBaseURL("");
  //   changeBaseURL(url);
  //   // debugger;
  // }, [vertical]);
  const history = useHistory();
  const getCrumbs = () => {
    let pathToSplit = path ? path : history.location.pathname;
    // pathToSplit = path;
    const urlToCrumbs = pathToSplit
      .split("/")
      .slice(0)
      .filter(
        (i) =>
          i !== "" && i !== "home" && i !== "small-inv" && i !== "large-inv" && i !== "store"
      )
      .slice(0, typeof offset === "number" ? offset : undefined);

    return (
      <Breadcrumb
        separator={isRTL ? <LeftOutlined /> : <RightOutlined />}
        className={styles.crumbs}
      >
        <Breadcrumb.Item>
          <Link className={styles.cta} to={`${baseURL}/home/dashboard`}>
            Home
          </Link>
        </Breadcrumb.Item>
        {urlToCrumbs.map((i, index) => {
          const text = i
            .split("-")
            .map((d) => d[0].toUpperCase() + d.substr(1))
            .join("-");
          return (
            <Breadcrumb.Item key={`${key}${i}`}>
              {crumbs?.length && crumbs[index] ? (
                <Link className={styles.cta} to={crumbs[index]}>
                  {text}
                </Link>
              ) : index == urlToCrumbs.length - 1 ? (
                customEnding ? (
                  customEnding
                ) : (
                  text
                )
              ) : (
                text
              )}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };
  return (
    <div dir={isRTL ? "rtl" : "ltr"} className={styles.wrapper}>
      <h2 dir={isRTL ? "rtl" : "ltr"} className={styles.title}>
        <>
          {showBackButton == true ? (
            <Button
              type="link"
              shape={undefined}
              className={styles.button}
              onClick={() => {
                history.goBack();
              }}
            >
              <Icon name="LEFT_ARROW" />
            </Button>
          ) : (
            null
          )}
        </>
        <header>
          <FormattedMessage id={title} />
        </header>
        <section>{getCrumbs()}</section>
      </h2>
      {subTitle && <h4 className={styles.subTitle}>{subTitle}</h4>}
    </div>
  );
}

const mapState = (state) => ({
  vertical: state.user.userInfo.selectedVerticalType,
  isRTL: checkRtl(state.user.locale),
});
export default connect(mapState)(Header);
