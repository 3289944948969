import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Input, Button, Select, DatePicker } from "antd";
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import {
  Panel,
  CustomModal,
  UploadButton,
  FullPageDrawer,
  ErrorMessage,
  Label,
  CustomImage,
} from "../../../../components/common";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import Validations from "../../../../shared/validations/Validations";

import "./add-edit-advertisement.scss";

const errorText = "Field cannot be empty";

const styles = {
  wrapper: "add-advertisement__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  imageContainer: "add-advertisement__image-container",
  leftForm: "add-advertisement__left-form",
  rightForm: "add-advertisement__right-form",
  form: "add-advertisement__form",
  spanImp: "add-advertisement__span-img",
};

const { Option } = Select;

function AddEditAdvertisement(props) {
  const intl = useIntl();
  const chainTimeout = useRef();
  const imageContainerRef = useRef(null);
  const [isPristine, togglePristine] = useState(true);
  const [store, updateStore] = useState(undefined);
  const [image, updateImage] = useState(Validations.validateTextField(""));
  const [advertisementType, updateAdvertisementType] = useState({
    selected: undefined,
    data: [
      {
        key: 0,
        value: "Category",
      },
      {
        key: 1,
        value: "Sub-Category",
      },
      {
        key: 2,
        value: "Product",
      },
      {
        key:3,
        value:"Store"

      }
    ],
  });

  const [categories, updateCategories] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [subCategories, updateSubCategories] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [products, updateProducts] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [startDate, updateStartDate] = useState(Validations.validateStartEndDate(undefined, undefined).startDate);
  const [endDate, updateEndDate] = useState(Validations.validateStartEndDate(undefined, undefined).endDate);
  const [isActive, updateIsActive] = useState({
    selected: 1,
    data: [
      {
        key: 0,
        value: "Inactive",
      },
      {
        key: 1,
        value: "Active",
      },
    ],
  });
  const handleStoreChange = (store) => {
    updateStore(store);
    updateCategories({
      ...categories,
      selected: undefined,
      data: [],
      notFound: false,
    });

    updateSubCategories({
      ...subCategories,
      selected: undefined,
      data: [],
      notFound: false,
    });
    updateProducts({
      ...products,
      selected: undefined,
      data: [],
      notFound: false,
    });
  };

  const handleAdvertisementTypeChange = (ad) => {
    updateAdvertisementType({
      ...advertisementType,
      selected: ad,
    });
  };

  const handleCategoryChange = (category) => {
    updateCategories({
      ...categories,
      selected: category,
    });

    updateSubCategories({
      ...subCategories,
      selected: undefined,
      data: [],
      notFound: false,
    });
    updateProducts({
      ...products,
      selected: undefined,
      data: [],
      notFound: false,
    });
  };

  const handleSubCategoryChange = (subCategory) => {
    updateSubCategories({
      ...subCategories,
      selected: subCategory,
    });
    updateProducts({
      ...products,
      selected: undefined,
      data: [],
      notFound: false,
    });
  };

  const handleProductChange = (product) => {
    updateProducts({
      ...products,
      selected: product,
    });
  };

  const handleImageChange = (image) => {
    updateImage(Validations.validateTextField(image));
  };

  const handleStartDateChange = (date) => {
    const dates = Validations.validateStartEndDate(date, endDate.value);
    updateStartDate(dates.startDate)
    updateEndDate(dates.endDate)
  };

  const handleEndDateChange = (date) => {
    const dates = Validations.validateStartEndDate(startDate.value, date);
    updateStartDate(dates.startDate)
    updateEndDate(dates.endDate)
  };

  const handleStatusChange = (status) => {
    updateIsActive({
      ...isActive,
      selected: status,
    });
  };

  const resetForm = () => {
    updateStore(undefined);
    togglePristine(true);
    updateImage(Validations.validateTextField(undefined));
    updateIsActive({
      ...isActive,
      selected: 1,
    });
    updateCategories({
      selected: undefined,
      isLoading: false,
      data: [],
      notFound: false,
    });
    updateSubCategories({
      selected: undefined,
      isLoading: false,
      data: [],
      notFound: false,
    });
    updateProducts({
      selected: undefined,
      isLoading: false,
      data: [],
      notFound: false,
    });
    updateStartDate(Validations.validateStartEndDate(undefined, undefined).startDate);
    updateEndDate(Validations.validateStartEndDate(undefined, undefined).endDate);
    updateAdvertisementType({
      ...advertisementType,
      selected: undefined,
    });
  };

  const isFormValid = () => {
    let validity = true;

    validity =
      image.isValid &&
      startDate.isValid &&
      endDate.isValid &&
      // categories.selected !== undefined &&
      advertisementType.selected !== undefined;

    if (props.modalType === "ADD") {
      validity = validity && store !== undefined;
    }
   if(advertisementType.selected===0){
     validity=validity && categories.selected !==undefined

   }
    if (advertisementType.selected === 1 ) {
      validity = validity && subCategories.selected !== undefined;
    }

    if (advertisementType.selected === 2) {
      validity = validity && products.selected !== undefined;
    }
    if (advertisementType.selected === 3) {
      validity = validity 
    }


    console.log(validity);
    return validity;
  };
  console.log(startDate, endDate)
  const handleSubmit = () => {
   alert("Submit")
    
  };

  const handleClose = () => {
    resetForm();
    props.onCloseIconClick();
  };

  const initEdit = () => {
    updateStore(props.editStoreId);
    updateAdvertisementType({
      ...advertisementType,
      selected: props.data.bannerType
    });
    const dateData = Validations.validateStartEndDate(moment(props.data.startDateTime), moment(props.data.endDateTime))
    updateStartDate(dateData.startDate);
    updateEndDate(dateData.endDate);
    updateImage(Validations.validateTextField(props.data.image))
    updateIsActive({
      ...isActive,
      selected: props.data.isActive ? 1 : 0,
    })
    console.log(props.data);
    if(props.data.bannerType === 0) {
      updateCategories({
        ...categories,
        selected: props.data.categoryId
      })
    } else if(props.data.bannerType === 1) {
      updateCategories({
        ...categories,
        selected: props.data.categoryId
      })
      updateSubCategories({
        ...subCategories,
        selected: props.data.subCategoryId
      })

    } else if(props.data.bannerType === 2) {
      updateCategories({
        ...categories,
        selected: props.data.categoryId
      })
      updateSubCategories({
        ...subCategories,
        selected: props.data.subCategoryId
      })
      updateProducts({
        ...products,
        selected: props.data.productId
      })
    }
  };


  useEffect(() => {
    if(props.isVisible) {
      if (props.modalType === "ADD") {
        resetForm();
      }
  
      if (props.modalType === "EDIT") {
        initEdit();
      }
    }
  }, [props.isVisible]);

  // STORE CHANGE


  // CATEGORY CHANGE


  // SubCategory Change


  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };


  const getFormData = () => ({
    "branchId": store,
    "image": image.value,
    "bannerType": advertisementType.selected,
    "startDateTime": startDate.value,
    "endDateTime": endDate.value,
    "categoryId": categories.selected,
    "subCategoryId": subCategories.selected,
    "productId": products.selected,
    // "isActive": isActive.selected,
  })
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <CustomModal
      // width={800}
      // onClose={props.onClose}
      onCloseIconClick={handleClose}
      header={"Add Question"}
      isVisible={props.isVisible}
      action={<>
          <Button shape={undefined} onClick={handleClose}>
            {intl.formatMessage({id: 'app.button.cancel'})}
          </Button>
          <Button type="primary" loading={props.isLoading} shape={undefined} onClick={handleSubmit}>
          {intl.formatMessage({id: 'app.button.submit'})}
          </Button>
      </>}
    >
      <Panel isModal isLoading={categories.isLoading || subCategories.isLoading || products.isLoading || props.isLoading} className={styles.formModal}>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Category Name" />
            <Input
              // disabled={modalToShow == "VIEW"}
              placeholder="Category Name"
              // value={category.value}
              // onChange={(e) => handlecategoryName(e)}
            />
            {/* {!isPristine && <ErrorMessage field={category} />} */}
          </div>

          <div className={styles.formItem}>
            <Label value="Category Type" />
            <Select
              className={styles.disabledColor}
              // disabled={modalToShow == "VIEW"}
              // onChange={(val) => handleCategoryType(val)}
              // value={typeOfPromo.value}
              // value={category_Type.value}
            >
              {/* {categoryType.map((item) => (
                <Option key={item.value} value={item.text}>
                  {item.text}
                </Option>
              ))} */}
            </Select>
            {/* {!isPristine && !category_Type.value && (
              <ErrorMessage text={errorText} />
            )} */}
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditAdvertisement;
