import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { Select, Dropdown, Button, Menu, DatePicker, Tag, Input } from "antd";
import { connect } from "react-redux";
import userActions from "../../redux/user-info/user-actions";
import classNames from "classnames";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../components/common";
import placeholderImg from "../../assets/images/picker-placeholder.png";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiose";
import AddEditUser from "./add-edit-picker/add-edit-picker";
// import "./pickers.scss";
import { checkRtl } from "../../shared/utils/rtl";

import { CustomerData } from "../../FakeData";

const styles = {
  wrapper: "serviceBooking__wrapper",
  stickyRight: "serviceBooking__sticky-right",
  stickyAction: "sticky-action",
  upperMenu: "serviceBooking__upper-menu",
  upperMenuLeft: "serviceBooking__upper-menu__left",
  upperMenuRight: "serviceBooking__upper-menu__right",
  upperMenuDropdown: "serviceBooking__upper-menu__dropdown",
  pageSize: "products__upper-menu__records-per-page",
  image: "serviceBooking__image",
  tableActions: "serviceBooking__table-actions",
  actionIcon: "serviceBooking__action-icon",
  actionIconHidden: "serviceBooking__action-icon--hidden",
  table: "custom-table",
  content: "custom-section",
  menu: "custom-menu",
  tableContainer: "serviceBooking__table-container",
  filterCont: "filterCont",
};

const { Option } = Select;
const { RangePicker } = DatePicker;

function BookingAccounts(props) {
  const intl = useIntl();
  const history = useHistory();
  const [timeFrameFilterValue, setTimeFrameFilterValue] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedFliter, setSelectedFilter] = useState("booking_id");
  const [search, updateSearch] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    type: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [searchString, setSearchString] = useState("");

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const closeModal = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };
  // MountConfirmModal

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };

  const getCustomersData = async () => {
    updateTableControls({
      ...tableControls,

      isLoading: true,
    });
    const queryParams = {
      status: selectedStatus !== "" ? selectedStatus : undefined,
      startDate: startDate ? startDate.toISOString() : undefined,
      endDate: endDate ? endDate.toISOString() : undefined,
      searchBy: selectedFliter !== "" ? selectedFliter : undefined,
      searchString: inputSearch !== "" ? inputSearch : undefined,
    };

    try {
      const response = await axios.get("/admins/accounts", {
        params: queryParams,
      });
      const data = response.data.data;

      updateTableControls({
        ...tableControls,
        isEmpty: data.transactions.length === 0,
        isLoading: false,
        data: data.transactions,
        count: data.transactionCount,
      });

      updatePagination({
        ...pagination,
        total: data.transactionCount,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      updateTableControls({
        ...tableControls,
        isEmpty: true,
        isLoading: false,
        data: [],
        count: 0,
      });
    }
  };
  useEffect(() => {
    getCustomersData();
  }, [
    pagination.current,
    pagination.pageSize,
    searchDelayed,
    timeFrameFilterValue,
    selectedStatus,
    selectedFliter,
    inputSearch,
    startDate,
    endDate,
  ]);
  const handleCustomerToggle = (data) => {
    alert("User Block");
  };

  const removeCustomer = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleDeleteCustomer = () => {
    alert("Delete Customer");
  };

  const handleCustomerView = (data) => {
    console.log("handleCustomerView => ");
    history.push(`/home/service-booking/details/${data?.id}`);
    // alert("View Details")
  };

  const handleAddSP = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      isDeleteModalVisible: false,
    });
  };
  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            handleCustomerView(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            handleAddSP(item);
          }}
        >
          Add Service Provider
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            removeCustomer(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };
  const handleSearchChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input value has at least 3 characters
    if (inputValue.length >= 3) {
      setInputSearch(inputValue);
    } else {
      setInputSearch("");
    }
  };
  useEffect(() => {
    getCustomersData();
  }, [pagination.current, pagination.pageSize, searchDelayed]);

  const handleTableChange = (pag, filt, sorter) => {
    updatePagination({
      ...pag,
    });
  };
  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: "left",
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "booking_id",
      title: "Booking Id",
      render: (bookingId) => (
        <Tag color="#5FAAFF">{bookingId ? bookingId : "Not Present"}</Tag>
      ),
    },
    {
      dataIndex: "transactionId",
      title: "Transaction Id",

      render: (transactionId) => (
        <Tag color="purple">
          {transactionId
            ? `${transactionId.substring(0, 15)}...`
            : "Not Present"}
        </Tag>
      ),
    },
    {
      dataIndex: "amount",
      title: "Amount",
      render: (amount) => (
        <Tag color={parseFloat(amount) < 0 ? "#F95050" : "#74D95B"}>
          {amount}
        </Tag>
      ),
    },

    {
      dataIndex: "status",
      title: "Status",
      render: (status) => (
        <Tag color={status === "CASH OUT" ? "#F95050" : "#74D95B"}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
    },
    {
      dataIndex: "createdAt",
      title: "Created Date",
      render: (createdAt) => (
        <span>{moment(createdAt).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      dataIndex: "createdAt",
      title: "Created Time",
      render: (createdAt) => (
        <span>{moment(createdAt).format("HH:mm:ss")}</span>
      ),
    },
  ];

  const FIlterList = [
    {
      id: "",
      text: "All",
    },
    {
      id: "CASH IN",
      text: "Cash In",
    },
    {
      id: "CASH OUT",
      text: "Cash Out",
    },
  ];
  // Filter by Last 1 day, week, 3 months and 6 months. Provide an option for start and end date if we select custom.
  const filterOption = [
    {
      id: "booking_id",
      text: "Booking ID",
    },
    {
      id: "transactionId",
      text: "Transaction ID",
    },
  ];
  const handleFilter = (val) => {
    console.log("handleFilter func", val);
    setTimeFrameFilterValue({
      ...timeFrameFilterValue,
      valueKey: val,
    });
  };
  return (
    <div className={styles.wrapper}>
      <h1>{"  "}</h1>

      <AddEditUser
        // isVisible={false}
        // isVisible={true}
        isVisible={modalControls.isVisible}
        onCloseIconClick={closeModal}
        // onClose={closeModal}
        getCustomersDetail={getCustomersData}
        // editCustomer={editCustomer}
        // data={modalControls.data}
        isLoading={modalControls?.isLoading}
        // data={}
        customerId={modalControls?.customerId}
        modalToShow={modalControls?.modalType}
        // data={modalControls?.data}
      />

      <ConfirmModal
        header={`Delete ${modalControls?.data?.firstName}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={closeModal}
        onAction={handleDeleteCustomer}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      <Header title="Accounts" path="/home/Accounts" />

      <div className={styles?.filterCont}>
        {/* Filter by Last 1 day, week, 3 months and 6 months. */}
        <div>
          <Label value="Filter by Status" vertical></Label>
          <Select
            showSearch
            placeholder="Filter by Status"
            style={{ width: "240px" }}
            value={selectedStatus}
            onChange={(value) => setSelectedStatus(value)}
          >
            {FIlterList.map((obj) => (
              <Option key={obj?.id} value={obj?.id}>
                {obj?.text}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <Label value="Filter By Date" vertical></Label>
          <div>
            <DatePicker
              style={{ width: "240px" }}
              placeholder="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
        </div>
        <div>
          <DatePicker
            style={{ width: "240px" }}
            placeholder="End Date"
            value={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
      </div>

      <div className="custom-section">
        <div
          className={styles.menu}
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-end",
            gap: "2rem",
          }}
        >
          {/* Filter by Last 1 day, week, 3 months and 6 months. */}

          <div className={styles.lowerMenuDropdown}>
            <Label value="Filter By" vertical />
            <Select
              style={{ width: "240px" }}
              onChange={(value) => setSelectedFilter(value)}
              value={selectedFliter}
            >
              {filterOption.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item?.text}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Input
              style={{ width: "240px" }}
              onChange={handleSearchChange}
              placeholder={
                selectedFliter === "booking_id"
                  ? "Enter Booking ID"
                  : "Enter Transaction ID"
              }
            />
          </div>
        </div>
        <CustomTable
          isLoading={tableControls.isLoading}
          columns={columns}
          dataSource={tableControls.data}
          isEmpty={tableControls.isEmpty}
          // dataSource={tableControls.data}
          toggleItemBlock={handleCustomerToggle}
          viewItem={handleCustomerView}
          removeItem={removeCustomer}
          pagination={pagination}
          onChange={handleTableChange}
          current={pagination.current}
          pageSize={pagination.pageSize}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(BookingAccounts);
