import React, { useState, useEffect } from "react";
import { Popconfirm, Tooltip, Switch, Menu, Dropdown, Rate, Tag } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import classNames from "classnames";
import moment from "moment";
import axiosConfig from "../../../../shared/api/axiose";
import axios from "../../../../shared/api/axiose";
import { useHistory } from "react-router-dom";
import {
  Panel,
  Icon,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../../../components/common";
import "./active-promo-table.scss";
import { connect } from "react-redux";
import ViewUser from "../../view-user/view-user";
import { dateFormat } from "../../../../shared/utils/local-storage";
import { toastr } from "react-redux-toastr";

const styles = {
  table: "custom-table",
  tableActions: "custom-table__table-actions",
  stickyAction: "sticky-action",
  actionIcon: "custom-table__action-icon",
  panelContainer: "active-promo-table__panel-container",
};

function ActivePromoTable({
  viewItem,
  updateTableControls,
  getCategoriesData,
  tableControls,
  ServiceData,

  ...props
}) {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const intl = useIntl();
  const Switchbtn = (item) => {};
  console.log("data", ServiceData);

  const handleEnableDisable = (data) => {
    console.log("handleEnableDisable => ", data);
    updateTableControls({
      ...tableControls,
      isTableLoading: true,
    });

    axios
      .put(`/services/enableDisable/${data?.id}`, {
        status: !data?.status,
      })
      .then((res) => {
        if (res?.data?.statusCode >= "200" && res?.data?.statusCode <= "299") {
          ServiceData();
        } else {
          updateTableControls({
            ...tableControls,
            isTableLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log("city enable disable err=>", err);
        updateTableControls({
          ...tableControls,
          isTableLoading: false,
        });
      });
  };
  const getCategoriesName = (item, getCategoriesData) => {
    let categoryName = "";

    if (item?.id && item?.id[0]?.categoryId) {
      let id = item?.id[0]?.categoryId;

      categoryName = getCategoriesData.filter((obj) => {
        return id == obj.id;
      });
    }

    return categoryName ? categoryName[0]?.title : "N/A";
  };
  const getServiceTag = (item) => {
    let ServiceTagsArr = JSON.parse(item);

    let serviceTag = "";
    ServiceTagsArr.map((obj) => {
      serviceTag = serviceTag + "  " + obj?.serviceTypeTag;
    });
    return serviceTag;
  };
  const columns = () => {
    const productMapping = props.productMapping;

    return [
      {
        fixed: "left",
        dataIndex: "",
        title: intl.formatMessage({ id: "app.field.sNo" }),
        render: (item, object, index) => {
          return (
            <span>
              {props.pagination.pageSize * (props.pagination.current - 1) +
                index +
                1}
            </span>
          );
        },
      },
      {
        dataIndex: "serviceImg",
        title: "Service Image",
        render: (item) => <CustomImage src={item} isTable />,
      },
      {
        dataIndex: "title",
        title: "Service Name",
        render: (title) => (
          <span style={{ color: "#1890ff", fontWeight: "600" }}>{title}</span>
        ),
      },
      {
        dataIndex: "isPickupService",
        title: "Service Type",
        render: (isPickupService) => (
          <Tag color={isPickupService ? "#5FAAFF" : "#2980B9"}>
            {isPickupService ? "Pickup Service" : "Regular Service"}
          </Tag>
        ),
      },
      {
        dataIndex: "rating",
        title: "Ratings",
        render: (rating) => {
          return <Rate style={{ color: "#ED432A" }} disabled value={rating} />;
        },
      },
      {
        dataIndex: "actualPrice",
        title: "Actual Price",
        render: (actualPrice) => (
          <Tag color="Orange">{actualPrice ? actualPrice : "null"}</Tag>
        ),
      },
      {
        dataIndex: "discountedPrice",
        title: "Discounted Price",
        render: (discountedPrice) => (
          <Tag color="Green">{discountedPrice ? discountedPrice : "null"}</Tag>
        ),
      },

      {
        dataIndex: "status",
        title: "Status",
        render: (item) => {
          return <CustomTag isEnabled={item} />;
        },
      },
      {
        dataIndex: "createdAt",
        title: "Created At",
        render: (createdAt) => (
          <span>
            {createdAt
              ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss")
              : "null"}
          </span>
        ),
      },
      {
        dataIndex: "updatedAt",
        title: "Updated At",
        render: (updatedAt) => (
          <span>
            {updatedAt
              ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss")
              : "null"}
          </span>
        ),
      },
      {
        dataIndex: "",
        title: intl.formatMessage({ id: "app.field.action" }),
        fixed: "right",
        render: (item) => {
          return (
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          );
        },
      },
      // ... (other columns)
    ];
  };
  const handleCustomerView = (data) => {
    history.push(`/home/service/details/${data.id}`);
  };
  const handleCustomerEdit = (data) => {
    history.push(`/home/service/edit/${data.id}`);
  };
  const getMenu = (item) => {
    console.log("getMenu =>> ", item);
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            handleCustomerView(item);
          }}
        >
          View
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleCustomerEdit(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>

        {item?.status === true ? (
          <Menu.Item onClick={() => handleEnableDisable(item)}>
            Disable
          </Menu.Item>
        ) : (
          <Menu.Item onClick={() => handleEnableDisable(item)}>
            Enable
          </Menu.Item>
        )}

        <Menu.Item
          onClick={() => {
            props.removeItem(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <CustomTable
        dataSource={props.data}
        // pagination={props.pagination}
        onChange={props.onChange}
        columns={columns()}
        columnWidh={"50px"}
        isLoading={props.isTableLoading}
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  panelType: state.user.enterprice.panelType,
});

export default connect(mapStateToProps)(ActivePromoTable);
