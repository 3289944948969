const removeEmptyKeys = (obj) => {
  return Object.keys(obj).reduce((acc, val) => {
    if (
      obj[val] ||
      obj[val] === 0 ||
      obj[val] === "0" ||
      Array.isArray(obj[val])
    ) {
      // if (obj[val].length !== 0) {
      acc[val] = obj[val];
      // }
    }
    return acc;
  }, {});
};

const testEmpty = {
  a: 0,
  b: null,
  c: NaN,
  d: undefined,
  e: [],
  f: "",
  g: ["a"],
  h: 13,
  i: "iasd",
};

function nestedParseJSON(data, isArray = Array.isArray(data)) {
  return Object.keys(data).reduce(
    (acc, val) => {
      if (
        typeof data[val] === "string" &&
        (data[val].includes("{") || data[val].includes("["))
      ) {
        try {
          acc[val] = nestedParseJSON(JSON.parse(data[val]));
        } catch (e) {
          acc[val] = data[val];
        }
      } else if (Array.isArray(data[val])) {
        acc[val] = nestedParseJSON(data[val]);
      } else if (
        typeof data[val] === "string" ||
        typeof data[val] === "boolean" ||
        typeof data[val] === "number" ||
        data[val] === null ||
        data[val] === undefined
      ) {
        acc[val] = data[val];
      } else {
        acc[val] = nestedParseJSON(data[val]);
      }
      return acc;
    },
    isArray ? [] : {}
  );
}

const numberWithCommas = (x) => {
  if (x === null) return 0;
  return parseInt(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = "";
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

function multiSort(array, sortObject = {}) {
  const sortKeys = Object.keys(sortObject);

  // Return array if no sort object is supplied.
  if (!sortKeys.length) {
    return array;
  }

  // Change the values of the sortObject keys to -1, 0, or 1.
  for (let key in sortObject) {
    sortObject[key] =
      sortObject[key] === "desc" || sortObject[key] === -1
        ? -1
        : sortObject[key] === "skip" || sortObject[key] === 0
        ? 0
        : 1;
  }

  const keySort = (a, b, direction) => {
    a = ltrim(a.toLowerCase());
    b = ltrim(b.toLowerCase());
    direction = direction !== null ? direction : 1;

    if (a === b) {
      // If the values are the same, do not switch positions.
      return 0;
    }
    // If b > a, multiply by -1 to get the reverse direction.
    return a > b ? direction : -1 * direction;
  };

  function ltrim(str) {
    if (!str) return str;
    return str.replace(/^\s+/g, "");
  }
  return array.sort((a, b) => {
    let sorted = 0;
    let index = 0;
    // Loop until sorted (-1 or 1) or until the sort keys have been processed.
    while (sorted === 0 && index < sortKeys.length) {
      const key = sortKeys[index];

      if (key) {
        const direction = sortObject[key];

        sorted = keySort(a[key], b[key], direction);
        index++;
      }
    }
    return sorted;
  });
}

export {
  removeEmptyKeys,
  numberWithCommas,
  abbreviateNumber,
  nestedParseJSON,
  multiSort,
};
