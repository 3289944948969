import React, { useState, useEffect } from "react";
import axios from "../../../../shared/api/axiose";
import { useIntl } from "react-intl";
import moment from "moment";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import numeral from "numeral";
import { Panel } from "../../../../components/common";
import {
  abbreviateNumber,
  numberWithCommas,
} from "../../../../shared/utils/object-manipulation";
import "./yearly-chart.scss";
import { Select, DatePicker, Button } from "antd";

const styles = {
  wrapper: "dashboard__yearly-chart__wrapper",
  header: "dashboard__yearly-chart__header",
  containerClass: "dashboard__yearly-chart__container-class",
  transFix: "custom-trans-fix",
  filterCont: "dashboard__yearly-chart__filterCont",
};

function CustomerChart({ header, ...props }) {
  const [timeFrameFilterValue, setTimeFrameFilterValue] = useState();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const handleFilter = async (val) => {
    setTimeFrameFilterValue(val);

    const today = moment().format("YYYY-MM-DD");
    let startDate, endDate;

    switch (val) {
      case 1: // Current Week
        startDate = moment().startOf("week").format("YYYY-MM-DD");
        endDate = moment().endOf("week").format("YYYY-MM-DD");
        break;
      case 2: // Current Month
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = today;
        break;
      case 3: // Last Six Months
        startDate = moment()
          .subtract(6, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = today;
        break;
      case 4: // Last Year
        startDate = moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD");
        endDate = today;
        break;
      case 6: // Custom Range
        // Set your custom start and end dates here
        break;
      default: // Default to current week if no valid value is selected
        startDate = moment().startOf("week").format("YYYY-MM-DD");
        endDate = moment().endOf("week").format("YYYY-MM-DD");
        break;
    }

    try {
      const response = await axios.get(
        "/admins/reports/dashboardCustomerGrowth",
        {
          params: {
            startDate,
            endDate,
          },
        }
      );

      setApiData(response.data.data);

      formatData(
        Object.entries(response.data.data).map(([year, sales]) => ({
          year,
          sales,
        }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const intl = useIntl();
  const [chartData, updateChartData] = useState([]);
  const formatData = (yearChart) => {
    const formattedData = yearChart.map((item) => ({
      ...item,
      Customers: item.sales,
    }));
    updateChartData(formattedData);
  };

  const formatNums = (num) => {
    return numeral(num).format("0 ");
  };

  const [apiData, setApiData] = useState({});

  const fetchData = async () => {
    const startDate = moment().startOf("week").format("YYYY-MM-DD");
    const endDate = moment().endOf("week").format("YYYY-MM-DD");

    try {
      const response = await axios.get(
        "/admins/reports/dashboardCustomerGrowth",
        {
          params: {
            startDate,
            endDate,
          },
        }
      );

      setApiData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    formatData(
      Object.entries(apiData).map(([year, sales]) => ({ year, sales }))
    );
  }, [apiData]);

  const filterOption = [
    {
      id: 1,
      text: "Current Week",
    },

    {
      id: 2,
      text: "Current Month",
    },
    {
      id: 3,
      text: "Last 6 Months",
    },
    {
      id: 4,
      text: "Last 1 Year",
    },
  ];

  return (
    <div className={styles.containerClass}>
      <div className={styles?.filterCont}>
        <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
          <div className={styles.lowerMenuDropdown}>
            <Label value="Filter By" horizontal />
            <Select
              // disabled={showAllProducts}
              value={timeFrameFilterValue?.valueKey}
              style={{ width: "150px" }}
              showSearch
              placeholder="Select Filter type"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleFilter}
            >
              {filterOption.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item?.text}
                </Option>
              ))}
            </Select>
          </div>
          {timeFrameFilterValue?.valueKey === 6 && <RangePicker />}
        </div>

        {/* <Button
          type="primary"
          style={{ backgroundColor: "rgba(21, 96, 189, 1)" }}
          className={styles.FilterBtn}
        >
          Filter
        </Button> */}
      </div>
      <div className={styles.header}>{header}</div>
      <ResponsiveContainer
        width="100%"
        height="100%"
        aspect={3}
        className={styles.transFix}
      >
        <AreaChart data={chartData}>
          <CartesianGrid vertical={false} strokeDasharray="3 0" />
          <XAxis dataKey="year"></XAxis>
          <YAxis
            type="number"
            tickCount={5}
            interval={1}
            tickFormatter={(tick) => (tick !== 0 ? Math.floor(tick) : "")}
          >
            <Label
              angle={-90}
              fill="#2067bd"
              value={header}
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>

          <Tooltip formatter={numberWithCommas} />
          <Legend />
          <Area
            type="monotone"
            dataKey="Customers"
            // stroke={props.color}
            fill="#b3e6ff"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CustomerChart;
