import React, { useState, useEffect } from "react";

import { CustomModal, ErrorMessage, Label, Panel } from "../components/common";

import Validations from "../shared/validations/Validations";
import "./forgot-password.scss";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { Button, Input } from "antd";
import axios from "../shared/api/axiose";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import OTPVerificationModal from "./login/verificationOTP";

const styles = {
  wrapper: "fp-component__wrapper",
  formGroup: "custom-form-group",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  label: "fp-component__label",
  value: "fp-component__value",
  row: "fp-component__row",
};

const ForgotPassword = (props) => {
  const intl = useIntl();
  const history = useHistory();

  const [email, updateEmail] = useState(Validations.validateEmail(""));
  const [countryCode, updateCountryCode] = useState(
    Validations.validateTextField("")
  );
  const [phoneNo, updatePhoneNo] = useState(Validations.validateNumericValue());
  const [isLoading, toggleLoading] = useState(false);
  const [isPristine, updateIsPristine] = useState(true);
  const [modalControl, updateModalControl] = useState({
    isVisible: false,
  });

  const handleEmailChange = (e) => {
    updateEmail(Validations.validateEmail(e.target.value));
  };
  const handleCountryCode = (e) => {
    updateCountryCode(Validations.validateTextField(e.target.value));
  };
  const handlePhoneNo = (e) => {
    updatePhoneNo(Validations.validateNumericValue(e.target.value));
  };
  const handleResetPassword = () => {
    toggleLoading(true);

    const payload = {
      email: email.value,
    };
    // console.log("api hit");
    axios
      .post("/admins/forget-password", payload)
      .then((response) => {
        console.log(response.data);

        toast("🤩 Reset link sent successfully, Please check your Email", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        history.push("/");
      })
      .catch((error) => {
        toast("🤕 Failed to send reset link", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        toggleLoading(false);
      });
  };
  const isFormValid = () => {
    let isValid = true;
    isValid =
      isValid &&
      ((props.loginType === 0 && email.isValid) ||
        (props.loginType === 1 && countryCode.isValid && phoneNo.isValid));

    return isValid;
  };

  return (
    <CustomModal
      size="tiny"
      header="Forgot Password"
      isVisible={props.isVisible}
      onCloseIconClick={props.onCloseIconClick}
      onClick={handleResetPassword}
      action={
        <>
          {" "}
          <Button
            type="default"
            shape={undefined}
            onClick={props.onCloseIconClick}
          >
            <FormattedMessage id="app.field.cancel" />
          </Button>
          <Button
            type="primary"
            loading={isLoading}
            shape={undefined}
            onClick={handleResetPassword}
          >
            <FormattedMessage id="Send Link" />
          </Button>
        </>
      }
    >
      <Panel className={styles.formModal}>
        {props.loginType === 0 ? (
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label for="email" value="app.field.email" />
              <Input
                name="email"
                size="large"
                onChange={handleEmailChange}
                value={email.value}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.email",
                })}
              />
              {!isPristine && !email.isValid && (
                <ErrorMessage text={email.errMsg} />
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        {props.loginType === 1 ? (
          <div>
            <div
              style={{
                marginBottom: "1rem",
                display: "flex",
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              <div style={{ width: "5rem" }}>
                <Input
                  style={{ width: "100%" }}
                  onChange={handleCountryCode}
                  value={countryCode.value}
                />
              </div>
              <div style={{ width: "100%" }}>
                <Input
                  onChange={handlePhoneNo}
                  value={phoneNo.value}
                  icon="user"
                  placeholder="Enter Phone no"
                />
              </div>
            </div>
            {!isPristine && (!countryCode.isValid || !phoneNo.isValid) && (
              <ErrorMessage text="Country code and Phone no should be valid" />
            )}
          </div>
        ) : (
          ""
        )}
      </Panel>
    </CustomModal>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(ForgotPassword);
