import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  Label,
  UploadButton,
  CustomImage,
} from "../../../components/common";
import { Button, Checkbox, Input, Rate, Select } from "antd";
import { connect } from "react-redux";

import Validations from "../../../shared/validations/Validations";
import countryCodes from "../../../shared/constants/countries.json";

import { toastr } from "react-redux-toastr";
import "./add-edit-user.scss";
import { AiOutlineCloudDownload } from "react-icons/ai";

import { useIntl } from "react-intl";
import { checkRtl } from "../../../shared/utils/rtl";
import { InteractionFilled } from "@ant-design/icons";
import DatePicker from "react-datepicker";
import { TimePicker } from "antd";
import axiosConfig from "../../../shared/api/axiosConfig";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import classNames from "classnames";
import moment, { weekdays } from "moment";
import { referral_Source } from "../../../shared/utils/local-storage";
dayjs.extend(customParseFormat);
const { Option } = Select;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
  datePicker: "datePicker",
  disabled: "disabled",
  multySelect_disabled: "multySelect_disabled",
  fileUpload: "fileUpload",
  // formHeader: "add-edit-user-modal__",
};
function AddEditUser({
  getCategoriesData,
  getServiceData,
  serviceChoice,
  cityData,
  getServiceProviderData,
  onCloseIconClick,
  serviceProviderDetails,
  ...props
}) {
  const intl = useIntl();
  const format = "HH:mm";
  console.log("cityData = > ", cityData);
  const errorMessage = "Please fill the field";
  const [value, setValue] = useState("10:00");
  console.log("TIme picker = > ", value);
  const imageContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (timeValue) => {
    setValue(timeValue);
  };
  const [frontImageURL, changeFrontImageURL] = useState(
    Validations.validateTextField("")
  );
  const [backImageURL, changeBackImageURL] = useState(
    Validations.validateTextField("")
  );

  const [name, changeName] = useState(Validations.validateTextField(""));
  const [email, changeEmail] = useState(Validations.validateEmail(""));

  //   {
  //       Personal Details
  //       ====Input =============

  //     1.bo_first_name
  //     2. bo_last_name
  //     3. bo_email
  //     4.bo_walletAmount
  //     5. bo_phoneNumber
  //     6. bo_priorityRanking
  //     7. bo_zipcode
  //     8. bo_Background_verification_status
  //     9. bo_license_verification_status
  //     10. bo_Status
  //     11. referral_source

  // =======Select ============
  // 1. countryCode
  // 2. Avg Rating

  // ===== DatePicker=======
  // 1. Registration date
  // 2. Last Active
  //   }
  // const [profileImageURL, changeProfileImageURL] = useState(
  //   Validations.validateTextField("")
  // );
  const [isPristine, changeIsPristine] = useState(true);
  const [personalDetails, updatePersonalDetails] = useState({
    bo_profileImageURL: Validations.validateTextField(""),
    bo_first_name: Validations.validateTextField(),
    bo_last_name: Validations.validateTextField(),
    bo_email: Validations.validateEmail(""),
    bo_walletAmount: Validations.validateNumericValue(""),
    bo_phoneNumber: Validations.validatePhoneNumber(""),
    bo_priorityRanking: Validations.validateNumericValue(""),
    bo_zipcode: Validations.validateTextField(""),
    bo_referredBy_code: Validations.validateTextField(""),
    bo_Background_verification_status: Validations.validateTextField(""), // Edit && View
    bo_license_verification_status: Validations.validateTextField(""), // Edit && View
    bo_Status: Validations.validateTextField(""), // Edit && View
    bo_referral_source: Validations.validateMultiSelect(),
    bo_regestrationDate: "", // Edit && View
    bo_lastActive: "", // Edit && View
    bo_avgRating: Validations.validateNumericValue(),
    bo_countryCode: Validations.validateTextField("+1"),
    bo_my_referral_code: Validations.validateTextField(""),
    bo_referralcode: Validations.validateTextField(""),
  });

  const [status, updateStatus] = useState({
    backgroundVerificationStatus: "",
    licenseVerificationStatus: "",
  });
  const [businessDetailsData, updateBusinessDetails] = useState({
    establishIn: "",
    businessName: Validations.validateTextField(""),
    noOfEmployee: Validations.validateNumericValue(""),
    businessDescription: Validations.validateTextField(""),

    preferredLang: Validations.validateTextField(),
    businessDocsUpload: Validations.validateTextField(""),
    businessDocsFileName: Validations.validateTextField(""),
  });
  console.log(
    "businessDetailsData => establishIn",
    businessDetailsData?.establishIn
  );

  const [servicesDetailsData, updateServicesDetailsData] = useState({
    categorySelect: Validations.validateMultiSelect(),
    servicesSelect: Validations.validateMultiSelect(),
    otherService: Validations.validateTextField(""),
    otherServiceDescp: Validations.validateTextField(""),
  });
  console.log("servicesDetailsData = > ", servicesDetailsData);
  const [serviceLocationData, updateServiceLocationData] = useState({
    city: Validations.validateMultiSelect(),
    service_choice: Validations.validateMultiSelect(),
    radius: Validations.validateNumericValue(),
  });
  console.log("serviceLocationData => ", serviceLocationData);
  const [setTime, updateTime] = useState("");
  const verifyStatus = [
    {
      id: 1,
      text: "Verified",
    },
    {
      id: 2,
      text: "Unverified",
    },
  ];

  const [workingHour, updateWorkingHour] = useState({
    tuesday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
    wednesday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },

    thursday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },

    friday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
    saturday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
    sunday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
    monday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
  });

  console.log("workingHour => ", workingHour);

  const [backgroundCheck, updateBackgroundChack] = useState({
    SSN: Validations.validateTextField(""),
    driversLice_no: Validations.validateTextField(""),
    facebookUrl: Validations.validateTextField(""),
    licenceType: Validations.validateTextField(""),
    licenseFrontImg: Validations.validateTextField(""),
    licenseBackImg: Validations.validateTextField(""),
  });

  const [professionalCreds, updateProfessionalCreads] = useState({
    licence_issue_state: Validations.validateTextField(""),
    licenceType: Validations.validateTextField(""),
    licenceNo: Validations.validateTextField(""),
    InstagramUrl: Validations.validateTextField(""),
    facebookUrl: Validations.validateTextField(""),
  });

  const [weekDays, updateWeekDays] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  console.log("updateWeekDays => weekDays ", weekDays.sunday);

  const FormReset = () => {
    changeIsPristine(true);
    updateWeekDays({
      ...weekDays,
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    });
    updateServiceLocationData({
      radius: Validations.validateNumericValue(undefined),
      service_choice: Validations.validateMultiSelect(undefined),
      city: Validations.validateMultiSelect(undefined),
    });
    updateProfessionalCreads({
      ...professionalCreds,
      licence_issue_state: Validations.validateTextField(""),
      licenceType: Validations.validateTextField(""),
      licenceNo: Validations.validateTextField(""),
      InstagramUrl: Validations.validateTextField(""),
      facebookUrl: Validations.validateTextField(""),
    });
    updateBackgroundChack({
      ...backgroundCheck,
      SSN: Validations.validateTextField(""),
      driversLice_no: Validations.validateTextField(""),
      facebookUrl: Validations.validateTextField(""),
      licenceType: Validations.validateTextField(""),
      licenseFrontImg: Validations.validateTextField(""),
      licenseBackImg: Validations.validateTextField(""),
    });
    updateServicesDetailsData({
      ...servicesDetailsData,
      categorySelect: Validations.validateMultiSelect(),
      servicesSelect: Validations.validateMultiSelect(),
      otherService: Validations.validateTextField(""),
      otherServiceDescp: Validations.validateTextField(""),
    });

    let todayDate = new Date();
    let endTime =
      moment(todayDate).format("YYYY-MM-DD") +
      "2023-04-12T11:30:00.384Z".slice(10);
    let startTime =
      moment(todayDate).format("YYYY-MM-DD") +
      "2023-04-12T03:30:00.686Z".slice(10);
    // T11: 30: 00.384Z

    console.log("todayDate => start", startTime);
    console.log("todayDate => end  ", endTime);

    updateWorkingHour({
      ...workingHour,
      monday: {
        start: {
          hours: 0,
          minutes: 0,
          startTime: moment(startTime),
        },
        end: {
          hours: 0,
          minutes: 0,
          endTime: moment(endTime),
        },
      },
      tuesday: {
        start: {
          hours: 0,
          minutes: 0,
          startTime: moment(startTime),
        },
        end: {
          hours: 0,
          minutes: 0,
          endTime: moment(endTime),
        },
      },
      wednesday: {
        start: {
          hours: 0,
          minutes: 0,
          startTime: moment(startTime),
        },
        end: {
          hours: 0,
          minutes: 0,
          endTime: moment(endTime),
        },
      },

      thursday: {
        start: {
          hours: 0,
          minutes: 0,
          startTime: moment(startTime),
        },
        end: {
          hours: 0,
          minutes: 0,
          endTime: moment(endTime),
        },
      },

      friday: {
        start: {
          hours: 0,
          minutes: 0,
          startTime: moment(startTime),
        },
        end: {
          hours: 0,
          minutes: 0,
          endTime: moment(endTime),
        },
      },
      saturday: {
        start: {
          hours: 0,
          minutes: 0,
          startTime: moment(startTime),
        },
        end: {
          hours: 0,
          minutes: 0,
          endTime: moment(endTime),
        },
      },
      sunday: {
        start: {
          hours: 0,
          minutes: 0,
          startTime: moment(startTime),
        },
        end: {
          hours: 0,
          minutes: 0,
          endTime: moment(endTime),
        },
      },
    });
    updateBusinessDetails({
      ...businessDetailsData,
      establishIn: "",
      businessName: Validations.validateTextField(""),
      noOfEmployee: Validations.validateNumericValue(""),
      businessDescription: Validations.validateTextField(""),
      businessDocsUpload: Validations.validateTextField(""),
      preferredLang: Validations.validateMultiSelect(),
    });
    updatePersonalDetails({
      personalDetails,
      bo_profileImageURL: Validations.validateTextField(""),
      bo_first_name: Validations.validateTextField(),
      bo_last_name: Validations.validateTextField(),
      bo_email: Validations.validateEmail(""),
      bo_walletAmount: Validations.validateNumericValue(""),
      bo_phoneNumber: Validations.validatePhoneNumber(""),
      bo_priorityRanking: Validations.validateNumericValue(""),
      bo_zipcode: Validations.validateTextField(""),
      bo_Background_verification_status: Validations.validateTextField(""), // Edit && View
      bo_license_verification_status: Validations.validateTextField(""), // Edit && View
      bo_Status: Validations.validateTextField(""), // Edit && View
      bo_referral_source: Validations.validateMultiSelect(["Google"]),
      bo_regestrationDate: "", // Edit && View
      bo_lastActive: "", // Edit && View
      bo_avgRating: Validations.validateNumericValue(),
      bo_countryCode: Validations.validateTextField("+1"),
    });
  };
  const setForm = () => {
    console.log("SetForm BO => 55", props?.data);

    let obj = {
      backgroundVerificationStatus: 2,
      licenseVerificationStatus: 2,
    };
    if (props?.data?.profile?.licenseVerificationStatus) {
      obj.licenseVerificationStatus = 1;
    } else {
      obj.licenseVerificationStatus = 2;
    }
    if (props?.data?.profile?.backgroundVerificationStatus) {
      obj.backgroundVerificationStatus = 1;
    } else {
      obj.backgroundVerificationStatus = 2;
    }
    // "providesOnDemandService": true,
    //         "providesQuotationServices": null,
    //         "providesScheduledServices": true,
    let service_choiceId = [];
    if (props?.data?.profile?.providesOnDemandService) {
      service_choiceId.push(1);
    }
    if (props?.data?.profile?.providesQuotationServices) {
      service_choiceId.push(3);
    }
    if (props?.data?.profile?.providesScheduledServices) {
      service_choiceId.push(2);
    }

    updateServiceLocationData({
      ...serviceLocationData,
      radius: props?.data?.profile?.serviceAreaRadius
        ? Validations.validateNumericValue(
            props?.data?.profile?.serviceAreaRadius
          )
        : "",
      service_choice: Validations.validateMultiSelect(service_choiceId),
    });

    if (props?.data?.profile?.serviceAreaRadius)
      updateStatus({
        ...status,
        ...obj,
      });

    let weekdayObj = { ...weekDays };
    let updatedWorkingHour = { ...workingHour };
    for (let day in props?.data?.profile?.workingHours) {
      let dayHours = props?.data?.profile?.workingHours[day];
      let start = dayHours.start;
      let end = dayHours.end;
      console.log("workingHours ", day, " => dayHours  ", dayHours);

      if (dayHours?.start?.startTime || dayHours?.end?.endTime) {
        weekdayObj = {
          ...weekdayObj,
          [day]: true,
        };
      }

      if ([day])
        updatedWorkingHour = {
          ...updatedWorkingHour,
          [day]: {
            start: {
              ...start,
              startTime: props?.data?.profile?.workingHours[day].start.startTime
                ? moment(
                    props?.data?.profile?.workingHours[day].start.startTime
                  )
                : "",
            },
            end: {
              ...end,
              endTime: props?.data?.profile?.workingHours[day].end.endTime
                ? moment(props?.data?.profile?.workingHours[day].end.endTime)
                : "",
            },
          },
        };
    }

    updateWorkingHour(updatedWorkingHour);
    // console.log("updateWeekDays => ", updateWeekDays)

    updateWeekDays({
      ...weekdayObj,
    });

    updateServicesDetailsData({
      ...servicesDetailsData,
      categorySelect: Validations.validateMultiSelect(),
      servicesSelect: Validations.validateMultiSelect(),
      otherService: Validations.validateTextField(""),
      otherServiceDescp: Validations.validateTextField(""),
    });

    updateBackgroundChack({
      ...backgroundCheck,
      SSN: Validations.validateTextField(props?.data?.profile?.ssn),
      driversLice_no: Validations.validateTextField(
        props?.data?.profile?.drivingLicenceNumber
      ),
      facebookUrl: Validations.validateTextField(
        props?.data?.profile?.backgroundCheckFacebook
      ),
      licenceType: Validations.validateTextField(
        props?.data?.profile?.backgroundCheckLicenceType
      ),
      licenseFrontImg: Validations.validateTextField(
        props?.data?.profile?.driverLicenceForntImage
      ),
      licenseBackImg: Validations.validateTextField(
        props?.data?.profile?.driverLicenceBackImage
      ),
    });

    updateProfessionalCreads({
      ...professionalCreds,
      licence_issue_state: Validations.validateTextField(
        props?.data?.profile?.licenseIssuedState
      ),
      licenceType: Validations.validateTextField(
        props?.data?.profile?.professionalLicenceType
      ),
      licenceNo: Validations.validateTextField(
        props?.data?.profile?.professionalLicenceNumber
      ),
      InstagramUrl: Validations.validateTextField(
        props?.data?.profile?.professionalInstagramUrl
      ),
      facebookUrl: Validations.validateTextField(
        props?.data?.profile?.professionalFacebookUrl
      ),
    });

    updatePersonalDetails({
      ...personalDetails,
      bo_first_name: Validations.validateTextField(props?.data?.firstName),
      bo_last_name: Validations.validateTextField(props?.data?.lastName),
      bo_email: Validations.validateEmail(props?.data?.email),
      bo_phoneNumber: Validations.validatePhoneNumber(props?.data?.phone),
      bo_countryCode: Validations.validateTextField(props?.data?.countryCode),
      bo_zipcode: Validations.validateTextField(props?.data?.zipcode),

      bo_priorityRanking: Validations.validateNumericValue(
        props?.data?.profile?.priorityRanking
          ? props?.data?.profile?.priorityRanking
          : ""
      ),
      bo_avgRating: Validations.validateNumericValue(
        props?.data?.profile?.avgRating
          ? parseInt(props?.data?.profile?.avgRating)
          : ""
      ),
      bo_profileImageURL: Validations.validateTextField(
        props?.data?.businessOwnerProfileImg
      ),
      bo_walletAmount: Validations.validateNumericValue(
        props?.data?.walletAmount
      ),
      bo_Background_verification_status: Validations.validateTextField(""), // Edit && View
      bo_license_verification_status: Validations.validateTextField(""), // Edit && View
      bo_Status: Validations.validateTextField(""), // Edit && View

      bo_regestrationDate: props?.data?.createdAt
        ? new Date(props?.data?.createdAt)
        : "",
      bo_lastActive: props?.data?.updatedAt
        ? new Date(props?.data?.updatedAt)
        : "",
      bo_referral_source: Validations.validateMultiSelect([
        props?.data?.profile?.referralSource,
      ]),

      bo_my_referral_code: Validations.validateTextField(
        props?.data?.referralCode ? props?.data?.referralCode : ""
      ),
      bo_referredBy_code: Validations.validateTextField("Not Added"),
    });
  };

  const handleFileUpload = (data) => {
    updateBusinessDetails({
      ...businessDetailsData,
      businessDocsUpload: Validations.validateTextField(data.fileUrl),
      businessDocsFileName: Validations.validateTextField(data.fileName),
    });
  };

  const handleFieldChange = (e) => {
    const { value } = e.target;
    // console.log(state)
    switch (e.target.name) {
      case "bo_first_name":
        updatePersonalDetails({
          ...personalDetails,
          bo_first_name: Validations.validateTextField(value),
        });
        break;
      case "bo_last_name":
        updatePersonalDetails({
          ...personalDetails,
          bo_last_name: Validations.validateTextField(value),
        });
        break;
      case "bo_email":
        updatePersonalDetails({
          ...personalDetails,
          bo_email: Validations.validateEmail(value),
        });
        break;

      case "bo_referralcode":
        updatePersonalDetails({
          ...personalDetails,
          bo_referralcode: Validations.validateTextField(value),
        });
        break;

      case "bo_phoneNumber":
        updatePersonalDetails({
          ...personalDetails,
          bo_phoneNumber: Validations.validatePhoneNumber(value),
        });
        break;
      case "bo_priorityRanking":
        updatePersonalDetails({
          ...personalDetails,
          bo_priorityRanking: Validations.validateNumericValue(value),
        });
        break;
      case "bo_zipcode":
        updatePersonalDetails({
          ...personalDetails,
          bo_zipcode: Validations.validateTextField(value),
        });
        break;
      case "bo_Background_verification_status":
        updatePersonalDetails({
          ...personalDetails,
          bo_Background_verification_status:
            Validations.validateTextField(value),
        });
        break;
      case "bo_license_verification_status":
        updatePersonalDetails({
          ...personalDetails,
          bo_license_verification_status: Validations.validateTextField(value),
        });
        break;
      case "bo_Status":
        updatePersonalDetails({
          ...personalDetails,
          bo_Status: Validations.validateTextField(value),
        });
        break;
      // case "bo_referral_source":
      //   updatePersonalDetails({
      //     ...personalDetails,
      //     bo_referral_source: Validations.validateTextField(value),
      //   });
      //   break;
      case "bo_regestrationDate":
        updatePersonalDetails({
          ...personalDetails,
          bo_regestrationDate: value,
        });
        break;
      case "bo_lastActive":
        updatePersonalDetails({
          ...personalDetails,
          bo_lastActive: value,
        });
        break;
      case "bo_avgRating":
        updatePersonalDetails({
          ...personalDetails,
          bo_avgRating: Validations.validateNumericValue(value),
        });
        break;

      default:
        return;
    }
  };
  const handleChangeReferralSource = (val) => {
    console.log("handleChangeReferralSource => ", val);

    updatePersonalDetails({
      ...personalDetails,
      bo_referral_source: Validations.validateMultiSelect([val]),
    });
  };

  const handleFieldChange_businessDetail = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "businessName":
        updateBusinessDetails({
          ...businessDetailsData,
          businessName: Validations.validateTextField(value),
        });
        break;
      case "businessDescription":
        updateBusinessDetails({
          ...businessDetailsData,
          businessDescription: Validations.validateTextField(value),
        });
        break;
      case "noOfEmployee":
        updateBusinessDetails({
          ...businessDetailsData,
          noOfEmployee: Validations.validateNumericValue(value),
        });
        break;
      case "estabilsIn":
        updateBusinessDetails({
          ...businessDetailsData,
          estabilsIn: value,
        });
        break;

      default:
        return;
    }
  };

  const handleServiceDetails = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "otherService":
        updateServicesDetailsData({
          ...servicesDetailsData,
          otherService: Validations.validateTextField(value),
        });
        break;
      case "otherServiceDescp":
        updateServicesDetailsData({
          ...servicesDetailsData,
          otherServiceDescp: Validations.validateTextField(value),
        });
        break;

      default:
        return;
    }
  };
  const handleSelectChange = (value, name) => {
    switch (name) {
      case "categorySelect":
        updateServicesDetailsData({
          ...servicesDetailsData,
          categorySelect: Validations.validateMultiSelect(value),
        });
        break;
      case "servicesSelect":
        // console.log("servicesSelect => ", value)

        updateServicesDetailsData({
          ...servicesDetailsData,
          servicesSelect: Validations.validateMultiSelect(value),
        });
        break;
      case "preferredLang":
        updateBusinessDetails({
          ...businessDetailsData,
          preferredLang: Validations.validateMultiSelect(value),
        });
        break;
      case "bo_avgRating":
        updatePersonalDetails({
          ...personalDetails,
          bo_avgRating: Validations.validateNumericValue(value),
        });
        break;
      case "bo_countryCode":
        updatePersonalDetails({
          ...personalDetails,
          bo_countryCode: Validations.validateTextField(value),
        });
        break;

      case "citySelect":
        updateServiceLocationData({
          ...serviceLocationData,
          city: Validations.validateMultiSelect(value),
        });
        break;
      case "service_choice":
        updateServiceLocationData({
          ...serviceLocationData,
          service_choice: Validations.validateMultiSelect(value),
        });
        break;
      case "licenseStatus":
        updateStatus({
          ...status,
          licenseVerificationStatus: value,
        });
        break;
      case "backgroundStatus":
        updateStatus({
          ...status,
          backgroundVerificationStatus: value,
        });
        break;
      default:
        return;
    }
  };
  const handleBackCheck_changes = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "SSN":
        updateBackgroundChack({
          ...backgroundCheck,
          SSN: Validations.validateTextField(value),
        });
        break;
      case "driversLice_no":
        updateBackgroundChack({
          ...backgroundCheck,
          driversLice_no: Validations.validateTextField(value),
        });
        break;
      case "facebookUrl":
        updateBackgroundChack({
          ...backgroundCheck,
          facebookUrl: Validations.validateTextField(value),
        });
        break;
      case "licenceType":
        updateBackgroundChack({
          ...backgroundCheck,
          licenceType: Validations.validateTextField(value),
        });
        break;
      case "licenseFrontImg":
        updateBackgroundChack({
          ...backgroundCheck,
          licenseFrontImg: Validations.validateTextField(value),
        });
        break;
      case "licenseBackImg":
        updateBackgroundChack({
          ...backgroundCheck,
          licenseBackImg: Validations.validateTextField(value),
        });
        break;
      case "areaRadius":
        updateServiceLocationData({
          ...serviceLocationData,
          radius: Validations.validateNumericValue(value),
        });
        break;

      default:
        return;
    }
  };
  const handlelicenseFrontImg = (val) => {
    updateBackgroundChack({
      ...backgroundCheck,
      licenseFrontImg: Validations.validateTextField(val),
    });
  };
  const handlelicenseBackImg = (val) => {
    updateBackgroundChack({
      ...backgroundCheck,
      licenseBackImg: Validations.validateTextField(val),
    });
  };
  const handleProfCred = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "licence_issue_state":
        updateProfessionalCreads({
          ...professionalCreds,
          licence_issue_state: Validations.validateTextField(value),
        });
        break;
      case "licenceType":
        updateProfessionalCreads({
          ...professionalCreds,
          licenceType: Validations.validateTextField(value),
        });
        break;
      case "licenceNo":
        updateProfessionalCreads({
          ...professionalCreds,
          licenceNo: Validations.validateTextField(value),
        });
        break;
      case "InstagramUrl":
        updateProfessionalCreads({
          ...professionalCreds,
          InstagramUrl: Validations.validateTextField(value),
        });
        break;
      case "facebookUrl":
        updateProfessionalCreads({
          ...professionalCreds,
          facebookUrl: Validations.validateTextField(value),
        });
        break;
      default:
        return;
    }
  };

  console.log("handleCheckboxChange weekDays", weekDays);

  const [phoneCountryCode, changePhoneCountryCode] = useState(
    Validations.validateTextField(undefined)
  );

  useEffect(() => {
    if (props.isVisible) {
      if (props.modalToShow === "EDIT" || props.modalToShow === "VIEW") {
        setForm();
      } else {
        FormReset();
      }
    }
  }, [props.isVisible, props.data]);

  const handleCountryCodeChange = (value) => {
    changePhoneCountryCode(
      Validations.validateTextField(value, "Country Code")
    );
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const closeModal = () => {
    onCloseIconClick();
  };

  const formAction = () => {
    changeIsPristine(false);
    if (!isFormValid()) {
      toastr.warning(intl.formatMessage({ id: "app.message.formCheck" }));

      return;
    }

    if (props?.modalToShow === "ADD") {
    } else if (props?.modalToShow === "EDIT") {
    }
  };
  const isFormValid = () => {
    let isValid = true;
    if (props.modalToShow === "ADD") {
      isValid =
        isValid &&
        personalDetails?.bo_first_name?.isValid &&
        personalDetails?.bo_last_name?.isValid &&
        personalDetails?.bo_email?.isValid &&
        personalDetails?.bo_phoneNumber?.isValid &&
        personalDetails?.bo_zipcode?.isValid &&
        personalDetails?.bo_countryCode?.isValid &&
        servicesDetailsData?.categorySelect?.isValid &&
        servicesDetailsData?.servicesSelect?.isValid &&
        backgroundCheck?.SSN?.isValid &&
        backgroundCheck?.driversLice_no?.isValid &&
        backgroundCheck?.licenseFrontImg?.isValid &&
        backgroundCheck?.licenseBackImg?.isValid &&
        (weekDays?.sunday ||
          weekDays?.monday ||
          weekDays?.tuesday ||
          weekDays?.wednesday ||
          weekDays?.thursday ||
          weekDays?.friday ||
          weekDays?.saturday);
    } else if (props.modalToShow === "EDIT") {
      isValid =
        isValid &&
        personalDetails?.bo_first_name?.isValid &&
        personalDetails?.bo_last_name?.isValid &&
        personalDetails?.bo_email?.isValid &&
        personalDetails?.bo_phoneNumber?.isValid &&
        personalDetails?.bo_priorityRanking?.isValid &&
        personalDetails?.bo_zipcode?.isValid &&
        personalDetails?.bo_avgRating?.isValid &&
        personalDetails?.bo_countryCode?.isValid &&
        servicesDetailsData?.categorySelect?.isValid &&
        servicesDetailsData?.servicesSelect?.isValid &&
        (weekDays?.sunday ||
          weekDays?.monday ||
          weekDays?.tuesday ||
          weekDays?.wednesday ||
          weekDays?.thursday ||
          weekDays?.friday ||
          weekDays?.saturday);
    }

    return isValid;
  };

  const ratingFilter = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];

  const handleRegestrationDate = (date) => {
    updatePersonalDetails({
      ...personalDetails,
      bo_regestrationDate: date,
    });
    // updateRegestrationDate(date);
  };
  const handleLaseActiveDate = (date) => {
    updatePersonalDetails({
      ...personalDetails,
      bo_lastActive: date,
    });
  };
  const handlbeEstableDate = (date) => {
    updateBusinessDetails({
      ...businessDetailsData,
      establishIn: date,
    });
  };

  const handleCheckboxChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    console.log("handleCheckboxChange => ", name, "  ", checked);
    switch (name) {
      case "sunday":
        // setSundayDisabled(!checked);
        updateWeekDays({
          ...weekDays,
          sunday: checked,
        });
        break;
      case "monday":
        updateWeekDays({
          ...weekDays,
          monday: checked,
        });
        break;
      case "tuesday":
        updateWeekDays({
          ...weekDays,
          tuesday: checked,
        });
        break;
      case "wednesday":
        updateWeekDays({
          ...weekDays,
          wednesday: checked,
        });
        break;
      case "thursday":
        updateWeekDays({
          ...weekDays,
          thursday: checked,
        });
        break;
      case "friday":
        updateWeekDays({
          ...weekDays,
          friday: checked,
        });
        break;
      case "saturday":
        updateWeekDays({
          ...weekDays,
          saturday: checked,
        });
        break;
      default:
        break;
    }
  };

  const handleStartTime = (e, day) => {
    console.log("handleStartTime day => ", e);
    let timeArr = moment(e?._d).format("HH:mm").split(":");
    console.log("handleStartTime =>", timeArr);

    switch (day) {
      case "sunday":
        updateWorkingHour({
          ...workingHour,
          sunday: {
            ...workingHour?.sunday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });
        updateTime(e);

        break;
      case "monday":
        updateWorkingHour({
          ...workingHour,
          monday: {
            ...workingHour?.monday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "tuesday":
        updateWorkingHour({
          ...workingHour,
          tuesday: {
            ...workingHour?.tuesday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "wednesday":
        updateWorkingHour({
          ...workingHour,
          wednesday: {
            ...workingHour?.wednesday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "thursday":
        updateWorkingHour({
          ...workingHour,
          thursday: {
            ...workingHour?.thursday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "friday":
        updateWorkingHour({
          ...workingHour,
          friday: {
            ...workingHour?.friday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "saturday":
        updateWorkingHour({
          ...workingHour,
          saturday: {
            ...workingHour?.saturday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      default:
        break;
    }
  };
  const handleEndTime = (e, day) => {
    let timeArr = moment(e?._d).format("HH:mm").split(":");

    // {
    //   start: {
    //     hours: 0,
    //      minutes: 0,
    //   },
    //   end: {
    //     hours: 0,
    //      minutes: 0,
    //   },
    // },

    switch (day) {
      case "sunday":
        updateWorkingHour({
          ...workingHour,
          sunday: {
            ...workingHour?.sunday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "monday":
        updateWorkingHour({
          ...workingHour,
          monday: {
            ...workingHour?.monday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "tuesday":
        updateWorkingHour({
          ...workingHour,
          tuesday: {
            ...workingHour?.tuesday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "wednesday":
        updateWorkingHour({
          ...workingHour,
          wednesday: {
            ...workingHour?.wednesday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "thursday":
        updateWorkingHour({
          ...workingHour,
          thursday: {
            ...workingHour?.thursday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "friday":
        updateWorkingHour({
          ...workingHour,
          friday: {
            ...workingHour?.friday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "saturday":
        updateWorkingHour({
          ...workingHour,
          saturday: {
            ...workingHour?.saturday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      default:
        break;
    }
  };

  const handleImageChange_profileImg = (image) => {
    updatePersonalDetails({
      ...personalDetails,
      bo_profileImageURL: Validations.validateTextField(image),
    });
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}

      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD"
          ? "Add Service Provider"
          : props.modalToShow === "VIEW"
          ? "View Service Provider"
          : "Edit Service Provider"
      }
      action={
        props.modalToShow !== "VIEW" ? (
          <>
            {" "}
            <Button type="default" shape={undefined} onClick={closeModal}>
              <FormattedMessage id="app.field.cancel" />
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              shape={undefined}
              onClick={() => formAction()}
            >
              <FormattedMessage id="app.field.submit" />
            </Button>
          </>
        ) : (
          ""
        )
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div>
          <div style={{ marginBottom: "1rem" }}>
            <CustomImage src={personalDetails?.bo_profileImageURL.value} />
            <UploadButton
              parentRef={imageContainerRef}
              resultType="url"
              accept="image/*"
              id="955487897hiu4n225ri"
              text="Upload SP Profile img (Optional)"
              onChange={handleImageChange_profileImg}
            />
          </div>

          <div
            style={{
              marginBottom: "1rem",
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="Personal Details" />
          </div>
          {/* {
            Personal Details:
          First Name
          Last Name
          E-Mail
          Phone number
          Zip code
          BO profile Picture
          Wallet Amount
          Avg Rating
          All 3 Statuses
          Priority Ranking

          Registration date

          Last Active

          Referral Source
          } */}
          <div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label for="name" value="First Name" />
                {/* <Label for="name" value="app.field.name" /> */}
                <Input
                  disabled={props.modalToShow === "VIEW"}
                  name="bo_first_name"
                  onChange={handleFieldChange}
                  value={personalDetails?.bo_first_name?.value}
                  placeholder="First Name"
                />
                {!isPristine && !personalDetails?.bo_first_name?.isValid && (
                  <ErrorMessage text={personalDetails?.bo_first_name?.errMsg} />
                )}
              </div>
              <div className={styles.formItem}>
                <Label value="Last Name" />
                <Input
                  disabled={props.modalToShow === "VIEW"}
                  name="bo_last_name"
                  onChange={handleFieldChange}
                  value={personalDetails?.bo_last_name?.value}
                  placeholder="Last Name"
                />
                {!isPristine && !personalDetails?.bo_last_name?.isValid && (
                  <ErrorMessage text={personalDetails?.bo_last_name?.errMsg} />
                )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label for="email" value="app.field.email" />
                <Input
                  disabled={props.modalToShow !== "ADD"}
                  name="bo_email"
                  onChange={handleFieldChange}
                  value={personalDetails?.bo_email?.value}
                  placeholder={props.intl.formatMessage({
                    id: "app.placeholder.email",
                  })}
                />
                {!isPristine && !personalDetails?.bo_email?.isValid && (
                  <ErrorMessage text={personalDetails?.bo_email?.errMsg} />
                )}
              </div>
              {props.modalToShow === "ADD" && (
                <div className={styles.formItem}>
                  <Label value="Referral Code" Optional />
                  <Input
                    disabled={props.modalToShow === "VIEW"}
                    name="bo_referralcode"
                    onChange={handleFieldChange}
                    value={personalDetails?.bo_referralcode?.value}
                    placeholder="Referral Code"
                  />
                </div>
              )}
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="app.field.countryCode" />
                <Select
                  disabled={props.modalToShow !== "ADD"}
                  showSearch
                  // style={{ marginRight: "1rem" }}
                  placeholder={props.intl.formatMessage({
                    id: "app.placeholder.countryCode",
                  })}
                  onChange={(e) => handleSelectChange(e, "bo_countryCode")}
                  value={personalDetails?.bo_countryCode.value}
                  optionFilterProp="children"
                  name="bo_countryCode"
                  filterOption={filterOption}
                >
                  {countryCodes.map((item, index) => (
                    <Option
                      key={index}
                      value={item.dial_code}
                    >{`${item.dial_code}(${item.name})`}</Option>
                  ))}
                </Select>
                {!isPristine && !personalDetails?.bo_countryCode.value && (
                  <ErrorMessage selectOption />
                )}
              </div>
              <div className={styles.formItem}>
                <Label for="phoneNumber" value="app.field.phoneNumber" />

                <Input
                  disabled={props.modalToShow !== "ADD"}
                  name="bo_phoneNumber"
                  onChange={handleFieldChange}
                  value={personalDetails?.bo_phoneNumber?.value}
                  placeholder={props.intl.formatMessage({
                    id: "app.placeholder.phoneNumber",
                  })}
                />
                {!isPristine && !personalDetails?.bo_phoneNumber?.isValid && (
                  <ErrorMessage
                    text={personalDetails?.bo_phoneNumber?.errMsg}
                  />
                )}
              </div>
            </div>
            {props.modalToShow === "EDIT" && (
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="Avg Rating" vertical></Label>
                  <Select
                    disabled={props.modalToShow === "VIEW"}
                    showSearch
                    placeholder="Rating"
                    name="bo_avgRating"
                    onChange={(e) => handleSelectChange(e, "bo_avgRating")}
                    value={personalDetails?.bo_avgRating?.value}
                    // vlaue={reviewData?.rating}
                    // onChange={handleRatingSelect}
                  >
                    {ratingFilter.map((item) => (
                      <Option key={item.id} value={item.id}>
                        <Rate
                          style={{ color: "#ED432A" }}
                          disabled
                          value={item.id}
                        />
                      </Option>
                    ))}
                  </Select>
                  {!isPristine && !personalDetails?.bo_avgRating?.isValid && (
                    <ErrorMessage selectOption />
                  )}
                </div>
                <div className={styles.formItem}>
                  <Label value="Priority Ranking" />

                  <Input
                    disabled={props.modalToShow === "VIEW"}
                    name="bo_priorityRanking"
                    onChange={handleFieldChange}
                    value={personalDetails?.bo_priorityRanking?.value}
                    placeholder="Priority Ranking"
                  />
                  {!isPristine &&
                    !personalDetails?.bo_priorityRanking?.isValid && (
                      <ErrorMessage
                        text={personalDetails?.bo_priorityRanking?.errMsg}
                      />
                    )}
                </div>
              </div>
            )}

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Zip code" />
                <Input
                  disabled={props.modalToShow === "VIEW"}
                  name="bo_zipcode"
                  onChange={handleFieldChange}
                  value={personalDetails?.bo_zipcode?.value}
                  placeholder="Zip code"
                />
                {!isPristine && !personalDetails?.bo_zipcode?.isValid && (
                  <ErrorMessage text={personalDetails?.bo_zipcode?.errMsg} />
                )}
              </div>

              {/* <div className={styles.formItem}>
                <Label value="Referral Source" optional />
                <Input
                  disabled={props.modalToShow === "VIEW"}
                  name="bo_referral_source"
                  onChange={handleFieldChange}
                  placeholder="Referral Source"
                  value={personalDetails?.bo_referral_source?.value}
                />
                {!isPristine &&
                  !personalDetails?.bo_referral_source?.isValid && (
                    <ErrorMessage
                      text={personalDetails?.bo_referral_source?.errMsg}
                    />
                  )}
              </div> */}

              {
                <div className={styles.formItem}>
                  <Label value="Referral Source " />

                  <Select
                    showSearch
                    // style={{ marginRight: "1rem" }}
                    placeholder="Select Seferral Source"
                    onChange={handleChangeReferralSource}
                    value={personalDetails?.bo_referral_source?.value}
                    optionFilterProp="children"
                    disabled={
                      props.modalToShow === "VIEW" ||
                      props.modalToShow === "EDIT"
                    }
                    // filterOption={filterOption}
                  >
                    {referral_Source.map((item) => (
                      <Option key={item?.id} value={item.text}>
                        {item?.text}
                      </Option>
                    ))}
                  </Select>
                  {!isPristine &&
                    !personalDetails?.bo_referral_source?.isValid && (
                      <ErrorMessage
                        text={personalDetails?.bo_referral_source?.errMsg}
                      />
                    )}
                </div>
              }
            </div>

            {props?.modalToShow !== "ADD" ? (
              <>
                <div className={styles.formGroup}>
                  <div className={styles.formItem}>
                    <Label value=" License verification Status" />

                    <Select
                      disabled={props.modalToShow === "VIEW"}
                      showSearch
                      // style={{ marginRight: "1rem" }}
                      placeholder="License verification Status"
                      onChange={(e) => handleSelectChange(e, "licenseStatus")}
                      value={status?.licenseVerificationStatus}
                      optionFilterProp="children"
                      name="licenseStatus"
                      filterOption={filterOption}
                    >
                      {verifyStatus.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item?.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className={styles.formItem}>
                    <Label value="Background verification Status" />
                    <Select
                      disabled={props.modalToShow === "VIEW"}
                      showSearch
                      // style={{ marginRight: "1rem" }}
                      placeholder="Background verification Status"
                      onChange={(e) =>
                        handleSelectChange(e, "backgroundStatus")
                      }
                      value={status?.backgroundVerificationStatus}
                      optionFilterProp="children"
                      name="backgroundStatus"
                      filterOption={filterOption}
                    >
                      {verifyStatus.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item?.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={styles.formGroup}>
                  {props?.modalToShow === "VIEW" ? (
                    <>
                      <div className={styles.formItem}>
                        <Label value="Registration date" />
                        <DatePicker
                          disabled={props.modalToShow === "VIEW"}
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          placeholderText="Registration date"
                          className={styles.datePicker}
                          dateFormat="MM-dd-yyyy hh:mm"
                          // dateFormat="MM-dd-yyyy hh:mm"
                          selected={personalDetails?.bo_regestrationDate}
                          onChange={handleRegestrationDate}
                          minDate={new Date()}
                        />
                        {!isPristine &&
                          !personalDetails?.bo_regestrationDate && (
                            <ErrorMessage text={errorMessage} />
                          )}
                      </div>
                      <div className={styles.formItem}>
                        <Label value="Last Active" />
                        <DatePicker
                          disabled={props.modalToShow === "VIEW"}
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          placeholderText="Last Active"
                          className={styles.datePicker}
                          dateFormat="MM-dd-yyyy hh:mm"
                          selected={personalDetails?.bo_lastActive}
                          onChange={handleLaseActiveDate}
                          minDate={new Date()}
                        />
                        {!isPristine && !personalDetails?.bo_lastActive && (
                          <ErrorMessage text={errorMessage} />
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {props?.modalToShow === "VIEW" ? (
                  <div className={styles.formGroup}>
                    <div className={styles.formItem}>
                      <Label value="Referral Code" />
                      <Input
                        disabled={props.modalToShow === "VIEW"}
                        name="bo_my_referral_code"
                        onChange={handleFieldChange}
                        placeholder="Referral Code"
                        value={personalDetails?.bo_my_referral_code?.value}
                      />
                    </div>
                    <div className={styles.formItem}>
                      <Label value="Referred By" />
                      <Input
                        disabled={props.modalToShow === "VIEW"}
                        name="bo_referredBy_code"
                        onChange={handleFieldChange}
                        placeholder="Referred By"
                        value={personalDetails?.bo_referredBy_code?.value}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* ============================== Completed ^^^ =============== */}
          </div>
        </div>

        <div>
          <div
            style={{
              marginBottom: "1rem",
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="Services" />
          </div>

          {/* Services:
Category-> drop down (multi select)
Services -> drop down (multi select)
          Any other services (optional)
          Other service Description (optional) */}

          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="Category" />
              <Select
                showSearch
                mode="multiple"
                className={classNames({
                  [styles.multySelect_disabled]: props.modalToShow === "VIEW",
                })}
                placeholder="Category"
                maxTagCount={2}
                name="categorySelect"
                onChange={(e) => handleSelectChange(e, "categorySelect")}
                value={servicesDetailsData?.categorySelect?.value}
              >
                {getCategoriesData.map((item) => (
                  <Option
                    key={item.id}
                    disabled={props.modalToShow === "VIEW"}
                    value={item.id}
                  >
                    {item?.title}
                  </Option>
                ))}
              </Select>
              {!isPristine && !servicesDetailsData?.categorySelect?.isValid && (
                <ErrorMessage selectOption />
              )}
            </div>
            <div className={styles.formItem}>
              <Label value="Services" />
              <Select
                disabled={props.modalToShow === "VIEW"}
                showSearch
                mode="multiple"
                placeholder="Services"
                maxTagCount={2}
                className={classNames({
                  [styles.multySelect_disabled]: props.modalToShow === "VIEW",
                })}
                name="servicesSelect"
                onChange={(e) => handleSelectChange(e, "servicesSelect")}
                value={servicesDetailsData?.servicesSelect?.value}
              >
                {getServiceData.map((item) => (
                  <Option
                    key={item.id}
                    disabled={props.modalToShow === "VIEW"}
                    value={item.id}
                  >
                    {item?.title}
                  </Option>
                ))}
              </Select>
              {!isPristine && !servicesDetailsData?.servicesSelect?.isValid && (
                <ErrorMessage selectOption />
              )}
            </div>
          </div>

          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="Any other services" optional />
              <Input
                disabled={props.modalToShow === "VIEW"}
                placeholder="Any other services"
                name="otherService"
                value={servicesDetailsData?.otherService?.value}
                onChange={handleServiceDetails}
              />
            </div>
            <div className={styles.formItem}>
              <Label value="Other service Description" optional />

              <Input.TextArea
                disabled={props.modalToShow === "VIEW"}
                name="otherServiceDescp"
                value={servicesDetailsData?.otherServiceDescp?.value}
                onChange={handleServiceDetails}
                placeholder="Other service Description"
              />
              {/* {!isPristine && servicesDetailsData?.otherServiceDescp?.isValid && <ErrorMessage text={servicesDetailsData?.otherServiceDescp?.errMsg} />} */}
            </div>
          </div>
          {/* =================Completed ^^^^^^^^^^^^^^====================== */}
        </div>
        <div>
          <div
            style={{
              marginBottom: "1rem",
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="Working hours" />
          </div>
          {/* sunday & monday */}
          {!isPristine &&
            !weekDays?.sunday &&
            !weekDays?.monday &&
            !weekDays?.tuesday &&
            !weekDays?.wednesday &&
            !weekDays?.thursday &&
            !weekDays?.friday &&
            !weekDays?.saturday && <ErrorMessage workingDays />}

          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Checkbox
                  disabled={props.modalToShow === "VIEW"}
                  name="sunday"
                  checked={weekDays.sunday}
                  onChange={(e) => handleCheckboxChange(e)}
                >
                  Sunday
                </Checkbox>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="Start Time" />

                  <TimePicker
                    format={format}
                    // defaultValue={dayjs('12:08:23', 'HH:mm:ss')}
                    name="sunday"
                    value={workingHour?.sunday?.start?.startTime}
                    onChange={(e) => handleStartTime(e, "sunday")}
                    disabled={!weekDays?.sunday || props.modalToShow === "VIEW"}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="End Time" />

                  <TimePicker
                    format={format}
                    name="sunday"
                    value={workingHour?.sunday?.end?.endTime}
                    onChange={(e) => handleEndTime(e, "sunday")}
                    disabled={!weekDays?.sunday || props.modalToShow === "VIEW"}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formItem}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Checkbox
                  disabled={props.modalToShow === "VIEW"}
                  name="monday"
                  checked={weekDays?.monday}
                  onChange={(e) => handleCheckboxChange(e)}
                >
                  Monday
                </Checkbox>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="Start Time" />

                  <TimePicker
                    format={format}
                    value={workingHour?.monday?.start?.startTime}
                    name="monday"
                    onChange={(e) => handleStartTime(e, "monday")}
                    disabled={!weekDays?.monday || props.modalToShow === "VIEW"}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="End Time" />

                  <TimePicker
                    format={format}
                    name="monday"
                    value={workingHour?.monday?.end?.endTime}
                    onChange={(e) => handleEndTime(e, "monday")}
                    disabled={!weekDays?.monday || props.modalToShow === "VIEW"}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* tuesday & wednesday */}
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Checkbox
                  disabled={props.modalToShow === "VIEW"}
                  name="tuesday"
                  checked={weekDays?.tuesday}
                  onChange={(e) => handleCheckboxChange(e)}
                >
                  Tuesday
                </Checkbox>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="Start Time" />

                  <TimePicker
                    format={format}
                    name="tuesday"
                    value={workingHour?.tuesday?.start?.startTime}
                    onChange={(e) => handleStartTime(e, "tuesday")}
                    disabled={
                      !weekDays?.tuesday || props.modalToShow === "VIEW"
                    }
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="End Time" />

                  <TimePicker
                    format={format}
                    name="tuesday"
                    onChange={(e) => handleEndTime(e, "tuesday")}
                    disabled={
                      !weekDays?.tuesday || props.modalToShow === "VIEW"
                    }
                    value={workingHour?.tuesday?.end?.endTime}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formItem}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Checkbox
                  disabled={props.modalToShow === "VIEW"}
                  name="wednesday"
                  value={weekDays?.wednesday}
                  onChange={(e) => handleCheckboxChange(e)}
                >
                  wednesday
                </Checkbox>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="Start Time" />

                  <TimePicker
                    format={format}
                    name="wednesday"
                    value={workingHour?.wednesday?.start?.startTime}
                    onChange={(e) => handleStartTime(e, "wednesday")}
                    disabled={
                      !weekDays?.wednesday || props.modalToShow === "VIEW"
                    }
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="End Time" />

                  <TimePicker
                    format={format}
                    name="wednesday"
                    onChange={(e) => handleEndTime(e, "wednesday")}
                    disabled={
                      !weekDays?.wednesday || props.modalToShow === "VIEW"
                    }
                    value={workingHour?.wednesday?.end?.endTime}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Thusday & friday */}
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Checkbox
                  disabled={props.modalToShow === "VIEW"}
                  name="thursday"
                  checked={weekDays?.thursday}
                  onChange={(e) => handleCheckboxChange(e)}
                >
                  Thursday
                </Checkbox>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="Start Time" />

                  <TimePicker
                    format={format}
                    name="thursday"
                    value={workingHour?.thursday?.start?.startTime}
                    onChange={(e) => handleStartTime(e, "thursday")}
                    disabled={
                      !weekDays?.thursday || props.modalToShow === "VIEW"
                    }
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="End Time" />

                  <TimePicker
                    format={format}
                    name="thursday"
                    onChange={(e) => handleEndTime(e, "thursday")}
                    disabled={
                      !weekDays?.thursday || props.modalToShow === "VIEW"
                    }
                    value={workingHour?.thursday?.end?.endTime}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formItem}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Checkbox
                  disabled={props.modalToShow === "VIEW"}
                  name="friday"
                  checked={weekDays?.friday}
                  onChange={(e) => handleCheckboxChange(e)}
                >
                  Friday
                </Checkbox>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="Start Time" />

                  <TimePicker
                    format={format}
                    name="friday"
                    value={workingHour?.friday?.start?.startTime}
                    onChange={(e) => handleStartTime(e, "friday")}
                    disabled={!weekDays?.friday || props.modalToShow === "VIEW"}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="End Time" />

                  <TimePicker
                    format={format}
                    name="friday"
                    onChange={(e) => handleEndTime(e, "friday")}
                    disabled={!weekDays?.friday || props.modalToShow === "VIEW"}
                    value={workingHour?.friday?.end?.endTime}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Checkbox
                  disabled={props.modalToShow === "VIEW"}
                  name="saturday"
                  checked={weekDays?.saturday}
                  onChange={(e) => handleCheckboxChange(e)}
                >
                  Saturday
                </Checkbox>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="Start Time" />

                  <TimePicker
                    format={format}
                    name="saturday"
                    value={workingHour?.saturday?.start?.startTime}
                    onChange={(e) => handleStartTime(e, "saturday")}
                    disabled={
                      !weekDays?.saturday || props.modalToShow === "VIEW"
                    }
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label value="End Time" />

                  <TimePicker
                    format={format}
                    name="saturday"
                    onChange={(e) => handleEndTime(e, "saturday")}
                    value={workingHour?.saturday?.end?.endTime}
                    disabled={
                      !weekDays?.saturday || props.modalToShow === "VIEW"
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.formItem}></div>
          </div>
        </div>

        {props?.modalToShow === "ADD" ? (
          <div>
            <div
              style={{
                marginBottom: "1rem",
                fontSize: "1.1rem",
                fontWeight: "bold",
              }}
            >
              <FormattedMessage id="Background check" />
            </div>

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="SSN" />
                <Input
                  disabled={props.modalToShow === "VIEW"}
                  name="SSN"
                  onChange={handleBackCheck_changes}
                  placeholder="Enter SSN"
                  value={backgroundCheck?.SSN?.value}
                />
                {!isPristine && !backgroundCheck?.SSN?.isValid && (
                  <ErrorMessage text={backgroundCheck?.SSN?.errMsg} />
                )}
              </div>
              <div className={styles.formItem}>
                <Label value="Drivers license number" />
                <Input
                  disabled={props.modalToShow === "VIEW"}
                  name="driversLice_no"
                  onChange={handleBackCheck_changes}
                  placeholder="Drivers license number"
                  value={backgroundCheck?.driversLice_no?.value}
                />
                {!isPristine && !backgroundCheck?.driversLice_no?.isValid && (
                  <ErrorMessage
                    text={backgroundCheck?.driversLice_no?.errMsg}
                  />
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <div>
                  <CustomImage src={backgroundCheck?.licenseFrontImg.value} />
                  <UploadButton
                    parentRef={imageContainerRef}
                    resultType="url"
                    accept="image/*"
                    id="913fjhiasau4ng2vri"
                    text="Upload Drivers license front image"
                    onChange={handlelicenseFrontImg}
                  />
                </div>
                {!isPristine && !backgroundCheck?.licenseFrontImg?.isValid && (
                  <ErrorMessage text={"Please upload front Img"} />
                )}
              </div>
              <div className={styles.formItem}>
                <div>
                  <CustomImage src={backgroundCheck?.licenseBackImg.value} />
                  <UploadButton
                    parentRef={imageContainerRef}
                    resultType="url"
                    accept="image/*"
                    id="913f4545u4n45454ri"
                    text="Upload Drivers license back image"
                    onChange={handlelicenseBackImg}
                  />
                </div>
                {!isPristine && !backgroundCheck?.licenseBackImg?.isValid && (
                  <ErrorMessage text={"Please upload back Img"} />
                )}
              </div>
            </div>
            {/* <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Facebook" optional />
                <Input
                  disabled={props.modalToShow === "VIEW"}
                  name="facebookUrl"
                  onChange={handleBackCheck_changes}
                  placeholder="Facebook"
                  value={backgroundCheck?.facebookUrl?.value}
                />
              </div>
              <div className={styles.formItem}>
                <Label value="License Type" optional />
                <Input
                  disabled={props.modalToShow === "VIEW"}
                  name="licenceType"
                  onChange={handleBackCheck_changes}
                  value={backgroundCheck?.licenceType?.value}
                  placeholder="License Type"
                />
              </div>
            </div> */}
          </div>
        ) : (
          ""
        )}

        <div>
          <div
            style={{
              marginBottom: "1rem",
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="Professional credentials (Optional)" />
          </div>

          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="License issued state" optional />
              <Input
                disabled={props.modalToShow === "VIEW"}
                name="licence_issue_state"
                onChange={handleProfCred}
                placeholder="License issued state"
                value={professionalCreds?.licence_issue_state?.value}
              />
            </div>
            <div className={styles.formItem}>
              <Label value="Instagram" optional />
              <Input
                disabled={props.modalToShow === "VIEW"}
                name="InstagramUrl"
                onChange={handleProfCred}
                placeholder="Instagram"
                value={professionalCreds?.InstagramUrl?.value}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="Licence Type" optional />
              <Input
                disabled={props.modalToShow === "VIEW"}
                name="licenceType"
                onChange={handleProfCred}
                placeholder="License Type"
                value={professionalCreds?.licenceType?.value}
              />
            </div>
            <div className={styles.formItem}>
              <Label value="License Number" optional />
              <Input
                disabled={props.modalToShow === "VIEW"}
                name="licenceNo"
                onChange={handleProfCred}
                placeholder="License Number"
                value={professionalCreds?.licenceNo?.value}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <Label value="Facebook" optional />
              <Input
                disabled={props.modalToShow === "VIEW"}
                name="facebookUrl"
                onChange={handleProfCred}
                placeholder="Facebook"
                value={professionalCreds?.facebookUrl?.value}
              />
            </div>
            <div className={styles.formItem}></div>
          </div>
        </div>

        {true ? (
          <div>
            <div
              style={{
                marginBottom: "1rem",
                fontSize: "1.1rem",
                fontWeight: "bold",
              }}
            >
              <FormattedMessage id="Choose where you work" />
            </div>

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Choice of services" />
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select Choice of services"
                  maxTagCount={2}
                  maxTagTextLength={8}
                  name="service_choice"
                  onChange={(e) => handleSelectChange(e, "service_choice")}
                  value={serviceLocationData?.service_choice?.value}
                  className={classNames({
                    [styles.multySelect_disabled]: props.modalToShow === "VIEW",
                  })}
                >
                  {serviceChoice.map((item) => (
                    <Option
                      key={item.id}
                      disabled={props.modalToShow === "VIEW"}
                      value={item.id}
                    >
                      {item?.text}
                    </Option>
                  ))}
                </Select>
                {!isPristine &&
                  !serviceLocationData?.service_choice?.isValid && (
                    <ErrorMessage selectOption />
                  )}
              </div>
              <div className={styles.formItem}>
                <Label value="Radius for Service Area" />
                <Input
                  disabled={props.modalToShow === "VIEW"}
                  name="areaRadius"
                  addonAfter="Miles"
                  onChange={handleBackCheck_changes}
                  placeholder="Radius for Service Area"
                  value={serviceLocationData?.radius?.value}
                />
                {!isPristine && !serviceLocationData?.radius?.isValid && (
                  <ErrorMessage text={serviceLocationData?.radius?.errMsg} />
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="City" />
                <Select
                  showSearch
                  mode="multiple"
                  maxTagCount={2}
                  maxTagTextLength={8}
                  placeholder="City"
                  className={classNames({
                    [styles.multySelect_disabled]: props.modalToShow === "VIEW",
                  })}
                  name="citySelect"
                  onChange={(e) => handleSelectChange(e, "citySelect")}
                  value={serviceLocationData?.city?.value}
                >
                  {cityData.map((item) => (
                    <Option
                      key={item.id}
                      disabled={props.modalToShow === "VIEW"}
                      value={item.id}
                    >
                      {item?.name}
                    </Option>
                  ))}
                </Select>
                {!isPristine && !serviceLocationData?.city?.isValid && (
                  <ErrorMessage selectOption />
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Panel>
    </CustomModal>
  );
}

const mapStateToProps = (state) => ({
  enabledPanels: state.user.enterprice.enabledPanels,
  isAddDisabled: state.user.isAddDisabled,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(injectIntl(AddEditUser));
