import React from "react";
import {
  CustomModal,
  Panel,
  Label,
  ErrorMessage,
  CustomImage,
} from "../../components/common";
const View = () => {
  return (
    <CustomModal size="tiny" width={700}>
      <Panel>hello i am user info</Panel>
    </CustomModal>
  );
};

export default View;
