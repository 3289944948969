import axios from "axios";
import userActions from "../../redux/user-info/user-actions";
import store from "../../redux/store";
import { getPanelType, DevStorage } from "../utils/local-storage";
import { toastr } from "react-redux-toastr";


const axiosClient = axios.create({
  // baseURL,
});

axiosClient.interceptors.request.use(function (config) {
  const accessToken = sessionStorage.getItem("Admin_access_token");
  
  // const language = 'en';

  let baseURL = "http://52.26.241.33:3001";
  // if (process.env.REACT_APP_DEV_MODE == "1") {
  //   baseURL = DevStorage.getData().endpoint;
  //   console.log("baseUrl: " , baseURL)
  // } else {
  //   baseURL = process.env.REACT_APP_API_ENDPOINT;
  // }


  baseURL += "/admin";
  // baseURL += "/api/admin";
  // debugger;
  if (accessToken) {
    config.headers["access-token"] = accessToken;
  }
  config.baseURL = baseURL;
  config.headers["content-language"] =  "en";
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    console.log("RESPONSE: ", response.config.url, response.data);
    return response;
  },
  function (error) {
    if (error?.response?.data?.statusCode === 401) {
      store.dispatch(userActions.sessionExpired());
    } else {
      if (error?.response?.data?.message) {
        toastr.error(error.response.data.message);
      }
      // else if(error.pri){}
      else {
        toastr.error("Error", "Something Went Wrong");
      }
    }

    return Promise.reject(error.response);
  }
);

export default axiosClient;
