import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  CustomModal,
  Panel,
  UploadButton,
} from "../../../../components/common";
import axios from "../../../../shared/api/axiose";
import { connect } from "react-redux";
import { checkRtl } from "../../../../shared/utils/rtl";
import { Input, Button, Select, Divider, Space } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const styles = {
  formModal: "custom-form-modal",
  addOptionButton: {
    color: "#1890ff",
    fontSize: "1.5rem",
    marginBottom: "1rem",
  },
  deleteOptionButton: {
    color: "red",
    fontSize: "1.5rem",
    marginBottom: "1rem",
  },
  optionIcon: {
    fontSize: "1rem",
  },
};

function AddQuestion({
  onCloseIconClick,
  isVisible,
  customerId,
  getCustomersData,
  onQuestionAdded,
}) {
  console.log("addid", customerId);
  const [questions, setQuestions] = useState([
    { question: "", questionType: "", options: [""] },
  ]);

  const closeModale = () => {
    onCloseIconClick();
  };

  const handleAdd = async () => {
    const questionPayload = questions.map((question) => {
      const { questionType, options } = question;
      let answerOption = [];
      if (
        questionType === "single-selection" ||
        questionType === "multiple-selection"
      ) {
        answerOption = options.filter((option) => option !== "");
      }

      const payload = {
        questionText: question.question,
        answerType: questionType,
      };

      if (customerId) {
        payload.customerId = customerId;
      } else {
        payload.questionId = 0;
      }

      if (
        questionType !== "date" &&
        questionType !== "time" &&
        questionType !== "text" &&
        questionType !== "numeric" &&
        questionType !== "boolean"
      ) {
        payload.answerOption = answerOption;
      }

      return payload;
    });

    try {
      const response = await axios.post(
        "/questionnaire/addOrUpdateBulkQuestion",
        { questions: questionPayload }
      );
      if (response.status === 200) {
        closeModale();

        // onQuestionAdded();

        toast.success("Questions added successfully");
      } else {
        closeModale();

        toast.error("Failed to add questions");
      }
    } catch (error) {
      console.error("API Error:", error);

      toast.error("Failed to add questions");
    }
  };

  const handleQuestionChange = (index, e) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = e.target.value;
    setQuestions(updatedQuestions);
  };

  const handleQuestionTypeChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].questionType = value;
    // Reset the options array when changing the question type
    updatedQuestions[index].options = [""];
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, e) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = e.target.value;
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.push("");
    setQuestions(updatedQuestions);
  };

  const handleDeleteOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      { question: "", questionType: "", options: [""] },
    ]);
  };

  const handleDeleteQuestion = (index) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(index, 1);
      return updatedQuestions;
    });
  };

  return (
    <CustomModal
      size="tiny"
      onCloseIconClick={closeModale}
      header="Add Question"
      action={
        <Button type="primary" onClick={handleAdd}>
          <FormattedMessage id="Add" />
        </Button>
      }
      isVisible={isVisible}
    >
      <Panel isModal className={styles.formModal}>
        {questions.map((question, questionIndex) => (
          <div key={questionIndex}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {questionIndex > 0 && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDeleteQuestion(questionIndex)}
                  >
                    Delete
                  </span>
                )}
              </div>
            </div>
            <div className="questions">
              <TextArea
                rows={3}
                style={{ marginBottom: "1.5rem" }}
                placeholder={`Write your question ${questionIndex + 1} here`}
                value={question.question}
                onChange={(e) => handleQuestionChange(questionIndex, e)}
              />
              <div style={{ marginBottom: "0.5rem" }}>
                <label>Question Type</label>
              </div>

              <Select
                placeholder="Question Type"
                onChange={(value) =>
                  handleQuestionTypeChange(questionIndex, value)
                }
                value={question.questionType || undefined}
                style={{ marginBottom: "1rem", width: "36.7rem" }}
              >
                <Option value="multiple-selection"> Multiple selection</Option>
                <Option value="single-selection">single selection</Option>
                <Option value="text">Text</Option>
                <Option value="numeric">Numeric Input</Option>
                <Option value="boolean">Boolean Input</Option>
                <Option value="date">Date Input</Option>
                <Option value="time">Time Input</Option>
              </Select>

              {question.questionType === "multiple-selection" ||
              question.questionType === "single-selection"
                ? question.options.map((option, optionIndex) => (
                    <div key={optionIndex}>
                      <Input
                        style={{ marginBottom: "1rem" }}
                        placeholder={`Option ${optionIndex + 1}`}
                        value={option}
                        onChange={(e) =>
                          handleOptionChange(questionIndex, optionIndex, e)
                        }
                      />
                      {optionIndex > 0 && (
                        <a
                          onClick={() =>
                            handleDeleteOption(questionIndex, optionIndex)
                          }
                          style={styles.deleteOptionButton}
                        >
                          <CloseCircleOutlined />
                        </a>
                      )}
                    </div>
                  ))
                : null}
              {question.questionType === "multiple-selection" ||
              question.questionType === "single-selection" ? (
                <a
                  onClick={() => handleAddOption(questionIndex)}
                  style={styles.addOptionButton}
                >
                  <PlusCircleOutlined />
                </a>
              ) : null}
            </div>
          </div>
        ))}
        <Button onClick={handleAddQuestion}>Add Question</Button>
      </Panel>
    </CustomModal>
  );
}

const mapStateToProps = (state) => ({
  enabledPanels: state.user.enterprice.enabledPanels,
  isAddDisabled: state.user.isAddDisabled,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(AddQuestion);
