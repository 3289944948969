import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import "./forget-password.css";

import axios from "../../../shared/api/axiosConfig";

import { toastr } from "react-redux-toastr";
import { Input } from "antd";
import BusinessLogo_two from "../../../assets/images/BusinessLogo_two.png";
import { useHistory } from "react-router-dom";

function ForgotPasswordPhone(props) {
  const history = useHistory();
  const validatePassword = (value) => {
    let state = {
      value,
      isValid: false,
      error: "",
    };
    if (!value.length) {
      state.error = intl.formatMessage({ id: "fpEmpty" });
      return state;
    }

    if (value.length < 6) {
      state.error = intl.formatMessage({ id: "fpMinLength" });
      return state;
    }

    state.isValid = true;
    return state;
  };

  const validateConfirm = (value, password) => {
    let state = {
      value,
      isValid: false,
      error: "",
    };
    if (value !== password) {
      state.error = intl.formatMessage({ id: "fpNoMatch" });
      return state;
    }
    state.isValid = true;
    return state;
  };

  const [token, setToken] = useState("");
  console.log("resetToken: ", token);

  const intl = useIntl();
  const [loading, toggleLoading] = useState(false);
  const [form, handleFormChange] = useState({
    password: validatePassword(""),
    confirm: validateConfirm("", ""),
    otp: "",
  });
  const [isPristine, togglePristine] = useState(true);
  const updateToken = (token) => {
    setToken(token);
  };

  useEffect(() => {
    // let resetToken = window.location.pathname.split("/")[2];
    // updateToken(resetToken);
  }, []);

  const handlePasswordChange = (e) => {
    handleFormChange({
      confirm: validateConfirm(form.confirm.value, e.target.value),
      password: validatePassword(e.target.value),
    });
  };

  const handleConfirmChange = (e) => {
    handleFormChange({
      password: validatePassword(form.password.value),
      confirm: validateConfirm(e.target.value, form.password.value),
    });
  };

  const handleOTP = (e) => {
    handleFormChange({
      ...form,
      otp: e.target.value,
    });
  };

  return (
    <div className="external-container">
      <Helmet title={`Forgot Password`} />
      <div className="forget-password__wrapper">
        <div className="forget-password__container">
          <div className="forget-password__logo">
            <img
              style={{ maxHeight: "100px" }}
              src={BusinessLogo_two}
              alt="Logo"
            />
          </div>

          <div className="form-control">
            <label htmlFor="otp">OTP</label>

            <Input.Password
              style={{
                padding: "0.5rem",
                border: "0",
                borderBottom: "1px solid grey",
                outline: "none",
              }}
              placeholder={"Enter OTP"}
              name="otp"
              onChange={handleOTP}
              type="password"
              value={form.otp}
            />

            {!isPristine && !form.otp && (
              <div className="error-message">{"OTP can not be empty"}</div>
            )}
          </div>
          <div className="form-control">
            <label htmlFor="password">
              {intl.formatMessage({ id: "fpNew" })}
            </label>

            <Input.Password
              style={{
                padding: "0.5rem",
                border: "0",
                borderBottom: "1px solid grey",
                outline: "none",
              }}
              name="password"
              placeholder={intl.formatMessage({ id: "fpNew" })}
              onChange={handlePasswordChange}
              type="password"
              value={form.password.value}
            />
            {!isPristine && (
              <div className="error-message">{form.password.error}</div>
            )}
          </div>
          <div className="form-control">
            <label htmlFor="password">
              {intl.formatMessage({ id: "fpConfirm" })}
            </label>

            <Input.Password
              style={{
                padding: "0.5rem",
                border: "0",
                borderBottom: "1px solid grey",
                outline: "none",
              }}
              placeholder={intl.formatMessage({ id: "fpNew" })}
              name="confirm"
              onChange={handleConfirmChange}
              type="password"
              value={form.confirm.value}
            />

            {!isPristine && (
              <div className="error-message">{form.confirm.error}</div>
            )}
          </div>
          <Button
            loading={loading}
            type="primary"
            size="large"
            shape={undefined}
            style={{ width: "100%", marginBottom: "1rem" }}
            onClick={"/"}
          >
            {intl.formatMessage({ id: "fpReset" })}
          </Button>
        </div>
      </div>
    </div>
    // </Helmet>
  );
}

const mapStateToProps = (state) => ({
  enterprice: state.user.enterprice,
});

export default connect(mapStateToProps)(ForgotPasswordPhone);
