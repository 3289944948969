import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Select,
  Menu,
  Tooltip,
  Tag,
  Rate,
  TimePicker,
  Checkbox,
  Input,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import { CustomerDetailsTable, CustomerDetailsFormModal } from "./components";
import AddCustomerNotes from "./components/Customer_Notes/AddCustomerNotes";

import { nestedParseJSON } from "../../shared/utils/object-manipulation";
import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  DelayedInput,
  BackButton,
  CustomTag,
} from "../../components/common";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  numberWithCommas,
} from "../../shared/utils/object-manipulation";
import axiosConfig from "../../shared/api/axios_config";
import axios from "../../shared/api/axiose";
// import { CustomerData } from "../../FakeData";
import placeholderImg from "../../assets/images/profile-placeholder.png";
import "./businessOwner_details.scss";
import { Button } from "antd";
import { CustomTable } from "../../components/common";
import {
  dateFormat,
  date_Format,
  ratingFilter,
  referral_Source,
  serviceChoice,
  timeFormat,
  verifyStatus,
} from "../../shared/utils/local-storage";
import AddEditUser from "../Business_Owners/add-edit-user/add-edit-user";
import { greenTick } from "../../shared/utils/icon-storage";
import Label from "../../components/common/label/label";
import Validations from "../../shared/validations/Validations";
import DatePicker from "react-datepicker";
import classNames from "classnames";
const styles = {
  wrapper: "customer-details__wrapper",
  menu: "customer-details__menu",
  pageSize: "customer-details__menu__records-per-page",
  image: "customer-details__image",
  form: "customer-details__form",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formWrapper: "customer-details__form-wrapper",
  editIcon: "customer-details__edit-icon",
  backButton: "customer-details__back-button",
  header: "header",
  datePicker: "datePicker",
  multySelect_disabled: "multySelect_disabled",
};

const { Option } = Select;

function BusinessOwnerDetails(props) {
  const intl = useIntl();
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const [customerFlag, updateCustomerFlag] = useState(true);
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [BO_flag, setBO_flag] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [categoryData, setCategory] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });
  const [personalDetails, updatePersonalDetails] = useState({
    bo_profileImageURL: Validations.validateTextField(""),
    bo_first_name: Validations.validateTextField(),
    bo_last_name: Validations.validateTextField(),
    bo_email: Validations.validateEmail(""),
    bo_walletAmount: Validations.validateNumericValue(""),
    bo_phoneNumber: Validations.validatePhoneNumber(""),
    bo_priorityRanking: Validations.validateNumericValue(""),
    bo_zipcode: Validations.validateTextField(""),
    bo_referredBy_code: Validations.validateTextField(""),
    bo_Background_verification_status: Validations.validateTextField(""), // Edit && View
    bo_license_verification_status: Validations.validateTextField(""), // Edit && View
    bo_Status: Validations.validateTextField(""), // Edit && View
    bo_referral_source: Validations.validateMultiSelect(),
    bo_regestrationDate: "", // Edit && View
    bo_lastActive: "", // Edit && View
    bo_avgRating: Validations.validateNumericValue(),
    bo_countryCode: Validations.validateTextField("+1"),
    bo_referral_code: Validations.validateTextField(""),
  });
  const [status, updateStatus] = useState({
    backgroundVerificationStatus: "",
    licenseVerificationStatus: "",
  });
  const [businessDetailsData, updateBusinessDetails] = useState({
    establishIn: "",
    businessName: Validations.validateTextField(""),
    noOfEmployee: Validations.validateNumericValue(""),
    businessDescription: Validations.validateTextField(""),

    preferredLang: Validations.validateTextField(),
    businessDocsUpload: Validations.validateTextField(""),
    businessDocsFileName: Validations.validateTextField(""),
  });
  const getBusinessOwnerData = () => {};
  const [servicesDetailsData, updateServicesDetailsData] = useState({
    categorySelect: Validations.validateMultiSelect(),
    servicesSelect: Validations.validateMultiSelect(),
    otherService: Validations.validateTextField(""),
    otherServiceDescp: Validations.validateTextField(""),
  });

  const [serviceLocationData, updateServiceLocationData] = useState({
    city: Validations.validateMultiSelect(),
    service_choice: Validations.validateMultiSelect(),
    radius: Validations.validateNumericValue(),
  });
  const [backgroundCheck, updateBackgroundChack] = useState({
    SSN: Validations.validateTextField(""),
    driversLice_no: Validations.validateTextField(""),
    facebookUrl: Validations.validateTextField(""),
    licenceType: Validations.validateTextField(""),
    licenseFrontImg: Validations.validateTextField(""),
    licenseBackImg: Validations.validateTextField(""),
  });

  const [professionalCreds, updateProfessionalCreads] = useState({
    licence_issue_state: Validations.validateTextField(""),
    licenceType: Validations.validateTextField(""),
    licenceNo: Validations.validateTextField(""),
    InstagramUrl: Validations.validateTextField(""),
    facebookUrl: Validations.validateTextField(""),
  });
  const [workingHour, updateWorkingHour] = useState({
    tuesday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
    wednesday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },

    thursday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },

    friday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
    saturday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
    sunday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
    monday: {
      start: {
        hours: 0,
        minutes: 0,
        startTime: "",
      },
      end: {
        hours: 0,
        minutes: 0,
        endTime: "",
      },
    },
  });

  const [weekDays, updateWeekDays] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  // 12. View -> Referral Section -> s.no, Referral date, Amount, Expiry Date, BO / SP Email, BO / SP Contact number.  - Not done

  const referral_columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "",
      title: "Referral Date",

      // fixed: 'left',
    },
    {
      dataIndex: "",
      title: "Amount",

      // fixed: 'left',
    },
    {
      title: "Expiry Date",
      dataIndex: "",
    },
    {
      title: " BO Email",
      dataIndex: "",
    },
    {
      title: "BO Contact number",
      dataIndex: "",
    },
  ];

  // 13. View -> High - level Booking Details section -> s.no, Booking ID, Serviced Date, Service type, Customer Care Notes - Not done
  const Booking_columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: "left",
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "",
      title: "Booking Id",

      fixed: "left",
    },
    {
      dataIndex: "",
      title: "Serviced Date",
    },
    {
      dataIndex: "",
      title: "Service Type",

      // fixed: 'left',
    },
    {
      dataIndex: "",
      title: "Customer Care Notes",
    },
  ];

  const [customerData, updateCustomerData] = useState({
    isLoading: false,
    data: "",
  });

  const [businessOwnerData, updateBusinessOwnerData] = useState({
    isLoading: false,
    data: [],
  });

  const [modalControls, updateModalControls] = useState({
    formModalVisible: false,
    orderDetailsModalVisible: false,
    AddCustomerNotesVisible: false,
    modalType: "EDIT",
    data: [],
    isLoading: false,
    BO_Id: "",
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const openFormModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: true,
      // data: customerData,
    });
  };

  const closeModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: false,
    });
  };

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  useEffect(() => {
    let Id = window?.location?.pathname.split("details/")[1];
    if (Id) {
    }
  }, [BO_flag]);
  useEffect(() => {}, []);

  const handleStartTime = (e, day) => {
    console.log("handleStartTime day => ", e);
    let timeArr = moment(e?._d).format("HH:mm").split(":");
    console.log("handleStartTime =>", timeArr);

    switch (day) {
      case "sunday":
        updateWorkingHour({
          ...workingHour,
          sunday: {
            ...workingHour?.sunday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "monday":
        updateWorkingHour({
          ...workingHour,
          monday: {
            ...workingHour?.monday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "tuesday":
        updateWorkingHour({
          ...workingHour,
          tuesday: {
            ...workingHour?.tuesday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "wednesday":
        updateWorkingHour({
          ...workingHour,
          wednesday: {
            ...workingHour?.wednesday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "thursday":
        updateWorkingHour({
          ...workingHour,
          thursday: {
            ...workingHour?.thursday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "friday":
        updateWorkingHour({
          ...workingHour,
          friday: {
            ...workingHour?.friday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      case "saturday":
        updateWorkingHour({
          ...workingHour,
          saturday: {
            ...workingHour?.saturday,
            start: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              startTime: e,
            },
          },
        });

        break;
      default:
        break;
    }
  };
  const handleEndTime = (e, day) => {
    let timeArr = moment(e?._d).format("HH:mm").split(":");

    // {
    //   start: {
    //     hours: 0,
    //      minutes: 0,
    //   },
    //   end: {
    //     hours: 0,
    //      minutes: 0,
    //   },
    // },

    switch (day) {
      case "sunday":
        updateWorkingHour({
          ...workingHour,
          sunday: {
            ...workingHour?.sunday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "monday":
        updateWorkingHour({
          ...workingHour,
          monday: {
            ...workingHour?.monday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "tuesday":
        updateWorkingHour({
          ...workingHour,
          tuesday: {
            ...workingHour?.tuesday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "wednesday":
        updateWorkingHour({
          ...workingHour,
          wednesday: {
            ...workingHour?.wednesday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "thursday":
        updateWorkingHour({
          ...workingHour,
          thursday: {
            ...workingHour?.thursday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "friday":
        updateWorkingHour({
          ...workingHour,
          friday: {
            ...workingHour?.friday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      case "saturday":
        updateWorkingHour({
          ...workingHour,
          saturday: {
            ...workingHour?.saturday,
            end: {
              hours: parseInt(timeArr[0]),
              minutes: parseInt(timeArr[1]),
              endTime: e,
            },
          },
        });

        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <AddEditUser
        isVisible={modalControls.formModalVisible}
        serviceChoice={serviceChoice}
        cityData={cityData}
        onClose={closeModal}
        onCloseIconClick={closeModal}
        modalToShow={modalControls?.modalType}
        data={modalControls.data}
        isLoading={modalControls.isLoading}
        getCategoriesData={categoryData}
        getServiceData={serviceData}
        setBO_flag={setBO_flag}
        BO_flag={BO_flag}
        getBusinessOwnerData={getBusinessOwnerData}
      />

      <Header
        title="Business Owner Details"
        showBackButton={true}
        path={`home/Business Owners/Details/${modalControls?.BO_Id}`}
      />
      <Panel
        className="custom-section single"
        isLoading={businessOwnerData.isLoading}
      >
        <div className={styles.formWrapper}>
          <div className={styles.header}>Personal Details</div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "nowrap",
              // flexDirection: "column"
            }}
          >
            <Tooltip placement="top" title="Edit">
              <div className={styles.editIcon}>
                <Icon name="PEN" onClick={openFormModal} />
              </div>
            </Tooltip>

            <div className={styles.image}>
              <img
                src={
                  personalDetails?.bo_profileImageURL.value
                    ? personalDetails?.bo_profileImageURL.value
                    : placeholderImg
                }
                onError={handleImageError}
                alt="Customer Profile Pic"
              />
            </div>
            <div className={styles.form} style={{ padding: "0px 1rem" }}>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label for="name" value="First Name" />

                  <Input
                    disabled={true}
                    name="bo_first_name"
                    value={personalDetails?.bo_first_name?.value}
                    // placeholder="First Name"
                  />
                </div>

                <div className={styles.formItem}>
                  <Label value="Last Name" />
                  <Input
                    disabled={true}
                    name="bo_last_name"
                    value={personalDetails?.bo_last_name?.value}
                  />
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label for="email" value="app.field.email" />
                  <Input
                    disabled={true}
                    name="bo_email"
                    value={personalDetails?.bo_email?.value}
                  />
                </div>
                <div className={styles.formItem}>
                  <Label for="phoneNumber" value="app.field.phoneNumber" />

                  <Input
                    disabled={props.modalToShow !== "ADD"}
                    name="bo_phoneNumber"
                    value={personalDetails?.bo_phoneNumber?.value}
                  />
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="Avg Rating" vertical></Label>
                  <Select
                    disabled={true}
                    showSearch
                    // placeholder="Rating"
                    name="bo_avgRating"
                    value={personalDetails?.bo_avgRating?.value || "Not added"}
                    // vlaue={reviewData?.rating}
                    // onChange={handleRatingSelect}
                  >
                    {ratingFilter.map((item) => (
                      <Option key={item.id} value={item.id}>
                        <Rate
                          style={{ color: "#ED432A" }}
                          disabled
                          value={item.id}
                        />
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.formItem}>
                  <Label value="Priority Ranking" />

                  <Input
                    disabled={true}
                    name="bo_priorityRanking"
                    value={
                      personalDetails?.bo_priorityRanking?.value || "Not added"
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div className={styles.formGroup} style={{ marginTop: "1rem" }}>
              <div
                className={styles.formItem}
                style={{ width: "22.5%", flex: "none", minWidth: "250px" }}
              >
                <Label value="Wallet Amount" />
                <Input
                  disabled={true}
                  name="bo_walletAmount"
                  value={personalDetails?.bo_walletAmount?.value}
                  // placeholder="Wallet Amount"
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "calc(100% - 22.5%)",
                  // paddingLeft: "1rem",
                }}
              >
                <div className={styles.formItem}>
                  <Label value="Zip code" />
                  <Input
                    disabled={true}
                    name="bo_zipcode"
                    value={personalDetails?.bo_zipcode?.value}
                    // placeholder="Zip code"
                  />
                </div>

                <div className={styles.formItem}>
                  <Label value="Referral Source " />

                  <Select
                    showSearch
                    // style={{ marginRight: "1rem" }}
                    // placeholder="Select Seferral Source"

                    value={personalDetails?.bo_referral_source?.value}
                    optionFilterProp="children"
                    disabled={true || props.modalToShow === "EDIT"}
                    // filterOption={filterOption}
                  >
                    {referral_Source.map((item) => (
                      <Option key={item?.id} value={item.text}>
                        {item?.text}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className={styles.formGroup}>
              <div
                className={styles.formItem}
                style={{ width: "22.5%", flex: "none", minWidth: "250px" }}
              >
                <Label value="Last Active" />
                <DatePicker
                  disabled={true}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  placeholderText="Last Active"
                  className={styles.datePicker}
                  dateFormat="MM-dd-yyyy hh:mm"
                  selected={personalDetails?.bo_lastActive}
                  minDate={new Date()}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "calc(100% - 22.5%)",
                  paddingLeft: "1rem",
                }}
              >
                <div className={styles.formItem}>
                  <Label value="Registration date" />
                  <DatePicker
                    disabled={true}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    placeholderText="Registration date"
                    className={styles.datePicker}
                    dateFormat="MM-dd-yyyy hh:mm"
                    // dateFormat="MM-dd-yyyy hh:mm"
                    selected={personalDetails?.bo_regestrationDate}
                    minDate={new Date()}
                  />
                </div>
                <div className={styles.formItem}>
                  <Label value=" My Referral Code" />
                  <Input
                    disabled={true}
                    name="bo_referral_code"
                    placeholder="Referral Code"
                    value={personalDetails?.bo_referral_code?.value}
                  />
                </div>
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Used Referral Code" />
                <Input
                  disabled={true}
                  name="bo_referredBy_code"
                  placeholder="Referred By"
                  value={personalDetails?.bo_referredBy_code?.value}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "calc(100% - 22.5%)",
                  paddingLeft: "1rem",
                }}
              >
                <div className={styles.formItem}>
                  <Label value=" License verification Status" />

                  <Select
                    disabled={true}
                    showSearch
                    // style={{ marginRight: "1rem" }}

                    value={status?.licenseVerificationStatus}
                    optionFilterProp="children"
                    name="licenseStatus"
                    filterOption={filterOption}
                  >
                    {verifyStatus.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item?.text}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.formItem}>
                  <Label value="Background verification Status" />
                  <Select
                    disabled={true}
                    showSearch
                    // style={{ marginRight: "1rem" }}
                    placeholder="Background verification Status"
                    value={status?.backgroundVerificationStatus}
                    optionFilterProp="children"
                    name="backgroundStatus"
                    filterOption={filterOption}
                  >
                    {verifyStatus.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item?.text}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            {/* <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>Status</label>
                <div className={styles.detailsField}>Not added </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "calc(100% - 22.5%)",
                  paddingLeft: "1rem",
                }}
              >
                <div className={styles.formItem}>
                  <Label value="Background verification Status" />

                  <Select
                    disabled={true}
                    showSearch
                    // style={{ marginRight: "1rem" }}
                    placeholder="Background verification Status"
                    value={status?.backgroundVerificationStatus}
                    optionFilterProp="children"
                    name="backgroundStatus"
                    filterOption={filterOption}
                  >
                    {verifyStatus.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item?.text}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className={styles.formItem}></div>
              </div>
            </div> */}
          </div>
        </div>

        <div className={styles.formWrapper}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "nowrap",
              flexDirection: "column",
            }}
          >
            <div className={styles.header}>Business Details</div>

            <div className={styles.form} style={{ padding: "1rem 0" }}>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="Name of Business" />

                  <Input
                    disabled={true}
                    name="businessName"
                    placeholder="Name of Business"
                    value={businessDetailsData?.businessName?.value}
                  />
                </div>
                <div className={styles.formItem}>
                  <Label value="Number of Employees" />

                  <Input
                    disabled={true}
                    name="noOfEmployee"
                    placeholder="Number of Employees"
                    value={businessDetailsData?.noOfEmployee?.value}
                  />
                </div>

                <div className={styles.formItem}>
                  <Label value="Preferred language" />
                  <Select
                    disabled={true}
                    showSearch
                    // mode="multiple"
                    // style={{ marginRight: "1rem" }}
                    maxTagCount={2}
                    name="preferredLang"
                    placeholder="Preferred language"
                    value={businessDetailsData?.preferredLang.value}
                    optionFilterProp="children"
                    filterOption={filterOption}
                  >
                    <Option value="english">English</Option>
                    <Option value="spanish">Spanish</Option>
                  </Select>
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="Establish in" />
                  <DatePicker
                    disabled={true}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    placeholderText="Establish in"
                    className={styles.datePicker}
                    dateFormat="MM-dd-yyyy"
                    selected={businessDetailsData?.establishIn}
                  />
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.formItem}>
                    <Label value="Business Description" />

                    <Input.TextArea
                      disabled={true}
                      name="businessDescription"
                      placeholder="Business Description"
                      value={businessDetailsData?.businessDescription?.value}
                    />
                  </div>
                </div>
                <div className={styles.formItem}></div>
              </div>
            </div>
          </div>

          <footer></footer>
        </div>

        <div className={styles.formWrapper}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "nowrap",
              flexDirection: "column",
            }}
          >
            <div className={styles.header}>Service Details</div>

            <div className={styles.form} style={{ padding: "1rem 0" }}>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="Category" />
                  <Select
                    showSearch
                    mode="multiple"
                    className={classNames({
                      [styles.multySelect_disabled]: true,
                    })}
                    placeholder="Category"
                    maxTagCount={2}
                    name="categorySelect"
                    value={servicesDetailsData?.categorySelect?.value}
                  >
                    {/* {getCategoriesData.map((item) => (
                      <Option
                        key={item.id}
                        disabled={true}
                        value={item.id}
                      >
                        {item?.title}
                      </Option>
                    ))} */}
                  </Select>
                </div>

                <div className={styles.formItem}>
                  <Label value="Services" />
                  <Select
                    disabled={true}
                    showSearch
                    mode="multiple"
                    placeholder="Services"
                    maxTagCount={2}
                    className={classNames({
                      [styles.multySelect_disabled]: true,
                    })}
                    name="servicesSelect"
                    value={servicesDetailsData?.servicesSelect?.value}
                  >
                    {/* {getServiceData.map((item) => (
                      <Option
                        key={item.id}
                        disabled={true}
                        value={item.id}
                      >
                        {item?.title}
                      </Option>
                    ))} */}
                  </Select>
                </div>

                <div className={styles.formItem}>
                  <Label value="Any other services" optional />
                  <Input
                    disabled={true}
                    placeholder="Any other services"
                    name="otherService"
                    value={servicesDetailsData?.otherService?.value}
                  />
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="Other service Description" optional />

                  <Input.TextArea
                    disabled={true}
                    name="otherServiceDescp"
                    value={servicesDetailsData?.otherServiceDescp?.value}
                    placeholder="Other service Description"
                  />
                </div>
                <div className={styles.formItem}></div>
              </div>
            </div>
          </div>

          <footer></footer>
        </div>

        <div className={styles.formWrapper}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "nowrap",
              flexDirection: "column",
            }}
          >
            <div className={styles.header}>Working hours</div>

            <div className={styles.form} style={{ padding: "1rem 0" }}>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Checkbox
                      disabled={true}
                      name="sunday"
                      checked={weekDays.sunday}
                      onChange={"/"}
                    >
                      Sunday
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="Start Time" />

                      <TimePicker
                        format={timeFormat}
                        // defaultValue={dayjs('12:08:23', 'HH:mm:ss')}
                        name="sunday"
                        value={workingHour?.sunday?.start?.startTime}
                        onChange={(e) => handleStartTime(e, "sunday")}
                        disabled={!weekDays?.sunday || true}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="End Time" />

                      <TimePicker
                        format={timeFormat}
                        name="sunday"
                        value={workingHour?.sunday?.end?.endTime}
                        onChange={(e) => handleEndTime(e, "sunday")}
                        disabled={!weekDays?.sunday || true}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.formItem}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Checkbox
                      disabled={true}
                      name="monday"
                      checked={weekDays?.monday}
                      onChange={"/"}
                    >
                      Monday
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="Start Time" />

                      <TimePicker
                        format={timeFormat}
                        value={workingHour?.monday?.start?.startTime}
                        name="monday"
                        onChange={(e) => handleStartTime(e, "monday")}
                        disabled={!weekDays?.monday || true}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="End Time" />

                      <TimePicker
                        format={timeFormat}
                        name="monday"
                        value={workingHour?.monday?.end?.endTime}
                        onChange={(e) => handleEndTime(e, "monday")}
                        disabled={!weekDays?.monday || true}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.formItem}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Checkbox
                      disabled={true}
                      name="tuesday"
                      checked={weekDays?.tuesday}
                      onChange={"/"}
                    >
                      Tuesday
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="Start Time" />

                      <TimePicker
                        format={timeFormat}
                        name="tuesday"
                        value={workingHour?.tuesday?.start?.startTime}
                        onChange={(e) => handleStartTime(e, "tuesday")}
                        disabled={!weekDays?.tuesday || true}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="End Time" />

                      <TimePicker
                        format={timeFormat}
                        name="tuesday"
                        onChange={(e) => handleEndTime(e, "tuesday")}
                        disabled={!weekDays?.tuesday || true}
                        value={workingHour?.tuesday?.end?.endTime}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Checkbox
                      disabled={true}
                      name="wednesday"
                      value={weekDays?.wednesday}
                      onChange={"/"}
                    >
                      wednesday
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="Start Time" />

                      <TimePicker
                        format={timeFormat}
                        name="wednesday"
                        value={workingHour?.wednesday?.start?.startTime}
                        onChange={(e) => handleStartTime(e, "wednesday")}
                        disabled={!weekDays?.wednesday || true}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="End Time" />

                      <TimePicker
                        format={timeFormat}
                        name="wednesday"
                        onChange={(e) => handleEndTime(e, "wednesday")}
                        disabled={!weekDays?.wednesday || true}
                        value={workingHour?.wednesday?.end?.endTime}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.formItem}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Checkbox
                      disabled={true}
                      name="thursday"
                      checked={weekDays?.thursday}
                      onChange={"/"}
                    >
                      Thursday
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="Start Time" />

                      <TimePicker
                        format={timeFormat}
                        name="thursday"
                        value={workingHour?.thursday?.start?.startTime}
                        onChange={(e) => handleStartTime(e, "thursday")}
                        disabled={!weekDays?.thursday || true}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="End Time" />

                      <TimePicker
                        format={timeFormat}
                        name="thursday"
                        onChange={(e) => handleEndTime(e, "thursday")}
                        disabled={!weekDays?.thursday || true}
                        value={workingHour?.thursday?.end?.endTime}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.formItem}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Checkbox
                      disabled={true}
                      name="friday"
                      checked={weekDays?.friday}
                      onChange={"/"}
                    >
                      Friday
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="Start Time" />

                      <TimePicker
                        format={timeFormat}
                        name="friday"
                        value={workingHour?.friday?.start?.startTime}
                        onChange={(e) => handleStartTime(e, "friday")}
                        disabled={!weekDays?.friday || true}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="End Time" />

                      <TimePicker
                        format={timeFormat}
                        name="friday"
                        onChange={(e) => handleEndTime(e, "friday")}
                        disabled={!weekDays?.friday || true}
                        value={workingHour?.friday?.end?.endTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Checkbox
                      disabled={true}
                      name="saturday"
                      checked={weekDays?.saturday}
                      onChange={"/"}
                    >
                      Saturday
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="Start Time" />

                      <TimePicker
                        format={timeFormat}
                        name="saturday"
                        value={workingHour?.saturday?.start?.startTime}
                        onChange={(e) => handleStartTime(e, "saturday")}
                        disabled={!weekDays?.saturday || true}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Label value="End Time" />

                      <TimePicker
                        format={timeFormat}
                        name="saturday"
                        onChange={(e) => handleEndTime(e, "saturday")}
                        value={workingHour?.saturday?.end?.endTime}
                        disabled={!weekDays?.saturday || true}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.formItem}></div>
                <div className={styles.formItem}></div>
              </div>
            </div>
          </div>

          <footer></footer>
        </div>

        <div className={styles.formWrapper}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "nowrap",
              flexDirection: "column",
            }}
          >
            <div className={styles.header}>Professional credentials</div>

            <div className={styles.form} style={{ padding: "1rem 0" }}>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="License issued state" optional />
                  <Input
                    disabled={true}
                    name="licence_issue_state"
                    placeholder="License issued state"
                    value={professionalCreds?.licence_issue_state?.value}
                  />
                </div>
                <div className={styles.formItem}>
                  <Label value="Instagram" optional />
                  <Input
                    disabled={true}
                    name="InstagramUrl"
                    placeholder="Instagram"
                    value={professionalCreds?.InstagramUrl?.value}
                  />
                </div>

                <div className={styles.formItem}>
                  <Label value="Licence Type" optional />
                  <Input
                    disabled={true}
                    name="licenceType"
                    placeholder="License Type"
                    value={professionalCreds?.licenceType?.value}
                  />
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="License Number" optional />
                  <Input
                    disabled={true}
                    name="licenceNo"
                    placeholder="License Number"
                    value={professionalCreds?.licenceNo?.value}
                  />
                </div>
                <div className={styles.formItem}>
                  <Label value="Facebook" optional />
                  <Input
                    disabled={true}
                    name="facebookUrl"
                    placeholder="Facebook"
                    value={professionalCreds?.facebookUrl?.value}
                  />
                </div>
                <div className={styles.formItem}></div>
              </div>
            </div>
          </div>

          <footer></footer>
        </div>

        <div className={styles.formWrapper}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "nowrap",
              flexDirection: "column",
            }}
          >
            <div className={styles.header}>Choose where you work</div>

            <div className={styles.form} style={{ padding: "1rem 0" }}>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="Choice of services" />
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder="Select Choice of services"
                    maxTagCount={2}
                    maxTagTextLength={8}
                    name="service_choice"
                    value={serviceLocationData?.service_choice?.value}
                    className={classNames({
                      [styles.multySelect_disabled]: true,
                    })}
                  >
                    {serviceChoice.map((item) => (
                      <Option key={item.id} disabled={true} value={item.id}>
                        {item?.text}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.formItem}>
                  <Label value="Radius for Service Area" />
                  <Input
                    disabled={true}
                    name="areaRadius"
                    addonAfter="Miles"
                    placeholder="Radius for Service Area"
                    value={serviceLocationData?.radius?.value}
                  />
                </div>

                <div className={styles.formItem}>
                  <Label value="City" />
                  <Select
                    showSearch
                    mode="multiple"
                    maxTagCount={2}
                    maxTagTextLength={8}
                    placeholder="City"
                    className={classNames({
                      [styles.multySelect_disabled]: true,
                    })}
                    name="citySelect"
                    value={serviceLocationData?.city?.value}
                  ></Select>
                </div>
              </div>
            </div>
          </div>

          <footer></footer>
        </div>

        <div>
          <h1>Referral Section</h1>

          <CustomTable
            isLoading={tableControls.isLoading}
            columns={referral_columns}
            dataSource={tableControls.data}
            isEmpty={tableControls.isEmpty}
            pagination={pagination}
            current={pagination.current}
            pageSize={pagination.pageSize}
            columnWidth={"150px"}
          />
        </div>

        <div>
          <h1>High Level Booking Details section </h1>

          <CustomTable
            isLoading={tableControls.isLoading}
            columns={Booking_columns}
            dataSource={tableControls.data}
            isEmpty={tableControls.isEmpty}
            pagination={pagination}
            current={pagination.current}
            pageSize={pagination.pageSize}
            columnWidth={"150px"}
          />
        </div>
      </Panel>
    </div>
  );
}
export default BusinessOwnerDetails;
