import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button, Menu, Tooltip, Rate, Input } from "antd";
import { useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import axios from "../../shared/api/axiosConfig";
import { useConfirm } from "../../hooks";
import {
  CustomImage,
  CustomTable,
  CustomTag,
  DelayedInput,
  Header,
  Label,
} from "../../components/common";
import {
  nestedParseJSON,
  removeEmptyKeys,
} from "../../shared/utils/object-manipulation";
import moment from "moment";
import { PAYMENT_METHODS_MAPPING } from "../../shared/constants/orders";
import AddEditReview from "./review-modal/review-Modal";
import { connect } from "react-redux";
import userActions from "../../redux/user-info/user-actions";
import { ReviewsData } from "../../FakeData";
import axiosConfig from "../../shared/api/axiose"; //http://52.26.241.33:3002
import ViewUser from "./view-user/view-user";
import { Modal } from "antd";
import {
  country,
  states,
  cities,
  zipcode,
} from "../../shared/constants/countryFilterData";
import AddEditUser from "./add-edit-user/add-edit-user";

const styles = {
  wrapper: "stores__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "stores__menu--left",
  menuRight: "stores__menu--right",
  pageSize: "stores__records-per-page",
  filterCont: "filterCont",
};
const { Option } = Select;

function Reviews(props) {
  const intl = useIntl();
  const [filter, updateFilter] = useState({
    country: {},
    state: {},
    city: {},
    zipcode: {},
  });

  const [stores, updateStores] = useState({
    selected: props.storeId !== null ? props.storeId : "",
    data: [],
    notFound: false,
    isLoading: true,
  });

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isLoading: false,
    isVisible_edit: false,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    count: 0,
    isEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    search: "",
    current: 1,
    total: 0,
  });

  const handlePaginationChange = ({ current, pageSize, total }) => {
    updatePagination({
      ...pagination,
      current,
      total,
      pageSize,
      skip: (current - 1) * pagination.pageSize,
    });
  };
  const [getCategoriesData, setCategoriesData] = useState([]);
  const [getServiceData, setServiceData] = useState([]);
  console.log("getCategoriesData => ", getCategoriesData);

  const openEditStoreModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "EDIT",
      data,
    });
  };
  const viewCustomerReview = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "VIEW",
      data,
    });
  };
  const EditReview = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible_edit: true,
      type: "EDIT",
      data: data,
    });
  };
  const handleModalClose = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isVisible_edit: false,
    });
  };

  const removeCustomerReview = (item) => {
    // console.log("removeCustomerReview=>", item);
    axiosConfig
      .delete(`/spReviews/${item.id}`)
      .then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
          console.log("review del res=>", res?.data);
          toastr.success("Customer review deleted!");
        } else {
          toastr.error(res?.data?.message);
        }
      })
      .catch((err) => console.log("review delete err", err));
  };
  const showDeleteConfirm = (item) => {
    Modal.confirm({
      title: "Delete Customer Review ?",
      width: 520,
      closable: true,
      // icon: <ExclamationCircleOutlined />,
      content: "Added customer review will be deleted",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      okButtonProps: {
        type: "primary",
        // danger:"true",
      },
      onOk: () => {
        // this.removeItem(item);
        console.log(item);
        // alert("Delete User");
        removeCustomerReview(item);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const toggleStore = (review, isDelete) => {
    const data = {
      reviewId: review.reviewId,
      isReviewApproved: review.isReviewApproved ? 0 : 1,
    };
    updateTableControls({
      ...tableControls,
      data: tableControls.data.map((i) => {
        if (i.reviewId === data.reviewId) {
          i.isReviewApproved = i.isReviewApproved ? 0 : 1;
        }
        return i;
      }),
    });
  };

  useEffect(() => {}, []);

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: "left",
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },

    {
      dataIndex: "",
      title: "Service Booking no",
    },
    {
      dataIndex: "",
      title: "Customer Name",
    },
    {
      dataIndex: "service",
      title: "Service",
    },
    {
      dataIndex: "",
      title: "Service Date",
    },
    {
      dataIndex: "",
      title: "Service Provider's Name ",
    },
    {
      dataIndex: "",
      title: "City (Customer)",
    },
    {
      dataIndex: "",
      title: "Zip Code (Customer)",
    },
    {
      dataIndex: "rating",
      title: intl.formatMessage({ id: "app.field.rating" }),
      render: (item) => {
        return <Rate style={{ color: "#ED432A" }} disabled value={item} />;
      },
    },
    {
      dataIndex: "title",
      title: "Reviews",
    },
    {
      dataIndex: "createdAt",
      title: intl.formatMessage({ id: "app.field.reviewDate" }),
      render: (item) => moment(item).format("MMMM Do YYYY"),
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            viewCustomerReview(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>
        {/* Add wallet will be delete after confirmation */}

        <Menu.Item
          onClick={() => {
            EditReview(item);
          }}
        >
          Edit
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            showDeleteConfirm(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };

  const handleFilterCountry = (val) => {
    let data = JSON.parse(val);
    updateFilter({
      ...filter,
      country: data,
    });
  };
  const handleFilterState = (val) => {
    // let data = JSON.parse(val)
    console.log("handleFilterState => ", val);

    updateFilter({
      ...filter,
      state: val,
    });
  };
  const handleFilterCity = (val) => {
    // let data = JSON.parse(val)
    updateFilter({
      ...filter,
      city: val,
    });
  };

  const handleFilterZipcode = (val) => {
    // let data = JSON.parse(val)
    updateFilter({
      ...filter,
      zipcode: val,
    });
  };
  const ratingFilter = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];
  const serviceData = [
    {
      id: 1,
      text: "Service 1",
    },
    {
      id: 2,
      text: "Service 2",
    },
    {
      id: 3,
      text: "Service 3",
    },
    {
      id: 4,
      text: "Service 4",
    },
    {
      id: 5,
      text: "Service 5",
    },
    {
      id: 6,
      text: "Service 6",
    },
    {
      id: 7,
      text: "Service 8",
    },
  ];

  const CategoryData = () => {
    setServiceData(serviceData);

    axiosConfig
      .get("/categories")
      .then((res) => {
        console.log("CategoryData", res?.data?.data);
        console.log("CategoryData res", res?.data?.statusCode);
        if (res?.data?.statusCode === 200) {
          setCategoriesData(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    CategoryData();
  }, []);

  return (
    <>
      <ViewUser
        isVisible={modalControls.isVisible}
        onCloseIconClick={handleModalClose}
        modalToShow={modalControls.isVisible}
        data={modalControls.data}
      />

      <AddEditUser
        isVisible={modalControls?.isVisible_edit}
        onClose={handleModalClose}
        onCloseIconClick={handleModalClose}
        modalToShow={modalControls?.type}
        data={modalControls?.data}
        getCategoriesData={getCategoriesData}
        getServiceData={getServiceData}
        isLoading={modalControls?.isLoading}
      />

      <div className={styles.wrapper}>
        <Header
          title="Service Provider To Customer Reviews"
          path="/home/reviews/Service Provider To Customer Reviews"
        />
        <div className={styles?.filterCont}>
          <header style={{ display: "flex", gap: "2.3rem", flexWrap: "wrap" }}>
            <div>
              <Label value="Select Country" vertical></Label>
              <Select
                showSearch
                placeholder="Country"
                onChange={handleFilterCountry}
                style={{ width: "180px" }}
                // value={stores.selected}
              >
                {country.map((item) => (
                  <Option key={item.id} value={JSON.stringify(item)}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Label value="Select State" vertical></Label>
              <Select
                showSearch
                placeholder="State"
                onChange={handleFilterState}
                mode="multiple"
                style={{ width: "180px" }}
                // value={stores.selected}
              >
                {filter?.country?.text &&
                  states.map((item) => (
                    <Option key={item.id} value={item?.text}>
                      {item.text}
                    </Option>
                  ))}
              </Select>
            </div>
            <div>
              <Label value="Select City" vertical></Label>
              <Select
                showSearch
                placeholder="City"
                mode="multiple"
                onChange={handleFilterCity}
                style={{ width: "180px" }}
                // value={stores.selected}
              >
                {filter?.state.length &&
                  cities.map((item) => (
                    <Option key={item.id} value={item?.text}>
                      {item.text}
                    </Option>
                  ))}
              </Select>
            </div>
            <div>
              <Label value="Select Zip Code" vertical></Label>
              <Select
                showSearch
                placeholder="Zip Code"
                name="zipCode"
                mode="multiple"
                onChange={handleFilterZipcode}
                style={{ width: "180px" }}
                // value={stores.selected}
              >
                {zipcode.map((item) => (
                  <Option key={item.id} value={item?.text}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </div>
          </header>
        </div>

        <div className={styles.content}>
          <h1> </h1>
          <div
            className={styles.menu}
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-end",
              gap: "2rem",
            }}
          >
            <div>
              <Label value="Filter By Rating" vertical></Label>
              <Select
                showSearch
                placeholder="Rating"
                // onChange={handleStoreChange}
                style={{ width: "200px" }}
                // value={stores.selected}
              >
                {ratingFilter.map((item) => (
                  <Option key={item.id} value={item.id}>
                    <Rate
                      style={{ color: "#ED432A" }}
                      disabled
                      value={item.id}
                    />
                  </Option>
                ))}
              </Select>
            </div>

            <div>
              <Label value="Filter By Category" vertical></Label>
              <Select
                showSearch
                placeholder="Category"
                // onChange={handleStoreChange}
                style={{ width: "180px" }}
                // value={stores.selected}
              >
                {getCategoriesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item?.title}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Label value="Filter By Business Name" vertical></Label>
              <Select
                showSearch
                placeholder="Enter Business Name"
                // onChange={handleStoreChange}
                style={{ width: "180px" }}
                // value={stores.selected}
              ></Select>
            </div>

            <header>
              <div>
                <DelayedInput
                  delay={500}
                  minLength={6}
                  size="medium"
                  prefixIcon={<SearchOutlined />}
                  placeholder={intl.formatMessage({
                    id: "app.placeholder.search",
                  })}
                />
              </div>
            </header>
          </div>
          <CustomTable
            columns={columns}
            dataSource={tableControls.data}
            isLoading={tableControls.isLoading}
            isEmpty={tableControls.isEmpty}
            pagination={pagination}
            editItem={openEditStoreModal}
            onChange={handlePaginationChange}
            toggleItem={toggleStore}
            offset={43}
            columnWidth={150}
            width
          />
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
});

export default connect(mapStateToProps)(Reviews);
