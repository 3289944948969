import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  Label,
} from "../../../components/common";
import { Button, Input, Select, Radio, Rate } from "antd";
import DatePicker from "react-datepicker";

import { connect } from "react-redux";

import Validations from "../../../shared/validations/Validations";
// import Validations from "../../../shared/validations/Validations";

import { toastr } from "react-redux-toastr";
import "./add-edit-user.scss";
import axios from "../../../shared/api/axiose";

import { checkRtl } from "../../../shared/utils/rtl";
import { InteractionFilled } from "@ant-design/icons";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import {
  country,
  states,
  cities,
  zipcode,
} from "../../../shared/constants/countryFilterData";

const { Option } = Select;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
  datePicker: "datePicker",
};

function AddEditUser({
  getCategoriesData,
  getServiceData,
  getReviewData,
  data,
  ...props
}) {
  const errorMessage = "Please fill the field";
  const [city, setCity] = useState(Validations.validateMultiSelect(undefined));
  const [country__, setCountry] = useState(
    Validations.validateTextField(undefined)
  );

  const [zipCode, setZipCode] = useState(
    Validations.validateMultiSelect(undefined)
  );
  const [state, setState] = useState(
    Validations.validateMultiSelect(undefined)
  );

  const [reviewData, updateReviewData] = useState({
    customerName: "",
    contactNo: "",
    orderNo: "",
    serviceDate: "",
    reviewDate: "",
    serviceProviderName: "",
    businessName: "",
    selectCategory: "",
    selectService: "",
    rating: "",
    review: "",
    tags: "",
  });

  const [filter, updateFilter] = useState({
    country: {},
    state: {},
    city: {},
    zipcode: {},
  });

  const [privilages, changePrivilages] = useState(
    Validations.validateMultiSelect([])
  );

  console.log("privilages ", privilages);

  const [isPristine, changeIsPristine] = useState(true);

  const setForm = () => {};

  const resetForm = () => {
    updateReviewData({
      ...reviewData,
      customerName: "",
      contactNo: "",
      orderNo: "",
      serviceDate: "",
      reviewDate: "",
      serviceProviderName: "",
      businessName: "",
      selectCategory: "",
      selectService: "",
      rating: "",
      review: "",
      tags: "",
    });
  };

  useEffect(() => {
    if (props.isVisible) {
      if (props.modalToShow === "EDIT") {
        console.log(props.data);
        setForm();
      } else {
        resetForm();
      }
    }
  }, [props.isVisible]);

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        updateReviewData({
          ...reviewData,
          customerName: value,
        });
        break;
      case "contactNo":
        updateReviewData({
          ...reviewData,
          contactNo: value,
        });
        break;
      case "orderNo":
        updateReviewData({
          ...reviewData,
          orderNo: value,
        });
        break;
      case "serviceProviderName":
        updateReviewData({
          ...reviewData,
          serviceProviderName: value,
        });
        break;
      case "businessName":
        updateReviewData({
          ...reviewData,
          businessName: value,
        });
        break;
      case "review":
        updateReviewData({
          ...reviewData,
          review: value,
        });
        break;

      case "tags":
        updateReviewData({
          ...reviewData,
          tags: value,
        });
        break;

      default:
        return;
    }
  };
  const handleServiceData = (date) => {
    console.log("handleServiceData = > ", date);
    updateReviewData({
      ...reviewData,
      serviceDate: date,
    });
  };
  const handleReviewDate = (date) => {
    updateReviewData({
      ...reviewData,
      reviewDate: date,
    });
  };
  const handleCategory = (val) => {
    console.log("handleCategory => ", val);
    updateReviewData({
      ...reviewData,
      selectCategory: val,
    });
  };
  const handleServiceSelect = (val) => {
    updateReviewData({
      ...reviewData,
      selectService: val,
    });
  };
  const handleRatingSelect = (val) => {
    console.log("handleRatingSelect => ", val);
    updateReviewData({
      ...reviewData,
      rating: val,
    });
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const formAction = () => {
    changeIsPristine(false);
    if (!isFormValid()) {
      toastr.warning(props.intl.formatMessage({ id: "app.message.formCheck" }));
      return;
    }
  };

  const isFormValid = () => {
    let isValid = true;

    isValid =
      isValid &&
      reviewData?.customerName &&
      reviewData?.contactNo &&
      // reviewData?.orderNo &&
      reviewData?.serviceDate &&
      reviewData?.reviewDate &&
      reviewData?.reviewDate &&
      reviewData?.serviceProviderName &&
      reviewData?.selectCategory &&
      reviewData?.selectService &&
      reviewData?.rating &&
      reviewData?.tags;

    return isValid;
  };

  const handleFilterCountry = (val) => {
    let data = JSON.parse(val);
    setCountry(Validations.validateTextField(data?.text));
  };
  const handleFilterState = (val) => {
    console.log("handleFilterState => ", val);
    setState(Validations.validateMultiSelect(val));
  };
  const handleFilterCity = (val) => {
    setCity(Validations.validateMultiSelect(val));
  };

  const handleFilterZipcode = (val) => {
    setZipCode(Validations.validateMultiSelect(val));
    updateFilter({
      ...filter,
      zipcode: val,
    });
  };
  const ratingFilter = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];
  return (
    <CustomModal
      size="tiny"
      onCloseIconClick={closeModal}
      header={props.modalToShow == "EDIT" ? "Edit Review" : ""}
      action={
        <>
          {" "}
          <Button type="default" shape={undefined} onClick={closeModal}>
            <FormattedMessage id="app.field.cancel" />
          </Button>
          <Button
            type="primary"
            loading={props.isLoading}
            shape={undefined}
            onClick={formAction}
          >
            <FormattedMessage id="app.field.submit" />
          </Button>
        </>
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div>
          <div
            style={{
              marginBottom: "1rem",
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="app.field.details" />
          </div>
          <div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label for="name" value="Customer name" />
                <Input
                  name="name"
                  onChange={handleFieldChange}
                  value={reviewData?.customerName}
                  placeholder="Enter Customer Name"
                />
                {!isPristine && !reviewData?.customerName && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
              <div className={styles.formItem}>
                <Label for="name" value="Customer Contact no" />
                <Input
                  name="contactNo"
                  onChange={handleFieldChange}
                  value={reviewData?.contactNo}
                  placeholder="Enter Customer Contact no"
                />
                {!isPristine && !reviewData?.contactNo && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
            </div>
            {
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label value="Order No" />
                  <Input
                    name="orderNo"
                    onChange={handleFieldChange}
                    value={reviewData?.orderNo}
                    disabled
                    placeholder="Order No"
                  />
                  {/* {!isPristine && !reviewData?.orderNo && <ErrorMessage text={errorMessage} />} */}
                </div>
                <div className={styles.formItem}>
                  <Label value="Service date" />
                  <DatePicker
                    dropdownMode="select"
                    placeholderText="Enter Service Date"
                    className={styles.datePicker}
                    dateFormat="dd-MM-yyyy"
                    selected={reviewData?.serviceDate}
                    onChange={handleServiceData}

                    // maxDate={new Date()}
                  />
                  {!isPristine && !reviewData?.serviceDate && (
                    <ErrorMessage text={errorMessage} />
                  )}
                </div>
              </div>
            }

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Review Date" />
                <DatePicker
                  name="reviewDate"
                  className={styles?.datePicker}
                  placeholderText="Enter Review Date"
                  dateFormat="dd-MM-yyyy"
                  selected={reviewData?.reviewDate}
                  onChange={handleReviewDate}
                />
                {!isPristine && !reviewData?.reviewDate && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>

              <div className={styles.formItem}>
                <Label value="Service Provider Name" />
                <Input
                  name="serviceProviderName"
                  value={reviewData?.serviceProviderName}
                  onChange={handleFieldChange}
                  placeholder="Enter Service Provider Name"
                />
                {!isPristine && !reviewData?.serviceProviderName && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Business Name" />
                <Input
                  name="businessName"
                  onChange={handleFieldChange}
                  value={reviewData?.businessName}
                  placeholder="Enter Business Name"
                />
                {!isPristine && !reviewData?.businessName && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
              <div className={styles.formItem}>
                <Label value="Category" vertical></Label>
                <Select
                  showSearch
                  placeholder="Category"
                  onChange={handleCategory}
                  value={reviewData?.selectCategory || []}
                >
                  {getCategoriesData.map((item) => (
                    <Option key={item.id} value={item.title}>
                      {item?.title}
                    </Option>
                  ))}
                </Select>
                {!isPristine && !reviewData?.selectCategory && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Service" vertical></Label>
                <Select
                  showSearch
                  placeholder="Service"
                  onChange={handleServiceSelect}
                  value={reviewData?.selectService || []}
                >
                  {getServiceData.map((item) => (
                    <Option key={item.id} value={item.text}>
                      {item?.text}
                    </Option>
                  ))}
                </Select>
                {!isPristine && !reviewData?.selectService && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>

              <div className={classNames([styles.formItem])}>
                <Label value="Country (SP)"></Label>
                <Select
                  showSearch
                  placeholder="Country"
                  onChange={handleFilterCountry}
                  value={country__?.value}
                >
                  {country?.map((item) => (
                    <Option key={item.id} value={JSON.stringify(item)}>
                      {item.text}
                    </Option>
                  ))}
                </Select>
                {!isPristine && !country__?.isValid && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={classNames([styles.formItem])}>
                <Label value="State (SP)" vertical></Label>
                <Select
                  showSearch
                  placeholder="State"
                  onChange={handleFilterState}
                  value={state?.value}
                  mode="multiple"
                >
                  {country__?.value &&
                    states.map((item) => (
                      <Option key={item.id} value={item?.text}>
                        {item.text}
                      </Option>
                    ))}
                </Select>
                {!isPristine && !state?.isValid && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>

              <div className={classNames([styles.formItem])}>
                <Label value="City (SP)" vertical></Label>
                <Select
                  showSearch
                  placeholder="City"
                  value={city?.value}
                  mode="multiple"
                  onChange={handleFilterCity}
                >
                  {state?.value &&
                    cities.map((item) => (
                      <Option key={item.id} value={item?.text}>
                        {item.text}
                      </Option>
                    ))}
                </Select>
                {!isPristine && !city?.isValid && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Zip Code (SP)" vertical></Label>
                <Select
                  showSearch
                  placeholder="Zip Code"
                  value={zipCode?.value}
                  name="zipCode"
                  mode="multiple"
                  onChange={handleFilterZipcode}
                >
                  {city?.value &&
                    zipcode.map((item) => (
                      <Option key={item.id} value={item?.text}>
                        {item.text}
                      </Option>
                    ))}
                </Select>
                {!isPristine && !zipCode?.isValid && (
                  <ErrorMessage text={zipCode?.errMsg[0]} />
                )}
              </div>

              <div className={styles.formItem}>
                <Label value="Rating" vertical></Label>
                <Select
                  showSearch
                  placeholder="Rating"
                  name="rating"
                  vlaue={reviewData?.rating}
                  onChange={handleRatingSelect}
                >
                  {ratingFilter.map((item) => (
                    <Option key={item.id} value={item.id}>
                      <Rate
                        style={{ color: "#ED432A" }}
                        disabled
                        value={item.id}
                      />
                    </Option>
                  ))}
                </Select>
                {!isPristine && !reviewData?.rating && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="Reviews" />
                <Input.TextArea
                  name="review"
                  onChange={handleFieldChange}
                  value={reviewData?.review}
                  placeholder="Enter Reviews"
                />
                {!isPristine && !reviewData?.review && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
              <div className={styles.formItem}>
                <Label value="Tags" />
                <Input
                  name="tags"
                  onChange={handleFieldChange}
                  value={reviewData?.tags}
                  placeholder="Enter Tags"
                />
                {!isPristine && !reviewData?.tags && (
                  <ErrorMessage text={errorMessage} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

const mapStateToProps = (state) => ({
  enabledPanels: state.user.enterprice.enabledPanels,
  isAddDisabled: state.user.isAddDisabled,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(injectIntl(AddEditUser));
