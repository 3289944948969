import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Button } from "antd";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";

import { AdvertisementsTable, AddEditAdvertisement } from "./components";
import AddEditPromoContainer from "./components/add-edit-promo-modal/add-edit-promo-modal";
import { Header, DelayedInput } from "../../components/common";

import "./advertisements.scss";
import userActions from "../../redux/user-info/user-actions";
import {
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import { CustomTable, CustomTag } from "../../components/common";
import { questionaryData } from "../../FakeData";
import axiosConfig from "../../shared/api/axiose";
import moment from "moment";
import { Modal } from "antd";
import ViewUser from "./components/view-user/view-user";
import { Dropdown, Tooltip, Menu, Switch } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import axios from "../../shared/api/axios_config";

const styles = {
  wrapper: "advertisements__wrapper",
  menu: "custom-menu",
  content: "custom-section",
};

const { Option } = Select;

function GeneralFAQ(props) {
  const questionType = [
    {
      value: "1",
      text: "String",
    },
    {
      value: "2",
      text: "Number",
    },
    {
      value: "3",
      text: "Phone No",
    },
    {
      value: "4",
      text: "Email",
    },
    {
      value: "5",
      text: "Document",
    },
    {
      value: "6",
      text: "Date only future",
    },
    {
      value: "7",
      text: "Date",
    },
    {
      value: "8",
      text: "Checklist",
    },
  ];
  const intl = useIntl();
  const [search, updateSearch] = useState("");
  const [pagination, updatePagination] = useState({
    pageType: undefined,
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
  });

  const handlePaginationChange = ({ current, pageSize, total }) => {
    updatePagination({
      ...pagination,
      current,
      total,
      pageSize,
      skip: (current - 1) * pagination.pageSize,
    });
  };

  const [generalFaq, setAllGeneralFaq] = useState({
    data: [],
    isLoading: false,
  });

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [questionData, updateQuestionData] = useState({
    isLoading: false,
    data: [],
    notFound: false,
  });

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    viewModal_IsVisible: false,

    modalType: "",
    data: {},
    isLoading: false,
  });

  const openAddAdvertisementModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data: {},
      modalType: "ADD",
    });
  };

  const handleEdit = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      modalType: "EDIT",
    });
  };
  const handleViewModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      modalType: "VIEW",
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isVisible: false,
      viewModal_IsVisible: false,

      // data: {},
    });
  };

  const showDeleteConfirm = (item) => {
    Modal.confirm({
      title: "Delete FAQ ?",
      width: 520,
      closable: true,
      // icon: <ExclamationCircleOutlined />,
      content: "Added FAQ  will be deleted",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      okButtonProps: {
        type: "primary",
        // danger:"true",
      },
      onOk: () => {
        console.log(item);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleViewModal(item)}>
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>

        <Menu.Item onClick={() => handleEdit(item)}>Edit</Menu.Item>

        {!item.status && <Menu.Item>Enable</Menu.Item>}
        {item.status && <Menu.Item>Disable</Menu.Item>}
        <Menu.Item
          onClick={() => {
            showDeleteConfirm(item?.id);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "question",
      title: "Question",
      render: (item) => {
        return item.length > 50 ? item.substring(0, 50) + "..." : item;
      },
    },
    {
      title: "Answer",
      dataIndex: "answer",
      render: (item) => {
        return item.length > 50 ? item.substring(0, 50) + "..." : item;
      },
    },

    {
      dataIndex: "createdAt",
      title: "Created At",
      render: (item) => moment(item).format("DD/MM/YY"),
    },

    {
      dataIndex: "status",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        return <CustomTag isEnabled={item} />;
      },
    },

    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        // debugger;
        return (
          <center>
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          </center>
        );
      },
    },
  ];

  // Mount

  // const getQuestionasData = () => {
  //   updateQuestionData({
  //     ...questionData,
  //     isLoading: true,

  //   });
  //   axiosConfig.get("/questionnaire").then((res) => {
  //     console.log("questionnaire =>", res?.data)
  //     if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
  //       updateQuestionData({
  //         ...questionData,
  //         isLoading: false,
  //         notFound: false,
  //         data: [...res?.data?.data, ]
  //       });
  //     }
  //   }).catch((Err) => {
  //     console.log(Err)
  //   })
  // }

  const addAdvertisement = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
  };

  const toggleAdvertisement = (bannerData, type) => {};

  const editAdvertisement = (data) => {
    alert("Edit");
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
  };

  const getAllFAQ = (params) => {
    setAllGeneralFaq({
      ...generalFaq,

      isLoading: true,
    });
    axios
      .get("/generalFaq", params)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setAllGeneralFaq({
            ...generalFaq,
            data: res?.data?.data,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setAllGeneralFaq({
          ...generalFaq,

          isLoading: true,
        });
      });
  };

  // Advertisements
  useEffect(() => {
    getAllFAQ();
  }, []);
  let debounceTimer;
  function debounce(func, delay) {
    // Clear any previously set debounce timer

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer
    debounceTimer = setTimeout(() => {
      debounceTimer = null;
      func();
    }, delay);
  }
  const handleSearchChange = (val) => {
    console.log("handleSearchChange => ", val);
    updateSearch(val);
  };
  const handleSearch = (val) => {
    console.log("handleSearch => ", val);
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });
    clearTimeout(debounceTimer);
    let query = {};
    query.search = val;
    let params = {};
    params.params = query;
    debounce(() => getAllFAQ(params), 1000);
  };
  return (
    <div className={styles.wrapper}>
      <Header title="General FAQ's" path="/home/General FAQ's" />

      <ViewUser
        isVisible={modalControls?.viewModal_IsVisible}
        onCloseIconClick={handleModalClose}
        // enabledVerticals={this.props.enabledVerticals}
        modalToShow={modalControls.isVisible}
        questionType={questionType}
        data={modalControls.data}
      />
      <AddEditPromoContainer
        editStoreId={stores.selected}
        modalType={modalControls?.modalType}
        data={modalControls.data}
        isVisible={modalControls?.isVisible}
        onCloseIconClick={handleModalClose}
        addAdvertisement={addAdvertisement}
        editAdvertisement={editAdvertisement}
        isLoading={modalControls.isLoading}
        questionType={questionType}
        getAllFAQ={getAllFAQ}
      />

      <div className={styles.content}>
        <div
          className={styles.menu}
          style={{ justifyContent: "space-between" }}
        >
          <section style={{ gap: "1rem" }}>
            <div>
              <DelayedInput
                onChange={handleSearchChange}
                value={search}
                callback={handleSearch}
                delay={500}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder={intl.formatMessage({
                  id: "app.placeholder.search",
                })}
              />
            </div>
            <div></div>
          </section>

          <div>
            {
              <Button
                onClick={openAddAdvertisementModal}
                type="primary"
                shape={undefined}
                size="medium"
              >
                {"Add FAQ"}
              </Button>
            }
          </div>
        </div>

        <CustomTable
          isLoading={generalFaq.isLoading}
          columns={columns}
          dataSource={generalFaq.data}
          // dataSource={tableControls.data}
          toggleItemBlock={toggleAdvertisement}
          onChange={handlePaginationChange}
          pagination={pagination}
          current={pagination.current}
          pageSize={pagination.pageSize}
          questionary={true}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(GeneralFAQ);
