import React, { useState, useEffect } from "react";
import { Button, Select, Menu, Dropdown, } from "antd";
import { useIntl } from "react-intl";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";

import { StoreCategoriesTable, StoreCategoriesModal } from "./components";
import {
  Header,
  PaginationInfo,
  DelayedInput,
  CustomTag,
  CustomTable,
  CustomImage,
  Label
} from "../../components/common";

import axios from "../../shared/api/axiosConfig";
import "./store-categories.scss";
import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import { useConfirm } from "../../hooks";
import { ServiceProviderData } from "../../FakeData";

const styles = {
  wrapper: "store-categories__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "store-categories__menu--left",
  menuRight: "store-categories__menu--right",
  pageSize: "store-categories__records-per-page",
};
const { Option } = Select;
function StoreCategories(props) {
  const intl = useIntl();


  const [searchDelayed, updateSearchDelayed] = useState("");

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isLoading: false,
  });

  const ProviderData =
    [
      {
        "providerId": 172,
        "adminId": 1,
        "name": "May Flower",
        "phoneNo": "3241235312",
        "countryCode": "+91",
        "email": "mayflower@june.com",
        "password": "$2b$10$rWtUFAD902T21la6x7vfC.2HKte1wJEN.NdnktfwJFQGe4v0BWQ7W",
        "accessToken": null,
        "socialId": null,
        "appVersion": 1,
        "deviceToken": "kdjkj43k4jk343",
        "deviceType": "WEB",
        "socialMode": null,
        "lastOnlineDate": "2022-12-13 04:37:08",
        "profilePicURL": "https://deiry8u6tztoz.cloudfront.net/images/H2i11670906224895-kOEA1670906224844puppy.jpeg",
        "isNotificationOn": 1,
        "passwordResetToken": null,
        "otpCode": 8314,
        "phoneVerified": 0,
        "totalBookings": null,
        "totalCompleteBookings": null,
        "online": null,
        "providerAddresses": null,
        "IsBlocked": 0,
        "IsVerified": 0,
        "IsDeleted": 0,
        "latitude": "45.2333",
        "longitude": "23.344334",
        "isLoggedIn": 0,
        "timezone": -330,
        "type": 0,
        "isEnable": 1,
        "isEnabled": 1,
        "isOnBoardingDone": 0,
        "teamId": 666,
        "createdAt": "2022-12-13 04:37:08",
        "totalRatedCount": 0,
        "totalRating": 0,
        "emailOtp": null,
        "phoneOtp": null,
        "cityIds": "91",
        "categories": "[{\"categoryId\": 502, \"categoryName\": \"Ear Nose Throat\"}]",
        "subCategories": "[]"
      },

      {
        "providerId": 168,
        "adminId": 1,
        "name": " Dr. Snehal Deshpande",
        "phoneNo": "8765678900",
        "countryCode": "+91",
        "email": "test@qwgmail.com",
        "password": "$2b$10$zUqzNY/EG3ZUqcDDbmTJqexjezxzOd0RiQz0JZlmYUvvSCuZjmrx6",
        "accessToken": null,
        "socialId": null,
        "appVersion": 1,
        "deviceToken": "kdjkj43k4jk343",
        "deviceType": "WEB",
        "socialMode": null,
        "lastOnlineDate": "2022-06-27 10:30:57",
        "profilePicURL": "https://deiry8u6tztoz.cloudfront.net/images/NVWm1656325843778-07w31656325843712drdrsnehaldeshpande.jpg",
        "isNotificationOn": 1,
        "passwordResetToken": null,
        "otpCode": 1759,
        "phoneVerified": 0,
        "totalBookings": null,
        "totalCompleteBookings": null,
        "online": null,
        "providerAddresses": null,
        "IsBlocked": 0,
        "IsVerified": 1,
        "IsDeleted": 0,
        "latitude": "45.2333",
        "longitude": "23.344334",
        "isLoggedIn": 0,
        "timezone": -330,
        "type": 0,
        "isEnable": 1,
        "isEnabled": 1,
        "isOnBoardingDone": 1,
        "teamId": 662,
        "createdAt": "2022-06-27 10:30:57",
        "totalRatedCount": 0,
        "totalRating": 0,
        "emailOtp": null,
        "phoneOtp": null,
        "cityIds": "91",
        "categories": "[{\"categoryId\": 515, \"categoryName\": \"Child Care\"}, {\"categoryId\": 507, \"categoryName\": \"Sexologist\"}, {\"categoryId\": 502, \"categoryName\": \"Ear Nose Throat\"}, {\"categoryId\": 505, \"categoryName\": \"Eyes Specialist\"}, {\"categoryId\": 506, \"categoryName\": \"Hair Specialist\"}]",
        "subCategories": "[]"
      },
      {
        "providerId": 167,
        "adminId": 1,
        "name": "Dr. Shashidhar TB",
        "phoneNo": "8360739431",
        "countryCode": "+91",
        "email": "test@artemis.com",
        "password": "$2b$10$TukZlqZoCb6ESQoK4TG3pOvCrxwRmkfkmeIMlJ9qZfQ5k.nMFGhHm",
        "accessToken": null,
        "socialId": null,
        "appVersion": 1,
        "deviceToken": "kdjkj43k4jk343",
        "deviceType": "WEB",
        "socialMode": null,
        "lastOnlineDate": "2022-06-20 10:18:39",
        "profilePicURL": "https://deiry8u6tztoz.cloudfront.net/images/Vq8n1656325281837-EhZ716563252817741drdrshashidhartb.jpg",
        "isNotificationOn": 1,
        "passwordResetToken": null,
        "otpCode": 9517,
        "phoneVerified": 0,
        "totalBookings": null,
        "totalCompleteBookings": null,
        "online": null,
        "providerAddresses": null,
        "IsBlocked": 0,
        "IsVerified": 0,
        "IsDeleted": 0,
        "latitude": "45.2333",
        "longitude": "23.344334",
        "isLoggedIn": 0,
        "timezone": -330,
        "type": 1,
        "isEnable": 1,
        "isEnabled": 1,
        "isOnBoardingDone": 0,
        "teamId": 661,
        "createdAt": "2022-06-20 10:18:39",
        "totalRatedCount": 0,
        "totalRating": 0,
        "emailOtp": null,
        "phoneOtp": null,
        "cityIds": "91",
        "categories": "[{\"categoryId\": 502, \"categoryName\": \"Ear Nose Throat\"}]",
        "subCategories": "[]"
      },
      {
        "providerId": 166,
        "adminId": 1,
        "name": " Dr. Dilpreet Bajwa",
        "phoneNo": "8360739432",
        "countryCode": "+91",
        "email": "ttest@artemis.com",
        "password": "$2b$10$VQaSAk6Rris8ZxZuj/OTEeqPxOLEFFHqD8hb37dh1Ng4v/cEFUBHS",
        "accessToken": null,
        "socialId": null,
        "appVersion": 1,
        "deviceToken": "kdjkj43k4jk343",
        "deviceType": "WEB",
        "socialMode": null,
        "lastOnlineDate": "2022-06-20 10:17:38",
        "profilePicURL": "https://deiry8u6tztoz.cloudfront.net/images/DJ5L1655720248491-O8Md1655720248429drdilpreetbajwa.jpg",
        "isNotificationOn": 1,
        "passwordResetToken": null,
        "otpCode": 3485,
        "phoneVerified": 0,
        "totalBookings": null,
        "totalCompleteBookings": null,
        "online": null,
        "providerAddresses": null,
        "IsBlocked": 0,
        "IsVerified": 0,
        "IsDeleted": 0,
        "latitude": "45.2333",
        "longitude": "23.344334",
        "isLoggedIn": 0,
        "timezone": -330,
        "type": 1,
        "isEnable": 1,
        "isEnabled": 1,
        "isOnBoardingDone": 0,
        "teamId": 660,
        "createdAt": "2022-06-20 10:17:38",
        "totalRatedCount": 0,
        "totalRating": 0,
        "emailOtp": null,
        "phoneOtp": null,
        "cityIds": "91",
        "categories": "[{\"categoryId\": 502, \"categoryName\": \"Ear Nose Throat\"}]",
        "subCategories": "[]"
      }
    ];


  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    data: [],
    count: 0,
    isEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    search: "",
    current: 1,
    total: 0,
  });

  const handlePaginationChange = ({ current, pageSize, total }) => {
    updatePagination({
      ...pagination,
      current: current,
      pageSize,
      total,
      skip: (current - 1) * pageSize,
    });
  };







  const handleModalClose = () => {
    updateModalControls({
      isVisible: false,
      data: {},
      type: "",
    });
  };

  const openAddStoreCategoryModal = () => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      type: "ADD_CATEGORY",
    });
  };

  const openEditStoreCategoryModal = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      data,
      type: "EDIT_CATEGORY",
    });
  };

  const getSuperCategories = (pageSize, current) => {
    updateTableControls({
      ...tableControls,
      isLoading: false,
      isEmpty: false,
      data: ServiceProviderData(),
      count: 20,
    });
    updatePagination({
      ...pagination,
      total: 20,
    });

  };

  const handleStoreCategoryAdd = (data) => {

    updateModalControls({
      ...modalControls,
      isLoading: false,
      isVisible: false,
    });


  };

  const handleStoreCategoryEdit = (data) => {
    alert("Edit")
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });


  };

  const tableActions = (data, deleteItem) => {
    alert("tableActions")

    updateTableControls({
      ...tableControls,
      isLoading: false,
      isEmpty: false,
    });

  };
  const deleteAction = useConfirm(tableActions, "Added Store category will be deleted !", "Delete Store category ?");

  useEffect(() => {
    getSuperCategories();
  }, [pagination.current, pagination.pageSize, searchDelayed]);

  const columns = [
    {
      fixed: "left",
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.sNo" }),
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "profilePicURL",
      title: "Image",
      render: (i) => <CustomImage isTable src={i} />,
    },
    {
      fixed: "left",
      dataIndex: "name",
      title: "Name",
    },
    {
      dataIndex: "email",
      title: "E-Mail",

    },

    {
      dataIndex: "phoneNo",
      title: "Phone Number",
    },
    {
      dataIndex: "type",
      title: "Type",
      render: (i) => {
        return i ? "Individual" : "Agency";
      },
    },

    {
      dataIndex: "categories",
      title: "Categories",
      render: (i) => {
        return JSON.parse(i).map((obj) => {
          return (obj.categoryName + ", ")

        });
      },
    },

    {
      title: "Action",
      fixed: "right",
      render: (item) => {
        return (
          <Dropdown overlay={getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const getMenu = (item) => {
    return (

      // {/* <DelayedInput /> */}
      <Menu>
        <Menu.Item
          onClick={() => {
            openEditStoreCategoryModal(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.edit" })}
        </Menu.Item>
        {item.isEnabled == 0 && (
          <Menu.Item
            onClick={() => {
              tableActions(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.enable" })}
          </Menu.Item>
        )}
        {item.isEnabled == 1 && (
          <Menu.Item
            onClick={() => {
              tableActions(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.disable" })}
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            deleteAction(item, true);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>

    );
  };



  return (
    <div className={styles.wrapper}>
      {true && (
        <StoreCategoriesModal
          // isVisible={true}
          isVisible={modalControls.isVisible}
          onClose={handleModalClose}
          onCloseIconClick={handleModalClose}
          modalToShow={modalControls.type}
          data={modalControls.data}
          addCategory={handleStoreCategoryAdd}
          editCategory={handleStoreCategoryEdit}
          isLoading={modalControls.isLoading}
        />
      )}
      <Header title="Service Provider" />

      <div className={styles.content}>
        <h1> </h1>
        <div className={styles.menu} style = {{justifyContent:"space-between"}}>
          <div>
              <DelayedInput
               
                delay={500}
                minLength={3}
                size="medium"
                prefixIcon={<SearchOutlined />}
                placeholder= "Name/Email/Phone no"
              />
            </div>
          <header style={{ display: "flex", gap: "1rem", }}>

            

            <div className={styles.lowerMenuDropdown}>
              <Label value="City" horizontal />
              <Select
          
                style={{ width: "150px" }}
                showSearch
                placeholder= "City"
            
               
              >
                <Option

                  value={"Delhi"}
                >{`Delhi`}</Option>
                <Option

                  value={"Noida"}
                >{`Noida`}</Option><Option

                  value={"India"}
                >{`India`}</Option><Option

                  value={"Gurugram"}
                >{`Gurugram`}</Option>
                
              </Select>
            </div>

     <section className={styles.menuRight} >


            <Button
              type="primary"
              shape={undefined}
              size="medium"
              disabled={props.isAddDisabled}
              onClick={openAddStoreCategoryModal}
            >
              Add Service Provider
            </Button>
          </section>
          </header>

         
        </div>

        <CustomTable
          isEmpty={tableControls.isEmpty}
          columns={columns}
          isLoading={tableControls.isLoading}
          dataSource={ProviderData}
          editItem={openEditStoreCategoryModal}
          deleteItem={tableActions}
          toggleItem={tableActions}
          onChange={handlePaginationChange}
          pagination={pagination}
          currentPage={pagination.current}
          recordsPerPage={pagination.pageSize}
        />


      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAddDisabled: state.user.isAddDisabled,
});
export default connect(mapStateToProps)(StoreCategories);
