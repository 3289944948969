import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { Icon, Placeholder, Label } from "../../components/common";
import { YearlyChart, HourlyChart, OverviewCard } from "./components";
import axios from "../../shared/api/axiose";
import "./dashboard-container.scss";
import { checkRtl } from "../../shared/utils/rtl";
import Validations from "../../shared/validations/Validations";
import { DashboardData } from "../../FakeData";
import { useHistory } from "react-router-dom";
import { DatePicker, Select, Button, Spin } from "antd";
import CustomerChart from "./components/yearly-chart/customer-chart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

const styles = {
  wrapper: "dashboard__wrapper",
  chartsContainer: "dashboard__charts-container",
  overviewContainer: "dashboard__overview-container",
  content: "dashboard__content",
  filterCont: "dashboard__filterCont",
  FilterBtn: "dashboard__FilterBtn",

  card: {
    countHeader: "dashboard__overview-card__count-header",
    header: "dashboard__overview-card__header",
    headerItem: "dashboard__overview-card__icon",
    footer: "dashboard__overview-card__footer",
    wrapper: "dashboard__overview-card__wrapper",
    content: "dashboard__overview-card__content",
  },
};

function Dashboard(props) {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const intl = useIntl();
  const history = useHistory();
  const [timeFrameFilterValue, setTimeFrameFilterValue] = useState();
  const [obj, setObj] = useState(null);
  const [data, setData] = useState(null);
  const [loadingStates, setLoadingStates] = useState([true, true, true, true]);
  const handleFilter = (val) => {
    console.log("handleFilter func", val);
    setTimeFrameFilterValue({
      ...timeFrameFilterValue,
      valueKey: val,
    });
  };
  const [dashboardData, updateDashboardData] = useState({
    isLoading: true,
    androidCustomers: "~",
    iosCustomers: "~",
    totalCustomer: "~",
    totalDrivers: "~",
    totalPicker: "~",
    totalStores: "~",
    todayOrders: "~",
    activeStores: "~",
    overAllTotalOrders: "~",
    overAllFailedOrders: "~",
    onlineCustomers: "~",
    todayCompletedOrders: "~",
    yearlySales: [],
    hourlySales: [],
  });

  const filterOption = [
    {
      id: 1,
      text: "Current Week",
    },
    {
      id: 2,
      text: "Current Month",
    },
    {
      id: 3,
      text: "Last 6 Months",
    },
    {
      id: 4,
      text: "Last 1 Year",
    },
    {
      id: 5,
      text: "Custom Date Range",
    },
  ];

  const dashboardCard = [
    {
      title: "Total revenue",
      icon: "MONEY",
      key: 0,
      totalNo: 0,
    },
    {
      title: "Service Booking",
      icon: "PRODUCT",
      totalNo: 0,
      key: 1,
    },

    {
      title: "Completed Services",
      icon: "REVIEWS",
      totalNo: 0,
      key: 2,
    },
    {
      title: " Total Number of Customers",
      icon: "USER",
      totalNo: 0,
      key: 4,
    },
  ];

  const fetchDataNo = async () => {
    try {
      setLoadingStates([true, true, true, true]);
      const response = await axios.get("/admins/reports/dashboard");
      setData(response.data.data);
      setLoadingStates([false, false, false, false]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRedirect = (key) => {
    switch (key) {
      case 0:
        history.push("/home/payments");
        break;
      case 1:
        history.push("/home/service-booking");
        break;
      case 2:
        history.push("/home/service-booking");
        break;
      case 3:
        history.push("/home/services");
        break;
      case 4:
        history.push("/home/customers");

        break;

      default:
        break;
    }
  };

  const formatNullOrEmpty = (field) => {
    if (field === null || field === undefined) {
      return 0;
    } else return field;
  };

  const getDashboardData = () => {
    let params = {};
    if (props.panelType == 1) {
      params.branchId = props.branchId;
    }
    updateDashboardData({
      ...DashboardData(),
      isLoading: false,
    });
  };

  useEffect(() => {
    fetchDataNo();
    getDashboardData();
    const interval = setInterval(getDashboardData, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (dashboardData.isLoading) {
    return <Placeholder full type="loading" />;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1></h1>

        <div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "0.3rem" }}>
            {dashboardCard.map((obj, index) => {
              const isLoading = loadingStates[index];
              const cardData = data ? data[Object.keys(data)[index]] : null;

              return (
                <div
                  key={obj.key}
                  className={styles.card.wrapper}
                  onClick={() => handleRedirect(obj?.key)}
                >
                  <div className={styles.card.headerItem}>
                    <Icon name={obj?.icon} size="medium" />
                  </div>

                  <div className={styles.card.content}>
                    <div className={styles.card.header}>{obj?.title}</div>
                    {isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "80px",
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    ) : (
                      <div className={styles.card.footer}>
                        {cardData !== null ? cardData : <Spin size="small" />}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.chartsContainer}>
          <YearlyChart
            name={props.enterprice.name}
            color={props.enterprice.color}
            yearData={dashboardData.yearlySales}
            isLoading={dashboardData.isLoading}
            header="Total Sales"
          />
          <CustomerChart
            name={props.enterprice.name}
            color={props.enterprice.color}
            yearData={dashboardData.yearlySales}
            isLoading={dashboardData.isLoading}
            header="Customer Growth"
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  enterprice: state.user.enterprice,
  color: state.user.enterprice.color,
  isRTL: checkRtl(state.user.locale),
  panelType: state.user.enterprice.panelType,
  pickerFlow: state.user.enterprice.pickerFlow,
  branchId: state.user.userInfo.branchId,
});

export default connect(mapStateToProps)(Dashboard);
