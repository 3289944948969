import React from "react";
import Cancel from "../../assets/images/cancel-dragon.png";
import "./CheckoutSuccess.css";

const CheckoutFailed = () => {
  return (
    <div className="container">
      <div className="white-box">
        <img src={Cancel} alt="Thumbs Up" className="image" />
        <div className="text">
          <span className="red-cross">&#10006; opps... </span>
          <br />
          Something went wrong
        </div>
      </div>
    </div>
  );
};

export default CheckoutFailed;
