import React, { useState, useEffect, useRef } from "react";
import { Input, Select, Dropdown, Button, Upload, message } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import CustomUpload from "./CustomUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import {
  Header,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../components/common";

import { UploadOutlined } from "@ant-design/icons";
import Validations from "../../shared/validations/Validations";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../shared/api/axiose";
import userActions from "../../redux/user-info/user-actions";
import {
  PAYMENT_METHODS_SETTINGS,
  PAYMENT_METHODS_SETTINGS_MAPPING,
  FLOW_STEP_MAPPING,
} from "../../shared/constants/orders";
import "./admin-settings.scss";
import {
  removeEmptyKeys,
  nestedParseJSON,
} from "../../shared/utils/object-manipulation";

import { AdminSetting } from "../../FakeData";

import { getAdminInfo } from "../../shared/utils/local-storage";

const styles = {
  wrapper: "oyelabs__large-inv__admin-settings__wrapper",
  content: "oyelabs__large-inv__admin-settings__content",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  formHeader: "oyelabs__large-inv__admin-settings__form-header",
  formWrapper: "oyelabs__large-inv__admin-settings__form-wrapper",
  imageItem: "oyelabs__large-inv__admin-settings__image-item",
};

function AdminSettings(props) {
  const imageContainerRef = useRef(null);
  const [settings, setSettings] = useState({});
  const [speechRecognition, setSpeechRecognition] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );
  const [imageURLSmall, changeImageURLSmall] = useState(
    Validations.validateTextField("")
  );

  useEffect(() => {
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      const recognition = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();

      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = "en-US";

      recognition.onstart = () => {
        setIsListening(true);
      };

      recognition.onend = () => {
        setIsListening(false);
      };

      recognition.onresult = (event) => {
        const spokenText = event.results[0][0].transcript;
        const newSettings = { ...settings, aboutCompany: spokenText };
        setSettings(newSettings);
      };

      setSpeechRecognition(recognition);
    }
  }, []);

  const handleSpeechRecognition = () => {
    if (speechRecognition) {
      if (!isListening) {
        speechRecognition.start();
        setIsActive(!isActive);
      } else {
        speechRecognition.stop();
      }
    }
  };

  useEffect(() => {
    axios
      .get("/admins/settings")
      .then((response) => {
        const responseData = response.data.data;
        setSettings(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
        setIsLoading(false);
      });
  }, []);

  const handleCancellationChargeChange = (event) => {
    const newSettings = {
      ...settings,
      cancelationChargePercentage: event.target.value,
    };
    setSettings(newSettings);
  };
  const handleLogoImageChange = (image) => {
    const newSettings = { ...settings, logoImage: image };
    setSettings(newSettings);
  };

  const handleFavIconChange = (image) => {
    const newSettings = { ...settings, favIcon: image };
    setSettings(newSettings);
  };
  const handlePaymentMethodsChange = (value) => {
    const newSettings = { ...settings, paymentMethods: value };
    setSettings(newSettings);
  };

  const handleAboutCompanyChange = (event) => {
    const newSettings = { ...settings, aboutCompany: event.target.value };
    setSettings(newSettings);
  };

  const handleSubmit = () => {
    const {
      id,
      createdAt,
      updatedAt,
      availablePaymentMethods,
      serviceTypeTag, // Exclude this field from the payload
      ...payload
    } = settings;

    axios
      .post("/admins/settings", payload)
      .then((response) => {
        toast.success("Settings updated successfully");
      })
      .catch((error) => {
        toast.error("Error updating settings");
      });
  };

  return (
    <div className={styles.wrapper}>
      <Header title="app.pageTitle.adminSettings" />
      <Panel
        loaderStyle={{
          height: "76vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        isLoading={isLoading}
        className="custom-section"
      >
        <div className={styles.content}>
          <div className={styles.formWrapper}>
            <div className={styles.formHeader}>Payment</div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                Cancellation Charge in Percentage %
                <Input
                  placeholder="Cancellation Charge Percentage"
                  value={settings.cancelationChargePercentage || ""}
                  onChange={handleCancellationChargeChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              Payment Methods
              <Select
                mode="multiple"
                placeholder="Payment Methods"
                value={settings.paymentMethods || []}
                onChange={handlePaymentMethodsChange}
                style={{ width: "22rem" }}
              >
                <Select.Option value="CARD">CARD</Select.Option>
                {/* Add more options as needed */}
              </Select>
            </div>
          </div>
          <div className={styles.formHeader}>About</div>
          <div className={styles.formGroup}>
            <div className={styles.formItem} style={{ maxWidth: "30%" }}>
              <div className={styles.formHeader}>
                Upload New Logo (Optional)
              </div>
              <CustomImage src={settings.logoImage || ""} />

              <UploadButton
                // disabled
                type="fileUpload"
                parentRef={imageContainerRef}
                resultType="url"
                accept="image/*"
                id="913fjhiu4ng2vri"
                text="Upload Image(300x300)"
                onChange={handleLogoImageChange}
              />
            </div>
          </div>

          <div className={styles.formGroup}>
            <div className={styles.formItem} style={{ maxWidth: "30%" }}>
              <div className={styles.formHeader}>
                Upload New Fav Icon (Optional)
              </div>
              <CustomImage src={settings.favIcon || ""} />
              <UploadButton
                // disabled
                type="fileUpload"
                parentRef={imageContainerRef}
                resultType="url"
                accept="image/*"
                id="913fjhiu4ng2vr3"
                text="Upload Image(300x300)"
                onChange={handleFavIconChange}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <div className={styles.formHeader}>Enter About</div>
              <Input.TextArea
                rows={4}
                placeholder="About Company"
                value={settings.aboutCompany || ""}
                onChange={handleAboutCompanyChange}
              />
              <FontAwesomeIcon
                icon={faMicrophone}
                onClick={handleSpeechRecognition}
                className={`microphone-icon ${isActive ? "active" : "stop"}`}
              />
            </div>
          </div>

          <div className={styles.formAction}>
            <Button type="primary" onClick={handleSubmit}>
              Save Settings
            </Button>
          </div>
        </div>
      </Panel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.user.locale,
  enabledLanguages: state.user.enterprice.enabledLanguages
    ? state.user.enterprice.enabledLanguages.split(",")
    : ["en"],
  userInfo: state.user.userInfo,
});

export default connect(mapStateToProps)(AdminSettings);
