import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select, Divider, Space } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import classNames from "classnames";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../../components/common";
import axiosConfig from "../../../../shared/api/axiose"; // base Url : http://52.26.241.33:3002
import Validations from "../../../../shared/validations/Validations";
import { multiSort } from "../../../../shared/utils/object-manipulation";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-promo-modal.scss";
import { PlusOutlined } from "@ant-design/icons";
import { getAdminInfo } from "../../../../shared/utils/local-storage";

const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formModal_wrapper: "Questionary_add_edit_modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "Questionary_add_edit_modal__image-container",
  imageContainerImage: "Questionary_add_edit_modal__image-container__image",
  imageContainerForm: "Questionary_add_edit_modal__image-container__form",
  spanImp: "Questionary_add_edit_modal__span-img",
  disabledColor: "disabledColor",
};

const errorText = "Field cannot be empty";

const Tags = [
  {
    value: "1",
    text: "Cleaning",
  },
  {
    value: "2",
    text: "Service provider",
  },
  {
    value: "3",
    text: "Customer",
  },
  {
    value: "4",
    text: "Enquire ",
  },
  {
    value: "5",
    text: "Document",
  },
  {
    value: "6",
    text: "PDF",
  },
  {
    value: "7",
    text: "Washing",
  },
  {
    value: "8",
    text: "Testing Tags",
  },
];

function AddEditPromoContainer({
  isVisible,
  modalType,
  onCloseIconClick,
  data,
  editCategory,
  isLoading,
  getQuestionasData,
  questionType,
  getAllVideos,

  ...props
}) {
  console.log("AddEditPromoContainer", data);
  console.log("modalType =>", modalType);

  /** Instaservice States */
  const [traingVideoData, updateTraingVideoData] = useState({
    video_url: Validations.validateTextField(""),
    serviceType: Validations.validateMultiSelect([]),
    moduleType: Validations.validateMultiSelect([]),
  });
  const intl = useIntl();
  const handleFieldChange = () => {};

  const [isPristine, togglePristine] = useState(true);
  const [tagSelect, updateTagSelect] = useState(
    Validations.validateMultiSelect([])
  );

  const [question, updateQuestion] = useState(
    Validations.validateTextField(undefined)
  );
  const [inputOption, updateInputOption] = useState(
    Validations.validateTextField(undefined)
  );
  const [question_Type, updateQuestionType] = useState(
    Validations.validateTextField(undefined)
  );

  // console.log("question_Type = > ", question_Type)
  const [allTags, setAllTages] = useState(Tags);
  const [addTageName, setaddTageName] = useState("");

  /** Instaservice States */

  const onNameChange = (event) => {
    setaddTageName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    let tagobj = {
      value: allTags.length + 1,
      text: addTageName,
    };

    setAllTages([...allTags, tagobj]);

    setaddTageName("");
  };

  const resetForm = () => {
    updateTraingVideoData({
      ...traingVideoData,
      moduleType: Validations.validateMultiSelect([]),
      serviceType: Validations.validateMultiSelect([]),
      video_url: Validations.validateTextField(""),
    });
    togglePristine(true);
  };

  const setForm = () => {
    // debugger
    console.log("setForm=>", data);
    //     {
    //     "id": 1,
    //     "moduleType": "Business Owner",
    //     "title": "Cleaning Service",
    //     "videoLink": "https://www.youtube.com/watch?v=z8CYDyFqzp0",
    //     "status": true,
    //     "createdAt": "2023-04-14T11:13:32.873Z",
    //     "updatedAt": "2023-04-14T11:13:32.873Z"
    // }

    updateTraingVideoData({
      ...traingVideoData,
      moduleType: Validations.validateMultiSelect([data?.moduleType]),
      serviceType: Validations.validateMultiSelect([data?.title]),
      video_url: Validations.validateTextField(data?.videoLink),
    });
  };

  useEffect(() => {
    if (modalType === "ADD") {
      resetForm();
    } else {
      setForm();
    }
  }, [modalType, data]);

  const isFormValid = () => {
    let isValid = true;

    isValid =
      isValid &&
      traingVideoData.moduleType.isValid &&
      traingVideoData.serviceType.isValid &&
      traingVideoData.video_url.isValid;

    return isValid;
  };

  const closeModal = () => {
    onCloseIconClick();
    resetForm();
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      return toastr.warning(
        intl.formatMessage({ id: "app.message.formCheck" })
      );
    }
    if (modalType === "ADD") {
    } else if (modalType === "EDIT") {
    }
  };

  const handleSelectService = (val) => {
    console.log("handleSelectService => ", val);
    updateTraingVideoData({
      ...traingVideoData,
      serviceType: Validations.validateMultiSelect([val]),
    });
  };
  const handleSelectModuleType = (val) => {
    updateTraingVideoData({
      ...traingVideoData,
      moduleType: Validations.validateMultiSelect([val]),
    });
  };
  const handleAddVideoURL = (e) => {
    updateTraingVideoData({
      ...traingVideoData,
      video_url: Validations.validateTextField(e.target.value),
    });
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        modalType === "ADD"
          ? "Add Training Video"
          : modalType === "EDIT"
          ? "Edit Training Video"
          : "View Training Details"
      }
      action={
        modalType !== "VIEW" ? (
          <>
            {" "}
            <Button type="default" shape={undefined} onClick={closeModal}>
              {intl.formatMessage({ id: "app.button.cancel" })}
            </Button>
            <Button
              // loading={isLoading}
              type="primary"
              shape={undefined}
              onClick={formAction}
            >
              {intl.formatMessage({ id: "app.button.submit" })}
            </Button>
          </>
        ) : (
          ""
        )
      }
      isVisible={isVisible}
    >
      <Panel
        isModal
        className={classNames({
          [styles.formModal]: true,
          [styles.formModal_wrapper]: true,
        })}
      >
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Training Video url" />
            <Input.TextArea
              name="video_url"
              disabled={modalType === "VIEW"}
              placeholder="Paste Video URL here !"
              value={traingVideoData?.video_url?.value}
              onChange={(e) => handleAddVideoURL(e)}
            />
            {!isPristine && !traingVideoData?.video_url?.isValid && (
              <ErrorMessage text={traingVideoData?.video_url?.errMsg} />
            )}
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Module Type" />
            <Select
              placeholder="moduleType"
              disabled={modalType === "VIEW"}
              value={traingVideoData?.moduleType?.value}
              onChange={handleSelectModuleType}
            >
              {questionType.map((item) => (
                <Option key={item.value} value={item.text}>
                  {item.text}
                </Option>
              ))}
            </Select>
            {!isPristine && !traingVideoData?.moduleType?.isValid && (
              <ErrorMessage selectOption />
            )}
          </div>

          <div className={styles.formItem}>
            <Label value="Services" />
            <Select
              disabled={modalType === "VIEW"}
              showSearch
              placeholder="Select Service"
              optionFilterProp="children"
              value={traingVideoData?.serviceType?.value}
              onChange={handleSelectService}
            >
              {props.serviceData.map((item) => (
                <Option key={item.id} value={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
            {!isPristine && !traingVideoData?.serviceType?.isValid && (
              <ErrorMessage selectOption />
            )}
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}
const mapState = (state) => ({
  // branchId: state.user.userInfo.branchId,
  // panelType: state.user.enterprice.panelType,
});
export default connect(mapState)(AddEditPromoContainer);
