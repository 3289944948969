import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { CustomModal, Panel, ErrorMessage } from "../../../components/common";
import { Tag } from "antd";
import "./view-user.scss";

import sidebarData from "../../../shared/constants/sidebar-data.json";
import axiosConfig from "../../../shared/api/axios_config";

const styles = {
  wrapper: "view-user__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  label: "view-user__label",
  value: "view-user__value",
  row: "view-user__row",
};
function ViewUser(props) {
  const intl = useIntl();
  console.log("ViewUser=>", props.data);
  useEffect(() => {
    // if (props.isVisible) {
    //   addTitleToRoles(props.data.roles);
    // }
    if (props?.data?.id) {
      getAdminDetails(props?.data?.id);
    }
  }, [props?.data?.id]);
  const [allRoles, updateAllRoles] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  });

  const [rolesArr, setRolesArr] = useState([]);

  console.log("rolesArr=>", rolesArr);

  const getAdminDetails = (id) => {
    axiosConfig
      .get(`/admins/${id}`)
      .then((res) => {
        // console.log("getAdmin res=>", res?.data?.data?.roles);
        // access_level
        let roles = res?.data?.data?.roles;
        let rolesArr = [];
        roles.map((role, index) => {
          // rolesObj[index] = [{ name: role.access_level }];
          let obj = { name: role.access_level };
          rolesArr.push(obj);
        });
        // console.log("rolesArr=>", rolesArr);
        setRolesArr(rolesArr);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const addStatusToSidebarRoles = (lsRoles) => {
    lsRoles = lsRoles.reduce((acc, val) => {
      if (val.status) {
        acc.push(val.menuId);
      }
      return acc;
    }, []);
    return sidebarData.map((i) => {
      if (lsRoles.indexOf(i.menuId) !== -1) {
        i.status = 1;
      } else {
        i.status = 0;
      }
      return i;
    });
  };

  const getFilteredRoles = (roles, sendAll, onlyActive) => {
    return roles.reduce(
      (acc, val) => {
        if (
          (val.menuId == 115 || val.menuId == 15) &&
          !props.goodFleetEnabled
        ) {
          return acc;
        }
        if (!props.pickerFlow && val.menuId == 8) {
          return acc;
        }
        if (onlyActive) {
          if (val.status == 1) {
            if (sendAll) {
              acc[val.verticalType].push(val);
            } else {
              acc[val.verticalType].push(val.menuId);
            }
          }
        } else {
          if (sendAll) {
            acc[val.verticalType].push(val);
          } else {
            acc[val.verticalType].push(val.menuId);
          }
        }
        return acc;
      },
      { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
    );
  };
  // const addTitleToRoles = (lsRoles) => {
  //   const alamanac = [...sidebarData, ].reduce((acc, val) => {
  //     acc[val.menuId] = val;
  //     return acc;
  //   }, {});

  //   const res = lsRoles.filter(i => alamanac[i.menuId] !== undefined).map((i) => ({
  //     ...i,
  //     name: alamanac[i.menuId].name,
  //   }));
  //   // updateAdminRoles(res);
  //   // debugger;
  //   updateAllRoles(getFilteredRoles(res, true, true));
  //   return res;
  // };
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={props.onCloseIconClick}
      header="app.pageTitle.viewSubAdmin"
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.name" })}
            </div>
            <div className={styles.value}>
              {props?.data?.name ? props?.data?.name : "Not Found"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.email" })}
            </div>
            <div className={styles.value}>
              {props?.data?.email ? props?.data?.email : "Not Found"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.userType" })}
            </div>
            <div className={styles.value}>
              {props?.data?.userType ? props?.data?.userType : "Not Found"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.countryCode" })}
            </div>
            <div className={styles.value}>
              {props?.data?.countryCode
                ? props?.data?.countryCode
                : "Not Found"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              {intl.formatMessage({ id: "app.field.phoneNumber" })}
            </div>
            <div className={styles.value}>
              {props?.data?.phone ? props?.data?.phone : "Not Found"}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Roles</div>
            <div className={styles.value}>
              {/* {allRoles[0].map((i) => (
                <Tag>{intl.formatMessage({ id: i.name })}</Tag>
              ))}

              {allRoles[0].length === 0 && <Tag>N/A</Tag>} */}
              {rolesArr.map((i) => (
                <Tag>{intl.formatMessage({ id: i.name })}</Tag>
              ))}

              {rolesArr.length === 0 && <Tag>N/A</Tag>}
            </div>
          </div>
          {/* {
            props.enabledVerticals ? props.enabledVerticals.map((ev) => {
              return(
                <div>
                  {ev == 0 && (
                    <div className={styles.row}>
                      <div className={styles.label}>
                        {intl.formatMessage({ id: "app.field.groceryPanelPrivilages" })}
                      </div>
                    </div>
                  )}
                  {ev == 0 && (
                    <div>
                    {allRoles[0].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
        
                    {allRoles[0].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev == 1 && (
                    <div className={styles.row}>
                      <div className={styles.label}>
                        {intl.formatMessage({ id: "app.field.foodPanelPrivilages" })}
                      </div>
                    </div>
                  )}
                  {ev ==1 && (
                    <div>
                    {allRoles[1].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[1].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev ==2 && (
                    <div className={styles.row}>
                      <div className={styles.label}>
                        {intl.formatMessage({
                          id: "app.field.conveniencePanelPrivilages",
                        })}
                      </div>
                    </div>
                  )}
                  {ev ==2 && (
                    <div>
                    {allRoles[2].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[2].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev ==3 && (
                    <div className={styles.row}>
                      <div className={styles.label}>
                        {intl.formatMessage({ id: "app.field.medicinePanelPrivilages" })}
                      </div>
                    </div>
                  )}
                  {ev ==3 && (
                    <div>
                    {allRoles[3].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[3].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev ==4 && (
                    <div className={styles.row}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: "app.field.liquorPanelPrivilages" })}
                    </div>
                  </div>
                  )}
                  {ev ==4 && (
                    <div>
                    {allRoles[4].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[4].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev ==5 && (
                    <div className={styles.row}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: "app.field.smallPanelPrivilages" })}
                    </div>
                  </div>
                  )}
                  {ev ==5 && (
                    <div className={styles.row}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: "app.field.smallPanelPrivilages" })}
                    </div>
                  </div>
                  )}
                  {ev ==5 && (
                    <div>
                    {allRoles[5].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[5].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                  {ev == 6 && (
                    <div className={styles.row}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: "app.field.largePanelPrivilages" })}
                    </div>
                  </div>
                  )}
                  {ev == 6 && (
                    <div>
                    {allRoles[6].map((i) => (
                      <Tag>{intl.formatMessage({ id: i.name })}</Tag>
                    ))}
                    {allRoles[6].length === 0 && <Tag>N/A</Tag>}
                  </div>
                  )}
                </div>
              )
            }):""
          } */}
        </div>
      </Panel>
    </CustomModal>
  );
}

export default ViewUser;
