import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import {
  CustomModal,
  Panel,
  ErrorMessage,

  Label,
} from "../../../../components/common";
import Validations from "../../../../shared/validations/Validations";
import countryCodes from "../../../../shared/constants/countries.json";

import { StandaloneSearchBox, Marker } from "@react-google-maps/api";
import GoogleMap from "../../../../components/google-map/google-map";

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
};
const { Option } = Select;

function AddCustomerNotes(props) {
  const intl = useIntl();
  const [firstName, changeFirstName] = useState(
    Validations.validateTextField("")
  );
  // const [lastName, changeLastName] = useState(
  //   Validations.validateTextField("")
  // );
  const [email, changeEmail] = useState(Validations.validateEmail(""));
  const [phoneNumber, changePhoneNumber] = useState(
    Validations.validatePhoneNumber("")
  );
  const [phoneCountryCode, changePhoneCountryCode] = useState(
    Validations.validateTextField(undefined)
  );
  const [walletAmount, changeWalletAmount] = useState(
    Validations.validateNumericValue("")
  );

  const [password, updatePassword] = useState(Validations.validatePassword(""));
  const [isPristine, togglePristine] = useState(true);
  const [address, updateAddress] = useState("");
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const locationSearchRef = useRef(null);
  const [isAddressLoading, changeIsAddressLoading] = useState(false);

  const [latLng, updateLatLng] = useState({
    lat: null,
    lng: null,
  });
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const setForm = () => {
    togglePristine(true);
    changeFirstName(Validations.validateTextField(props.data.firstName));
    // changeLastName(Validations.validateTextField(props.data.lastName))
    changePhoneCountryCode(
      Validations.validateTextField(props.data.countryCode)
    );
    changeEmail(Validations.validateEmail(props.data.email));
    changePhoneNumber(Validations.validatePhoneNumber(props.data.phoneNo));
    changeWalletAmount(
      Validations.validateNumericValue(
        props.data.walletAmount ? props.data.walletAmount : 0
      )
    );
    updatePassword(Validations.validatePassword(""));
    updateAddress(Validations.validateTextField(props.data.customerAddresses));
    updateLatLng({
      lat: parseFloat(props.data.latitude),
      lng: parseFloat(props.data.longitude),
    });
    if (mapRef.current !== null && markerRef.current !== null) {
      mapRef.current.setCenter(
        // new window.google.maps.LatLngBounds({
        {
          lat: parseFloat(props.data.latitude),
          lng: parseFloat(props.data.longitude),
        }
        // })
      );

      mapRef.current.setZoom(18);
    }
  };
  useEffect(() => {
    if (props.isVisible) {
      setForm();
    }
  }, [props.isVisible]);

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "firstName":
        changeFirstName(Validations.validateTextField(value));
        break;
      // case "lastName":
      //   changeLastName(Validations.validateTextField(value));
      //   break;
      case "phoneNumber":
        changePhoneNumber(Validations.validatePhoneNumber(value));
        break;
      case "email":
        changeEmail(Validations.validateEmail(value));
        break;
      case "walletAmount":
        changeWalletAmount(Validations.validateNumericValue(value));
        break;
      case "password":
        updatePassword(Validations.validatePassword(value));
        break;
      case "address":
        updateAddress(Validations.validateTextField(value));
        break;
      default:
        break;
    }
  };
  const handleCountryCodeChange = (value) => {
    changePhoneCountryCode(
      Validations.validateTextField(value, "Country Code")
    );
  };

  const handleEdit = () => {
    // TODO
    const data = {
      firstName: firstName.value,
      // "lastName": lastName.value ,
      email: email.value,
      phoneNo: phoneNumber.value,
      walletAmount: walletAmount.value,
      latitude: `${latLng.lat}`,
      longitude: `${latLng.lng}`,
      countryCode: phoneCountryCode.value,

      // customerAddresses: address.value,

      // "customerId" : 1 ,
      // "profilePicURL" : image
    };
    if (password.isValid) {
      data.password = password.value;
    }

    props.editCustomer(data);
  };

  const isFormValid = () => {
    let isValid = true;
    isValid =
      firstName.isValid &&
      phoneCountryCode.isValid &&
      // lastName.isValid &&
      phoneNumber.isValid &&
      email.isValid;
    // walletAmount.isValid

    if (password.value) {
      isValid = password.isValid && isValid;
    }

    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const formAction = () => {
    togglePristine(false);
    if (!isFormValid()) {
      console.log(" not working");
      return;
    }
    console.log("working");
    handleEdit();
  };
  const handleSearchPlaceChange = (data) => {
    const places = locationSearchRef.current.getPlaces();
    console.log(places);
    if (places.length) {
      const bounds = new window.google.maps.LatLngBounds();
      updateAddress(Validations.validateTextField(places[0].formatted_address));
      updateLatLng({
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng(),
      });
      places.forEach((place) => {
        if (!place.geometry) {
          console.log("No geometry");
          return;
        }
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      mapRef.current.fitBounds(bounds);
    }
  };
  const handleMarkerLoad = (ref) => {
    markerRef.current = ref;
    mapRef.current.setCenter(ref.position);
    mapRef.current.setZoom(18);
  };

  const handleMapLoad = (ref) => {
    mapRef.current = ref;
  };
  const handleSearchOnLoad = (ref) => {
    locationSearchRef.current = ref;
  };
  const handleMarkerDrag = (marker) => {
    const lat = marker.latLng.lat();
    const lng = marker.latLng.lng();
    mapRef.current.setCenter(marker.latLng);
    changeIsAddressLoading(true);
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCSHHKKFCGHd8jd0_jlhytWadlpFLX6h9k`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.results.length) {
          updateAddress(
            Validations.validateTextField(res.results[0].formatted_address)
          );
        }
        changeIsAddressLoading(false);
      })
      .catch((err) => {
        changeIsAddressLoading(false);
        // toastr.error("Error", "Something Went Wrong");
      });
    updateLatLng({
      lat,
      lng,
    });
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header="Add Customer Notes"
      action={
        <>
          {" "}
          <Button type="default" shape={undefined} onClick={closeModal}>
            <FormattedMessage id="app.field.cancel" />
          </Button>
          <Button
            type="primary"
            loading={props.isLoading}
            shape={undefined}
            onClick={formAction}
          >
            <FormattedMessage id="app.field.submit" />
          </Button>
        </>
      }
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal}>
        <div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <label>{intl.formatMessage({ id: "app.field.firstName" })}</label>
              <Input
                name="firstName"
                placeholder={intl.formatMessage({
                  id: "app.placeholder.firstName",
                })}
                value={firstName.value}
                onChange={handleFieldChange}
              />
              {!isPristine && <ErrorMessage field={firstName} />}
            </div>
        
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <label>{intl.formatMessage({ id: "app.field.email" })}</label>
              <Input
                name="email"
                placeholder={intl.formatMessage({
                  id: "app.placeholder.email",
                })}
                value={email.value}
                onChange={handleFieldChange}
              />
              {!isPristine && <ErrorMessage field={email} />}
            </div>
            <div className={styles.formItem}>
              <label>Update Password</label>
              <Input
                name="password"
                type="password"
                placeholder="Enter New Password"
                value={password.value}
                onChange={handleFieldChange}
              />
              {!isPristine && password.value !== "" && (
                <ErrorMessage field={password} />
              )}
            </div>
          </div>
          <div className={styles.formItem}>
            <Label for="countryCode" value="app.field.countryCode" />
            <Select
              showSearch
              // style={{ marginRight: "1rem" }}
              placeholder={intl.formatMessage({
                id: "app.placeholder.countryCode",
              })}
              onChange={handleCountryCodeChange}
              value={phoneCountryCode.value}
              optionFilterProp="children"
              filterOption={filterOption}
            >
              {countryCodes.map((item, index) => (
                <Option
                  key={index}
                  value={item.dial_code}
                >{`${item.dial_code}(${item.name})`}</Option>
              ))}
            </Select>
            {!isPristine && <ErrorMessage field={phoneCountryCode} />}
          </div>
          <div className={styles.formItem}>
            <label>{intl.formatMessage({ id: "app.field.phoneNumber" })}</label>
            <Input
              name="phoneNumber"
              placeholder={intl.formatMessage({
                id: "app.placeholder.phoneNumber",
              })}
             
              value={phoneNumber.value}
              onChange={handleFieldChange}
            />
            {!isPristine && <ErrorMessage field={phoneNumber} />}
          </div>
         
          {/* <div className={styles.mapFormItem}>
            <div className="googleMap">
              <GoogleMap drawingControl={false} onLoad={handleMapLoad}>
                <StandaloneSearchBox
                  onLoad={handleSearchOnLoad}
                  onPlacesChanged={handleSearchPlaceChange}
                >
                  <Input
                    placeholder="Search location"
                    style={{
                   
                      position: "relative",
                      top: 0,
                      left: "37%",
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `240px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                    }}
                  />
                </StandaloneSearchBox>
                {latLng.lat !== null && (
                  <Marker
                    draggable={true}
                    onDragEnd={handleMarkerDrag}
                    position={latLng}
                    onLoad={handleMarkerLoad}
                  />
                )}
              </GoogleMap>
            </div>
          </div> */}
          {/* <div className={styles.formItem}>
            <label>
              {intl.formatMessage({ id: "app.field.walletAmount" })}
            </label>
            <Input
              name="walletAmount"
              placeholder={intl.formatMessage({
                id: "app.placeholder.walletAmount",
              })}
              value={walletAmount.value}
              onChange={handleFieldChange}
            />
            {!isPristine && <ErrorMessage field={walletAmount} />}
          </div> */}
        </div>
        {/* 
        <div className={styles.formAction}>

        </div> */}
      </Panel>
    </CustomModal>
  );
}

export default AddCustomerNotes;
