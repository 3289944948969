import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import classNames from "classnames";

import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../components/common";
import axios from "../../../shared/api/axiosConfig";
import Validations from "../../../shared/validations/Validations";
import placeholderImg from "../../../assets/images/picker-placeholder.png";
import countryCodes from "../../../shared/constants/countries.json";
import "./../add-edit-picker/add-edit-picker.scss";

const { Option } = Select;

const TRANSPORT_TYPES = [
  { type: 0, name: "Bike" },
  { type: 1, name: "Truck" },
];

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "custom-modal-image-container",
  imageContainerImage: "pickers__add-edit-picker-modal__image-container__image",
  imageContainerForm: "pickers__add-edit-picker-modal__image-container__form",
  spanImp: "pickers__add-edit-picker-modal__span-img",
};

function AddEditUser(props) {
  const intl = useIntl();

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: true,
    data: [],
    notFound: false,
  });

  const imageContainerRef = useRef(null);
  const [isPristine, togglePristine] = useState(true);
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [email, changeEmail] = useState(Validations.validateEmail(""));
  const [password, changePassword] = useState(Validations.validatePassword(""));
  const [phoneNumber, changePhoneNumber] = useState(
    Validations.validatePhoneNumber("")
  );
  const [phoneCountryCode, changePhoneCountryCode] = useState(
    Validations.validateTextField(undefined)
  );
  const [transportType, changeTransportType] = useState(
    Validations.validateNumericValue(undefined)
  );
  const [address, changeAddress] = useState(Validations.validateTextField(""));
  const [description, changeDescription] = useState(
    Validations.validateTextField("")
  );
  const [store, changeStore] = useState(Validations.validateTextField(""));
  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  const resetForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(null));
    changeEmail(Validations.validateEmail(""));
    changePassword(Validations.validatePassword(""));
    changePhoneCountryCode(Validations.validateTextField(undefined));
    changePhoneNumber(Validations.validatePhoneNumber(""));
    changeTransportType(Validations.validateNumericValue(undefined));
    changeStore(Validations.validateTextField(props.branchId));
    changeDescription(Validations.validateTextField(null));
    changeAddress(Validations.validateTextField(null));
    changeImageURL(Validations.validateTextField(null));
  };

  const setForm = () => {
    togglePristine(true);
    changeName(Validations.validateTextField(props.data.name));
    changeEmail(Validations.validateEmail(props.data.email));
    changePassword(Validations.validatePassword(""));
    changePhoneNumber(Validations.validatePhoneNumber(props.data.phoneNo));
    changeDescription(Validations.validateTextField(props.data.description));
    changeAddress(Validations.validateTextField(props.data.address));
    changePhoneCountryCode(
      Validations.validateTextField(+props.data.countryCode)
    );
    changeTransportType(
      Validations.validateNumericValue(props.data.transportType)
    );
    changeImageURL(Validations.validateTextField(props.data.profileImage));
    changeStore(
      Validations.validateTextField(
        JSON.parse(props.data.branches)[0]?.branchId
      )
    );
  };

  useEffect(() => {
    if (!props.isVisible) return;
    if (props.modalToShow === "EDIT_PICKER") {
      setForm();
      //   setForm();
    } else if (props.modalToShow === "ADD_PICKER") {
      resetForm();
    }
  }, [props.isVisible]);

  //mount
  useEffect(() => {
    if (props.panelType == 1) {
      updateStores({
        ...stores,
        selected: props.branchId,
      });
    } else {
      updateStores({
        selected: undefined,
        data: props.stores,
        isLoading: false,
        notFound: false,
      });
    }
  }, [props.stores]);

  const handleFieldChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "email":
        changeEmail(Validations.validateEmail(value));
        break;
      case "password":
        changePassword(Validations.validatePassword(value));
        break;
      case "phoneNumber":
        changePhoneNumber(Validations.validatePhoneNumber(value));
        break;
      case "address":
        changeAddress(Validations.validateAddress(value));
        break;
      case "description":
        changeDescription(Validations.validateTextField(value));
        break;
      default:
        break;
    }
  };

  const handleCountryCodeChange = (value) => {
    changePhoneCountryCode(Validations.validateTextField(value));
  };

  const handleTransportTypeChange = (value) => {
    changeTransportType(Validations.validateNumericValue(value));
  };

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    changeStore(Validations.validateTextField(selected));
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const getData = () => ({
    name: name.value,
    phoneNo: phoneNumber.value,
    countryCode: phoneCountryCode.value.toString(),
    email: email.value,
    password: password.value,
    transportType: transportType.value,
    branchIds: [store.value],
    address: address.value,
    description: description.value ? description.value : "---",
    profileImage: imageURL.value,
  });

  const handleAdd = () => {
    // TODO
    props.addPicker(getData());
  };

  const handleEdit = () => {
    // TODO
    props.editPicker(getData());
  };
  const isFormValid = () => {
    console.log(transportType);
    if (props.modalToShow === "ADD_PICKER") {
      return (
        name.isValid &&
        email.isValid &&
        password.isValid &&
        phoneCountryCode.isValid &&
        phoneNumber.isValid &&
        transportType.isValid &&
        address.isValid &&
        store.isValid &&
        imageURL.isValid
      );
    } else if (props.modalToShow === "EDIT_PICKER") {
      let isValid = true;
      isValid =
        isValid &&
        name.isValid &&
        email.isValid &&
        phoneCountryCode.isValid &&
        phoneNumber.isValid &&
        transportType.isValid &&
        address.isValid &&
        description.isValid &&
        store.isValid &&
        imageURL.isValid;
      if (password.value.length) {
        isValid = isValid && password.isValid;
      }
      return isValid;
    }
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const handleImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const formAction = () => {
    closeModal();
    togglePristine(false);
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={"Add Service Provider"}
      action={
        <>
          {" "}
          <Button type="default" shape={undefined} onClick={closeModal}>
            {intl.formatMessage({ id: "app.button.cancel" })}
          </Button>
          <Button
            type="primary"
            loading={props.isLoading}
            shape={undefined}
            onClick={formAction}
          >
            {intl.formatMessage({ id: "app.button.submit" })}
          </Button>
        </>
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formItem}>
          <Label value="Available Service Provider" />
          <Select
            showSearch
            placeholder="Available Service Provider"
            // onChange={handleCountryCodeChange}
            // value={phoneCountryCode.value}
            optionFilterProp="children"
            filterOption={filterOption}
          ></Select>

          {!isPristine && <ErrorMessage field={name} />}
        </div>
      </Panel>
    </CustomModal>
  );
}

export default AddEditUser;
