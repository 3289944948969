import { useEffect, useRef, useCallback } from "react";

const useOptimized = (func) => {
    const ref = useRef(func);

    const funcMemo = useCallback((...args) => {
        ref.current(...args);
    }, []);
    useEffect(() => {
        ref.current = func;
    })
    return funcMemo;
}

export default useOptimized;