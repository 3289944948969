import React from "react";
import { Table } from "antd";
import "./custom-table.scss";

import { connect } from "react-redux";
import { checkRtl } from "../../../shared/utils/rtl";

const getViewportHeight = (offset) => {
  offset = offset || 0;
  return window.innerHeight - 380 - offset;
};

function CustomTable(props) {
  const RowClass = (record) => {
    if (props.toggleRowSelectAccessor) {
      if (record[props.toggleRowSelectAccessor] === 1) {
        return "disabled-antd-row";
      }
    }
    return "";
  };

  return (
    <Table
      className="custom-table"
      dataSource={props.dataSource}
      size={props.size || "small"}
      rowKey={props.rowKey}
      columns={props.columns.map((i) => ({
        ...i,
        width:
          i?.title === "S No." || i?.title === "Action"
            ? "60px"
            : props?.columnWidth,
      }))}
      loading={props.isLoading}
      onChange={props.onChange}
      pagination={{ ...props.pagination, direction: "rtl" }}
      rowSelection={props.rowSelection}
      expandable={props.expandable}
      scroll={
        props.noScroll
          ? undefined
          : {
              x: props.columns.length * props.columnWidth,
              y: getViewportHeight(props.offset),
            }
      }
      rowClassName={(record) => RowClass(record)}
    />
  );
}

CustomTable.defaultProps = {
  rowKey: (temp, index) => index,
  onChange: (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
  },
  columnWidth: 150,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(CustomTable);
