import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  Label,
} from "../../../components/common";
import { Button, Input, Select, Radio } from "antd";
import { connect } from "react-redux";

import Validations from "../../../shared/validations/Validations";
import countryCodes from "../../../shared/constants/countries.json";
import sidebarData from "../../../shared/constants/sidebar-data.json";

import { toastr } from "react-redux-toastr";
import "./add-edit-user.scss";
import axios from "../../../shared/api/axiosConfig";

import { checkRtl } from "../../../shared/utils/rtl";
import { InteractionFilled } from "@ant-design/icons";
import classNames from "classnames";
const { Option } = Select;
const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  formGroup: "custom-form-group",
  // formHeader: "add-edit-user-modal__",
};
function AddEditUser({ admin_types, ...props }) {
  const [name, changeName] = useState(Validations.validateTextField(""));
  const [lastName, changeLastName] = useState(
    Validations.validateTextField("")
  );
  const [email, changeEmail] = useState(Validations.validateEmail(""));
  const [password, changePassword] = useState(Validations.validatePassword(""));
  console.log("data=>", props?.data);

  const [phoneCountryCode, changePhoneCountryCode] = useState(
    Validations.validateTextField("+1")
  );
  console.log("Country Code => ", phoneCountryCode.value);
  const [phoneNumber, changePhoneNumber] = useState(
    Validations.validatePhoneNumber("")
  );
  const [isLoading, toggleLoading] = useState(false);
  const [userType, changeUserType] = useState(
    Validations.validateTextField(undefined)
  );
  const [privilages, changePrivilages] = useState(
    Validations.validateMultiSelect([])
  );

  console.log("privilages =>  ", privilages);

  const [foodPrivilages, changeFoodPrivilages] = useState(
    Validations.validateMultiSelect([])
  );

  const [convPrivilages, changeConvPrivilages] = useState(
    Validations.validateMultiSelect([])
  );

  const [medPrivilages, changeMedPrivilages] = useState(
    Validations.validateMultiSelect([])
  );

  const [liqPrivilages, changeLiqPrivilages] = useState(
    Validations.validateMultiSelect([])
  );
  const [smallPrivilages, changeSmallPrivilages] = useState(
    Validations.validateMultiSelect([])
  );

  const [largePrivilages, changeLargePrivilages] = useState(
    Validations.validateMultiSelect([])
  );

  const [isPristine, changeIsPristine] = useState(true);

  const [adminRoles, setAllRoles] = useState([]);
  const [accounttype, setAccountType] = useState(0);
  console.log("accounttype state", accounttype);

  const getFilteredRoles = (roles, sendAll, onlyActive) => {
    // return roles.reduce(
    //   (acc, val) => {
    //     if (!props.pickerFlow && val.menuId == 8) {
    //       return acc;
    //     }
    //     if (
    //       (val.menuId == 115 || val.menuId == 15) &&
    //       !props.goodFleetEnabled
    //     ) {
    //       return acc;
    //     }
    //     if (onlyActive) {
    //       if (val.status == 1) {
    //         if (sendAll) {
    //           acc[val.verticalType].push(val);
    //         } else {
    //           acc[val.verticalType].push(val.menuId);
    //         }
    //       }
    //     } else {
    //       if (sendAll) {
    //         acc[val.verticalType].push(val);
    //       } else {
    //         acc[val.verticalType].push(val.menuId);
    //       }
    //     }
    //     return acc;
    //   },
    //   { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
    // );
  };

  const changeAdminAccoutType = (value) => {
    // Email , Phone , Cuntory code
    changePassword(Validations.validatePassword(""));
    changeEmail(Validations.validateEmail(""));
    changePhoneCountryCode(Validations.validateTextField("+1"));
    changePhoneNumber(Validations.validatePhoneNumber(""));
    setAccountType(value);
  };

  const setForm = () => {
    console.log("Role manegment => ", props?.data);
    changeName(Validations.validateTextField(props?.data?.firstName));
    changeLastName(Validations.validateTextField(props?.data?.lastName));
    changeEmail(Validations.validateEmail(props?.data?.email));
    changePhoneCountryCode(Validations.validateTextField("+1"));
    changePassword(Validations.validatePassword(""));

    // changePhoneCountryCode(
    //   Validations.validateTextField(props?.data?.countryCode)
    // );
    changePrivilages(
      Validations.validateMultiSelect([props?.data?.roleGroupId])
    );
    if (props?.data?.phone) {
      changePhoneNumber(Validations.validatePhoneNumber(props?.data?.phone));
      setAccountType(1);
    }
    changeIsPristine(true);
    changeUserType(Validations.validateTextField(props?.data?.userType));
  };

  const resetForm = () => {
    changeName(Validations.validateTextField(""));
    changeLastName(Validations.validateTextField(""));
    changeEmail(Validations.validateEmail(""));
    changePassword(Validations.validatePassword(""));
    setAccountType(0);
    changePhoneCountryCode(Validations.validateTextField("+1"));
    changePhoneNumber(Validations.validatePhoneNumber(""));
    changeIsPristine(true);
    changeUserType(Validations.validateTextField(undefined));

    const roles = getFilteredRoles([]);
    // debugger;
    changePrivilages(Validations.validateMultiSelect());

    changeFoodPrivilages(Validations.validateMultiSelect([]));
    changeConvPrivilages(Validations.validateMultiSelect([]));
    changeMedPrivilages(Validations.validateMultiSelect([]));
    changeLiqPrivilages(Validations.validateMultiSelect([]));
    changeSmallPrivilages(Validations.validateMultiSelect([]));
    changeLargePrivilages(Validations.validateMultiSelect([]));
  };

  useEffect(() => {
    if (props.isVisible) {
      if (props.modalToShow === "VIEW" || props.modalToShow === "EDIT") {
        setForm();
      } else {
        resetForm();
      }
    }
  }, [props.isVisible]);

  const handleFieldChange = (e) => {
    const { value } = e.target;
    // console.log(state)
    // debugger;
    switch (e.target.name) {
      case "name":
        changeName(Validations.validateTextField(value));
        break;
      case "lastName":
        changeLastName(Validations.validateTextField(value));
        break;

      case "email":
        changeEmail(Validations.validateEmail(value));
        break;
      case "createSubAdmin":
        changeAdminAccoutType(value);
        // changePassword(Validations.validatePassword(""));
        break;
      case "password":
        changePassword(Validations.validatePassword(value));
        break;
      case "phoneNumber":
        changePhoneNumber(Validations.validatePhoneNumber(value));
        break;

      default:
        return;
    }
  };

  const getRoles = () => {
    axios
      .get("/available-roles")
      .then((res) => {
        console.log("Roles : ", res?.data);
        console.log("Roles Data: ", res?.data?.data);
        if (res?.data?.statusCode === 200) {
          setAllRoles(res?.data?.data);
        }
      })
      .catch((Err) => {
        console.log("Err", Err);
      });
  };
  useEffect(() => {
    getRoles();
  }, []);

  const handleCountryCodeChange = (value) => {
    changePhoneCountryCode(
      Validations.validateTextField(value, "Country Code")
    );
  };
  console.log("privilages?.value => ", privilages?.value);

  const handlePrivilagesChange = (value) => {
    console.log("handlePrivilagesChange => ", value);
    changePrivilages(Validations.validateMultiSelect([value]));
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const closeModal = () => {
    resetForm();
    props.onCloseIconClick();
  };

  const getRolesDataForSubmission = () => {
    return privilages?.value;
  };

  const handleAdd = () => {
    const data = {
      firstName: name.value,
      lastName: lastName.value,
      password: password.value,
      roleGroupId: privilages?.value[0],

    };

    if (accounttype === 1) {
      data.phone = phoneNumber.value;
             data.countryCode = phoneCountryCode.value;
    } else {
      data.email = email.value;
    }

    

    props.addAdmin(data);
  };

  const handleEdit = () => {
    const data = {
      firstName: name.value,
      lastName: lastName.value,




      roleGroupId: privilages?.value[0],
    };

    if (accounttype === 1) {
      data.phone = phoneNumber.value;
             data.countryCode = phoneCountryCode.value;
    } else {
      data.email = email.value;
    }

    props.editAdmin(data, props.data.id);
  };

  const formAction = () => {
    changeIsPristine(false);
    if (!isFormValid()) {
      toastr.warning(props.intl.formatMessage({ id: "app.message.formCheck" }));
      return;
    }
    if (props.modalToShow === "ADD") {
      handleAdd();
    } else {
      handleEdit();
    }
  };

  const isFormValid = () => {
    let isValid = true;
    if (props.modalToShow === "ADD") {
      isValid =
        isValid &&
        name.isValid &&
        lastName.isValid &&
        (email.isValid || (phoneCountryCode.isValid && phoneNumber.isValid)) &&
        password.isValid &&
        privilages.isValid;
    } else {
      isValid =
        isValid &&
        name.isValid &&
        lastName.isValid &&
        (email.isValid || (phoneCountryCode.isValid && phoneNumber.isValid)) &&
        privilages.isValid;
      if (
        password.value === "" ||
        password.value === undefined ||
        password.value === null
      ) {
      } else {
        isValid = password.isValid;
      }
    }

    return isValid;
  };

  const USER_TYPES = [
    {
      key: "Admin",
      value: "Admin",
      text: "Admin",
    },
    {
      key: "Sub-Admin",
      value: "Sub-Admin",
      text: "Sub-Admin",
    },
  ];

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}

      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD"
          ? "Add User"
          : props.modalToShow === "EDIT"
          ? "Edit User"
          : "View User details"
      }
      action={
        props.modalToShow !== "VIEW" ? (
          <>
            {" "}
            <Button type="default" shape={undefined} onClick={closeModal}>
              <FormattedMessage id="app.field.cancel" />
            </Button>
            <Button
              type="primary"
              loading={props.isLoading}
              shape={undefined}
              onClick={formAction}
            >
              <FormattedMessage id="app.field.submit" />
            </Button>
          </>
        ) : (
          ""
        )
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div>
          <div
            style={{
              marginBottom: "1rem",
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="app.field.details" />
          </div>
          <div>
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <Label value="First Name" />
                <Input
                  name="name"
                  disabled={props.modalToShow === "VIEW"}
                  onChange={handleFieldChange}
                  value={name.value}
                  placeholder="First Name"
                />
                {!isPristine && !name.isValid && (
                  <ErrorMessage text={name.errMsg} />
                )}
              </div>
              <div className={styles.formItem}>
                <Label value="Last Name" />
                <Input
                  name="lastName"
                  disabled={props.modalToShow === "VIEW"}
                  onChange={handleFieldChange}
                  value={lastName.value}
                  placeholder="Last Name"
                />
                {!isPristine && !lastName.isValid && (
                  <ErrorMessage text={lastName.errMsg} />
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className={classNames({
                  [styles.formItem]: true,
                })}
              >
                <Label value="Create Sub Admin" />
                <Radio.Group
                  name="createSubAdmin"
                  onChange={handleFieldChange}
                  value={accounttype}
                  defaultValue={0}
                  style={{
                    display: "flex",
                    // justifyContent: "space-around",
                  }}
                >
                  <Radio disabled={props.modalToShow === "VIEW"} value={0}>
                    With Email
                  </Radio>
                  <Radio disabled={props.modalToShow === "VIEW"} value={1}>
                    With Phone No
                  </Radio>
                </Radio.Group>
              </div>
              <div className={styles.formItem}></div>
            </div>

            {accounttype === 1 ? (
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <Label for="countryCode" value="app.field.countryCode" />
                  <Select
                    showSearch
                    disabled={props.modalToShow === "VIEW"}
                    // style={{ marginRight: "1rem" }}
                    placeholder={props.intl.formatMessage({
                      id: "app.placeholder.countryCode",
                    })}
                    onChange={handleCountryCodeChange}
                    value={phoneCountryCode.value}
                    optionFilterProp="children"
                    filterOption={filterOption}
                  >
                    {countryCodes.map((item, index) => (
                      <Option
                        key={index}
                        value={item.dial_code}
                      >{`${item.dial_code}(${item.name})`}</Option>
                    ))}
                  </Select>
                  {!isPristine && <ErrorMessage field={phoneCountryCode} />}
                </div>
                <div className={styles.formItem}>
                  <Label for="phoneNumber" value="app.field.phoneNumber" />

                  <Input
                    name="phoneNumber"
                    onChange={handleFieldChange}
                    disabled={props.modalToShow === "VIEW"}
                    value={phoneNumber.value}
                    placeholder={props.intl.formatMessage({
                      id: "app.placeholder.phoneNumber",
                    })}
                  />
                  {!isPristine && <ErrorMessage field={phoneNumber} />}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className={styles.formGroup}>
              {accounttype === 0 ? (
                <div className={styles.formItem}>
                  <Label for="email" value="app.field.email" />
                  <Input
                    name="email"
                    onChange={handleFieldChange}
                    disabled={props.modalToShow === "VIEW"}
                    value={email.value}
                    placeholder={props.intl.formatMessage({
                      id: "app.placeholder.email",
                    })}
                  />
                  {!isPristine && <ErrorMessage field={email} />}
                </div>
              ) : (
                ""
              )}
              {props.modalToShow === "ADD" ? (
                <div className={styles.formItem}>
                  <Label for="password" value="app.field.password" />
                  <Input.Password
                    name="password"
                    onChange={handleFieldChange}
                    disabled={props.modalToShow === "VIEW"}
                    value={password.value}
                    type="password"
                    placeholder={props.intl.formatMessage({
                      id: "app.placeholder.password",
                    })}
                  />
                  {!isPristine && props.modalToShow === "ADD" && (
                    <ErrorMessage field={password} />
                  )}
                  {!isPristine &&
                    !(
                      password.value === "" ||
                      password.value === undefined ||
                      password.value === null
                    ) &&
                    props.modalToShow === "EDIT" && (
                      <ErrorMessage field={password} />
                    )}
                </div>
              ) : (
                ""
              )}
            </div>

            <div>
              <div className="custom-form-item">
                <label>Roles</label>
                <Select
                  optionFilterProp="children"
                  // filterOption={filterOption}
                  showSearch
                  placeholder="Select Roles"
                  onChange={handlePrivilagesChange}
                  disabled={props.modalToShow === "VIEW"}
                  value={privilages?.value}
                >
                  {admin_types &&
                    admin_types.map((obj) => {
                      return (
                        <Option key={obj?.id} value={obj?.id}>
                          {obj?.title}
                        </Option>
                      );
                    })}
                </Select>
                {!isPristine && !privilages?.isValid && (
                  <ErrorMessage selectOption />
                )}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}

const mapStateToProps = (state) => ({
  enabledPanels: state.user.enterprice.enabledPanels,
  isAddDisabled: state.user.isAddDisabled,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(injectIntl(AddEditUser));
