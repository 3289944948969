import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CustomModal,
  Panel,
  ErrorMessage,
  UploadButton,
  Label,
  CustomImage,
} from "../../../../components/common";
import axios from "../../../../shared/api/axiose";
import Validations from "../../../../shared/validations/Validations";
import { multiSort } from "../../../../shared/utils/object-manipulation";
import placeholderImg from "../../../../assets/images/item-placeholder.png";
import "./add-edit-promo-modal.scss";
const { TextArea } = Input;
const { Option } = Select;

const styles = {
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formGroup: "custom-form-group",
  formAction: "custom-form-action",
  imageContainer: "active-promo__add-edit-promo-modal__image-container",
  imageContainerImage:
    "active-promo__add-edit-promo-modal__image-container__image",
  imageContainerForm:
    "active-promo__add-edit-promo-modal__image-container__form",
  spanImp: "active-promo__add-edit-promo-modal__span-img",
  datePicker: "datePicker",
};

const errorText = "Field cannot be empty";

const Services = [
  {
    value: "1",
    text: "Service A",
  },
  {
    value: "2",
    text: "Service B",
  },
  {
    value: "3",
    text: "Service C",
  },
];

function AddEditPromoContainer({
  service_data,
  promocodes,
  isLoading,
  fetchData,
  ...props
}) {
  const intl = useIntl();
  console.log("categories_data => ", props?.categories_data);
  const [promodata, updatePromoData] = useState({
    promoCode: Validations.validateTextField(undefined),
    title: Validations.validateTextField(undefined),
    description: Validations.validateTextField(undefined),
    discountType: Validations.validateTextField(undefined),
    validTill: Validations.validateTextField(undefined),
    totalMaxAllowed: Validations.validateNumericValue(undefined),
    categoriesSelect: Validations.validateMultiSelect(undefined),
    serviceSelect: Validations.validateMultiSelect(undefined),
    maxAllowedPerCustomer: Validations.validateNumericValue(undefined),
    discount: Validations.validateNumericValue(),
  });
  const imageContainerRef = useRef(null);
  const [isPristine, togglePristine] = useState(true);
  const [startDate, updateStartDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).startDate
  );

  const [endDate, updateEndDate] = useState(
    Validations.validateStartEndDate(undefined, undefined).endDate
  );

  const [tillDate, updateTillDate] = useState("");
  console.log("tillDate=>", moment(tillDate).format("DD/MM/YYYY"));
  const [title, updateTitle] = useState(Validations.validateTextField(""));

  const [imageURL, changeImageURL] = useState(
    Validations.validateTextField("")
  );

  const [numberIssued, updateNumberIssued] = useState(
    Validations.validateNumericValue("")
  );

  const [ValiedTill, updateValiedTill] = useState();
  console.log("ValiedTill => ", moment(ValiedTill).format("DD/MM/YY"));
  /* Instaservice States */

  const [stores, updateStores] = useState({
    selected: undefined,
    isLoading: true,
    data: [],
    notFound: false,
  });

  console.log("imageURL=>", imageURL);
  console.log("title=>", title);
  console.log("numberIssued=>", numberIssued);

  const resetForm = () => {
    togglePristine(true);
    updatePromoData({
      ...promodata,
      promoCode: Validations.validateTextField(undefined),
      title: Validations.validateTextField(undefined),
      description: Validations.validateTextField(undefined),
      validTill: Validations.validateTextField(undefined),
      discount: Validations.validateTextField(undefined),
      totalMaxAllowed: Validations.validateNumericValue(undefined),
      totalMaxAllowed: Validations.validateNumericValue(undefined),
      categoriesSelect: Validations.validateMultiSelect(undefined),
      serviceSelect: Validations.validateMultiSelect(undefined),
      maxAllowedPerCustomer: Validations.validateNumericValue(undefined),
    });
  };

  const setForm = () => {
    togglePristine(true);

    let category = [];
    let service = [];
    if (props?.data?.category) {
      JSON.parse(props?.data?.category).map((obj) => {
        category.push(obj.category);
      });
    }

    if (props?.data?.service) {
      JSON.parse(props?.data?.service).map((obj) => {
        service.push(obj.service);
      });
    }

    updatePromoData({
      ...promodata,
      promoCode: Validations.validateTextField(props?.data?.code),
      title: Validations.validateTextField(props?.data?.title),
      description: Validations.validateTextField(props?.data?.description),
      discountType: Validations.validateTextField(props?.data?.discountType),
      validTill: Validations.validateTextField(
        props?.data?.validTill ? new Date(props?.data?.validTill) : ""
      ),
      totalMaxAllowed: Validations.validateNumericValue(
        props?.data?.totalMaxAllowed
      ),
      discount: Validations.validateNumericValue(props?.data?.discount),
      categoriesSelect: Validations.validateMultiSelect(category),
      serviceSelect: Validations.validateMultiSelect(service),
      maxAllowedPerCustomer: Validations.validateNumericValue(
        props?.data?.maxAllowedPerCustomer
      ),
    });
  };

  const handleEdit = () => {
    console.log("handle Add promo code => ", promodata);
    let category = [];
    let service = [];
    if (promodata?.categoriesSelect?.value) {
      (promodata?.categoriesSelect?.value).map((id) => {
        let obj = {};
        obj.category = id;
        category.push(obj);
      });
    }
    if (promodata?.serviceSelect?.value) {
      (promodata?.serviceSelect?.value).map((id) => {
        let obj = {};
        obj.service = id;
        service.push(obj);
      });
    }

    let payload = {
      title: promodata?.title?.value,
      description: promodata?.description?.value,
      code: promodata?.promoCode?.value,
      maxAllowedPerCustomer: promodata?.maxAllowedPerCustomer?.value,
      totalMaxAllowed: promodata?.totalMaxAllowed?.value,
      categories: category ? category : [],
      services: service ? service : [],
      validTill: promodata?.validTill?.value,
      discount: promodata?.discount?.value,
      // discountType: promodata?.discountType?.value,
    };
    axios
      .put(`/promo/${props?.data?.id}`, payload)
      .then((res) => {
        console.log("Add banner => ", res?.data);
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
          toast.success(res?.data?.message);
          closeModal();
          fetchData();
          props.getPromoData();
          props.promocodes();
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err, res) => {
        // toast.error("Something went wrong");
        closeModal();
      });
  };

  const handleStartDateChange = (date) => {
    updateValiedTill(date?._d);
  };

  // Instaservice Functions ****************************************************
  // const handleAdd = () => {
  //   console.log("updatePromoData => " , promodata)

  //   alert("Add");
  // };
  const handleAdd = () => {
    console.log("handle Add promo code => ", promodata);
    let category = [];
    let service = [];
    if (promodata?.categoriesSelect?.value) {
      (promodata?.categoriesSelect?.value).map((id) => {
        let obj = {};
        obj.category = id;
        category.push(obj);
      });
    }
    if (promodata?.serviceSelect?.value) {
      (promodata?.serviceSelect?.value).map((id) => {
        let obj = {};
        obj.service = id;
        service.push(obj);
      });
    }

    let payload = {
      title: promodata?.title?.value,
      description: promodata?.description?.value,
      code: promodata?.promoCode?.value,
      maxAllowedPerCustomer: promodata?.maxAllowedPerCustomer?.value,
      totalMaxAllowed: promodata?.totalMaxAllowed?.value,
      categories: category ? category : ["test"],
      services: service ? service : ["test"],
      validTill: promodata?.validTill?.value,
      discount: promodata?.discount?.value,
      discountType: promodata?.discountType?.value || "percentage",
    };
    axios
      .post("/promo", payload)
      .then((res) => {
        console.log("Add banner => ", res?.data);
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
          toast.success(res?.data?.message);
          closeModal();
          fetchData();
          props.getPromoData();
        }
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong");
      });
  };
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    console.log("handleFieldChange => ", name);

    switch (name) {
      case "title":
        updatePromoData({
          ...promodata,
          title: Validations.validateTextField(value),
        });
        break;
      case "promoCode":
        updatePromoData({
          ...promodata,
          promoCode: Validations.validateTextField(value),
        });
        break;
      case "totalMaxAllowed":
        updatePromoData({
          ...promodata,
          totalMaxAllowed: Validations.validateNumericValue(value),
        });
        break;
      case "description":
        updatePromoData({
          ...promodata,
          description: Validations.validateTextField(value),
        });
        break;
      case "maxAllowedPerCustomer":
        updatePromoData({
          ...promodata,
          maxAllowedPerCustomer: Validations.validateNumericValue(value),
        });
        break;
      case "discountType":
        updatePromoData({
          ...promodata,
          discountType: { value, isValid: true },
        });
        break;
      case "discount":
        updatePromoData({
          ...promodata,
          discount: Validations.validateNumericValue(value),
        });
        break;

      default:
        return;
    }
  };

  const handleCategorySelect = (val) => {
    updatePromoData({
      ...promodata,
      categoriesSelect: Validations.validateMultiSelect(val),
    });
  };
  const handleserviceSelect = (val) => {
    updatePromoData({
      ...promodata,
      serviceSelect: Validations.validateMultiSelect(val),
    });
  };
  useEffect(() => {
    if (props?.modalToShow === "EDIT" || props?.modalToShow === "VIEW") {
      setForm();
    } else {
      resetForm();
    }
  }, [props?.modalToShow, props?.data]);
  // Instaservice Functions***************************************************************************

  const handleStoreChange = (selected) => {
    updateStores({
      ...stores,
      selected,
    });
    // changeStore(selected);
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const isFormValid = () => {
    let isValid = true;

    isValid =
      isValid &&
      promodata?.promoCode?.isValid &&
      promodata?.title?.isValid &&
      promodata?.description?.isValid &&
      promodata?.discountType?.isValid &&
      promodata?.validTill?.isValid &&
      promodata?.totalMaxAllowed?.isValid &&
      // promodata?.categoriesSelect?.isValid &&
      // promodata?.serviceSelect?.isValid &&
      promodata?.maxAllowedPerCustomer?.isValid &&
      promodata?.discount?.isValid;

    return isValid;
  };

  const closeModal = () => {
    props.onCloseIconClick();
  };

  const formAction = () => {
    if (props.modalToShow === "ADD") {
      handleAdd();
    } else if (props.modalToShow === "EDIT") {
      handleEdit();
    }
  };

  const handleImageChange = (image) => {
    changeImageURL(Validations.validateTextField(image));
  };
  const handleValiedTillDate = (date) => {
    updatePromoData({
      // ...promodata,
      validTill: Validations.validateTextField(date),
    });
  };

  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={closeModal}
      header={
        props.modalToShow === "ADD"
          ? "Add Promo Code"
          : props.modalToShow === "EDIT"
          ? "Edit Promo Code"
          : props.modalToShow === "VIEW"
          ? "View Promo Code"
          : ""
      }
      action={
        <>
          {props?.modalToShow !== "VIEW" && (
            <>
              <Button type="default" shape={undefined} onClick={closeModal}>
                {intl.formatMessage({ id: "app.button.cancel" })}
              </Button>
              <Button
                loading={props.isLoading}
                type="primary"
                shape={undefined}
                onClick={formAction}
              >
                {intl.formatMessage({ id: "app.button.submit" })}
              </Button>
            </>
          )}
        </>
      }
      isVisible={props.isVisible}
    >
      <Panel isModal isLoading={props.isLoading} className={styles.formModal}>
        <div className={styles.formItem}>
          <Label value="Title" />
          <Input
            placeholder="Enter Title"
            name="title"
            disabled={props?.modalToShow === "VIEW"}
            onChange={handleFieldChange}
            value={promodata?.title?.value}
          />
          {!isPristine && !promodata?.title?.isValid && (
            <ErrorMessage text={"Please enter the title"} />
          )}
        </div>
        <div className={styles.formItem}>
          <Label value="Discount Type" />
          <select
            style={{ height: "2rem", border: "1px solid #C0C0C0" }}
            name="discountType"
            disabled={props?.modalToShow === "VIEW"}
            onChange={handleFieldChange}
            value={promodata?.discountType?.value}
          >
            <option value="percentage">Percentage</option>
            <option value="amount">Amount</option>
          </select>
          {!isPristine && !promodata?.discountType?.isValid && (
            <ErrorMessage text={"Please select a discount type"} />
          )}
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Promo Code" />
            <Input
              placeholder="Promo Code"
              name="promoCode"
              disabled={props?.modalToShow === "VIEW"}
              onChange={handleFieldChange}
              value={promodata?.promoCode?.value}
            />
            {!isPristine && !promodata?.promoCode?.isValid && (
              <ErrorMessage text={promodata?.promoCode?.errMsg} />
            )}
          </div>

          <div className={styles.formItem}>
            <Label value="Total Max Allowed" />
            <Input
              name="totalMaxAllowed"
              disabled={props?.modalToShow === "VIEW"}
              placeholder="Total Max Allowed"
              onChange={handleFieldChange}
              value={promodata?.totalMaxAllowed?.value}
            />
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Discount" />
            <Input
              name="discount"
              disabled={props?.modalToShow === "VIEW"}
              placeholder="Discount"
              onChange={handleFieldChange}
              value={promodata?.discount?.value}
            />
            {!isPristine && !promodata?.discount?.isValid && (
              <ErrorMessage text={promodata?.discount?.errMsg} />
            )}
          </div>
        </div>

        <div className={styles.formItem}>
          <Label value="Description" />

          <TextArea
            placeholder="Description"
            name="description"
            disabled={props?.modalToShow === "VIEW"}
            onChange={handleFieldChange}
            value={promodata?.description?.value}
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
            showCount
            maxLength={200}
          />
          {!isPristine && !promodata?.description?.isValid && (
            <ErrorMessage text={"Please write description"} />
          )}
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <Label value="Max Allowed Per Customer" />
            <Input
              placeholder="Max Allowed Per Customer"
              name="maxAllowedPerCustomer"
              disabled={props?.modalToShow === "VIEW"}
              onChange={handleFieldChange}
              value={promodata?.maxAllowedPerCustomer?.value}
            />
          </div>

          <div className={styles.formItem}>
            <Label value="Valied Till" />

            <DatePicker
              disabled={
                props.modalToShow === "VIEW" || props.modalToShow === "EDIT"
              }
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              placeholderText="Valide Till"
              className={styles.datePicker}
              dateFormat="MM-dd-yyyy hh:mm"
              selected={promodata?.validTill?.value}
              onChange={handleValiedTillDate}
            />
          </div>
        </div>
      </Panel>
    </CustomModal>
  );
}
const mapState = (state) => ({
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
  Categories_data: state?.categories?.categories,
});
export default connect(mapState)(AddEditPromoContainer);
