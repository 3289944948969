const allValidUnits =  {
    TONES : 't' ,
    GRAMS : 'g' ,
    KILOGRAMS : 'kg' ,
    POUNDS : 'lb' ,
    LITRES : 'l' ,
    MILILITERES : 'ml' ,
    PIECES : 'pcs',
    PACKET : 'packet',
    BUNCH : 'bunch', 
  };

  const unitNames =  {
    't'  :  'Tonne',
    'g' : 'Gram' ,
    'kg' : 'Kilogram' ,
    'lb' : 'Pound' ,
    'l' : 'Litre' ,
    'ml' : 'Milliliter' ,
    'pcs' : 'Piece',
    'packet' : 'Packet',
    'bunch' : 'Bunch',
  };

  const unitNamesArray = Object.keys(unitNames);
  
  const validUnitCombinations =  {
    't'  : ['g','kg','lb', 't'] ,
    'g' : ['g','kg','lb', 't'] ,
    'kg' : ['g','kg','lb', 't'] ,
    'lb' : ['g','kg','lb', 't'] ,
    'l' : ['ml', 'l'] ,
    'ml' : ['l', 'ml'] ,
    'pcs' : ['pcs'] ,
    'packet' : ['packet'] ,
    'bunch' : ['bunch'] ,
  };

  export {
      allValidUnits,
      validUnitCombinations,
      unitNames,
      unitNamesArray,
  }