import React from "react";
import Pagination from "react-js-pagination";
import countries from "../../shared/constants/countries.json";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Modal } from "antd";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import {
  Header,
  Panel,
  PaginationInfo,
  Icon,
  DelayedInput,
  CustomTable,
  CustomTag,
} from "../../components/common";
import { roleManagementData } from "../../FakeData";
import { Radio } from "antd";

import axios from "../../shared/api/axios_config"; // base url : http://52.26.241.33:3003
import axiosClient from "../../shared/api/axiosConfig"; // basw url : http://52.26.241.33:3001/admin
import axiosConfig from "../../shared/api/axiosAccount";

import { Button, Select, Menu, Dropdown, Tag } from "antd";
import AddEditUser from "./add-edit-user/add-edit-user";
import ViewUser from "./view-user/view-user";
import "./users.scss";
import { toastr } from "react-redux-toastr";
import { checkRtl } from "../../shared/utils/rtl";
import { useRoles } from "../../hooks";
import { AccessControl } from "../AccessControl/accessControl";
import RolesTabs from "./rolesTabs/rolesTabs";
import ActiveRolesTable from "./Table/active-roles-table";

const styles = {
  wrapper: "role-management__users__wrapper",
  menuContainer: "role-management__users__menu-container",
  leftMenuItem: "role-management__users__left-menu-item",
  rightMenuItem: "role-management__users__right-menu-item",
  tableActions: "role-management__users__table-actions",
  actionIcon: "role-management__users__action-icon",
  actionIconHidden: "role-management__users__action-icon--hidden",
  table: "custom-table",
  menu: "custom-menu",
  section: "custom-section",
  userTabs: "role-management__users__userTabs",
};

const { Option } = Select;
const { confirm } = Modal;

class Users extends React.Component {
  state = {
    totalRecords: null,
    admins: [],
    isLoading: false,
    noRecordsFound: false,
    current: 1,
    pageSize: 5,
    skipRecords: 0,
    isModalVisible: false,
    isModalLoading: false,
    isViewModalVisible: false,
    modalData: {},
    // For No column
    currentDelayed: 1,
    pageSizeDelayed: 5,
    modalToShow: "",
    userRoles: [],
    search: "",
    create: null,
    update: null,
    view: null,
    delete: null,
    tabsType: "user",
    admin_types: [],
  };

  /*********************Instaser API vvvv********************************* */
  getAdminData = () => {
    axios
      .get("/admins")
      .then((res) => {
        // if (res?.data?.statusCode == 200){

        console.log("getAdminData : ", res?.data?.data);
        this.setState({
          admins: res?.data?.data,
        });

        // }
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**********************Instaser API ^^^^******************************** */

  filteredData = () => {
    // return roleManagementData().filter((i) => {
    return this.state.admins.filter((i) => {
      if (this.state.search === "") {
        console.log("this.state.admins", i);
        return true;
      } else {
        return (
          i?.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
          (i?.email
            ? i?.email.toLowerCase().includes(this.state.search.toLowerCase())
            : "") ||
          (i?.phone
            ? i?.phone.toLowerCase().includes(this.state.search.toLowerCase())
            : "")
        );
      }
    });
  };

  handleSearchChange = (val) => {
    this.setState({
      search: val,
    });
  };

  handlePaginationChange = (pagination) => {
    this.setState({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.getPageData(this.state.pageSize, pagination.current);
  };

  handlepageSizeChange = (e, data) => {
    this.setState({
      pageSize: data.value,
    });

    this.getPageData(data.value, this.state.current);
  };

  getPlaceholder = (id) => {
    return this.props.intl.formatMessage({ id });
  };

  removeItem = (item) => {
   
    axiosClient
      .delete(`/delete-account/${item?.id}`)
      .then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
          this.getAdminData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    

  };

  toggleBlockAdmin = (item) => {
    // alert("Block sub Admin")
    let module = AccessControl(
      ["customer.read", "customer.delete"],
      this.props?.subModules
    );
    console.log("module: useRoles", module);
    console.log("module: useRoles list", this.props?.subModules);
  };

  toggleAddModal = () => {
    this.setState((prev) => ({
      isModalVisible: !prev.isModalVisible,
      modalToShow: "ADD",
      adminId: "",
    }));
  };

  toggleEditModal = (data) => {
    this.setState((prev) => ({
      isModalVisible: true,
      modalToShow: "EDIT",
      modalData: data,
      adminId: data.adminId,
    }));
  };

  toggleViewModal = (data) => {
    this.setState((prev) => ({
      isModalVisible: true,
      modalToShow: "VIEW",
      modalData: data,
      adminId: data?.adminId,
    }));
  };
  getAdmin_Types = () => {
    this.setState((prev) => ({
      ...prev, 
      isLoading: true
    }));
    axiosClient
      .get("/role-groups")
      .then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 200) {
          this.setState({
            admin_types: res?.data?.data,
            isLoading: false,
          });
        } else {
          toastr.error(res?.data?.message);
          this.setState((prev) => ({
            ...prev,
            isLoading: true,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState((prev) => ({
          ...prev,
          isLoading: true,
        }));
      });
  };

  handleModalClose = () => {
    this.setState({
      isModalVisible: false,
      isModalLoading: false,
      isViewModalVisible: false,
    });
  };

  addUser = (data) => {
    console.log("addUser: ", data);
    axiosClient
      .post("/signup", data)
      .then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
          this.setState({
            isModalVisible: false,
            isModalLoading: false,
            isViewModalVisible: false,
          });
          this.getAdminData();
          toastr.success("Add Successfully");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  showDeleteConfirm = (item) => {
    confirm({
      title: "Delete User ?",
      width: 520,
      closable: true,
      // icon: <ExclamationCircleOutlined />,
      content: "Added User will be deleted",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      okButtonProps: {
        type: "primary",
        // danger:"true",
      },
      onOk: () => {
        this.removeItem(item);

      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  editUser = (data, id) => {
    console.log("editAdmin role => " , id)
    // 52.26.241.33:3001/admin/account/:adminId
    axiosClient.put(`/account/${id}`, data).then((res) => {
        if (res?.data?.statusCode >= 200 && res?.data?.statusCode <= 299) {
           this.setState({
             isModalVisible: false,
             isModalLoading: false,
             isViewModalVisible: false,
           });
          toastr.success(res?.data?.message);
          this.getAdminData();
        }
    }).catch((err) => console.log(err))
   
  };

  getPageData = (pageSize, current) => {};

  getMenu = (item) => {
    return (
      <Menu>
        {AccessControl(
          ["admin.read", "admin.update", "admin.create"],
          this.props?.subModules
        ) || true ? (
          <Menu.Item
            onClick={() => {
              this.toggleViewModal(item);
            }}
          >
            {this.props.intl.formatMessage({ id: "app.field.view" })}
          </Menu.Item>
        ) : (
          ""
        )}

        {AccessControl(
          ["admin.update", "admin.create"],
          this.props?.subModules
        ) || true ? (
          <Menu.Item
            onClick={() => {
              this.toggleEditModal(item);
            }}
          >
            {this.props.intl.formatMessage({ id: "app.field.edit" })}
          </Menu.Item>
        ) : (
          ""
        )}

        {AccessControl(["admin.delete"], this.props?.subModules) || true ? (
          <Menu.Item
            onClick={() => {
              this.showDeleteConfirm(item);
            }}
          >
            {this.props.intl.formatMessage({ id: "app.field.delete" })}
          </Menu.Item>
        ) : (
          ""
        )}
      </Menu>
    );
  };
  checkCountryCode() {
    const newcountries = countries.reduce((res, currentVal) => {
      if (res[currentVal.dial_code] === undefined) {
        res[currentVal.dial_code] = [currentVal];
      } else {
        res[currentVal.dial_code].push(currentVal);
      }
      return res;
    }, {});
    console.log(newcountries);
    // debugger;
  }

  componentDidMount() {
    this.getAdmin_Types();
    this.getPageData(this.state.pageSize, 1);
    this.checkCountryCode();
    this.getAdminData();
  }

  getRoleName = (id) => {
    let roleName;
    if (this.state.admin_types) {
      roleName = this.state.admin_types.filter((obj) => {
        return obj.id === id;
      });
    }
    console.log("getRoleName => ", roleName);
    return roleName ? roleName[0]?.title : "N/A";
  };

  columns = [
    {
      // props.recordsPerPage * (props.currentPage - 1) + index + 1
      dataIndex: "",
      title: this.props.intl.formatMessage({ id: "app.field.sNo" }),
      render: (item, object, index) => {
        return (
          <span>
            {this.state.pageSize * (this.state.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "firstName",
      title: "First Name",
    },
    {
      dataIndex: "lastName",
      title: "Last Name",
    },
    {
      dataIndex: "email",
      title: this.props.intl.formatMessage({ id: "app.field.email" }),
    },
    {
      dataIndex: "phone",
      title: "Phone Number",
    },
    {
      dataIndex: "roleGroupId",
      title: "Roles",
      render: (id) => {

        return this.getRoleName(id);
      },
    },
    // {
    //   dataIndex: "",
    //   title: this.props.intl.formatMessage({ id: "app.field.status" }),
    //   render: (item) => {
    //     return <CustomTag isBlocked={item.isBlocked} />;
    //   },
    // },
    {
      title: this.props.intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      render: (item) => {
        // debugger;
        return (
          <Dropdown overlay={this.getMenu(item)}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];
  handleUserTabs = (e) => {
    console.log("handleUserTabs => ", e.target.value);
    this.setState({
      tabsType: e.target.value,
    });
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <AddEditUser
          isVisible={this.state.isModalVisible}
          onClose={this.handleModalClose}
          onCloseIconClick={this.handleModalClose}
          modalToShow={this.state.modalToShow}
          data={this.state.modalData}
          addAdmin={this.addUser}
          editAdmin={this.editUser}
          admin_types={this.state.admin_types}
          isLoading={this.state.isModalLoading}
        />

        <Header title="Role Management" path="/home/Role Management" />
        <div className={styles.userTabs}>
          <Radio.Group defaultValue="user" onChange={this.handleUserTabs}>
            <Radio.Button value="user">User</Radio.Button>
            <Radio.Button value="roles">Roles</Radio.Button>
          </Radio.Group>
        </div>

        {this.state.tabsType === "user" ? (
          <div className={styles.section}>
            <h1></h1>
            <div className={styles.menu}>
              <header></header>
              <section>
                <div>
                  <DelayedInput
                    onChange={this.handleSearchChange}
                    value={this.state.search}
                    delay={500}
                    minLength={3}
                    size="medium"
                    prefixIcon={<SearchOutlined />}
                    placeholder={this.props.intl.formatMessage({
                      id: "app.placeholder.search",
                    })}
                  />
                </div>

                {AccessControl(["admin.create"], this.props?.subModules) ||
                true ? (
                  <Button
                    size="medium"
                    disabled={this.props.isAddDisabled}
                    onClick={this.toggleAddModal}
                    type="primary"
                    shape=""
                  >
                    Add User
                  </Button>
                ) : (
                  ""
                )}
              </section>
            </div>
            {/* <CustomTable
              isLoading={this.state.isLoading}
              columns={this.columns}
              dataSource={this.state.admins}
              pagination={{
                pageSize: !this.state.search ? "10" : "10",
                current: this.state.current,
              }}
              onChange={this.handlePaginationChange}
              // offset={43}
            /> */}

            <ActiveRolesTable
              dataSource={this.state.admins}
              isLoading={this.state.isLoading}
              onChange={this.handlePaginationChange}
              pagination={{
                pageSize: !this.state.search ? "10" : "10",
                current: this.state.current,
              }}
              columnWidth={100}
              toggleViewModal={this.toggleViewModal}
              toggleEditModal={this.toggleEditModal}
              showDeleteConfirm={this.showDeleteConfirm}
              admin_types={this.state.admin_types}
              width
            />
          </div>
        ) : (
          <div className={styles.section}>
            <RolesTabs
              isLoading={this.state.isLoading}
              getAdmin_Types={this.getAdmin_Types}
              admin_types_roles={this.state.admin_types}
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
  pickerFlow: state.user.enterprice.pickerFlow,
  subModules: state.roles.subModules,
  goodFleetEnabled: state.user.enterprice.goodFleetEnabled ? true : false,
  enabledVerticals: state.user.enterprice.enabledVerticals
    ? JSON.parse(state.user.enterprice.enabledVerticals)
    : [],
});
export default connect(mapStateToProps)(injectIntl(React.memo(Users)));
