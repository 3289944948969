import React, { useState, useEffect } from "react";
import { Select, Dropdown, Button, Table, Tag } from "antd";
import { useIntl } from "react-intl";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";

import { removeEmptyKeys } from "../../shared/utils/object-manipulation";
import {
  Header,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
} from "../../components/common";
import { ActivePromoTable, AddEditPromoModal } from "./components";
import axiosClient from "../../shared/api/axios_config";
import axiosConfig from "../../shared/api/axiose";
import { PromoCodeData } from "../../FakeData";
import AddEditAdvertisement from "./components/add-edit-advertisement/add-edit-advertisement";

import "./Banners.scss";
import { checkRtl } from "../../shared/utils/rtl";
import { findAllByDisplayValue } from "@testing-library/react";

const { Option } = Select;

const styles = {
  wrapper: "active-promo__wrapper",
  content: "custom-section",
  menu: "custom-menu",
  menuLeft: "active-promo__menu--left",
  menuRight: "active-promo__menu--right",
  pageSize: "active-promo__records-per-page",
};

function Banners({ fetchBanners, banners, ...props }) {
  const intl = useIntl();
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [search, updateSearch] = useState("");

  console.log("updateSearch => ", search);

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };
  const [getServicesData, setServicesData] = useState([]);
  const [getCategoriesData, setCategoriesData] = useState([]);
  const [getCityData, setCityData] = useState([]);

  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    data: {},
    type: "",
    isDeleteModalVisible: false,
    isLoading: false,
  });

  const [promocodes, updatePromos] = useState({
    data: [],
    notFound: false,
    isLoading: true,
    count: null,
  });

  const [bannerData, updateBannerData] = useState({
    data: [],
    isEmpty: false,
    isLoading: true,
    count: null,
  });

  const [tableControls, updateTableControls] = useState({
    isTableLoading: true,
    isTableEmpty: false,
  });

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    total: 0,
    current: 1,
    search: "",
  });

  const handlePaginationChange = (page, total) => {
    updatePagination({
      ...pagination,
      total: page.total,
      current: page.current,
      skip: (page.current - 1) * page.pageSize,
    });
  };

  const openAddPromoModal = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "ADD",
    });
  };

  const addPromo = (data) => {
    console.log(data);

    alert("Add promo");
  };

  const editPromo = (data) => {
    updateModalControls({
      ...modalControls,
      isLoading: false,
    });
    data.promoId = modalControls.data.promoId;
    console.log(data);
  };

  const openEditPromoModal = (data) => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: true,
      type: "EDIT",
      data: data,
    });
  };

  const handleModalClose = () => {
    updateModalControls({
      ...modalControls,
      isDeleteModalVisible: false,
      isVisible: false,
    });
  };

  const handleSearchChange = (val) => {
    clearTimeout();
    if (val) {
    }
    updateSearch(val);
  };

  const removePromo = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };
  const handleView = (data) => {
    updateModalControls({
      ...modalControls,
      isVisible: true,
      isDeleteModalVisible: false,
      data,
      type: "VIEW",
    });
  };

  useEffect(() => {
    if (!search) {
    }
  }, [search]);

  useEffect(() => {}, []);

  return (
    <div className={styles.wrapper}>
      <h1>{"  "}</h1>
      <Header title="Banners" />
      <AddEditPromoModal
        isVisible={modalControls.isVisible}
        // getBannerData={banners}
        getCategoriesData={getCategoriesData}
        getServicesData={getServicesData}
        getCityData={getCityData}
        modalToShow={modalControls.type}
        onCloseIconClick={handleModalClose}
        addPromo={addPromo}
        data={modalControls.data}
        editPromo={editPromo}
        isRTL={props.isRTL}
        isLoading={modalControls.isLoading}
        fetchBanners={fetchBanners}
      />

      <ConfirmModal
        header={`Delete ${modalControls?.data?.code}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={handleModalClose}
        onAction={"/"}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      <div className={styles.content}>
        <div className={styles.menu}>
          <Button
            type="primary"
            disabled={props.isAddDisabled}
            shape={undefined}
            size="medium"
            onClick={openAddPromoModal}
            style={{ marginBottom: "0" }}
          >
            Add Banner
          </Button>
        </div>

        <ActivePromoTable
          // data={bannerData?.data}
          // isLoading={bannerData?.isLoading}
          isEmpty={bannerData?.isEmpty}
          editItem={openEditPromoModal}
          handleView={handleView}
          onChange={handlePaginationChange}
          removeItem={removePromo}
          pagination={pagination}
          columnWidth={150}
          width
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
  panelType: state.user.enterprice.panelType,
});

export default connect(mapStateToProps)(Banners);
