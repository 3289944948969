import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Select, Menu, Tooltip, Tag, Rate } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import { CustomerDetailsTable, CustomerDetailsFormModal } from "./components";
import AddCustomerNotes from "./components/Customer_Notes/AddCustomerNotes";

import { nestedParseJSON } from "../../shared/utils/object-manipulation";
import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  DelayedInput,
  BackButton,
  CustomTag,
} from "../../components/common";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  numberWithCommas,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig"; // baseUrl : http://52.26.241.33:3001
// import { CustomerData } from "../../FakeData";
import placeholderImg from "../../assets/images/profile-placeholder.png";
import "./customer-details.scss";
import { Button } from "antd";
import { CustomTable } from "../../components/common";
import { dateFormat, date_Format } from "../../shared/utils/local-storage";
import AddEditUser from "../customers/components/add-edit-user/add-edit-user";
import { greenTick } from "../../shared/utils/icon-storage";

const styles = {
  wrapper: "customer-details__wrapper",
  menu: "customer-details__menu",
  pageSize: "customer-details__menu__records-per-page",
  image: "customer-details__image",
  form: "customer-details__form",
  formItem: "custom-form-item",
  formGroup: "custom-form-group",
  formWrapper: "customer-details__form-wrapper",
  editIcon: "customer-details__edit-icon",
  backButton: "customer-details__back-button",
};

const { Option } = Select;

function CustomerDetails(props) {
  const intl = useIntl();

  const [customerFlag, updateCustomerFlag] = useState(true);
  const [searchDelayed, updateSearchDelayed] = useState("");
  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });

  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.address" }),
    },
    {
      dataIndex: "",
      title: "Home Type",

      // fixed: 'left',
    },

    {
      title: "Booking Id",
      dataIndex: "",
    },
  ];
  const referral_columns = [
    // s.no, Referral date, Amount, Expiry Date, Booking id
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "",
      title: "Referral Date",

      // fixed: 'left',
    },
    {
      dataIndex: "",
      title: "Amount",

      // fixed: 'left',
    },
    {
      title: "Expiry Date",
      dataIndex: "",
    },
    {
      title: "Booking Id",
      dataIndex: "",
    },
  ];
  const Booking_columns = [
    {
      // Bookings sectionin the customers page columns should be as follows:
      // Booking number(hyperlink → redirect it to service bookings),
      //  Requested Date, Service Type, Address, Payment Type and Customer notes.
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      fixed: "left",
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "",
      title: "Booking Id",

      fixed: "left",
    },
    {
      dataIndex: "",
      title: "Requested Date",
    },
    {
      dataIndex: "",
      title: "Service Type",

      // fixed: 'left',
    },
    {
      dataIndex: "",
      title: "Home Type",

      // fixed: 'left',
    },

    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.address" }),
    },

    {
      dataIndex: "",
      title: "Payment Type",

      // fixed: 'left',
    },

    {
      dataIndex: "",
      title: "Customer Notes",
    },
  ];

  const [customerData, updateCustomerData] = useState({
    isLoading: false,
    data: "",
  });

  const [modalControls, updateModalControls] = useState({
    formModalVisible: false,
    orderDetailsModalVisible: false,
    AddCustomerNotesVisible: false,
    modalType: "EDIT",
    data: {},
    isLoading: false,
    customerId: "",
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: false,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const openFormModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: true,
      data: customerData,
    });
  };

  const closeModal = () => {
    updateModalControls({
      ...modalControls,
      formModalVisible: false,

      // orderDetailsModalVisible: false,
      // AddCustomerNotesVisible: false,
    });
  };

  // Mount

  return (
    <div className={styles.wrapper}>
      <AddEditUser
        // isVisible={false}
        isVisible={modalControls.formModalVisible}
        updateCustomerFlag={updateCustomerFlag}
        customerFlag={customerFlag}
        onCloseIconClick={closeModal}
        // onClose={closeModal}
        getCustomersDetail={"/"}
        // editCustomer={editCustomer}
        data={modalControls.data}
        isLoading={modalControls?.isLoading}
        customerId={modalControls?.customerId}
        modalToShow={modalControls?.modalType}
        // data={modalControls?.data}
      />

      <Header title="app.pageTitle.customerDetails" showBackButton={true} />
      <Panel
        className="custom-section single"
        isLoading={customerData.isLoading}
      >
        <div className={styles.formWrapper}>
          <div style={{ display: "flex", width: "100%", flexWrap: "nowrap" }}>
            {/* <Tooltip placement="top" title="Edit">
              <div className={styles.editIcon}>
                <Icon name="PEN" onClick={openFormModal} />
              </div>
            </Tooltip> */}

            <div className={styles.image}>
              <img
                src={
                  customerData.profilePicURL
                    ? customerData.profilePicURL
                    : placeholderImg
                }
                onError={"/"}
                alt="Customer Profile Pic"
              />
            </div>
            <div className={styles.form}>
              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>First Name</label>
                  <div className={styles.detailsField}>
                    {customerData.firstName ? customerData.firstName : "N/A"}
                  </div>
                </div>

                <div className={styles.formItem}>
                  <label>Last Name</label>
                  <div className={styles.detailsField}>
                    {customerData.lastName ? customerData.lastName : "N/A"}
                  </div>
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>{intl.formatMessage({ id: "app.field.email" })}</label>
                  <div
                    className={styles.detailsField}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span>{customerData.email}</span>
                    <Tooltip placement="bottom">
                      {/* <span style={{}} > */}

                      {greenTick("#f16a56")}
                      {/* </span> */}
                    </Tooltip>
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>
                    {intl.formatMessage({ id: "app.field.phoneNumber" })}
                  </label>
                  <div className={styles.detailsField}>
                    {`${customerData.countryCode || ""} ${
                      customerData.phoneNo
                    }`}
                  </div>
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.formItem}>
                  <label>Last Active</label>
                  <div className={styles.detailsField}>
                    {moment(customerData.lastOnlineDate).format(dateFormat)}
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Registration Date</label>
                  <div className={styles.detailsField}>
                    {moment(customerData.createdAt).format(dateFormat)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div className={styles.formGroup} style={{ marginTop: "1rem" }}>
              <div
                className={styles.formItem}
                style={{ width: "200px", flex: "none" }}
              >
                <label>Customer wallet</label>
                <div className={styles.detailsField}>
                  {" "}
                  {customerData?.walletAmount
                    ? customerData?.walletAmount
                    : 0}{" "}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "calc(100% - 200px)",
                  paddingLeft: "1rem",
                }}
              >
                <div className={styles.formItem}>
                  <label>Address</label>

                  <div className={styles.detailsField}>
                    {customerData?.address
                      ? JSON.parse(customerData.address)[0].address
                      : "N/A"}
                  </div>
                </div>

                <div className={styles.formItem}>
                  <label>My Referral code</label>
                  <div className={styles.detailsField}>
                    {customerData?.referralCode
                      ? customerData?.referralCode
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.formGroup}>
              <div
                className={styles.formItem}
                style={{ width: "200px", flex: "none" }}
              >
                <label>Rating</label>

                <div className={styles.detailsField}>
                  <Rate style={{ color: "#ED432A" }} disabled value={0} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "calc(100% - 200px)",
                  paddingLeft: "1rem",
                }}
              >
                <div className={styles.formItem}>
                  <label>Date of Birth</label>
                  <div className={styles.detailsField}>
                    {customerData?.dateOfBirth
                      ? moment(customerData?.dateOfBirth).format(date_Format)
                      : "Not added"}
                  </div>
                </div>
                <div className={styles.formItem}>
                  <label>Status</label>
                  <div className={styles.detailsField}>
                    <CustomTag isBlocked={customerData?.isBlocked} />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <label>Referral Source</label>
                <div className={styles.detailsField}>
                  {customerData?.referralSource
                    ? customerData?.referralSource
                    : "N/A"}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "calc(100% - 200px)",
                  paddingLeft: "1rem",
                }}
              >
                <div className={styles.formItem}>
                  <label>Used Referral Code</label>
                  <div className={styles.detailsField}>
                    {customerData?.usedReferralCode
                      ? customerData?.usedReferralCode
                      : "Not use"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer></footer>
        </div>

        <div>
          <h1>Saved Address</h1>

          <CustomTable
            isLoading={tableControls.isLoading}
            columns={columns}
            dataSource={tableControls.data}
            isEmpty={tableControls.isEmpty}
            pagination={pagination}
            current={pagination.current}
            pageSize={pagination.pageSize}
            columnWidth={"150px"}
          />
        </div>

        <div>
          <h1>Referral Section</h1>

          <CustomTable
            isLoading={tableControls.isLoading}
            columns={referral_columns}
            dataSource={tableControls.data}
            isEmpty={tableControls.isEmpty}
            pagination={pagination}
            current={pagination.current}
            pageSize={pagination.pageSize}
            columnWidth={"150px"}
          />
        </div>

        <div>
          <h1>Booking </h1>

          <CustomTable
            isLoading={tableControls.isLoading}
            columns={Booking_columns}
            dataSource={tableControls.data}
            isEmpty={tableControls.isEmpty}
            pagination={pagination}
            current={pagination.current}
            pageSize={pagination.pageSize}
            columnWidth={"150px"}
          />
        </div>
      </Panel>
    </div>
  );
}
export default CustomerDetails;
