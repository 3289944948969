import RolesActionTypes from "./roles-action-types";


const initialState = {
  
  modules: [],
  subModules: [],

};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
      case RolesActionTypes.SET_MODULES:
      return {
          modules: action.data, 
        
      };
      case RolesActionTypes.SET_SUB_MODULES:
          return {
              subModules: action.data

          };
    case RolesActionTypes.ROLES_PREVALIGESDATA: 
      let module = action.data[0]?.modules;
      let subModule_privelages = action.data[1]?.sub_module_access;
         return{
           modules: module,
           subModules: subModule_privelages
         }
    default:
      return state;
  }
};

export default rolesReducer;
