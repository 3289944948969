import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import {
  Select,
  Dropdown,
  Button,
  Menu,

  DatePicker
} from "antd";
import { connect } from "react-redux";
import userActions from "../../redux/user-info/user-actions";
import classNames from "classnames";
import {
  CaretDownOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";


import {
  Header,
  Panel,
  Icon,
  PaginationInfo,
  ConfirmModal,
  DelayedInput,
  CustomTable,
  CustomTag,
  CustomImage,
  Label,
} from "../../components/common";
import placeholderImg from "../../assets/images/picker-placeholder.png";
import { toastr } from "react-redux-toastr";
import {
  removeEmptyKeys,
  multiSort,
} from "../../shared/utils/object-manipulation";
import axios from "../../shared/api/axiosConfig";
import AddEditPicker from "./add-edit-picker/add-edit-picker";
// import "./pickers.scss";
import { checkRtl } from "../../shared/utils/rtl";

import { CustomerData } from "../../FakeData";


const styles = {
  wrapper: "pickers__wrapper",
  stickyRight: "pickers__sticky-right",
  stickyAction: "sticky-action",
  upperMenu: "pickers__upper-menu",
  upperMenuLeft: "pickers__upper-menu__left",
  upperMenuRight: "pickers__upper-menu__right",
  upperMenuDropdown: "pickers__upper-menu__dropdown",
  pageSize: "products__upper-menu__records-per-page",
  image: "pickers__image",
  tableActions: "pickers__table-actions",
  actionIcon: "pickers__action-icon",
  actionIconHidden: "pickers__action-icon--hidden",
  table: "custom-table",
  content: "custom-section",
  menu: "custom-menu",
  tableContainer: "pickers__table-container",
};

const { Option } = Select;
const { RangePicker } = DatePicker;

function Pickers(props) {
  const intl = useIntl();

  const [search, updateSearch] = useState("");
  const [modalControls, updateModalControls] = useState({
    isVisible: false,
    isDeleteModalVisible: false,
    data: {},
    type: "",
  });

  const [searchDelayed, updateSearchDelayed] = useState("");

  const [pagination, updatePagination] = useState({
    pageSize: 10,
    skip: 0,
    current: 1,
    search: "",
    delayedcurrent: 1,
    delayedpageSize: 10,
  });

  const [tableControls, updateTableControls] = useState({
    isLoading: true,
    isEmpty: false,
    data: [],
    count: 0,
  });

  const openAddWalletModal = (data) => {
    alert("Open modal")
  };

  const addWallet = (value) => {

    alert("Add Wallet")
  };

  const closeModal = () => {
    updateModalControls({
      isVisible: false,
      isDeleteModalVisible: false,
      data: {},
    });
  };
  // MountConfirmModal




  const handleSearchChange = (val) => {
    updateSearch(val);
  };

  const handleSearch = (val) => {
    updatePagination({
      ...pagination,
      current: 1,
      skip: 0,
    });

    updateSearchDelayed(val);
  };

  const getCustomersData = () => {
    updateTableControls({
      ...tableControls,
      isEmpty: false,
      isLoading: false,
      data: CustomerData(),
      count: 10,
    });
    updatePagination({
      ...pagination,
      total: 10,

    });

  };

  const handleCustomerToggle = (data) => {
    alert("User Block")
  };

  const removeCustomer = (data) => {
    console.log(data);
    updateModalControls({
      ...modalControls,
      isVisible: false,
      isDeleteModalVisible: true,
      data,
      type: "",
    });
  };

  const handleDeleteCustomer = () => {

    alert("Delete Customer")

  };

  const handleCustomerView = (data) => {
    alert("View Details")
  };

  const getMenu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            handleCustomerView(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.view" })}
        </Menu.Item>

        {item.IsBlocked ? (
          <Menu.Item
            onClick={() => {
              handleCustomerToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.unblock" })}
          </Menu.Item>
        ) : ""}
        {!item.IsBlocked ? (
          <Menu.Item
            onClick={() => {
              handleCustomerToggle(item);
            }}
          >
            {intl.formatMessage({ id: "app.field.block" })}
          </Menu.Item>
        ) : ""}
        <Menu.Item
          onClick={() => {
            removeCustomer(item);
          }}
        >
          {intl.formatMessage({ id: "app.field.delete" })}
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    getCustomersData();
  }, [pagination.current, pagination.pageSize, searchDelayed]);

  const handleTableChange = (pag, filt, sorter) => {
    updatePagination({
      ...pag,
    });
  };
  const columns = [
    {
      dataIndex: "",
      // sorter: true,
      title: intl.formatMessage({ id: "app.field.sNo" }),
      // fixed: 'left',
      render: (item, object, index) => {
        return (
          <span>
            {pagination.pageSize * (pagination.current - 1) + index + 1}
          </span>
        );
      },
    },
    {
      dataIndex: "",
      title: "Booking Number",


    },
    {
      title: "Customer Name",
      dataIndex: ""
    },
    {
      dataIndex: "email",
      title: intl.formatMessage({ id: "app.field.email" }),
    },
    {
      dataIndex: "address",
      title: intl.formatMessage({ id: "app.field.address" }),
      // render: (add) => {
      //   let i = add?.address || "---";

      //   return i.length < TABLE_TEXT_LIMIT.ADDRESS ? (
      //     <Tooltip title={i}>
      //       <span style={{ cursor: "pointer" }}>
      //         {textTrimmer(i, TABLE_TEXT_LIMIT.ADDRESS)}
      //       </span>
      //     </Tooltip>
      //   ) : (
      //     i
      //   );
      // },
      render: (add) => add?.address
    },
    {
      dataIndex: "phoneNo",
      title: "Provider",

    },
    {
      dataIndex: "",
      title: "Services"
    },
    {
      dataIndex: "",
      title: "Scheduled At"
    },
    {
      dataIndex: "",
      title: "Created At"
    },
    {
      dataIndex: "",
      title: "Booking Type"
    },




    {
      dataIndex: "",
      title: intl.formatMessage({ id: "app.field.status" }),
      render: (item) => {
        return <CustomTag isBlocked={item.IsBlocked} />;
      },
    },
    {
      dataIndex: "",
      title: "Payment Method"
    },
    {
      dataIndex: "",
      title: "Review"
    },
    {
      title: intl.formatMessage({ id: "app.field.action" }),
      dataIndex: "",
      fixed: "right",
      width: 100,
      render: (item) => {
        // debugger;
        return (
          <center>
            <Dropdown overlay={getMenu(item)}>
              <MoreOutlined />
            </Dropdown>
          </center>
        );
      },
    },

  ];

  return (
    <div className={styles.wrapper} >
      <h1>{"  "}</h1>


      <ConfirmModal
        header={`Delete ${modalControls?.data?.firstName}?`}
        message={intl.formatMessage({
          id: "app.message.deleteWarning",
        })}
        onCloseIconClick={closeModal}
        onAction={handleDeleteCustomer}
        isVisible={modalControls.isDeleteModalVisible}
        actionText="Delete"
        isLoading={modalControls.isLoading}
      />
      <Header title="Order List" />
      <div className="custom-section">
        <div className={styles.menu} >
          <div>
            < RangePicker />
          </div>
          <header>
            <div>
              <Label value="Filter By City" horizontal></Label>
              <Select
                showSearch
                placeholder="City"

                style={{ width: "180px" }}


              >
                <Option value="All Store">

                  Delhi
                </Option>
                <Option value="option 1">
                  Noida
                </Option>
                <Option value=" Option 2 ">
                  Gurugram
                </Option>

              </Select>
            </div>
          </header>

          <header>
            <div>
              <Label value="Provider" horizontal></Label>
              <Select
                showSearch
                placeholder="Provider"

                style={{ width: "180px" }}


              >
                <Option value="All Store">

                  Service providers list.
                </Option>
               

              </Select>
            </div>
          </header>

          <header>
            <div>
              <Label value="Status" horizontal></Label>
              <Select
                showSearch
                placeholder="Status"

                style={{ width: "180px" }}


              >
                {/* Green- Completed Booking
                Red- Cancelled Booking
                Blue- Ongoing Booking
                Orange- Scheduled Booking */}
                <Option value="All Store">

                  Completed Booking 
                </Option>
                <Option value="option 1">
                  Cancelled Booking
                </Option>
                <Option value="option 1">
                  Ongoing Booking
                </Option>
                <Option value="option 1">
                 Scheduled Booking
                </Option>
               

              </Select>
            </div>
          </header>

          <header>
            <div>
              <Label value="Type" horizontal></Label>
              <Select
                showSearch
                placeholder="Type"

                style={{ width: "180px" }}


              >
                

              </Select>
            </div>
          </header>
          <div>
            <DelayedInput
              onChange={handleSearchChange}
              value={search}
              callback={handleSearch}
              delay={500}
              minLength={3}
              size="medium"
              prefixIcon={<SearchOutlined />}
              placeholder={intl.formatMessage({ id: "app.placeholder.search" })}
            />
          </div>
        </div>
        <CustomTable
          isLoading={tableControls.isLoading}
          columns={columns}
          dataSource={tableControls.data}
          isEmpty={tableControls.isEmpty}
          // dataSource={tableControls.data}
          toggleItemBlock={handleCustomerToggle}
          viewItem={handleCustomerView}

          removeItem={removeCustomer}
          pagination={pagination}
          onChange={handleTableChange}
          current={pagination.current}
          pageSize={pagination.pageSize}
        />
      </div>

    </div>
  );
}
const mapStateToProps = (state) => ({
  storeId: state.user.enterprice.storeId,
  branchId: state.user.userInfo.branchId,
  panelType: state.user.enterprice.panelType,
  isAddDisabled: state.user.isAddDisabled,
  isRTL: checkRtl(state.user.locale),
});

export default connect(mapStateToProps)(Pickers);
