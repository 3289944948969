import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { CustomModal, Panel, ErrorMessage } from "../../../../components/common";

import { Tag } from "antd";
import "./view-user.scss";



import moment from "moment";
import { Rate } from "antd";
import { dateFormat } from "../../../../shared/utils/local-storage";

const styles = {
  wrapper: "view-user__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  label: "view-user__label",
  value: "view-user__value",
  row: "view-user__row",
};
function ViewUser({ data, questionType, ...props }) {
  console.log("ViewUser Question => ", data)
  const intl = useIntl();


  const [allRoles, updateAllRoles] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  });

  const [rolesArr, setRolesArr] = useState([]);
  const [viewuserDetail, updateviewuserDetail] = useState({
    question: "",
    questionType: "",
    tag: "",
    adminId: "",
    inputOptions: "",
    createdAt: "",
    updatedAt: "",
    id: ""

  })
  const getViewUserDetail = (data) => {
    updateviewuserDetail({
      ...viewuserDetail,
      question: data?.question,
      questionType: data?.questionType,
      tag: data?.tag,
      adminId: data?.adminId,
      inputOptions: data?.inputOptions,
      createdAt: data?.createdAt,
      updatedAt: data?.updatedAt,
      id: data?.id ? data?.id : "N/A"

    })
  }
  useEffect(() => {
    if(data){
      getViewUserDetail(data)
    }
  }, [data])



  /*
  const getAdminDetails = (id) => {
    axiosConfig
      .get(`/admins/${id}`)
      .then((res) => {
        // console.log("getAdmin res=>", res?.data?.data?.roles);
        // access_level
        let roles = res?.data?.data?.roles;
        let rolesArr = [];
        roles.map((role, index) => {
          // rolesObj[index] = [{ name: role.access_level }];
          let obj = { name: role.access_level };
          rolesArr.push(obj);
        });
        // console.log("rolesArr=>", rolesArr);
        setRolesArr(rolesArr);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
*/

  // {
  //   "id": 9,
  //     "question": "Testing Question 58",
  //       "questionType": 5,
  //         "tag": "Enquire  , Testing Tags",
  //           "isDeleted": false,
  //             "isDisabled": false,
  //               "adminId": 23,
  //                 "inputOptions": null,
  //                   "createdAt": "2023-03-07T09:59:45.607Z",
  //                     "updatedAt": "2023-03-07T09:59:45.607Z"
  // }


  return (
    <CustomModal
      size="tiny"
   
      onCloseIconClick={props?.onCloseIconClick}
      header={`Question View Detail Id : ${viewuserDetail?.id ? viewuserDetail?.id : "N/A"} `}
      isVisible={props?.isVisible}
    >
      <Panel isLoading={props?.isLoading} className={styles.formModal}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.label}>Question</div>
            <div className={styles.value}>
              {viewuserDetail?.question ? viewuserDetail?.question : "N/A"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
             
              Question Type
            </div>
            <div className={styles.value}>
              {viewuserDetail?.questionType ? questionType[viewuserDetail?.questionType - 1].text : "N/A"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
             
              Tags
            </div>
            <div className={styles.value}>
             
              {viewuserDetail?.tag ? viewuserDetail?.tag : "N/A"}

              
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>

              adminId
            </div>
            <div className={styles.value}>
              {viewuserDetail?.adminId ? viewuserDetail?.adminId : "N/A" }

            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
             
              inputOptions
            </div>
            <div className={styles.value}>
              {viewuserDetail?.inputOptions ? viewuserDetail?.inputOptions : "N/A"}
            </div>
          </div>
       

          <div className={styles.row}>
            <div className={styles.label}>Created At</div>

            <div className={styles.value}>
              {viewuserDetail?.createdAt
                ? moment(viewuserDetail?.createdAt).format(dateFormat)
                : "Not Found"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Updated At</div>

            <div className={styles.value}>
              {viewuserDetail?.updatedAt
                ? moment(viewuserDetail?.updatedAt).format(dateFormat)
                : "Not Found"}
            </div>
          </div>
          
        </div>
      </Panel>
    </CustomModal>
  );
}

export default ViewUser;
