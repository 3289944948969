import React from "react";
import { useIntl } from "react-intl";
import "./forget-password.css";
import logo from "../../../assets/images/password-reset.jpg";

function ForgetPasswordSuccess() {
  const intl = useIntl();
  return (
    <div className="external-container">
      <div className="forget-password__wrapper">
        <div className="forget-password__container">
          <img
            src={logo}
            alt=""
            style={{ width: "80%", height: "100%", paddingLeft: "6rem" }}
          />
          <h2>{intl.formatMessage({ id: "pwResetSuccess" })}</h2>
        </div>
      </div>
    </div>
  );
}

export default ForgetPasswordSuccess;
