import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { CustomModal, Panel, ErrorMessage } from "../../../components/common";
import { Tag } from "antd";
import "./view-user.scss";

import sidebarData from "../../../shared/constants/sidebar-data.json";


const styles = {
  wrapper: "view-user__wrapper",
  formItem: "custom-form-item",
  formModal: "custom-form-modal",
  formAction: "custom-form-action",
  label: "view-user__label",
  value: "view-user__value",
  row: "view-user__row",
};
function ViewUser(props) {
  const intl = useIntl();

  useEffect(() => {
    if (props.isVisible) {
      addTitleToRoles(props.data.roles);
    }
  }, [props.isVisible]);
  const [allRoles, updateAllRoles] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  });

  const addStatusToSidebarRoles = (lsRoles) => {
    lsRoles = lsRoles.reduce((acc, val) => {
      if (val.status) {
        acc.push(val.menuId);
      }
      return acc;
    }, []);
    return sidebarData.map((i) => {
      if (lsRoles.indexOf(i.menuId) !== -1) {
        i.status = 1;
      } else {
        i.status = 0;
      }
      return i;
    });
  };

  const getFilteredRoles = (roles, sendAll, onlyActive) => {
    return roles.reduce(
      (acc, val) => {
        if ((val.menuId == 115 || val.menuId == 15) && !props.goodFleetEnabled) {
          return acc;
        }
        if (!props.pickerFlow && val.menuId == 8) {
          return acc;
        }
        if (onlyActive) {
          if (val.status == 1) {
            if (sendAll) {
              acc[val.verticalType].push(val)
            } else {
              acc[val.verticalType].push(val.menuId)
            }
          }
        } else {
          if (sendAll) {
            acc[val.verticalType].push(val);
          } else {
            acc[val.verticalType].push(val.menuId);
          }
        }
        return acc;
      },
      { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
    );
  };
  const addTitleToRoles = (lsRoles) => {
    const alamanac = [...sidebarData, ].reduce((acc, val) => {
      acc[val.menuId] = val;
      return acc;
    }, {});

    const res = lsRoles.filter(i => alamanac[i.menuId] !== undefined).map((i) => ({
      ...i,
      name: alamanac[i.menuId].name,
    }));
    // updateAdminRoles(res);
    // debugger;
    updateAllRoles(getFilteredRoles(res, true, true));
    return res;
  };
  return (
    <CustomModal
      size="tiny"
      // onClose={props.onClose}
      onCloseIconClick={props.onCloseIconClick}
      header="View Details"
      isVisible={props.isVisible}
    >
      <Panel isLoading={props.isLoading} className={styles.formModal} >
        <div className={styles.wrapper} style={{ marginBottom:"1rem" }}>
          <div className={styles.row}>
            <div className={styles.label}>
              Name of Business
            </div>
            <div className={styles.value}>{props.data.name}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              Business Owner First Name
            </div>
            <div className={styles.value}>{props.data.name}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              Business Owner Last Name
            </div>
            <div className={styles.value}>{props.data.name}</div>
          </div>





          <div className={styles.row}>
            <div className={styles.label}>
              Business Registration Number
            </div>
            <div className={styles.value}>{props.data.phoneNo}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              Year Founded
            </div>
            <div className={styles.value}> 2000 </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              No. Of Employees
            </div>
            <div className={styles.value}> 150 </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              Address
            </div>
            <div className={styles.value}>  04-01/02, Kewal Industrial Estate, Senapati Bapat Marg, Lower Parel</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              Zip Code
            </div>
            <div className={styles.value}> 110022 </div>
          </div>


          <div className={styles.row}>
            <div className={styles.label}>
              Which area he will be serving
            </div>
            <div className={styles.value}> Delhi - 110094 </div>
          </div>


          <div className={styles.row}>
            <div className={styles.label}>
              Category
            </div>
            <div className={styles.value}> Cleaning </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              Sub Category
            </div>
            <div className={styles.value}> Car Wash</div>
          </div>



        </div>



        <div className={styles.wrapper} >
          <div className={styles.row}>
            <div className={styles.label}>
              Business License Number
            </div>
            <div className={styles.value}>55421hbg5874</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              License Exprition Date
            </div>
            <div className={styles.value}> March/2025</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              License issuing Authority
            </div>
            <div className={styles.value}>xyz</div>
          </div>





          <div className={styles.row}>
            <div className={styles.label}>
              License Verification Status
            </div>
            <div className={styles.value}> Not Verified</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              Isurance and Insurance Details
            </div>
            <div className={styles.value}> xyz </div>
          </div>

          




        </div>
      </Panel>
    </CustomModal>
  );
}

export default ViewUser;
